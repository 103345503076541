import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useQuery } from '@tanstack/react-query';
import { ScrollView } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { Amplitude, DashboardStackParamList, useDashboardNavigation } from '@iokanx/dashboard/feature';
import { findReviseByIdResolver } from '@iokanx/shared/data-access/api';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Divider, Icon, List } from '@iokanx/shared/ui/universal';

export type IRevisePageProps = NativeStackScreenProps<DashboardStackParamList, 'Revise'>;

function Revise(props: IRevisePageProps) {
  const navigation = useDashboardNavigation();

  const snackbar = useSnackbar();
  const { data: revise } = useQuery(['revise', props.route.params], () => findReviseByIdResolver(props.route.params));
  if (!revise) return null;

  function handleNavigateToOrder(orderId: string) {
    navigation.navigate('Order', { orderId: orderId });
    Amplitude.logEventWithPropertiesAsync('order_click', {});
  }

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    brevise: 'none',
  }));

  // @ts-ignore
  return (
    <ScrollView>
      <List.Section title={' '}>
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Референс</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{revise.reference || revise.bank_reference}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Эквайер</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{revise.acquirer}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Разница в сумме</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{revise.diffAmountWithSymbol}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Разница в комиссии</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{revise.diffAcquirerFeeWithSymbol}</Item>
          </Grid>
        </Grid>
      </List.Section>
      <Divider />
      {revise.bankTransactions && (
        <List.Section title={'Транзакции из реестра банка эквайера'}>
          <Grid container>
            {revise.bank_transactions && (
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Дата и время</TableCell>
                        <TableCell>Сумма</TableCell>
                        <TableCell>Общая комиссия</TableCell>
                        <TableCell>Комиссия эквайера</TableCell>
                        <TableCell>Дата возмещения</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {revise.bankTransactions.map((item, index) => {
                        return (
                          <TableRow>
                            <TableCell>{item.dateTime}</TableCell>
                            <TableCell>{item.amountWithSymbol}</TableCell>
                            <TableCell>{item.processingFeeWithSymbol}</TableCell>
                            <TableCell>{item.acquirerFeeWithSymbol}</TableCell>
                            <TableCell>{item.receiptDate}</TableCell>
                            <TableCell>
                              <a
                                href={item.reestr?.url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                              >
                                <Button sx={{ borderRadius: '8px', minWidth: '28px', paddingX: '8px' }}>
                                  <Icon name={'download'} color={'#fff'} size={20} />
                                </Button>
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </List.Section>
      )}

      {revise.order_id && (
        <List.Section title={'Операции платежей по закзазу'}>
          <Grid container>
            {revise.transactions && (
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Дата и время</TableCell>
                        <TableCell>Сумма</TableCell>
                        <TableCell>Общая комиссия</TableCell>
                        <TableCell>Комиссия эквайера</TableCell>
                        <TableCell>Эквайер/терминал</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {revise.transactions.map((item, index) => {
                        return (
                          <TableRow>
                            <TableCell>{item.dateTime}</TableCell>
                            <TableCell>{item.amountWithSymbol}</TableCell>
                            <TableCell>{item.processingFeeWithSymbol}</TableCell>
                            <TableCell>{item.acquirerFeeWithSymbol}</TableCell>
                            <TableCell>
                              {item.acquirer} / {item.terminal}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </List.Section>
      )}
    </ScrollView>
  );
}

// @ts-ignore
export default wrapPage(
  wrapModalPage(Revise, {
    position: 'Center',
    type: 'Modal',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'Revise'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Reports', params: { screen: 'Revises' } });
        navigation.push('Revise', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

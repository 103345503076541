import { alpha } from '@mui/material';
import { Platform, StyleSheet, View, ViewProps } from 'react-native';
import { useTheme } from 'react-native-paper';

export type IOutlineViewProps = ViewProps;

export function OutlineView(props: IOutlineViewProps) {
  const theme = useTheme();

  return (
    <View
      {...props}
      style={[
        props.style,
        styles.root,
        {
          borderRadius: theme.roundness,
          borderColor: Platform.OS === 'web' ? alpha(theme.colors.primary, 0.23) : theme.colors.placeholder,
        },
      ]}
    />
  );
}

const styles = StyleSheet.create({
  root: { borderWidth: 1, padding: 16 },
});

import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';

interface useFormEditReturnType {
  isDisable: boolean;
  handleIsEdit: (isDisable?: boolean) => void;
  handlePress: () => void;
}

export const useFormEdit = (isSaved: boolean, handleSave: any): useFormEditReturnType => {
  const [isDisable, setIsDisable] = useState<boolean>(isSaved);

  const handleIsEdit = (isDisable?: boolean) => {
    if (isUndefined(isDisable)) {
      setIsDisable((prevIsDisable) => !prevIsDisable);

      return;
    }

    setIsDisable(isDisable);
  };

  const handlePress = () => {
    isDisable ? handleIsEdit() : handleSave();
  };

  useEffect(() => {
    setIsDisable(isSaved);
  }, [isSaved]);

  return {
    isDisable,
    handleIsEdit,
    handlePress,
  };
};

import AsyncStorage from '@react-native-async-storage/async-storage';

import { SELECTED_SHOP_ID } from '@iokanx/shared/data-access/constants';

import { findShopUsersResolver, IFindShopUsersResolverResult } from './find-shop-users.resolver';

export async function findCurrentShopUsersResolver(): Promise<IFindShopUsersResolverResult> {
  const currentShopId = await AsyncStorage.getItem(SELECTED_SHOP_ID);
  if (!currentShopId) return [];

  return findShopUsersResolver({ shopId: currentShopId });
}

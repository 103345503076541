import { isDate, isNumber } from 'lodash';
import moment from 'moment';

import {
  IGetSearchPaymentLinksResponseDataItem,
  IPostPaymentLinksRequestData,
  postPaymentLinks,
} from '../../resources';

export interface ICreatePaymentLinkResolverParams extends IPostPaymentLinksRequestData {
  dueDate?: Date;
}

export type ICreatePaymentLinkResolverResult = IGetSearchPaymentLinksResponseDataItem;

export function createPaymentLinkResolver({
  dueDate,
  ...params
}: ICreatePaymentLinkResolverParams): Promise<ICreatePaymentLinkResolverResult> {
  if (isNumber(params.amount)) {
    params.amount = params.amount * 100;
  }

  if (isDate(dueDate)) {
    params.due_date = moment(dueDate).utcOffset(0).format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
  }

  if (isNumber(params.min_amount) && isNumber(params.max_amount) && isNumber(params.step)) {
    params.min_amount = params.min_amount * 100;
    params.max_amount = params.max_amount * 100;
    params.step = params.step * 100;
  }

  return postPaymentLinks(params);
}

import { Box } from '@mobily/stacks';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ComponentType } from 'react';

import { findCurrentShopUsersResolver, IFindShopUsersResolverResult } from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { Button } from '@iokanx/shared/ui/universal';

import { EmployeeListDesktop } from '../employee-list-desktop/employee-list-desktop';
import { EmployeeListMobile } from '../employee-list-mobile/employee-list-mobile';
import { useDashboardNavigation } from '../navigation';

export interface IEmployeeListProps {
  header: JSX.Element;
  query: UseQueryResult<IFindShopUsersResolverResult>;
}
const RESPONSIVE_EMPLOYEE_LIST: Record<Breakpoint, ComponentType<IEmployeeListProps>> = {
  [Breakpoint.Desktop]: EmployeeListDesktop,
  [Breakpoint.Mobile]: EmployeeListMobile,
};

export function EmployeeList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const ResponsiveEmployeeList = RESPONSIVE_EMPLOYEE_LIST[currentBreakpoint];

  const navigation = useDashboardNavigation();
  function handleAddEmployee() {
    navigation.navigate('AddEmployee');
  }

  const query = useQuery(['users'], findCurrentShopUsersResolver);

  const header = (
    <Box padding={2} alignX={'right'}>
      <Button icon={{ name: 'account-plus' }} onPress={handleAddEmployee}>
        Добавить сотрудника
      </Button>
    </Box>
  );

  return <ResponsiveEmployeeList header={header} query={query} />;
}

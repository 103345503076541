import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import { UseMutateFunction } from '@tanstack/react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ICreateUserResolverResult } from '@iokanx/shared/data-access/api';
import { Button, HelperText, PasswordField, TextField } from '@iokanx/shared/ui/universal';

export interface ICreateUserFormProps {
  createUser: UseMutateFunction<ICreateUserResolverResult>;
  isLoading: boolean;
  error?: string;
  isError: boolean;
}

type ICreateUserFormFieldValues = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
};

const createShopFormValidationSchema: yup.SchemaOf<ICreateUserFormFieldValues> = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().required().email(),
  password: yup.string().required(),
});

export function CreateUserForm(props: ICreateUserFormProps) {
  const { control, handleSubmit } = useForm<ICreateUserFormFieldValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      password: '',
    },
    mode: 'all',
    resolver: yupResolver(createShopFormValidationSchema),
  });

  const onSubmit: SubmitHandler<ICreateUserFormFieldValues> = (data) => {
    props.createUser(data);
  };

  const onError = (error: unknown) => console.error(error);

  return (
    <Stack padding={5} space={5}>
      <title>Title</title>
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'Фамилия'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'first_name'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'Имя'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'last_name'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'Телефон'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'phone'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'Почтовый адрес'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'email'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <PasswordField
            label={'Пароль'}
            value={field.value}
            onChangeText={field.onChange}
            onBlur={field.onBlur}
            helperText={fieldState.error?.message}
            error={Boolean(fieldState.error)}
          />
        )}
        name={'password'}
      />
      <HelperText type={'error'} visible={props.isError}>
        {props.error}
      </HelperText>
      <Button onPress={handleSubmit(onSubmit, onError)} loading={props.isLoading}>
        Добавить пользователя
      </Button>
    </Stack>
  );
}

import { useInfiniteQuery } from '@tanstack/react-query';
import { ListRenderItem } from 'react-native';

import { PayoutCard } from '@iokanx/dashboard/ui';
import { searchPayoutsResolver, IFindPayoutResolverResultItem } from '@iokanx/shared/data-access/api';
import { createGetNextPageParam } from '@iokanx/shared/util';

import { OperationFlatList } from '../operation-flat-list/operation-flat-list';
import { IPayoutListProps } from '../payout-list/payout-list';

export function PayoutListMobile(props: IPayoutListProps) {
  const query = useInfiniteQuery(
    ['payouts', props.form.watch()],
    (context) => searchPayoutsResolver({ ...props.form.watch(), page: context.pageParam, limit: props.pageLimit }),
    {
      getNextPageParam: createGetNextPageParam({ pageLimit: props.pageLimit }),
    },
  );

  const renderItem: ListRenderItem<IFindPayoutResolverResultItem> = (info) => {
    return <PayoutCard payout={info.item} onCardPress={() => props.onPayoutPress(info.item)} />;
  };

  return (
    <OperationFlatList
      renderItem={renderItem}
      query={query}
      hasNotNextPageText={'Платежей больше нет'}
      emptyText={'По указанным параметрам, платежей не найдено'}
      filter={props.filter}
    />
  );
}

export const organizationAddressFields = [
  { name: 'country', label: 'Страна' },
  { name: 'city', label: 'Город' },
  { name: 'region', label: 'Район' },
  { name: 'street', label: 'Улица' },
  { name: 'home', label: 'Номер дома' },
  { name: 'flat', label: 'Номер квартиры/офиса' },
];

export const LEGAL_ADDRESS_TYPE = 'LEGAL';
export const ACTUAL_ADDRESS_TYPE = 'ACTUAL';

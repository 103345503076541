import { AxiosInstance } from 'axios';

import { getEnv } from '@iokanx/shared/util';

import { adminHttp } from '../admin-http';
import { backendHttp } from '../backend-http';
import { paymentFormHttp } from '../payment-form';
import { transferHttp } from '../transfer-http';

const INSTANCE_MAP: Record<string, AxiosInstance> = {
  'corporate-payments': transferHttp,
  'corporate-payments-e2e': transferHttp,
  admin: adminHttp,
  'payment-form': paymentFormHttp,
  'payment-form-e2e': paymentFormHttp,
  dashboard: backendHttp,
};

const project = getEnv('NX_TASK_TARGET_PROJECT') || process.env['NX_TASK_TARGET_PROJECT'];

export const http = INSTANCE_MAP[project];

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { Amplitude, DashboardStackParamList, useDashboardNavigation, useMe } from '@iokanx/dashboard/feature';
import { CreateUserForm } from '@iokanx/dashboard/feature';
import { createUserResolver, ICreateUserResolverResult } from '@iokanx/shared/data-access/api';
import { IHttpError } from '@iokanx/shared/data-access/types';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';

function CreateUser() {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const navigation = useDashboardNavigation();
  const { me } = useMe();

  const {
    mutate: createUser,
    error,
    isLoading,
    isError,
  } = useMutation<ICreateUserResolverResult, AxiosError<IHttpError>, ICreateUserResolverResult>(createUserResolver, {
    onSuccess: async () => {
      snackbar.show('Пользователь успешно создан', { type: 'success' });
      await Amplitude.logEventWithPropertiesAsync('create_user', { user_id: me?.id });

      navigation.push('Drawer', { screen: 'Users', params: { screen: 'Owners' } });

      return queryClient.invalidateQueries(['users']);
    },
  });

  return (
    <CreateUserForm
      createUser={createUser}
      isLoading={isLoading}
      error={error?.response?.data.message}
      isError={isError}
    />
  );
}

export default wrapPage(
  wrapModalPage(CreateUser, {
    fillNavigationStack: (navigation: NativeStackNavigationProp<DashboardStackParamList>) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Users', params: { screen: 'Owners' } });
        navigation.push('CreateShop');
      }
    },
    handleDismiss: (navigation) => {
      navigation.navigate('Drawer', { screen: 'Users', params: { screen: 'Owners' } });
    },
  }),
);

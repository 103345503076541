import { REFUND_STATUS_I18N, RefundStatus } from '@iokanx/shared/data-access/constants';

import { StatusChip, StatusChipPropsIconName, StatusChipVariant } from '../status-chip/status-chip';

interface IRefundStatusChipProps {
  refundStatus: RefundStatus;
}

const refundStatusVariant: Record<RefundStatus, StatusChipVariant> = {
  [RefundStatus.Pending]: StatusChipVariant.Failure,
  [RefundStatus.Approved]: StatusChipVariant.Loading,
  [RefundStatus.Declined]: StatusChipVariant.Failure,
};

const statusChipIconName: Record<RefundStatus, StatusChipPropsIconName> = {
  [RefundStatus.Pending]: 'loading',
  [RefundStatus.Approved]: 'loading',
  [RefundStatus.Declined]: 'alert-circle-outline',
};

export function RefundStatusChip(props: IRefundStatusChipProps) {
  return (
    <StatusChip variant={refundStatusVariant[props.refundStatus]} iconName={statusChipIconName[props.refundStatus]}>
      {REFUND_STATUS_I18N[props.refundStatus]}
    </StatusChip>
  );
}

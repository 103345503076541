import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import { Link } from '@react-navigation/native';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import * as yup from 'yup';

import { IAuthLoginRequestData } from '@iokanx/shared/data-access/api';
import { DeviceSize } from '@iokanx/shared/data-access/constants';
import { Card, EmailField, HelperText, PasswordField, SimpleCard, Button } from '@iokanx/shared/ui/universal';

import { useSignIn } from '../auth';
import { DashboardStackParamList, useDashboardNavigation } from '../navigation';

type IFormInputs = IAuthLoginRequestData;

const schema: yup.SchemaOf<IFormInputs> = yup.object().shape({
  email: yup.string().email('Email должен быть таким user@domain.com').required('Обязательное поле'),
  password: yup.string().required('Обязательное поле'),
});

export function SignInForm() {
  const { control, handleSubmit } = useForm<IFormInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const signInMutation = useSignIn();
  const onSubmit: SubmitHandler<IFormInputs> = (data) => signInMutation.mutate(data);

  const navigation = useDashboardNavigation();
  function handleNavigateToRegistration() {
    navigation.push('Registration');
  }

  return (
    <SimpleCard title={'Вход'} cardStyle={styles.card}>
      <Card.Content>
        <Stack space={2}>
          <Controller
            control={control}
            name={'email'}
            render={({ field, fieldState }) => (
              <EmailField
                label={'Логин'}
                value={field.value}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                helperText={fieldState.error?.message}
                error={Boolean(fieldState.error)}
                onFocus={signInMutation.reset}
              />
            )}
          />
          <Controller
            control={control}
            name={'password'}
            render={({ field, fieldState }) => (
              <PasswordField
                label={'Пароль'}
                value={field.value}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                helperText={fieldState.error?.message}
                error={Boolean(fieldState.error)}
                onFocus={signInMutation.reset}
              />
            )}
          />
        </Stack>
        <HelperText type={'error'} visible={signInMutation.isError}>
          {signInMutation.error?.response?.data?.message}
        </HelperText>
      </Card.Content>
      <Card.Actions>
        <Stack space={2}>
          <Link<DashboardStackParamList> to={{ screen: 'ForgotPassword' }} style={styles.forgotPasswordLink}>
            Забыли пароль?
          </Link>
          <Button onPress={handleSubmit(onSubmit)} loading={signInMutation.isLoading}>
            Войти
          </Button>
          <Button onPress={handleNavigateToRegistration} mode={'text'}>
            Зарегистрироваться
          </Button>
        </Stack>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  card: {
    width: '100%',
    backgroundColor: '#f8fafc',
    borderRadius: 12,
    border: 'none',
    maxWidth: DeviceSize.LargeMobile,
    padding: '40px',
  },
  forgotPasswordLink: {
    textAlign: 'right',
  },
});

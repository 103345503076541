import moment from 'moment';

import {
  getAnalyticsPaymentAcceptance,
  IGetAnalyticsPaymentAcceptanceParams,
  IGetAnalyticsPaymentAcceptanceResponseData,
} from '../../resources';

export interface IFindPaymentAcceptanceAnalyticsResolverResult {
  rate: string;
}

export async function findPaymentAcceptanceAnalyticsResolver(_params: IGetAnalyticsPaymentAcceptanceParams) {
  const params = { ..._params };
  if (params.from_dt && !params.to_dt) {
    params.to_dt = moment(params.from_dt).endOf('day').toDate();
  }

  const data = await getAnalyticsPaymentAcceptance(params);
  return transformAnalyticsPaymentAcceptance(data);
}

function transformAnalyticsPaymentAcceptance(
  data: IGetAnalyticsPaymentAcceptanceResponseData,
): IFindPaymentAcceptanceAnalyticsResolverResult {
  return {
    rate: data.rate.toString() + '%',
  };
}

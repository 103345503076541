/* eslint-disable no-useless-concat */
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Collapse, FormControlLabel, FormHelperText, Grid, Typography, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useFormik } from 'formik';
import { isEmpty, isString } from 'lodash';
import { useTranslation } from 'next-i18next';
import { FocusEvent, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { ICard, ICardFormType, IPreSavedCardType } from '@iokanx/payment-form/types';
import { Button, CardFooter, Footer } from '@iokanx/payment-form/ui';
import { IFindShopFeaturesResolverResult } from '@iokanx/shared/data-access/api';
import {
  AVAILABLE_PAYMENT_SYSTEMS,
  CardPayAdditionalField,
  DEFAULT_CARD_PLACEHOLDER,
  ShopFeature,
} from '@iokanx/shared/data-access/constants';
import { IHttpError, IWidgetProps } from '@iokanx/shared/data-access/types';
import {
  CardExpiryDateField,
  CardNumberField,
  CvcField,
  MaskedCardNumberField,
  PhoneField,
  StyledCheckbox,
  StyledTextField,
} from '@iokanx/shared/ui/web';
import { compactObject, getAmountWithCurrency, getPaymentSystemsFromCardNumber } from '@iokanx/shared/util';

import { ConditionalComponent } from '../../../../../dashboard/feature/src/lib/profile-form/components/ConditionalComponent';
import { ApplePay } from '../apple-pay/apple-pay';
import { useCardSchema } from '../use-card-schema/use-card-schema';

interface IDiscount {
  type: string;
  value: number;
  description?: string;
  payment_system?: string;
  emitter?: string;
  ranges?: Array<{ min: string; max: string }>;
}

interface IPaymentFormProps {
  onSubmit: (values: ICardFormType) => void;
  initialValues?: IPreSavedCardType;
  orderId?: string;
  amount?: string;
  isSaveCard?: boolean;
  widgetProps?: IWidgetProps;
  isTokenPayment?: boolean;
  isWaitPaymentInfo?: boolean;
  merchantName?: string;
  amountValue?: number;
  isApplePayAvailable?: boolean;
  handleSetInterval?: (value: number | false) => void;
  headerText?: string;
  error?: string;
  saveCardText?: string;
  hideAmount?: boolean;
  shopConfig?: IFindShopFeaturesResolverResult;
  discounts?: Array<IDiscount>;
  extraInfo?: any;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  textAlign: 'left',
  borderRadius: 0,
  boxShadow: 'none',
  border: 'none',
}));

const extraInfoStyles: any = {
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  margin: '24px 0 0 24px',
  backgroundColor: '#fff',
  border: '1px solid #e2e8f0',
  borderRadius: '12px',
  paddingTop: '14px',
  overflow: 'hidden',
};

export function applyDiscount(amount: number, discounts?: Array<IDiscount>, cardNumber?: string) {
  let discountedAmountValue = amount;
  let totalDiscountPercent = 0;

  // get the payment system e.g. visa, mastercard, etc.
  cardNumber = cardNumber?.split(' ').join('');

  const paymentSystems = isString(cardNumber) ? getPaymentSystemsFromCardNumber(cardNumber) : AVAILABLE_PAYMENT_SYSTEMS;
  const paymentSystem = paymentSystems.length === 1 ? paymentSystems[0] : undefined;

  // select applicable discounts
  const applicableDiscounts = discounts
    ?.filter((discount) => !discount.payment_system || discount.payment_system === paymentSystem)
    .filter(
      (discount) =>
        !discount.ranges ||
        discount.ranges.length === 0 ||
        discount.ranges.some((range) => isString(cardNumber) && cardNumber >= range.min && cardNumber <= range.max),
    );
  if (applicableDiscounts) {
    applicableDiscounts.forEach((discount) => {
      if (discount.type === 'percent') {
        totalDiscountPercent += discount.value;
      }
    });
    discountedAmountValue = discountedAmountValue * (1 - totalDiscountPercent / 100);
  }
  totalDiscountPercent = Math.round((totalDiscountPercent + Number.EPSILON) * 100) / 100;
  discountedAmountValue = Math.round((discountedAmountValue + Number.EPSILON) * 100) / 100;
  return [discountedAmountValue, totalDiscountPercent];
}

const mapOrderAndFeatures = (orderInfo: any, features: any) => {
  if (!orderInfo || !features) return null;

  const additionalFieldNames = features?.card_payment?.additional_fields.reduce((modified: any, field: any) => {
    modified[field.name] = {
      displayName: field.display_name,
    };

    return modified;
  }, {});

  return Object.entries(orderInfo).reduce((modified: any, [name, value]) => {
    const additionalFieldName = additionalFieldNames[name];

    if (Object.keys(additionalFieldNames).includes(name)) {
      modified[name] = {
        value,
        displayName: additionalFieldName.displayName,
      };

      if (additionalFieldName.prefixes) {
        modified[name] = {
          ...modified[name],
          value: additionalFieldName.prefixes[orderInfo.car_model] + value,
        };
      }
    }

    return modified;
  }, {});
};

export function PaymentForm({
  amount,
  onSubmit,
  widgetProps,
  isTokenPayment = false,
  isWaitPaymentInfo = false,
  initialValues,
  merchantName,
  orderId,
  amountValue,
  isApplePayAvailable,
  handleSetInterval,
  headerText,
  isSaveCard,
  error,
  discounts,
  extraInfo,
  shopConfig,
  ...props
}: IPaymentFormProps) {
  const { t } = useTranslation();

  const [serverError, setServerError] = useState<string>();

  useEffect(() => {
    setServerError(error);
  }, [error]);

  const [focused, setFocused] = useState('');

  const saveCard = shopConfig?.card_payment?.save_card || false;
  const additionalFields = shopConfig?.card_payment?.additional_fields || [];
  const additionalFieldNames = additionalFields.map((field) => field.name);
  const additionalFieldsValues = additionalFields.reduce((acc, field) => {
    return { ...acc, [field.name]: '' };
  }, {});
  const isCvcHidden = shopConfig?.features.includes(ShopFeature.NoCVC);

  const cardSchema = useCardSchema(
    {
      requiredFields: additionalFields.filter((field) => field.required).map((field) => field.name),
    },
    isCvcHidden,
  );
  const validationSchema = useMemo(() => {
    const schema = yup.object({
      cardNumber: cardSchema.panSchema,
      expireDate: cardSchema.expSchema,
      cardCvC: cardSchema.cvcSchema,
      saveCard: yup.boolean().required(),
      holder: cardSchema.holderSchema,
      phone: cardSchema.phoneSchema,
      email: cardSchema.emailSchema,
    });

    if (isTokenPayment) {
      return schema.pick(['cardCvC']);
    }

    const requiredFields = ['cardNumber', 'expireDate', 'cardCvC'] as const;

    return schema.pick([...requiredFields, ...additionalFieldNames]);
  }, [isTokenPayment, cardSchema, additionalFieldNames]);

  const formik = useFormik<ICard>({
    enableReinitialize: true,
    initialValues: {
      cardNumber: '',
      expireDate: '',
      cardCvC: '',
      saveCard: saveCard,
      ...additionalFieldsValues,
      ...compactObject({ ...initialValues }),
    },
    onSubmit: async (values) => {
      setServerError('');
      const [cardExpireMonth, cardExpireYear] = values.expireDate.split('/');
      const cardNumber = values.cardNumber.split(' ').join('');

      try {
        await onSubmit({
          cardNumber,
          cardExpireMonth,
          cardExpireYear,
          cardCvC: values.cardCvC,
          cardHolder: values.holder || 'Holder',
          saveCard: values.saveCard,
          email: values.email,
          phone: values.phone,
        });
      } catch (error) {
        if (axios.isAxiosError(error) && (error.response?.data as IHttpError).message) {
          setServerError((error.response?.data as IHttpError).message);
        } else {
          setServerError(t('Неизвестная ошибка. Проверьте интернет подключение и попробуйте ещё раз.'));
        }
      }
    },
    validationSchema,
  });

  const [discountedAmountValue, totalDiscountPercent] = applyDiscount(amountValue || 0, discounts);
  const [displayAmountValue, setDisplayAmountValue] = useState(discountedAmountValue);
  const [discountAlertMessage, setDiscountAlertMessage] = useState(
    'Скидка ' + totalDiscountPercent + '%' + ' применена',
  );
  const [open, setOpen] = useState(totalDiscountPercent > 0);

  const handleCardNumberChange = (cardNumber: string): void => {
    const [discountedAmountValue, totalDiscountPercent] = applyDiscount(amountValue || 0, discounts, cardNumber);
    setDisplayAmountValue(discountedAmountValue);
    setOpen(totalDiscountPercent > 0);
    setDiscountAlertMessage('Скидка ' + totalDiscountPercent + '%' + ' применена');
  };

  const handleFocusedField = (event: FocusEvent<HTMLInputElement>): void => {
    setServerError('');
    setFocused(event.target.name);
  };

  const handleBlur = (): void => setFocused('');

  const panNumberError = focused !== 'cardNumber' && formik.touched.cardNumber && formik.errors.cardNumber;
  const expireDateError = focused !== 'expireDate' && formik.touched.expireDate && formik.errors.expireDate;
  const cvcError = focused !== 'cardCvC' && formik.touched.cardCvC && formik.errors.cardCvC;
  const holderError = focused !== 'holder' && formik.touched.holder && formik.errors.holder;
  const phoneError = focused !== 'phone' && formik.touched.phone && formik.errors.phone;
  const emailError = focused !== 'email' && formik.touched.email && formik.errors.email;

  const mappedExtraInfo = useMemo(() => mapOrderAndFeatures(extraInfo, shopConfig), [extraInfo, shopConfig]);

  return (
    <Grid
      container
      direction={'column'}
      component={'form'}
      onSubmit={formik.handleSubmit}
      style={widgetProps?.styles?.cardFormContainer}
      spacing={3}
    >
      <Grid item display={{ xs: 'none', md: 'block' }}>
        <Typography variant={'h5'}>{headerText}</Typography>
      </Grid>

      {/* Order info */}

      <ConditionalComponent
        condition={!isEmpty(mappedExtraInfo) && Boolean(mappedExtraInfo)}
        component={
          <div style={extraInfoStyles}>
            {mappedExtraInfo &&
              Object.entries(mappedExtraInfo)?.map(([key, object]: any) => {
                return (
                  <div key={key} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ backgroundColor: 'transparent' }}>
                      <Item className="infoTitle">{object?.displayName}</Item>
                    </div>

                    <div style={{ backgroundColor: 'transparent' }}>
                      <Item style={{ textAlign: 'end' }}>{object?.value}</Item>
                    </div>
                  </div>
                );
              })}
          </div>
        }
      />

      {/* --------- */}

      <Grid item container direction={'column'}>
        <Grid item>
          {isTokenPayment ? (
            <MaskedCardNumberField
              label={t('Номер карты')}
              name="cardNumber"
              value={formik.values.cardNumber}
              style={widgetProps?.styles?.panInput}
              disabled
            />
          ) : (
            <CardNumberField
              label={t('Номер карты')}
              name="cardNumber"
              value={formik.values.cardNumber}
              onChange={(event) => {
                formik.handleChange(event);
                handleCardNumberChange(event.target.value);
              }}
              onBlur={(event) => {
                formik.handleBlur(event);
                handleBlur();
              }}
              onFocus={handleFocusedField}
              error={Boolean(panNumberError)}
              helperText={panNumberError}
              style={widgetProps?.styles?.panInput}
              disabled={isWaitPaymentInfo}
              autoFocus
              getNextFocusElementName={(value) => cardSchema.panSchema.isValidSync(value) && 'expireDate'}
            />
          )}
        </Grid>
        <Grid item container spacing={3} marginBottom={-2.5}>
          <Grid item xs={6}>
            <CardExpiryDateField
              label={t('Срок карты')}
              name="expireDate"
              value={formik.values.expireDate}
              onChange={formik.handleChange}
              onBlur={(event) => {
                formik.handleBlur(event);
                handleBlur();
              }}
              onFocus={handleFocusedField}
              error={Boolean(expireDateError)}
              helperText={expireDateError}
              style={widgetProps?.styles?.expireInput}
              disabled={isTokenPayment || isWaitPaymentInfo}
              getNextFocusElementName={(value) => cardSchema.expSchema.isValidSync(value) && 'cardCvC'}
            />
          </Grid>
          {!isCvcHidden && (
            <Grid item xs={6}>
              <CvcField
                label={'CVC / CVV'}
                name="cardCvC"
                value={formik.values.cardCvC}
                onChange={formik.handleChange}
                onBlur={(event) => {
                  formik.handleBlur(event);
                  handleBlur();
                }}
                onFocus={handleFocusedField}
                disabled={isWaitPaymentInfo}
                error={Boolean(cvcError)}
                helperText={cvcError}
                style={widgetProps?.styles?.cvcInput}
                getNextFocusElementName={(value) => cardSchema.cvcSchema.isValidSync(value) && additionalFieldNames[0]}
                tooltipTitle={t('3 цифры на обороте карты')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {additionalFieldNames.includes(CardPayAdditionalField.Holder) && (
        <Grid item marginBottom={-2.5}>
          <StyledTextField
            label={t('Имя на карте')}
            name={'holder'}
            error={Boolean(holderError)}
            helperText={holderError}
            value={formik.values.holder}
            onChange={(event) => {
              formik.setFieldValue('holder', event.target.value.toUpperCase());
            }}
            onBlur={(event) => {
              formik.handleBlur(event);
              handleBlur();
            }}
            onFocus={handleFocusedField}
            disabled={isWaitPaymentInfo}
          />
        </Grid>
      )}
      {additionalFieldNames.includes(CardPayAdditionalField.Phone) && (
        <Grid item marginBottom={-2.5}>
          <PhoneField
            label={t('Номер телефона')}
            name={'phone'}
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={(event) => {
              formik.handleBlur(event);
              handleBlur();
            }}
            onFocus={handleFocusedField}
            disabled={isWaitPaymentInfo}
            error={Boolean(phoneError)}
            helperText={phoneError}
          />
        </Grid>
      )}
      {additionalFieldNames.includes(CardPayAdditionalField.Email) && (
        <Grid item marginBottom={-2.5}>
          <StyledTextField
            label={t('Email')}
            type={'email'}
            error={Boolean(emailError)}
            helperText={emailError}
            name={'email'}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={(event) => {
              formik.handleBlur(event);
              handleBlur();
            }}
            onFocus={handleFocusedField}
            disabled={isWaitPaymentInfo}
            inputMode={'email'}
            placeholder={DEFAULT_CARD_PLACEHOLDER.email}
          />
        </Grid>
      )}
      {isSaveCard && (
        <Grid item>
          <FormControlLabel
            control={<StyledCheckbox />}
            name="saveCard"
            onChange={formik.handleChange}
            disabled={isWaitPaymentInfo}
            onBlur={formik.handleBlur}
            checked={formik.values.saveCard}
            label={
              <Typography variant={'body2'}>{props.saveCardText || t('Сохранить карту на этом сайте')}</Typography>
            }
          />
        </Grid>
      )}
      <Grid item>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {discountAlertMessage}
          </Alert>
        </Collapse>
      </Grid>
      <Grid item>
        <Button
          isLoading={formik.isSubmitting || isWaitPaymentInfo}
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting || isWaitPaymentInfo}
          style={widgetProps?.styles?.button}
          id={'ioka-pay-button'}
        >
          {`${t('Оплатить')}${
            props.hideAmount ? '' : ` ${displayAmountValue ? getAmountWithCurrency(displayAmountValue / 100) : ''}`
          }`}
        </Button>
      </Grid>
      {isApplePayAvailable && amountValue && orderId && handleSetInterval && (
        <Grid item>
          <ApplePay
            orderId={orderId}
            handleSetInterval={handleSetInterval}
            amount={`${amountValue / 100}`}
            merchantId={shopConfig?.apple_pay?.merchant_id}
            merchantName={shopConfig?.apple_pay?.display_name}
          />
        </Grid>
      )}
      {serverError && (
        <Grid item>
          <FormHelperText error>{serverError}</FormHelperText>
        </Grid>
      )}
      <Grid item display={{ md: 'none', sm: 'none', xs: 'none' }}>
        <CardFooter customStyles={widgetProps?.styles?.cardFooter} hide={widgetProps?.cardForm?.hideFooter} />
      </Grid>
      <Grid item>
        <Footer text={t('Платежи надежно защищены')} />
      </Grid>
    </Grid>
  );
}

import { Tiles } from '@mobily/stacks';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { ComponentType, useEffect, useState } from 'react';
import { Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Platform } from 'react-native';

import { TableSkeletonDesktop, TableSkeletonMobile } from '@iokanx/dashboard/ui';
import {
  IFindFulfilmentsRequestResolverParams,
  IGetFulfilmentByIdResponseData,
  exportReportsResolver,
} from '@iokanx/shared/data-access/api';
import { DATE_FORMAT } from '@iokanx/shared/data-access/constants';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { Button, DateRangePicker } from '@iokanx/shared/ui/universal';
import { useSessionStorageDate } from '@iokanx/shared/util';

import { saveFile } from '../file-saver/file-saver';
import { FulfilmentListDesktop } from '../fulfilment-list-desktop/fulfilment-list-desktop';
import { FulfilmentListMobile } from '../fulfilment-list-mobile/fulfilment-list-mobile';

export interface IFulfilmentListFormFieldValues {
  page: NonNullable<IFindFulfilmentsRequestResolverParams['page']>;
  from_dt: IFindFulfilmentsRequestResolverParams['from_dt'];
  to_dt: IFindFulfilmentsRequestResolverParams['to_dt'];
}

const FULFILMENT_LIST_LIMIT = 10;

export interface IFulfilmentListProps {
  onFulfilmentPress: (fulfilment: IGetFulfilmentByIdResponseData) => void;
  form: UseFormReturn<IFulfilmentListFormFieldValues>;
  pageLimit: number;
  filter: JSX.Element;
  isToday: boolean;
}

const RESPONSIVE_FULFILMENT_LIST: Record<Breakpoint, ComponentType<IFulfilmentListProps>> = {
  [Breakpoint.Desktop]: FulfilmentListDesktop,
  [Breakpoint.Mobile]: FulfilmentListMobile,
};

const RESPONSIVE_SKELETON: Record<Breakpoint, ComponentType> = {
  [Breakpoint.Desktop]: TableSkeletonDesktop,
  [Breakpoint.Mobile]: TableSkeletonMobile,
};

export function FulfilmentList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const { startDate, endDate } = useSessionStorageDate();
  const [isToday, setIsToday] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);

  const form = useForm<IFulfilmentListFormFieldValues>({
    defaultValues: {
      page: 1,
      from_dt: startDate || moment().subtract(1, 'month').startOf('day').toDate(),
      to_dt: endDate || moment().endOf('day').subtract(1, 'day').toDate(),
    },
  });

  function handleNavigateToFulfilment(fulfilment: IGetFulfilmentByIdResponseData) {
    //  do nothing on click fulfilment
  }
  const { mutate: exportReports, isLoading: isExporting } = useMutation(exportReportsResolver, {
    onSuccess: (data, variables) => {
      const startDate = moment(variables.from_dt).format(DATE_FORMAT);
      const endDate = moment(variables.to_dt).format(DATE_FORMAT);
      return saveFile({ data, fileName: `report_${startDate}-${endDate}.xlsx` });
    },
  });

  useEffect(() => {
    setIsToday(() =>
      [form.watch().from_dt, form.watch().to_dt]
        .map((date) => moment(date).format('YYYY-MM-DD'))
        .every((date) => date === moment().format('YYYY-MM-DD')),
    );
  }, [form.watch()]);

  const tags = [
    {
      title: 'Сегодня',
      startDate: moment().toDate(),
      endDate: moment().toDate(),
    },
    {
      title: 'Вчера',
      startDate: moment().subtract(1, 'day').toDate(),
      endDate: moment().subtract(1, 'day').toDate(),
    },
    {
      title: 'За неделю',
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate(),
    },
    {
      title: 'Текущий месяц',
      startDate: moment().set('date', 1).toDate(),
      endDate: moment().toDate(),
    },
    {
      title: 'Прошедний месяц',
      startDate: moment().subtract(1, 'month').set('date', 1).toDate(),
      endDate: moment().subtract(1, 'month').set('date', moment().subtract(1, 'month').daysInMonth()).toDate(),
    },
  ];

  function setTag(index: any, startDate: any, endDate: any) {
    const _endDate = moment(endDate).endOf('day').toDate();
    const _startDate = moment(startDate).startOf('day').toDate();

    setCurrentTag(index);
    form.setValue('to_dt', _endDate);
    form.setValue('from_dt', _startDate);
  }

  const filter = (
    <Tiles columns={3} space={2}>
      <ul className={'report-tags-filter'}>
        {tags.map((tag, index) => {
          return (
            <li
              key={index}
              className={index === currentTag ? 'active' : ''}
              onClick={() => {
                setTag(index, tag.startDate, tag.endDate);
              }}
            >
              {tag.title}
            </li>
          );
        })}
      </ul>
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          setCurrentTag(null);
          form.setValue('to_dt', values.endDate);
          form.setValue('from_dt', values.startDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
      {Platform.OS === 'web' && (
        <Button icon={{ name: 'file-excel' }} onPress={() => exportReports(form.watch())} loading={isExporting}>
          Выгрузить
        </Button>
      )}
    </Tiles>
  );

  const ResponsiveFulfilmentList = RESPONSIVE_FULFILMENT_LIST[currentBreakpoint];
  const ResponsiveSkeleton = RESPONSIVE_SKELETON[currentBreakpoint];

  return (
    <Suspense fallback={<ResponsiveSkeleton />}>
      <ResponsiveFulfilmentList
        isToday={isToday}
        filter={filter}
        onFulfilmentPress={handleNavigateToFulfilment}
        form={form}
        pageLimit={FULFILMENT_LIST_LIMIT}
      />
    </Suspense>
  );
}

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import Grid from '@mui/material/Grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  capturePaymentResolver,
  IFindPaymentByIdResult,
  ICapturePaymentResolverParams,
} from '@iokanx/shared/data-access/api';
import { useSnackbar } from '@iokanx/shared/feature';
import { Card, IconButton, TextField, SimpleCard, Button } from '@iokanx/shared/ui/universal';

export interface ICapturePaymentProps {
  payment: IFindPaymentByIdResult;
}

type ICapturePaymentFormFieldValues = Omit<ICapturePaymentResolverParams, 'orderId'>;

export function CapturePayment(props: ICapturePaymentProps) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const [isVisibleCapturePaymentForm, setVisibleCapturePaymentForm] = useState(false);
  function handleShowCapturePaymentForm() {
    setVisibleCapturePaymentForm(true);
  }
  function handleHideCapturePaymentForm() {
    setVisibleCapturePaymentForm(false);
  }

  const validationSchema = yup.object({
    amount: yup.number().required().min(1).max(props.payment.approvedAmount),
    reason: yup.string(),
  });
  const { control, handleSubmit } = useForm<ICapturePaymentFormFieldValues>({
    defaultValues: {
      amount: 0,
      reason: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutate: capturePayment, isLoading: isCapturing } = useMutation(capturePaymentResolver, {
    onSuccess: () => {
      snackbar.show('Списано', { type: 'success' });
      return queryClient.invalidateQueries(['payment']);
    },
  });

  const onSubmit: SubmitHandler<ICapturePaymentFormFieldValues> = (data) => {
    capturePayment({ orderId: props.payment.order_id, ...data });
  };

  if (isVisibleCapturePaymentForm) {
    return (
      <SimpleCard
        title={'Списание'}
        right={(props) => <IconButton {...props} icon="close" onPress={handleHideCapturePaymentForm} />}
      >
        <Card.Content>
          <Stack space={2}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState }) => (
                <TextField
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={String(value)}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  label={'Сумма'}
                />
              )}
              name={'amount'}
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  label={'Причина'}
                  helperText={'Необязательно'}
                />
              )}
              name={'reason'}
            />
          </Stack>
        </Card.Content>
        <Card.Actions>
          <Button onPress={handleSubmit(onSubmit)} loading={isCapturing} fullWidth>
            Списать
          </Button>
        </Card.Actions>
      </SimpleCard>
    );
  }

  return <Button onPress={handleShowCapturePaymentForm}>Списать</Button>;
}

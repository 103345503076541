import { Send } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import { Button as MaterialButton, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { FC, Suspense, useEffect, useState } from 'react';
import { ScrollView } from 'react-native';

import { getOrganizationByIdResolver } from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { PageLoader } from '@iokanx/shared/ui/universal';
import { getProfileFormStepLabels, VerticalStepper } from '@iokanx/shared/ui/web';

import { ConditionalComponent } from './ConditionalComponent';
import SkeletonComponent from './Skeleton';
import { ActivityTypesStep } from './steps/ActivityTypesStep/ActivityTypesStep';
import { AdditionalQuestionsStep } from './steps/AdditionalQuestionsStep/AdditionalQuestionsStep';
import { FileUploadStep } from './steps/FileUploadStep/FileUploadStep';
import { GovernmentConnectionStep } from './steps/GovernmentConnectionStep/GovernmentConnectionStep';
import { HolderStep } from './steps/HolderStep/HolderStep';
import { IntermediariesStep } from './steps/IntermediariesStep/IntermediariesStep';
import { MainBeneficiariesStep } from './steps/MainBeneficiariesStep/MainBeneficiariesStep';
import { OrganizationInfoStep } from './steps/OrganizationInfoStep/OrganizationInfoStep';
import { IFormSection } from './steps/OrganizationInfoStep/types';
import { ScreeningResultsStep } from './steps/ScreeningResultsStep/ScreeningResultsStep';
import { TurnoversStep } from './TurnoversStep/TurnoversStep';
import { useValidateSteps } from './useValidateSteps';

import { useMe } from '../../auth';
import { initializeSteps, isCompleted } from '../helpers/helpers';
import { ProfileFormProvider } from '../providers/ProfileFormProvider';
import { completeOrganizationFormResolver } from '../shared/data-access/organizations';
import { ProfileFormStatusStyles } from '../shared/styles';
import { StepsState } from '../shared/types';

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  marginTop: theme.spacing(1.5),
  marginLeft: theme.spacing(1),
  boxShadow: 'none',
  border: 'none',
}));

export const profileFormSteps: FC<IFormSection>[] = [
  OrganizationInfoStep,
  ActivityTypesStep,
  TurnoversStep,
  HolderStep,
  MainBeneficiariesStep,
  GovernmentConnectionStep,
  IntermediariesStep,
  AdditionalQuestionsStep,
  FileUploadStep,
  ScreeningResultsStep,
];

export const ProfileForm = ({ organizationId }: { organizationId: string }) => {
  const { me } = useMe();
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const queryClient = useQueryClient();
  const { validateSteps } = useValidateSteps(organizationId);

  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [errorStep, setErrorStep] = useState<StepsState>(initializeSteps(profileFormSteps));
  const [completed, setCompleted] = useState<StepsState>(initializeSteps(profileFormSteps));

  const { mutate: completeProfile } = useMutation(() => completeOrganizationFormResolver(organizationId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['organizations', organizationId]);
    },
  });

  const { data: organization, isFetching } = useQuery(
    ['organizations', organizationId],
    async () => await getOrganizationByIdResolver(organizationId),
  );

  useEffect(() => {
    setIsSubmitted(organization?.status === 'REVIEW');
  }, [organization]);

  useEffect(() => {
    const completionStepsState = Object.values(completed);

    setIsFormCompleted(isCompleted(completionStepsState));
  }, [completed]);

  useEffect(() => setActiveStep(0), [organizationId]);

  useEffect(() => validateSteps(completed), []);

  if (isFetching) return <PageLoader />;

  if (!organization) return <Typography>Error</Typography>;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleErrorStep = (index: number) => {
    setErrorStep((prevErrorState) => ({ ...prevErrorState, [index]: true }));
  };

  const handleComplete = (isCompleted: boolean) => {
    setCompleted((prevCompleted) => ({ ...prevCompleted, [activeStep]: isCompleted }));
  };

  const checkErrorSteps = () => {
    const updatedErrorStep = { ...errorStep };

    getProfileFormStepLabels(me, organization?.status).forEach((step: any, index: number) => {
      if (!completed[index]) {
        updatedErrorStep[index] = true;
      }
    });

    setErrorStep(updatedErrorStep);
  };

  const handleCompleteForm = () => {
    const completionStepsState = Object.values(completed);
    const isFormCompleted = isCompleted(completionStepsState);

    checkErrorSteps();

    if (isFormCompleted) {
      completeProfile();
      setIsSubmitted(isFormCompleted);
    }
  };

  const ProfileFormStep = profileFormSteps[activeStep];

  if (currentBreakpoint === Breakpoint.Mobile) {
    return <Typography padding={'14px'}>Доступна только десктопная версия</Typography>;
  }

  const profileLabels = getProfileFormStepLabels(me, organization?.status);

  return (
    <ProfileFormProvider isSubmitted={isSubmitted} organizationId={organizationId}>
      <ScrollView style={{ flex: 1 }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={4} paddingX={4} paddingY={2}>
              <VerticalStepper
                me={me}
                status={organization?.status}
                errorStep={errorStep}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                completed={completed}
              />

              <ConditionalComponent
                condition={isSubmitted}
                component={
                  <Alert severity="info" sx={ProfileFormStatusStyles}>
                    Заявка на рассмотрении
                  </Alert>
                }
              />

              <ConditionalComponent
                condition={!isSubmitted}
                component={
                  <Grid item sx={{ marginTop: '24px' }}>
                    <MaterialButton
                      disabled={!isFormCompleted}
                      sx={{ minWidth: '180px' }}
                      onClick={handleCompleteForm}
                      endIcon={<Send />}
                    >
                      {'отправить на проверку'}
                    </MaterialButton>
                  </Grid>
                }
              />
            </Grid>

            <Grid item xs={8} paddingX={4}>
              <Suspense fallback={<SkeletonComponent />}>
                <ProfileFormStep
                  organizationId={organizationId}
                  title={profileLabels[activeStep].label}
                  handleNext={profileLabels?.length - 1 !== activeStep && handleNext}
                  handleComplete={handleComplete}
                  handleErrorStep={handleErrorStep}
                />
              </Suspense>
            </Grid>
          </Grid>
        </Container>
      </ScrollView>
    </ProfileFormProvider>
  );
};

import moment from 'moment';

import { CURRENCY_SYMBOL, DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';

import { getPayoutById, IGetPayoutByIdResponseData } from '../../resources';
import { findShopByIdResolver, IFindShopIdResult } from '../shops/find-shop-by-id.resolver';

export interface IFindPayoutByIdResolverParams {
  payoutId: string;
}

export interface IFindPayoutByIdResolverResult extends IGetPayoutByIdResponseData {
  createdAt: string;
  shopId: string;
  externalId: string;
  amountWithSymbol: string;
}

export interface IFindDetailPayoutByIdResolverResult extends IFindPayoutByIdResolverResult {
  shop: IFindShopIdResult;
}

export async function findPayoutByIdResolver(
  params: IFindPayoutByIdResolverParams,
): Promise<IFindDetailPayoutByIdResolverResult> {
  const payout = await getPayoutById(params.payoutId);
  const shop = await findShopByIdResolver({ shopId: payout.shop_id });
  return { ...transformPayout(payout), shop };
}

export function transformPayout(payout: IGetPayoutByIdResponseData): IFindPayoutByIdResolverResult {
  const amount = payout.amount / 100;
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL[payout.currency]}`;
  return {
    ...payout,
    externalId: payout.external_id,
    shopId: payout.shop_id,
    amountWithSymbol: amountWithSymbol,
    createdAt: moment(payout.created_at).format(DATETIME_FORMAT),
  };
}

import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { refreshUserAuthLogic } from './refresh-user-auth-logic';
import { setShopAccessTokenInterceptor } from './set-shop-access-token-interceptor';

import { setAccessTokenInterceptor } from '../shared/interceptors/set-access-token-interceptor';
import { getHttpUrlRequestInterceptor } from '../shared/interceptors/set-http-url-request-interceptor';

export const backendHttp = axios.create();

backendHttp.interceptors.request.use(getHttpUrlRequestInterceptor('backendApiUrl'));
backendHttp.interceptors.request.use(setAccessTokenInterceptor);
backendHttp.interceptors.request.use(setShopAccessTokenInterceptor);
createAuthRefreshInterceptor(backendHttp, refreshUserAuthLogic);

import { DateCategory } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../instances';

export interface IGetAnalyticsEmittersRequestParams {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
  /** Категория временного отрезка */
  date_category?: DateCategory;
}

export type IGetAnalyticsEmittersResponseDataItem = [string, number];
export type IGetAnalyticsEmittersResponseData = IGetAnalyticsEmittersResponseDataItem[];

export async function getAnalyticsEmitters(
  params: IGetAnalyticsEmittersRequestParams,
): Promise<IGetAnalyticsEmittersResponseData> {
  const { data } = await backendHttp.get('/analytics/emitters', { params });
  return data;
}

import { http } from '../../../../instances';
import { IGetOrderByIdPaymentByIdResponseData } from '../payments/by-id';

export interface IPostOrdersByIdCancelRequestData {
  /**
   * Причина совершения операции
   *
   * <= 255 characters
   */
  reason?: string;
}

export type IPostOrdersByIdCancelResponseData = IGetOrderByIdPaymentByIdResponseData;

/** Отмена авторизованного платежа заказа */
export async function postOrdersByIdCancel(
  orderId: string,
  requestData: IPostOrdersByIdCancelRequestData,
): Promise<IPostOrdersByIdCancelResponseData> {
  const { data } = await http.post<IPostOrdersByIdCancelResponseData>(`/orders/${orderId}/cancel`, requestData);
  return data;
}

import { Button as MaterialButton, Container } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ScrollView } from 'react-native';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';
import { RadioButtons, Select } from '@iokanx/shared/ui/universal';

import { ConditionalComponent } from '../profile-form/components/ConditionalComponent';
import { parseOrganizationsType } from '../profile-form/components/steps/OrganizationInfoStep/helpers';
import { getBinaryItems } from '../profile-form/helpers/helpers';
import { useFormEdit } from '../profile-form/helpers/useFormEdit';
import { BinaryAnswer } from '../profile-form/shared/types';

export const CreateProfile = ({ shop }: { shop: any }) => {
  const queryClient = useQueryClient();

  const { data: types } = useQuery(['organization', 'types'], async () => {
    const { data } = await backendHttp.get('/dictionaries/organizations/types');
    return data;
  });

  const { mutate: createOrganization, isLoading: createOrganizationLoading } = useMutation(
    ['organization', 'creation'],
    async (formData: any) => {
      const { data } = await backendHttp.post('/organizations', {
        trade_name: shop.display_name,
        legal_name: shop.name,
        nib: shop.bin,
        organization_type_id: formData.organization_type_id,
      });

      return data;
    },
    {
      onSuccess: async (data: any) => {
        await createRelation(data?.id);
      },
    },
  );

  const { mutate: createRelation, isLoading: createRelationLoading } = useMutation(
    ['organization', 'shops', 'relation'],
    async (organizationId: string) => {
      const { data } = await backendHttp.post('/shops/organizations', {
        shop_id: shop.id,
        organization_id: organizationId,
      });

      return data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['shops']);
      },
    },
  );

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      is_ready: '',
      organization_type_id: '',
    },
  });

  const onSubmit = (data: any) => createOrganization(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress } = useFormEdit(false, handleSubmit(onSubmit, onError));

  if (createRelationLoading || createOrganizationLoading) {
    return <h1>loading</h1>;
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      <Container>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Item>
              <RadioButtons
                label={'Получить доступ к созданию профиля?'}
                onValueChange={onChange}
                value={value}
                items={getBinaryItems(false)}
              />
            </Item>
          )}
          name={'is_ready'}
        />

        <ConditionalComponent
          condition={watch('is_ready') === BinaryAnswer.YES && !isEmpty(types)}
          component={
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Item>
                  <Select
                    onChange={onChange}
                    label={'Тип организации'}
                    value={value}
                    items={parseOrganizationsType(types)}
                    style={{ width: '100%' }}
                    disabled={false}
                  />
                </Item>
              )}
              name={'organization_type_id'}
            />
          }
        />

        <ConditionalComponent
          condition={Boolean(watch('organization_type_id'))}
          component={
            <Item>
              <MaterialButton
                sx={{ padding: '8px 12px', minWidth: 'fit-content' }}
                disabled={isDisable}
                onClick={handlePress}
                fullWidth={false}
              >
                Получить доступ к созданию профиля
              </MaterialButton>
            </Item>
          }
        />
      </Container>
    </ScrollView>
  );
};

import moment from 'moment';

import { PAYMENT_SYSTEM_COLOR, PaymentSystem } from '@iokanx/shared/data-access/constants';

import {
  getAnalyticsPaymentSystems,
  IGetAnalyticsPaymentSystemsRequestParams,
  IGetAnalyticsPaymentSystemsResponseDataItem,
} from '../../resources';

export type IGetPaymentSystemAnalyticsResolverParams = IGetAnalyticsPaymentSystemsRequestParams;

export interface IGetPaymentSystemAnalyticsResolverResultItem {
  /** Название платежной системы */
  paymentSystem: PaymentSystem;
  title: string;
  /** Количество платежей */
  count: number;
  color: string;
}
export type IGetPaymentSystemAnalyticsResolverResult = IGetPaymentSystemAnalyticsResolverResultItem[];

export async function getPaymentSystemAnalyticsResolver(
  _params: IGetPaymentSystemAnalyticsResolverParams,
): Promise<IGetPaymentSystemAnalyticsResolverResult> {
  const params = { ..._params };
  if (params.from_dt && !params.to_dt) {
    params.to_dt = moment(params.from_dt).endOf('day').toDate();
  }

  const analytics = await getAnalyticsPaymentSystems(params);

  return analytics.map(transformGetAnalyticsPaymentSystemsItem).sort((a, b) => b.count - a.count);
}

export function transformGetAnalyticsPaymentSystemsItem([
  paymentSystem,
  count,
]: IGetAnalyticsPaymentSystemsResponseDataItem): IGetPaymentSystemAnalyticsResolverResultItem {
  const color = PAYMENT_SYSTEM_COLOR[paymentSystem];
  const title = paymentSystem === 'not defined' ? 'Другие' : paymentSystem;

  return { paymentSystem, count, color, title };
}

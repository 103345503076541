import { Stack } from '@mui/material';
import { DataGrid, GridColumns, ruRU } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { ScrollView } from 'react-native';

import { PayoutStatusChip, ShopNameChip } from '@iokanx/dashboard/ui';
import { IGetPayoutByIdResponseData, searchPayoutsResolver } from '@iokanx/shared/data-access/api';
import { ACQUIRER_I18N } from '@iokanx/shared/data-access/constants';

import { useMe } from '../auth';
import { IPayoutListProps } from '../payout-list/payout-list';

const columns: GridColumns<IGetPayoutByIdResponseData> = [
  {
    headerName: 'Дата создания',
    field: 'createdAt',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Магазин',
    field: 'shopId',
    renderCell: (props) => <ShopNameChip shopId={props.value} />,
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Получатель',
    field: 'recipientName',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'IBAN',
    field: 'recipientIban',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Эквайер',
    field: 'acquirer',
    flex: 1,
    renderCell: (props) => {
      // @ts-ignore
      return ACQUIRER_I18N[props.value];
    },
    sortable: false,
  },
  {
    headerName: 'ID выплаты',
    field: 'id',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Статус',
    field: 'status',
    flex: 1,
    renderCell: (props) => <PayoutStatusChip status={props.value} />,
    sortable: false,
  },
  {
    headerName: 'Сумма',
    field: 'amountWithSymbol',
    flex: 1,
    sortable: false,
  },
];

const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам, выплат не найдено',
};

export function PayoutListDesktop(props: IPayoutListProps) {
  const { data: payouts, isFetching } = useQuery(['payouts', props.form.watch()], () =>
    searchPayoutsResolver({ ...props.form.watch(), limit: props.pageLimit }),
  );

  const { me } = useMe();

  return (
    <ScrollView>
      <Stack padding={2} spacing={2} marginBottom={8}>
        {props.filter}
        <DataGrid
          rows={payouts?.data || []}
          columnVisibilityModel={{
            shopId: !!me?.is_superuser,
          }}
          columns={columns}
          loading={isFetching}
          page={props.form.watch('page') - 1}
          onRowClick={(params) => props.onPayoutPress(params.row)}
          onPageChange={(page) => props.form.setValue('page', page + 1)}
          rowCount={payouts?.count}
          localeText={localeText}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          pageSize={props.pageLimit}
          rowsPerPageOptions={[props.pageLimit]}
          paginationMode="server"
        />
      </Stack>
    </ScrollView>
  );
}

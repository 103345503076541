import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColumns, ruRU } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { ScrollView } from 'react-native';

import { ShopNameChip } from '@iokanx/dashboard/ui';
import { IFindReviseResolverResultItem, searchRevisesResolver } from '@iokanx/shared/data-access/api';

import { useMe } from '../auth';
import { IReviseListProps } from '../revise-list/revise-list';

const columns: GridColumns<IFindReviseResolverResultItem> = [
  {
    headerName: 'Дата операции',
    field: 'dateTime',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Эквайер',
    field: 'acquirer',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Референс',
    field: 'reference',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Магазин',
    field: 'shopId',
    flex: 1,
    renderCell: (props) => {
      return (
        props.value && (
          <Suspense fallback={<Typography>loading...</Typography>}>
            <ShopNameChip shopId={props.value} />
          </Suspense>
        )
      );
    },
    sortable: false,
  },
  {
    headerName: 'Сумма',
    field: 'bankAmountWithSymbol',
    flex: 1,
    sortable: false,
    valueGetter: function (params) {
      return params.row.bankAmountWithSymbol;
    },
  },
  {
    headerName: 'Комиссия общая',
    field: 'bankProcessingFeeWithSymbol',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Комиссия эквайера',
    field: 'bankAcquirerFeeWithSymbol',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Разница суммы',
    field: 'diffAmountWithSymbol',
    flex: 1,
    sortable: false,
    renderCell: (props) => {
      // TODO create separate component
      return (
        props.value && (
          <span
            style={
              // тупость сделал на быструю руку
              { color: props.value === '0 ₸' ? '#10b981' : props.value === '- ₸' ? '#334155' : '#ef4444' }
            }
          >
            {props.value}
          </span>
        )
      );
    },
  },
  {
    headerName: 'Разница ком.банка',
    field: 'diffAcquirerFeeWithSymbol',
    flex: 1,
    sortable: false,
    renderCell: (props) => {
      // TODO create separate component
      return (
        props.value && (
          <span
            style={
              // тупость сделал на быструю руку
              { color: props.value === '0 ₸' ? '#10b981' : props.value === '- ₸' ? '#334155' : '#ef4444' }
            }
          >
            {props.value}
          </span>
        )
      );
    },
  },
  {
    headerName: 'Дата возмещения',
    field: 'bankReceiptDate',
    flex: 1,
    sortable: false,
  },
];

const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам, сверка не найдено',
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  textAlign: 'left',
  borderRadius: 0,
  boxShadow: 'none',
  brevise: 'none',
}));

export function ReviseListDesktop(props: IReviseListProps) {
  const { data: revises, isFetching } = useQuery(['revises', props.form.watch(), props.selectedShopId], () =>
    searchRevisesResolver({ ...props.form.watch(), limit: props.pageLimit, shop_id: props.selectedShopId }),
  );

  const { me } = useMe();

  return (
    <ScrollView>
      <Stack padding={2} spacing={2}>
        {props.filter}
        <DataGrid
          rows={revises?.data || []}
          columns={columns}
          loading={isFetching}
          page={props.form.watch('page') - 1}
          onRowClick={(params) => props.onRevisePress(params.row)}
          onPageChange={(page) => props.form.setValue('page', page + 1)}
          rowCount={revises?.count}
          localeText={localeText}
          getRowId={(row) => {
            return row.reference;
          }}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          pageSize={props.pageLimit}
          rowsPerPageOptions={[props.pageLimit]}
          paginationMode="server"
        />
      </Stack>
      {revises && revises.stats && (
        <Stack padding={2} spacing={0} marginBottom={8}>
          <Grid container>
            <Grid item xs={4}>
              <Item>Реестр банка эквайера</Item>
              <Item>Сумма: {revises.stats.totalBankAmountWithSymbol}</Item>
              <Item>Комиссия: {revises.stats.totalBankProcessingFeeWithSymbol}</Item>
              <Item>Комиссия эквайера: {revises.stats.totalBankAcquirerFeeWithSymbol}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>Реестра нашей системы</Item>
              <Item>Сумма: {revises.stats.totalAmountWithSymbol}</Item>
              <Item>Комиссия: {revises.stats.totalProcessingFeeWithSymbol}</Item>
              <Item>Комиссия эквайера: {revises.stats.totalAcquirerFeeWithSymbol}</Item>
            </Grid>
            <Grid item xs={4}>
              <Item>Разница</Item>
              <Item>Сумма: {revises.stats.totalDiffAmountWithSymbol}</Item>
              <Item>Комиссия: - </Item>
              <Item>Комиссия эквайера: {revises.stats.totalDiffAmountWithSymbol}</Item>
            </Grid>
          </Grid>
        </Stack>
      )}
    </ScrollView>
  );
}

import { TextField as MuiTextField, TextFieldProps, OutlinedInputProps, InputAdornment } from '@mui/material';
import { ChangeEventHandler, ComponentProps, InputHTMLAttributes } from 'react';
import { KeyboardTypeOptions } from 'react-native';
import { TextInput } from 'react-native-paper';

import { Icon, IIconProps } from '../icon/icon';

export type ITextFieldIconProps = Pick<IIconProps, 'name' | 'onPress'>;
export interface ITextFieldProps
  extends Pick<
      ComponentProps<typeof TextInput>,
      | 'value'
      | 'onBlur'
      | 'onChangeText'
      | 'label'
      | 'error'
      | 'keyboardType'
      | 'editable'
      | 'autoComplete'
      | 'textContentType'
      | 'autoCapitalize'
      | 'secureTextEntry'
      | 'multiline'
      | 'numberOfLines'
      | 'onFocus'
      | 'disabled'
    >,
    Pick<TextFieldProps, 'helperText' | 'fullWidth'> {
  rightIcon?: ITextFieldIconProps;
  leftIcon?: ITextFieldIconProps;
}

export function TextField(props: ITextFieldProps) {
  const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    props.onChangeText?.(event.target.value);
  };

  return (
    <MuiTextField
      value={props.value}
      onBlur={props.onBlur}
      onChange={handleChange}
      label={props.label}
      error={props.error}
      helperText={props.helperText}
      InputProps={prepareInputProps(props)}
      autoComplete={props.autoComplete}
      autoCapitalize={props.autoCapitalize}
      multiline={props.multiline}
      rows={props.numberOfLines}
      onFocus={props.onFocus}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    />
  );
}

export type IPrepareInputPropsParams = Pick<
  ITextFieldProps,
  'keyboardType' | 'editable' | 'rightIcon' | 'leftIcon' | 'secureTextEntry'
>;
function prepareInputProps(params: IPrepareInputPropsParams): OutlinedInputProps {
  const inputProps: OutlinedInputProps = {};

  if (params.rightIcon) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <Icon {...params.rightIcon} />
      </InputAdornment>
    );
  }

  if (params.leftIcon) {
    inputProps.startAdornment = (
      <InputAdornment position="start">
        <Icon {...params.leftIcon} />
      </InputAdornment>
    );
  }

  const parsedKeyboardType = parseKeyboardType(params.keyboardType);

  if (params.secureTextEntry) {
    parsedKeyboardType.type = 'password';
  }

  return { ...inputProps, ...parsedKeyboardType, readOnly: !(params.editable ?? true) };
}

export type IParseKeyboardTypeResult = Pick<InputHTMLAttributes<unknown>, 'inputMode' | 'type'>;

function parseKeyboardType(keyboardType?: KeyboardTypeOptions): IParseKeyboardTypeResult {
  let type: IParseKeyboardTypeResult['type'];
  let inputMode: IParseKeyboardTypeResult['inputMode'];

  switch (keyboardType) {
    case 'email-address':
      type = 'email';
      break;
    case 'number-pad':
    case 'numeric':
      inputMode = 'numeric';
      break;
    case 'decimal-pad':
      inputMode = 'decimal';
      break;
    case 'phone-pad':
      type = 'tel';
      break;
    case 'web-search':
      type = 'search';
      break;
    case 'url':
      type = 'url';
      break;
    default:
      type = 'text';
  }

  return { type, inputMode };
}

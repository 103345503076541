import { DateCategory, PaymentStatus } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../instances';

export interface IGetAnalyticsPaymentStatusesRequestParams {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
  /** Категория временного отрезка */
  date_category?: DateCategory;
}

export interface IGetAnalyticsPaymentStatusesResponseDataItem {
  /** Статус платежа */
  status: PaymentStatus;
  /** Количество платежей с указанным статусом */
  count: number;
}
export type IGetAnalyticsPaymentStatusesResponseData = IGetAnalyticsPaymentStatusesResponseDataItem[];

export async function getAnalyticsPaymentStatuses(
  params: IGetAnalyticsPaymentStatusesRequestParams,
): Promise<IGetAnalyticsPaymentStatusesResponseData> {
  const { data } = await backendHttp.get<IGetAnalyticsPaymentStatusesResponseData>('/analytics/payment-statuses', {
    params,
  });
  return data;
}

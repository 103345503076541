import { InputAdornment, InputBaseComponentProps } from '@mui/material';
import { isString } from 'lodash';
import { forwardRef } from 'react';
import { IMask, IMaskInput } from 'react-imask';

import { AVAILABLE_PAYMENT_SYSTEMS, DEFAULT_CARD_PLACEHOLDER } from '@iokanx/shared/data-access/constants';
import { getPaymentSystemsFromCardNumber } from '@iokanx/shared/util';

import { PaymentSystemLogoList } from '../payment-system-logo-list/payment-system-logo-list';
import { IStyledTextFieldProps, StyledTextField } from '../styled-text-field/styled-text-field';

const MASK = '0000 00●● ●●●● 0000';
const PLACEHOLDER = IMask.createMask({ mask: '0000 00●● ●●●● 0000' }).resolve(DEFAULT_CARD_PLACEHOLDER.pan);

const MaskInput = forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) => (
  <IMaskInput
    {...props}
    mask={MASK}
    onAccept={(value) =>
      props.onChange?.({
        target:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { value },
      })
    }
  />
));

export function MaskedCardNumberField(props: IStyledTextFieldProps) {
  const paymentSystems = isString(props.value)
    ? getPaymentSystemsFromCardNumber(props.value)
    : AVAILABLE_PAYMENT_SYSTEMS;

  return (
    <StyledTextField
      InputProps={{
        inputComponent: MaskInput,
        endAdornment: (
          <InputAdornment position={'end'}>
            <PaymentSystemLogoList paymentSystems={paymentSystems} />
          </InputAdornment>
        ),
      }}
      inputMode="numeric"
      placeholder={PLACEHOLDER}
      {...props}
    />
  );
}

import { DateCategory, OrderStatus } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../instances';

export type IGetExportDashboardPaymentsRequestParams = {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
  /** Виды фильтров по дате */
  date_category?: DateCategory;
  /** Статус заказа */
  order_status?: OrderStatus;
};

export type IGetExportDashboardPaymentsResponseData = Blob;

export async function getExportDashboardPayments(
  params: IGetExportDashboardPaymentsRequestParams,
): Promise<IGetExportDashboardPaymentsResponseData> {
  const { data } = await backendHttp.get<IGetExportDashboardPaymentsResponseData>('/dashboard/payments/export', {
    params,
    responseType: 'blob',
  });
  return data;
}

import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import AppLoading from 'expo-app-loading';
import { useRef } from 'react';

import {
  Amplitude,
  dashboardLinking,
  DashboardStack,
  DashboardStackParamList,
  useIsSignedIn,
  useMe,
} from '@iokanx/dashboard/feature';
import { OPERATIONS_PAGES } from '@iokanx/dashboard/pages';
import AddEmployeePage from '@iokanx/dashboard/pages/add-employee-page/add-employee-page';
import ConfigPage from '@iokanx/dashboard/pages/config-page/config-page';
import CreateOrderPage from '@iokanx/dashboard/pages/create-order-page/create-order-page';
import CreatePaymentLinkPage from '@iokanx/dashboard/pages/create-payment-link-page/create-payment-link-page';
import CreatePayoutPage from '@iokanx/dashboard/pages/create-payout-page/create-payout-page';
import CreateShopPage from '@iokanx/dashboard/pages/create-shop-page/create-shop-page';
import CreateUserPage from '@iokanx/dashboard/pages/create-user-page/create-user-page';
import ForgotPasswordPage from '@iokanx/dashboard/pages/forgot-password-page/forgot-password-page';
import FulfilmentPage from '@iokanx/dashboard/pages/fulfilment-page/fulfilment-page';
import IncomePage from '@iokanx/dashboard/pages/income-page/income-page';
import OrderPage from '@iokanx/dashboard/pages/order-page/order-page';
import PayoutPage from '@iokanx/dashboard/pages/payout-page/payout-page';
import RegistrationPage from '@iokanx/dashboard/pages/registration-page/registration-page';
import ResetPasswordPage from '@iokanx/dashboard/pages/reset-password-page/reset-password-page';
import RevisePage from '@iokanx/dashboard/pages/revise-page/revise-page';
import ShopPage from '@iokanx/dashboard/pages/shop-page/shop-page';
import SignInPage from '@iokanx/dashboard/pages/sign-in-page/sign-in-page';
import UserPage from '@iokanx/dashboard/pages/user-page/user-page';
import VotePage from '@iokanx/dashboard/pages/vote-page/vote-page';
import { useCombinedTheme, useDrawerType } from '@iokanx/shared/feature';
import { CDNImage, PageLoader } from '@iokanx/shared/ui/universal';

import DashboardDrawerNavigator from './dashboard-drawer-navigator/dashboard-drawer-navigator';

export function NavigationProvider() {
  const combinedTheme = useCombinedTheme();
  const drawerType = useDrawerType();
  const navigationRef = useNavigationContainerRef<DashboardStackParamList>();
  const routeNameRef = useRef<string>();
  const { me } = useMe();
  const { isSignedIn, isSignedInLoading } = useIsSignedIn();
  if (isSignedInLoading) return <AppLoading />;

  return (
    <NavigationContainer
      theme={combinedTheme}
      linking={dashboardLinking}
      fallback={<PageLoader />}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      onStateChange={() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;
        if (previousRouteName && previousRouteName !== currentRouteName) {
          switch (currentRouteName) {
            case 'Payments':
              if (OPERATIONS_PAGES.includes(previousRouteName)) {
                Amplitude.logEventWithPropertiesAsync('operation_payments', { user_id: me?.id });
              }
              break;
            case 'Refunds':
              if (OPERATIONS_PAGES.includes(previousRouteName)) {
                Amplitude.logEventWithPropertiesAsync('operation_refunds', { user_id: me?.id });
              }
              break;
            case 'PaymentLinks':
              if (OPERATIONS_PAGES.includes(previousRouteName)) {
                Amplitude.logEventWithPropertiesAsync('operation_payment_links', { user_id: me?.id });
              }
              break;
            case 'Analytics':
              Amplitude.logEventWithPropertiesAsync('menu_analytics', { user_id: me?.id });
              break;
            case 'Shops':
              Amplitude.logEventWithPropertiesAsync('menu_staff', { user_id: me?.id });
              break;
            case 'Employees':
              Amplitude.logEventWithPropertiesAsync('menu_staff', { user_id: me?.id });
              break;
            case 'Contacts':
              Amplitude.logEventWithPropertiesAsync('menu_contacts', { user_id: me?.id });
              break;
          }
        }
        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
    >
      <DashboardStack.Navigator>
        {isSignedIn ? (
          <DashboardStack.Group
            screenOptions={
              drawerType === 'permanent'
                ? {
                    presentation: 'transparentModal',
                    headerShown: false,
                  }
                : {
                    presentation: 'modal',
                    headerShadowVisible: false,
                  }
            }
          >
            <DashboardStack.Screen
              name={'Drawer'}
              options={{ headerShown: false }}
              component={DashboardDrawerNavigator}
            />
            <DashboardStack.Screen
              name={'AddEmployee'}
              component={AddEmployeePage}
              options={{ title: 'Новый сотрудник' }}
            />
            {/*<DashboardStack.Screen*/}
            {/*  name={'AddShop'}*/}
            {/*  component={ShopPage}*/}
            {/*  options={{ title: 'Новый мерчант' }}*/}
            {/*/>*/}
            <DashboardStack.Screen
              name={'Shop'}
              component={ShopPage}
              options={(props) => ({ title: `Магазин ${props.route.params.shopId}` })}
            />
            <DashboardStack.Screen
              name={'User'}
              component={UserPage}
              options={(props) => ({ title: `Пользователь ${props.route.params.userId}` })}
            />
            <DashboardStack.Screen
              name={'Vote'}
              component={VotePage}
              options={(props) => ({ title: `Голос ${props.route.params.voteId}` })}
            />
            <DashboardStack.Screen
              name={'Config'}
              component={ConfigPage}
              options={(props) => ({ title: `Конфигурация магазина ${props.route.params.shopId}` })}
            />
            {/*<DashboardStack.Screen*/}
            {/*  name={'Profile'}*/}
            {/*  component={ProfilePage}*/}
            {/*/>*/}
            <DashboardStack.Screen
              name={'Order'}
              component={OrderPage}
              options={(props) => ({ title: `Заказ ${props.route.params.orderId}` })}
            />
            <DashboardStack.Screen
              name={'CreateOrder'}
              options={{ title: 'Новый заказ' }}
              component={CreateOrderPage}
            />
            <DashboardStack.Screen
              name={'CreateShop'}
              options={{ title: 'Новый магазин' }}
              component={CreateShopPage}
            />
            <DashboardStack.Screen
              name={'CreateUser'}
              options={{ title: 'Новый пользователь' }}
              component={CreateUserPage}
            />
            <DashboardStack.Screen
              name={'CreatePaymentLink'}
              options={{ title: 'Новая ссылка на оплату' }}
              component={CreatePaymentLinkPage}
            />
            <DashboardStack.Screen
              name={'CreatePayout'}
              options={{ title: 'Новая выплата' }}
              component={CreatePayoutPage}
            />
            <DashboardStack.Screen
              name={'Payout'}
              component={PayoutPage}
              options={(props) => ({ title: `Выплата ${props.route.params.payoutId}` })}
            />
            <DashboardStack.Screen
              name={'Revise'}
              component={RevisePage}
              options={(props) => ({ title: `Сверка ${props.route.params.reference}` })}
            />
            <DashboardStack.Screen
              name={'Income'}
              component={IncomePage}
              options={(props) => ({ title: `Поступление ${props.route.params.incomeId}` })}
            />
            <DashboardStack.Screen
              name={'Fulfilment'}
              component={FulfilmentPage}
              options={(props) => ({ title: `Выгрузка ${props.route.params.payoutId}` })}
            />
          </DashboardStack.Group>
        ) : (
          <DashboardStack.Group
            screenOptions={{
              headerShadowVisible: false,
              headerTitle: () => <CDNImage src="ioka-logo.svg" width={54} height={24} />,
              headerLeft: () => null,
              headerBackVisible: false,
              headerTitleAlign: 'center',
            }}
          >
            <DashboardStack.Screen name={'SignIn'} component={SignInPage} options={{ title: 'Вход' }} />
            <DashboardStack.Screen
              name={'ForgotPassword'}
              component={ForgotPasswordPage}
              options={{ title: 'Восстановление пароля' }}
            />
            <DashboardStack.Screen
              name={'ResetPassword'}
              component={ResetPasswordPage}
              options={{ title: 'Сброс пароля' }}
            />
            <DashboardStack.Screen
              name={'Registration'}
              component={RegistrationPage}
              options={{ title: 'Регистрация' }}
            />
          </DashboardStack.Group>
        )}
      </DashboardStack.Navigator>
    </NavigationContainer>
  );
}

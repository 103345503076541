import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';

import { buildSharesRoute, filterOwnerData, formatDateTime } from './helpers';

import { useFormEdit } from '../../../helpers/useFormEdit';
import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { DatePickerYear } from '../../Datepicker';
import { FormField } from '../../FormField/FormField';
import { FormSelectField } from '../../FormSelectField/FormSelectField';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { getPhysicalPersonalData } from '../../PersonalData';
import { organizationHolderValidationSchema } from '../OrganizationInfoStep/model/schemas';

const parseShare = (data: any) => {
  if (!data) return;

  const { personal_data } = data;
  const { created_at, id, ...formData } = personal_data;

  return {
    ...formData,
  };
};

const postData = async ({ organizationId, share, ...data }: any) => {
  const { data: response } = await backendHttp.post(buildSharesRoute(organizationId), {
    is_holder: true,
    personal_data: {
      ...data,
      is_resident: data.is_resident === 'true',
      issue_date: formatDateTime(new Date(data.issue_date)),
      type: 'PHYSICAL',
    },
  });

  return response;
};

const patchData = async ({ organizationId, shareId, share, ...data }: any) => {
  const { data: response } = await backendHttp.patch(buildSharesRoute(organizationId, shareId), {
    type: 'PHYSICAL',
    is_holder: true,
    personal_data: {
      ...data,
      type: 'PHYSICAL',
      is_resident: data.is_resident === 'true',
      issue_date: formatDateTime(new Date(data.issue_date)),
    },
  });

  return response;
};

export const OwnerProfileSection = ({ updateChildrenCompletionStatus }: any) => {
  const { organizationId, isSubmitted } = useProfileFormContext();
  const [holder, setHolder] = useState<any>();

  const { data: shares } = useQuery(['shares'], async () => {
    try {
      const { data } = await backendHttp.get(buildSharesRoute(organizationId));

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  });

  const { mutate: mutateHolder } = useMutation(
    holder
      ? (formData: any) => patchData({ organizationId, shareId: holder.id, ...formData })
      : (formData: any) => postData({ organizationId, ...formData }),
    {
      onSuccess: (data: any) => {
        reset(parseShare(data));
        handleIsEdit();
      },
    },
  );

  const { control, reset, handleSubmit } = useForm({
    defaultValues: getPhysicalPersonalData(),
    resolver: yupResolver(organizationHolderValidationSchema),
  });

  useEffect(() => {
    if (!isEmpty(shares)) {
      const holderData = filterOwnerData(shares);

      setHolder(holderData);
      reset(parseShare(holderData));
    }
  }, [shares]);

  const onSubmit = (data: any) => mutateHolder(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(holder), handleSubmit(onSubmit, onError));

  useEffect(() => {
    updateChildrenCompletionStatus('Данные о Руководстве', isDisable);
  }, [isDisable]);

  return (
    <ProfileFormSection
      isSubmitted={isSubmitted}
      title={'Данные о Руководстве/Исполнительного органа, организации/мерчанта'}
      isSaved={isDisable}
      handleSubmit={handlePress}
    >
      <FormField name={'first_name'} control={control} label={'Имя'} disabled={isDisable} />
      <FormField name={'last_name'} control={control} label={'Фамилия'} disabled={isDisable} />
      <FormField name={'patronymic'} control={control} label={'Отчество'} disabled={isDisable} />
      <FormField name={'nib'} control={control} label={'ИИН'} disabled={isDisable} />

      <FormSelectField
        control={control}
        name={'is_resident'}
        disabled={isDisable}
        label={'Резиденство'}
        items={[
          { value: 'true', title: 'Является резидентом' },
          { value: 'false', title: 'Не является резидентом' },
        ]}
      />

      <FormField name={'number'} control={control} label={'№ Удостоверяющего документа'} disabled={isDisable} />
      <FormField name={'issued_by'} control={control} label={'Кем выдан'} disabled={isDisable} />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Item>
              <DatePickerYear
                disabled={isDisable}
                label="Дата выдачи"
                value={value && new Date(value)}
                onChange={onChange}
              />
              {/*<DatePicker*/}
              {/*  disabled={isDisable}*/}
              {/*  label="Дата выдачи"*/}
              {/*  value={value && new Date(value)}*/}
              {/*  onChange={onChange}*/}
              {/*/>*/}
            </Item>
          );
        }}
        name={'issue_date'}
      />
    </ProfileFormSection>
  );
};

import { Currency, CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';
import { prepareDateTime } from '@iokanx/shared/util';

import { getOrderHistories, IGetCommandResponseDataItem, IGetOrderHistoriesResponseDataItem } from '../../resources';
import { PAYMENT_METHODS } from '../payments/find-payment-by-id.resolver';

export interface IGetOrderHistoriesResolverParams {
  orderId: string;
}

export interface IFindHistoryResult extends IGetOrderHistoriesResponseDataItem {
  approvedAmountWithSymbol: string;
  capturedAmountWithSymbol: string;
  refundedAmountWithSymbol: string;
  processingFeeWithSymbol: string;
  createdAt: string;
  paymentMethod?: string;
  commandList?: IFindCommandResult[];
}

export interface IFindCommandResult extends IGetCommandResponseDataItem {
  amountWithSymbol: string;
  createdAt: string;
}

export async function getOrderHistoriesResolver(
  params: IGetOrderHistoriesResolverParams,
): Promise<IFindHistoryResult[]> {
  const payments = await getOrderHistories(params.orderId);
  return payments.map(transformHistory);
}

function transformHistory(payment: IGetOrderHistoriesResponseDataItem): IFindHistoryResult {
  const approvedAmount = payment.approved_amount / 100;
  const capturedAmount = payment.captured_amount / 100;
  const refundedAmount = payment.refunded_amount / 100;
  const processingFee = payment.processing_fee / 100;
  const commandList = payment.commands.map(transformCommand);

  const paymentMethod = payment.payer?.type && PAYMENT_METHODS[payment.payer.type];
  return {
    ...payment,
    approvedAmountWithSymbol: `${approvedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    capturedAmountWithSymbol: `${capturedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    refundedAmountWithSymbol: `${refundedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    processingFeeWithSymbol: `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    createdAt: prepareDateTime(payment.created_at),
    paymentMethod,
    commandList: commandList,
  };
}

function transformCommand(command: IGetCommandResponseDataItem): IFindCommandResult {
  const amount = command.amount / 100;
  return {
    ...command,
    amountWithSymbol: `${amount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    createdAt: prepareDateTime(command.created_at),
  };
}

import { Box, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import classNames from 'classnames';
import { includes, isNumber, isString, last } from 'lodash';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { IQueryParamsType, IZoidProps } from '@iokanx/payment-form/types';
import {
  AviataTimeoutView,
  Button,
  CustomHead,
  FormLayout,
  PaymentFormLoader,
  ResultView,
  ResultViewAmway,
} from '@iokanx/payment-form/ui';
import {
  findConstructorByShopIdResolver,
  findOrderByIdResolver,
  findShopByIdResolver,
  IFindOrderEventsResolverResultItem,
  ISearchOrderItem,
} from '@iokanx/shared/data-access/api';
import {
  InstallmentStatus,
  OrderEventName,
  OrderState,
  OrderStatus,
  PaymentFormTemplate,
} from '@iokanx/shared/data-access/constants';
import { HttpErrorCode, IHttpError } from '@iokanx/shared/data-access/types';
import { getAmountWithCurrency, isExpiredDate, isExpiredDateGlobal } from '@iokanx/shared/util';

import { AviataPaymentFormContainer } from '../aviata-payment-form-container/aviata-payment-form-container';
import { DeclinedPageAmway } from '../declined-page-amway/declined-page-amway';
import { DeclinedPage } from '../declined-page/declined-page';
import { OrderInfoAmway } from '../order-info-amway/order-info-amway';
import { OrderInfo } from '../order-info/order-info';
import { PaymentFormContainer } from '../payment-form-container/payment-form-container';
import { PaymentStateContext } from '../payment-state/payment-state';
import { ResultViewAviata } from '../result-view-aviata/result-view-aviata';
import { SuccessPageAmway } from '../success-page-amway/success-page-amway';
import { SuccessPage } from '../success-page/success-page';
import { useShopConfig } from '../use-shop-config/use-shop-config';

export interface IMainOrderContainerProps {
  orderId: string;
  isWidget: boolean;
  widgetOptions: IZoidProps | null;
  queryParams: IQueryParamsType;
}

const customTemplates: string[] = [
  PaymentFormTemplate.CUSTOM_AVIATA,
  PaymentFormTemplate.CUSTOM_AVIATA_APPLE_PAY,
  PaymentFormTemplate.CUSTOM_AVIATA_GOOGLE_PAY,
  PaymentFormTemplate.CUSTOM_AVIATA_RW,
  PaymentFormTemplate.CUSTOM_CHOCOTRAVEL,
  PaymentFormTemplate.CUSTOM_CHOCOTRAVEL_APPLE_PAY,
  PaymentFormTemplate.CUSTOM_CHOCOTRAVEL_GOOGLE_PAY,
];

const FROM_3DS_PARAM = 'from3ds';

export function MainOrderContainer({
  orderId,
  isWidget = false,
  widgetOptions,
  queryParams,
}: IMainOrderContainerProps) {
  const { t } = useTranslation();

  const [state, setState] = useState<OrderState | InstallmentStatus>(OrderState.LOADING);
  const [infoInterval, setInfoInterval] = useState<number | false>(false);
  const [lastEvent, setLastEvent] = useState<IFindOrderEventsResolverResultItem | null>(null);
  const [isHookFired, fireHook] = useState<boolean>(false);
  const router = useRouter();
  const onError = useCallback(
    (e: unknown): void => {
      if (state === OrderState.PAYMENT_ACTION_REQUIRED) return;
      if (axios.isAxiosError(e) && e?.response?.status === 404) {
        setState(OrderState.NOT_FOUND);
      } else {
        setState(OrderState.ERROR);
      }
    },
    [state],
  );

  const [isFrom3ds] = useState(router.query[FROM_3DS_PARAM] === 'true');
  useEffect(() => {
    if (router.query[FROM_3DS_PARAM]) {
      const { [FROM_3DS_PARAM]: _, ...rest } = router.query;
      router.replace({ query: rest });
    }
  }, [router]);

  const [error, setError] = useState<IHttpError>();

  const [fetchCount, setFetchCount] = useState(0);

  const { data: orderData } = useQuery(['order', orderId], () => findOrderByIdResolver({ orderId }), {
    onError,
    refetchInterval: infoInterval,
    enabled: state !== OrderState.PAYMENT_ACTION_REQUIRED,
    onSuccess: async (data) => {
      const events = data.events;

      const filteredEvents = events.filter((event) =>
        includes({ ...OrderState, ...InstallmentStatus }, event.name.toString()),
      );
      const lastEvent = last(filteredEvents);

      if (isExpiredDate(data.due_date)) {
        const isExpiredGlobal = await isExpiredDateGlobal(data.due_date);

        if (isExpiredGlobal) {
          setState(OrderState.TIMEOUT);
          return;
        }
      }

      if (lastEvent) {
        setLastEvent(lastEvent);
        if (state !== OrderState.TIMEOUT) {
          if (isFrom3ds) {
            setState(lastEvent.name as unknown as OrderState | InstallmentStatus);
            // } else if (isCustomTemplate && fetchCount < 1 && lastEvent.name === OrderEventName.PAYMENT_DECLINED) {
          } else if (fetchCount < 1 && lastEvent.name === OrderEventName.PAYMENT_DECLINED) {
            setState(OrderState.INIT);
          } else {
            setState(lastEvent.name as unknown as OrderState | InstallmentStatus);
          }
        }
      }

      setFetchCount((prevState) => ++prevState);
    },
  });

  useEffect(() => {
    (async () => {
      if (orderData?.due_date && isExpiredDate(orderData.due_date)) {
        const isExpiredGlobalDate = await isExpiredDateGlobal(orderData.due_date);

        if (isExpiredGlobalDate) {
          setState(OrderState.TIMEOUT);
        }
      }

      if (
        orderData &&
        isNumber(orderData?.attempts) &&
        orderData.attempts <= 0 &&
        orderData.status === OrderStatus.Unpaid
      ) {
        setError({
          code: HttpErrorCode.OrderHasNoAttemptsLeft,
          message: 'Исчерпано количество попыток оплаты.',
        });
        setState(OrderState.PAYMENT_DECLINED);
      }
    })();
  }, [orderData]);

  const { data: shop } = useQuery(
    ['shop', orderData?.shop_id],
    () => {
      if (orderData?.shop_id) {
        return findShopByIdResolver({ shopId: orderData?.shop_id });
      }
      return undefined;
    },
    {
      enabled: Boolean(orderData?.shop_id),
    },
  );
  const findConstructorByShopIdQuery = useQuery(
    ['constructor', shop?.id],
    () => shop && findConstructorByShopIdResolver({ shopId: shop.id }),
  );

  const isCustomTemplate = Boolean(orderData?.template && customTemplates.includes(orderData.template));
  const isCustomAviataTemplate: boolean = orderData?.template === PaymentFormTemplate.CUSTOM_AVIATA;
  const isCustomAmwayTemplate: boolean = orderData?.template === PaymentFormTemplate.CUSTOM_AMWAY;

  const { config } = useShopConfig();

  useEffect(() => {
    switch (state) {
      case OrderState.ORDER_PAID:
      case OrderState.PAYMENT_DECLINED:
      case OrderState.PAYMENT_APPROVED:
        setInfoInterval(false);
        break;
    }
  }, [state]);

  const handleSetInterval = (value: number | false): void => setInfoInterval(value);

  const widgetEventHandler = useCallback(
    (
      isSuccess: boolean,
      widgetOptions?: IZoidProps | null,
      data?: IFindOrderEventsResolverResultItem | ISearchOrderItem | null,
    ) => {
      fireHook(true);
      if (isSuccess) {
        widgetOptions?.onSuccess && widgetOptions.onSuccess(data);
      } else {
        widgetOptions?.onError && widgetOptions.onError(data);
      }
    },
    [],
  );

  const showBackToShopButton = Boolean(orderData?.back_url || orderData?.success_url || orderData?.failure_url);

  function getAviataBackUrl(): string {
    const successUrl: string = orderData?.success_url || '';
    const failureUrl: string = orderData?.failure_url || '';
    const backUrl: string = orderData?.back_url || '';

    const isSuccessState = (
      [OrderState.PAYMENT_APPROVED, OrderState.PAYMENT_CAPTURED, OrderState.ORDER_PAID] as (
        | OrderState
        | InstallmentStatus
      )[]
    ).includes(state);
    const isFailureState = (
      [
        OrderState.PAYMENT_DECLINED,
        OrderState.PAYMENT_CANCELLED,
        OrderState.ERROR,
        OrderState.NOT_FOUND,
        OrderState.TIMEOUT,
      ] as (OrderState | InstallmentStatus)[]
    ).includes(state);

    if (isSuccessState) return successUrl;
    if (isFailureState && isExpiredDate(orderData?.due_date)) return failureUrl;
    return backUrl;
  }

  const orderNumber =
    (isString(orderData?.extra_info?.['order_number']) && orderData?.extra_info?.['order_number']) ||
    orderData?.external_id;

  const isInitState: boolean = [
    InstallmentStatus.CONFIRM_OTP_DECLINED,
    InstallmentStatus.OTP_CONFIRMED,
    InstallmentStatus.OTP_SENT,
    InstallmentStatus.PENDING,
    InstallmentStatus.SEND_OTP_DECLINED,
    OrderState.INIT,
    OrderState.ORDER_CREATED,
    OrderState.ORDER_UNPAID,
  ].includes(state);

  function renderView() {
    switch (state) {
      case InstallmentStatus.CONFIRM_OTP_DECLINED:
      case InstallmentStatus.OTP_CONFIRMED:
      case InstallmentStatus.OTP_SENT:
      case InstallmentStatus.PENDING:
      case InstallmentStatus.SEND_OTP_DECLINED:
      case OrderState.INIT:
      case OrderState.ORDER_CREATED:
      case OrderState.ORDER_UNPAID:
      case OrderState.PAYMENT_CANCELLED:
        if (!orderData) return null;

        if (isCustomTemplate) {
          return (
            <AviataPaymentFormContainer
              order={orderData}
              orderId={orderId}
              widgetOptions={widgetOptions}
              queryParams={queryParams}
              handleSetInterval={handleSetInterval}
              shopConfig={config}
              onError={setError}
            />
          );
        }

        return (
          <PaymentFormContainer
            order={orderData}
            widgetOptions={widgetOptions}
            queryParams={queryParams}
            handleSetInterval={handleSetInterval}
            onError={setError}
            shopConfig={config}
            shop={shop}
            error={error}
            typography={findConstructorByShopIdQuery.data?.typography}
          />
        );
      case InstallmentStatus.INSTALLMENT_ACTION_REQUIRED:
        if (lastEvent?.redirect_url) {
          window.location.assign(lastEvent.redirect_url);
        }
        return null;
      case OrderState.PAYMENT_ACTION_REQUIRED:
        if (lastEvent?.action_url) {
          goTo3DS(lastEvent.action_url);
        }
        return null;
      case InstallmentStatus.INSTALLMENT_DECLINED:
        return (
          <>
            <OrderInfo
              amount={getAmountWithCurrency(orderData ? orderData?.amount / 100 : 0, orderData?.currency)}
              orderId={orderId}
              description={orderData && orderData?.description}
              backBtnText={queryParams.backBtnText}
              shop={shop}
              typography={findConstructorByShopIdQuery.data?.typography}
            />
            <div className={'card-form__wrapper flex-basis-md-50 flex align-items-center justify-center'}>
              <CustomHead titleText={t('Заказ отклонен')} />
              <DeclinedPage
                handleRetry={() => {
                  if (orderData?.payment_link_url) {
                    window.location.assign(orderData.payment_link_url);
                  } else {
                    setState(OrderState.INIT);
                    fireHook(false);
                  }
                }}
                order={orderData}
                autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                description={t('Ваш запрос на рассрочку отклонен со стороны банка')}
                title={t('Заказ отклонен')}
              />
            </div>
          </>
        );
      case OrderState.PAYMENT_DECLINED:
        !isHookFired && widgetEventHandler(false, widgetOptions, lastEvent);
        queryParams.redirectAfterPay &&
          (queryParams.callbackParams
            ? router.push(`${orderData?.back_url}?response=error`)
            : router.push(`${orderData?.back_url}`));

        if (isCustomTemplate) {
          return (
            <>
              <CustomHead titleText={t('Оплата отклонена')} />
              <ResultViewAviata
                headText={t('Ошибка при оплате')}
                description={
                  error?.message || [t('Билет не выписался.'), t('Пожалуйста, попробуйте снова.')].join('\n')
                }
                orderId={orderNumber}
                expiryDate={orderData?.due_date}
                logo="/images/aviataLogo.svg"
                primaryBtn={
                  [
                    PaymentFormTemplate.CUSTOM_AVIATA_GOOGLE_PAY,
                    PaymentFormTemplate.CUSTOM_AVIATA_APPLE_PAY,
                    PaymentFormTemplate.CUSTOM_CHOCOTRAVEL_APPLE_PAY,
                    PaymentFormTemplate.CUSTOM_CHOCOTRAVEL_GOOGLE_PAY,
                  ].includes(orderData?.template as PaymentFormTemplate) ||
                  (isCustomAviataTemplate && error?.code === HttpErrorCode.OrderHasNoAttemptsLeft)
                    ? undefined
                    : {
                        text: t('Попробовать еще раз'),
                        action: () => {
                          setState(OrderState.INIT);
                          fireHook(false);
                        },
                      }
                }
                secondaryBtn={
                  queryParams.hideAviataSecondaryBtn
                    ? undefined
                    : {
                        text: t('Вернуться к заказу'),
                        action: () => {
                          router.push(getAviataBackUrl());
                        },
                      }
                }
              />
            </>
          );
        }

        return (
          <>
            {!queryParams.hideHeader && !isCustomAmwayTemplate && (
              <OrderInfo
                shop={shop}
                amount={getAmountWithCurrency(orderData ? orderData?.display_amount / 100 : 0, orderData?.currency)}
                orderId={orderId}
                description={orderData && orderData?.description}
                backBtnText={queryParams.backBtnText}
                typography={findConstructorByShopIdQuery.data?.typography}
              />
            )}
            {!queryParams.hideHeader && isCustomAmwayTemplate && (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: 1,
                  alignItems: 'center',
                }}
              >
                <OrderInfoAmway
                  shop={shop}
                  amount={getAmountWithCurrency(orderData ? orderData?.display_amount / 100 : 0, orderData?.currency)}
                  orderId={orderNumber}
                  description={orderData && orderData?.description}
                  backBtnText={queryParams.backBtnText}
                  typography={findConstructorByShopIdQuery.data?.typography}
                  isNew
                />
              </Box>
            )}
            <div
              className={classNames('card-form__wrapper flex align-items-center justify-center', {
                'flex-basis-md-50': !queryParams.hideHeader,
                'flex-basis-100': queryParams.hideHeader,
              })}
            >
              <CustomHead titleText={t('Оплата отклонена')} />
              {error?.code === HttpErrorCode.OrderHasNoAttemptsLeft && !isCustomAmwayTemplate && (
                <DeclinedPage
                  order={orderData}
                  autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                  description={lastEvent?.message || error?.message}
                  action={
                    !!orderData?.back_url && (
                      <Button
                        customClassname="declined-page__btn"
                        onClick={() => {
                          router.push(`${orderData?.back_url}`);
                        }}
                        disabled={false}
                      >
                        <img src="/images/leftArrowBtn.svg" alt="left arrow button" style={{ marginRight: 8 }} />
                        {t('Вернуться в магазин')}
                      </Button>
                    )
                  }
                />
              )}
              {error?.code !== HttpErrorCode.OrderHasNoAttemptsLeft && isCustomAmwayTemplate && (
                <DeclinedPageAmway
                  order={orderData}
                  handleRetry={() => {
                    if (orderData?.payment_link_url) {
                      window.location.assign(orderData.payment_link_url);
                    } else {
                      setState(OrderState.INIT);
                      fireHook(false);
                    }
                  }}
                  autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                  description={lastEvent?.message || error?.message}
                  iconSrc="/images/amway-warning.png"
                  action={
                    !!orderData?.back_url && (
                      <Button
                        customClassname="declined-page__btn"
                        onClick={() => {
                          router.push(`${orderData?.back_url}`);
                        }}
                        disabled={false}
                      >
                        <img src="/images/leftArrowBtn.svg" alt="left arrow button" style={{ marginRight: 8 }} />
                        {t('Вернуться в магазин')}
                      </Button>
                    )
                  }
                />
              )}
              {error?.code === HttpErrorCode.OrderHasNoAttemptsLeft && isCustomAmwayTemplate && (
                <DeclinedPageAmway
                  order={orderData}
                  autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                  description={lastEvent?.message || error?.message}
                  handleRetry={() => {
                    if (orderData?.payment_link_url) {
                      window.location.assign(orderData.payment_link_url);
                    } else {
                      setState(OrderState.INIT);
                      fireHook(false);
                    }
                  }}
                  iconSrc="/images/amway-warning.png"
                  action={
                    !!orderData?.back_url && (
                      <Button
                        customClassname="declined-page__btn"
                        style={{ marginTop: 170 }}
                        onClick={() => {
                          router.push(`${orderData?.back_url}`);
                        }}
                        disabled={false}
                      >
                        <img src="/images/leftArrowBtn.svg" alt="left arrow button" style={{ marginRight: 8 }} />
                        {t('Вернуться в магазин')}
                      </Button>
                    )
                  }
                />
              )}
              {error?.code !== HttpErrorCode.OrderHasNoAttemptsLeft && !isCustomAmwayTemplate && (
                <DeclinedPage
                  handleRetry={() => {
                    if (orderData?.payment_link_url) {
                      window.location.assign(orderData.payment_link_url);
                    } else {
                      setState(OrderState.INIT);
                      fireHook(false);
                    }
                  }}
                  order={orderData}
                  autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                  description={lastEvent?.message || error?.message}
                />
              )}
            </div>
          </>
        );
      case InstallmentStatus.INSTALLMENT_ISSUED:
        !isHookFired && widgetEventHandler(true, widgetOptions, orderData);
        queryParams.redirectAfterPay &&
          (queryParams.callbackParams
            ? router.push(`${orderData?.back_url}?response=success`)
            : router.push(`${orderData?.back_url}`));

        return (
          <>
            <OrderInfo
              shop={shop}
              amount={getAmountWithCurrency(orderData ? orderData.display_amount / 100 : 0, orderData?.currency)}
              orderId={orderId}
              description={orderData && orderData?.description}
              backToShopBtn={
                showBackToShopButton
                  ? {
                      action: () => {
                        router.push(`${orderData?.back_url}`);
                      },
                    }
                  : undefined
              }
              backBtnText={queryParams.backBtnText}
              typography={findConstructorByShopIdQuery.data?.typography}
            />
            <div className={'card-form__wrapper flex-basis-md-50 flex align-items-center justify-center'}>
              <CustomHead titleText={t('Заказ оформлен')} />
              {orderData && (
                <SuccessPage
                  order={orderData}
                  autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                  backBtnText={queryParams.backBtnText}
                  event={lastEvent}
                  orderNumber={orderNumber}
                  shopFeatures={config?.features}
                />
              )}
            </div>
          </>
        );
      case OrderState.PAYMENT_APPROVED:
      case OrderState.PAYMENT_CAPTURED:
      case OrderState.REFUND_CREATED:
      case OrderState.REFUND_APPROVED:
      case OrderState.REFUND_DECLINED:
      case OrderState.ORDER_ON_HOLD:
      case OrderState.ORDER_PAID:
        !isHookFired && widgetEventHandler(true, widgetOptions, orderData);
        queryParams.redirectAfterPay &&
          (queryParams.callbackParams
            ? router.push(`${orderData?.back_url}?response=success`)
            : router.push(`${orderData?.back_url}`));
        if (isCustomTemplate) {
          return (
            <div className={'card-form__wrapper flex align-items-center justify-center'}>
              <CustomHead titleText={t('Заказ оплачен')} />
              {orderData && (
                <SuccessPage
                  isAviataSuccessPage={true}
                  order={orderData}
                  isAviataOrderId={true}
                  autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                  backBtnText={queryParams.backBtnText}
                  aviataBackUrl={getAviataBackUrl()}
                  orderNumber={orderNumber}
                  shopFeatures={config?.features}
                />
              )}
            </div>
          );
        }

        if (widgetOptions?.widgetProps?.cardForm?.hideSuccessResultMessages) {
          return null;
        }

        return (
          <>
            {!queryParams.hideHeader && !isCustomAmwayTemplate && (
              <OrderInfo
                shop={shop}
                amount={getAmountWithCurrency(orderData ? orderData?.display_amount / 100 : 0, orderData?.currency)}
                description={orderData && orderData?.description}
                orderId={orderId}
                backToShopBtn={
                  showBackToShopButton
                    ? {
                        action: () => {
                          router.push(`${orderData?.back_url || orderData?.success_url || orderData?.failure_url}`);
                        },
                      }
                    : undefined
                }
                backBtnText={queryParams.backBtnText}
                typography={findConstructorByShopIdQuery.data?.typography}
              />
            )}
            {!queryParams.hideHeader && isCustomAmwayTemplate && (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: 1,
                  alignItems: 'center',
                }}
              >
                <OrderInfoAmway
                  shop={shop}
                  amount={getAmountWithCurrency(orderData ? orderData?.display_amount / 100 : 0, orderData?.currency)}
                  orderId={orderNumber}
                  description={orderData && orderData?.description}
                  backBtnText={queryParams.backBtnText}
                  typography={findConstructorByShopIdQuery.data?.typography}
                  isNew
                />
              </Box>
            )}
            <div
              className={classNames('card-form__wrapper flex align-items-center justify-center', {
                'flex-basis-md-50': !queryParams.hideHeader,
                'flex-basis-100': queryParams.hideHeader,
              })}
            >
              <CustomHead titleText={t('Заказ оплачен')} />
              {orderData && !isCustomAmwayTemplate && (
                <SuccessPage
                  order={orderData}
                  autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                  backBtnText={queryParams.backBtnText}
                  orderNumber={orderNumber}
                  shopFeatures={config?.features}
                />
              )}
              {orderData && isCustomAmwayTemplate && (
                <SuccessPageAmway
                  order={orderData}
                  autoRedirectMs={showBackToShopButton ? queryParams.autoRedirectMs : null}
                  backBtnText={queryParams.backBtnText}
                  orderNumber={orderNumber}
                  shopFeatures={config?.features}
                />
              )}
            </div>
          </>
        );
      case OrderState.ERROR:
        return (
          <>
            <CustomHead titleText={t('Неизвестная ошибка')} />
            <ResultView descriptionError={error?.message || t('Неизвестная ошибка')} />
          </>
        );
      case OrderState.TIMEOUT:
        if (isCustomTemplate) {
          return (
            <>
              <CustomHead titleText="Оплата отклонена" />
              <AviataTimeoutView order={orderData} />
            </>
          );
        }

        if (isCustomAmwayTemplate) {
          return (
            <>
              <CustomHead titleText="Оплата отклонена" />
              <ResultViewAmway />
            </>
          );
        }

        return (
          <>
            <CustomHead titleText={t('Срок оплаты истек. Обратитесь в магазин')} />
            <ResultView descriptionError={t('Время оплаты истекло.')} />
          </>
        );
      case OrderState.NOT_FOUND:
        return (
          <>
            <CustomHead titleText={t('Заказ не найден')} />
            <ResultView descriptionError={t('Заказ не найден')} />
          </>
        );
      case OrderState.LOADING:
      default:
        return (
          <>
            <Head>
              <title>{`${t('Загрузка...')} | IOKA`}</title>
            </Head>
            <PaymentFormLoader />
          </>
        );
    }
  }

  const isEmptyTemplate = isCustomTemplate || (isInitState && !isCustomTemplate);

  return (
    <ThemeProvider theme={(outerTheme) => deepmerge(outerTheme, findConstructorByShopIdQuery.data)}>
      <PaymentStateContext.Provider value={setState}>
        <FormLayout isWidget={isWidget} isEmptyTemplate={isEmptyTemplate}>
          {renderView()}
        </FormLayout>
      </PaymentStateContext.Provider>
    </ThemeProvider>
  );
}

function goTo3DS(actionUrl: string) {
  const url = new URL(actionUrl);

  const returnUrl = new URL(window.location.href);
  returnUrl.searchParams.set(FROM_3DS_PARAM, 'true');

  url.searchParams.set('return_url', returnUrl.toString());

  window.location.assign(url.toString());
}

import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow placement={'top'} classes={{ popper: className }} {...props} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#475569',
    padding: 16,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#475569',
    marginRight: 16,
  },
}));

import { backendHttp, http } from '../../instances';
import { IGetUsersRequestParams, IGetUsersResponseData } from '../../resolvers/users/get-users.resolver';

export async function getUsers(params: IGetUsersRequestParams): Promise<IGetUsersResponseData> {
  const { data } = await http.get<IGetUsersResponseData>(`/users`, {
    params,
  });
  return data;
}

export async function createUser(requestData: any): Promise<Readonly<any>> {
  const { data } = await backendHttp.post<any>('/users', requestData);
  return data;
}

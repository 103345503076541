import { MaterialCommunityIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mobily/stacks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as Clipboard from 'expo-clipboard';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';
import * as yup from 'yup';

import {
  useSelectedShop,
  useDashboardNavigation,
  DashboardStackParamList,
  Amplitude,
  useMe,
} from '@iokanx/dashboard/feature';
import {
  IInviteShopUserResolverParams,
  IInviteShopUserResolverResult,
  inviteShopUserResolver,
} from '@iokanx/shared/data-access/api';
import { AVAILABLE_SHOP_USER_ROLES, SHOP_USER_ROLE_I18N, ShopUserRole } from '@iokanx/shared/data-access/constants';
import { IHttpError } from '@iokanx/shared/data-access/types';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import {
  Caption,
  HelperText,
  Colors,
  TextField,
  EmailField,
  Button,
  RadioButtons,
  IRadioButtonItem,
} from '@iokanx/shared/ui/universal';

const ROLE_DESCRIPTION: Record<typeof AVAILABLE_SHOP_USER_ROLES[number], string> = {
  [ShopUserRole.Admin]: 'Полный доступ, подходит для руководителей бизнеса и администрации',
  [ShopUserRole.Support]: 'Подходит для тех, кто занимается возвратами и отвечает на запросы клиентов',
  [ShopUserRole.Manager]: 'Подходит для тех, кто занимается заказами',
  [ShopUserRole.Viewer]: 'Если нужно дать доступ на просмотр без возможности вносить изменения',
};

interface IRoleItem extends IRadioButtonItem<ShopUserRole> {
  description: string;
}
const roleMenuItems: IRoleItem[] = AVAILABLE_SHOP_USER_ROLES.map((value) => ({
  label: SHOP_USER_ROLE_I18N[value],
  value,
  description: ROLE_DESCRIPTION[value],
}));

type IAddEmployeeFormFieldValues = Pick<IInviteShopUserResolverParams, 'role_code' | 'email'>;

function getRegistrationPageLink(): string {
  try {
    return window.location.host + '/registration';
  } catch {
    return 'https://dashboard.ioka.kz/registration';
  }
}

const addEmployeeFormSchema: yup.SchemaOf<IAddEmployeeFormFieldValues> = yup.object({
  email: yup.string().email('Некорректная почта').required('Обязательное поле'),
  role_code: yup.mixed().oneOf([...AVAILABLE_SHOP_USER_ROLES]),
});

function AddEmployee() {
  const navigation = useDashboardNavigation();
  const queryClient = useQueryClient();
  const { me } = useMe();

  const {
    control,
    handleSubmit,

    reset: resetAddEmployeeForm,
  } = useForm<IAddEmployeeFormFieldValues>({
    defaultValues: {
      email: '',
      role_code: ShopUserRole.Viewer,
    },
    resolver: yupResolver(addEmployeeFormSchema),
  });

  const {
    mutate: inviteShopUser,
    error: inviteShopUserError,
    isError: isInviteShopUserError,
    isLoading: isInvitingShopUser,
    isSuccess: isSuccessInviteShopUser,
    data: inviteShopUserData,
    reset: resetInviteShopUser,
  } = useMutation<IInviteShopUserResolverResult, AxiosError<IHttpError>, IInviteShopUserResolverParams>(
    inviteShopUserResolver,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['users']);
        return Amplitude.logEventWithPropertiesAsync('staff_add', { user_id: me?.id });
      },
    },
  );

  const { selectedShop } = useSelectedShop();

  const onSubmit: SubmitHandler<IAddEmployeeFormFieldValues> = (data) => {
    if (selectedShop) {
      inviteShopUser({ shopId: selectedShop.id, ...data });
    }
  };

  function handleGoBack() {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Drawer', { screen: 'Employees' });
    }
  }

  function addAnotherEmployee() {
    resetInviteShopUser();
    resetAddEmployeeForm();
  }

  const snackbar = useSnackbar();
  async function handleCopyRegistrationPageLink() {
    await Clipboard.setStringAsync(getRegistrationPageLink());
    snackbar.show('Скопировано');
  }

  if (isSuccessInviteShopUser) {
    return (
      <Box style={styles.employeeSuccessfullyInvitedContainer} alignSelf={'center'}>
        <Stack padding={4} space={4} align={'center'}>
          <MaterialCommunityIcons name="account-check" size={120} color={Colors.green500} />
          <Caption>{inviteShopUserData?.detail}</Caption>
          <Button icon={{ name: 'account-plus' }} onPress={addAnotherEmployee} fullWidth>
            Добавить еще сотрудника
          </Button>
          <Button mode={'outlined'} icon={{ name: 'arrow-left' }} onPress={handleGoBack} fullWidth>
            Вернуться назад
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Stack padding={4} space={4}>
      <Stack space={2}>
        <HelperText type={'info'} padding={'none'} style={styles.helperText}>
          Отправьте ссылку на приглашение
        </HelperText>
        <TextField
          value={getRegistrationPageLink()}
          editable={false}
          rightIcon={{ name: 'content-copy', onPress: handleCopyRegistrationPageLink }}
          leftIcon={{ name: 'earth' }}
        />
      </Stack>

      <Stack space={2}>
        <HelperText type={'info'} padding={'none'} style={[styles.helperText, { marginTop: 24 }]}>
          Если пользователь уже зарегистрирован в ioka, то можете отправить приглашение в ваш магазин
        </HelperText>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EmailField
              label={'Почта'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
          name={'email'}
        />
      </Stack>

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioButtons
            label={'Выберите роль для сотрудника'}
            onValueChange={onChange}
            value={value}
            items={roleMenuItems}
          />
        )}
        name={'role_code'}
      />

      {isInviteShopUserError && (
        <HelperText type={'error'} visible={isInviteShopUserError}>
          {inviteShopUserError?.response?.data.message}
        </HelperText>
      )}

      <Button onPress={handleSubmit(onSubmit)} loading={isInvitingShopUser}>
        Отправить приглашение
      </Button>
    </Stack>
  );
}

const styles = StyleSheet.create({
  selectedListItem: {
    borderWidth: 1,
  },
  employeeSuccessfullyInvitedContainer: {
    maxWidth: 375,
  },
  helperText: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export default wrapPage(
  wrapModalPage(AddEmployee, {
    title: 'Новый сотрудник',
    fillNavigationStack: (navigation: NativeStackNavigationProp<DashboardStackParamList>) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Employees' });
        navigation.push('AddEmployee');
      }
    },
    handleDismiss: (navigation) => {
      navigation.push('Drawer', { screen: 'Employees' });
    },
  }),
);

import { styled } from '@mui/material';

import { isEmitter } from '@iokanx/shared/util';

export interface IEmitterLogoProps {
  emitter: string;
}

const Img = styled('img')({
  borderWidth: 0.5,
  borderStyle: 'solid',
  borderColor: '#E2E8F0',
  borderRadius: 2,
});

export function EmitterLogo(props: IEmitterLogoProps) {
  if (!isEmitter(props.emitter)) return null;

  const src = `/images/${props.emitter}-logo.svg`;
  const alt = `${props.emitter} logo`;

  return <Img src={src} alt={alt} height={20} width={30} />;
}

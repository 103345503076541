import { Typography } from '@mui/material';

import { Item, ProfileForm, useSelectedShop } from '@iokanx/dashboard/feature';
import { wrapPage } from '@iokanx/shared/feature';

import { CreateProfile } from '../../../../feature/src/lib/create-profile/create-profile';

const Profile = () => {
  const { selectedShop } = useSelectedShop();

  if (!selectedShop) {
    return (
      <Item>
        <Typography>Выберите магазин</Typography>
      </Item>
    );
  }

  return selectedShop?.organization_id ? (
    <ProfileForm organizationId={selectedShop?.organization_id} />
  ) : (
    <CreateProfile shop={selectedShop} />
  );
};

export const ProfilePage = wrapPage(Profile);

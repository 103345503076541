export enum DashboardPaymentStatus {
  /** Заказ создан, но не было попыток оплаты */
  'UNPAID' = 'UNPAID',
  /** Заказ создан, и истекло время оплаты */
  'EXPIRED' = 'EXPIRED',
  /** Ожидает подтверждения платежа */
  'PENDING' = 'PENDING',
  /** Ожидает подтверждения платежа */
  'REQUIRES_ACTION' = 'REQUIRES_ACTION',
  /** Сумма авторизована после двухфакторки */
  'APPROVED' = 'APPROVED',
  /** Сумма списана полностью */
  'CAPTURED' = 'CAPTURED',
  /** Авторизованная сумма отменена полностью */
  'CANCELLED' = 'CANCELLED',
  /** Попытка платежа неуспешна */
  'DECLINED' = 'DECLINED',
  /** Частично возвращен */
  'PARTIALLY_REFUNDED' = 'PARTIALLY_REFUNDED',
  /** Полностью возвращен */
  'REFUNDED' = 'REFUNDED',
}

export const DASHBOARD_PAYMENT_STATUS_I18N: Record<DashboardPaymentStatus, string> = {
  [DashboardPaymentStatus.UNPAID]: 'Создан',
  [DashboardPaymentStatus.EXPIRED]: 'Истек',
  [DashboardPaymentStatus.PENDING]: 'В обработке',
  [DashboardPaymentStatus.REQUIRES_ACTION]: 'В обработке',
  [DashboardPaymentStatus.APPROVED]: 'Авторизован',
  [DashboardPaymentStatus.CAPTURED]: 'Списан',
  [DashboardPaymentStatus.CANCELLED]: 'Отменен',
  [DashboardPaymentStatus.DECLINED]: 'Отклонен',
  [DashboardPaymentStatus.PARTIALLY_REFUNDED]: 'Частично возвращен',
  [DashboardPaymentStatus.REFUNDED]: 'Полностью возвращен',
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item, useMe } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';
import { RadioButtons } from '@iokanx/shared/ui/universal';

import { useFormEdit } from '../../../helpers/useFormEdit';
import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { FormField } from '../../FormField/FormField';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { ProfileFormStep } from '../../layouts/ProfileFormStep';

const riskLevel = [
  {
    value: '0',
    label: 'Низкий',
  },
  {
    value: '0.3',
    label: 'Средний',
  },
  {
    value: '0.7',
    label: 'Высокий',
  },
];

const getRiskLevelItems = (isDisabled: boolean) =>
  riskLevel.map((riskLevelItem) => ({
    value: riskLevelItem.value,
    label: riskLevelItem.label,
    disabled: isDisabled,
  }));

export const ScreeningResultsStep = (props: any) => {
  const queryClient = useQueryClient();
  const { me } = useMe();
  const { organizationId } = useProfileFormContext();

  const { data: organizationInfo } = useQuery(['organizations', organizationId], async () => {
    const { data } = await backendHttp.get(`organizations/${organizationId}`);

    return data;
  });

  const { mutate: mutateRiskLevel } = useMutation(
    async ({ reason, level }: { reason: string; level: string }) => {
      const method = organizationInfo?.risk_level ? 'patch' : 'post';

      await backendHttp[method](`organizations/${organizationId}/risk-level`, {
        level: Number(level),
        reason: reason,
      });
    },
    {
      onSuccess: async ({ level, reason }: { level: number; reason: string }) => {
        reset({
          level: String(level),
          reason,
        });

        handleIsEdit();
        await queryClient.invalidateQueries(['organizations', organizationId]);
      },
    },
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      level: '',
      reason: '',
    },
    // resolver: yupResolver(createFormValidationSchema),
  });

  const onSubmit = (data: any) => mutateRiskLevel(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(
    Boolean(organizationInfo.risk_level || Boolean(!me?.is_superuser)),
    handleSubmit(onSubmit, onError),
  );

  useEffect(() => {
    if (organizationInfo?.risk_level) {
      reset({
        level: String(organizationInfo?.risk_level.level),
        reason: organizationInfo?.risk_level.reason,
      });
    }
  }, [organizationInfo]);

  return (
    <ProfileFormStep title={'Результаты проверки'} {...props}>
      <ProfileFormSection isSaved={isDisable} isSubmitted={Boolean(!me?.is_superuser)} handleSubmit={handlePress}>
        <Controller
          control={control}
          name={'level'}
          render={({ field: { onChange, value } }) => (
            <Item>
              <RadioButtons
                label={'Определите уровень риска'}
                onValueChange={onChange}
                value={value}
                items={getRiskLevelItems(isDisable)}
              />
            </Item>
          )}
        />

        <FormField name={'reason'} control={control} label={'Комментарий'} disabled={isDisable} />
      </ProfileFormSection>
    </ProfileFormStep>
  );
};

import { noop } from 'lodash';
import { createContext, useContext } from 'react';

import { OrderState } from '@iokanx/shared/data-access/constants';

type IStateSwitcher = (state: OrderState) => void;

export const PaymentStateContext = createContext<IStateSwitcher>(noop);

export function usePaymentStateContext() {
  return useContext(PaymentStateContext);
}

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React from 'react';

export const profileFormStepLabels = [
  {
    label: 'Общая Информация',
  },
  {
    label: 'Виды деятельности',
  },
  {
    label: 'Обороты',
  },
  {
    label: 'Руководство и акционеры',
  },
  {
    label: 'Данные о бенефициарном собственнике',
  },
  {
    label: 'Связь с государственными должностными лицами',
  },
  {
    label: 'Посредники',
  },
  {
    label: 'Дополнительные вопросы',
  },
  {
    label: 'Прикрепите документы',
  },
];

interface IStepper extends Stepper {
  status: string;
}

export const getProfileFormStepLabels = (me: any, status: string): any[] => {
  let labels = profileFormStepLabels;

  if (status === 'REVIEW' && me?.is_superuser) {
    labels = [
      ...labels,
      {
        label: 'Результаты проверки',
      },
    ];
  }

  return labels;
};

interface Stepper {
  me: any;
  activeStep: number;
  completed: any;
  errorStep: any;
  setActiveStep: (step: number) => void;
}

export function VerticalStepper({ me, status, setActiveStep, activeStep, completed, errorStep }: IStepper) {
  const handleStep = (step: number) => setActiveStep(step);

  const isStepFailed = (step: number) => {
    return errorStep[step];
  };

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
        {getProfileFormStepLabels(me, status).map((step, index) => {
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
          } = {};
          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="warning">
                Секция не заполнена
              </Typography>
            );
            labelProps.error = true;
          }

          return (
            <Step
              key={index}
              completed={completed[index]}
              onClick={() => handleStep(index)}
              style={{ cursor: 'pointer' }}
            >
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

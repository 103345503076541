import { FormHelperText } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import classnames from 'classnames';
import { useFormik } from 'formik';
import isMobile from 'is-mobile';
import { compact, last } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import { CardFooter } from '@iokanx/payment-form/ui';
import {
  findOrderEventsResolver,
  ISearchOrderItem,
  confirmInstallmentResolver,
  IConfirmInstallmentResolverParams,
  IConfirmInstallmentResolverResult,
  sendInstallmentOtpResolver,
  ISendInstallmentOtpResolverResult,
  ISendInstallmentOtpResolverParams,
} from '@iokanx/shared/data-access/api';
import { OrderEventName, InstallmentStatus, InstallmentTransferType } from '@iokanx/shared/data-access/constants';
import { HttpErrorCode, IHttpError } from '@iokanx/shared/data-access/types';

import { InstallmentConfirmationStep } from '../installment-confirmation-step/installment-confirmation-step';
import { InstallmentParamsStep } from '../installment-params-step/installment-params-step';
import { InstallmentPersonalDataStep } from '../installment-personal-data-step/installment-personal-data-step';
import { useShopConfig } from '../use-shop-config/use-shop-config';

export interface IInstallmentPayProps {
  headerText?: string;
  order: ISearchOrderItem;
}

export enum InstallmentPayStep {
  PersonalData,
  InstallmentParams,
  ConfirmationCode,
}

export type ISendOtpFormValues = Pick<ISendInstallmentOtpResolverParams, 'iin' | 'phone' | 'period'>;

export function InstallmentPay(props: IInstallmentPayProps): JSX.Element {
  const { t } = useTranslation();

  const [enabledOrderEvents, setEnabledOrderEvents] = useState(false);

  const {
    mutate: mutateSendOtp,
    isLoading: isSendingOtp,
    error: sendOtpError,
    data: sendOtpData,
    reset: resetSendOtp,
  } = useMutation<
    ISendInstallmentOtpResolverResult,
    AxiosError<IHttpError>,
    Omit<ISendInstallmentOtpResolverParams, 'orderId' | 'orderAccessToken'>
  >(
    (variables) =>
      sendInstallmentOtpResolver({ orderId: props.order.id, orderAccessToken: props.order.access_token, ...variables }),
    {
      onSuccess: (data) => {
        if (data.status === InstallmentStatus.OTP_SENT) {
          setStep(InstallmentPayStep.ConfirmationCode);
          toast.success(t('Код отправлен'));
        }
      },
      onError: (error) => {
        if (error.response?.data.code === HttpErrorCode.ProcessingDeadlineReached) {
          setEnabledOrderEvents(true);
        }
      },
    },
  );

  const sendOtpForm = useFormik<ISendOtpFormValues>({
    initialValues: {
      iin: '',
      phone: '',
      period: '',
    },
    onSubmit: (values) => {
      const phone = values.phone.replace(/ /g, '');

      mutateSendOtp({
        iin: values.iin,
        phone,
        period: values.period,
        channel: isMobile() ? InstallmentTransferType.Mobile : InstallmentTransferType.Web,
      });
    },
  });

  const [step, setStep] = useState<InstallmentPayStep>(InstallmentPayStep.PersonalData);

  useEffect(() => {
    resetSendOtp();
    setEnabledOrderEvents(false);
  }, [resetSendOtp, step]);

  const { config } = useShopConfig();

  const installmentProvider = config?.providers.find((provider) => provider.installment);
  const periods = installmentProvider?.installment?.map((value) => value.period) || [];

  const {
    mutate: mutateConfirm,
    isLoading: isConfirming,
    error: confirmError,
    data: confirmData,
  } = useMutation<
    IConfirmInstallmentResolverResult,
    AxiosError<IHttpError>,
    Pick<IConfirmInstallmentResolverParams, 'code'>
  >(
    (variables) =>
      confirmInstallmentResolver({ orderId: props.order.id, orderAccessToken: props.order.access_token, ...variables }),
    {
      onSuccess: (data) => {
        if (data.status === InstallmentStatus.INSTALLMENT_ACTION_REQUIRED) {
          setEnabledOrderEvents(true);
        }
      },
      onError: (error) => {
        if (error.response?.data.code === HttpErrorCode.ProcessingDeadlineReached) {
          setEnabledOrderEvents(true);
        }
      },
    },
  );

  const [redirectUrl, setRedirectUrl] = useState<string | null>();

  const { data: orderEvents } = useQuery(
    ['order-events', props.order.id],
    () => findOrderEventsResolver({ orderId: props.order.id }),
    {
      enabled: enabledOrderEvents,
      refetchInterval: 1000,
    },
  );

  const lastEvent = last(orderEvents);

  useEffect(() => {
    if (confirmData?.status === InstallmentStatus.INSTALLMENT_ACTION_REQUIRED) {
      setRedirectUrl(confirmData.redirect_url as string);
    } else if (lastEvent?.name === OrderEventName.INSTALLMENT_ACTION_REQUIRED) {
      setRedirectUrl(lastEvent.redirect_url);
    }
  }, [orderEvents, confirmData, lastEvent?.name, lastEvent?.redirect_url]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    }
  }, [redirectUrl]);

  const errors: string[] = compact([
    sendOtpError?.response?.data.message,
    sendOtpData?.error?.message,
    enabledOrderEvents && lastEvent?.message,
    confirmError?.response?.data.code !== HttpErrorCode.ProcessingDeadlineReached &&
      confirmError?.response?.data.message,
    confirmData?.error?.message,
  ]);

  return (
    <div>
      {props.headerText && <h3 className="card-form__title">{props.headerText}</h3>}
      {/*<div className="card-form__row">*/}
      {/*  <TogglePaymentTypeButton shopConfig={config} />*/}
      {/*</div>*/}
      {step === InstallmentPayStep.PersonalData && (
        <InstallmentPersonalDataStep
          onSubmit={(values) => {
            sendOtpForm.setFieldValue('iin', values.iin);
            sendOtpForm.setFieldValue('phone', values.phone);

            setStep(InstallmentPayStep.InstallmentParams);
          }}
          formInitialValues={sendOtpForm.values}
        />
      )}
      {step === InstallmentPayStep.InstallmentParams && (
        <InstallmentParamsStep
          onSubmit={(values) => {
            sendOtpForm.setFieldValue('period', values.period);

            return sendOtpForm.submitForm();
          }}
          personalData={sendOtpForm.values}
          onBack={() => {
            setStep(InstallmentPayStep.PersonalData);
          }}
          order={props.order}
          isSubmitting={isSendingOtp || enabledOrderEvents}
          periods={periods}
        />
      )}
      {step === InstallmentPayStep.ConfirmationCode && (
        <InstallmentConfirmationStep
          onBack={() => {
            setStep(InstallmentPayStep.InstallmentParams);
          }}
          otpValues={sendOtpForm.values}
          order={props.order}
          isSubmitting={isConfirming || enabledOrderEvents}
          onSubmit={mutateConfirm}
          resendOtp={sendOtpForm.submitForm}
          isResendingOtp={isSendingOtp}
        />
      )}
      {errors.length > 0 && (
        <div className="card-form__row">
          {errors.map((error) => (
            <FormHelperText error key={error}>
              {error}
            </FormHelperText>
          ))}
        </div>
      )}
      <div className={classnames('card-form__row', 'display-md-none')}>
        <CardFooter />
      </div>
      <div className="card-form__row">
        <div className="bottom-text">
          {`${t('Платежи надежно защищены')} `}
          <img src="/images/ioka-logo.svg" className="bottom-text__logo" alt="ioka" height={16} width="auto" />
        </div>
      </div>
    </div>
  );
}

import { Box, Stack } from '@mobily/stacks';
import { StyleSheet } from 'react-native';

import { ISearchPaymentsResolverResultItem } from '@iokanx/shared/data-access/api';
import { Caption, Card, SimpleCard, Paragraph, SimpleCardProps } from '@iokanx/shared/ui/universal';

import { PaymentStatusChip } from '../payment-status-chip/payment-status-chip';

export interface IPaymentCardProps {
  payment: ISearchPaymentsResolverResultItem;
  onCardPress: SimpleCardProps['onPress'];
}

interface IContentItem {
  title: string;
  key: keyof ISearchPaymentsResolverResultItem;
}
const contentItems: IContentItem[] = [
  {
    title: 'Идентификатор заказа',
    key: 'order_id',
  },
  {
    title: 'Идентификатор платежа',
    key: 'id',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
  },
];

export function PaymentCard(props: IPaymentCardProps) {
  return (
    <SimpleCard
      title={props.payment.approvedAmountWithSymbol}
      subtitle={props.payment.processingFeeWithSymbol}
      right={() => (
        <Box marginRight={4}>
          <PaymentStatusChip paymentStatus={props.payment.status} />
        </Box>
      )}
      onPress={props.onCardPress}
    >
      <Card.Content>
        {contentItems.map((item) => (
          <Stack key={item.key} horizontal space={2} style={styles.contentItem}>
            <Caption>{item.title}</Caption>
            <Paragraph>{props.payment[item.key]}</Paragraph>
          </Stack>
        ))}
      </Card.Content>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  contentItem: {
    justifyContent: 'space-between',
  },
});

import { backendHttp } from '../../../../instances';

import { IGetConfigByShopsIdResponseData } from '.';

export async function updateConfigByShopId(
  shopId: string,
  config: IGetConfigByShopsIdResponseData,
): Promise<IGetConfigByShopsIdResponseData> {
  const { data } = await backendHttp.patch(`/configs/${shopId}`, config);
  return data;
}

import { Box } from '@mobily/stacks';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { DashboardStackParamList, useMe } from '@iokanx/dashboard/feature';
import { findShopByIdResolver } from '@iokanx/shared/data-access/api';
import { wrapModalPage, wrapPage } from '@iokanx/shared/feature';

import ShopApiKey from '../../../../feature/src/lib/shop-api-key/shop-api-key';
import ShopConfig from '../../../../feature/src/lib/shop-config/shop-config';
import ShopInfo from '../../../../feature/src/lib/shop-info/shop-info';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  classes?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className={props.classes}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const styles = StyleSheet.create({
  tab: {
    fontSize: 12,
    fontWeight: 'normal',
  },
});

export type IShopPageProps = NativeStackScreenProps<DashboardStackParamList, 'Shop'>;

function Shop(props: IShopPageProps) {
  const { me } = useMe();
  const { data: shop } = useQuery(['shop', props.route.params], () => findShopByIdResolver(props.route.params));

  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTabIndex = (event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue);

  return (
    <ScrollView>
      <Tabs
        value={tabIndex}
        onChange={handleChangeTabIndex}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Информация" style={styles.tab} />
        <Tab label="Конфигурация" style={styles.tab} />
        <Tab label="Доступы" style={styles.tab} />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <Grid item>
          <ShopInfo is_superuser={me?.is_superuser} shop={shop} />
        </Grid>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <Grid item>
          <ShopConfig is_superuser={me?.is_superuser} shop={shop} />
        </Grid>
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <Grid item>
          <ShopApiKey is_superuser={me?.is_superuser} shop={shop} />
        </Grid>
      </TabPanel>
    </ScrollView>
  );
}

export default wrapPage(
  wrapModalPage(Shop, {
    position: 'Right',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'Shop'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Shops' });
        navigation.push('Shop', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

import { omit, random } from 'lodash';

import { COLORS } from '@iokanx/shared/data-access/constants';

const AVAILABLE_COLORS = omit(COLORS, 'white');
const colors = Object.values(AVAILABLE_COLORS);

export function randomColor(): string {
  return colors[random(0, colors.length - 1)];
}

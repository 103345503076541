import { Stack } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';

import { IFindShopUsersResolverResultItem } from '@iokanx/shared/data-access/api';

import { DeleteEmployee } from '../delete-employee/delete-employee';
import { IEmployeeListProps } from '../employee-list/employee-list';
import { EmployeeUpdateRole } from '../employee-update-role/employee-update-role';

const columns: GridColumns<IFindShopUsersResolverResultItem> = [
  {
    headerName: 'Пользователь',
    field: 'fullName',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'Почта',
    field: 'email',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'Роль',
    field: 'role',
    renderCell: (props) => <EmployeeUpdateRole employee={props.row} />,
    flex: 0.3,
    sortable: false,
  },
  {
    field: 'id',
    renderCell: (props) => <DeleteEmployee employee={props.row} />,
    sortable: false,
    headerName: '',
    align: 'center',
  },
];

export function EmployeeListDesktop(props: IEmployeeListProps) {
  return (
    <Stack padding={2}>
      {props.header}
      <DataGrid
        rows={props.query.data || []}
        columns={columns}
        loading={props.query.isFetching}
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        autoHeight
      />
    </Stack>
  );
}

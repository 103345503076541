import { useInfiniteQuery } from '@tanstack/react-query';
import { ListRenderItem } from 'react-native';

import { OrderCard } from '@iokanx/dashboard/ui';
import {
  findDashboardPaymentsResolver,
  IFindDashboardPaymentsResolverResultItem,
} from '@iokanx/shared/data-access/api';
import { createGetNextPageParam } from '@iokanx/shared/util';

import { OperationFlatList } from '../operation-flat-list/operation-flat-list';
import { IOrderListProps } from '../order-list/order-list';

export function OrderListMobile(props: IOrderListProps) {
  const query = useInfiniteQuery(
    ['orders', props.form.watch()],
    (context) =>
      findDashboardPaymentsResolver({ ...props.form.watch(), page: context.pageParam, limit: props.pageLimit }),
    {
      getNextPageParam: createGetNextPageParam({ pageLimit: props.pageLimit }),
    },
  );

  const renderItem: ListRenderItem<IFindDashboardPaymentsResolverResultItem> = (info) => {
    return <OrderCard order={info.item} onCardPress={() => props.onOrderPress(info.item)} />;
  };

  return (
    <OperationFlatList
      renderItem={renderItem}
      query={query}
      hasNotNextPageText={'Платежей больше нет'}
      emptyText={'По указанным параметрам, платежей не найдено'}
      filter={props.filter}
    />
  );
}

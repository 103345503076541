import { IFindShopUsersResolverResultItem, transformShopUser } from './find-shop-users.resolver';

import { IPatchShopsByIdUsersByIdRequestData, patchShopsByIdUsersById } from '../../resources';

export interface IUpdateShopUserRoleResolverParams extends IPatchShopsByIdUsersByIdRequestData {
  shopId: string;
  userId: string;
}

export async function updateShopUserRoleResolver({
  shopId,
  userId,
  ...requestData
}: IUpdateShopUserRoleResolverParams): Promise<IFindShopUsersResolverResultItem> {
  const updatedUser = await patchShopsByIdUsersById(shopId, userId, requestData);
  return transformShopUser(updatedUser);
}

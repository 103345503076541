import { http } from '../../../instances';

export interface IGetAnalyticsErrorsRequestParams {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
}

export interface IGetAnalyticsErrorsResponseDataItem {
  /** Код ошибки */
  error: string;

  /** Количество платежей с указанным кодом ошибки */
  count: number;
}
export type IGetAnalyticsErrorsResponseData = IGetAnalyticsErrorsResponseDataItem[];

export async function getAnalyticsErrors(
  params: IGetAnalyticsErrorsRequestParams,
): Promise<IGetAnalyticsErrorsResponseData> {
  const { data } = await http.get<IGetAnalyticsErrorsResponseData>('/analytics/errors', { params });
  return data;
}

import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQuery } from '@tanstack/react-query';
import { StyleSheet } from 'react-native';

import { OrderList, useDashboardNavigation } from '@iokanx/dashboard/feature';
import { SELECTED_SHOP_ROLE, ShopUserRole } from '@iokanx/shared/data-access/constants';
import { Breakpoint, useTypedCurrentBreakpoint, wrapPage } from '@iokanx/shared/feature';
import { FAB } from '@iokanx/shared/ui/universal';

function Payments() {
  const navigation = useDashboardNavigation();

  function handlePressCreateOrder() {
    navigation.navigate('CreateOrder');
  }

  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  const { data: selectedShopRole } = useQuery([SELECTED_SHOP_ROLE], () => AsyncStorage.getItem(SELECTED_SHOP_ROLE));

  return (
    <>
      {selectedShopRole && ![ShopUserRole.Viewer].includes(selectedShopRole as ShopUserRole) && (
        <FAB
          style={styles.fab}
          icon="plus"
          onPress={handlePressCreateOrder}
          label={currentBreakpoint === Breakpoint.Desktop ? 'Создать заказ' : ''}
        />
      )}
      <OrderList />
    </>
  );
}

const styles = StyleSheet.create({
  fab: {
    position: 'absolute',
    margin: 8,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
});

export const PaymentsPage = wrapPage(Payments);

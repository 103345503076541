import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';
import { Select } from '@iokanx/shared/ui/universal';

import { useFormEdit } from '../../../helpers/useFormEdit';
import { organizationOfficialValidationSchema } from '../../../model/schemas';
import { DatePickerYear } from '../../Datepicker';
import { FormField } from '../../FormField/FormField';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { formatDateTime } from '../HolderStep/helpers';

const parsePersonalData = (officialShare: any) => ({
  first_name: officialShare.personal_data.first_name || '',
  last_name: officialShare.personal_data.last_name || '',
  patronymic: officialShare.personal_data.patronymic || '',
  nib: officialShare.personal_data.nib || '',
  number: officialShare.personal_data.number || '',
  is_resident: officialShare.personal_data.is_resident || '',
  issued_by: officialShare.personal_data.issued_by || '',
  issue_date: officialShare.personal_data.issue_date || '',
});

export const ProfileFormOfficials = ({
  officialShare,
  organizationId,
  isSubmitted,
}: {
  isSubmitted: boolean;
  organizationId: string;
  officialShare: any;
}) => {
  const queryClient = useQueryClient();

  const { mutate: removeOfficial } = useMutation(
    async () => {
      const { data: response } = await backendHttp.delete(
        `organizations/${organizationId}/shares/${officialShare?.id}`,
      );

      return response;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['shares']);
      },
    },
  );

  const { mutate: updateOfficial } = useMutation(
    async () => {
      const data = getValues();
      const { data: response } = await backendHttp.patch(
        `organizations/${organizationId}/shares/${officialShare?.id}`,
        {
          is_official: true,
          personal_data: {
            type: 'PHYSICAL',
            first_name: data.personal_data.first_name,
            last_name: data.personal_data.last_name,
            patronymic: data.personal_data.patronymic,
            nib: data.personal_data.nib,
            number: data.personal_data.number,
            issued_by: data.personal_data.issued_by,
            is_resident: data.personal_data.is_resident === 'true',
            issue_date: formatDateTime(new Date(data.personal_data.issue_date)),
          },
        },
      );
      return response;
    },
    {
      onSuccess: async () => {
        reset();
        handleIsEdit();
        await queryClient.invalidateQueries(['shares']);
      },
    },
  );

  const {
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      personal_data: parsePersonalData(officialShare),
    },
    resolver: yupResolver(organizationOfficialValidationSchema),
  });

  useEffect(() => {
    reset({
      personal_data: parsePersonalData(officialShare),
    });
  }, [officialShare]);

  const onSubmit = (data: any) => updateOfficial(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(officialShare), handleSubmit(onSubmit, onError));

  const handleButtonPress = () => (isDirty ? handlePress() : handleIsEdit());
  const handleRemoveOfficial = () => removeOfficial();

  return (
    <ProfileFormSection
      isSaved={isDisable}
      isRemovable={true}
      isSubmitted={isSubmitted}
      handleSubmit={handleButtonPress}
      handleRemove={handleRemoveOfficial}
    >
      <FormField name={'personal_data.first_name'} control={control} label={'Имя'} disabled={isDisable} />
      <FormField name={'personal_data.last_name'} control={control} label={'Фамилия'} disabled={isDisable} />
      <FormField name={'personal_data.patronymic'} control={control} label={'Отчество'} disabled={isDisable} />
      <FormField name={'personal_data.nib'} control={control} label={'ИИН'} disabled={isDisable} />
      <FormField
        name={'personal_data.number'}
        control={control}
        label={'№ Удостоверяющего документа'}
        disabled={isDisable}
      />
      <FormField name={'personal_data.issued_by'} control={control} label={'Кем выдан'} disabled={isDisable} />
      <Controller
        control={control}
        name={'personal_data.is_resident'}
        render={({ field: { onChange, value } }) => (
          <Item>
            <Select
              onChange={onChange}
              style={{ width: '100%' }}
              disabled={isDisable}
              value={String(value)}
              label={'Резиденство'}
              items={[
                { value: 'true', title: 'Является резидентом' },
                { value: 'false', title: 'Не является резидентом' },
              ]}
            />
          </Item>
        )}
      />

      <FormField
        name={'personal_data.number'}
        control={control}
        label={'№ Удостоверяющего документа'}
        disabled={isDisable}
      />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Item>
            <DatePickerYear
              disabled={isDisable}
              label="Дата выдачи"
              value={value && new Date(value)}
              onChange={onChange}
            />
          </Item>
        )}
        name={'personal_data.issue_date'}
      />
    </ProfileFormSection>
  );
};

import { INCOME_TYPE_I18N, IncomeType } from '@iokanx/shared/data-access/constants';

import { StatusChip, StatusChipPropsIconName, StatusChipVariant } from '../status-chip/status-chip';

export interface IIncomeTypeChipProps {
  type: IncomeType;
}

const incomeTypeVariant: Record<IncomeType, StatusChipVariant> = {
  [IncomeType.Debit]: StatusChipVariant.Failure,
  [IncomeType.Credit]: StatusChipVariant.Success,
};

const statusChipIconName: Record<IncomeType, StatusChipPropsIconName> = {
  [IncomeType.Debit]: 'minus-box-outline',
  [IncomeType.Credit]: 'plus-box-outline',
};

export function IncomeTypeChip(props: IIncomeTypeChipProps) {
  return (
    <StatusChip variant={incomeTypeVariant[props.type]} iconName={statusChipIconName[props.type]}>
      {INCOME_TYPE_I18N[props.type]}
    </StatusChip>
  );
}

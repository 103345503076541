import { Box, Stack } from '@mobily/stacks';
import { StyleSheet } from 'react-native';

import { IGetFulfilmentByIdResponseData } from '@iokanx/shared/data-access/api';
import { Caption, Card, Paragraph, SimpleCard, SimpleCardProps } from '@iokanx/shared/ui/universal';

import { ClipboardButton } from '../clipboard-button/clipboard-button';

export interface IFulfilmentCardProps {
  fulfilment: IGetFulfilmentByIdResponseData;
  onCardPress: SimpleCardProps['onPress'];
}

interface IContentItem {
  title: string;
  key: keyof Pick<IGetFulfilmentByIdResponseData, 'amount'>;
}
const contentItems: IContentItem[] = [
  {
    title: 'Сумма',
    key: 'amount',
  },
];

export function FulfilmentCard(props: IFulfilmentCardProps) {
  return (
    <SimpleCard title={props.fulfilment.amount} right={() => <Box marginRight={4}>''</Box>} onPress={props.onCardPress}>
      <Card.Content>
        {contentItems.map((item) => (
          <Stack key={item.key} horizontal space={2} style={styles.contentItem}>
            <Caption>{item.title}</Caption>
            <Paragraph>{props.fulfilment[item.key]}</Paragraph>
          </Stack>
        ))}
      </Card.Content>
      <Card.Actions>
        <ClipboardButton copiedText={''}>{props.fulfilment.date}</ClipboardButton>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  contentItem: {
    justifyContent: 'space-between',
  },
});

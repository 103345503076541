import { Stack } from '@mui/material';
import { DataGrid, GridColumns, ruRU } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { ScrollView } from 'react-native';

import { IncomeTypeChip } from '@iokanx/dashboard/ui';
import { IGetIncomeByIdResponseData, searchIncomesResolver } from '@iokanx/shared/data-access/api';

import { useMe } from '../auth';
import { IIncomeListProps } from '../income-list/income-list';

const columns: GridColumns<IGetIncomeByIdResponseData> = [
  {
    headerName: 'ID выплаты',
    field: 'id',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'IBAN',
    field: 'account',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Наименование',
    field: 'name',
    flex: 3,
    sortable: false,
  },
  {
    headerName: 'Тип',
    field: 'type',
    flex: 1,
    renderCell: (props) => <IncomeTypeChip type={props.value} />,
    sortable: false,
  },
  {
    headerName: 'Сумма',
    field: 'amountWithSymbol',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Дата создания',
    field: 'approvedAt',
    flex: 1,
    sortable: false,
  },
];

const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам, выплат не найдено',
};

export function IncomeListDesktop(props: IIncomeListProps) {
  const { data: incomes, isFetching } = useQuery(['incomes', props.form.watch()], () =>
    searchIncomesResolver({ ...props.form.watch(), limit: props.pageLimit }),
  );

  const { me } = useMe();

  return (
    <ScrollView>
      <Stack padding={2} spacing={2} marginBottom={8}>
        {props.filter}
        <DataGrid
          rows={incomes?.data || []}
          columnVisibilityModel={{
            shopId: !!me?.is_superuser,
          }}
          columns={columns}
          loading={isFetching}
          page={props.form.watch('page') - 1}
          onRowClick={(params) => props.onIncomePress(params.row)}
          onPageChange={(page) => props.form.setValue('page', page + 1)}
          rowCount={incomes?.count}
          localeText={localeText}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          pageSize={props.pageLimit}
          rowsPerPageOptions={[props.pageLimit]}
          paginationMode="server"
        />
      </Stack>
    </ScrollView>
  );
}

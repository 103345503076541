import { isEmpty } from 'lodash';

export const convertValuesToString = (object: any) => {
  if (isEmpty(object)) {
    return {};
  }

  const convertedObject: Record<string, string> = {};

  for (const key in object) {
    if (Object.keys(object).includes(key)) {
      convertedObject[key] = object[key].toString();
    }
  }
  return convertedObject;
};

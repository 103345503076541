import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';

import { Item } from '@iokanx/dashboard/feature';

import { OrganizationActualAddress } from './OrganizationActualAddress';
import { OrganizationLegalAddress } from './OrganizationLegalAddress';

import { ProfileFormSection } from '../../../../layouts/ProfileFormSection';

export const OrganizationAddressSection = () => {
  const [isSame, setIsSame] = useState<boolean>();

  const handleIsSameAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSame(event.currentTarget.checked);
  };

  return (
    <ProfileFormSection handleSubmit={null} isSaved={false}>
      <OrganizationLegalAddress isSameAddress={isSame} />

      <Item>
        <FormControlLabel
          label="Фактический адрес совпадает с юридическим"
          control={<Checkbox checked={isSame} onChange={handleIsSameAddress} />}
        />
      </Item>

      <OrganizationActualAddress isSameAddress={isSame} />
    </ProfileFormSection>
  );
};

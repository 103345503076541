import { Tiles } from '@mobily/stacks';
import { Controller, UseFormReturn } from 'react-hook-form';

import { ISearchPaymentLinksResolverParams } from '@iokanx/shared/data-access/api';
import { PaymentLinkType } from '@iokanx/shared/data-access/constants';
import { DateRangePicker, ISelectItem, Select, TextField } from '@iokanx/shared/ui/universal';

export interface IPaymentLinkListFilterProps {
  form: UseFormReturn<IPaymentLinkListFilterValues>;
}

export type IPaymentLinkListFilterValues = Pick<
  ISearchPaymentLinksResolverParams,
  'slug' | 'link_type' | 'from_dt' | 'to_dt'
>;

const ALL_VALUE_KEY = 'all';

const linkTypeItems: ISelectItem<PaymentLinkType | typeof ALL_VALUE_KEY>[] = [
  {
    value: PaymentLinkType.Regular,
    title: 'Фиксированная цена',
  },
  {
    value: PaymentLinkType.Ranged,
    title: 'Диапазон цен',
  },
  {
    value: ALL_VALUE_KEY,
    title: 'Все',
  },
];

export function PaymentLinkListFilter(props: IPaymentLinkListFilterProps) {
  return (
    <Tiles columns={[1, 3]} space={2}>
      <Controller
        control={props.form.control}
        name={'link_type'}
        render={({ field }) => (
          <Select
            items={linkTypeItems}
            value={field.value ?? ALL_VALUE_KEY}
            onChange={(value) => field.onChange(value === ALL_VALUE_KEY ? undefined : value)}
            onBlur={field.onBlur}
            label={'Тип ссылки'}
          />
        )}
      />
      <Controller
        control={props.form.control}
        render={({ field }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'Идентификатор ссылки'}
          />
        )}
        name={'slug'}
      />
      <DateRangePicker
        values={{ startDate: props.form.watch('from_dt'), endDate: props.form.watch('to_dt') }}
        onChangeValues={(values) => {
          props.form.setValue('from_dt', values.startDate);
          props.form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
    </Tiles>
  );
}

import { LinkingOptions } from '@react-navigation/native/lib/typescript/src/types';
import * as Linking from 'expo-linking';

import { DashboardStackParamList } from '../navigation';

const prefix = Linking.createURL('/');

export const dashboardLinking: LinkingOptions<DashboardStackParamList> = {
  prefixes: [prefix, 'https://dashboard.ioka.kz'],
  config: {
    screens: {
      SignIn: { path: 'sign-in' },
      ForgotPassword: { path: 'forgot-password' },
      ResetPassword: { path: 'reset-password' },
      Drawer: {
        screens: {
          Operations: {
            path: 'operations',
            screens: {
              Payments: 'payments',
              Refunds: 'refunds',
              PaymentLinks: 'payment-links',
            },
          },
          Profiles: {
            path: 'profiles',
            screens: {
              Profile: 'main',
            },
          },
          Merchants: {
            path: 'merchants',
            screens: { Shops: 'shops' },
          },
          Nps: {
            path: 'nps',
            screens: { Votes: 'votes' },
          },
          Users: {
            path: 'users',
            screens: {
              Owners: 'owners',
            },
          },
          Employees: {
            path: 'employees',
          },
          Analytics: {
            path: 'analytics',
          },
          Organizations: {
            path: 'organizations',
          },
          Contacts: {
            path: 'contacts',
          },
          Reports: {
            path: 'reports',
            screens: {
              Payouts: 'payouts',
              Revises: 'revises',
              Fulfilments: 'fulfilment',
              Incomes: 'incomes',
            },
          },
        },
      },
      Shop: { path: 'shops/:shopId' },
      User: { path: 'users/:userId' },
      Vote: { path: 'vote/:voteId' },
      CreateShop: { path: 'create-shop' },
      CreateUser: { path: 'create-user' },
      Config: { path: 'configs/:shopId/:key' },
      Order: { path: 'orders/:orderId' },
      CreateOrder: { path: 'create-order' },
      Payout: { path: 'reports/payouts/:payoutId' },
      CreatePayout: { path: 'create-payout' },
      Revise: { path: 'reports/revises/:reference' },
      Income: { path: 'reports/incomes/:incomeId' },
      Fulfilment: { path: 'reports/payouts/:shopId/:date' },
      AddEmployee: { path: 'add-employee' },
      CreatePaymentLink: { path: 'create-payment-link' },
      Registration: { path: 'registration' },
    },
  },
};

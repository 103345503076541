import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, TextField as MaterialTextField } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';

import { getOptionLabel, parseOrganizationsType } from './helpers';
import { IOked } from './types';

import { useFormEdit } from '../../../helpers/useFormEdit';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { organizationOKEDValidationSchema } from '../OrganizationInfoStep/model/schemas';

const postData = async (organizationId: string, selectedOption: any, isMain?: boolean) => {
  const { data: response } = await backendHttp.post(`organizations/${organizationId}/okeds`, {
    oked_id: selectedOption?.id,
    is_main: isMain,
  });

  return response;
};

const patchData = async (organizationId: string, okedId: string, selectedOption: any, isMain?: boolean) => {
  const { data: response } = await backendHttp.post(`organizations/${organizationId}/okeds/${okedId}`, {
    oked_id: selectedOption?.id,
    is_main: isMain,
  });

  return response;
};

interface IOKED {
  isMain?: boolean;
  organizationId: string;
  oked: any;
  isRemovable: boolean;
  isSubmitted: boolean;
}

export const OKED = ({ isMain, isSubmitted, organizationId, oked, isRemovable }: IOKED) => {
  const queryClient = useQueryClient();
  const [selectedOption, setSelectedOption] = useState<IOked | null>(null);

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      okedTypes: '',
      license: undefined,
    },
    resolver: yupResolver(organizationOKEDValidationSchema),
  });

  const { data: okedTypes } = useQuery(['oked', watch('okedTypes')], async (searchKey) => {
    const queryKey = searchKey?.queryKey.at(-1);

    if (isUndefined(queryKey)) return '';

    const url = queryKey ? '/dictionaries/oked' + '?' + 'code=' + queryKey : '/dictionaries/oked';

    const { data } = await backendHttp.get(url);

    return parseOrganizationsType(data);
  });

  const { data: okedType } = useQuery(['oked', oked?.oked_id], async () => {
    if (oked) {
      const { data } = await backendHttp.get(`/dictionaries/oked/${oked?.oked_id}`);

      return data;
    }

    return '';
  });

  const { mutate: mutateOked } = useMutation<{ oked_id: string; is_main: boolean }>(
    okedType
      ? () => patchData(organizationId, okedType.id, selectedOption, isMain)
      : () => postData(organizationId, selectedOption, isMain),
    {
      onSuccess: async () => {
        handleIsEdit();
        await queryClient.invalidateQueries(['okeds']);
      },
    },
  );

  const { mutate: removeOKED } = useMutation(
    async () => {
      const { data: response } = await backendHttp.delete(`organizations/${organizationId}/okeds/${oked?.id}`);

      return response;
    },
    {
      onSuccess: async () => {
        await Promise.all([queryClient.invalidateQueries(['okeds'])]);
      },
    },
  );

  const handleInputChange = (e: any, value: any) => {
    setValue('okedTypes', value);
  };

  const handleValueChange = (e: any, value: any) => {
    setSelectedOption(value);
  };

  const handleFilterOptions = (options: IOked[], state: any) => {
    const inputValue = state.inputValue.toLowerCase();

    return options.filter((option) => option.value?.toString().includes(inputValue));
  };

  useEffect(() => {
    if (okedType) {
      const parsedOkedType = {
        title: okedType.name,
        value: okedType.code,
        id: okedType.id,
      };

      setSelectedOption(parsedOkedType);
    }
  }, [okedType]);

  const onSubmit = () => {
    const data: any = { oked_id: selectedOption?.id, is_main: false };
    mutateOked(data);
  };

  const onError = (e: any) => console.error(e);
  const handleRemoveOked = () => removeOKED();

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(okedType), handleSubmit(onSubmit, onError));

  return (
    <ProfileFormSection
      title={oked?.title}
      isSaved={isDisable}
      isRemovable={isRemovable}
      isSubmitted={isSubmitted}
      handleSubmit={handlePress}
      handleRemove={handleRemoveOked}
    >
      <Controller
        control={control}
        render={() => (
          <Item>
            <Autocomplete
              freeSolo
              disabled={isDisable}
              value={selectedOption}
              filterOptions={handleFilterOptions}
              getOptionLabel={getOptionLabel}
              onChange={handleValueChange}
              onInputChange={handleInputChange}
              renderInput={(params) => (
                <MaterialTextField
                  {...params}
                  inputProps={{ ...params.inputProps, type: 'search' }}
                  label={'Общий классификатор видов экономической деятельности (ОКЭД)'}
                />
              )}
              options={okedTypes}
            />
          </Item>
        )}
        name={'okedTypes'}
      />
    </ProfileFormSection>
  );
};

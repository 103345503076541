import { compactObject } from '@iokanx/shared/util';

import { analyticsNPS, IAnalyticsNpsRequestParams, IAnalyticsNpsResponseData } from '../../resources';

export interface IAnalyticsNpsResolverResult {
  promoters: string;
  detractors: string;
  nps: string;
}

export interface IAnalyticsNpsRequestResolverParams extends IAnalyticsNpsRequestParams {
  email?: string;
  type_nps: string;
}

export async function analyticsNpsResolver(params: IAnalyticsNpsRequestParams): Promise<IAnalyticsNpsResolverResult> {
  const analytics = await analyticsNPS(compactObject(params));
  return transformAnalytics(analytics);
}

export async function transformAnalytics(items: IAnalyticsNpsResponseData[]): Promise<IAnalyticsNpsResolverResult> {
  let total = 0;
  let promoters = 0;
  let detractors = 0;
  for (const item of items) {
    const { id, count } = item;
    total += count;
    detractors += id >= 0 && id <= 6 ? count : 0;
    promoters += id >= 9 && id <= 10 ? count : 0;
  }
  const promotersPercent = total ? (promoters * 100) / total : 0;
  const detractorsPercent = total ? (detractors * 100) / total : 0;
  return {
    promoters: promotersPercent.toFixed(2),
    detractors: detractorsPercent.toFixed(2),
    nps: (promotersPercent - detractorsPercent).toFixed(2),
  };
}

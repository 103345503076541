import { messages, RATING_HALF_INDEX, ZERO_INDEX } from './constants';

export const getDefaultState = () => {
  return {
    title: messages.default.title,
  };
};

export const splitIntoTwoArrays = (array: number[]) => {
  return [array.slice(ZERO_INDEX, RATING_HALF_INDEX), array.slice(RATING_HALF_INDEX)];
};

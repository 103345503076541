import axios from 'axios';

import { getEnv } from '@iokanx/shared/util';

import { setAccessTokenInterceptor } from '../shared/interceptors/set-access-token-interceptor';

export const bffHttp = axios.create({
  baseURL: getEnv('BFF_API_URL') || process.env.NEXT_PUBLIC_BFF_API_URL,
});

bffHttp.interceptors.request.use(setAccessTokenInterceptor);

import { FillView } from '@mobily/stacks';

import { ResetPasswordForm } from '@iokanx/dashboard/feature';
import { wrapPage } from '@iokanx/shared/feature';

function ResetPassword() {
  return (
    <FillView alignX={'center'} alignY={'center'} padding={4}>
      <ResetPasswordForm />
    </FillView>
  );
}

export default wrapPage(ResetPassword);

import { AnalyticsRounded, Description, Monitor, Payment, SupportAgent, Workspaces } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { CSSProperties } from 'react';

import { NPSDesktopTitleStyles, NPSSubtitleStyles } from '../styles/NPSDesktopStyles';

const first = [
  {
    title: 'Подключение',
    icon: <Workspaces />,
    name: 'CONNECTION',
  },
  {
    title: 'Техническая интеграция',
    icon: <Monitor />,
    name: 'TECHNICAL_INTEGRATION',
  },
  {
    title: 'Прием платежей',
    icon: <Payment />,
    name: 'PAYMENT',
  },
];

const second = [
  {
    title: 'Служба поддержки',
    icon: <SupportAgent />,
    name: 'SUPPORT',
  },
  {
    title: 'Личный кабинет',
    icon: <AnalyticsRounded />,
    name: 'CABINET',
  },
  {
    title: 'Отчеты по платежам',
    icon: <Description />,
    name: 'PAYMENT_REPORTS',
  },
];

const tagStyles = (isActive: boolean) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: isActive ? '1px solid #63F' : '1px solid #F1EDFD',
  borderRadius: '12px',
  padding: '24px 12px',
  gap: '12px',
  width: '100%',
});

export const NPSMobileTextareaStyles = (value: string): CSSProperties => ({
  boxSizing: 'border-box',
  resize: 'none',
  width: '100%',
  height: '50px',
  padding: '12px',
  fontSize: '18px',
  fontFamily: 'Roboto_400Regular, sans-serif',
  border: value ? '1px solid #63F' : '1px solid #F1EDFD',
  borderRadius: '12px',
  outline: 'none',
});

const NPSTagLayoutStyles: CSSProperties = {
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

export const NPSSecondStep = ({ customTag, setCustomTag, handleSelect, tags, isMobile, text }: any) => {
  return (
    <>
      <Stack>
        <Typography component="legend" sx={NPSDesktopTitleStyles}>
          {text.title}
        </Typography>

        <Typography component="legend" sx={NPSSubtitleStyles}>
          Вы можете выбрать несколько вариантов
        </Typography>
      </Stack>

      <Stack direction={'row'} sx={{ width: '100%', gap: '12px' }}>
        {first.map(({ title, icon, name }, index: number) => {
          return (
            <Box key={`1.${index}`} sx={tagStyles(tags.includes(name))}>
              <Box onClick={handleSelect} id={name} sx={NPSTagLayoutStyles} />
              {icon}
              <Typography textAlign={'center'} fontSize={isMobile ? '12px' : '14px'}>
                {title}
              </Typography>
            </Box>
          );
        })}
      </Stack>

      <Stack direction={'row'} sx={{ width: '100%', gap: '12px' }}>
        {second.map(({ title, icon, name }, index: number) => {
          return (
            <Box key={`2.${index}`} sx={tagStyles(tags.includes(name))}>
              <Box onClick={handleSelect} id={name} sx={NPSTagLayoutStyles} />
              {icon}
              <Typography textAlign={'center'} fontSize={isMobile ? '12px' : '14px'}>
                {title}
              </Typography>
            </Box>
          );
        })}
      </Stack>

      <textarea
        value={customTag}
        onChange={(e) => setCustomTag(e.target.value)}
        placeholder={'Другое'}
        style={NPSMobileTextareaStyles(customTag)}
      />
    </>
  );
};

import { Delete, Download } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Input, Paper, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { ConditionalComponent } from '../../ConditionalComponent';

const inputContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 1,
  boxShadow: 'none',
  border: 'none',
  margin: '4px 0',
};

interface IFileUploadField {
  id: string;
  title: string;
  control: any;
  fileTypeId: string;
  uploadFile: any;
  deleteFile: any;
  // downloadFile: any;
}

export const FileUploadField = ({
  id,
  control,
  title,
  fileTypeId,
  uploadFile,
  deleteFile,
}: // downloadFile,
IFileUploadField) => {
  const { organizationId, isSubmitted } = useProfileFormContext();
  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = () => {
        uploadFile({
          file: selectedFile,
          file_type_id: fileTypeId,
          comment: title,
        });
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  // const handleDownload = (e: any) => {
  //   e.preventDefault();
  //   downloadFile(id);
  //   console.log('downloaded');
  // };

  const handleDelete = (e: any) => {
    e.preventDefault();
    deleteFile(id);
  };

  return (
    <Controller
      name={String(fileTypeId)}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Paper elevation={3} sx={inputContainer}>
            <Input
              disabled={false}
              sx={{ display: 'none' }}
              id={String(fileTypeId)}
              type="file"
              disableUnderline
              onChange={(event) => {
                onChange(event);
                handleFileChange(event);
              }}
            />
            <label
              htmlFor={String(fileTypeId)}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography component="p" variant="body1" gutterBottom sx={{ maxWidth: '60%' }}>
                {title}
              </Typography>

              <Box display="flex" sx={{ gap: '8px' }}>
                {value ? (
                  <>
                    <a
                      href={`https://dev.ioka.kz/v2/organizations/${organizationId}/files/${id}/download`}
                      style={{ padding: 0, margin: 0 }}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <Button
                        // onClick={handleDownload}
                        variant="contained"
                        component="span"
                        sx={{ padding: '6px 12px', minWidth: 'fit-content' }}
                      >
                        <Download sx={{ fontSize: '18px' }} />
                      </Button>
                    </a>
                    <ConditionalComponent
                      condition={!isSubmitted}
                      component={
                        <Button
                          onClick={handleDelete}
                          variant="contained"
                          component="span"
                          sx={{ padding: '6px 12px', minWidth: 'fit-content' }}
                        >
                          <Delete sx={{ fontSize: '18px' }} />
                        </Button>
                      }
                    />
                  </>
                ) : (
                  <ConditionalComponent
                    condition={!isSubmitted}
                    component={
                      <Button
                        variant="contained"
                        component="span"
                        sx={{ padding: '6px 12px', minWidth: 'fit-content' }}
                      >
                        <CloudUploadIcon sx={{ fontSize: '18px' }} />
                      </Button>
                    }
                  />
                )}
              </Box>
            </label>
          </Paper>
        );
      }}
    />
  );
};

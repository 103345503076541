import { styled } from '@mui/material';
import Popover, { PopoverProps } from '@mui/material/Popover';
import React from 'react';

const StyledContainer = styled('div')(() => {
  return {
    width: 300,
    padding: 8,
  };
});

type ColorPopoverProps = PopoverProps & {
  children: React.ReactNode;
};

export const ColorPopover = (props: ColorPopoverProps) => {
  const { children, className, ...restPopoverProps } = props;

  return (
    <Popover
      className={`MuiColorInput-Popover ${className || ''}`}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...restPopoverProps}
    >
      <StyledContainer>{children}</StyledContainer>
    </Popover>
  );
};

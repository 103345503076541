import { backendHttp } from '../../../../instances';
import { IGetPayoutByIdResponseData } from '../index';

export type IPostPayoutsByIdStatusResponseData = IGetPayoutByIdResponseData;

/** Запрос статуса только для статуса ACCEPTED */
export async function postPayoutsByIdStatus(payoutId: string): Promise<IPostPayoutsByIdStatusResponseData> {
  const { data } = await backendHttp.post<IPostPayoutsByIdStatusResponseData>(`/reports/payouts/${payoutId}/status`);
  return data;
}

import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { GovernmentConnectionForm } from './GovernmentConnectionForm';
import { filterOfficialsShares } from './helpers';
import { ProfileFormOfficials } from './ProfileFormOfficials';

import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { FormButton } from '../../Button';
import { ConditionalComponent } from '../../ConditionalComponent';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { ProfileFormStep } from '../../layouts/ProfileFormStep';
import { IFormSection } from '../OrganizationInfoStep/types';

export const GovernmentConnectionStep: FC<IFormSection> = (props) => {
  const { organizationId, isSubmitted } = useProfileFormContext();
  const [isMore, setIsMore] = useState(false);
  const [officials, setOfficials] = useState<any[]>([]);

  const { data: shares } = useQuery(['shares'], async () => {
    try {
      const { data } = await backendHttp.get(`organizations/${organizationId}/shares`);

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  });

  const handleFormDisplay = () => setIsMore((isMore) => !isMore);

  useEffect(() => {
    const filteredShares = filterOfficialsShares(shares);

    setOfficials(filteredShares);

    setIsMore(false);
  }, [shares]);

  return (
    <ProfileFormStep {...props}>
      <ProfileFormSection
        title={'Связь с государственными должностными лицами'}
        tooltip={{
          text: 'Является ли кто-либо из учредителей/руководителей, перечисленных выше, действующим или бывшим Государственным должностным лицом, как этот термин определен в примечании. Укажите данное лицо (лиц) в таблице',
        }}
        isSaved={true}
        handleSubmit={null}
      >
        {isEmpty(officials) ? (
          <GovernmentConnectionForm
            isEmpty={isEmpty(officials)}
            isSubmitted={isSubmitted}
            organizationId={organizationId}
            form={false}
          />
        ) : (
          <>
            {officials?.map((officialShare, index) => (
              <ProfileFormOfficials
                isSubmitted={isSubmitted}
                organizationId={organizationId}
                officialShare={officialShare}
                key={index}
              />
            ))}

            <ConditionalComponent
              condition={!isSubmitted}
              component={<FormButton onClick={handleFormDisplay}>{isMore ? 'Скрыть' : 'Добавить'}</FormButton>}
            />

            <ConditionalComponent
              condition={isMore}
              component={
                <GovernmentConnectionForm isSubmitted={isSubmitted} organizationId={organizationId} form={true} />
              }
            />
          </>
        )}
      </ProfileFormSection>
    </ProfileFormStep>
  );
};

import { ShopUserRole } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../../../instances';
import { IGetShopsByIdUsersResponseDataItem } from '../index';

export interface IPatchShopsByIdUsersByIdRequestData {
  role_code: ShopUserRole;
}

export async function patchShopsByIdUsersById(
  shopId: string,
  userId: string,
  requestData: IPatchShopsByIdUsersByIdRequestData,
): Promise<IGetShopsByIdUsersResponseDataItem> {
  const { data } = await backendHttp.patch<IGetShopsByIdUsersResponseDataItem>(
    `/shops/${shopId}/users/${userId}`,
    requestData,
  );
  return data;
}

export function deleteShopsByIdUsersById(shopId: string, userId: string): Promise<void> {
  return backendHttp.delete(`/shops/${shopId}/users/${userId}`);
}

import moment from 'moment';

import { randomColor } from '@iokanx/shared/util';

import {
  getAnalyticsErrors,
  IGetAnalyticsErrorsRequestParams,
  IGetAnalyticsErrorsResponseData,
  IGetAnalyticsErrorsResponseDataItem,
} from '../../resources';

export interface IFindErrorAnalyticsResolverResultItem extends IGetAnalyticsErrorsResponseDataItem {
  color: string;
  percentage: string;
}
export type IFindErrorAnalyticsResolverResult = IFindErrorAnalyticsResolverResultItem[];

export async function findErrorAnalyticsResolver(
  _params: IGetAnalyticsErrorsRequestParams,
): Promise<IFindErrorAnalyticsResolverResult> {
  const params = { ..._params };
  if (params.from_dt && !params.to_dt) {
    params.to_dt = moment(params.from_dt).endOf('day').toDate();
  }

  const data = await getAnalyticsErrors(params);
  return prepareErrorAnalyticsResolver(data);
}

function prepareErrorAnalyticsResolver(data: IGetAnalyticsErrorsResponseData): IFindErrorAnalyticsResolverResult {
  const total = data.reduce((acc, item) => acc + item.count, 0);
  return data.map((item) => transformErrorAnalytics(item, total)).sort((a, b) => b.count - a.count);
}

function transformErrorAnalytics(
  item: IGetAnalyticsErrorsResponseDataItem,
  totalCount: number,
): IFindErrorAnalyticsResolverResultItem {
  const color = randomColor();
  const percentage = ((item.count / totalCount) * 100).toFixed(2) + '%';

  return { ...item, color, percentage };
}

import { IGetNpsByIdResponseData } from './by-id';

import { backendHttp } from '../../instances';

export type IFindNpsRequestParams = {
  page?: number;
  limit?: number;
  to_dt?: Date;
  from_dt?: Date;
};

export interface IGetNpsResponseData<IDataItem = IGetNpsByIdResponseData> {
  count: number;
  data: IDataItem[];
}

export async function findNPS(params: IFindNpsRequestParams): Promise<Readonly<IGetNpsResponseData>> {
  const { data } = await backendHttp.get<IGetNpsResponseData>('/support/nps', { params });
  return data;
}

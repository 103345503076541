import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColumns, GridEventListener, ruRU } from '@mui/x-data-grid';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQuery } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ScrollView } from 'react-native';

import { DashboardPaymentStatusChip, ShopNameChip } from '@iokanx/dashboard/ui';
import {
  findDashboardPaymentsResolver,
  IFindDashboardPaymentsResolverResultItem,
} from '@iokanx/shared/data-access/api';
import { SELECTED_SHOP_ROLE, ShopUserRole } from '@iokanx/shared/data-access/constants';

import { StatusChipWrapper } from '../../../../ui/src/lib/dashboard-payment-status-chip/status-chip-wrapper';
import { useMe } from '../auth';
import { IOrderListProps } from '../order-list/order-list';

const columns: GridColumns<IFindDashboardPaymentsResolverResultItem> = [
  {
    headerName: 'Дата создания',
    field: 'createdAt',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Магазин',
    field: 'shopId',
    flex: 1,
    renderCell: (props) => {
      return (
        <Suspense fallback={<Typography>loading...</Typography>}>
          <ShopNameChip shopId={props.value} />
        </Suspense>
      );
    },
    sortable: false,
  },
  {
    headerName: 'ID заказа',
    field: 'id',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Номер заказа',
    field: 'externalId',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (props) => {
      return (
        <StatusChipWrapper>
          <DashboardPaymentStatusChip status={props.value} />
        </StatusChipWrapper>
      );
    },
    width: 200,
    sortable: false,
  },
  {
    headerName: 'Сумма',
    field: 'amountWithSymbol',
    type: 'number',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Комиссия',
    field: 'processingFeeWithSymbol',
    type: 'number',
    flex: 1,
    sortable: false,
  },
];

const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам, платежей не найдено',
};

export function OrderListDesktop(props: IOrderListProps) {
  const { data: orders, isFetching } = useQuery(['orders', props.form.watch()], () =>
    findDashboardPaymentsResolver({ ...props.form.watch(), limit: props.pageLimit }),
  );

  const { me } = useMe();
  const { data: selectedShopRole } = useQuery([SELECTED_SHOP_ROLE], () => AsyncStorage.getItem(SELECTED_SHOP_ROLE));

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    props.onOrderPress(params.row);
  };

  return (
    <ScrollView>
      <Stack padding={2} spacing={2} marginBottom={8}>
        {props.filter}

        <DataGrid
          rows={orders?.data || []}
          columnVisibilityModel={{
            shopId: Boolean(me?.is_superuser),
            processingFeeWithSymbol: Boolean(![ShopUserRole.Manager].includes(selectedShopRole as ShopUserRole)),
          }}
          onRowClick={handleRowClick}
          columns={columns}
          loading={isFetching}
          page={props.form.watch('page') - 1}
          onPageChange={(page) => props.form.setValue('page', page + 1)}
          rowCount={orders?.count}
          localeText={localeText}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          pageSize={props.pageLimit}
          rowsPerPageOptions={[props.pageLimit]}
          paginationMode="server"
        />
      </Stack>
    </ScrollView>
  );
}

import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { findShopByIdResolver } from '@iokanx/shared/data-access/api';

export interface IShopNameChipProps {
  shopId: string;
}

const useShopName = (shopId: string): any => {
  const { data: shop, isLoading } = useQuery(['shop', shopId], async () => findShopByIdResolver({ shopId: shopId }));
  return { shop, isLoading };
};

export function ShopNameChip(props: IShopNameChipProps) {
  const { shop } = useShopName(props.shopId);
  return <Typography>{shop.displayName}</Typography>;
}

import { Autocomplete, FormControl, TextField } from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { findShops, switchShopResolver } from '@iokanx/shared/data-access/api';
import { SELECTED_SHOP_ID } from '@iokanx/shared/data-access/constants';
import { useSnackbar } from '@iokanx/shared/feature';

import { useIsSignedIn, useMe } from '../auth';
import { useSignOut } from '../auth/use-sign-out/use-sign-out';

export function ShopsMenu() {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const { me } = useMe();
  const { signOut } = useSignOut();
  const { isSignedIn } = useIsSignedIn();

  const [inputValue, setInputValue] = useState('');

  const { data: selectedShopId, refetch: reFetchSelectedShopId } = useQuery([SELECTED_SHOP_ID], () =>
    AsyncStorage.getItem(SELECTED_SHOP_ID),
  );

  const { data: shops = [] } = useQuery(['shops'], () => findShops(), {
    onSuccess: async (data) => {
      if (data.length <= 0) {
        snackbar.show('У вас еще нет магазина', { type: 'warning' });
        signOut();
      } else {
        const shopAccessToken = await AsyncStorage.getItem('shop_access_token');
        if (!shopAccessToken && selectedShopId && !me?.is_superuser) {
          return switchShopResolver(selectedShopId);
        }
      }
    },
    enabled: isSignedIn,
  });

  const hasShops: boolean = shops.length > 0;
  const selectedShop = shops.find((shop) => shop.id === selectedShopId);

  const { mutate: switchShop } = useMutation(switchShopResolver, {
    onSuccess: async () => {
      await reFetchSelectedShopId();
      await queryClient.invalidateQueries();
    },
  });

  useEffect(() => {
    if (hasShops && !selectedShopId && me?.is_superuser === false) {
      switchShop(shops[0].id);
    }
  }, [shops, selectedShopId, hasShops, switchShop]);

  const value = {
    id: selectedShop?.id,
    label: selectedShop?.displayName,
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        value={selectedShopId ? value : null}
        onChange={(event, newValue) => {
          if (newValue?.id) switchShop(newValue.id);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          if (newInputValue === '' && me?.is_superuser) switchShop('');
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={shops.map((option) => ({ id: option.id, label: option?.displayName }))}
        renderInput={(params) => <TextField {...params} label="Все магазины" />}
      />
    </FormControl>
  );
}

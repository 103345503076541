import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { ComponentProps, PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Chip } from 'react-native-paper';

export enum StatusChipVariant {
  Success,
  Loading,
  Failure,
}

export const StatusChipTextColor: Record<StatusChipVariant, string> = {
  [StatusChipVariant.Success]: '#10b981',
  [StatusChipVariant.Loading]: '#64748b',
  [StatusChipVariant.Failure]: '#ef4444',
};

export type StatusChipPropsIconName = ComponentProps<typeof Icon>['name'];

export interface StatusChipProps {
  variant: StatusChipVariant;
  iconName: StatusChipPropsIconName;
}

export function StatusChip(props: PropsWithChildren<StatusChipProps>) {
  return (
    <Chip
      icon={() => <Icon name={props.iconName} color={StatusChipTextColor[props.variant]} />}
      style={styles[props.variant]}
      textStyle={textStyles[props.variant]}
    >
      {props.children}
    </Chip>
  );
}

const styles = StyleSheet.create({
  [StatusChipVariant.Success]: {
    backgroundColor: '#ecfdf5',
  },
  [StatusChipVariant.Loading]: {
    backgroundColor: '#f1f5f9',
  },
  [StatusChipVariant.Failure]: {
    backgroundColor: '#fef2f2',
  },
});

const textStyles = StyleSheet.create({
  [StatusChipVariant.Success]: {
    color: StatusChipTextColor[StatusChipVariant.Success],
  },
  [StatusChipVariant.Loading]: {
    color: StatusChipTextColor[StatusChipVariant.Loading],
  },
  [StatusChipVariant.Failure]: {
    color: StatusChipTextColor[StatusChipVariant.Failure],
  },
});

import { IPostShopResponseData } from '../../resources';
import { createUser } from '../../resources/users';

export interface ICreateUserResolverParams {
  first_name: string;
  last_name: string;
  patronymic?: string;
  phone: string;
  email: string;
  password: string;
}

export type ICreateUserResolverResult = IPostShopResponseData;

export function createUserResolver({
  ...params
}: ICreateUserResolverParams): Promise<Readonly<ICreateUserResolverResult>> {
  return createUser({
    ...params,
  });
}

import { OrderEventName } from '@iokanx/shared/data-access/constants';

import { http } from '../../../../instances';

export interface IGetOrderByIdEventsResponseDataItem {
  /** Идентификатор события */
  id: string;
  /** Событие по заказу */
  name: OrderEventName;
  /** Время создания события */
  created_at: string;
  /** Идентификатор заказа */
  order_id: string;
  /** Идентификатор платежа */
  payment_id: string | null;
  /** Идентификатор возврата */
  refund_id: string | null;
  /** Параметр MD для 3DSecure авторизации */
  md: string | null;
  /** Параметр PaReq для 3DSecure авторизации */
  pa_req: string | null;
  /** URL ACS сервера банка эмиттера */
  acs_url: string | null;
  /** URL терминала для отправки ответа 3DSecure */
  term_url: string | null;
  /** Ссылка для открытия формы верификации 3DSecure от Банка */
  action_url: string | null;
  /** Код ошибки */
  code: string | null;
  /** Описание ошибки */
  message: string | null;
  redirect_url: string | null;
  iin: string | null;
  phone: string | null;
  period: string | null;
  amount: string | null;
  monthly_payment: string | null;
}
export type IGetOrderByIdEventsResponseData = IGetOrderByIdEventsResponseDataItem[];

export async function getOrderByIdEvents(orderId: string): Promise<IGetOrderByIdEventsResponseData> {
  const { data } = await http.get<IGetOrderByIdEventsResponseData>(`/orders/${orderId}/events`);
  return data;
}

import { backendHttp, IGetUsersResponseDataItem } from '@iokanx/shared/data-access/api';
import { UserStatus } from '@iokanx/shared/data-access/constants';

export interface IPatchUserByIdRequestData {
  first_name: string;
  last_name: string;
  email: string;
  status: UserStatus;
}

export async function updateUserById(
  userId: string,
  requestData: IPatchUserByIdRequestData,
): Promise<IGetUsersResponseDataItem> {
  const { data } = await backendHttp.patch<IGetUsersResponseDataItem>(`/users/${userId}`, requestData);
  return data;
}

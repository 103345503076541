import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useQuery } from '@tanstack/react-query';
import { ScrollView } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { DashboardStackParamList } from '@iokanx/dashboard/feature';
import { IncomeTypeChip } from '@iokanx/dashboard/ui';
import { findIncomeByIdResolver } from '@iokanx/shared/data-access/api';
import { IncomeType } from '@iokanx/shared/data-access/constants';
import { wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { List } from '@iokanx/shared/ui/universal';

export type IIncomePageProps = NativeStackScreenProps<DashboardStackParamList, 'Income'>;

function Income(props: IIncomePageProps) {
  const { data: income } = useQuery(['income', props.route.params], () => findIncomeByIdResolver(props.route.params));
  if (!income) return null;

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    bincome: 'none',
  }));

  return (
    <ScrollView>
      <List.Section title={' '}>
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Тип</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <IncomeTypeChip type={income.type as IncomeType} />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Время</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.approvedAt}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Идентификатор</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.id}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Референс</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.external_id}</Item>
          </Grid>
        </Grid>
      </List.Section>
      <List.Section title={' '}>
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Сумма</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.amountWithSymbol}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Наименование</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.name}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Описание</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.narrative}</Item>
          </Grid>
        </Grid>
      </List.Section>
      <List.Section title={' '}>
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Банк</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.bank}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">IDN</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.idn}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">КНП</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{income.knp}</Item>
          </Grid>

          {income.error && (
            <>
              <Grid item xs={6}>
                <Item className="infoTitle">Причина ошибки</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{income.error.message}</Item>
              </Grid>
            </>
          )}
        </Grid>
      </List.Section>
    </ScrollView>
  );
}

export default wrapPage(
  wrapModalPage(Income, {
    position: 'Right',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'Income'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Reports', params: { screen: 'Incomes' } });
        navigation.push('Income', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

import { getShops, IGetAdminShopsRequestParams, IShop } from '../../resources';

export type IFindShopsParams = IGetAdminShopsRequestParams;

export interface IFindShopsResultItem extends IShop {
  /** Нормализованное название магазина  */
  displayName: string;
  bin?: string;
  iban?: string;
  email?: string;
  phone?: string;
  status?: string;
  organization_id: null | string;
  created_at?: string;
}
export type IFindShopsResult = IFindShopsResultItem[];

export async function findShops(params?: IFindShopsParams): Promise<IFindShopsResult> {
  const shops = await getShops(params);
  return shops.map(transformShop);
}

function transformShop(shop: IShop): IFindShopsResultItem {
  return {
    ...shop,
    displayName: shop.display_name || shop.name,
  };
}

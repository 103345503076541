export enum PaymentStatus {
  // Ожидает подтверждения оплаты
  Pending = 'PENDING',
  // Ожидает подтверждения оплаты
  RequiresAction = 'REQUIRES_ACTION',
  // Сумма авторизована после двухфакторки
  Approved = 'APPROVED',
  // Сумма списана полностью после однофакторки или двухфакторки
  Captured = 'CAPTURED',
  // Авторизованная сумма отменена полностью
  Cancelled = 'CANCELLED',
  // Попытка платежа неуспешна
  Declined = 'DECLINED',
}

export const PAYMENT_STATUS_TITLE: Record<PaymentStatus, string> = {
  [PaymentStatus.Pending]: 'В обработке',
  [PaymentStatus.RequiresAction]: '3D Secure',
  [PaymentStatus.Approved]: 'Авторизован',
  [PaymentStatus.Captured]: 'Списан',
  [PaymentStatus.Cancelled]: 'Отменен',
  [PaymentStatus.Declined]: 'Отклонен',
};

export const PAYMENT_STATUS_COLOR: Record<PaymentStatus, string> = {
  [PaymentStatus.Pending]: '#64748B',
  [PaymentStatus.RequiresAction]: '#F8BC3B',
  [PaymentStatus.Approved]: '#334155',
  [PaymentStatus.Captured]: '#10B981',
  [PaymentStatus.Cancelled]: '#B8CCE0',
  [PaymentStatus.Declined]: '#EF4444',
};

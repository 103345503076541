import { IGetPayoutByIdResponseData } from './by-id';

import { backendHttp } from '../../instances';

export type IGetPayoutsRequestParams = {
  page?: number;
  limit?: number;
  to_dt?: Date;
  from_dt?: Date;
};

export interface IGetPayoutsResponseData<IDataItem = IGetPayoutByIdResponseData> {
  count: number;
  data: IDataItem[];
}

/** Поиск возвратов по фильтрам */
export async function getPayouts(params: IGetPayoutsRequestParams): Promise<Readonly<IGetPayoutsResponseData>> {
  const { data } = await backendHttp.get<IGetPayoutsResponseData>('/reports/payouts', { params });
  return data;
}

export interface IPostPayoutRequestRecipient {
  iin: string;
  iban: string;
  bank: string;
  name: string;
  knp: number;
  kbe: number;
  description: string;
}

export interface IPostPayoutRequestData {
  shop_id: string;
  account_id: string;
  from_dt: string;
  to_dt: string;
  amount: number;
  acquirer: string;
  recipient: IPostPayoutRequestRecipient;
}

export async function createPayout(requestData: IPostPayoutRequestData): Promise<Readonly<IGetPayoutByIdResponseData>> {
  const { data } = await backendHttp.post<IGetPayoutByIdResponseData>('/reports/payouts', requestData);
  return data;
}

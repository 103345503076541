import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { isFunction } from 'lodash';
import { useState } from 'react';

import { PaperThemeProvider } from '@iokanx/shared/feature';
import { Button } from '@iokanx/shared/ui/universal';

import { IAlertProps } from '../utils';

export interface IWebAlertProps extends IAlertProps {
  onCancel: () => void;
}

export function WebAlert(props: IWebAlertProps) {
  const [open, setOpen] = useState(true);

  function wrapButtonOnPress(func?: () => void) {
    return () => {
      if (isFunction(func)) {
        func();
      }
      handleCancel();
    };
  }

  function handleCancel() {
    setOpen(false);
    props.onCancel();
  }

  return (
    <PaperThemeProvider>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        {props.message && (
          <DialogContent>
            <DialogContentText>{props.message}</DialogContentText>
          </DialogContent>
        )}
        {props.buttons?.length && (
          <DialogActions>
            {props.buttons.map((button, index) => (
              <Button key={index} onPress={wrapButtonOnPress(button.onPress)} mode={'text'}>
                {button.text}
              </Button>
            ))}
          </DialogActions>
        )}
      </Dialog>
    </PaperThemeProvider>
  );
}

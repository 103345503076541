import { Stack } from '@mobily/stacks';
import { useQuery } from '@tanstack/react-query';
import { useReducer } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip } from 'victory-native';

import { VictoryMaterialTheme } from '@iokanx/dashboard/ui';
import { getEmitterAnalyticsResolver } from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { SimpleCard, Card, Button } from '@iokanx/shared/ui/universal';

export interface IBankAnalyticsProps {
  from_dt?: Date;
  to_dt?: Date;
}

const PADDING = 16;
const TOP_CHART_COUNT = 10;

export function BankAnalytics(props: IBankAnalyticsProps) {
  const [fullErrorAnalytics, toggleFullErrorAnalytics] = useReducer((state) => !state, false);

  const emitterAnalyticsQuery = useQuery(['emitter-analytics', props], () => getEmitterAnalyticsResolver(props));

  const chartData = emitterAnalyticsQuery.data?.slice(0, fullErrorAnalytics ? undefined : TOP_CHART_COUNT);
  const chartDataLength = chartData?.length ?? 0;

  const windowDimensions = useWindowDimensions();
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const victoryChartWidth = (windowDimensions.width - 64) * (currentBreakpoint === Breakpoint.Desktop ? 0.375 : 1);
  const victoryChartHeight = (victoryChartWidth / (victoryChartWidth / 175)) * (chartDataLength * 0.2);

  return (
    <SimpleCard title={'Банки'} cardStyle={styles.container}>
      <Card.Content>
        <Stack space={2}>
          <VictoryChart
            horizontal
            theme={VictoryMaterialTheme}
            width={victoryChartWidth}
            height={victoryChartHeight}
            padding={{
              left: 100 + PADDING,
              right: 50 + PADDING,
              top: PADDING,
              bottom: PADDING * 2,
            }}
          >
            <VictoryBar
              style={{
                data: { fill: (args) => args.datum.color },
              }}
              data={chartData}
              x={'name'}
              y={'count'}
              labels={({ datum }) => datum.name}
              sortKey="y"
              labelComponent={<VictoryTooltip constrainToVisibleArea text={(args) => args.datum.name} />}
              barWidth={10}
            />
            <VictoryAxis
              orientation={'right'}
              tickFormat={(tick) => chartData?.find((item) => item.name === tick)?.count}
            />
            <VictoryAxis orientation={'left'} />
          </VictoryChart>
          {emitterAnalyticsQuery.data && emitterAnalyticsQuery.data.length > TOP_CHART_COUNT && (
            <Button
              mode={'outlined'}
              onPress={toggleFullErrorAnalytics}
              icon={{ name: fullErrorAnalytics ? 'chevron-up' : 'chevron-down' }}
            >
              {fullErrorAnalytics ? 'Свернуть' : 'Развернуть'} диаграмму
            </Button>
          )}
        </Stack>
      </Card.Content>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

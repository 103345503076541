import { pickBy } from 'lodash';
import moment from 'moment';

import { cleanObject } from '@iokanx/payment-form/feature';
import { CaptureMethod } from '@iokanx/shared/data-access/constants';
import { convertValuesToString } from '@iokanx/shared/util';

import { IPostOrdersRequestData, IPostOrdersResponseData, postOrders } from '../../resources';

export interface ICreateOrderResolverParams extends IPostOrdersRequestData {
  // Срок действия заказа
  dueDate?: Date;
  // Способ списания оплаты
  captureMethod: CaptureMethod;
}

export type ICreateOrderResolverResult = IPostOrdersResponseData;

export function createOrderResolver({
  dueDate,
  captureMethod,
  ...params
}: ICreateOrderResolverParams): Promise<Readonly<ICreateOrderResolverResult>> {
  const extra_info = cleanObject(params.extra_info);
  const convertedExtraInfo = convertValuesToString(extra_info);

  const filteredObject = pickBy(
    {
      ...params,
      extra_info: convertedExtraInfo,
      due_date: dueDate ? moment(dueDate).utcOffset(0).format(moment.HTML5_FMT.DATETIME_LOCAL_MS) : undefined,
      amount: params.amount * 100,
      capture_method: captureMethod ? 'MANUAL' : 'AUTO',
    },
    (value) => value !== undefined,
  );

  return postOrders(filteredObject as any);
}

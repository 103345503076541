import { cdnHttp } from '../../../instances';

export interface IGetCdnDashboardBinapiPublicEmitterResponseDataItem {
  code: string;
  name: string;
  color?: string;
}

export type IGetCdnDashboardBinapiPublicEmitterResponseData = IGetCdnDashboardBinapiPublicEmitterResponseDataItem[];

export async function getCdnDashboardBinapiPublicEmitter(): Promise<IGetCdnDashboardBinapiPublicEmitterResponseData> {
  const { data } = await cdnHttp.get<IGetCdnDashboardBinapiPublicEmitterResponseData>(
    '/dashboard/binapi_public_emitter.json',
  );
  return data;
}

import moment from 'moment';

import { DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';

import { getVoteById, IGetNpsByIdResponseData } from '../../resources';

export interface IGetVoteIdParams {
  voteId: string;
}

export interface IFindNpsResolverResultItem extends IGetNpsByIdResponseData {
  shopId?: string;
  shopName?: string;
  voteAt?: string;
  createdAt?: string;
}

export async function getVoteByIdResolver(params: IGetVoteIdParams): Promise<IFindNpsResolverResultItem> {
  const shop = await getVoteById(params.voteId);
  return transformVote(shop);
}

export function transformVote(vote: IGetNpsByIdResponseData): IFindNpsResolverResultItem {
  return {
    ...vote,
    email: vote.email || vote.name,
    shopId: vote.shop_id,
    shopName: vote.shop_name,
    voteAt: vote.voted_at ? moment(vote.voted_at).format(DATETIME_FORMAT) : undefined,
    createdAt: moment(vote.created_at).format(DATETIME_FORMAT),
  };
}

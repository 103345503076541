export interface IConfigResponseData {
  readonly backendApiUrl: string;
  readonly adminApiUrl: string;
}

export type IConfigApiUrl = keyof Pick<IConfigResponseData, 'backendApiUrl' | 'adminApiUrl'>;

export function getConfig(): Promise<IConfigResponseData> {
  return Promise.resolve({
    backendApiUrl: process.env['BACKEND_API_URL'] || '',
    adminApiUrl: process.env['NX_ADMIN_API_URL'] || '',
  });
}

import { ListItemText } from '@mui/material';
import { StyleSheet } from 'react-native';

import { IFindShopsResultItem } from '@iokanx/shared/data-access/api';
import { Card, SimpleCard } from '@iokanx/shared/ui/universal';

export interface IShopCardProps {
  shop: IFindShopsResultItem;
}

export function ShopCard(props: IShopCardProps) {
  return (
    <SimpleCard title={props.shop.display_name} subtitle={props.shop.bin}>
      <Card.Actions style={styles.actions}>
        <ul>
          <li>IBAN: {props.shop.iban}</li>
          <li>Телефон: {props.shop.phone}</li>
          <li>Почта: {props.shop.email}</li>
          <li>Статус: {props.shop.status}</li>
          <li>Дата создания: {props.shop.created_at}</li>
        </ul>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  actions: {
    justifyContent: 'space-between',
  },
});

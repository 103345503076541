import { backendHttp } from '@iokanx/shared/data-access/api';

import { filterAddress } from './helpers';

export const postData = async (organizationId: string, data: any) => {
  await backendHttp.post(`/organizations/${organizationId}/addresses`, data);
};

export const patchData = async (organizationId: string, data: any, addresses: any[]) => {
  const address = filterAddress(addresses, data.type);

  await backendHttp.patch(`/organizations/${organizationId}/addresses/${address.id}`, data);
};

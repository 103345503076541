import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';
import { RadioButtons, Select } from '@iokanx/shared/ui/universal';

import { QUESTION } from './constants';

import { getBinaryItems, isFormEmpty } from '../../../helpers/helpers';
import { useFormEdit } from '../../../helpers/useFormEdit';
import { BinaryAnswer } from '../../../shared/types';
import { ConditionalComponent } from '../../ConditionalComponent';
import { DatePickerYear } from '../../Datepicker';
import { FormField } from '../../FormField/FormField';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { formatDateTime } from '../HolderStep/helpers';
import { organizationOfficialValidationSchema } from '../OrganizationInfoStep/model/schemas';

export const GovernmentConnectionForm = ({
  isEmpty,
  isSubmitted,
  organizationId,
  form,
}: {
  isEmpty?: boolean;
  isSubmitted: boolean;
  organizationId: string;
  form: boolean;
}) => {
  const queryClient = useQueryClient();

  const { mutate: postOfficial } = useMutation(
    async (data: any) => {
      const { data: response } = await backendHttp.post(`organizations/${organizationId}/shares`, {
        is_official: true,
        reason: data.reason,
        personal_data: {
          ...data.personal_data,
          type: 'PHYSICAL',
          is_resident: data.personal_data.is_resident === 'true',
          issue_date: formatDateTime(new Date(data.personal_data.issue_date)),
        },
      });

      return response;
    },
    {
      onSuccess: async () => {
        reset();
        handleIsEdit();

        await queryClient.invalidateQueries(['shares']);
      },
    },
  );

  const {
    getValues,
    trigger,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      is_official: isEmpty ? BinaryAnswer.NO : '',
      personal_data: {
        first_name: '',
        last_name: '',
        patronymic: '',
        nib: '',
        is_resident: '',
        number: '',
        issued_by: '',
        issue_date: '',
      },
    },
    resolver: yupResolver(organizationOfficialValidationSchema),
  });

  const onSubmit = (data: any) => {
    if (data.is_official === BinaryAnswer.NO) {
      handleIsEdit();

      return;
    }
    postOfficial(data);
  };

  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(isEmpty), handleSubmit(onSubmit, onError));

  const handleSubmitSection = () => {
    const formValues = getValues();

    if (isFormEmpty(formValues)) {
      trigger();

      return;
    }

    return isDirty ? handlePress() : handleIsEdit();
  };

  return (
    <ProfileFormSection isSaved={isDisable} isSubmitted={isSubmitted} handleSubmit={handleSubmitSection}>
      <ConditionalComponent
        condition={!form}
        component={
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <Item>
                <RadioButtons
                  label={QUESTION}
                  onValueChange={onChange}
                  value={value}
                  items={getBinaryItems(isDisable || isSubmitted)}
                />
              </Item>
            )}
            name={'is_official'}
          />
        }
      />

      <ConditionalComponent
        condition={watch('is_official') === BinaryAnswer.YES || form}
        component={
          <>
            <FormField name={'personal_data.first_name'} control={control} label={'Имя'} disabled={isDisable} />
            <FormField name={'personal_data.last_name'} control={control} label={'Фамилия'} disabled={isDisable} />
            <FormField name={'personal_data.patronymic'} control={control} label={'Отчество'} disabled={isDisable} />
            <FormField name={'personal_data.nib'} control={control} label={'ИИН'} disabled={isDisable} />

            <Controller
              control={control}
              name={'personal_data.is_resident'}
              render={({ field: { onChange, value } }) => (
                <Item>
                  <Select
                    onChange={onChange}
                    style={{ width: '100%' }}
                    disabled={isDisable}
                    value={String(value)}
                    label={'Резиденство'}
                    items={[
                      { value: 'true', title: 'Является резидентом' },
                      { value: 'false', title: 'Не является резидентом' },
                    ]}
                  />
                </Item>
              )}
            />

            <FormField
              name={'personal_data.number'}
              control={control}
              label={'№ Удостоверяющего документа'}
              disabled={isDisable}
            />

            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Item>
                  <DatePickerYear
                    disabled={isDisable}
                    label="Дата выдачи"
                    value={value && new Date(value)}
                    onChange={onChange}
                  />
                </Item>
              )}
              name={'personal_data.issue_date'}
            />

            <FormField name={'personal_data.issued_by'} control={control} label={'Кем выдан'} disabled={isDisable} />
          </>
        }
      />
    </ProfileFormSection>
  );
};

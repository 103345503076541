import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import { UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ICreateShopResolverParams, ICreateShopResolverResult } from '@iokanx/shared/data-access/api';
import { Button, HelperText, TextField } from '@iokanx/shared/ui/universal';

export interface ICreateShopFormProps {
  createShop: UseMutateFunction<ICreateShopResolverResult, unknown, ICreateShopResolverParams>;
  isLoading: boolean;
  error?: string;
  isError: boolean;
}

type ICreateShopFormFieldValues = {
  name: string;
  bin: string;
  iban: string;
};

const createShopFormValidationSchema: yup.SchemaOf<ICreateShopFormFieldValues> = yup.object({
  name: yup.string().required(),
  bin: yup.string().required(),
  iban: yup.string().required(),
});

// TODO remove
interface IUser {
  id: string;
}

export function CreateShopForm(props: ICreateShopFormProps) {
  const queryClient = useQueryClient();

  const { control, handleSubmit } = useForm<ICreateShopFormFieldValues>({
    defaultValues: {
      name: '',
      bin: '',
      iban: '',
    },
    mode: 'all',
    resolver: yupResolver(createShopFormValidationSchema),
  });

  const onSubmit: SubmitHandler<ICreateShopFormFieldValues> = (data) => {
    const user: IUser | undefined = queryClient.getQueryData(['selectedUser']);
    console.log(user);
    if (user) {
      props.createShop({
        ...data,
        owner_id: user.id,
      } as ICreateShopResolverParams);
    }
  };

  const onError = (error: any) => console.log(error);

  return (
    <Stack padding={5} space={5}>
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'BIN'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'bin'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'Юридическое название'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'name'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'IBAN'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'iban'}
      />
      <HelperText type={'error'} visible={props.isError}>
        {props.error}
      </HelperText>
      <Button onPress={handleSubmit(onSubmit, onError)} loading={props.isLoading}>
        Добавить магазин
      </Button>
    </Stack>
  );
}

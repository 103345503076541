import { Refresh } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { ScrollView } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { DashboardStackParamList, saveFile } from '@iokanx/dashboard/feature';
import { PayoutStatusChip } from '@iokanx/dashboard/ui';
import {
  acceptPayoutResolver,
  approvePayoutResolver,
  exportPayoutsResolver,
  findPayoutByIdResolver,
  statusPayoutResolver,
} from '@iokanx/shared/data-access/api';
import { ACQUIRER_I18N, AcquirerType, PayoutStatus } from '@iokanx/shared/data-access/constants';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Button, Divider, List } from '@iokanx/shared/ui/universal';

export type IPayoutPageProps = NativeStackScreenProps<DashboardStackParamList, 'Payout'>;

function Payout(props: IPayoutPageProps) {
  const snackbar = useSnackbar();
  const queryClient = new QueryClient();

  const payoutAcceptedMutation = useMutation(
    (variables: { payoutId: string }) => acceptPayoutResolver({ payoutId: variables.payoutId }),
    {
      onSuccess: (data) => {
        snackbar.show('Запущен процесс выплаты', { type: 'success' });
        return queryClient.invalidateQueries(['payout', props.route.params.payoutId]);
      },
      onError: (err) => {
        console.log('on error', err);
      },
    },
  );

  const payoutApproveMutation = useMutation(
    (variables: { payoutId: string }) => approvePayoutResolver({ payoutId: variables.payoutId }),
    {
      onSuccess: (data) => {
        snackbar.show('Запущен процесс подтверждения перечисления', { type: 'success' });
      },
      onError: (err) => {
        console.log('on error', err);
      },
    },
  );

  const payoutStatusMutation = useMutation(
    (variables: { payoutId: string }) => statusPayoutResolver({ payoutId: variables.payoutId }),
    {
      onSuccess: (data) => {
        snackbar.show('Запрос на обновление статуса выплаты', { type: 'success' });
      },
      onError: (err) => {
        console.log('on error', err);
      },
    },
  );

  const payoutExportedMutation = useMutation(
    (variables: { payoutId: string }) => exportPayoutsResolver(variables.payoutId),
    {
      onSuccess: (data, variables) => {
        return saveFile({ data, fileName: `payout_${variables.payoutId}.xlsx` });
      },
      onError: (err) => {
        snackbar.show('Ошибка при выгрузке обратитесь в тех поддержку', { type: 'error' });
        console.log('on error', err);
      },
    },
  );

  const { data: payout } = useQuery(['payout', props.route.params.payoutId], () =>
    findPayoutByIdResolver(props.route.params),
  );
  if (!payout) return null;

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    bpayout: 'none',
  }));

  // @ts-ignore
  return (
    <ScrollView>
      <List.Section title={'Выплата'}>
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Идентификатор</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{payout.id}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Дата создания:</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{payout.createdAt}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Номер заказа</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{payout.externalId}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Сумма</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{payout.amountWithSymbol}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Эквайер</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{ACQUIRER_I18N[payout.acquirer]}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">
              Статус
              {payout.status === PayoutStatus.Accepted && payout.acquirer !== AcquirerType.EPAY_V2 && (
                <IconButton size={'small'} onClick={() => payoutStatusMutation.mutate({ payoutId: payout.id })}>
                  <Refresh />
                </IconButton>
              )}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <PayoutStatusChip status={payout.status} />
            </Item>
          </Grid>
          {payout.error && (
            <>
              <Grid item xs={6}>
                <Item className="infoTitle">Причина ошибки</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.error.message}</Item>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Item>
              <Button
                mode={'outlined'}
                icon={{ name: 'file-excel-outline' }}
                onPress={() => payoutExportedMutation.mutate({ payoutId: payout.id })}
                fullWidth={true}
              >
                Скачать список платежей
              </Button>
            </Item>
            {payout.acquirer === AcquirerType.EPAY_V2 && payout.status === PayoutStatus.Accepted && (
              <Item>
                <Button
                  icon={{ name: 'file-excel-outline' }}
                  onPress={() => payoutApproveMutation.mutate({ payoutId: payout.id })}
                  fullWidth={true}
                >
                  Подтвердить перечисление со счета
                </Button>
              </Item>
            )}
            {(payout.status === PayoutStatus.Pending || payout.status === PayoutStatus.Declined) && (
              <Item>
                <Button
                  icon={{ name: 'receipt' }}
                  onPress={() => payoutAcceptedMutation.mutate({ payoutId: payout.id })}
                  fullWidth={true}
                >
                  Подтвердить
                </Button>
              </Item>
            )}
            <Divider />
          </Grid>
        </Grid>
      </List.Section>
      <Divider />
      {payout.shop && (
        <List.Section>
          <List.Section title={'Мерчант'}>
            <Grid container>
              <Grid item xs={6}>
                <Item className="infoTitle">Наименование</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.shop.displayName || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">ФИО владельца</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.shop.owner || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Адрес</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.shop.address || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Номер</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.shop.phone || '-'}</Item>
              </Grid>
            </Grid>
          </List.Section>
        </List.Section>
      )}
      {payout.recipient && (
        <List.Section>
          <List.Section title={'Получатель'}>
            <Grid container>
              <Grid item xs={6}>
                <Item className="infoTitle">ФИО/Наименование</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.recipient.name || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">ИИН/БИН</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.recipient.iin || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">IBAN</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.recipient.iban || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">КНП</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.recipient.knp || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">КБЕ</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.recipient.kbe || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Описание</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{payout.recipient.description || '-'}</Item>
              </Grid>
            </Grid>
          </List.Section>
        </List.Section>
      )}
    </ScrollView>
  );
}

export default wrapPage(
  wrapModalPage(Payout, {
    position: 'Right',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'Payout'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Reports', params: { screen: 'Payouts' } });
        navigation.push('Payout', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

import { Collapse } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { DashboardPaymentStatusChip } from '@iokanx/dashboard/ui';
import { IFindHistoryResult, updatePsmEventById } from '@iokanx/shared/data-access/api';
import {
  COMMAND_EVENT_I18N,
  CommandEvent,
  PaymentStatus,
  PsmStatus,
  RefundStatus,
} from '@iokanx/shared/data-access/constants';
import { useSnackbar } from '@iokanx/shared/feature';
import { Button, Divider, Icon, List } from '@iokanx/shared/ui/universal';

export interface IHistoryProps {
  payment: IFindHistoryResult;
  is_superadmin?: boolean;
  isOpen?: boolean;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  textAlign: 'left',
  borderRadius: 0,
  boxShadow: 'none',
  border: 'none',
}));

export function HistoryList(props: IHistoryProps) {
  const { payment, is_superadmin, isOpen } = props;
  const [isCollapsed, setIsCollapsed] = useState(isOpen);
  const snackbar = useSnackbar();

  const { mutate: updatePsmEventByIdMutation } = useMutation(
    (variables: { id: number; name: PsmStatus; order_id?: string | null; reference?: string | null }) =>
      updatePsmEventById({
        command_id: variables.id,
        name: variables.name,
        payment: {
          order_id: variables?.order_id,
          reference: variables?.reference,
        },
      }),
    {
      onSuccess: (data) => {
        snackbar.show('Команда успешно доведена', { type: 'success' });
      },
      onError: (err) => {
        snackbar.show('Ошибка доведения команды', { type: ' warning' });
      },
    },
  );

  return (
    <>
      <List.Section title={''}>
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Дата и время попытки</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{payment.createdAt}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Статус</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <DashboardPaymentStatusChip status={payment.status} />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={isCollapsed}>
              <List.Section title={'Плательщик'}>
                <Grid container>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Способ оплаты</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.paymentMethod || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Номер карты</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.payer?.pan_masked || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Эмитент</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.payer?.emitter || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Платежная система</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.payer?.payment_system || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Номер телефона</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.payer?.phone || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Email</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.payer?.email || '-'}</Item>
                  </Grid>
                </Grid>
              </List.Section>

              <List.Section title={'Эквайер'}>
                <Grid container>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Имя</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.acquirer?.name || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Терминал</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.acquirer?.terminal || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">RRN</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.acquirer?.reference || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Order ID</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.acquirer?.order_id || '-'}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Invoice ID</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{payment.acquirer?.invoice_id || '-'}</Item>
                  </Grid>
                </Grid>
              </List.Section>

              {payment && payment.commandList && (
                <List.Section title={'Операции'}>
                  {payment.commandList.map((command) => (
                    <Grid container style={{ marginBottom: '10px', borderBottom: '1px solid rgb(226, 232, 240)' }}>
                      <Grid item xs={3}>
                        <Item>
                          ID: {command.id} <br />
                          {command.createdAt}
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          {command.amount && (
                            <>
                              Сумма: {command.amountWithSymbol} <br />
                            </>
                          )}
                          {COMMAND_EVENT_I18N[command.name]}
                          {command.code && (
                            <>
                              <br />
                              <br />
                              {command.code} <br /> {command.message}
                            </>
                          )}
                        </Item>
                      </Grid>
                      {is_superadmin &&
                        [CommandEvent.Cancel, CommandEvent.Refund, CommandEvent.Capture].includes(command.name) &&
                        (command.payment_status === PaymentStatus.Declined ||
                          command.refund_status === RefundStatus.Declined ||
                          command.code) && (
                          <Grid item xs={3}>
                            <Item>
                              <Button
                                fullWidth={true}
                                onPress={() => {
                                  let psm_event = null;
                                  if (command.name === CommandEvent.Cancel) psm_event = PsmStatus.PaymentCancelled;
                                  if (command.name === CommandEvent.Capture) psm_event = PsmStatus.PaymentCaptured;
                                  if (command.name === CommandEvent.Refund) psm_event = PsmStatus.RefundApproved;
                                  if (!psm_event) return false;
                                  updatePsmEventByIdMutation({
                                    id: command.id,
                                    name: psm_event,
                                    order_id: payment.acquirer?.order_id,
                                    reference: payment.acquirer?.reference,
                                  });
                                }}
                              >
                                Довести
                              </Button>
                            </Item>
                          </Grid>
                        )}
                    </Grid>
                  ))}
                </List.Section>
              )}
            </Collapse>

            <Item>
              <div
                style={{
                  display: 'block',
                  textDecoration: 'none',
                  textAlign: 'right',
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.5)',
                }}
                onClick={() => setIsCollapsed((isCollapsed) => !isCollapsed)}
              >
                {isCollapsed ? 'скрыть' : 'раскрыть'}
                <Icon style={{ fontSize: '16px' }} name={isCollapsed ? 'chevron-up' : 'chevron-down'} />
              </div>
            </Item>
          </Grid>
        </Grid>
      </List.Section>
      <Divider />
    </>
  );
}

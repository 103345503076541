import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { backendHttp } from '@iokanx/shared/data-access/api';
import { profileFormStepLabels } from '@iokanx/shared/ui/web';

import { filterShares } from './steps/HolderStep/helpers';
import { turnoverQueryBuilder } from './TurnoversStep/TurnoversStep';

import { getOrganizationFilesResolver } from '../shared/data-access/files';

export const useValidateSteps = (organizationId: string) => {
  const { data: files } = useQuery(['files'], async () => await getOrganizationFilesResolver(organizationId));
  const { data: shares } = useQuery(['shares', 'PHYSICAL'], async () => {
    const { data } = await backendHttp.get(`organizations/${organizationId}/shares?type=PHYSICAL`);

    return data;
  });

  const { data: registeredOkeds } = useQuery(['okeds'], async () => {
    const { data } = await backendHttp.get(`organizations/${organizationId}/okeds`);

    return data;
  });

  const { data: turnovers } = useQuery(['turnovers'], async () => {
    const { data } = await backendHttp.get(turnoverQueryBuilder(organizationId));

    return data;
  });

  const stepValidators = [
    {
      title: 'Прикрепите документы',
      validationFn: () => !isEmpty(files),
    },
    {
      title: 'Дополнительные вопросы',
      validationFn: () => true,
    },
    {
      title: 'Посредники',
      validationFn: () => true,
    },
    {
      title: 'Посредники',
      validationFn: () => true,
    },
    {
      title: 'Связь с государственными должностными лицами',
      validationFn: () => true,
    },
    {
      title: 'Данные о бенефициарном собственнике',
      validationFn: () => !isEmpty(filterShares(shares)),
    },
    {
      title: 'Виды деятельности',
      validationFn: () => !isEmpty(registeredOkeds),
    },
    {
      title: 'Обороты',
      validationFn: () => !isEmpty(turnovers),
    },
  ];

  const validateSteps = (completed: any) => {
    stepValidators.forEach((stepValidator) => {
      const index = profileFormStepLabels.findIndex((formStepLabel) => formStepLabel.label === stepValidator.title);

      if (index) {
        completed[index] = stepValidator.validationFn();
      }
    });
  };

  return { validateSteps };
};

export enum InstallmentStatus {
  PENDING = 'PENDING',
  OTP_SENT = 'OTP_SENT',
  SEND_OTP_DECLINED = 'SEND_OTP_DECLINED',
  OTP_CONFIRMED = 'OTP_CONFIRMED',
  CONFIRM_OTP_DECLINED = 'CONFIRM_OTP_DECLINED',
  INSTALLMENT_ACTION_REQUIRED = 'INSTALLMENT_ACTION_REQUIRED',
  INSTALLMENT_DECLINED = 'INSTALLMENT_DECLINED',
  INSTALLMENT_ISSUED = 'INSTALLMENT_ISSUED',
}

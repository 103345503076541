import { getOrderByIdEvents, IGetOrderByIdEventsResponseDataItem } from '../../resources';

export interface IFindOrderEventsResolverParams {
  orderId: string;
}

export type IFindOrderEventsResolverResultItem = IGetOrderByIdEventsResponseDataItem;

export function findOrderEventsResolver(
  params: IFindOrderEventsResolverParams,
): Promise<IFindOrderEventsResolverResultItem[]> {
  return getOrderByIdEvents(params.orderId);
}

import { Stack, Tiles } from '@mobily/stacks';
import { Control, Controller, useWatch } from 'react-hook-form';

import { Currency, CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';
import { Accordion, Divider, TextField, OutlineView, Slider } from '@iokanx/shared/ui/universal';

import { ICreatePaymentLinkFormFieldValues } from '../create-payment-link-form/create-payment-link-form';

export interface CreatePaymentLinkFormRangedTabProps {
  control: Control<ICreatePaymentLinkFormFieldValues>;
}

export function CreatePaymentLinkFormRangedTab(props: CreatePaymentLinkFormRangedTabProps) {
  const watch = useWatch({ control: props.control });

  const minAmount = Number(watch.min_amount ?? 0);
  const maxAmount = Number(watch.max_amount ?? 0);
  const step = Number(watch.step ?? 0);

  const showPreview = maxAmount > minAmount && step > 0;

  return (
    <OutlineView>
      <Stack space={4}>
        <Tiles columns={3} space={2}>
          <Controller
            control={props.control}
            name={'min_amount'}
            render={({ field, fieldState }) => (
              <TextField
                label={'Мин. цена'}
                value={field.value?.toString() ?? ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                keyboardType={'numeric'}
              />
            )}
          />
          <Controller
            control={props.control}
            name={'max_amount'}
            render={({ field, fieldState }) => (
              <TextField
                label={'Макс. цена'}
                value={field.value?.toString() ?? ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                keyboardType={'numeric'}
              />
            )}
          />
          <Controller
            control={props.control}
            name={'step'}
            render={({ field, fieldState }) => (
              <TextField
                label={'Шаг'}
                value={field.value?.toString() ?? ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                keyboardType={'numeric'}
              />
            )}
          />
        </Tiles>
        <Divider />
        <Accordion title={'Превью'} disabled={!showPreview} isExpanded>
          <Slider
            minimumValue={minAmount}
            maximumValue={maxAmount}
            step={step}
            rangeLabelFormat={(value) => `${value?.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`}
          />
        </Accordion>
      </Stack>
    </OutlineView>
  );
}

import type { HSV, Numberify } from '@ctrl/tinycolor';
import { styled } from '@mui/material/styles';
import { clamp } from 'lodash';
import React from 'react';

import { useEvent } from '@iokanx/shared/util';

const BG_IMAGE_SPACE = 'linear-gradient(to top, #000000, transparent), linear-gradient(to right, #ffffff, transparent)';

const StyledSpace = styled('div')(() => {
  return {
    width: '100%',
    height: '180px',
    boxSizing: 'border-box',
    outline: 0,
    position: 'relative',
    backgroundImage: BG_IMAGE_SPACE,
  };
});
const StyledThumb = styled('div')(() => {
  return {
    position: 'absolute',
    border: '3px solid #ffffff',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    marginLeft: '-10px',
    marginBottom: '-10px',
    outline: 0,
    boxSizing: 'border-box',
    willChange: 'left, bottom',
    transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      boxShadow: `0px 0px 0px 4px rgba(255 255 255 / 0.16)`,
    },

    '&.MuiColorInput-Thumb-active': {
      boxShadow: `0px 0px 0px 8px rgba(255 255 255 / 0.16)`,
    },

    '@media (hover: none)': {
      boxShadow: 'none',
    },
  };
});

type ColorSpaceProps = {
  hsv: Numberify<HSV>;
  currentHue: number;
  onChange: (args: Pick<Numberify<HSV>, 's' | 'v'>) => void;
};

function getNewThumbPosition(colorSpace: HTMLDivElement, clientX: number, clientY: number): { x: number; y: number } {
  const boundingClientRect = colorSpace.getBoundingClientRect();
  const positionX = clientX - boundingClientRect.left;
  const positionY = clientY - boundingClientRect.top;
  return {
    x: clamp(positionX / boundingClientRect.width, 0, 1),
    y: clamp(1 - positionY / boundingClientRect.height, 0, 1),
  };
}

const KEYBOARD_KEY: Record<string, KeyboardEvent['key']> = {
  up: 'ArrowUp',
  down: 'ArrowDown',
  left: 'ArrowLeft',
  right: 'ArrowRight',
};

type ArrowColorSpace = {
  [key in typeof KEYBOARD_KEY[keyof typeof KEYBOARD_KEY]]: {
    type: 'hsvS' | 'hsvV';
    value: 1 | -1;
  };
};

const ARROW_COLOR_SPACE: ArrowColorSpace = {
  ArrowUp: {
    type: 'hsvV',
    value: +1,
  },
  ArrowDown: {
    type: 'hsvV',
    value: -1,
  },
  ArrowLeft: {
    type: 'hsvS',
    value: -1,
  },
  ArrowRight: {
    type: 'hsvS',
    value: +1,
  },
};
function matchIsArrowKey(key: string): key is keyof typeof ARROW_COLOR_SPACE {
  return (
    key === KEYBOARD_KEY.up || key === KEYBOARD_KEY.down || key === KEYBOARD_KEY.left || key === KEYBOARD_KEY.right
  );
}

function round(value: number, minimumFractionDigits?: number, maximumFractionDigits?: number): number {
  const formattedValue = value.toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits,
    maximumFractionDigits,
  });
  return Number(formattedValue);
}

export const ColorSpace = (props: ColorSpaceProps) => {
  const { hsv, onChange, currentHue } = props;
  const isMouseDown = React.useRef<boolean>(false);
  const spaceRef = React.useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = React.useState<boolean>(false);

  const moveThumb = useEvent((clientX: number, clientY: number) => {
    if (!spaceRef.current) {
      return;
    }
    const { x, y } = getNewThumbPosition(spaceRef.current, clientX, clientY);
    onChange({
      s: x,
      v: y,
    });

    if (spaceRef.current && document.activeElement !== spaceRef.current) {
      spaceRef.current.focus();
    }
  });

  const handleMouseUp = React.useCallback(() => {
    if (isMouseDown.current) {
      isMouseDown.current = false;
      setIsActive(false);
    }
  }, []);

  const handleMouseMove = React.useCallback((event: MouseEvent) => {
    if (isMouseDown.current) {
      moveThumb(event.clientX, event.clientY);
    }
    // moveThumb is a useEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove, false);
    document.addEventListener('mouseup', handleMouseUp, false);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove, false);
      document.removeEventListener('mouseup', handleMouseUp, false);
    };
  }, [handleMouseUp, handleMouseMove]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    isMouseDown.current = true;
    moveThumb(event.clientX, event.clientY);
    setIsActive(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (matchIsArrowKey(event.key)) {
      event.preventDefault();
      const { type, value } = ARROW_COLOR_SPACE[event.key];
      const step = event.shiftKey ? 10 : 1;
      const previousHsvTypeValue = type === 'hsvS' ? hsv.s : hsv.v;
      const newHsvTypeValue = clamp(previousHsvTypeValue + value * step * 0.01, 0, 1);
      setIsActive(true);
      onChange({
        s: type === 'hsvS' ? newHsvTypeValue : hsv.s,
        v: type === 'hsvV' ? newHsvTypeValue : hsv.v,
      });
    }
  };

  const saturationInPercent = hsv.s * 100;
  const valueInPercent = hsv.v * 100;

  return (
    <StyledSpace
      onMouseDown={handleMouseDown}
      ref={spaceRef}
      className="MuiColorInput-ColorSpace"
      style={{
        backgroundColor: `hsl(${currentHue} 100% 50%)`,
      }}
      role="slider"
      aria-valuetext={`Saturation ${round(saturationInPercent, 0, 0)}%, Brightness ${round(valueInPercent, 0, 0)}%`}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <StyledThumb
        aria-label="Color space thumb"
        className={isActive ? 'MuiColorInput-Thumb-active' : ''}
        style={{
          left: `${saturationInPercent}%`,
          bottom: `${valueInPercent}%`,
        }}
      />
    </StyledSpace>
  );
};

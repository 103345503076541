import AsyncStorage from '@react-native-async-storage/async-storage';

import { IAuthLoginResponseData } from '@iokanx/shared/data-access/api';
import { SELECTED_SHOP_ID, SELECTED_SHOP_ROLE } from '@iokanx/shared/data-access/constants';

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const SHOP_ACCESS_TOKEN_KEY = 'shop_access_token';

export function setTokens({ access_token, refresh_token }: IAuthLoginResponseData) {
  return AsyncStorage.multiSet([
    [ACCESS_TOKEN_KEY, access_token],
    [REFRESH_TOKEN_KEY, refresh_token],
  ]);
}

export function getAccessToken() {
  return AsyncStorage.getItem(ACCESS_TOKEN_KEY);
}

export function clearTokens() {
  return AsyncStorage.multiRemove([
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
    SHOP_ACCESS_TOKEN_KEY,
    SELECTED_SHOP_ID,
    SELECTED_SHOP_ROLE,
  ]);
}

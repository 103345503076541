import AsyncStorage from '@react-native-async-storage/async-storage';
import { AxiosError } from 'axios';

import { ACCESS_TOKEN_KEY, SELECTED_SHOP_ID } from '@iokanx/shared/data-access/constants';

import { refreshAuthToken } from '../../resolvers/auth/refresh-token';
import { switchShopResolver } from '../../resolvers/shops/switch';

const REFRESH_TOKEN_KEY = 'refresh_token';

export function getRefreshToken() {
  return AsyncStorage.getItem(REFRESH_TOKEN_KEY);
}
export function setRefreshToken(refreshToken: string) {
  return AsyncStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}
export function setAccessToken(accessToken: string) {
  return AsyncStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export async function refreshUserAuthLogic(failedRequest: AxiosError) {
  const currRefreshToken = await getRefreshToken();
  if (!currRefreshToken) return failedRequest;

  const { access_token, refresh_token } = await refreshAuthToken({ refresh_token: currRefreshToken });
  await setAccessToken(access_token);
  await setRefreshToken(refresh_token);

  const selectedShopId = await AsyncStorage.getItem(SELECTED_SHOP_ID);
  if (selectedShopId) await switchShopResolver(selectedShopId);
  return;
}

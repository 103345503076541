import { IGetReviseByIdResponseData } from './by-id';

import { backendHttp } from '../../instances';

export type IGetRevisesRequestParams = {
  page?: number;
  limit?: number;
  to_dt?: Date;
  from_dt?: Date;
  shop_id?: string;
  order_id?: string;
  reference?: string;
};

export type IGetStatsResponse = {
  total_bank_amount?: number;
  total_bank_acquirer_fee?: number;
  total_bank_processing_fee?: number;
  total_amount?: number;
  total_acquirer_fee?: number;
  total_processing_fee?: number;
  total_diff_amount?: number;
  total_diff_acquirer_fee?: number;
};

export interface IGetRevisesResponseData<IDataItem = IGetReviseByIdResponseData> {
  count: number;
  data: IDataItem[];
  stats: IGetStatsResponse;
}

/** Поиск возвратов по фильтрам */
export async function getRevises(params: IGetRevisesRequestParams): Promise<Readonly<IGetRevisesResponseData>> {
  const { data } = await backendHttp.get<IGetRevisesResponseData>('/reports/revises', { params });
  return data;
}

import { isFunction } from 'lodash';
import { ComponentProps } from 'react';
import { Caption, List, Title } from 'react-native-paper';

export type IListItemProps = ComponentProps<typeof List.Item>;

export function ListItem(props: IListItemProps) {
  return (
    <List.Item
      {...props}
      title={(titleProps) => {
        if (isFunction(props.title)) {
          return props.title(titleProps);
        }

        return (
          <Title
            ellipsizeMode={titleProps.ellipsizeMode}
            style={{ fontSize: titleProps.fontSize, color: titleProps.color }}
          >
            {props.title}
          </Title>
        );
      }}
      description={
        props.description
          ? (descriptionProps) => {
              if (isFunction(props.description)) {
                return props.description(descriptionProps);
              }

              return (
                <Caption
                  ellipsizeMode={descriptionProps.ellipsizeMode}
                  style={{ fontSize: descriptionProps.fontSize, color: descriptionProps.color }}
                >
                  {props.description}
                </Caption>
              );
            }
          : null
      }
    />
  );
}

import { AcquirerType } from './acquirer';

export enum PayoutStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  InProcess = 'IN_PROCESS',
}

export const PAYOUT_STATUS_I18N: Record<PayoutStatus, string> = {
  [PayoutStatus.Pending]: 'Ожидает',
  [PayoutStatus.Accepted]: 'В обработке',
  [PayoutStatus.Approved]: 'Обработан',
  [PayoutStatus.Declined]: 'Не обработан',
  [PayoutStatus.InProcess]: 'Готов к отправке',
};

export const ACQUIRER_I18N: Record<AcquirerType, string> = {
  [AcquirerType.SBERBANK]: 'Bereke',
  [AcquirerType.JUSAN]: 'Jusan',
  [AcquirerType.EPAY]: 'Epay',
  [AcquirerType.EPAY_V2]: 'Epay 2.0',
  [AcquirerType.PAYBOX]: 'Paybox',
  [AcquirerType.RBS]: 'Rbs',
  [AcquirerType.BCC]: 'BCC',
  [AcquirerType.EUBANK]: 'Eubank',
};

import { backendHttp } from '../../instances';

export type IAnalyticsNpsRequestParams = {
  to_dt?: Date;
  from_dt?: Date;
};

export interface IAnalyticsNpsResponseData {
  id: number;
  count: number;
}

export async function analyticsNPS(params: IAnalyticsNpsRequestParams): Promise<IAnalyticsNpsResponseData[]> {
  const { data } = await backendHttp.get<IAnalyticsNpsResponseData[]>('/support/nps/analytics', { params });
  return data;
}

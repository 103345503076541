import MonitorIcon from '@mui/icons-material/Monitor';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {
  Box,
  createTheme,
  Grid,
  Paper,
  Stack,
  Theme,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createBreakpoints } from '@mui/system';
import { mapValues } from 'lodash';
import { QueryParamProvider } from 'next-query-params';
import { useMemo, useState } from 'react';

import { PaymentFormContainer } from '@iokanx/payment-form/feature';
import { IFindShopIdResult, transformSearchOrder } from '@iokanx/shared/data-access/api';
import { createOrderMock } from '@iokanx/shared/data-access/api/mocks';
import { DeviceSize } from '@iokanx/shared/data-access/constants';
import { IConstructorTypography } from '@iokanx/shared/data-access/types';
import { useDrawerType } from '@iokanx/shared/feature';

enum Device {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

const DRAWER_WIDTH = 280;

export interface IOrderPaymentFormPreviewProps {
  shop: IFindShopIdResult;
  theme: Theme;
  typography?: IConstructorTypography;
}
export function OrderPaymentFormPreview(props: IOrderPaymentFormPreviewProps) {
  const order = useMemo(() => transformSearchOrder(createOrderMock({ shop: props.shop })), [props.shop]);

  const drawerType = useDrawerType();

  const [device, setDevice] = useState(Device.Desktop);
  const handleChangeDevice: ToggleButtonGroupProps['onChange'] = (event, value) => {
    if (value) {
      setDevice(value);
    }
  };

  const desktopBreakpoints = createBreakpoints({
    values: mapValues(props.theme.breakpoints.values, (value) => value && value + DRAWER_WIDTH),
  });
  function createMuiBreakpoints(device: Device) {
    switch (device) {
      case Device.Desktop:
        return desktopBreakpoints;
      case Device.Mobile:
        return createBreakpoints({
          values: {
            xs: 0,
            sm: Infinity,
            md: Infinity,
            lg: Infinity,
            xl: Infinity,
          },
        });
    }
  }

  const theme =
    drawerType === 'permanent' ? createTheme(props.theme, { breakpoints: createMuiBreakpoints(device) }) : props.theme;

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const showToggle = useMediaQuery(desktopBreakpoints.up('md'));

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'subtitle2'}>Превью формы оплаты заказа</Typography>
        {showToggle && (
          <Grid item textAlign={'end'}>
            <ToggleButtonGroup value={device} exclusive onChange={handleChangeDevice}>
              <ToggleButton value={Device.Desktop}>
                <MonitorIcon />
              </ToggleButton>
              <ToggleButton value={Device.Mobile}>
                <PhoneIphoneIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
      </Stack>
      <Box display={'flex'} justifyContent={'center'}>
        <Paper
          sx={{
            maxWidth: isMd && device === Device.Desktop ? '100%' : DeviceSize.LargeMobile,
            padding: isMd ? 0 : 2,
            flex: 1,
          }}
        >
          <QueryParamProvider>
            <ThemeProvider theme={theme}>
              <PaymentFormContainer order={order} shop={props.shop} isPreview typography={props.typography} />
            </ThemeProvider>
          </QueryParamProvider>
        </Paper>
      </Box>
    </Stack>
  );
}

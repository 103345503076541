import { IGetUsersResponseDataItem } from '@iokanx/shared/data-access/api';

import { IPatchUserByIdRequestData, updateUserById } from '../../resources/users/update-user-by-id.resolver';

export interface IUpdateUserStatusResolverParams extends IPatchUserByIdRequestData {
  userId: string;
}

export async function updateUsersResolver({
  userId,
  ...requestData
}: IUpdateUserStatusResolverParams): Promise<IGetUsersResponseDataItem> {
  return await updateUserById(userId, requestData);
}

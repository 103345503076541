import { Delete, Edit, Save } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button as MaterialButton,
  Grid,
  IconButton,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import React, { ReactNode, useEffect } from 'react';
import { List } from 'react-native-paper';

import { Item } from '@iokanx/dashboard/feature';

import { useProfileFormStepContext } from './ProfileFormStep';

import { ConditionalComponent } from '../ConditionalComponent';

interface ProfileFormSection {
  title?: string;
  children: ReactNode;
  isSaved: boolean;
  handleSubmit: any;
  isRemovable?: boolean;
  isSubmitted?: boolean;
  handleRemove?: any;
  tooltip?: any;
  style?: any;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 420,
    fontSize: '16px',
  },
}));

const iconStyles = { fontSize: '18px' };

export const ProfileFormSection = ({
  title,
  children,
  isSaved,
  isRemovable,
  isSubmitted = false,
  handleSubmit,
  handleRemove,
  tooltip = null,
  style,
}: ProfileFormSection) => {
  const { updateCompletionStatus } = useProfileFormStepContext();

  useEffect(() => {
    updateCompletionStatus(title, isSaved);
  }, [isSaved]);

  return (
    <List.Section style={style}>
      <List.Subheader style={{ margin: 0, padding: 0, display: 'flex', alignItems: 'center' }}>
        <Item style={{ margin: 0, paddingLeft: '8px' }}>
          <Typography style={{ margin: 0, fontSize: 'inherit', fontWeight: 500 }}>{title}</Typography>
        </Item>

        {tooltip && (
          <StyledTooltip title={tooltip.text} arrow>
            <IconButton size={'small'}>
              <InfoOutlinedIcon />
            </IconButton>
          </StyledTooltip>
        )}
      </List.Subheader>

      {children}

      <ConditionalComponent
        condition={!isSubmitted && handleSubmit}
        component={
          <Grid container>
            <Item>
              <MaterialButton
                sx={{ padding: '8px 12px', minWidth: 'fit-content' }}
                disabled={isSubmitted}
                onClick={handleSubmit}
                fullWidth={false}
              >
                {isSaved ? <Edit sx={iconStyles} /> : <Save sx={iconStyles} />}
              </MaterialButton>
            </Item>

            <ConditionalComponent
              condition={Boolean(isRemovable && isSaved)}
              component={
                <Item sx={{ alignSelf: 'flex-end' }}>
                  <MaterialButton
                    sx={{ padding: '8px 12px', minWidth: 'fit-content' }}
                    disabled={isSubmitted}
                    onClick={handleRemove}
                    fullWidth={false}
                  >
                    <Delete sx={{ fontSize: '18px' }} />
                  </MaterialButton>
                </Item>
              }
            />
          </Grid>
        }
      />
    </List.Section>
  );
};

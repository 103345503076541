import moment from 'moment';

import { DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';

import { getUserById } from '../../resources/users/get-user-by-id.resolver';

export interface IGetUserIdParams {
  userId: string;
}

export async function getUserByIdResolver(params: IGetUserIdParams): Promise<any> {
  const user = await getUserById(params.userId);
  return transformUser(user);
}

function transformUser(user: any) {
  return {
    ...user,
    createdAt: moment(user.created_at).format(DATETIME_FORMAT),
  };
}

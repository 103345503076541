import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Column, Columns, Tiles } from '@mobily/stacks';
import { useQuery } from '@tanstack/react-query';
import { Text, useWindowDimensions } from 'react-native';
import { VictoryPie, VictoryTooltip } from 'victory-native';

import { VictoryMaterialTheme } from '@iokanx/dashboard/ui';
import { getPaymentSystemAnalyticsResolver } from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { Caption, SimpleCard, Card } from '@iokanx/shared/ui/universal';

export interface IPaymentSystemAnalyticsProps {
  from_dt?: Date;
  to_dt?: Date;
}

export function PaymentSystemAnalytics(props: IPaymentSystemAnalyticsProps) {
  const windowDimensions = useWindowDimensions();
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const chartWidth = windowDimensions.width - 48;
  const chartHeight = chartWidth / (chartWidth / 175);

  const chartSize = currentBreakpoint === Breakpoint.Mobile && { width: chartWidth, height: chartHeight };

  const chartInnerRadius = currentBreakpoint === Breakpoint.Mobile ? 50 : 100;

  const { data: paymentSystemAnalytics } = useQuery(['payment-system-analytics', props], () =>
    getPaymentSystemAnalyticsResolver(props),
  );

  return (
    <SimpleCard title={'Платежные системы'}>
      <Card.Content>
        <Tiles columns={[1, 2]} space={6}>
          <VictoryPie
            data={paymentSystemAnalytics}
            x={'title'}
            y={'count'}
            theme={VictoryMaterialTheme}
            padding={0}
            labelComponent={<VictoryTooltip constrainToVisibleArea />}
            innerRadius={chartInnerRadius}
            style={{ data: { fill: (args) => args.datum.color } }}
            {...chartSize}
          />
          <Tiles space={2}>
            {paymentSystemAnalytics?.map((item) => (
              <Columns key={item.paymentSystem} space={2} alignY={'center'}>
                <Column width={'content'}>
                  <Icon name={'circle'} color={item.color} />
                </Column>
                <Column width={'content'}>
                  <Caption>{item.title}</Caption>
                </Column>
                <Column>
                  <Text style={{ textAlign: 'right' }}>{item.count}</Text>
                </Column>
              </Columns>
            ))}
          </Tiles>
        </Tiles>
      </Card.Content>
    </SimpleCard>
  );
}

import React, { FC, useEffect, useState } from 'react';

import { OwnerProfileSection } from './OwnerProfileSection';
import { ShareholdersSection } from './ShareholdersSection';

import { checkChildrenSections } from '../../../helpers/helpers';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { ProfileFormStep } from '../../layouts/ProfileFormStep';
import { IFormSection } from '../OrganizationInfoStep/types';

export const HolderStep: FC<IFormSection> = (props: any) => {
  const [isSaved, setIsSaved] = useState(false);
  const [childrenSections, setChildrenSections] = useState<any[]>([]);

  const updateChildrenCompletionStatus = (title: string, isComplete: boolean) => {
    setChildrenSections((prevStatus) => ({
      ...prevStatus,
      [title]: isComplete,
    }));
  };

  // console.log(childrenSections, 'childrenSections', isSaved);

  useEffect(() => {
    setIsSaved(checkChildrenSections(childrenSections));
  }, [childrenSections]);

  return (
    <ProfileFormStep {...props}>
      <ProfileFormSection isSaved={isSaved} handleSubmit={null}>
        {/*<ProfileFormSection title={'Руководство и акционеры'} isSaved={isSaved} handleSubmit={null}>*/}
        <OwnerProfileSection updateChildrenCompletionStatus={updateChildrenCompletionStatus} />
        <ShareholdersSection isSaved={isSaved} updateChildrenCompletionStatus={updateChildrenCompletionStatus} />
      </ProfileFormSection>
    </ProfileFormStep>
  );
};

import { useCurrentBreakpoint } from '@mobily/stacks';

import { Breakpoint } from '../constants';

export interface UseTypedCurrentBreakpoint {
  currentBreakpoint: Breakpoint;
}

export function useTypedCurrentBreakpoint(): UseTypedCurrentBreakpoint {
  const currentBreakpoint = useCurrentBreakpoint() as Breakpoint;

  return { currentBreakpoint };
}

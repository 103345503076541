export enum AccountStatus {
  /** На проверке */
  Pending = 'PENDING',
  /** Потвержден */
  Accepted = 'ACCEPTED',
  /** Заблокирован */
  Blocked = 'BLOCKED',
}

export const ACCOUNT_STATUS_TITLE: Record<AccountStatus, string> = {
  [AccountStatus.Pending]: 'Создан',
  [AccountStatus.Blocked]: 'Заблокирован',
  [AccountStatus.Accepted]: 'Принят',
};

export const ACCOUNT_STATUS_COLOR: Record<AccountStatus, string> = {
  [AccountStatus.Pending]: '#334155',
  [AccountStatus.Blocked]: '#334155',
  [AccountStatus.Accepted]: '#10B981',
};

export const SplitRuleOperationType: Record<string, string> = {
  DEPOSIT: 'Пополнение',
  CASHBACK: 'Кэшбек',
  COMMISSION: 'Комиссия',
  WITHDRAWAL: 'Списани',
};

import AsyncStorage from '@react-native-async-storage/async-storage';
import axios, { AxiosRequestConfig } from 'axios';
import { set } from 'lodash';

const ACCESS_TOKEN_KEY = 'access_token';
const SHOP_ACCESS_TOKEN_KEY = 'shop_access_token';

export const SHOP_ACCESS_TOKEN_URLS = [
  'orders',
  'analytics',
  'search',
  'export',
  'payment-links',
  'dashboard',
  'payouts',
  'revises',
  'fulfilment',
  'statements',
  'organizations',
  'psm',
  'nps',
];
export function isShopAccessTokenUrl(url?: string) {
  return SHOP_ACCESS_TOKEN_URLS.some((shopUrl) => url?.includes(shopUrl));
}

export async function setShopAccessTokenInterceptor(requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  if (isShopAccessTokenUrl(requestConfig?.url)) {
    const shopAccessToken = await AsyncStorage.getItem(SHOP_ACCESS_TOKEN_KEY);
    const accessToken = await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
    if (shopAccessToken) {
      set(requestConfig, ['headers', 'API-KEY'], shopAccessToken);
    } else if (accessToken) {
      set(requestConfig, ['headers', 'API-KEY'], accessToken);
    } else {
      throw new axios.Cancel("hasn't shop access token");
    }
  }

  return requestConfig;
}

import React, { FC } from 'react';

import { OrganizationAddressSection } from './components/OrganizationAddressSection/OrganizationAddressSection';
import { OrganizationContactForm } from './components/OrganizationContactForm/OrganizationContactForm';
import { OrganizationEmployerSection } from './components/OrganizationEmployerSection/OrganizationEmployerSection';
import { OrganizationInfoSection } from './components/OrganizationInfoSection/OrganizationInfoSection';
import { IFormSection } from './types';

import { ProfileFormStep } from '../../layouts/ProfileFormStep';

export const OrganizationInfoStep: FC<IFormSection> = (props) => {
  return (
    <ProfileFormStep {...props}>
      <OrganizationEmployerSection title={'Контактные данные представителя компании'} />
      <OrganizationInfoSection />
      <OrganizationAddressSection />
      <OrganizationContactForm title={'Контакты организации'} />
    </ProfileFormStep>
  );
};

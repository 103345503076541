import { deleteShopsByIdUsersById } from '../../resources';

export interface IDeleteShopUserResolverParams {
  shopId: string;
  userId: string;
}

export function deleteShopUserResolver(params: IDeleteShopUserResolverParams): Promise<void> {
  return deleteShopsByIdUsersById(params.shopId, params.userId);
}

import { Box } from '@mui/material';
import { range } from 'lodash';
import { MouseEvent } from 'react';

import { Breakpoint } from '@iokanx/shared/feature';

import { RATING_LENGTH } from './constants';
import { splitIntoTwoArrays } from './helpers';
import { NPSRatingContainerStyles, NPSRatingItemStyles, NPSRatingWrapperStyles } from './styles';

interface INPSRating {
  rating?: number;
  handleChange: (event: MouseEvent<HTMLDivElement>) => void;
  type?: Breakpoint;
}

export const NPSRating = ({ rating, handleChange, type = Breakpoint.Desktop }: INPSRating) => {
  const ratingItems = range(RATING_LENGTH);

  const RESPONSIVE_NPS_RATING: Record<Breakpoint, JSX.Element> = {
    [Breakpoint.Mobile]: (
      <Box sx={NPSRatingWrapperStyles}>
        {splitIntoTwoArrays(ratingItems).map((ratingItemsRow, index) => {
          return (
            <Box key={index} sx={NPSRatingContainerStyles(true)}>
              {ratingItemsRow.map((item: number) => {
                return (
                  <Box sx={NPSRatingItemStyles(item, rating)} key={item} onClick={handleChange}>
                    {item}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    ),
    [Breakpoint.Desktop]: (
      <Box sx={NPSRatingContainerStyles(false)}>
        {ratingItems.map((item: number) => {
          return (
            <Box key={item} onClick={handleChange} sx={NPSRatingItemStyles(item, rating)}>
              {item}
            </Box>
          );
        })}
      </Box>
    ),
  };

  return RESPONSIVE_NPS_RATING[type];
};

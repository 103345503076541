import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { LEGAL_ADDRESS_TYPE, ACTUAL_ADDRESS_TYPE, organizationAddressFields } from './constants';
import { patchData, postData } from './data-access';
import { filterAddress } from './helpers';

import { useFormEdit } from '../../../../../helpers/useFormEdit';
import { useProfileFormContext } from '../../../../../providers/ProfileFormProvider';
import { FormField } from '../../../../FormField/FormField';
import { ProfileFormSection } from '../../../../layouts/ProfileFormSection';
import { getDefaultValues } from '../../helpers';
import { organizationAddressValidationSchema } from '../../model/schemas';

const getOrganizationAddressQueryKey = (organizationId: string) => ['organization', organizationId, 'address'];

export const OrganizationLegalAddress: FC<{ isSameAddress?: boolean }> = ({ isSameAddress }) => {
  const { organizationId, isSubmitted } = useProfileFormContext();
  const queryClient = useQueryClient();

  const { data } = useQuery(getOrganizationAddressQueryKey(organizationId), async () => {
    const { data } = await backendHttp.get(`/organizations/${organizationId}/addresses`);

    return data;
  });

  const { mutate: mutateAddress } = useMutation(
    !isEmpty(filterAddress(data, LEGAL_ADDRESS_TYPE))
      ? (formData: any) => patchData(organizationId, formData, data)
      : (formData: any) => postData(organizationId, formData),
    {
      onSuccess: async () => {
        handleIsEdit();
        await queryClient.invalidateQueries(getOrganizationAddressQueryKey(organizationId));
      },
      onError: (error) => console.error(error),
    },
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(organizationAddressValidationSchema),
  });

  const onSubmit = async (data: any) => {
    const legalAddressData = {
      ...data,
      type: LEGAL_ADDRESS_TYPE,
    };

    if (isSameAddress) {
      const actualAddressData = {
        ...data,
        type: ACTUAL_ADDRESS_TYPE,
      };

      await Promise.all([mutateAddress(legalAddressData), mutateAddress(actualAddressData)]);

      return;
    }

    mutateAddress(legalAddressData);
  };
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(
    Boolean(!isEmpty(filterAddress(data, LEGAL_ADDRESS_TYPE))),
    handleSubmit(onSubmit, onError),
  );

  const handleSubmitSection = () => (isDirty ? handlePress() : handleIsEdit());

  useEffect(() => {
    const isLegalAddress = (addresses: any) => addresses.type === LEGAL_ADDRESS_TYPE;
    const legalAddress = data?.find(isLegalAddress);

    if (legalAddress) {
      reset(legalAddress);
    }
  }, [data]);

  return (
    <ProfileFormSection
      handleSubmit={handleSubmitSection}
      title={'Юридический адрес'}
      isSubmitted={isSubmitted}
      isSaved={isDisable}
    >
      {organizationAddressFields.map((field, index) => (
        <FormField key={index} name={field.name} control={control} label={field.label} disabled={isDisable} />
      ))}
    </ProfileFormSection>
  );
};

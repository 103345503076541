import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { ITextFieldProps, TextField } from '@iokanx/shared/ui/universal';

import { Item } from '../ProfileForm';

interface FormFieldProps extends ITextFieldProps {
  name: string;
  control: Control<any>;
  label: string;
  disabled: boolean;
}

export const FormField: FC<FormFieldProps> = ({ name, control, label, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <Item>
          <TextField
            label={label}
            value={value}
            onChangeText={onChange}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={error?.message}
            fullWidth
            {...props}
          />
        </Item>
      )}
    />
  );
};

import { CardPayAdditionalField, ShopFeature } from '@iokanx/shared/data-access/constants';

import { http } from '../../../../instances';

export interface IGetConfigsByShopIdFeaturesResponseDataProviderInstallment {
  /** Период рассрочки */
  period: number;
  /** Минимальная сумма рассрочки в тиынах */
  min_value: 500000;
  /** Максимальная сумма рассрочки в тиынах */
  max_value: 100000000;
  unit: 'PERCENT';
  value: 0;
}

export interface IGetConfigsByShopIdFeaturesResponseDataApplePay {
  merchant_id: string;
  display_name: string;
  domain_name: string;
}

export interface IGetConfigsByShopIdFeaturesResponseDataLimit {
  min?: number;
  max?: number;
}

export interface IGetConfigsByShopIdFeaturesResponseDataProvider {
  name: string;
  installment?: IGetConfigsByShopIdFeaturesResponseDataProviderInstallment[];
}
export interface IGetConfigsByShopIdFeaturesResponseDataCardPaymentAdditionalField {
  name: CardPayAdditionalField;
  required: boolean;
}
export interface IGetConfigsByShopIdFeaturesResponseDataCardPayment {
  additional_fields?: (IGetConfigsByShopIdFeaturesResponseDataCardPaymentAdditionalField | CardPayAdditionalField)[];
}
export interface IGetConfigsByShopIdFeaturesResponseDataAcquirer {
  priority: number;
  features: ShopFeature[];
  commission_rules: {
    payment_systems: string[];
    emitters: string[];
    mcc: string;
    processing_fee: {
      unit: 'PERCENT' | 'FIXED';
      value: number;
      min_value: number;
    };
  }[];
}
export interface IGetConfigsByShopIdFeaturesResponseData {
  /** Дата создания */
  created_at: string;
  /** Идентификатор магазина */
  shop_id: string;
  /** Название магазина */
  shop_name: string;
  /** Доступные конфигурации */
  features: ShopFeature[];
  /** Доступные банк-эквайеры магазина */
  acquirers: IGetConfigsByShopIdFeaturesResponseDataAcquirer[];
  /** Доступные кредиторы магазина */
  providers: IGetConfigsByShopIdFeaturesResponseDataProvider[];
  apple_pay?: IGetConfigsByShopIdFeaturesResponseDataApplePay;
  google_pay?: any;
  card_payment?: IGetConfigsByShopIdFeaturesResponseDataCardPayment;
  limit?: IGetConfigsByShopIdFeaturesResponseDataLimit;
}

/** Доступные банк-эквайеры и кредиторы */
export async function getConfigsByShopIdFeatures(shopId: string): Promise<IGetConfigsByShopIdFeaturesResponseData> {
  const { data } = await http.get<IGetConfigsByShopIdFeaturesResponseData>(`/configs/${shopId}/features`);
  return data;
}

import { PAYMENT_STATUS_COLOR } from './payment-status';

export enum AdminPaymentStatus {
  // Ожидает подтверждения оплаты
  Pending = 'PENDING',
  // Ожидает подтверждения оплаты
  RequiresAction = 'REQUIRES_ACTION',
  // Сумма авторизована после двухфакторки
  Approved = 'APPROVED',
  // Сумма списана полностью после однофакторки или двухфакторки
  Captured = 'CAPTURED',
  // Авторизованная сумма отменена полностью
  Cancelled = 'CANCELLED',
  // Попытка платежа неуспешна
  Declined = 'DECLINED',
  // Заказ создан, но не было попытки оплаты (не создан платеж)
  Created = 'CREATED',
}

export const ADMIN_PAYMENT_STATUS_I18N: Record<AdminPaymentStatus, string> = {
  [AdminPaymentStatus.Pending]: 'В обработке',
  [AdminPaymentStatus.RequiresAction]: '3D Secure',
  [AdminPaymentStatus.Approved]: 'Авторизован',
  [AdminPaymentStatus.Captured]: 'Списан',
  [AdminPaymentStatus.Cancelled]: 'Отменен',
  [AdminPaymentStatus.Declined]: 'Отклонен',
  [AdminPaymentStatus.Created]: 'Создан',
};

export const ADMIN_PAYMENT_STATUS_COLOR: Record<AdminPaymentStatus, string> = {
  ...PAYMENT_STATUS_COLOR,
  [AdminPaymentStatus.Created]: 'blue',
};

import { SliderProps } from '@react-native-community/slider';
import { isNumber } from 'lodash';

export interface ISliderProps extends SliderProps {
  rangeLabelFormat?: (value: number) => string;
}

export function getSliderValue(props: ISliderProps) {
  const min = isNumber(props.minimumValue) && Number.isFinite(props.minimumValue) ? props.minimumValue : 0;
  const max = isNumber(props.maximumValue) && Number.isFinite(props.maximumValue) ? props.maximumValue : 1;
  const step = isNumber(props.step) && Number.isFinite(props.step) ? props.step : 0;

  return { min, max, step };
}

import { Box, Stack } from '@mobily/stacks';
import { StyleSheet } from 'react-native';

import { IFindDashboardPaymentsResolverResultItem } from '@iokanx/shared/data-access/api';
import { Caption, Card, Paragraph, SimpleCard, SimpleCardProps } from '@iokanx/shared/ui/universal';

import { ClipboardButton } from '../clipboard-button/clipboard-button';
import { DashboardPaymentStatusChip } from '../dashboard-payment-status-chip/dashboard-payment-status-chip';

export interface IOrderCardProps {
  order: IFindDashboardPaymentsResolverResultItem;
  onCardPress: SimpleCardProps['onPress'];
}

interface IContentItem {
  title: string;
  key: keyof Pick<IFindDashboardPaymentsResolverResultItem, 'externalId' | 'createdAt'>;
}
const contentItems: IContentItem[] = [
  {
    title: 'Номер заказа',
    key: 'externalId',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
  },
];

export function OrderCard(props: IOrderCardProps) {
  return (
    <SimpleCard
      title={props.order.amountWithSymbol}
      right={() => (
        <Box marginRight={4}>
          <DashboardPaymentStatusChip status={props.order.status} />
        </Box>
      )}
      onPress={props.onCardPress}
    >
      <Card.Content>
        {contentItems.map((item) => (
          <Stack key={item.key} horizontal space={2} style={styles.contentItem}>
            <Caption>{item.title}</Caption>
            <Paragraph>{props.order[item.key]}</Paragraph>
          </Stack>
        ))}
      </Card.Content>
      <Card.Actions>
        <ClipboardButton copiedText={props.order.checkout_url}>{props.order.id}</ClipboardButton>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  contentItem: {
    justifyContent: 'space-between',
  },
});

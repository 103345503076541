export enum PsmStatus {
  PaymentCaptured = 'PAYMENT_CAPTURED',
  PaymentCancelled = 'PAYMENT_CANCELLED',
  RefundApproved = 'REFUND_APPROVED',
  PaymentDeclined = 'PAYMENT_DECLINED',
}

export const PSM_STATUS_I18N: Record<PsmStatus, string> = {
  [PsmStatus.PaymentCaptured]: 'Списать',
  [PsmStatus.PaymentCancelled]: 'Отменить',
  [PsmStatus.RefundApproved]: 'Вернуть',
  [PsmStatus.PaymentDeclined]: 'Отклонить',
};

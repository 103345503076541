export enum PayerType {
  /** Оплата картой */
  CARD = 'CARD',
  /** Оплата картой без подтверждения CVC */
  CARD_NO_CVC = 'CARD_NO_CVC',
  /** Оплата картой с последующим сохранением */
  CARD_WITH_BINDING = 'CARD_WITH_BINDING',
  /** Оплата сохраненной картой */
  BINDING = 'BINDING',
  /** Оплата через Apple Pay */
  APPLE_PAY = 'APPLE_PAY',
  /** Оплата через Google Pay */
  GOOGLE_PAY = 'GOOGLE_PAY',
  /** Masterpass */
  MASTERPASS = 'MASTERPASS',
}

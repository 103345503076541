import { backendHttp } from '../../instances';

export async function postNpsSync(formData: any): Promise<Blob> {
  const { data } = await backendHttp.post(`/support/nps/sync`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

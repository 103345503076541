import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQuery } from '@tanstack/react-query';

import { findShops, IFindShopsResultItem } from '@iokanx/shared/data-access/api';
import { SELECTED_SHOP_ID } from '@iokanx/shared/data-access/constants';

export interface IUseSelectedShop {
  selectedShop?: IFindShopsResultItem;
}

export function useSelectedShop(): IUseSelectedShop {
  const { data: shops = [] } = useQuery(['shops'], () => findShops());
  const { data: selectedShopId } = useQuery([SELECTED_SHOP_ID], () => AsyncStorage.getItem(SELECTED_SHOP_ID));
  const selectedShop = shops.find((shop) => shop.id === selectedShopId);
  return { selectedShop };
}

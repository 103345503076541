import { Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColumns, GridPagination, ruRU } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ScrollView } from 'react-native';

import { ShopNameChip } from '@iokanx/dashboard/ui';
import { analyticsNpsResolver, IFindNpsResolverResultItem, searchNpsResolver } from '@iokanx/shared/data-access/api';

import { useMe } from '../auth';
import { IVoteListProps } from '../vote-list/vote-list';

const columns: GridColumns<IFindNpsResolverResultItem> = [
  {
    headerName: 'Идентификатор',
    field: 'id',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Магазин',
    field: 'shopId',
    flex: 1,
    renderCell: (props) => {
      return (
        <Suspense fallback={<Typography>loading...</Typography>}>
          {(props.value && <ShopNameChip shopId={props.value} />) || props.row.shopName}
        </Suspense>
      );
    },
    sortable: false,
  },
  {
    headerName: 'Email',
    flex: 1,
    field: 'email',
    sortable: false,
  },
  {
    headerName: 'Оценка',
    flex: 1,
    field: 'grade',
    sortable: false,
  },
  {
    headerName: 'Дата голосования',
    flex: 1,
    field: 'voteAt',
    sortable: false,
  },
  {
    headerName: 'Дата создания',
    flex: 1,
    field: 'createdAt',
    sortable: false,
  },
];

const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам опросов не найдено',
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingBottom: theme.spacing(2),
  boxShadow: 'none',
  border: 'none',
  textAlign: 'center',
}));

export function VoteListDesktop(props: IVoteListProps) {
  const { data: votes, isFetching } = useQuery(['votes', props.form.watch()], () =>
    searchNpsResolver({
      ...props.form.watch(),
      limit: props.pageLimit,
    }),
  );

  const { data: nps, isFetching: isNpsFetching } = useQuery(['nps', props.form.watch()], () =>
    analyticsNpsResolver({
      ...props.form.watch(),
    }),
  );

  const { me } = useMe();

  return (
    <ScrollView>
      <Stack padding={2} spacing={2}>
        {props.filter}
        <DataGrid
          rows={votes?.data || []}
          columnVisibilityModel={{ shopId: !!me?.is_superuser }}
          onRowClick={(params) => props.onVotePress(params.row)}
          columns={columns}
          loading={isFetching}
          page={props.form.watch('page') - 1}
          onPageChange={(page) => props.form.setValue('page', page + 1)}
          rowCount={votes?.count}
          localeText={localeText}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          pageSize={props.pageLimit}
          rowsPerPageOptions={[props.pageLimit]}
          paginationMode="server"
        />
        {nps && (
          <Item>
            Промоутеры ({nps.promoters}%) - Дектракторы ({nps.detractors}%) = NPS ({nps.nps}%)
          </Item>
        )}
      </Stack>
    </ScrollView>
  );
}

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Grid, IconButton, Stack, Link, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { IPageProps } from '@iokanx/payment-form/types';
import { Button, Footer, PaymentFormLoader, PaymentStatusHeader } from '@iokanx/payment-form/ui';
import {
  findCustomerByIdResolver,
  findCustomerCardsResolver,
  findCustomerEventsResolver,
  IFindCustomerEventsResolverResultItem,
} from '@iokanx/shared/data-access/api';
import { CustomerEventName } from '@iokanx/shared/data-access/constants';

import { CardList } from '../card-list/card-list';
import { CardSaveForm } from '../card-save-form/card-save-form';

const CUSTOMER_SCREEN = {
  list: 'list',
  add: 'add',
} as const;
export const CustomerScreenValues = Object.values(CUSTOMER_SCREEN);

export interface IMainCustomerContainerProps extends Pick<IPageProps, 'zoidProps'> {
  customerId: string;
  initialScreen?: typeof CustomerScreenValues[number];
}

const FROM_3DS_PARAM = 'from3ds';

export function MainCustomerContainer(props: IMainCustomerContainerProps) {
  const { t } = useTranslation();
  const router = useRouter();

  const [screen, setScreen] = useState(props.initialScreen ?? CUSTOMER_SCREEN.add);
  useEffect(() => {
    if (props.initialScreen) {
      setScreen(props.initialScreen);
    }
  }, [props.initialScreen]);

  const [isFrom3ds] = useState(router.query[FROM_3DS_PARAM] === 'true');
  useEffect(() => {
    if (router.query[FROM_3DS_PARAM]) {
      const { [FROM_3DS_PARAM]: _, ...rest } = router.query;
      router.replace({ query: rest });
    }
  }, [router]);

  const customerQuery = useQuery(['customer', props.customerId], () =>
    findCustomerByIdResolver({ customerId: props.customerId }),
  );
  const customerCardsQuery = useQuery(
    ['customer-cards', customerQuery.data?.id],
    () =>
      customerQuery.data &&
      findCustomerCardsResolver({
        customerId: customerQuery.data.id,
        customerAccessToken: customerQuery.data.access_token,
      }),
    {
      onSuccess: (cards) => {
        if (props.initialScreen) return;

        if (cards && cards.length > 0) {
          setScreen(CUSTOMER_SCREEN.list);
        }
      },
    },
  );

  const [lastEvent, setLastEvent] = useState<IFindCustomerEventsResolverResultItem>();
  const customerEventsQuery = useQuery(
    ['customer-events', customerQuery.data?.id],
    () => customerQuery.data && findCustomerEventsResolver({ customerId: customerQuery.data.id }),
    {
      onSuccess: (events) => {
        if (events && events.length >= 2) {
          const lastEvent = events[events.length - 1];
          const preLastEvent = events[events.length - 2];

          const isErrorEventFrom3DS =
            isFrom3ds &&
            preLastEvent.name === CustomerEventName.CARD_ACTION_REQUIRED &&
            lastEvent.name === CustomerEventName.CARD_DECLINED;

          if (isErrorEventFrom3DS) {
            props.zoidProps?.onError?.(lastEvent);
            setLastEvent(lastEvent);
          }

          const isSuccessEventFrom3DS =
            isFrom3ds &&
            preLastEvent.name === CustomerEventName.CARD_ACTION_REQUIRED &&
            lastEvent.name === CustomerEventName.CARD_APPROVED;

          if (isSuccessEventFrom3DS) {
            props.zoidProps?.onSuccess?.(lastEvent);
          }
        }
      },
    },
  );

  function handleAddCard() {
    setLastEvent(undefined);
    setScreen(CUSTOMER_SCREEN.add);
  }

  if ([customerQuery, customerCardsQuery, customerEventsQuery].some((query) => query.isLoading)) {
    return <PaymentFormLoader />;
  }

  if (lastEvent?.name === CustomerEventName.CARD_DECLINED) {
    return (
      <Grid item>
        <Stack spacing={2}>
          <PaymentStatusHeader type="declined" title={t('Карта не сохранена')} />
          <Typography>{lastEvent.message}</Typography>
          <Button onClick={handleAddCard} id={'add-card-button'}>
            {t('Добавить новую карту')}
          </Button>
        </Stack>
      </Grid>
    );
  }

  if (!customerQuery.data) return null;

  return (
    <>
      <Grid item mb={5}>
        {screen === CUSTOMER_SCREEN.list && <Typography variant={'h5'}>{t('Сохраненные карты')}</Typography>}
        {screen === CUSTOMER_SCREEN.add && (
          <Stack direction={'row'} alignItems={'center'}>
            {Boolean(customerCardsQuery.data?.length) && (
              <IconButton onClick={() => setScreen(CUSTOMER_SCREEN.list)}>
                <ChevronLeftIcon color={'primary'} />
              </IconButton>
            )}
            <Typography variant={'h5'}>{t('Новая карта')}</Typography>
          </Stack>
        )}
      </Grid>
      {screen === CUSTOMER_SCREEN.list && (
        <>
          {customerQuery.data.back_url && (
            <Link href={customerQuery.data.back_url} underline={'none'}>
              <Stack direction={'row'} alignItems={'center'} padding={'0 0 20px'}>
                <ChevronLeftIcon color={'primary'} />
                <Typography>{t('Вернуться в магазин')}</Typography>
              </Stack>
            </Link>
          )}

          <Grid item>
            <CardList customer={customerQuery.data} />
          </Grid>
          <Grid item mt={3}>
            <Button onClick={handleAddCard} id={'add-card-button'}>
              {t('Добавить новую карту')}
            </Button>
          </Grid>
        </>
      )}
      {screen === CUSTOMER_SCREEN.add && (
        <CardSaveForm
          customer={customerQuery.data}
          onSuccess={(data) => {
            props.zoidProps?.onSuccess?.(data);
            setScreen(CUSTOMER_SCREEN.list);
          }}
          onError={props.zoidProps?.onError}
        />
      )}
      <Grid item mt={5}>
        <Footer text={t('Ваши карты надежно защищены')} />
      </Grid>
    </>
  );
}

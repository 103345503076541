import { MaterialCommunityIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import { Link } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { Colors } from 'react-native-paper';
import * as yup from 'yup';

import { IResetPasswordResolverParams, resetPasswordResolver } from '@iokanx/shared/data-access/api';
import { DeviceSize } from '@iokanx/shared/data-access/constants';
import { IHttpError } from '@iokanx/shared/data-access/types';
import { Caption, Card, SimpleCard, HelperText, Title, PasswordField, Button } from '@iokanx/shared/ui/universal';

import { DashboardStackParamList, useDashboardRoute } from '../navigation';

type IResetPasswordFormFieldValues = Pick<IResetPasswordResolverParams, 'password1' | 'password2'>;

const resetPasswordFormSchema: yup.SchemaOf<IResetPasswordFormFieldValues> = yup.object({
  password1: yup.string().required('Обязательное поле'),
  password2: yup.string().required('Обязательное поле'),
});

export function ResetPasswordForm() {
  const route = useDashboardRoute<'ResetPassword'>();

  const { control, handleSubmit } = useForm<IResetPasswordFormFieldValues>({
    defaultValues: { password1: '', password2: '' },
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const {
    mutate: resetPassword,
    isLoading: isResettingPassword,
    error: resetPasswordError,
    isError: isResetPasswordError,
    isSuccess: isSuccessResetPassword,
  } = useMutation<void, AxiosError<IHttpError>, IResetPasswordResolverParams>(resetPasswordResolver);
  const onSubmit: SubmitHandler<IResetPasswordFormFieldValues> = (data) => {
    resetPassword({ ...data, token: route.params.token });
  };

  if (isSuccessResetPassword) {
    return (
      <Stack space={2} align={'center'}>
        <MaterialCommunityIcons name="account-check" size={80} color={Colors.green500} />
        <Title>Доступ восстановлен</Title>
        <Link<DashboardStackParamList> to={{ screen: 'SignIn' }}>Войти</Link>
      </Stack>
    );
  }

  return (
    <SimpleCard title={'Сброс пароля'} subtitle={'Придумайте новый пароль'} cardStyle={styles.card}>
      <Card.Content>
        <Stack space={2}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PasswordField
                label={'Новый пароль'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                error={Boolean(error)}
                helperText={error?.message}
                autoComplete={'password-new'}
              />
            )}
            name={'password1'}
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <PasswordField
                label={'Повторите пароль'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                error={Boolean(error)}
                helperText={error?.message}
                autoComplete={'password-new'}
              />
            )}
            name={'password2'}
          />
          <HelperText type={'error'} visible={isResetPasswordError}>
            {resetPasswordError?.response?.data.message}
          </HelperText>
        </Stack>
      </Card.Content>
      <Card.Actions>
        <Stack padding={2} space={2} align={'center'}>
          <Button onPress={handleSubmit(onSubmit)} loading={isResettingPassword} fullWidth>
            Сохранить
          </Button>
          <Stack horizontal>
            <Link<DashboardStackParamList> to={{ screen: 'SignIn' }}>
              <Caption>Вспомнили пароль?</Caption> Войти
            </Link>
          </Stack>
        </Stack>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  card: {
    width: '100%',
    backgroundColor: '#f8fafc',
    borderRadius: 12,
    border: 'none',
    maxWidth: DeviceSize.LargeMobile,
  },
});

import { ShopUserRole } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../../instances';

export interface IPostShopsByIdInvitesRequestData {
  /** Почта пользователя */
  email: string;
  /** Код роли */
  role_code: ShopUserRole;
}

export interface IPostShopsByIdInvitesResponseData {
  /** Статус запроса */
  status: number;
  /** Детали запроса */
  detail: string;
}

/** Приглашение пользователя */
export async function postShopsByIdInvites(
  shopId: string,
  requestData: IPostShopsByIdInvitesRequestData,
): Promise<IPostShopsByIdInvitesResponseData> {
  const { data } = await backendHttp.post(`/shops/${shopId}/invites`, requestData);
  return data;
}

import { backendHttp } from '@iokanx/shared/data-access/api';

export const createOrganizationEmployerResolver = async (requestBody: any, organizationId: string) => {
  const { data } = await backendHttp.post(`/organizations/${organizationId}/employers`, requestBody);

  return data;
};

export const updateOrganizationEmployerResolver = async (
  requestBody: any,
  organizationId: string,
  employerId: string,
) => {
  const { data } = await backendHttp.patch(`/organizations/${organizationId}/employers/${employerId}`, requestBody);

  return data;
};

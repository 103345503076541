import { Platform, StyleSheet } from 'react-native';
import { MaskedTextInput, unMask } from 'react-native-mask-text';
import { TextInput } from 'react-native-paper';

import { IInputProps, Input } from '../input/input';

export function MaskedPanInput(props: IInputProps) {
  const unmaskedValue = props.value && unMask(props.value);

  const error =
    unmaskedValue &&
    ((unmaskedValue.length > 0 && unmaskedValue.length < 6 ? 'Введите первые 6 цифр' : '') ||
      (unmaskedValue.length > 6 && unmaskedValue.length < 10 ? 'Введите последние 4 цифры' : ''));

  return (
    <Input
      render={(inputProps) => <MaskedTextInput mask={'9999 99·· ···· 9999'} {...inputProps} />}
      left={<TextInput.Icon name="credit-card-outline" />}
      placeholder={'···· ···· ···· ····'}
      textContentType={'creditCardNumber'}
      keyboardType={'numeric'}
      autoComplete={'cc-number'}
      error={error}
      style={styles.input}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: Platform.OS === 'web' ? -8 : 0,
  },
});

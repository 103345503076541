import { IGetFulfilmentByIdResponseData } from './by-id';

import { backendHttp } from '../../instances';

export type IGetFulfilmentsRequestParams = {
  page?: number;
  limit?: number;
  to_dt?: Date;
  from_dt?: Date;
};

export interface IGetFulfilmentsResponseData<IDataItem = IGetFulfilmentByIdResponseData> {
  count: number;
  data: IDataItem[];
}

/** Поиск отчетов по фильтрам */
export async function getFulfilments(
  params: IGetFulfilmentsRequestParams,
): Promise<Readonly<IGetFulfilmentsResponseData>> {
  const { data } = await backendHttp.get<IGetFulfilmentsResponseData>('/reports/fulfilments', { params });
  return data;
}

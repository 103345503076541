import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';

import { useFormEdit } from '../../../helpers/useFormEdit';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { PersonalData } from '../../PersonalData';
import { parseData } from '../HolderStep/helpers';
import { organizationOfficialValidationSchema } from '../OrganizationInfoStep/model/schemas';

export const parseFormData = (beneficiary: any) => {
  return {
    is_beneficiary: beneficiary.is_beneficiary,
    type: beneficiary.personal_data.type,
    share: beneficiary.share,
    personal_data: {
      first_name: beneficiary.personal_data.first_name,
      last_name: beneficiary.personal_data.last_name,
      patronymic: beneficiary.personal_data.patronymic,
      nib: beneficiary.personal_data.nib,
      number: beneficiary.personal_data.number,
      issued_by: beneficiary.personal_data.issued_by,
      issue_date: beneficiary.personal_data.issue_date,
      is_resident: beneficiary.personal_data.is_resident,
    },
  };
};

export const patchData = async (data: any, organizationId: string, id: string) => {
  const { data: response } = await backendHttp.patch(`organizations/${organizationId}/shares/${id}`, {
    is_beneficiary: data.is_beneficiary,
    share: Number(data.share),
    personal_data: parseData(data, 'PHYSICAL'),
  });

  return response;
};

interface IBeneficiary {
  beneficiary?: any;
  beneficiaries?: any[];
  parentId?: string;
  organizationId: string;
  isSubmitted: boolean;
}

export const MainBeneficiary = ({ isSubmitted, organizationId, beneficiary }: IBeneficiary) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      personal_data: parseData(beneficiary, 'PHYSICAL'),
    },
    resolver: yupResolver(organizationOfficialValidationSchema),
  });

  const { data: parentHolder } = useQuery(
    ['shares', beneficiary.parent_id],
    async () => {
      const { data } = await backendHttp.get(`organizations/${organizationId}/shares/${beneficiary.parent_id}`);

      return data;
    },
    {
      enabled: Boolean(beneficiary.parent_id),
    },
  );

  const { mutate: mutateBeneficiary } = useMutation((formData) => patchData(formData, organizationId, beneficiary.id), {
    onSuccess: (data: any) => {
      reset(parseFormData(data));
      handleIsEdit();
    },
  });

  const onSubmit = (data: any) => mutateBeneficiary(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(beneficiary), handleSubmit(onSubmit, onError));

  const handleSectionSubmit = () => (isDirty ? handlePress() : handleIsEdit());

  useEffect(() => {
    if (beneficiary) {
      reset(parseFormData(beneficiary));
    }
  }, [beneficiary]);

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Box padding={'8px'} marginTop={'12px'} border={'1px solid #e2e8f0'} borderRadius={'4px'} sx={{ width: '100%' }}>
        <ProfileFormSection
          title={`${beneficiary?.personal_data?.first_name} ${beneficiary?.personal_data?.last_name}`}
          isSaved={isDisable}
          isRemovable={false}
          isSubmitted={isSubmitted}
          handleSubmit={handleSectionSubmit}
        >
          <PersonalData control={control} type={'PHYSICAL'} isDisable={isDisable} />

          <Controller
            control={control}
            name={'is_beneficiary'}
            render={({ field: { onChange, value } }) => {
              return (
                <Item>
                  <FormControlLabel
                    onChange={onChange}
                    disabled={isDisable}
                    label={
                      parentHolder
                        ? `Является бенефициаром ${parentHolder?.personal_data?.name}`
                        : 'Является бенефициаром организации'
                    }
                    control={<Checkbox checked={Boolean(value)} />}
                  />
                </Item>
              );
            }}
          />
        </ProfileFormSection>
      </Box>
    </Stack>
  );
};

import { Stack, Typography } from '@mui/material';
import { CategoryScale, Chart, ChartDataset, Filler, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useTypedCurrentBreakpoint } from '@iokanx/shared/feature';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);

interface IChartDataItem {
  x: string;
  y: number;
  label: string;
}
export interface IFinanceAnalyticsChartProps {
  capturedChartData?: IChartDataItem[];
  approvedChartData?: IChartDataItem[];
}

export function FinanceAnalyticsChart(props: IFinanceAnalyticsChartProps) {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  const labels = props.capturedChartData?.map((item) => item.x) || [];

  const datasets: ChartDataset<'line', number[] | undefined>[] = [
    {
      label: 'Авторизовано',
      fill: true,
      backgroundColor: 'rgba(106, 48, 255, 0.1)',
      borderColor: '#6A30FF',
      borderWidth: 2,
      pointRadius: 0,
      data: props.approvedChartData?.map((item) => item.y),
    },
    {
      label: 'Списано',
      fill: true,
      backgroundColor: 'rgba(58, 189, 142, 0.2)',
      borderColor: '#3ABD8E',
      borderWidth: 2,
      pointRadius: 0,
      data: props.capturedChartData?.map((item) => item.y),
    },
  ];

  return (
    <Stack>
      <Line
        data={{
          labels,
          datasets,
        }}
        height={currentBreakpoint === 'desktop' ? 40 : 160}
        options={{
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              ticks: {
                display: false,
              },
              grid: {
                display: false,
                drawBorder: false,
              },
              beginAtZero: true,
            },
          },
          elements: {
            line: {
              tension: 0.08,
            },
          },
          layout: {
            padding: 4,
          },
          plugins: {
            tooltip: {
              intersect: false,
              mode: 'nearest',
              backgroundColor: '#475569',
              bodyFont: { size: 12 },
              padding: 16,
            },
          },
        }}
      />
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography color={'#94A3B8'} fontSize={12}>
          {labels[0]}
        </Typography>
        <Typography color={'#94A3B8'} fontSize={12}>
          {labels[labels.length - 1]}
        </Typography>
      </Stack>
    </Stack>
  );
}

import { backendHttp } from '../../../instances';

export interface IGetNpsByIdResponseData {
  id: string;
  shop_id?: string;
  shop_name?: string;
  email?: string;
  name?: string;
  grade: number;
  comment?: string;
  counter: number;
  voted_at?: string;
  created_at: string;
  tags: string[];
}

export async function getVoteById(voteId: string): Promise<IGetNpsByIdResponseData> {
  const { data } = await backendHttp.get<any>(`/support/nps/${voteId}`);
  return data;
}

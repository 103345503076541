import { AxiosResponse } from 'axios';

import { backendHttp } from '../../instances';

export interface IAuthLoginRequestData {
  email: string;
  password: string;
}
export interface IAuthLoginResponseData {
  readonly access_token: string;
  readonly refresh_token: string;
}

export async function postAuthLogin(requestData: IAuthLoginRequestData): Promise<IAuthLoginResponseData> {
  const { data } = await backendHttp.post<
    IAuthLoginResponseData,
    AxiosResponse<IAuthLoginResponseData>,
    IAuthLoginRequestData
  >('/auth/login', requestData);
  return data;
}

import { Box, Tiles } from '@mobily/stacks';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { RefreshControl, ScrollView } from 'react-native';

import {
  BankAnalytics,
  ErrorCountsAnalytics,
  FinanceAnalytics,
  PaymentAnalytics,
  PaymentMethodsAnalytics,
  PaymentSystemAnalytics,
  // TodaysAnalytics,
} from '@iokanx/dashboard/feature';
import { wrapPage } from '@iokanx/shared/feature';
import { DateRangePicker } from '@iokanx/shared/ui/universal';
import { useSessionStorageDate } from '@iokanx/shared/util';

export interface IAnalyticsPageStickyHeaderFormFieldValues {
  from_dt?: Date;
  to_dt?: Date;
}

function Analytics() {
  const { startDate, endDate } = useSessionStorageDate();

  const stickyHeaderForm = useForm<IAnalyticsPageStickyHeaderFormFieldValues>({
    defaultValues: {
      from_dt: startDate || moment().startOf('month').toDate(),
      to_dt: endDate || moment().endOf('day').toDate(),
    },
  });

  const queryClient = useQueryClient();
  const refreshing = Boolean(queryClient.isFetching());

  function onRefresh() {
    return queryClient.invalidateQueries();
  }

  return (
    <ScrollView
      stickyHeaderIndices={[1]}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
    >
      <Box padding={2}>
        <FinanceAnalytics from_dt={stickyHeaderForm.watch('from_dt')} to_dt={stickyHeaderForm.watch('to_dt')} />
      </Box>
      <Tiles columns={[1, 4]} paddingX={2}>
        <DateRangePicker
          values={{ startDate: stickyHeaderForm.watch('from_dt'), endDate: stickyHeaderForm.watch('to_dt') }}
          onChangeValues={(values) => {
            stickyHeaderForm.setValue('from_dt', values.startDate);
            stickyHeaderForm.setValue('to_dt', values.endDate);
          }}
        />
      </Tiles>
      <Tiles columns={[1, 2]} space={2} padding={2}>
        <PaymentAnalytics from_dt={stickyHeaderForm.watch('from_dt')} to_dt={stickyHeaderForm.watch('to_dt')} />
        <ErrorCountsAnalytics from_dt={stickyHeaderForm.watch('from_dt')} to_dt={stickyHeaderForm.watch('to_dt')} />
        <BankAnalytics from_dt={stickyHeaderForm.watch('from_dt')} to_dt={stickyHeaderForm.watch('to_dt')} />
        <PaymentSystemAnalytics from_dt={stickyHeaderForm.watch('from_dt')} to_dt={stickyHeaderForm.watch('to_dt')} />
        <PaymentMethodsAnalytics from_dt={stickyHeaderForm.watch('from_dt')} to_dt={stickyHeaderForm.watch('to_dt')} />
      </Tiles>
    </ScrollView>
  );
}

export default wrapPage(Analytics);

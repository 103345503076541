import { useQuery } from '@tanstack/react-query';

import { getAccessToken } from '../token';

export const IS_SIGNED_IN_QUERY_KEY = 'is-signed-in';

export interface IUseIsSignedInResult {
  isSignedIn: boolean;
  isSignedInLoading: boolean;
}

export function useIsSignedIn(): IUseIsSignedInResult {
  const { data, isLoading } = useQuery([IS_SIGNED_IN_QUERY_KEY], getAccessToken);
  return { isSignedIn: Boolean(data), isSignedInLoading: isLoading };
}

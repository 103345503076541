import AsyncStorage from '@react-native-async-storage/async-storage';
import { ErrorBoundary } from '@sentry/react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { List, Colors, ListItem, Button } from '@iokanx/shared/ui/universal';

export function ErrorResetBoundary(props: PropsWithChildren<unknown>) {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      fallback={({ resetError, error }) => {
        async function fullReset() {
          await AsyncStorage.clear();
          resetError();
        }

        return (
          <SafeAreaView style={styles.root}>
            <ListItem
              title={'Нажмите, чтобы сбросить ошибку'}
              description={error.message}
              descriptionNumberOfLines={0}
              left={(props) => <List.Icon {...props} icon="alert-circle" color={Colors.red500} />}
              onPress={resetError}
            />
            <Button onPress={fullReset}>Нажмите, чтобы сбросить все</Button>
          </SafeAreaView>
        );
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}

const styles = StyleSheet.create({ root: { flex: 1, justifyContent: 'space-between', padding: 8 } });

import { IGetIncomeByIdResponseData } from './by-id';

import { backendHttp } from '../../instances';

export type IGetIncomesRequestParams = {
  page?: number;
  limit?: number;
  to_dt?: Date;
  from_dt?: Date;
};

export interface IGetIncomesResponseData<IDataItem = IGetIncomeByIdResponseData> {
  count: number;
  data: IDataItem[];
}

/** Поиск опреации по транзитному счету */
export async function getIncomes(params: IGetIncomesRequestParams): Promise<Readonly<IGetIncomesResponseData>> {
  const { data } = await backendHttp.get<IGetIncomesResponseData>('/reports/statements', { params });
  return data;
}

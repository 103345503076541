import { IRadioButtonItem } from '@iokanx/shared/ui/universal';

import { BINARY_ANSWER_I18N, BinaryAnswer } from '../shared/types';

export const queryBuilder = (organizationId: string) => {
  return `/organizations/${organizationId}/answers`;
};

export const isCompleted = (array: any[]) => {
  const clientSteps = array;
  clientSteps.pop();

  return clientSteps.length > 0 ? clientSteps.every(Boolean) : false;
};

export const getBinaryItems = (isDisable: boolean): IRadioButtonItem<BinaryAnswer>[] =>
  Object.values(BinaryAnswer)?.map((captureMethod) => ({
    value: captureMethod,
    label: BINARY_ANSWER_I18N[captureMethod],
    disabled: isDisable,
  }));

export const generateFileFormData = (data: any) => {
  const formData = new FormData();

  formData.append('file', data.file);
  formData.append('comment', data.comment);
  formData.append('file_type_id', data.file_type_id);

  return formData;
};

export const isFormEmpty = (values: any) => Object.values(values).every((value) => value === '' || value === undefined);

export const checkChildrenSections = (sections: any) => {
  return Object.values(sections).length > 0 ? Object.values(sections)?.every(Boolean) : false;
};

export const initializeSteps = (stepsArray: any[]) => {
  const initialObject: {
    [k: number]: boolean;
  } = {};
  stepsArray.forEach((step: any, index: number) => {
    initialObject[index] = false;
  });
  return initialObject;
};

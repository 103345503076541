import { Stack } from '@mobily/stacks';
import { Typography } from '@mui/material';
import { useNavigation } from '@react-navigation/native';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';
import QRCode from 'react-qr-code';

import { Amplitude, CreateOrderForm, DashboardStackParamList, useMe } from '@iokanx/dashboard/feature';
import { ClipboardButton } from '@iokanx/dashboard/ui';
import {
  createOrderResolver,
  ICreateOrderResolverParams,
  ICreateOrderResolverResult,
} from '@iokanx/shared/data-access/api';
import { IHttpError } from '@iokanx/shared/data-access/types';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Button } from '@iokanx/shared/ui/universal';

function CreateOrder() {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { me } = useMe();

  const {
    mutate: createOrder,
    isSuccess,
    data: createdOrder,
    error,
    isLoading,
    isError,
    reset,
  } = useMutation<ICreateOrderResolverResult, AxiosError<IHttpError>, ICreateOrderResolverParams>(createOrderResolver, {
    onSuccess: () => {
      snackbar.show('Заказ успешно создан', { type: 'success' });
      Amplitude.logEventWithPropertiesAsync('create_order', { user_id: me?.id });
      return queryClient.invalidateQueries(['orders']);
    },
  });

  if (isSuccess && createdOrder) {
    return (
      <Stack padding={2} space={4}>
        <Typography sx={{ textAlign: 'center' }} marginY={'6px'} fontWeight={500} fontSize={18}>
          Создание платежной ссылки
        </Typography>

        <div style={{ height: 'auto', margin: '0 auto 20px', maxWidth: 256, width: '100%' }}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={createdOrder.order.checkout_url}
            viewBox={`0 0 256 256`}
          />
        </div>

        <ClipboardButton>{createdOrder.order.checkout_url}</ClipboardButton>

        <Button onPress={reset}>Создать новый заказ</Button>
      </Stack>
    );
  }

  return (
    <CreateOrderForm
      createOrder={createOrder}
      isLoading={isLoading}
      error={error?.response?.data.message}
      isError={isError}
    />
  );
}

export default wrapPage(
  wrapModalPage(CreateOrder, {
    fillNavigationStack: (navigation: NativeStackNavigationProp<DashboardStackParamList>) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Operations', params: { screen: 'Payments' } });
        navigation.push('CreateOrder');
      }
    },
    handleDismiss: (navigation) => {
      navigation.push('Drawer', { screen: 'Operations', params: { screen: 'Payments' } });
    },
  }),
);

import {
  IPostShopsByIdInvitesRequestData,
  IPostShopsByIdInvitesResponseData,
  postShopsByIdInvites,
} from '../../resources';

export interface IInviteShopUserResolverParams extends IPostShopsByIdInvitesRequestData {
  shopId: string;
}

export type IInviteShopUserResolverResult = IPostShopsByIdInvitesResponseData;

export function inviteShopUserResolver({
  shopId,
  ...requestData
}: IInviteShopUserResolverParams): Promise<IInviteShopUserResolverResult> {
  return postShopsByIdInvites(shopId, requestData);
}

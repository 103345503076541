export const NPSDesktopRatingContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

export const NPSDesktopSendButtonStyles = {
  fontSize: 30,
  borderRadius: 0,
};

export const NPSDesktopContainerStyles = (isMobile: boolean) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  backgroundColor: 'transparent',

  minWidth: '50%',
  padding: isMobile ? '0' : '0 70px',
});

export const NPSDesktopInputContainerStyles = {
  display: 'flex',
  width: '100%',
  position: 'relative',
  marginBottom: '20px',
};

export const NPSDesktopTitleStyles = {
  fontWeight: '500',
  fontSize: 20,
  textAlign: 'center',
};

export const NPSSubtitleStyles = {
  fontWeight: '400',
  fontSize: 16,
  textAlign: 'center',
  color: '#474D55',
};

export const NPSDesktopDescription = {
  fontSize: 14,
  color: '#a5a7ae',
};

export const NPSDesktopSnackbarStyles = (isMobile: boolean) => ({
  outline: 'none',
  position: 'fixed',
  top: '50%',
  left: '50%',
  minHeight: '400px',
  width: '80%',
  maxWidth: '950px',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  justifyContent: 'center',
  boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.15)',
  backgroundColor: '#ffffff',
  padding: isMobile ? '20px' : '40px',
  borderRadius: 4,
});

export const NPSButtonsContainer = (isCentered: boolean) => ({
  width: '100%',
  marginTop: '60px',
  justifyContent: isCentered ? 'center' : 'flex-end',
});

export const NPSDesktopModalStyles = (isMobile: boolean) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  minHeight: '200px',
  width: isMobile ? '70%' : '50%',
  maxWidth: '450px',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
  justifyContent: 'center',
  boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.15)',
  backgroundColor: '#ffffff',
  padding: '20px',
  borderRadius: 4,
});

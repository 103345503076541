import { Typography } from '@mui/material';

import { NPSMobileTextareaStyles } from '../styles';
import { NPSDesktopTitleStyles } from '../styles/NPSDesktopStyles';

export const NPSThirdStep = ({ comment, handleChangeComment, text }: any) => {
  return (
    <>
      <Typography component="legend" sx={NPSDesktopTitleStyles}>
        {text.title}
      </Typography>

      <textarea
        value={comment}
        onChange={handleChangeComment}
        placeholder={'Комментарий'}
        style={NPSMobileTextareaStyles}
      />
    </>
  );
};

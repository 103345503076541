import { DateCategory, PaymentSystem } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../instances';

export interface IGetAnalyticsPaymentSystemsRequestParams {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
  /** Категория временного отрезка */
  date_category?: DateCategory;
}

export type IGetAnalyticsPaymentSystemsResponseDataItem = [PaymentSystem, number];
export type IGetAnalyticsPaymentSystemsResponseData = IGetAnalyticsPaymentSystemsResponseDataItem[];

export async function getAnalyticsPaymentSystems(
  params: IGetAnalyticsPaymentSystemsRequestParams,
): Promise<IGetAnalyticsPaymentSystemsResponseData> {
  const { data } = await backendHttp.get<IGetAnalyticsPaymentSystemsResponseData>('/analytics/payment-systems', {
    params,
  });
  return data;
}

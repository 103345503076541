import { Tiles } from '@mobily/stacks';
import { Typography } from '@mui/material';
import React, { ComponentType, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { DateRangePicker, ISelectItem, Select, TextField } from '@iokanx/shared/ui/universal';

import { OrganizationListDesktop } from '../organization-list-desktop';

export enum SearchOrganizationsParam {
  fio = 'fio',
  name = 'name',
  nib = 'nib',
}

const searchOrganizationsParamI18n: Record<SearchOrganizationsParam, string> = {
  [SearchOrganizationsParam.fio]: 'ФИО',
  [SearchOrganizationsParam.name]: 'Наименование',
  [SearchOrganizationsParam.nib]: 'БИН',
};

const searchOrganizationsParamItems: ISelectItem<any>[] = Object.values(SearchOrganizationsParam).map((value) => ({
  title: searchOrganizationsParamI18n[value],
  value,
}));

const ORGANIZATIONS_LIST_LIMIT = 10;

export interface IOrganizationListProps {
  form: UseFormReturn<any>;
  pageLimit: number;
  filter: JSX.Element;
}

const RESPONSIVE_ORGANIZATION_LIST: Record<Breakpoint, ComponentType<IOrganizationListProps> | any> = {
  [Breakpoint.Desktop]: OrganizationListDesktop,
  [Breakpoint.Mobile]: <Typography>Доступна только десктопная версия</Typography>,
};

export function OrganizationList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  const form = useForm<any>({
    defaultValues: {
      page: 1,
      id: '',
    },
  });

  const [searchOrganizationParam, setSearchOrganizationParam] = useState(SearchOrganizationsParam.name);

  function handleChangeSearchOrganizationParam(nextSearchOrganizationParam: SearchOrganizationsParam) {
    form.reset();
    setSearchOrganizationParam(nextSearchOrganizationParam);
  }

  const filter = (
    <Tiles columns={[1, 4]} space={2}>
      <Select
        onChange={handleChangeSearchOrganizationParam}
        value={searchOrganizationParam}
        label={'Поиск по'}
        items={searchOrganizationsParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label={searchOrganizationsParamItems[searchOrganizationParam]}
          />
        )}
        name={searchOrganizationParam}
      />
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          form.setValue('from_dt', values.startDate);
          form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
    </Tiles>
  );

  const ResponsiveOrganizationList = RESPONSIVE_ORGANIZATION_LIST[currentBreakpoint];

  return <ResponsiveOrganizationList filter={filter} form={form} pageLimit={ORGANIZATIONS_LIST_LIMIT} />;
}

import * as amplitude from '@amplitude/analytics-browser';
import type {
  initializeAsync as IInitializeAsync,
  logEventWithPropertiesAsync as ILogEventWithPropertiesAsync,
} from 'expo-analytics-amplitude';

export const initializeAsync: typeof IInitializeAsync = async (apiKey) => {
  amplitude.init(apiKey);
};

export const logEventWithPropertiesAsync: typeof ILogEventWithPropertiesAsync = async (eventName, properties) => {
  amplitude.logEvent(eventName, properties);
};

export const Amplitude = { initializeAsync, logEventWithPropertiesAsync };

import { createTheme, ThemeProvider } from '@mui/material';
import { Platform } from 'react-native';
import { Provider } from 'react-native-paper';
import type {} from '@mui/lab/themeAugmentation';

import { IPaperThemeProviderProps } from './utils';

import { useCombinedTheme } from '../use-combined-theme/use-combined-theme';

export function PaperThemeProvider(props: IPaperThemeProviderProps) {
  const combinedTheme = useCombinedTheme();
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: combinedTheme.colors.primary,
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: combinedTheme.colors.background,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        styleOverrides: {
          outlined: {
            backgroundColor: combinedTheme.colors.background,
          },
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          variant: 'contained',
        },
      },
    },
  });

  return (
    <Provider theme={combinedTheme}>
      <ThemeProvider theme={muiTheme}>
        {Platform.OS === 'web' ? (
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        ) : null}
        {props.children}
      </ThemeProvider>
    </Provider>
  );
}

import { backendHttp } from '../../../../instances';
import { IGetPayoutByIdResponseData } from '../index';

export interface IPostPayoutsByIdAcceptRequestData {
  amount?: number;
}

export type IPostPayoutsByIdAcceptResponseData = IGetPayoutByIdResponseData;

/** Отмена авторизованного платежа заказа */
export async function postPayoutsByIdAccept(
  payoutId: string,
  requestData?: IPostPayoutsByIdAcceptRequestData,
): Promise<IPostPayoutsByIdAcceptResponseData> {
  const { data } = await backendHttp.post<IPostPayoutsByIdAcceptResponseData>(
    `/reports/payouts/${payoutId}/accept`,
    requestData,
  );
  return data;
}

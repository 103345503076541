import { CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';
import { compactObject, prepareDateTime } from '@iokanx/shared/util';

import {
  getDashboardPayments,
  IGetDashboardPaymentsRequestParams,
  IGetDashboardPaymentsResponseDataItem,
} from '../../resources';

export interface IFindDashboardPaymentsResolverParams extends IGetDashboardPaymentsRequestParams {
  /** Маска карты */
  maskedPan?: string;
}

export interface IFindDashboardPaymentsResolverResultItem extends IGetDashboardPaymentsResponseDataItem {
  /** Сумма с символом валюты */
  amountWithSymbol: string;
  /** Сумма комиссии с символом валюты */
  processingFeeWithSymbol: string;
  /** Дата создания в формате HH:mm, DD.MM.YYYY */
  createdAt: string;
  /** Номер заказа */
  externalId: string;
  shopId: string;
}
export interface IFindDashboardPaymentsResolverResult {
  count: number;
  data: IFindDashboardPaymentsResolverResultItem[];
}

export async function findDashboardPaymentsResolver({
  maskedPan,
  ...params
}: IFindDashboardPaymentsResolverParams): Promise<IFindDashboardPaymentsResolverResult> {
  const dashboardPayments = await getDashboardPayments(compactObject({ ...params, ...transformMaskedPan(maskedPan) }));

  const data = dashboardPayments.data.map(transformDashboardPayment);

  return { ...dashboardPayments, data };
}

function transformMaskedPan(maskedPan?: string): Pick<IGetDashboardPaymentsRequestParams, 'pan_first6' | 'pan_last4'> {
  if (!maskedPan) {
    return {};
  }

  const unmaskedPan = maskedPan.replace(/\W/g, '');

  const panFirst6 = unmaskedPan.substring(0, 6);
  const panLast4 = unmaskedPan.substring(6);

  return { pan_first6: panFirst6.length === 6 ? panFirst6 : '', pan_last4: panLast4.length === 4 ? panLast4 : '' };
}

function transformDashboardPayment(dashboardPayment: IGetDashboardPaymentsResponseDataItem) {
  const amount =
    (dashboardPayment.captured_amount > 0
      ? dashboardPayment.captured_amount
      : dashboardPayment.refunded_amount > 0
      ? 0
      : dashboardPayment.approved_amount > 0
      ? dashboardPayment.approved_amount
      : dashboardPayment.amount) / 100;
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL[dashboardPayment.currency]}`;

  const processingFee = dashboardPayment.processing_fee / 100;
  const processingFeeWithSymbol = `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL[dashboardPayment.currency]}`;
  const createdAt = prepareDateTime(dashboardPayment.created_at);
  const externalId = dashboardPayment.external_id || '-';
  const shopId = dashboardPayment.shop_id;

  return {
    ...dashboardPayment,
    amountWithSymbol,
    processingFeeWithSymbol,
    createdAt,
    externalId,
    shopId,
  };
}

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { TextField } from '@mui/material';
import moment from 'moment';
import { useEffect, useId, useState } from 'react';
import DatePicker from 'react-datepicker';
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar';

import { useSessionStorageDate } from '@iokanx/shared/util';

import { ITextFieldProps } from '../text-field/text-field';

export interface IDateRangePickerValues {
  startDate: CalendarDate;
  endDate: CalendarDate;
}

export interface IDateRangePickerProps {
  inputProps?: ITextFieldProps;
  values: IDateRangePickerValues;
  onChangeValues: (values: IDateRangePickerValues) => void;
  maxDate?: Date;
}

export function DateRangePicker(props: IDateRangePickerProps) {
  const portalId = useId();
  const { setDate } = useSessionStorageDate();

  const [_startDate, set_startDate] = useState(props.values.startDate);
  const [_endDate, set_endDate] = useState(props.values.endDate);

  function handleChange(values: [Date | null, Date | null]) {
    const startDate = values[0] ? moment(values[0]).startOf('day').toDate() : undefined;
    const endDate = values[1] ? moment(values[1]).endOf('day').toDate() : undefined;

    set_startDate(startDate);
    set_endDate(endDate);

    if (endDate !== undefined) {
      props.onChangeValues({ startDate, endDate });
    }
  }

  useEffect(() => {
    if (_startDate && _endDate) {
      setDate({
        startDate: _startDate,
        endDate: _endDate,
      });
    }
  }, [_startDate, _endDate, setDate]);

  useEffect(() => {
    const { startDate, endDate } = props.values;

    if (startDate && endDate) {
      set_startDate(startDate);
      set_endDate(endDate);
    }
  }, [props]);

  return (
    <DatePicker
      onChange={handleChange}
      startDate={_startDate}
      endDate={_endDate}
      selectsRange
      maxDate={props.maxDate || new Date()}
      customInput={
        <TextField
          id={portalId}
          InputProps={{ readOnly: true, endAdornment: <CalendarMonthIcon /> }}
          fullWidth
          {...props.inputProps}
        />
      }
      portalId={portalId}
      dateFormat={'dd.MM.yyyy'}
    />
  );
}

import { StyleSheet } from 'react-native';

import { IFindReviseResolverResultItem } from '@iokanx/shared/data-access/api';
import { Card, SimpleCard, SimpleCardProps } from '@iokanx/shared/ui/universal';

import { ClipboardButton } from '../clipboard-button/clipboard-button';

export interface IReviseCardProps {
  revise: IFindReviseResolverResultItem;
  onCardPress: SimpleCardProps['onPress'];
}

interface IContentItem {
  title: string;
  key: keyof Pick<IFindReviseResolverResultItem, 'reference'>;
}

const contentItems: IContentItem[] = [
  {
    title: 'Референс',
    key: 'reference',
  },
];

export function ReviseCard(props: IReviseCardProps) {
  return (
    <SimpleCard title={props.revise.id} onPress={props.onCardPress}>
      <Card.Actions>
        <ClipboardButton copiedText={''}>{props.revise.id}</ClipboardButton>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  contentItem: {
    justifyContent: 'space-between',
  },
});

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { TextField } from '@mui/material';
import { useId } from 'react';
import ReactDatePicker from 'react-datepicker';

import { ITextFieldProps } from '../text-field/text-field';

export interface IDatePickerProps extends Omit<ITextFieldProps, 'value' | 'onChangeText'> {
  value?: Date;
  minDate?: Date;
  onChange: (value?: Date) => void;
}

export function DatePicker(props: IDatePickerProps) {
  const portalId = useId();

  function handleChange(value: Date | null) {
    props.onChange(value ?? undefined);
  }

  return (
    <ReactDatePicker
      minDate={props.minDate}
      disabled={props.disabled}
      selected={props.value}
      onChange={handleChange}
      dateFormat={'dd.MM.yyyy'}
      customInput={
        <TextField
          label={props.label}
          InputProps={{ readOnly: true, endAdornment: <CalendarMonthIcon /> }}
          fullWidth
          id={portalId}
        />
      }
      portalId={portalId}
    />
  );
}

// @ts-ignore
import { JSONEditor } from '@json-editor/json-editor';
import { Box, Stack } from '@mobily/stacks';
import { Collapse } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, isNil, isObject } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Platform, ScrollView, StyleSheet } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import {
  CancelPayment,
  CapturePayment,
  DashboardStackParamList,
  HistoryList,
  RefundPayment,
  saveFile,
  useMe,
} from '@iokanx/dashboard/feature';
import { ClipboardButton } from '@iokanx/dashboard/ui';
import {
  findOrderByIdResolver,
  findShopFeaturesResolver,
  getOrderFiscalReceiptResolver,
  getOrderHistoriesResolver,
  getOrderReceiptResolver,
  getRefundReceiptResolver,
  IFindRefundByIdResult,
  updateAdminOrderExtraInfoById,
} from '@iokanx/shared/data-access/api';
import {
  CAPTURE_METHOD_I18N,
  Currency,
  CURRENCY_SYMBOL,
  OrderStatus,
  PaymentStatus,
  SELECTED_SHOP_ROLE,
  ShopFeature,
  ShopUserRole,
  SplitRuleOperationType,
} from '@iokanx/shared/data-access/constants';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Button, Divider, Icon, List } from '@iokanx/shared/ui/universal';

const hardCodedKeys: Record<string, string> = {
  payer_iin: 'ИИН плательщика',
  payer_name: 'ФИО плательщика',
  buyer_name: 'ФИО покупателя',
  buyer_iin: 'ИИН покупателя',
  contract_number: 'Номер договора',
  car_model: 'Бренд автомобиля',
  car_category: 'Категрория автомобиля',
  comment: 'Примечание',
  order_id: 'ID заказа',
  order_number: 'Номер заказа',
  consumer_name: 'Клиент',
  seller: 'Менеджер',
};

const getSchema = (order: any): object => {
  if (!order) return {};

  const obj = {
    type: 'object',
    title: ' ',
    properties: {},
  };

  Object.keys(order.extraInfo).map((key) => {
    const value = order.extraInfo[key];

    // @ts-ignore
    obj['properties'][key] = {
      type: 'string',
      title: get(hardCodedKeys, key, key),
      default: value,
    };
  });
  return obj;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  classes?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className={props.classes}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const styles = StyleSheet.create({
  tab: {
    fontSize: 12,
    fontWeight: 'normal',
  },
});

export type IOrderPageProps = NativeStackScreenProps<DashboardStackParamList, 'Order'>;

function Order(props: IOrderPageProps) {
  const ref = useRef(null);
  const snackbar = useSnackbar();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [editor, setEditor] = useState<JSONEditor | null>(null);
  const { me } = useMe();

  const { data: selectedShopRole } = useQuery([SELECTED_SHOP_ROLE], () => AsyncStorage.getItem(SELECTED_SHOP_ROLE));

  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTabIndex = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  const { data: order } = useQuery(['order', props.route.params], () => findOrderByIdResolver(props.route.params));
  const { data: histories } = useQuery(['histories', props.route.params], () =>
    getOrderHistoriesResolver(props.route.params),
  );

  const getOrderReceiptMutation = useMutation(() => getOrderReceiptResolver({ orderId: props.route.params.orderId }), {
    onSuccess: (data) => {
      return saveFile({ data, fileName: `receipt_${props.route.params.orderId}.pdf` });
    },
  });

  const getOrderFiscalReceiptMutation = useMutation(
    () => getOrderFiscalReceiptResolver({ orderId: props.route.params.orderId }),
    {
      onSuccess: (data) => {
        return saveFile({ data, fileName: `fiscal_receipt_${props.route.params.orderId}.pdf` });
      },
    },
  );

  const getRefundReceiptMutation = useMutation(
    (variables: { refundId: string }) => getRefundReceiptResolver({ refundId: variables.refundId }),
    {
      onSuccess: (data) => {
        return saveFile({ data, fileName: `refund_receipt.pdf` });
      },
    },
  );

  const updateAdminOrderExtraInfoByIdMutation = useMutation(
    (variables: { extraInfo: object }) =>
      updateAdminOrderExtraInfoById({
        id: props.route.params.orderId,
        extraInfo: variables.extraInfo,
      }),
    {
      onSuccess: () => {
        snackbar.show('Успешно обновлено', { type: 'success' });
      },
      onError: () => {
        snackbar.show('Ошибка обновления', { type: ' warning' });
      },
    },
  );

  const shopFeaturesQuery = useQuery(
    ['shop-features', order?.shop_id],
    () =>
      order &&
      findShopFeaturesResolver({
        shopId: order.shop_id,
      }),
  );

  const editorOptions = {
    schema: order ? getSchema(order) : null,
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
    compact: true,
    disable_array_delete_all_rows: true,
    disable_array_delete_last_row: true,
    disable_array_reorder: true,
    startval: order ? order.extraInfo : null,
  };
  useEffect(() => {
    if (editor) editor.destroy();
    if (ref.current) {
      setEditor(new JSONEditor(ref.current, editorOptions));
    }
  }, [props]);

  if (!order) return null;

  const showGetReceiptButton: boolean =
    Platform.OS === 'web' && [OrderStatus.Paid, OrderStatus.OnHold].includes(order.status);

  const showCopyCheckoutURLButton: boolean = Platform.OS === 'web' && [OrderStatus.Unpaid].includes(order.status);

  const hasPayment: boolean = order.payments.length > 0;
  const hasApprovedSplit: boolean = !!order.split && order.split.status === 'APPROVED';
  const hasApprovedPayment: boolean = hasPayment && order.payments[0].status === PaymentStatus.Approved;
  const hasCapturedPayment: boolean = hasPayment && order.payments[0].status === PaymentStatus.Captured;
  const hasRefunds: boolean = hasPayment && order.payments[0].approved_refunds.length > 0;
  const canRefund: boolean = hasCapturedPayment && order.payments[0].capturedAmount > 0;

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  const isValidRowValue = (value: unknown): boolean => !isNil(value) && !isObject(value) && Boolean(value);
  return (
    <ScrollView>
      <Tabs
        value={tabIndex}
        onChange={handleChangeTabIndex}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Заказ" style={styles.tab} />
        {me?.is_superuser && <Tab label="История" style={styles.tab} />}
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <List.Section title={''}>
          <Grid container>
            {showCopyCheckoutURLButton && (
              <Grid item xs={12}>
                <Item>
                  {
                    <ClipboardButton copiedText={order.checkout_url} popupMessage={'Ссылка для оплаты скопирована'}>
                      {'Ссылка для оплаты'}
                    </ClipboardButton>
                  }
                </Item>
              </Grid>
            )}
            <Grid item xs={6}>
              <Item className="infoTitle">Идентификатор</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{order.id}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Дата создания:</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{order.createdAt}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Номер заказа</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{order.externalId}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Описание</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{order.description}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Тип транзакции</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{CAPTURE_METHOD_I18N[order.capture_method]}</Item>
            </Grid>
            {order.due_date && (
              <Grid container>
                <Grid item xs={6}>
                  <Item className="infoTitle">Срок действия</Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>{order.dueDate}</Item>
                </Grid>
              </Grid>
            )}
            {order.extraInfo && (
              <Grid container>
                <Grid item xs={6}>
                  <Item className="infoTitle">Дополнительная информация</Item>
                </Grid>
                <Grid item xs={6} justifyContent={'center'}>
                  <Item>
                    <Icon
                      name={isCollapsed ? 'chevron-up' : 'chevron-down'}
                      onPress={() => setIsCollapsed((isCollapsed) => !isCollapsed)}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={isCollapsed}>
                    {me && me.is_superuser && (
                      <>
                        <div ref={ref} id="editor" className={'editor editorExtraInfo'} />
                        {Object.keys(order.extraInfo).length != 0 && (
                          <Item>
                            <Grid container>
                              <Grid item xs={12}>
                                <Button
                                  icon={{ name: 'receipt' }}
                                  onPress={() => {
                                    const data = editor?.getValue();
                                    if (editor.validate().length === 0) {
                                      updateAdminOrderExtraInfoByIdMutation.mutate({ extraInfo: data });
                                    }
                                  }}
                                  fullWidth={true}
                                >
                                  Сохранить
                                </Button>
                              </Grid>
                            </Grid>
                          </Item>
                        )}
                      </>
                    )}

                    {me && !me.is_superuser && (
                      <Item>
                        {Object.keys(order.extraInfo).map((key) => {
                          const value = order.extraInfo[key];

                          return (
                            isValidRowValue(value) && (
                              <Grid container key={key}>
                                <Grid item xs={6}>
                                  <Item className="infoTitle">{hardCodedKeys[key] || key}</Item>
                                </Grid>
                                <Grid item xs={6}>
                                  <Item>{value}</Item>
                                </Grid>
                              </Grid>
                            )
                          );
                        })}
                      </Item>
                    )}
                  </Collapse>
                </Grid>
              </Grid>
            )}
            {showGetReceiptButton && (
              <Grid container>
                {shopFeaturesQuery.data?.features.includes(ShopFeature.Webkassa) ? (
                  <Grid item xs={12}>
                    <Item>
                      <Button
                        icon={{ name: 'receipt' }}
                        onPress={getOrderFiscalReceiptMutation.mutate}
                        loading={getOrderFiscalReceiptMutation.isLoading}
                        fullWidth={true}
                      >
                        Скачать фискальный чек
                      </Button>
                    </Item>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Item>
                      <Button
                        icon={{ name: 'receipt' }}
                        onPress={getOrderReceiptMutation.mutate}
                        loading={getOrderReceiptMutation.isLoading}
                        fullWidth={true}
                      >
                        Скачать квитанцию об оплате
                      </Button>
                    </Item>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </List.Section>

        {hasPayment && (
          <List.Section title={'Платеж'}>
            <Grid container>
              <Grid item xs={6}>
                <Item className="infoTitle">Дата оплаты</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].createdAt}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Авторизовано</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].approvedAmountWithSymbol}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Списано</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].capturedAmountWithSymbol}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Возвращено</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].refundedAmountWithSymbol}</Item>
              </Grid>
              {![ShopUserRole.Manager].includes(selectedShopRole as ShopUserRole) && (
                <>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Комиссия</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{order.payments[0].processingFeeWithSymbol}</Item>
                  </Grid>
                </>
              )}
            </Grid>
            {order.payments[0].error && (
              <Grid container>
                <Grid item xs={6}>
                  <Item className="infoTitle">Подробнее о статусе</Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>{order.payments[0].error.message}</Item>
                </Grid>
              </Grid>
            )}
            <Grid container>
              {hasApprovedPayment &&
                selectedShopRole &&
                ![ShopUserRole.Viewer, ShopUserRole.Manager].includes(selectedShopRole as ShopUserRole) && (
                  <Stack padding={2} space={2}>
                    <CapturePayment payment={order.payments[0]} />
                    <CancelPayment payment={order.payments[0]} />
                  </Stack>
                )}
              {canRefund && ![ShopUserRole.Viewer, ShopUserRole.Manager].includes(selectedShopRole as ShopUserRole) && (
                <Stack padding={2} space={2}>
                  <RefundPayment payment={order.payments[0]} />
                </Stack>
              )}
            </Grid>
          </List.Section>
        )}

        {hasRefunds && (
          <List.Section title={'Возвраты'}>
            <Grid container>
              {order.payments[0].approved_refunds.map((refund: IFindRefundByIdResult, index) => {
                return (
                  <Grid container sx={{ mb: 2 }} key={index}>
                    <Grid item xs={6}>
                      <Item className="infoTitle">Идентификатор</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>{refund.id}</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item className="infoTitle">Дата возврата</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>{refund.createdAt}</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item className="infoTitle">Сумма</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>{refund.amountWithSymbol}</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item className="infoTitle">Комментарий</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>{refund.reason}</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item className="infoTitle">Инициатор</Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>{refund.author}</Item>
                    </Grid>
                    <Grid item xs={12}>
                      <Item>
                        <Button
                          icon={{ name: 'receipt' }}
                          onPress={() => getRefundReceiptMutation.mutate({ refundId: refund.id })}
                          fullWidth={true}
                        >
                          Скачать квитанцию о возврате
                        </Button>
                      </Item>
                      <Divider />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </List.Section>
        )}

        {hasPayment && (
          <List.Section title={'Плательщик'}>
            <Grid container>
              {me && me.is_superuser && order?.customer_id && (
                <>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Идентификатор</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{order?.customer_id}</Item>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Item className="infoTitle">Способ оплаты</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].paymentMethod || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Номер карты</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].payer?.pan_masked || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Эмитент</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].payer?.emitter || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Платежная система</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].payer?.payment_system || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Номер телефона</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].payer?.phone || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Email</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].payer?.email || '-'}</Item>
              </Grid>
            </Grid>
          </List.Section>
        )}

        {hasPayment && (
          <List.Section title={'Эквайер'}>
            <Grid container>
              <Grid item xs={6}>
                <Item className="infoTitle">Имя</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].acquirer?.name || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Терминал</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].acquirer?.terminal || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">RRN</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].acquirer?.reference || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Order ID</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].acquirer?.order_id || '-'}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item className="infoTitle">Invoice ID</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>{order.payments[0].acquirer?.invoice_id || '-'}</Item>
              </Grid>
            </Grid>
          </List.Section>
        )}

        {hasApprovedSplit && (
          <Box>
            <List.Section title={'Расщепление'}>
              {order?.split?.rules?.map((rule, index) => (
                <Grid container key={index}>
                  <Grid item xs={12}>
                    <Item>
                      {`${SplitRuleOperationType[rule.operation_type]} счета ${rule.account_id}
                            на сумму ${((rule.amount - rule.processing_fee) / 100.0)?.toLocaleString()} ${
                        CURRENCY_SYMBOL[Currency.KZT]
                      }.
                            Комиссия - ${(rule.processing_fee / 100.0)?.toLocaleString()} ${
                        CURRENCY_SYMBOL[Currency.KZT]
                      }`}
                    </Item>
                  </Grid>
                </Grid>
              ))}
            </List.Section>
          </Box>
        )}
      </TabPanel>

      {me?.is_superuser && (
        <TabPanel value={tabIndex} index={1}>
          <Grid item>
            {histories &&
              histories.map((item, idx) => {
                return <HistoryList key={item.id} payment={item} is_superadmin={me?.is_superuser} isOpen={!idx} />;
              })}
          </Grid>
        </TabPanel>
      )}
    </ScrollView>
  );
}

export default wrapPage(
  wrapModalPage(Order, {
    position: 'Right',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'Order'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Operations', params: { screen: 'Payments' } });
        navigation.push('Order', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

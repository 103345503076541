export enum PaymentSystem {
  Visa = 'VISA',
  Mastercard = 'MASTERCARD',
  AmericanExpress = 'AMERICAN_EXPRESS',
  Mir = 'MIR',
  Maestro = 'MAESTRO',
  UnionPay = 'UNIONPAY',
  NotDefined = 'not defined',
}

export const PAYMENT_SYSTEM_COLOR: Record<PaymentSystem, string> = {
  [PaymentSystem.Visa]: 'rgb(29,50,191)',
  [PaymentSystem.Mastercard]: 'rgb(234,163,63)',
  [PaymentSystem.AmericanExpress]: 'rgb(47,109,202)',
  [PaymentSystem.Mir]: '#F8BC3B',
  [PaymentSystem.Maestro]: 'rgb(216,46,43)',
  [PaymentSystem.NotDefined]: 'grey',
  [PaymentSystem.UnionPay]: 'rgb(255,0,0)',
};

export const AVAILABLE_PAYMENT_SYSTEMS = [
  PaymentSystem.Visa,
  PaymentSystem.Mastercard,
  PaymentSystem.AmericanExpress,
  PaymentSystem.UnionPay,
];

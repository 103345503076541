import { CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';
import { prepareDateTime } from '@iokanx/shared/util';

import { IGetOrderByIdResponseData } from '../../resources';

export interface ISearchOrderItem extends IGetOrderByIdResponseData {
  // Дата создания в формате HH:mm, DD.MM.YYYY
  createdAt: string;
  // Сумма с символом валюты
  amountWithSymbol: string;
  // Номер заказа
  externalId: string | null;
  // Срок действия заказа в формате HH:mm, DD.MM.YYYY
  dueDate: string;
  description?: string;
  extraInfoStringify: string;
  extraInfo: any;
}

export function transformSearchOrder(searchOrder: IGetOrderByIdResponseData): ISearchOrderItem {
  const amount = searchOrder.amount / 100;

  return {
    ...searchOrder,
    amount,
    amountWithSymbol: `${amount.toLocaleString()} ${CURRENCY_SYMBOL[searchOrder.currency]}`,
    createdAt: prepareDateTime(searchOrder.created_at),
    externalId: searchOrder?.external_id || null,
    dueDate: prepareDateTime(searchOrder.due_date),
    description: searchOrder.description || '',
    extraInfo: searchOrder.extra_info || {},
    extraInfoStringify: searchOrder.extra_info ? JSON.stringify(searchOrder.extra_info, null, 2) : '-',
  };
}

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { DashboardOperationsTabsParamList } from '@iokanx/dashboard/feature';

import { PaymentsPage } from '../payments-page';

const MaterialTopTabNavigator = createMaterialTopTabNavigator<DashboardOperationsTabsParamList>();

export const OPERATIONS_PAGES = ['Payments', 'Refunds', 'PaymentLinks'];

export default function OperationsPage() {
  return (
    <MaterialTopTabNavigator.Navigator
      screenOptions={{
        lazy: true,
        swipeEnabled: false,
      }}
    >
      <MaterialTopTabNavigator.Screen name="Payments" component={PaymentsPage} options={{ title: 'Платежи' }} />
      {/*<MaterialTopTabNavigator.Screen*/}
      {/*  name="PaymentLinks"*/}
      {/*  component={PaymentLinksPage}*/}
      {/*  options={{ title: 'Ссылки на оплату' }}*/}
      {/*/>*/}
    </MaterialTopTabNavigator.Navigator>
  );
}

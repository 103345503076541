import { backendHttp } from '../../../instances';

export type IGetFulfilmentRequestParams = {
  shop_id?: string;
  date?: string;
};

export interface IGetFulfilmentByIdResponseData {
  date: string;
  shop_id: string;
  account: string;
  bin: string;
  iik: string;
  amount: number;
  processing_fee: number;
  payout: number;
  report_url: string;
}

/** Получение  вызгрузки по ID */
export async function getFulfilmentById(params: IGetFulfilmentRequestParams): Promise<IGetFulfilmentByIdResponseData> {
  const { data } = await backendHttp.get<IGetFulfilmentByIdResponseData>(`/reports/fulfilments`, { params });
  return data;
}

import { backendHttp } from '../../../../instances';

export interface IGetConfigByShopsIdResponseData {
  id: string;
  created_at: string;
  shop_id: string;
  shop_name: string;
  adapters?: [];
  acquirers?: [];
  providers?: [];
  apple_pay?: any;
  google_pay?: any;
  card_payment?: any;
}

/** Приглашение пользователя */
export async function getConfigByShopId(shopId: string): Promise<IGetConfigByShopsIdResponseData> {
  const { data } = await backendHttp.get(`/configs/${shopId}`);
  return data;
}

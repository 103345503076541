import { backendHttp } from '@iokanx/shared/data-access/api';

import { parseData } from './helpers';

export const postData = async (data: any, organizationId: string, parentId: string | null = null) => {
  const { data: response } = await backendHttp.post(`organizations/${organizationId}/shares`, {
    parent_id: parentId,
    share: Number(data.share),
    personal_data: parseData(data, data.type),
  });

  return response;
};

export const patchData = async (data: any, organizationId: string, id: string, parentId: string | null = null) => {
  const { data: response } = await backendHttp.patch(`organizations/${organizationId}/shares/${id}`, {
    parent_id: parentId,
    share: Number(data.share),
    personal_data: parseData(data, data.type),
  });

  return response;
};

export const deleteData = async (organizationId: string, beneficiaryId: string) => {
  return await backendHttp.delete(`organizations/${organizationId}/shares/${beneficiaryId}`);
};

import moment from 'moment/moment';

import { AcquirerType } from '@iokanx/shared/data-access/constants';

import {
  getAnalyticsAcquirers,
  IGetAnalyticsAcquirersRequestParams,
  IGetAnalyticsAcquirersResponseData,
} from '../../resources';

export interface IFindAcquirersAnalyticsResolverResultItem {
  acquirer: AcquirerType;
  count: number;
  title: string;
  color: string;
}

export interface IGetAcquirersAnalyticsResolverResult {
  /** Общее кол-во платежей */
  totalPaymentsCount: number;
  /** Данные статусов платежей */
  data: IFindAcquirersAnalyticsResolverResultItem[];
}

const ACQUIRER_I18N: Record<AcquirerType, string> = {
  [AcquirerType.SBERBANK]: 'Сбербанк',
  [AcquirerType.EPAY_V2]: 'Epay 2.0',
  [AcquirerType.EPAY]: 'Epay',
  [AcquirerType.JUSAN]: 'Jusan Bank',
  [AcquirerType.RBS]: 'Freedom',
  [AcquirerType.PAYBOX]: 'Paybox',
  [AcquirerType.BCC]: 'BCC',
  [AcquirerType.EUBANK]: 'Eubank',
};

const ACQUIRER_COLORS: Record<AcquirerType, string> = {
  [AcquirerType.SBERBANK]: 'rgb(240,190,65)',
  [AcquirerType.EPAY_V2]: 'rgb(0,128,95)',
  [AcquirerType.EPAY]: 'rgb(0,128,90)',
  [AcquirerType.JUSAN]: 'rgb(255,115,72)',
  [AcquirerType.RBS]: 'rgb(45,125,47)',
  [AcquirerType.PAYBOX]: 'rgb(77,189,65)',
  [AcquirerType.BCC]: 'rgb(218,171,107)',
  [AcquirerType.EUBANK]: 'rgb(255, 0, 109)',
};

export async function findAcquirersAnalytics(
  _params: IGetAnalyticsAcquirersRequestParams,
): Promise<IGetAcquirersAnalyticsResolverResult> {
  const params = { ..._params };

  if (params.from_dt && !params.to_dt) {
    params.to_dt = moment(params.from_dt).endOf('day').toDate();
  }

  const data = await getAnalyticsAcquirers(params);

  return prepareAcquirersAnalytics(data);
}

function prepareAcquirersAnalytics(
  analytics: IGetAnalyticsAcquirersResponseData,
): IGetAcquirersAnalyticsResolverResult {
  const totalPaymentsCount = analytics.reduce((acc, item) => acc + item.count, 0);

  const data = analytics.map((item) => ({
    acquirer: item.acquirer,
    count: item.count,
    color: ACQUIRER_COLORS[item.acquirer],
    title: ACQUIRER_I18N[item.acquirer],
  }));

  data.sort((a, b) => b.count - a.count);

  return { totalPaymentsCount, data };
}

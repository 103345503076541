import { useTranslation } from 'next-i18next';

interface IPaymentStatusHeaderProps {
  type: 'success' | 'declined';
  title?: string;
  iconSrc?: string;
}

const titles = {
  success: 'Заказ оплачен',
  declined: 'Оплата отклонена',
};

const icons = {
  success: '/images/green-check.svg',
  declined: '/images/error-circle.svg',
};

export const PaymentStatusHeaderAmway = (props: IPaymentStatusHeaderProps) => {
  const { t } = useTranslation();

  return (
    <h1
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
      className="ps-header"
      id={`${props.type}-header`}
    >
      <img src={props.iconSrc ?? icons[props.type]} className="ps-header__icon" alt={props.type} />
      {props.title ?? t(titles[props.type])}
    </h1>
  );
};

import {
  IPostActionLinkResetForgotPasswordRequestData,
  IPostActionLinkResetForgotPasswordRequestParams,
  postActionLinkResetForgotPassword,
} from '../../resources';

export interface IResetPasswordResolverParams
  extends IPostActionLinkResetForgotPasswordRequestData,
    IPostActionLinkResetForgotPasswordRequestParams {}

export function resetPasswordResolver({ token, ...requestData }: IResetPasswordResolverParams) {
  return postActionLinkResetForgotPassword(requestData, { token });
}

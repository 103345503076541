import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IFindShopUsersResolverResultItem, updateShopUserRoleResolver } from '@iokanx/shared/data-access/api';
import { AVAILABLE_SHOP_USER_ROLES, SHOP_USER_ROLE_I18N, ShopUserRole } from '@iokanx/shared/data-access/constants';
import { ISelectItem, Select, Chip, Button } from '@iokanx/shared/ui/universal';

import { useMe } from '../auth';
import { useSelectedShop } from '../use-selected-shop/use-selected-shop';

export interface EmployeeUpdateRoleProps {
  employee: IFindShopUsersResolverResultItem;
}

const roleItems: ISelectItem<ShopUserRole>[] = AVAILABLE_SHOP_USER_ROLES.map((value) => ({
  title: SHOP_USER_ROLE_I18N[value],
  value: value,
}));

export function EmployeeUpdateRole(props: EmployeeUpdateRoleProps) {
  const { selectedShop } = useSelectedShop();
  const { me } = useMe();

  const isOwner = props.employee.role === ShopUserRole.Owner;
  const meOwnerOrAdmin = selectedShop && [ShopUserRole.Owner, ShopUserRole.Admin].includes(selectedShop.role);

  const canAct = !isOwner && meOwnerOrAdmin && props.employee.id !== me?.id;

  const queryClient = useQueryClient();
  const { mutate: updateShopUserRole, isLoading: isUpdatingShopUserRole } = useMutation(updateShopUserRoleResolver, {
    onSuccess: () => queryClient.invalidateQueries(['users']),
  });
  function handleUpdateShopUserRole(newRole: ShopUserRole) {
    if (selectedShop) {
      updateShopUserRole({ shopId: selectedShop.id, userId: props.employee.id, role_code: newRole });
    }
  }

  if (isOwner) {
    return <Chip>{SHOP_USER_ROLE_I18N[props.employee.role]}</Chip>;
  }

  return (
    <Select
      anchor={(anchorProps) => (
        <Button
          mode={'outlined'}
          disabled={!canAct}
          icon={{ name: 'chevron-down' }}
          onPress={anchorProps.handleOpenMenu}
          loading={isUpdatingShopUserRole}
        >
          {SHOP_USER_ROLE_I18N[props.employee.role]}
        </Button>
      )}
      value={props.employee.role}
      items={roleItems}
      onChange={handleUpdateShopUserRole}
      disabled={isUpdatingShopUserRole}
    />
  );
}

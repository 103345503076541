import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ComponentProps } from 'react';
import { useTheme } from 'react-native-paper';

export type IIconProps = ComponentProps<typeof MaterialCommunityIcons>;

export function Icon(props: IIconProps) {
  const theme = useTheme();

  return <MaterialCommunityIcons color={theme.colors.primary} size={24} {...props} />;
}

import { PAYMENT_STATUS_TITLE, PaymentStatus } from '@iokanx/shared/data-access/constants';

import { StatusChip, StatusChipPropsIconName, StatusChipVariant } from '../status-chip/status-chip';

export interface IPaymentStatusChipProps {
  paymentStatus: PaymentStatus;
}

const paymentStatusVariant: Record<PaymentStatus, StatusChipVariant> = {
  [PaymentStatus.Pending]: StatusChipVariant.Failure,
  [PaymentStatus.RequiresAction]: StatusChipVariant.Loading,
  [PaymentStatus.Approved]: StatusChipVariant.Success,
  [PaymentStatus.Captured]: StatusChipVariant.Success,
  [PaymentStatus.Cancelled]: StatusChipVariant.Failure,
  [PaymentStatus.Declined]: StatusChipVariant.Failure,
};

const statusChipIconName: Record<PaymentStatus, StatusChipPropsIconName> = {
  [PaymentStatus.Pending]: 'loading',
  [PaymentStatus.RequiresAction]: 'loading',
  [PaymentStatus.Approved]: 'check',
  [PaymentStatus.Captured]: 'check',
  [PaymentStatus.Cancelled]: 'close-circle-outline',
  [PaymentStatus.Declined]: 'close',
};

export function PaymentStatusChip(props: IPaymentStatusChipProps) {
  return (
    <StatusChip variant={paymentStatusVariant[props.paymentStatus]} iconName={statusChipIconName[props.paymentStatus]}>
      {PAYMENT_STATUS_TITLE[props.paymentStatus]}
    </StatusChip>
  );
}

import { UserStatus } from '@iokanx/shared/data-access/constants';
import { compactObject } from '@iokanx/shared/util';

import { getUsers } from '../../resources/users';

export interface IGetUsersResponseDataItem {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  patronymic: string;
  created_at: string;
  status: UserStatus;
}

export interface IGetUsersResponseData {
  count: number;
  data: IGetUsersResponseDataItem[];
}

export interface IGetUsersRequestParams {
  page?: number;
  limit?: number;
  to_dt?: Date;
  from_dt?: Date;
}

export async function getUsersResolver({ ...params }): Promise<IGetUsersResponseData> {
  return await getUsers(compactObject({ ...params }));
}

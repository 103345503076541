import moment from 'moment';

import { compactObject, prepareDateTime } from '@iokanx/shared/util';

import {
  getDashboardShops,
  IGetDashboardShopsRequestParams,
  IGetDashboardShopsResponseDataItem,
} from '../../resources';

export interface IFindDashboardShopsResolverParams extends IGetDashboardShopsRequestParams {
  name?: string;
  nib?: string;
  status?: string;
}

export interface IFindDashboardShopsResolverResultItem extends IGetDashboardShopsResponseDataItem {
  createdAt: string;
  /** Номер заказа */
  externalId: string;
  shopId: string;
}
export interface IFindDashboardShopsResolverResult {
  count: number;
  data: IFindDashboardShopsResolverResultItem[];
}

export async function findDashboardShopsResolver({
  ...params
}: IFindDashboardShopsResolverParams): Promise<IFindDashboardShopsResolverResult> {
  const dashboardShops = await getDashboardShops(compactObject({ ...params }));

  const data = dashboardShops.data.map(transformDashboardShop);

  return { ...dashboardShops, data };
}

function transformDashboardShop(dashboardShop: IGetDashboardShopsResponseDataItem) {
  const createdAt = prepareDateTime(dashboardShop.created_at);
  const externalId = dashboardShop.external_id || '-';
  const shopId = dashboardShop.id;

  return {
    ...dashboardShop,
    createdAt,
    externalId,
    shopId,
  };
}

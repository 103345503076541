import { Fade, Menu, MenuProps, styled } from '@mui/material';

export const ShopMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    TransitionComponent={Fade}
    {...props}
  />
))({
  '& .MuiPaper-root': {
    borderRadius: 12,
  },
  '& .MuiList-root': {
    padding: 0,
  },
});

import { GetNextPageParamFunction } from '@tanstack/react-query';
import { last } from 'lodash';

export interface ICreateGetNextPageParamParams {
  pageLimit: number;
}

export interface ICreateGetNextPageParamBaseData {
  count: number;
  data: unknown[];
}
export type ICreateGetNextPageParamResult = GetNextPageParamFunction<ICreateGetNextPageParamBaseData>;

export function createGetNextPageParam(params: ICreateGetNextPageParamParams): ICreateGetNextPageParamResult {
  return (lastPage, allPages) => {
    const lastAllPage = last(allPages);
    if (!lastAllPage) return;

    const allPagesLength: number = allPages.reduce(
      (previousValue, currentValue) => previousValue + currentValue.data.length,
      0,
    );

    const hasNextPage: boolean = lastAllPage.count - allPagesLength > 0;
    if (!hasNextPage) return;

    return allPagesLength / params.pageLimit + 1;
  };
}

import { Box, Tiles } from '@mobily/stacks';

import { ISearchPaymentLinksResolverResultItem } from '@iokanx/shared/data-access/api';
import { Caption, Card, Paragraph, SimpleCard } from '@iokanx/shared/ui/universal';

import { ClipboardButton } from '../clipboard-button/clipboard-button';
import { PaymentLinkAmount } from '../payment-link-amount/payment-link-amount';
import { PaymentLinkStatus } from '../payment-link-status/payment-link-status';

export interface IPaymentLinkCardProps {
  paymentLink: ISearchPaymentLinksResolverResultItem;
}

interface IContentItem {
  title: string;
  key: keyof ISearchPaymentLinksResolverResultItem;
}
const contentItems: IContentItem[] = [
  {
    key: 'description',
    title: 'Описание',
  },
  {
    key: 'createdAt',
    title: 'Дата создания',
  },
];

export function PaymentLinkCard(props: IPaymentLinkCardProps) {
  return (
    <SimpleCard
      title={props.paymentLink.slug}
      right={() => (
        <Box marginRight={4}>
          <PaymentLinkAmount paymentLink={props.paymentLink} />
        </Box>
      )}
    >
      <Card.Content>
        <Tiles columns={2} space={2}>
          <Caption numberOfLines={1}>Оплаты</Caption>
          <PaymentLinkStatus paymentLink={props.paymentLink} />
        </Tiles>
        {contentItems.map((item) => (
          <Tiles key={item.key} columns={2} space={2}>
            <Caption numberOfLines={1}>{item.title}</Caption>
            <Paragraph numberOfLines={1}>{props.paymentLink[item.key]}</Paragraph>
          </Tiles>
        ))}
      </Card.Content>
      <Card.Actions>
        <ClipboardButton>{props.paymentLink.payment_link_url}</ClipboardButton>
      </Card.Actions>
    </SimpleCard>
  );
}

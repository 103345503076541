import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authLogin, IAuthLoginParams, IAuthLoginResult } from '@iokanx/shared/data-access/api';
import { IHttpError } from '@iokanx/shared/data-access/types';

import { Amplitude } from '../../amplitude/amplitude';
import { setTokens } from '../token';
import { IS_SIGNED_IN_QUERY_KEY } from '../use-is-signed-in/use-is-signed-in';

export function useSignIn() {
  const queryClient = useQueryClient();

  return useMutation<IAuthLoginResult, AxiosError<IHttpError>, IAuthLoginParams>(authLogin, {
    onSuccess: async (data, variables) => {
      await setTokens(data);
      await queryClient.invalidateQueries([IS_SIGNED_IN_QUERY_KEY]);
      return Amplitude.logEventWithPropertiesAsync('sign_in', { login: variables.email, success: true });
    },
    onError: (error, variables) => {
      return Amplitude.logEventWithPropertiesAsync('sign_in', { login: variables.email, success: false });
    },
  });
}

export enum UserStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export const USER_STATUS_I18N: Record<UserStatus, string> = {
  [UserStatus.Pending]: 'Ожидает',
  [UserStatus.Active]: 'Активный',
  [UserStatus.Inactive]: 'Неактивный',
};

export const AVAILABLE_USER_STATUSES = [UserStatus.Pending, UserStatus.Active, UserStatus.Inactive] as const;

import { Tiles } from '@mobily/stacks';
import Button2 from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment/moment';
import { ComponentType, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import {
  exportNpsResolver,
  IFindDashboardPaymentsResolverParams,
  IFindNpsRequestResolverParams,
  postNpsSyncResolver,
} from '@iokanx/shared/data-access/api';
import { DATE_FORMAT } from '@iokanx/shared/data-access/constants';
import { Breakpoint, useSnackbar, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { Button, DateRangePicker, Icon, ISelectItem, Select, TextField } from '@iokanx/shared/ui/universal';

import { saveFile } from '../file-saver/file-saver';
import { useDashboardNavigation } from '../navigation';
import { VoteListDesktop } from '../vote-list-desktop/vote-list-desktop';
import { VoteListMobile } from '../vote-list-mobile/vote-list-mobile';

export enum NpsType {
  ALL = '',
  DETRACTORS = 'DETRACTORS',
  PASSIVES = 'PASSIVES',
  PROMOTERS = 'PROMOTERS',
}

export enum SearchVotesParam {
  email = 'email',
}

export interface IVoteListFormFieldValues extends IFindNpsRequestResolverParams {
  page: NonNullable<IFindDashboardPaymentsResolverParams['page']>;
  from_dt: IFindDashboardPaymentsResolverParams['from_dt'];
  to_dt: IFindDashboardPaymentsResolverParams['to_dt'];
}

const searchVotesParamI18n: Record<SearchVotesParam, string> = {
  [SearchVotesParam.email]: 'Почта',
};

const searchVotesParamItems: ISelectItem<SearchVotesParam>[] = Object.values(SearchVotesParam).map((value) => ({
  title: searchVotesParamI18n[value],
  value,
}));

const npsTypeI18n: Record<NpsType, string> = {
  [NpsType.ALL]: 'Все',
  [NpsType.DETRACTORS]: 'Детракторы',
  [NpsType.PASSIVES]: 'Нейтралы',
  [NpsType.PROMOTERS]: 'Промоутеры',
};

const npsTypes: ISelectItem<NpsType>[] = Object.values(NpsType).map((value) => ({
  title: npsTypeI18n[value],
  value,
}));

const VOTE_LIST_LIMIT = 10;

export interface IVoteListProps {
  onVotePress: (vote: any) => void;
  form: UseFormReturn<IVoteListFormFieldValues>;
  pageLimit: number;
  filter: JSX.Element;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const RESPONSIVE_VOTE_LIST: Record<Breakpoint, ComponentType<IVoteListProps>> = {
  [Breakpoint.Desktop]: VoteListDesktop,
  [Breakpoint.Mobile]: VoteListMobile,
};

export function VoteList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const form = useForm<IVoteListFormFieldValues>({
    defaultValues: {
      page: 1,
    },
  });

  const [searchVoteParam, setSearchVoteParam] = useState(SearchVotesParam.email);

  function handleChangeSearchVoteParam(nextSearchVoteParam: SearchVotesParam) {
    form.reset();
    setSearchVoteParam(nextSearchVoteParam);
  }

  function handleChangeNpsType(value: NpsType) {
    form.setValue('type_nps', value);
  }

  const exportNpsMutation = useMutation(() => exportNpsResolver(form.getValues()), {
    onSuccess: (data) => {
      const startDate = moment(form.watch('from_dt')).format(DATE_FORMAT);
      const endDate = moment(form.watch('to_dt')).format(DATE_FORMAT);
      return saveFile({ data, fileName: `nps_${startDate}-${endDate}.xlsx` });
    },
  });

  const { mutate: uploadFile } = useMutation(async (data: any) => postNpsSyncResolver(data), {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['votes', form.watch()]);
      snackbar.show('Файл успешно загружен и синхранизирорван со списком.', { type: 'success' });
    },
    onError: async (e) => {
      snackbar.show('Ошика загрузки файлу. Подробнее в консоли браузера ', { type: 'danger' });
      console.log(e);
    },
  });

  const navigation = useDashboardNavigation();

  function handleNavigateToVote(vote: any) {
    navigation.navigate('Vote', { voteId: vote.id });
  }

  const filter = (
    <Tiles columns={[1, 6]} space={2}>
      <Select onChange={handleChangeNpsType} value={form.watch('type_nps')} label={'Тип'} items={npsTypes} />
      <Select
        onChange={handleChangeSearchVoteParam}
        value={searchVoteParam}
        label={'Поиск по'}
        items={searchVotesParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label={searchVotesParamI18n[searchVoteParam]}
          />
        )}
        name={searchVoteParam}
      />
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          form.setValue('from_dt', values.startDate);
          form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
      <Button
        icon={{ name: 'file-excel' }}
        onPress={() => exportNpsMutation.mutate()}
        disabled={exportNpsMutation.isLoading}
      >
        Выгрузить
      </Button>
      <Tooltip
        title="Выгрузите с Google Sheet файл форматом .xlsx с результами опроса.
      Пересохраните у себя изменив какие либо данные и загрузите файл."
      >
        <Button2
          component="label"
          variant="contained"
          tabIndex={-1}
          sx={{
            borderRadius: '12px',
          }}
        >
          Загрузить GOOGLE SHEET
          <VisuallyHiddenInput
            type="file"
            onChange={(event) => {
              if (event.target.files) {
                uploadFile(event.target.files[0]);
              }
            }}
            accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
          />
        </Button2>
      </Tooltip>
    </Tiles>
  );

  const ResponsiveVoteList = RESPONSIVE_VOTE_LIST[currentBreakpoint];

  return (
    <ResponsiveVoteList filter={filter} onVotePress={handleNavigateToVote} form={form} pageLimit={VOTE_LIST_LIMIT} />
  );
}

import { useInfiniteQuery } from '@tanstack/react-query';
import { ListRenderItem } from 'react-native';

import { ShopCard } from '@iokanx/dashboard/ui';
import { IFindDashboardShopsResolverResultItem, searchNpsResolver } from '@iokanx/shared/data-access/api';
import { createGetNextPageParam } from '@iokanx/shared/util';

import { ShopFlatList } from '../shop-flat-list/shop-flat-list';
import { IVoteListProps } from '../vote-list/vote-list';

export function VoteListMobile(props: IVoteListProps) {
  const query = useInfiniteQuery(
    ['shops', props.form.watch()],
    (context) => searchNpsResolver({ ...props.form.watch(), page: context.pageParam, limit: props.pageLimit }),
    {
      getNextPageParam: createGetNextPageParam({ pageLimit: props.pageLimit }),
    },
  );

  const renderItem: ListRenderItem<IFindDashboardShopsResolverResultItem> = (info) => {
    return <ShopCard shop={info.item} onCardPress={() => props.onVotePress(info.item)} />;
  };

  return (
    <ShopFlatList
      renderItem={renderItem}
      query={query}
      hasNotNextPageText={'Опросов больше нет'}
      emptyText={'По указанным параметрам опросов не найдено'}
      filter={props.filter}
    />
  );
}

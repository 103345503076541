import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColumns, ruRU } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';

import { getOrganizationsResolver } from '@iokanx/shared/data-access/api';
import { prepareDateTime } from '@iokanx/shared/util';

export interface IFindDashboardShopsResolverResult {
  count: number;
  data: any[];
}

export async function findDashboardOrganizationsResolver(): Promise<any> {
  const organizations = await getOrganizationsResolver();
  // const dashboardOrganizations = await getOrganizationsResolver(compactObject({ ...params }));

  const data = organizations.data.map(transformDashboardShop);

  if (organizations) {
    return { ...organizations, data };
  }

  return { data };
}

function transformDashboardShop(organization: any) {
  const createdAt = prepareDateTime(organization.created_at);
  return {
    ...organization,
    createdAt,
  };
}

const columns: GridColumns = [
  {
    headerName: 'Наименование',
    field: 'legal_name',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'Наименование торговое',
    field: 'trade_name',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'БИН/ИИН',
    field: 'nib',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'ФИО руководителя',
    field: 'employer',
    renderCell: (props) => {
      if (props.value?.first_name) {
        return <Typography>{`${props.value?.first_name} ${props.value?.last_name}`}</Typography>;
      }

      return <Typography>---</Typography>;
    },
    flex: 0.3,
    sortable: false,
  },
  // {
  //   headerName: 'ФИО бен.собственника/акционера',
  //   field: 'email',
  //   flex: 0.3,
  //   sortable: false,
  // },
  {
    headerName: 'Дата ввода в систему',
    field: 'created_at',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'Уровень АМЛ риска',
    field: 'risk_level',
    renderCell: (props) => {
      return <Typography>{props.value?.level}</Typography>;
    },
    flex: 0.3,
    sortable: false,
  },
];

const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам магазинов не найдено',
};

export function OrganizationListDesktop(props: any) {
  const { data: organizations, isFetching } = useQuery(['organizations', props.form.watch()], () =>
    getOrganizationsResolver(),
  );

  return (
    <Stack padding={2} spacing={2}>
      {/*{props.filter}*/}
      <DataGrid
        rows={organizations?.data || []}
        columns={columns}
        loading={isFetching}
        page={props.form.watch('page') - 1}
        onPageChange={(page) => props.form.setValue('page', page + 1)}
        rowCount={organizations?.count}
        localeText={localeText}
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
        pageSize={props.pageLimit}
        rowsPerPageOptions={[props.pageLimit]}
        paginationMode="server"
      />
    </Stack>
  );
}

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { DashboardNpsTabsParamList } from '@iokanx/dashboard/feature';

import { VotesPage } from '../votes-page/votes-page';

const MaterialTopTabNavigator = createMaterialTopTabNavigator<DashboardNpsTabsParamList>();

export default function NpsPage() {
  return (
    <MaterialTopTabNavigator.Navigator
      screenOptions={{
        lazy: true,
        swipeEnabled: false,
      }}
    >
      <MaterialTopTabNavigator.Screen
        name="Votes"
        component={VotesPage}
        options={{
          title: 'Список опросов',
        }}
      />
    </MaterialTopTabNavigator.Navigator>
  );
}

import { checkNPS } from '../../resources';

export const checkNPSResolver = async (): Promise<boolean> => {
  try {
    const { required } = await checkNPS();

    return required;
  } catch (e) {
    console.log(e);

    return false;
  }
};

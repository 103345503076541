import { Alert, MenuItem, Select, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import classnames from 'classnames';
import getCardType from 'credit-card-type';
import { useFormik } from 'formik';
import { isPlainObject, isEmpty, pickBy } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import * as yup from 'yup';

import { IQueryParamsType, IZoidProps } from '@iokanx/payment-form/types';
import { Button, CardFooter, CVCInput } from '@iokanx/payment-form/ui';
import {
  createPaymentLinkPaymentResolver,
  ICreatePaymentLinkPaymentResolverError,
  ICreatePaymentLinkPaymentResolverResult,
  IFindPaymentLinkBySlugResolverResult,
} from '@iokanx/shared/data-access/api';
import { DEFAULT_CARD_PLACEHOLDER, PaymentSystem } from '@iokanx/shared/data-access/constants';
import CurrencyInput from '@iokanx/shared/ui/universal/amount-input/amount-input';
import { ExpireInput } from '@iokanx/shared/ui/universal/expire-input/expire-input';
import { PanInput } from '@iokanx/shared/ui/universal/pan-input/pan-input';
import { getAmountWithCurrency } from '@iokanx/shared/util';

import { PaymentLinksContractNumber } from '../payment-links-contract-number/payment-links-contract-number';
import { useCardSchema } from '../use-card-schema/use-card-schema';

export const CADILLAC_ALMATY = 'cadillac-almaty';
export const CARTIME_AUTO = 'cartime-auto';

export interface IPaymentLinksPaymentFormRangedProps {
  widgetOptions?: IZoidProps | null;
  queryParams: IQueryParamsType;
  slug: IFindPaymentLinkBySlugResolverResult;
}

export type IPaymentLinksPaymentFormRangedValues = {
  /** PAN карты плательщика. */
  pan: string;
  /** Месяц и год истечения карты в формате MM/YY. */
  exp: string;
  /** Код верификации ^\d{3,4}$ */
  cvc?: string | undefined;
  /** Имя держателя карты. */
  holder?: string;
  /**
   * ИИН покупателя.
   */
  iin?: string;
  /**
   * ФИО покупателя.
   */
  buyer_name?: string;
  /**
   * ИИН покупателя.
   */
  buyer_iin?: string;
  /**
   * Номер договора.
   */
  contract_number?: string;
  /**
   * Примечание.
   */
  comment?: string;
  /**
   * Модель авто.
   */
  car_model?: string;
  /**
   * Электронный адрес плательщика.
   * */
  email: string;
  /**
   * Сумма платежа в тиынах.
   *
   * @moreThan 100
   * */
  amount: string;
};

export const cleanObject = (obj: any): any => {
  return pickBy(obj, (value) => {
    if (isPlainObject(value)) {
      return !isEmpty(cleanObject(value));
    }
    return !isEmpty(value);
  });
};

export function PaymentLinksPaymentFormRanged(props: IPaymentLinksPaymentFormRangedProps): JSX.Element {
  const { t } = useTranslation();
  const router = useRouter();

  const [isLoading, setLoading] = useState(false);

  const { mutate: createPayment, error } = useMutation<
    ICreatePaymentLinkPaymentResolverResult,
    AxiosError<ICreatePaymentLinkPaymentResolverError>,
    IPaymentLinksPaymentFormRangedValues
  >(
    (values) => {
      const requestData = {
        pan: values.pan,
        exp: values.exp,
        cvc: values.cvc,
        holder: values.holder,
        email: values.email,
        amount: values.amount,
        extra_info: {
          payer_name: values.holder,
          payer_iin: values.iin,
          buyer_name: values.buyer_name,
          buyer_iin: values.buyer_iin,
          contract_number: values.contract_number,
          comment: values.comment,
          car_model: values.car_model,
        },
      };

      const cleanRequestData: any = cleanObject(requestData);

      return createPaymentLinkPaymentResolver({ slug: props.slug.slug, ...cleanRequestData });
    },
    {
      onSettled: (data, error) => {
        const orderId = data?.order_id || error?.response?.data.order_id;
        if (orderId) {
          router.push(`/orders/${orderId}`);
        } else {
          setLoading(false);
        }
      },
    },
  );

  const [focused, setFocused] = useState('');

  const [cardBrand, setCardBrand] = useState('');
  const [cvcSize, setCvcSize] = useState(3);

  const { panSchema, expSchema, cvcSchema } = useCardSchema();
  const [carModels, setCarModels] = useState<Record<string, string>>({});

  const [samePerson, setSamePerson] = useState<boolean>(false);

  useEffect(() => {
    const slug = props.slug.slug;

    async function fetchData() {
      const response = await fetch('https://cdn.ioka.kz/carCodes.json');
      const data = await response.json();

      setCarModels(data[slug]);
    }

    fetchData();
  }, [props]);

  const includesSubstring = (str: string, substrings: string[]) => {
    return substrings.some((substring) => str.includes(substring));
  };

  const validationSchema: yup.SchemaOf<IPaymentLinksPaymentFormRangedValues> = yup.object({
    pan: panSchema,
    exp: expSchema,
    cvc: cvcSchema,
    holder: !props.slug.slug.includes(CADILLAC_ALMATY) ? yup.string().required(t('Обязательное поле')) : yup.string(),
    iin: !props.slug.slug.includes(CADILLAC_ALMATY)
      ? yup.string().length(12, t('Неверный формат ИИН')).required(t('Обязательное поле'))
      : yup.string(),
    buyer_name: props.slug.slug.includes('auto') ? yup.string().required(t('Обязательное поле')) : yup.string(),
    buyer_iin: props.slug.slug.includes('auto')
      ? yup.string().length(12, t('Неверный формат ИИН')).required(t('Обязательное поле'))
      : yup.string(),
    contract_number: includesSubstring(props.slug.slug, ['auto', CADILLAC_ALMATY])
      ? yup.string().required(t('Обязательное поле'))
      : yup.string(),
    car_model: props.slug.slug.includes('auto') ? yup.string().required(t('Обязательное поле')) : yup.string(),
    comment: props.slug.slug.includes('accessories') ? yup.string().required(t('Обязательное поле')) : yup.string(),
    email: yup.string().email(t('Неверный формат email')).required(t('Обязательное поле')),
    amount: yup.string().required(t('Обязательное поле')),
  });

  const formik = useFormik<IPaymentLinksPaymentFormRangedValues>({
    initialValues: {
      pan: '',
      exp: '',
      cvc: '',
      holder: '',
      iin: '',
      buyer_name: '',
      buyer_iin: '',
      contract_number: '',
      comment: '',
      car_model: '',
      email: '',
      amount: '0',
    },
    onSubmit: (values) => {
      setLoading(true);

      const paymentValues = {
        ...values,
        pan: values.pan.replace(/ /g, ''),
        // append 00 to amount string to convert to minor units
        amount: values.amount.replace(/ /g, '') + '00',
      };

      if (values.contract_number && values.car_model && !isEmpty(carModels)) {
        paymentValues.contract_number = `${carModels[values.car_model] || ''}_${values.contract_number}`;
      }

      return createPayment(paymentValues);
    },
    validationSchema,
  });

  function setFocusByName(name: string, prevInputName?: string): void {
    const input = document.getElementsByName(name)[0];
    input.focus();

    if (prevInputName) {
      formik.setFieldTouched(prevInputName, false);
    }
  }

  const handleBlur = (): void => setFocused('');

  const handleFocusedField = (event: FocusEvent<HTMLInputElement>): void => {
    setFocused(event.target.name);
  };

  const panNumberError = focused !== 'pan' && formik.touched.pan && formik.errors.pan;
  const expireDateError = focused !== 'exp' && formik.touched.exp && formik.errors.exp;
  const cvcError = focused !== 'cvc' && formik.touched.cvc && formik.errors.cvc;
  const emailError = focused !== 'email' && formik.touched.email && formik.errors.email;
  const holderError = focused !== 'name' && formik.touched.holder && formik.errors.holder;
  const iinError = focused !== 'iin' && formik.touched.iin && formik.errors.iin;
  const buyerNameError = focused !== 'buyer_name' && formik.touched.buyer_name && formik.errors.buyer_name;
  const buyerIinError = focused !== 'buyer_iin' && formik.touched.buyer_iin && formik.errors.buyer_iin;
  const contractNumberError =
    focused !== 'contract_number' && formik.touched.contract_number && formik.errors.contract_number;
  const commentError = focused !== 'comment' && formik.touched.comment && formik.errors.comment;
  const carModelError = focused !== 'car_model' && formik.touched.car_model && formik.errors.car_model;

  const displayAmount = getAmountWithCurrency(formik.values.amount, props.slug.currency);

  const MenuProps = {
    PaperProps: {
      style: {
        width: 'fit-content',
      },
    },
  };

  const handleChangeIIN = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parsedValue = value?.replace(/[^0-9]/g, '');
    const limitedValue = parsedValue.slice(0, 12);

    formik.setFieldValue(name, limitedValue);
    formik.setFieldTouched(name);
  };

  const handleChangeContractNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const parsedValue = value?.replace(/[^0-9]/g, '');
    const limitedValue = parsedValue.slice(0, 6);

    formik.setFieldValue(name, limitedValue);
    formik.setFieldTouched(name);
  };

  const renderContractNumberInput = () => {
    const preselectedCode = () => (
      <div
        className={classnames('card-form__custom-container', {
          'card-form__input--focus': focused === 'contract_number',
          'card-form__input--error': contractNumberError,
        })}
      >
        {
          <div
            className={classnames('card-form__custom', {
              'card-form__input--disabled': !formik.values.car_model,
            })}
          >
            {formik?.values?.car_model ? `${carModels[formik?.values?.car_model]}` : '000'}_
          </div>
        }

        <input
          type="text"
          name={'contract_number'}
          className={classnames('input', 'card-form__input--custom', {
            'input--error': contractNumberError,
            'input--focus': focused === 'contract_number',
          })}
          value={formik.values.contract_number}
          placeholder={'000000'}
          onChange={handleChangeContractNumber}
          onBlur={(event) => {
            formik.handleBlur(event);
            handleBlur();
          }}
          onFocus={handleFocusedField}
          disabled={isLoading}
          inputMode={'text'}
        />
      </div>
    );

    const maskedInput = (slug: string) => (
      <PaymentLinksContractNumber
        slug={slug}
        contractNumberError={Boolean(contractNumberError)}
        handleChangeContractNumber={formik.handleChange}
        handleFocusedField={handleFocusedField}
        handleBlur={handleBlur}
        formHandleBlur={formik.handleBlur}
        values={formik.values}
        isLoading={isLoading}
        focused={focused}
      />
    );

    const defaultInput = () => (
      <input
        type="text"
        name={'contract_number'}
        className={classnames('input', buyerIinError && 'input--error')}
        placeholder={t('contract_number')}
        onBlur={(event) => {
          formik.handleBlur(event);
          handleBlur();
        }}
        onFocus={handleFocusedField}
        disabled={isLoading}
        onChange={formik.handleChange}
        value={formik.values.contract_number}
        inputMode={'text'}
      />
    );

    if ([CARTIME_AUTO].includes(props.slug.slug)) {
      return maskedInput(props.slug.slug);
    }

    if (!isEmpty(carModels)) {
      return preselectedCode();
    }

    return defaultInput();
  };

  useEffect(() => {
    if (samePerson) {
      const iin = formik.values['iin'];
      const holder = formik.values['holder'];

      formik.setFieldValue('buyer_iin', iin);
      formik.setFieldValue('buyer_name', holder);
    }
  }, [samePerson, formik.values['iin'], formik.values['holder']]);

  //TODO remove warning
  return (
    <div className={'card-form__wrapper flex-basis-md-50 flex align-items-center justify-center'}>
      <form
        className={'card-form'}
        onSubmit={formik.handleSubmit}
        style={props.widgetOptions?.widgetProps?.styles?.cardFormContainer}
      >
        <h3 className="card-form__title">{t('Оплата картой')}</h3>
        {/* i am sorry. don't blame me */}
        {props.slug.slug !== CADILLAC_ALMATY ? (
          <div className={'card-form__warning'}>
            <Alert severity="warning">{t('DCG_PAYMENTS_WARNING')}</Alert>
          </div>
        ) : null}

        <div className="card-form__row" style={props.widgetOptions?.widgetProps?.styles?.panContainer}>
          <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
            {t('Номер карты')}
          </label>
          <PanInput
            name="pan"
            placeholder={DEFAULT_CARD_PLACEHOLDER.pan}
            type="text"
            value={formik.values.pan}
            onChange={(e) => {
              const type = getCardType(e.target.value);

              if (e.target.value !== '') {
                setCardBrand(type?.[0]?.type);
              } else {
                setCardBrand('');
              }

              setCvcSize(type?.[0]?.code?.size || 3);

              formik.handleChange(e);

              if (panSchema.isValidSync(e.target.value)) {
                setFocusByName('exp', e.target.name);
              }
            }}
            onBlur={(event) => {
              formik.handleBlur(event);
              handleBlur();
            }}
            onFocus={handleFocusedField}
            customClassname={panNumberError ? 'input--error' : ''}
            brand={cardBrand as PaymentSystem}
            style={props.widgetOptions?.widgetProps?.styles?.panInput}
            disabled={isLoading}
            inputMode="numeric"
          />
          {panNumberError ? (
            <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
              {formik.errors.pan}
            </p>
          ) : null}
        </div>
        <div
          className="card-form__row card-form__row--exp-cvc"
          style={props.widgetOptions?.widgetProps?.styles?.expireAndCvcContainer}
        >
          <div className="card-form__row--exp" style={props.widgetOptions?.widgetProps?.styles?.expireContainer}>
            <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
              {t('Срок карты')}
            </label>
            <ExpireInput
              name="exp"
              placeholder={DEFAULT_CARD_PLACEHOLDER.expDate}
              type="text"
              value={formik.values.exp}
              onChange={(event) => {
                formik.handleChange(event);

                if (expSchema.isValidSync(event.target.value)) {
                  setFocusByName('cvc', event.target.name);
                }
              }}
              onBlur={(event) => {
                formik.handleBlur(event);
                handleBlur();
              }}
              onFocus={handleFocusedField}
              className={expireDateError ? 'input--error' : ''}
              style={props.widgetOptions?.widgetProps?.styles?.expireInput}
              disabled={isLoading}
              inputMode="numeric"
            />
            {expireDateError ? (
              <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                {formik.errors.exp}
              </p>
            ) : null}
          </div>
          <div className="card-form__row--cvc" style={props.widgetOptions?.widgetProps?.styles?.cvcContainer}>
            <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
              {'CVC / CVV'}
            </label>
            <CVCInput
              autoComplete="cc-csc"
              name="cvc"
              placeholder={DEFAULT_CARD_PLACEHOLDER.cvv.slice(0, cvcSize)}
              value={formik.values.cvc}
              onChange={(event) => {
                formik.handleChange(event);

                if (cvcSchema.isValidSync(event.target.value)) {
                  const nameToFocus = props.slug.slug === CADILLAC_ALMATY ? 'contract_number' : 'holder';

                  setFocusByName(nameToFocus, event.target.name);
                }
              }}
              onBlur={(event) => {
                formik.handleBlur(event);
                handleBlur();
              }}
              maxLength={cvcSize}
              onFocus={handleFocusedField}
              disabled={isLoading}
              customClassname={cvcError ? 'input--error' : ''}
              style={props.widgetOptions?.widgetProps?.styles?.cvcInput}
              inputMode="numeric"
            />
            {cvcError ? (
              <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                {formik.errors.cvc}
              </p>
            ) : null}
          </div>
        </div>

        {props.slug.slug.includes(CADILLAC_ALMATY) ? (
          <div className="card-form__row">
            <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
              {t('contract_number')}
            </label>
            {renderContractNumberInput()}
            {contractNumberError ? (
              <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                {formik.errors.contract_number}
              </p>
            ) : null}
          </div>
        ) : null}

        {/* i am sorry. don't blame me */}
        {props.slug.slug !== CADILLAC_ALMATY ? (
          <>
            <div className="card-form__row">
              <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
                {t('holder')}
              </label>
              <input
                type="text"
                name={'holder'}
                className={classnames('input', holderError && 'input--error')}
                value={formik.values.holder}
                placeholder={t('holder')}
                onChange={formik.handleChange}
                onBlur={(event) => {
                  formik.handleBlur(event);
                  handleBlur();
                }}
                onFocus={handleFocusedField}
                disabled={isLoading}
                inputMode={'text'}
              />
              {holderError ? (
                <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                  {formik.errors.holder}
                </p>
              ) : null}
            </div>
            <div className="card-form__row">
              <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
                {t('iin')}
              </label>
              <input
                type="text"
                name={'iin'}
                className={classnames('input', iinError && 'input--error')}
                value={formik.values.iin}
                placeholder={'------------'}
                onChange={handleChangeIIN}
                onBlur={(event) => {
                  formik.handleBlur(event);
                  handleBlur();
                }}
                onFocus={handleFocusedField}
                disabled={isLoading}
                inputMode={'text'}
              />
              {iinError ? (
                <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                  {formik.errors.iin}
                </p>
              ) : null}
            </div>
            {(props.slug.slug.includes('auto') || props.slug.slug.includes('accessories')) && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 'var(--card-form-row-mb)',
                  gap: '8px',
                }}
              >
                <input
                  type="checkbox"
                  style={{ width: '18px', height: '18px' }}
                  onChange={(e) => setSamePerson(e.target.checked)}
                />
                <Typography fontSize={'14px'}>{t('same_person_checkbox')}</Typography>
              </div>
            )}
            {(props.slug.slug.includes('auto') || props.slug.slug.includes('accessories')) && (
              <div className="card-form__row">
                <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
                  {t('buyer_name')}
                </label>
                <input
                  type="text"
                  name={'buyer_name'}
                  className={classnames('input', (samePerson ? holderError : buyerNameError) && 'input--error')}
                  value={formik.values.buyer_name}
                  placeholder={t('buyer_name')}
                  onChange={formik.handleChange}
                  onBlur={(event) => {
                    formik.handleBlur(event);
                    handleBlur();
                  }}
                  onFocus={handleFocusedField}
                  disabled={isLoading || samePerson}
                  inputMode={'text'}
                />
                {(samePerson ? holderError : buyerNameError) ? (
                  <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                    {formik.errors.buyer_name}
                  </p>
                ) : null}
              </div>
            )}
            {(props.slug.slug.includes('auto') || props.slug.slug.includes('accessories')) && (
              <div className="card-form__row">
                <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
                  {t('buyer_iin')}
                </label>
                <input
                  type="text"
                  name={'buyer_iin'}
                  className={classnames('input', (samePerson ? iinError : buyerIinError) && 'input--error')}
                  value={formik.values.buyer_iin}
                  placeholder={'------------'}
                  onChange={handleChangeIIN}
                  onBlur={(event) => {
                    formik.handleBlur(event);
                    handleBlur();
                  }}
                  onFocus={handleFocusedField}
                  disabled={isLoading || samePerson}
                  inputMode={'text'}
                />
                {(samePerson ? iinError : buyerIinError) ? (
                  <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                    {formik.errors.buyer_iin}
                  </p>
                ) : null}
              </div>
            )}
            {props.slug.slug.includes('auto') && (
              <div className={classnames('card-form__row', 'card-form__input-container')}>
                <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
                  {t('car_model')}
                </label>

                {isEmpty(carModels) ? (
                  <input
                    type="text"
                    name={'car_model'}
                    className={classnames('input', carModelError && 'input--error')}
                    placeholder={t('car_model')}
                    onBlur={(event) => {
                      formik.handleBlur(event);
                      handleBlur();
                    }}
                    onFocus={handleFocusedField}
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    value={formik.values.car_model}
                    inputMode={'text'}
                  />
                ) : (
                  <Select
                    style={{ padding: 0 }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '0',
                      },
                    }}
                    name={'car_model'}
                    MenuProps={MenuProps}
                    onBlur={(event) => {
                      formik.handleBlur(event);
                      handleBlur();
                    }}
                    onFocus={handleFocusedField}
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    value={formik.values.car_model}
                    className={classnames('input', carModelError && 'input--error')}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em style={{ color: '#8f8f8f' }}>{t('car_model')}</em>;
                      }

                      return selected;
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>{t('car_model')}</em>
                    </MenuItem>
                    {Object.keys(carModels).map((carModel, index) => {
                      return (
                        <MenuItem key={index} value={carModel}>
                          {carModel}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                {carModelError ? (
                  <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                    {formik.errors.car_model}
                  </p>
                ) : null}
              </div>
            )}
            {/*show vin number field if payment links is for accessories else show contract number field*/}
            {props.slug.slug.includes('auto') ? (
              <div className="card-form__row">
                <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
                  {t('contract_number')}
                </label>
                {renderContractNumberInput()}
                {contractNumberError ? (
                  <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                    {formik.errors.contract_number}
                  </p>
                ) : null}
              </div>
            ) : null}

            {props.slug.slug.includes('accessories') ? (
              <div className="card-form__row">
                <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
                  {t('name')}
                </label>

                <Select
                  style={{ padding: 0 }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '0',
                    },
                  }}
                  name={'comment'}
                  MenuProps={MenuProps}
                  onBlur={(event) => {
                    formik.handleBlur(event);
                    handleBlur();
                  }}
                  onFocus={handleFocusedField}
                  disabled={isLoading}
                  onChange={formik.handleChange}
                  value={formik.values.comment}
                  className={classnames('input', carModelError && 'input--error')}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em style={{ color: '#8f8f8f' }}>{t('name')}</em>;
                    }

                    return selected;
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>{t('name')}</em>
                  </MenuItem>
                  {[t('service maintenance'), t('additional equipment')].map((serviceType, index) => {
                    return (
                      <MenuItem key={index} value={serviceType}>
                        {serviceType}
                      </MenuItem>
                    );
                  })}
                </Select>

                {commentError ? (
                  <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
                    {formik.errors.comment}
                  </p>
                ) : null}
              </div>
            ) : null}
          </>
        ) : null}

        <div className="card-form__row">
          <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
            {t('Email')}
          </label>
          <input
            type="text"
            name={'email'}
            className={classnames('input', emailError && 'input--error')}
            placeholder={t('Email')}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={(event) => {
              formik.handleBlur(event);
              handleBlur();
            }}
            onFocus={handleFocusedField}
            disabled={isLoading}
            inputMode={'email'}
          />
          {emailError ? (
            <p className="errorText" style={props.widgetOptions?.widgetProps?.styles?.errorText}>
              {formik.errors.email}
            </p>
          ) : null}
        </div>

        <div className="card-form__row" style={props.widgetOptions?.widgetProps?.styles?.panContainer}>
          <label className="input-label" style={props.widgetOptions?.widgetProps?.styles?.inputsLabel}>
            {t('amount')}
          </label>
          <CurrencyInput
            name={'amount'}
            type="text"
            placeholder="0"
            onChange={formik.handleChange}
            disabled={isLoading}
          />
        </div>
        <div className="card-form__row">
          <Button
            isLoading={isLoading}
            type="submit"
            disabled={!formik.isValid || isLoading}
            style={props.widgetOptions?.widgetProps?.styles?.button}
            id={'ioka-pay-button'}
          >
            {`${t('Оплатить')} ${displayAmount}`}
          </Button>
        </div>
        {error?.response?.data.message && <p className="serverError">{error?.response.data.message}</p>}
        <div className={'card-form__row display-md-none'}>
          <CardFooter
            customStyles={props.widgetOptions?.widgetProps?.styles?.cardFooter}
            hide={props.widgetOptions?.widgetProps?.cardForm?.hideFooter}
          />
        </div>
        <div className="card-form__row">
          <div className="bottom-text">
            {`${t('Платежи надежно защищены')} `}
            <img src="/images/ioka-logo.svg" className="bottom-text__logo" alt="ioka" height={16} width="auto" />
          </div>
        </div>
      </form>
    </div>
  );
}

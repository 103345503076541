import { MaterialCommunityIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mobily/stacks';
import { Link } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { Colors } from 'react-native-paper';
import * as yup from 'yup';

import { IRegisterResolverParams, IRegisterResolverResult, registerResolver } from '@iokanx/shared/data-access/api';
import { DeviceSize } from '@iokanx/shared/data-access/constants';
import { IHttpError } from '@iokanx/shared/data-access/types';
import {
  SimpleCard,
  Card,
  HelperText,
  Title,
  Caption,
  EmailField,
  PasswordField,
  TextField,
  Button,
} from '@iokanx/shared/ui/universal';

import { Amplitude } from '../amplitude/amplitude';
import { DashboardStackParamList, useDashboardNavigation } from '../navigation';

const registrationFormSchema: yup.SchemaOf<IRegisterResolverParams> = yup.object({
  first_name: yup
    .string()
    .min(2, 'Имя должно быть длиннее 2 символов')
    .required('Обязательное поле')
    .matches(/(^\S*$)/, 'Имя не может содержать только пробел'),
  last_name: yup
    .string()
    .min(2, 'Фамилия должна быть длиннее 2 символов')
    .required('Обязательное поле')
    .matches(/(^\S*$)/, 'Фамилия не может содержать только пробел'),
  email: yup.string().email('Email должен быть таким user@domain.com').required('Обязательное поле'),
  password: yup
    .string()
    .min(8, 'Пароль должен быть длиннее 8 символов')
    .required('Обязательное поле')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Добавьте один верхний регистр, один нижний регистр, одно число.',
    ),
});

export function RegistrationForm() {
  const {
    mutate: register,
    isLoading,
    isError,
    error,
    isSuccess,
    reset,
  } = useMutation<IRegisterResolverResult, AxiosError<IHttpError>, IRegisterResolverParams>(registerResolver, {
    onSuccess: () => Amplitude.logEventWithPropertiesAsync('sign_up', { success: true }),
    onError: () => Amplitude.logEventWithPropertiesAsync('sign_up', { success: false }),
  });

  const { control, handleSubmit } = useForm<IRegisterResolverParams>({
    defaultValues: {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
    },
    resolver: yupResolver(registrationFormSchema),
  });
  const onSubmit: SubmitHandler<IRegisterResolverParams> = (data) => register(data);

  const navigation = useDashboardNavigation();
  function handleNavigateToSignIn() {
    navigation.push('SignIn');
  }

  if (isSuccess) {
    return (
      <Box>
        <Stack space={2} align={'center'}>
          <MaterialCommunityIcons name="email-check" size={80} color={Colors.green500} />
          <Title>Проверьте почту</Title>
          <Caption>Мы отправили вам ссылку для подтверждения почты и активации кабинета. Не получили письмо?</Caption>
          <Button onPress={reset}>Отправить еще раз</Button>
          <Link<DashboardStackParamList> to={{ screen: 'SignIn' }}>Войти</Link>
        </Stack>
      </Box>
    );
  }

  return (
    <SimpleCard title={'Регистрация'} cardStyle={styles.card}>
      <Card.Content>
        <Stack space={2}>
          <Controller
            control={control}
            name={'first_name'}
            render={({ field, fieldState }) => (
              <TextField
                label={'Имя'}
                value={field.value}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                helperText={fieldState.error?.message}
                error={Boolean(fieldState.error)}
                autoComplete={'name-given'}
                textContentType={'givenName'}
              />
            )}
          />
          <Controller
            control={control}
            name={'last_name'}
            render={({ field, fieldState }) => (
              <TextField
                label={'Фамилия'}
                value={field.value}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                helperText={fieldState.error?.message}
                error={Boolean(fieldState.error)}
                autoComplete={'name-family'}
                textContentType={'familyName'}
              />
            )}
          />
          <Controller
            control={control}
            name={'email'}
            render={({ field, fieldState }) => (
              <EmailField
                label={'Email'}
                value={field.value}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                helperText={fieldState.error?.message}
                error={Boolean(fieldState.error)}
                autoComplete={'off'}
              />
            )}
          />
          <Controller
            control={control}
            name={'password'}
            render={({ field, fieldState }) => (
              <PasswordField
                label={'Пароль'}
                value={field.value}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                autoComplete={'password-new'}
                textContentType={'newPassword'}
              />
            )}
          />
        </Stack>
        <HelperText type={'info'}>
          После отправки формы мы отправим вам ссылку для подтверждения почты и активации кабинета
        </HelperText>
        <HelperText type={'error'} visible={isError}>
          {error?.response?.data?.message}
        </HelperText>
      </Card.Content>
      <Card.Actions>
        <Stack space={2}>
          <Button onPress={handleSubmit(onSubmit)} loading={isLoading}>
            Зарегистрироваться
          </Button>
          <Button onPress={handleNavigateToSignIn} mode={'text'}>
            Войти
          </Button>
        </Stack>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  card: {
    width: '100%',
    backgroundColor: '#f8fafc',
    borderRadius: 12,
    border: 'none',
    maxWidth: DeviceSize.LargeMobile,
  },
});

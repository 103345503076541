import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip } from '@mui/material';

const TOOLTIP_TITLE =
  'Отношение количества успешно оплаченных заказов к количеству всех заказов, по которым были попытки оплаты. При расчете конверсии учитываются заказы, обработанные за выбранный промежуток времени.';

export function ConversionTooltip() {
  return (
    <Tooltip title={TOOLTIP_TITLE} arrow>
      <IconButton size={'small'}>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';
import { RadioButtons } from '@iokanx/shared/ui/universal';

import { QUESTION_INTERMEDIARIES } from './constants';

import { getBinaryItems, isFormEmpty } from '../../../helpers/helpers';
import { useFormEdit } from '../../../helpers/useFormEdit';
import { BinaryAnswer } from '../../../shared/types';
import { ConditionalComponent } from '../../ConditionalComponent';
import { FormField } from '../../FormField/FormField';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { organizationIntermediaryValidationSchema } from '../OrganizationInfoStep/model/schemas';

export const parseIntermediaryData = (data: any) => {
  if (data) {
    return {
      type: 'LEGAL',
      nib: data.personal_data.nib,
      name: data.personal_data.name,
    };
  }

  return;
};

export const IntermediariesForm = ({
  isEmpty,
  isSubmitted,
  organizationId,
  form,
}: {
  isEmpty?: boolean;
  isSubmitted: boolean;
  organizationId: string;
  form: boolean;
}) => {
  const queryClient = useQueryClient();

  const { mutate: postIntermediary } = useMutation(
    async (data: any) => {
      const { data: response } = await backendHttp.post(`organizations/${organizationId}/shares`, {
        is_intermediary: true,
        personal_data: parseIntermediaryData(data),
      });

      return response;
    },
    {
      onSuccess: async () => {
        console.log('created');
        reset();
        handleIsEdit();
        await queryClient.invalidateQueries(['shares']);
      },
    },
  );

  const {
    getValues,
    control,
    trigger,
    formState: { isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      is_intermediary: isEmpty ? BinaryAnswer.NO : '',
      personal_data: {
        name: '',
        nib: '',
      },
    },
    resolver: yupResolver(organizationIntermediaryValidationSchema),
  });

  const onSubmit = (data: any) => {
    if (data.is_intermediary === BinaryAnswer.NO) {
      handleIsEdit();

      return;
    }
    postIntermediary(data);
  };

  const onError = (e: any) => console.error(e);

  const handleButtonPress = () => {
    const formValues = getValues();

    if (isFormEmpty(formValues)) {
      trigger();

      return;
    }

    return isDirty ? handlePress() : handleIsEdit();
  };

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(isEmpty), handleSubmit(onSubmit, onError));

  return (
    <ProfileFormSection isSaved={isDisable} isSubmitted={isSubmitted} handleSubmit={handleButtonPress}>
      <ConditionalComponent
        condition={!form}
        component={
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <Item>
                <RadioButtons
                  label={QUESTION_INTERMEDIARIES}
                  onValueChange={onChange}
                  value={value}
                  items={getBinaryItems(isDisable || isSubmitted)}
                />
              </Item>
            )}
            name={'is_intermediary'}
          />
        }
      />

      <ConditionalComponent
        condition={watch('is_intermediary') === BinaryAnswer.YES || form}
        component={
          <>
            <FormField disabled={isDisable} name={'personal_data.name'} control={control} label={'Название'} />

            <FormField disabled={isDisable} name={'personal_data.nib'} control={control} label={'БИН/ИИН'} />
          </>
        }
      />
    </ProfileFormSection>
  );
};

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { ScrollView } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { Amplitude, DashboardStackParamList, useDashboardNavigation } from '@iokanx/dashboard/feature';
import { getUserByIdResolver } from '@iokanx/shared/data-access/api';
import { USER_STATUS_I18N, UserStatus } from '@iokanx/shared/data-access/constants';
import { wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Button, Divider, List } from '@iokanx/shared/ui/universal';

export type IUserPageProps = NativeStackScreenProps<DashboardStackParamList, 'User'>;

const User = (props: IUserPageProps) => {
  const { data: user } = useQuery(['user', props.route.params], () => getUserByIdResolver(props.route.params));
  const queryClient = useQueryClient();
  const navigation = useDashboardNavigation();

  if (!user) return null;

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  const handleCreateShopClick = () => {
    queryClient.setQueryData(['selectedUser'], user);
    navigation.navigate('CreateShop');
    Amplitude.logEventWithPropertiesAsync('create_shop_click', {});
  };

  const handleShopClick = (id: string) => {
    navigation.navigate('Shop', { shopId: id });
    Amplitude.logEventWithPropertiesAsync('create_shop_click', {});
  };

  return (
    <ScrollView>
      <List.Section title="Информация">
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Имя</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{user.first_name}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Фамилия</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{user.last_name}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Статус</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{USER_STATUS_I18N[user.status as UserStatus]}</Item>
          </Grid>
        </Grid>
      </List.Section>

      <Divider />

      <List.Section title="Детали">
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Почта</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{user.email}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Телефон</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{user.phone}</Item>
          </Grid>
        </Grid>
      </List.Section>

      <Divider />

      {!isEmpty(user.shops) ? (
        <List.Section title="Магазин">
          <Grid container>
            {user.shops.map((shop: any) => {
              return (
                <>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Название</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{shop.name}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Название в форме</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{shop?.display_name}</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item className="infoTitle">Роль</Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>{shop.role}</Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item>
                      <Button onPress={() => handleShopClick(shop.id)} icon={{ name: 'share' }} fullWidth={true}>
                        Перейти
                      </Button>
                    </Item>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </List.Section>
      ) : (
        <List.Section>
          <Grid container>
            <Grid item xs={12}>
              <Item>
                <Button onPress={handleCreateShopClick} icon={{ name: 'receipt' }} fullWidth={true}>
                  Создать магазин
                </Button>
              </Item>
            </Grid>
          </Grid>
        </List.Section>
      )}
    </ScrollView>
  );
};

export default wrapPage(
  wrapModalPage(User, {
    position: 'Right',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'User'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Users', params: { screen: 'Owners' } });
        navigation.push('User', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

import { isArray } from 'lodash';

export const parseData = (data: any, type: string) => {
  return type === 'PHYSICAL'
    ? {
        type: data.type,
        first_name: data.personal_data.first_name,
        last_name: data.personal_data.last_name,
        patronymic: data.personal_data.patronymic,
        nib: data.personal_data.nib,
        number: data.personal_data.number,
        issued_by: data.personal_data.issued_by,
        is_resident: data.personal_data.is_resident === 'true',
        issue_date: formatDateTime(new Date(data.personal_data.issue_date)),
      }
    : {
        type: data.type,
        name: data.personal_data.name,
        nib: data.personal_data.nib,
        is_resident: data.personal_data.is_resident === 'true',
      };
};

export const parseFormData = (beneficiary: any) => {
  if (beneficiary) {
    return beneficiary.personal_data.type === 'PHYSICAL'
      ? {
          type: beneficiary.personal_data.type,
          share: beneficiary.share,
          personal_data: {
            first_name: beneficiary.personal_data.first_name,
            last_name: beneficiary.personal_data.last_name,
            patronymic: beneficiary.personal_data.patronymic,
            nib: beneficiary.personal_data.nib,
            number: beneficiary.personal_data.number,
            issued_by: beneficiary.personal_data.issued_by,
            issue_date: beneficiary.personal_data.issue_date,
            is_resident: beneficiary.personal_data.is_resident,
          },
        }
      : {
          type: beneficiary.personal_data.type,
          share: beneficiary.share,
          personal_data: {
            name: beneficiary.personal_data.name,
            nib: beneficiary.personal_data.nib,
            is_resident: beneficiary.personal_data.is_resident,
          },
        };
  }

  return undefined;
};

export const buildSharesRoute = (organizationId: string, id?: string) => {
  const baseURL = `organizations/${organizationId}/shares`;

  return id ? baseURL + `/${id}` : baseURL;
};

export const filterShares = (shares: any[]) => {
  if (isArray(shares)) {
    const properties = ['is_holder', 'is_intermediary', 'is_official'];

    return shares.filter((share) => properties.every((property) => !share[property]));
  }

  return shares;
};

export const filterOwnerData = (shares: any[]) => {
  const ownerData = shares?.find((share) => share.is_holder);

  return ownerData || null;
};

export const formatDateTime = (date: any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

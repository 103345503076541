import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { createOrganizationEmployerResolver, updateOrganizationEmployerResolver } from './data-access';
import { getDefaultValues, parseContactData } from './helpers';
import { IContactData } from './types';

import { useFormEdit } from '../../../../../helpers/useFormEdit';
import { useProfileFormContext } from '../../../../../providers/ProfileFormProvider';
import { FormField } from '../../../../FormField/FormField';
import { ProfileFormSection } from '../../../../layouts/ProfileFormSection';
import { fieldsData } from '../../constants';
import { contactFormValidationSchema } from '../../model/schemas';
import { IFormSection } from '../../types';

export const OrganizationEmployerSection: FC<IFormSection> = (props) => {
  const { organizationId, isSubmitted } = useProfileFormContext();

  const { data: employer } = useQuery(['organization', organizationId, 'employer'], async () => {
    const { data } = await backendHttp.get(`organizations/${organizationId}`);

    return data.employer;
  });

  const { mutate } = useMutation(
    employer
      ? (formData: any) => updateOrganizationEmployerResolver(formData, organizationId, employer.id)
      : (formData: any) => createOrganizationEmployerResolver(formData, organizationId),
    {
      onSuccess: (data) => {
        reset(parseContactData(data));
        handleIsEdit();
      },
    },
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: employer ? parseContactData(employer) : getDefaultValues(),
    resolver: yupResolver(contactFormValidationSchema),
  });

  const onSubmit = (data: IContactData) => mutate(data);
  const onError = (error: any) => console.error(error);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(employer), handleSubmit(onSubmit, onError));

  const handleSubmitSection = () => (isDirty ? handlePress() : handleIsEdit());

  useEffect(() => {
    employer ? reset(parseContactData(employer)) : reset();
  }, [employer]);

  return (
    <ProfileFormSection
      isSubmitted={isSubmitted}
      isRemovable={false}
      handleSubmit={handleSubmitSection}
      isSaved={isDisable}
      {...props}
    >
      {fieldsData.map(({ name, label }, index) => {
        return <FormField key={index} name={name} label={label} control={control} disabled={isDisable} />;
      })}
    </ProfileFormSection>
  );
};

export const mapBooleanAnswer = (answer: boolean | string | undefined) => {
  if (typeof answer === 'undefined' || answer === '') return answer;

  return answer ? 'yes' : 'no';
};

export const answerQueryBuilder = (organizationId: string, id?: number) => {
  const baseURL = `/organizations/${organizationId}/answers`;
  return id ? baseURL + `/${id}` : baseURL;
};

import { StyleSheet } from 'react-native';

import { ShopList } from '@iokanx/dashboard/feature';
import { wrapPage } from '@iokanx/shared/feature';
// import { FAB } from '@iokanx/shared/ui/universal';

function Shops() {
  // const navigation = useDashboardNavigation();

  // function handlePressCreateShop() {
  //   navigation.navigate('CreateShop');
  // }

  return (
    <>
      {/*<FAB*/}
      {/*  style={styles.fab}*/}
      {/*  icon="plus"*/}
      {/*  onPress={handlePressCreateShop}*/}
      {/*  label={currentBreakpoint === Breakpoint.Desktop ? 'Добавить мерчанта' : ''}*/}
      {/*/>*/}
      <ShopList />
    </>
  );
}

// const styles = StyleSheet.create({
//   fab: {
//     position: 'absolute',
//     margin: 8,
//     right: 0,
//     bottom: 0,
//     zIndex: 9,
//   },
// });

export const ShopsPage = wrapPage(Shops);

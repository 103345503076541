import moment from 'moment';

import { CURRENCY_SYMBOL, DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';
import { compactObject } from '@iokanx/shared/util';

import {
  getIncomes,
  IGetIncomeByIdResponseData,
  IGetIncomesRequestParams,
  IGetIncomesResponseData,
} from '../../resources';

export type IFindIncomesResolverResult = IGetIncomesResponseData;

export interface IFindIncomesRequestResolverParams extends IGetIncomesRequestParams {
  income_id?: string;
}

export interface IFindIncomeResolverResultItem extends IGetIncomeByIdResponseData {
  externalId: string;
  amountWithSymbol: string;
  createdAt: string;
  approvedAt: string;
}

export async function searchIncomesResolver(params: IGetIncomesRequestParams): Promise<IFindIncomesResolverResult> {
  const incomes = await getIncomes(compactObject(params));
  const data = incomes.data.map(transformIncome);
  return { ...incomes, data };
}

function transformIncome(income: IGetIncomeByIdResponseData): IFindIncomeResolverResultItem {
  const amount = income.amount / 100;
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL[income.currency]}`;
  return {
    ...income,
    externalId: income.external_id,
    amountWithSymbol: amountWithSymbol,
    approvedAt: moment(income.approved_at).format(DATETIME_FORMAT),
    createdAt: moment(income.created_at).format(DATETIME_FORMAT),
  };
}

import { Stack, TextField, InputLabel, styled, OutlinedTextFieldProps } from '@mui/material';

const StyledInputLabel = styled(InputLabel)((props) => ({
  color: props.theme.palette.primary.main,
  fontSize: 12,
  fontWeight: 500,
}));

export interface IStyledTextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  getNextFocusElementName?: (value: string) => false | string | undefined;
}

export const StyledTextField = styled(({ label, getNextFocusElementName, ...props }: IStyledTextFieldProps) => (
  <Stack spacing={0.5}>
    <StyledInputLabel>{label}</StyledInputLabel>
    <TextField
      {...props}
      onChange={(event) => {
        const nextFocusElementName = getNextFocusElementName?.(event.target.value);
        if (nextFocusElementName) {
          setFocusByName(nextFocusElementName);
        }

        return props.onChange?.(event);
      }}
      helperText={props.helperText || ' '}
    />
  </Stack>
))((props) => ({
  '& .MuiOutlinedInput-root': {
    height: 48,
    backgroundColor: props.theme.palette.background.default,
    fontSize: 14,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E2E8F0',
  },
}));

function setFocusByName(name: string) {
  const el = document.getElementsByName(name)[0];
  if (el) {
    el.focus();
  } else {
    console.log(`Element ${el} is not found`);
  }
}

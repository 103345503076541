import { ArrowForward } from '@mui/icons-material';
import { Button as MaterialButton, Grid } from '@mui/material';
import { FC, ReactNode, useEffect } from 'react';
import React, { createContext, useContext, useState } from 'react';
import { List } from 'react-native-paper';

import { IFormSection } from '../steps/OrganizationInfoStep/types';

interface ProfileFormSectionProps extends IFormSection {
  children: ReactNode;
}

const ProfileFormStepContext = createContext<any>(null);

export const useProfileFormStepContext = () => useContext(ProfileFormStepContext);

export const ProfileFormStep: FC<ProfileFormSectionProps> = ({ handleNext, handleComplete, children }) => {
  const [sectionsCompletionStatus, setSectionsCompletionStatus] = useState({});

  const updateCompletionStatus = (title: string, isComplete: boolean) => {
    setSectionsCompletionStatus((prevStatus) => ({
      ...prevStatus,
      [title]: isComplete,
    }));
  };

  useEffect(() => {
    const allChildrenCompleted =
      Object.values(sectionsCompletionStatus).length > 0
        ? Object.values(sectionsCompletionStatus).every(Boolean)
        : false;

    if (allChildrenCompleted && handleComplete) {
      handleComplete(allChildrenCompleted);
    }
  }, [sectionsCompletionStatus]);

  return (
    <ProfileFormStepContext.Provider value={{ updateCompletionStatus }}>
      <List.Section>
        {children}

        <Grid container justifyContent={'flex-end'}>
          {handleNext && (
            <MaterialButton
              sx={{ minWidth: '180px', marginTop: '24px' }}
              onClick={handleNext}
              endIcon={<ArrowForward />}
            >
              {'Дальше'}
            </MaterialButton>
          )}
        </Grid>
      </List.Section>
    </ProfileFormStepContext.Provider>
  );
};

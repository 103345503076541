import { SHOP_STATUS_TITLE, ShopStatus } from '@iokanx/shared/data-access/constants';

import { StatusChip, StatusChipPropsIconName, StatusChipVariant } from '../status-chip/status-chip';

export interface IShopStatusChipProps {
  shopStatus: ShopStatus;
}

const statusChipVariant: Record<ShopStatus, StatusChipVariant> = {
  [ShopStatus.Rejected]: StatusChipVariant.Failure,
  [ShopStatus.Suspended]: StatusChipVariant.Failure,
  [ShopStatus.Pending]: StatusChipVariant.Loading,
  [ShopStatus.Review]: StatusChipVariant.Loading,
  [ShopStatus.Accepted]: StatusChipVariant.Success,
};

const statusChipIconName: Record<ShopStatus, StatusChipPropsIconName> = {
  [ShopStatus.Review]: 'clock',
  [ShopStatus.Pending]: 'loading',
  [ShopStatus.Accepted]: 'check',
  [ShopStatus.Suspended]: 'check',
  [ShopStatus.Rejected]: 'check',
};

export function ShopStatusChip(props: IShopStatusChipProps) {
  return (
    <StatusChip variant={statusChipVariant[props.shopStatus]} iconName={statusChipIconName[props.shopStatus]}>
      {SHOP_STATUS_TITLE[props.shopStatus]}
    </StatusChip>
  );
}

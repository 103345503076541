import { CaptureMethod, Currency, OrderStatus } from '@iokanx/shared/data-access/constants';

import { IGetOrderByIdPaymentByIdResponseData } from './payments/by-id';

import { http } from '../../../instances';

export interface IGetOrderByIdResponseData {
  /** Идентификатор заказа */
  id: string;
  /** Идентификатор магазина */
  shop_id: string;
  /** Статус заказа */
  status: OrderStatus;
  /** Время создания заказа */
  created_at: string;
  /**
   * Стоимость предлагаемых товаров или услуг в минорных денежных единицах.
   *
   * Например, для создания заказа на сумму 500 тенге, необходимо ввести 50000 (1 тенге = 100 тиын)
   */
  amount: number;
  display_amount: number;
  /** Доступные валюты для проведения платежа */
  currency: Currency;
  /** Способ списания оплаты */
  capture_method: CaptureMethod;
  /** Внешний идентификатор заказа */
  external_id?: string;
  /** Описание заказа */
  description?: string;
  /** Дополнительные данные, связанные с заказом */
  extra_info?: Record<string, unknown>;
  /** Количество разрешенных попыток оплаты для заказа */
  attempts?: number;
  /** Срок действия заказа */
  due_date?: string;
  /**
   * Идентификатор плательщика.
   *
   * Для оплаты по сохраненной карте через форму оплаты с подтверждением CVC.
   */
  customer_id?: string;
  /**
   * Идентификатор сохраненной карты плательщика.
   *
   * Для оплаты по сохраненной карте через форму оплаты с подтверждением CVC.
   */
  card_id?: string;
  /** Ссылка, служащая для перенаправления клиента на сайт мерчанта после проведения оплаты */
  back_url?: string;
  /** Ссылка, служащая для перенаправления клиента на сайт мерчанта после успешного проведения оплаты */
  success_url?: string;
  /** Ссылка, служащая для перенаправления клиента на сайт мерчанта после неуспешного проведения оплаты */
  failure_url?: string;
  /** Шаблон формы оплаты */
  template?: string;
  /** Ссылка на форму оплаты */
  checkout_url: string;
  /** Токен доступа к заказу */
  access_token: string;
  /** MCC Код */
  mcc?: string;
  payment_link_url?: string;
  payments?: IGetOrderByIdPaymentByIdResponseData[];
  split?: any;
}

/** Получение заказа по ID */
export async function getOrderById(orderId: string): Promise<IGetOrderByIdResponseData> {
  const { data } = await http.get<IGetOrderByIdResponseData>(`/orders/${orderId}`);
  return data;
}

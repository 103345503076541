import moment from 'moment';

import { DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';

import { getShopById, IGetShopByIdResponseData } from '../../resources';

export interface IFindShopIdParams {
  shopId: string;
}

export interface IFindShopIdResult extends IGetShopByIdResponseData {
  // l10n created_at
  createdAt: string;
  // Нормализованное имя магазина
  displayName: string;
}

export async function findShopByIdResolver(params: IFindShopIdParams): Promise<IFindShopIdResult> {
  const shop = await getShopById(params.shopId);
  return transformShop(shop);
}

export function transformShop(shop: IGetShopByIdResponseData): IFindShopIdResult {
  return {
    ...shop,
    createdAt: moment(shop.created_at).format(DATETIME_FORMAT),
    displayName: shop.display_name || shop.name,
  };
}

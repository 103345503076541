import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { Select } from '@iokanx/shared/ui/universal';

import { ConditionalComponent } from './ConditionalComponent';
import { DatePickerYear } from './Datepicker';
import { FormField } from './FormField/FormField';

interface IPersonalData {
  control: Control<any>;
  type: string;
  isDisable: boolean;
}

export const getPhysicalPersonalData = () => ({
  type: '',
  first_name: '',
  last_name: '',
  patronymic: '',
  nib: '',
  share: '',
  is_resident: '',
  number: '',
  issued_by: '',
  issue_date: '',
});

export const getLegalPersonalData = () => ({
  type: '',
  name: '',
  nib: '',
  is_resident: '',
});

export const PersonalData: FC<IPersonalData> = ({ control, type, isDisable }) => {
  return (
    <>
      <ConditionalComponent
        condition={type === 'PHYSICAL'}
        component={
          <>
            <FormField name={'share'} control={control} label={'Доля участия %'} disabled={isDisable} />
            <FormField name={'personal_data.first_name'} control={control} label={'Имя'} disabled={isDisable} />
            <FormField name={'personal_data.last_name'} control={control} label={'Фамилия'} disabled={isDisable} />
            <FormField name={'personal_data.patronymic'} control={control} label={'Отчество'} disabled={isDisable} />
            <FormField name={'personal_data.nib'} control={control} label={'ИИН'} disabled={isDisable} />

            <Controller
              control={control}
              name={'personal_data.is_resident'}
              render={({ field: { onChange, value } }) => (
                <Item>
                  <Select
                    onChange={onChange}
                    style={{ width: '100%' }}
                    disabled={isDisable}
                    value={String(value)}
                    label={'Резиденство'}
                    items={[
                      { value: 'true', title: 'Является резидентом' },
                      { value: 'false', title: 'Не является резидентом' },
                    ]}
                  />
                </Item>
              )}
            />

            <FormField
              name={'personal_data.number'}
              control={control}
              label={'№ Удостоверяющего документа'}
              disabled={isDisable}
            />

            <FormField name={'personal_data.issued_by'} control={control} label={'Кем выдан'} disabled={isDisable} />

            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Item>
                  <DatePickerYear
                    disabled={isDisable}
                    label="Дата выдачи"
                    value={value && new Date(value)}
                    onChange={onChange}
                  />
                </Item>
              )}
              name={'personal_data.issue_date'}
            />
          </>
        }
      />

      <ConditionalComponent
        condition={type === 'LEGAL'}
        component={
          <>
            <FormField name={'share'} control={control} label={'Доля владения %'} disabled={isDisable} />

            <FormField
              name={'personal_data.name'}
              control={control}
              label={'Наименование/ФИО акционера/учредителя'}
              disabled={isDisable}
            />

            <FormField name={'personal_data.nib'} control={control} label={'ИИН/БИН'} disabled={isDisable} />

            <Controller
              control={control}
              name={'personal_data.is_resident'}
              render={({ field: { onChange, value } }) => (
                <Item>
                  <Select
                    onChange={onChange}
                    style={{ width: '100%' }}
                    disabled={isDisable}
                    value={String(value)}
                    label={'Резиденство'}
                    items={[
                      { value: 'true', title: 'Является резидентом' },
                      { value: 'false', title: 'Не является резидентом' },
                    ]}
                  />
                </Item>
              )}
            />
          </>
        }
      />
    </>
  );
};

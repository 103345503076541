export enum BinaryAnswer {
  YES = 'yes',
  NO = 'no',
}

export const BINARY_ANSWER_I18N: Record<BinaryAnswer, string> = {
  [BinaryAnswer.YES]: 'Да',
  [BinaryAnswer.NO]: 'Нет',
};

export type StepsState = {
  [k: number]: boolean;
};

import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';

export const paymentFormMuiTheme = createTheme({
  palette: {
    primary: { main: '#0F172A' },
    divider: '#E2E8F0',
    error: { main: '#EF4444' },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeLarge: {
          height: 48,
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: (props) => ({ color: props.theme.palette.primary.contrastText }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: '#E2E8F0',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(),
  },
});

import { PropsWithChildren } from 'react';
import { ToastProvider, useToast } from 'react-native-toast-notifications';
import { ToastOptions } from 'react-native-toast-notifications/lib/typescript/toast';

export function SnackbarProvider(props: PropsWithChildren<unknown>) {
  return <ToastProvider {...props} />;
}

export type IUseSnackbarShowOptions = Pick<ToastOptions, 'id' | 'type'>;
export interface IUseSnackbarResult {
  show: (message: string, options?: IUseSnackbarShowOptions) => string;
}

export function useSnackbar(): IUseSnackbarResult {
  const { show: showToast } = useToast();

  function show(message: string, options?: IUseSnackbarShowOptions) {
    return showToast(message, options);
  }

  return { show };
}

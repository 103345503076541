import AsyncStorage from '@react-native-async-storage/async-storage';

import { SELECTED_SHOP_ID, SELECTED_SHOP_ROLE } from '@iokanx/shared/data-access/constants';

import { getShopsSwitch } from '../../resources/shops/switch';

const SHOP_ACCESS_TOKEN_KEY = 'shop_access_token';

export async function switchShopResolver(shopId: string) {
  let shop_access_token = '';
  let shop_role = '';

  if (shopId) {
    const { access_token, role } = await getShopsSwitch({ shop_id: shopId });
    shop_access_token = access_token;
    shop_role = role;
  }
  await AsyncStorage.multiSet([
    [SHOP_ACCESS_TOKEN_KEY, shop_access_token],
    [SELECTED_SHOP_ID, shopId],
    [SELECTED_SHOP_ROLE, shop_role],
  ]);
}

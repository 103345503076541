import { backendHttp } from '../../../../instances';

import { IGetApiKeyByShopsIdResponseItem } from '.';

export interface IPostApiKeyByShopIdResponseData {
  shop_id: string;
  expire: number;
}

export async function createApiKeyByShopId(
  apiKey: IPostApiKeyByShopIdResponseData,
): Promise<IGetApiKeyByShopsIdResponseItem> {
  const { data } = await backendHttp.post(`/api-key`, apiKey);
  return data;
}

import moment from 'moment';

import { CURRENCY_SYMBOL, DATE_FORMAT } from '@iokanx/shared/data-access/constants';
import { compactObject } from '@iokanx/shared/util';

import {
  getPayouts,
  IGetPayoutByIdResponseData,
  IGetPayoutsRequestParams,
  IGetPayoutsResponseData,
} from '../../resources';

export type IFindPayoutsResolverResult = IGetPayoutsResponseData;

export interface IFindPayoutsRequestResolverParams extends IGetPayoutsRequestParams {
  /** Маска карты */
  payout_id?: string;
  external_id?: string;
}

export interface IFindPayoutResolverResultItem extends IGetPayoutByIdResponseData {
  createdAt: string;
  shopId: string;
  recipientName: string;
  recipientIban: string;
  externalId: string;
  amountWithSymbol: string;
}

export async function searchPayoutsResolver(params: IGetPayoutsRequestParams): Promise<IFindPayoutsResolverResult> {
  const payouts = await getPayouts(compactObject(params));
  const data = payouts.data.map(transformPayout);
  return { ...payouts, data };
}

function transformPayout(payout: IGetPayoutByIdResponseData): IFindPayoutResolverResultItem {
  const amount = payout.amount / 100;
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL[payout.currency]}`;
  return {
    ...payout,
    externalId: payout.external_id,
    shopId: payout.shop_id,
    recipientName: payout.recipient.name,
    recipientIban: payout.recipient.iban,
    amountWithSymbol: amountWithSymbol,
    createdAt: moment(payout.created_at).format(DATE_FORMAT),
  };
}

import AsyncStorage from '@react-native-async-storage/async-storage';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';

import { ACCESS_TOKEN_KEY, ShopUserRole } from '@iokanx/shared/data-access/constants';

import { authHttp } from '../../instances/auth-http';

export interface IGetShopsSwitchRequestParams {
  shop_id?: string;
}

export interface IGetShopsSwitchResponse {
  readonly access_token: string;
  readonly role: ShopUserRole;
}

export async function getShopsSwitch(params: IGetShopsSwitchRequestParams): Promise<IGetShopsSwitchResponse> {
  const accessToken = await AsyncStorage.getItem(ACCESS_TOKEN_KEY);

  const { data } = await authHttp.get<IGetShopsSwitchResponse>('/shops/switch', {
    params,
    skipAuthRefresh: true,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  } as AxiosAuthRefreshRequestConfig);
  return data;
}

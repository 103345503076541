import { LoadingButton } from '@mui/lab';
import { useTheme, Theme } from '@mui/material';
import { isFunction } from 'lodash';
import { MouseEventHandler } from 'react';

import { IButtonProps } from './utils';

import { Icon } from '../icon/icon';

const ICON_SIZE = 16;

export function Button(props: IButtonProps) {
  const theme = useTheme();

  const iconColor = getIconColor(props, theme);

  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation();

    if (props.onPress) {
      props.onPress();
    }
  };

  return (
    <LoadingButton
      onClick={handleClick}
      variant={props.mode}
      color={props.color}
      startIcon={
        isFunction(props.icon) ? (
          props.icon({ size: ICON_SIZE, color: iconColor })
        ) : (
          <Icon color={iconColor} size={ICON_SIZE} {...props.icon} />
        )
      }
      loading={props.loading}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      sx={{
        textTransform: props.uppercase ?? true ? 'uppercase' : 'none',
        borderRadius: '12px',
        borderColor: '#e2e8f0',
      }}
      size={props.compact ? 'small' : 'medium'}
    >
      {props.children}
    </LoadingButton>
  );
}

function getIconColor(props: IButtonProps, theme: Theme) {
  switch (props.mode) {
    case 'outlined':
      if (props.color && props.color !== 'inherit') {
        return theme.palette[props.color].main;
      }
      return theme.palette.primary.main;
    default:
    case 'contained':
      return theme.palette.primary.contrastText;
  }
}

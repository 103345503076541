import { styled } from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { forwardRef } from 'react';
import PhoneInput, { Labels } from 'react-phone-number-input';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from 'react-phone-number-input/locale/en';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ru from 'react-phone-number-input/locale/ru';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import tr from 'react-phone-number-input/locale/tr';

import { getClientLanguage } from '@iokanx/shared/util';

import { IStyledTextFieldProps, StyledTextField } from '../styled-text-field/styled-text-field';

const labels: Record<string, Labels> = { ru, en, tr };

const StyledInput = styled(
  forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) => (
    <PhoneInput
      defaultCountry="KZ"
      international
      countryCallingCodeEditable={false}
      {...props}
      labels={labels[getClientLanguage()]}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      onChange={(value) =>
        props.onChange?.({
          target:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            { name: props.name, value },
        })
      }
    />
  )),
)({
  '& input': {
    outline: 'none',
  },
});

export function PhoneField(props: IStyledTextFieldProps) {
  return (
    <StyledTextField InputProps={{ inputComponent: StyledInput }} autoComplete={'tel'} inputMode={'tel'} {...props} />
  );
}

import { http } from '../../instances';

export interface IGetNpsExportParams {
  to_dt?: Date;
  from_dt?: Date;
  type_nps?: string;
  email?: string;
}

export async function getNpsExport(params: IGetNpsExportParams): Promise<Blob> {
  const { data } = await http.get('/support/nps/export', { params, responseType: 'blob' });
  return data;
}

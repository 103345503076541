import { GridColumns, ruRU } from '@mui/x-data-grid';

import { IGetUsersResponseDataItem } from '@iokanx/shared/data-access/api';

import { UserUpdateStatus } from '../user-update-status/user-update-status';

export const columns: GridColumns<IGetUsersResponseDataItem> = [
  {
    headerName: 'Имя',
    field: 'first_name',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'Фамилия',
    field: 'last_name',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'Телефон',
    field: 'phone',
    flex: 0.3,
    sortable: false,
  },
  {
    headerName: 'Статус',
    field: 'status',
    flex: 0.3,
    sortable: false,
    renderCell: (props) => <UserUpdateStatus user={props.row} />,
  },
];

export const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам пользователей не найдено',
};

import { Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { getUsersResolver } from '@iokanx/shared/data-access/api';

import { columns, localeText } from './constants';

import { useMe } from '../auth';
import { Filter } from '../user-list/Filter';
import { IUserListProps } from '../user-list/user-list';

export const UsersListDesktop: FC<IUserListProps> = ({ form, onShopPress, pageLimit }) => {
  const { me } = useMe();

  const { data: users, isFetching } = useQuery(['users', form.watch()], () =>
    getUsersResolver({ ...form.watch(), limit: pageLimit }),
  );

  const handleRowClick = (params: any) => onShopPress(params.row);

  const currentPage = form.watch('page') - 1;

  const handlePageChange = (page: number) => form.setValue('page', page + 1);

  return (
    <Stack padding={2} spacing={2}>
      <Filter form={form} />
      <DataGrid
        rows={users?.data || []}
        rowCount={users?.count}
        onRowClick={handleRowClick}
        columns={columns}
        columnVisibilityModel={{ shopId: Boolean(me?.is_superuser) }}
        page={currentPage}
        onPageChange={handlePageChange}
        pageSize={pageLimit}
        rowsPerPageOptions={[pageLimit]}
        loading={isFetching}
        localeText={localeText}
        paginationMode="server"
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
      />
    </Stack>
  );
};

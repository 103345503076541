import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

export const StyledButton = styled(LoadingButton)((props) => ({
  ...(props.variant === 'outlined' && {
    fontWeight: 400,
    fontSize: 14,
    borderColor: props.theme.palette.divider,
    '& .MuiLoadingButton-loadingIndicator': {
      color: props.theme.palette.primary.main,
    },
  }),
}));

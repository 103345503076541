import { CaptureMethod, Currency } from '@iokanx/shared/data-access/constants';

import { IGetOrderByIdResponseData } from './by-id';

import { backendHttp } from '../../instances';

export interface IPostOrdersRequestData {
  /**
   *   Стоимость предлагаемых товаров или услуг в минорных денежных единицах.
   *
   *   Например, для создания заказа на сумму 500 тенге, необходимо ввести 50000 (1 тенге = 100 тиын)
   *
   *   @moreOrEqual 100
   */
  amount: number;
  // Доступные валюты для проведения платежа
  currency?: Currency;
  // Способ списания оплаты
  capture_method?: CaptureMethod;
  /**
   * Внешний идентификатор заказа
   *
   * @nonEmpty
   */
  external_id?: string;
  // Описание заказа
  description?: string;
  // Дополнительные данные, связанные с заказом
  extra_info?: Record<string, unknown>;
  /**
   * Количество разрешенных попыток оплаты для заказа.
   *
   * @default 10
   * [ 1 .. 50 ]
   */
  attempts?: number;
  // Срок действия заказа
  due_date?: string;
  /**
   * Идентификатор плательщика.
   *
   * Для оплаты по сохраненной карте через форму оплаты с подтверждением CVC.
   *
   * @nonEmpty
   */
  customer_id?: string;
  /**
   * Идентификатор сохраненной карты плательщика.
   *
   * Для оплаты по сохраненной карте через форму оплаты с подтверждением CVC.
   *
   * @nonEmpty
   */
  card_id?: string;
  /**
   * Ссылка, служащая для перенаправления клиента на сайт мерчанта после проведения оплаты
   *
   * @uri
   * [ 1 .. 2083 ] characters
   */
  back_url?: string;
  /**
   * Ссылка, служащая для перенаправления клиента на сайт мерчанта после успешного проведения оплаты
   *
   * @uri
   * [ 1 .. 2083 ] characters
   */
  success_url?: string;
  /**
   * Ссылка, служащая для перенаправления клиента на сайт мерчанта после неуспешного проведения оплаты
   *
   * @uri
   * [ 1 .. 2083 ] characters
   */
  failure_url?: string;
  // Шаблон формы оплаты
  template?: string;
}

export interface IPostOrdersResponseData {
  // Информация по созданному заказу
  order: IGetOrderByIdResponseData;
  //  Токен доступа к заказу
  order_access_token: string;
}

// Создание и управление заказами.
export async function postOrders(requestData: IPostOrdersRequestData): Promise<Readonly<IPostOrdersResponseData>> {
  const { data } = await backendHttp.post<IPostOrdersResponseData>('/orders', requestData);
  return data;
}

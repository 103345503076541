import { Tiles } from '@mobily/stacks';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { ComponentType, useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { Platform } from 'react-native';

import { exportRefundsResolver, ISearchRefundsResolverParams } from '@iokanx/shared/data-access/api';
import { DATE_FORMAT } from '@iokanx/shared/data-access/constants';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { DateRangePicker, ISelectItem, Select, TextField, Button } from '@iokanx/shared/ui/universal';

import { saveFile } from '../file-saver/file-saver';
import { RefundListDesktop } from '../refund-list-desktop/refund-list-desktop';
import { RefundListMobile } from '../refund-list-mobile/refund-list-mobile';

export enum SearchRefundsParam {
  /** Идентификатор заказа */
  OrderId = 'order_id',
  /** Идентификатор платежа */
  PaymentId = 'payment_id',
}

export interface IRefundListFormFieldValues extends Pick<ISearchRefundsResolverParams, SearchRefundsParam> {
  page: NonNullable<ISearchRefundsResolverParams['page']>;
  from_dt: ISearchRefundsResolverParams['from_dt'];
  to_dt: ISearchRefundsResolverParams['to_dt'];
}

const searchRefundsParamI18n: Record<SearchRefundsParam, string> = {
  [SearchRefundsParam.OrderId]: 'Идентификатор заказа',
  [SearchRefundsParam.PaymentId]: 'Идентификатор платежа',
};
const searchRefundsParamItems: ISelectItem<SearchRefundsParam>[] = Object.values(SearchRefundsParam).map((value) => ({
  title: searchRefundsParamI18n[value],
  value,
}));

const REFUND_LIST_LIMIT = 10;

export interface IRefundListProps {
  filter: JSX.Element;
  form: UseFormReturn<IRefundListFormFieldValues>;
  limit: number;
}

const RESPONSIVE_REFUND_LIST: Record<Breakpoint, ComponentType<IRefundListProps>> = {
  [Breakpoint.Desktop]: RefundListDesktop,
  [Breakpoint.Mobile]: RefundListMobile,
};

export function RefundList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  const form = useForm<IRefundListFormFieldValues>({
    defaultValues: {
      page: 1,
      from_dt: moment().subtract(1, 'month').startOf('day').toDate(),
      to_dt: moment().endOf('day').toDate(),
      payment_id: '',
      order_id: '',
    },
  });

  const [searchRefundsParam, setSearchRefundsParam] = useState(SearchRefundsParam.OrderId);
  function handleChangeSearchRefundsParam(nextSearchRefundsParam: SearchRefundsParam) {
    form.reset();
    setSearchRefundsParam(nextSearchRefundsParam);
  }

  const { mutate: exportRefunds, isLoading: isExporting } = useMutation(exportRefundsResolver, {
    onSuccess: (data, variables) => {
      const startDate = moment(variables.from_dt).format(DATE_FORMAT);
      const endDate = moment(variables.to_dt).format(DATE_FORMAT);

      return saveFile({ data, fileName: `refunds_${startDate}-${endDate}.xlsx` });
    },
  });

  const filter = (
    <Tiles columns={[1, 4]} space={2}>
      <Select
        onChange={handleChangeSearchRefundsParam}
        value={searchRefundsParam}
        label={'Поиск по'}
        items={searchRefundsParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label={searchRefundsParamI18n[searchRefundsParam]}
          />
        )}
        name={searchRefundsParam}
      />
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          form.setValue('from_dt', values.startDate);
          form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
      {Platform.OS === 'web' && (
        <Button icon={{ name: 'file-excel' }} onPress={() => exportRefunds(form.watch())} loading={isExporting}>
          Выгрузить
        </Button>
      )}
    </Tiles>
  );

  const ResponsiveRefundList = RESPONSIVE_REFUND_LIST[currentBreakpoint];

  return <ResponsiveRefundList form={form} limit={REFUND_LIST_LIMIT} filter={filter} />;
}

import { Chip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useQuery } from '@tanstack/react-query';
import { ScrollView } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { DashboardStackParamList } from '@iokanx/dashboard/feature';
import { getVoteByIdResolver } from '@iokanx/shared/data-access/api';
import { wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Divider, List } from '@iokanx/shared/ui/universal';

import { NPS_GRADE_I18N } from '../../../../feature/src/lib/nps-modal/constants';

export type IVotePageProps = NativeStackScreenProps<DashboardStackParamList, 'Vote'>;

const Vote = (props: IVotePageProps) => {
  const { data: vote } = useQuery(['vote', props.route.params], () => getVoteByIdResolver(props.route.params));

  if (!vote) return null;

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  return (
    <ScrollView>
      <List.Section title="Информация">
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Дата голосования</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{vote.voteAt}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Почта</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{vote.email}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Комментарий</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{vote.comment}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Оценка</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              {vote.grade} - {NPS_GRADE_I18N[vote.grade]}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className="infoTitle">Теги</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              {vote.tags &&
                vote.tags.map((item) => {
                  return <Chip label={item} variant="outlined" style={{ margin: '5px' }} />;
                })}
            </Item>
          </Grid>
        </Grid>
      </List.Section>

      <Divider />
    </ScrollView>
  );
};

export default wrapPage(
  wrapModalPage(Vote, {
    position: 'Right',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'Vote'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Nps', params: { screen: 'Votes' } });
        navigation.push('Vote', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

import { Box } from '@mobily/stacks';
import { FlatList, StyleSheet } from 'react-native';

import { EmployeeCard } from '../employee-card/employee-card';
import { IEmployeeListProps } from '../employee-list/employee-list';

export function EmployeeListMobile(props: IEmployeeListProps) {
  return (
    <FlatList
      data={props.query.data}
      renderItem={(info) => <EmployeeCard employee={info.item} />}
      onRefresh={props.query.refetch}
      refreshing={props.query.isRefetching}
      ListHeaderComponent={props.header}
      contentContainerStyle={styles.root}
      ItemSeparatorComponent={ItemSeparatorComponent}
    />
  );
}

function ItemSeparatorComponent() {
  return <Box padding={1} />;
}

const styles = StyleSheet.create({
  root: {
    padding: 8,
  },
});

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { PropsWithChildren, useEffect, useState } from 'react';

import { Icon } from '../icon/icon';

export interface IAccordionProps {
  title?: string;
  disabled?: boolean;
  isExpanded?: boolean;
}

export function Accordion(props: PropsWithChildren<IAccordionProps>) {
  const [isExpanded, setIsExpanded] = useState(!props.disabled ?? props.isExpanded ?? false);

  useEffect(() => {
    if (props.disabled) {
      setIsExpanded(false);
    }
  }, [props.disabled]);

  return (
    <MuiAccordion
      disabled={props.disabled}
      expanded={isExpanded}
      onChange={(event, expanded) => setIsExpanded(expanded)}
    >
      <MuiAccordionSummary expandIcon={<Icon name="chevron-down" size={16} />}>{props.title}</MuiAccordionSummary>
      <MuiAccordionDetails>{props.children}</MuiAccordionDetails>
    </MuiAccordion>
  );
}

import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { IntermediariesForm } from './IntermediariesForm';
import { Intermediary } from './Intermediary';

import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { ConditionalComponent } from '../../ConditionalComponent';
import { ProfileFormStep } from '../../layouts/ProfileFormStep';
import { Item } from '../../ProfileForm';
import { IFormSection } from '../OrganizationInfoStep/types';

const filterIntermediariesShare = (shares: any[]) => shares?.filter((intermediary) => intermediary.is_intermediary);

export const IntermediariesStep: FC<IFormSection> = (props) => {
  const { organizationId, isSubmitted } = useProfileFormContext();

  const [isMore, setIsMore] = useState(false);
  const [intermediaries, setIntermediaries] = useState<any[]>([]);

  const { data: shares } = useQuery(['shares'], async () => {
    try {
      const { data } = await backendHttp.get(`organizations/${organizationId}/shares`);

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  });

  const handleFormDisplay = () => {
    setIsMore((isMore) => !isMore);
  };

  useEffect(() => {
    const filteredShares = filterIntermediariesShare(shares);

    setIntermediaries(filteredShares);

    setIsMore(false);
  }, [shares]);

  return (
    <ProfileFormStep {...props}>
      {isEmpty(intermediaries) ? (
        <IntermediariesForm
          isEmpty={isEmpty(intermediaries)}
          isSubmitted={isSubmitted}
          organizationId={organizationId}
          form={false}
        />
      ) : (
        <>
          {intermediaries?.map((intermediary, index) => {
            return (
              <Intermediary
                isSubmitted={isSubmitted}
                organizationId={organizationId}
                intermediary={intermediary}
                key={index}
              />
            );
          })}

          <ConditionalComponent
            condition={!isSubmitted}
            component={
              <Item>
                <Button sx={{ minWidth: '180px' }} fullWidth={false} onClick={handleFormDisplay}>
                  {isMore ? 'Скрыть' : 'Добавить'}
                </Button>
              </Item>
            }
          />

          <ConditionalComponent
            condition={isMore}
            component={<IntermediariesForm isSubmitted={isSubmitted} organizationId={organizationId} form={true} />}
          />
        </>
      )}
    </ProfileFormStep>
  );
};

import moment from 'moment/moment';

import { PayerType } from '@iokanx/shared/data-access/constants';

import {
  getAnalyticsPaymentMethods,
  IGetAnalyticsPaymentMethodsRequestParams,
  IGetAnalyticsPaymentMethodsResponseData,
} from '../../resources';

export interface IFindPaymentMethodsAnalyticsResolverResultItem {
  paymentMethod: PaymentMethod;
  count: number;
  title: string;
  color: string;
}

enum PaymentMethod {
  CARD = 'CARD',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
}

const PAYMENT_METHOD_I18N: Record<PaymentMethod, string> = {
  [PaymentMethod.CARD]: 'Банковские карты',
  [PaymentMethod.APPLE_PAY]: 'Apple Pay',
  [PaymentMethod.GOOGLE_PAY]: 'Google Pay',
};

const PAYMENT_METHOD_COLORS: Record<PaymentMethod, string> = {
  [PaymentMethod.CARD]: 'rgb(240,190,65)',
  [PaymentMethod.APPLE_PAY]: 'rgb(8,3,5)',
  [PaymentMethod.GOOGLE_PAY]: 'rgb(112,122,234)',
};

export async function findPaymentMethodsAnalytics(
  _params: IGetAnalyticsPaymentMethodsRequestParams,
): Promise<IFindPaymentMethodsAnalyticsResolverResultItem[]> {
  const params = { ..._params };
  if (params.from_dt && !params.to_dt) {
    params.to_dt = moment(params.from_dt).endOf('day').toDate();
  }

  const data = await getAnalyticsPaymentMethods(params);
  return preparePaymentMethodsAnalytics(data);
}

function preparePaymentMethodsAnalytics(
  data: IGetAnalyticsPaymentMethodsResponseData,
): IFindPaymentMethodsAnalyticsResolverResultItem[] {
  const applePayCount = data.find(([paymentMethod]) => paymentMethod === PayerType.APPLE_PAY)?.[1] ?? 0;
  const googlePayCount = data.find(([paymentMethod]) => paymentMethod === PayerType.GOOGLE_PAY)?.[1] ?? 0;
  const cardCount = data
    .filter(([paymentMethod]) =>
      [PayerType.CARD, PayerType.CARD_NO_CVC, PayerType.CARD_WITH_BINDING, PayerType.BINDING].includes(paymentMethod),
    )
    .reduce((acc, [, count]) => acc + count, 0);

  const stats = [
    {
      paymentMethod: PaymentMethod.CARD,
      count: cardCount,
      title: PAYMENT_METHOD_I18N[PaymentMethod.CARD],
      color: PAYMENT_METHOD_COLORS[PaymentMethod.CARD],
    },
    {
      paymentMethod: PaymentMethod.APPLE_PAY,
      count: applePayCount,
      title: PAYMENT_METHOD_I18N[PaymentMethod.APPLE_PAY],
      color: PAYMENT_METHOD_COLORS[PaymentMethod.APPLE_PAY],
    },
    {
      paymentMethod: PaymentMethod.GOOGLE_PAY,
      count: googlePayCount,
      title: PAYMENT_METHOD_I18N[PaymentMethod.GOOGLE_PAY],
      color: PAYMENT_METHOD_COLORS[PaymentMethod.GOOGLE_PAY],
    },
  ];

  return stats;
}

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mobily/stacks';
import { Link } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { Colors } from 'react-native-paper';
import * as yup from 'yup';

import { IPasswordRecoveryResolverParams, passwordRecoveryResolver } from '@iokanx/shared/data-access/api';
import { DeviceSize } from '@iokanx/shared/data-access/constants';
import { SimpleCard, Card, Caption, Title, EmailField, Button } from '@iokanx/shared/ui/universal';

import { DashboardStackParamList } from '../navigation';

type IPasswordRecoveryFormFieldValues = IPasswordRecoveryResolverParams;

const passwordRecoveryFormSchema: yup.SchemaOf<IPasswordRecoveryFormFieldValues> = yup.object({
  email: yup.string().email('Email должен быть таким user@domain.com').required('Обязательное поле'),
});

export function PasswordRecoveryForm() {
  const { control, handleSubmit } = useForm<IPasswordRecoveryFormFieldValues>({
    defaultValues: { email: '' },
    resolver: yupResolver(passwordRecoveryFormSchema),
  });

  const {
    mutate: passwordRecovery,
    isLoading: isPasswordRecovering,
    isSuccess: isSuccessPasswordRecovered,
    reset: resetPasswordRecoveryMutation,
  } = useMutation(passwordRecoveryResolver);
  const onSubmit: SubmitHandler<IPasswordRecoveryFormFieldValues> = (data) => passwordRecovery(data);

  if (isSuccessPasswordRecovered) {
    return (
      <Box>
        <Stack space={2} align={'center'}>
          <MaterialCommunityIcons name="email-check" size={80} color={Colors.green500} />
          <Title>Письмо отправлено</Title>
          <Caption style={styles.successPasswordRecoveredCaption}>
            {'Перейдите по ссылке в письме для восстановления доступа.\n' +
              'Не забудьте проверить папку со Спамом.\n' +
              'Если ничего не пришло — попробуйте отправить письмо еще раз'}
          </Caption>
          <Button onPress={resetPasswordRecoveryMutation}>Отправить еще раз</Button>
          <Link<DashboardStackParamList> to={{ screen: 'SignIn' }} style={styles.signInLink}>
            Войти
          </Link>
        </Stack>
      </Box>
    );
  }

  return (
    <SimpleCard
      title={'Восстановление пароля'}
      subtitle={'Введите почту, на которую будет отправлено письмо для восстановления пароля'}
      subtitleNumberOfLines={0}
      cardStyle={styles.card}
    >
      <Card.Content>
        <Stack marginTop={2}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EmailField
                label={'Почта'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                helperText={error?.message}
                error={Boolean(error)}
              />
            )}
            name={'email'}
          />
        </Stack>
      </Card.Content>
      <Card.Actions>
        <Stack padding={2} space={2} align={'center'}>
          <Button onPress={handleSubmit(onSubmit)} loading={isPasswordRecovering} fullWidth>
            Отправить
          </Button>
          <Stack horizontal>
            <Link<DashboardStackParamList> to={{ screen: 'SignIn' }}>
              <Caption>Вспомнили пароль?</Caption> Войти
            </Link>
          </Stack>
        </Stack>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  card: {
    width: '100%',
    maxWidth: DeviceSize.LargeMobile,
  },
  signInLink: {
    textAlign: 'center',
  },
  successPasswordRecoveredCaption: {
    textAlign: 'center',
  },
});

import {
  useFonts,
  Roboto_100Thin,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
} from '@expo-google-fonts/roboto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import ru from 'date-fns/locale/ru';
import AppLoading from 'expo-app-loading';
import { StatusBar } from 'expo-status-bar';
import moment from 'moment';
import { createContext, PropsWithChildren } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Appearance } from 'react-native';
import 'react-datepicker/dist/react-datepicker.css';

import { getConfigResolver, IConfigResponseData } from '@iokanx/shared/data-access/api';
import { locale } from '@iokanx/shared/data-access/constants';
import { getEnv } from '@iokanx/shared/util';

moment.locale(locale);
registerLocale('ru', ru);
setDefaultLocale(locale);

export type IConfigContextValue = Pick<UseQueryResult, 'isLoading' | 'isSuccess' | 'isError'>;

const ConfigContext = createContext<IConfigContextValue>({ isLoading: true, isSuccess: false, isError: false });

const colorScheme = Appearance.getColorScheme();

export function ConfigProvider(props: PropsWithChildren<unknown>) {
  const { mutate: setConfig } = useMutation((variables: IConfigResponseData) =>
    AsyncStorage.setItem('config', JSON.stringify(variables)),
  );

  const { isLoading, isSuccess, isError } = useQuery(
    ['config'],
    () => getConfigResolver({ ownConfig: getEnv('apiUrl') }),
    {
      onSuccess: (data) => setConfig(data),
    },
  );

  useFonts({
    Roboto_100Thin,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
  });

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <ConfigContext.Provider value={{ isLoading, isSuccess, isError }}>
      <StatusBar style={colorScheme === 'dark' ? 'dark' : 'auto'} />
      {props.children}
    </ConfigContext.Provider>
  );
}

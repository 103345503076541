import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Item } from '@iokanx/dashboard/feature';
import { IFindShopIdResult, updateShopByIdResolver } from '@iokanx/shared/data-access/api';
import { SHOP_STATUS_LIST, SHOP_STATUS_TITLE, ShopStatus } from '@iokanx/shared/data-access/constants';
import { useSnackbar } from '@iokanx/shared/feature';
import { Button, ISelectItem, List, Select, TextField } from '@iokanx/shared/ui/universal';

export interface IShopInfoProps {
  shop: IFindShopIdResult | undefined;
  is_superuser?: boolean;
}

const validationSchema = yup.object({
  name: yup.string().nullable(true),
  display_name: yup.string().nullable(true),
  mcc: yup.string().nullable(true),
  owner: yup.string().nullable(true),
  address: yup.string().nullable(true),
  // registration_type: ,
  iban: yup.string().nullable(true),
  bank_id: yup.string().nullable(true),
  bank_name: yup.string().nullable(true),

  phone: yup.string().nullable(true),
  email: yup.string().email().nullable(true),
  site: yup.string().nullable(true),
  status: yup.string().nullable(true),
});

export default function ShopInfo(props: IShopInfoProps) {
  const snackbar = useSnackbar();
  const { shop, is_superuser } = props;

  const { mutate: updateShop, isLoading: isUpdating } = useMutation(
    ({ shopId, data }: any) => updateShopByIdResolver(shopId, data),
    {
      onSuccess: () => snackbar.show('Обновлено', { type: 'success' }),
    },
  );

  const { control, handleSubmit, watch, setValue } = useForm<any>({
    defaultValues: {
      name: shop?.name ? shop.name : undefined,
      display_name: shop?.display_name ? shop.display_name : undefined,
      mcc: shop?.mcc ? shop.mcc : undefined,
      status: shop?.status ? shop.status : undefined,
      owner: shop?.owner ? shop.owner : undefined,
      address: shop?.address ? shop.address : undefined,
      iban: shop?.iban ? shop.iban : undefined,
      bank_id: shop?.bank_id ? shop.bank_id : undefined,
      bank_name: shop?.bank_name ? shop.bank_name : undefined,
      phone: shop?.phone ? shop.phone : undefined,
      email: shop?.email ? shop.email : undefined,
      site: shop?.site ? shop.site : undefined,
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  if (!shop) return null;

  const statusItems: ISelectItem<ShopStatus>[] = SHOP_STATUS_LIST.map((value) => ({
    title: SHOP_STATUS_TITLE[value],
    value: value,
  }));

  const onSubmit: SubmitHandler<IFindShopIdResult> = (data) => {
    updateShop({ shopId: shop.id, data });
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  return (
    <Grid container>
      <Grid item>
        <List.Section title={'Данные'}>
          <Grid container>
            <Grid item xs={6}>
              <Item className="infoTitle">Статус</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <Select
                      fullWidth={true}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      items={statusItems}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'status'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Юр. наименование</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'name'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">MCC</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'mcc'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Наименование в форме</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'display_name'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">ФИО владельца</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'owner'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Адрес</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'address'}
                />
              </Item>
            </Grid>
          </Grid>
        </List.Section>

        <List.Section title={'Реквизиты'}>
          <Grid container>
            <Grid item xs={6}>
              <Item className="infoTitle">Банк</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={true}
                    />
                  )}
                  name={'bank_id'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Название банка</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={true}
                    />
                  )}
                  name={'bank_name'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">IBAN</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'iban'}
                />
              </Item>
            </Grid>
          </Grid>
        </List.Section>

        <List.Section title={'Контакты'}>
          <Grid container>
            <Grid item xs={6}>
              <Item className="infoTitle">Номер телефона</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'phone'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Email</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'email'}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className="infoTitle">Сайт</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Controller
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      onBlur={field.onBlur}
                      onChangeText={field.onChange}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      disabled={!is_superuser}
                    />
                  )}
                  name={'site'}
                />
              </Item>
            </Grid>
          </Grid>
        </List.Section>

        {is_superuser && (
          <List.Section title={''}>
            <Grid container>
              <Grid item xs={12}>
                <Item className="infoTitle">
                  <Button icon={{ name: 'receipt' }} onPress={handleSubmit(onSubmit)} loading={isUpdating} fullWidth>
                    Обновить
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </List.Section>
        )}
      </Grid>
    </Grid>
  );
}

import { TextInput } from 'react-native-paper';
import { TextInputProps } from 'react-native-paper/lib/typescript/components/TextInput/TextInput';

import { HelperText } from '../helper-text/helper-text';

export interface IInputProps extends Omit<TextInputProps, 'error' | 'theme'> {
  error?: string;
  helperText?: string;
}

export function Input({ error, helperText, ...inputProps }: IInputProps) {
  const isError = Boolean(error);

  const helperTextType = isError ? 'error' : 'info';
  const helperTextContent = error || helperText;
  const helperTextVisible = Boolean(helperText);

  return (
    <>
      <TextInput mode={'outlined'} dense error={isError} {...inputProps} />
      <HelperText type={helperTextType} visible={helperTextVisible}>
        {helperTextContent}
      </HelperText>
    </>
  );
}

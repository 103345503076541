import { ShopStatus } from '@iokanx/shared/data-access/constants';

import { http } from '../../../instances';

export interface IGetDashboardShopsRequestParams {
  /**
   * Номер запрашиваемой страницы
   *
   * @default 1
   * @moreOrEqual 1
   */
  page?: number;

  /**
   * Количество запрашиваемых строк
   *
   * @default 10
   * @moreOrEqual 1
   */
  limit?: number;

  /** Конец временного отрезка */
  to_dt?: Date;

  /** Начало временного отрезка */
  from_dt?: Date;
}

export interface IGetDashboardShopsResponseDataItem {
  /** Идентификатор магазина */
  id: string;
  name: string;
  bin: string;
  iban: string;
  external_id?: string;
  email?: string;
  phone?: string;
  created_at: string;
  status: ShopStatus;
}
export interface IGetDashboardShopsResponseData {
  /**
   * Количество магазинов
   *
   * @moreOrEqual 0
   */
  count: number;

  /** Список магазинов */
  data: IGetDashboardShopsResponseDataItem[];
}

/** Поиск магазинов по фильтрам */
export async function getDashboardShops(
  params: IGetDashboardShopsRequestParams,
): Promise<IGetDashboardShopsResponseData> {
  const { data } = await http.get<IGetDashboardShopsResponseData>(`/shops`, {
    params,
  });
  return data;
}

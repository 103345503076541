export const messages = {
  default: {
    title: 'Какова вероятность того, что Вы порекомендуете ioka?',
  },
  good: {
    title: 'Что Вы цените в наших продуктах?',
  },
  medium: {
    title: 'Что мы можем улучшить?',
  },
  bad: {
    title: 'Что не оправдывает ожиданий?',
  },
};

export const ZERO_INDEX = 0;
export const RATING_LENGTH = 11;
export const RATING_HALF_INDEX = 6;

export const NPS_GRADE_I18N: Record<number, string> = {
  1: 'Критик',
  2: 'Критик',
  3: 'Критик',
  4: 'Критик',
  5: 'Критик',
  6: 'Критик',
  7: 'Нейтрал',
  8: 'Нейтрал',
  9: 'Сторонник',
  10: 'Сторонник',
};

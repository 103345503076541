import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { findConstructorByShopIdResolver, IFindShopIdResult } from '@iokanx/shared/data-access/api';
import { IConstructor } from '@iokanx/shared/data-access/types';
import { paymentFormMuiTheme } from '@iokanx/shared/util';

import { ConstructorForm } from '../constructor-form/constructor-form';
import { OrderPaymentFormPreview } from '../order-payment-form-preview/order-payment-form-preview';

export interface IConstructorProps {
  shop: IFindShopIdResult;
}

export type IConstructorFormFieldValues = Omit<IConstructor, 'shopId'>;

export const CONSTRUCTOR_FORM_DEFAULT_VALUES: IConstructorFormFieldValues = {
  palette: {
    primary: {
      main: paymentFormMuiTheme.palette.primary.main,
    },
  },
  typography: {
    header: '',
    main: '',
  },
};

const validationSchema = yup.object({
  typography: yup.object({
    header: yup.string().max(50, 'Максимальная длина 50 символов'),
    main: yup.string().max(100, 'Максимальная длина 100 символов'),
  }),
});

export function Constructor(props: IConstructorProps) {
  const findConstructorByShopIdQuery = useQuery(['constructor', props.shop.id], () =>
    findConstructorByShopIdResolver({ shopId: props.shop.id }),
  );

  const constructorForm = useForm<IConstructorFormFieldValues>({
    defaultValues: deepmerge(CONSTRUCTOR_FORM_DEFAULT_VALUES, findConstructorByShopIdQuery.data),
    resolver: yupResolver(validationSchema),
  });

  const theme = deepmerge(paymentFormMuiTheme, constructorForm.watch());

  return (
    <Stack spacing={2}>
      <ConstructorForm constructorForm={constructorForm} shopId={props.shop.id} theme={theme} />
      <OrderPaymentFormPreview shop={props.shop} theme={theme} typography={constructorForm.watch('typography')} />
    </Stack>
  );
}

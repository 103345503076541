import { Menu, MenuProps, styled } from '@mui/material';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#EDEEF1',
  },
}));

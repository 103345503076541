export enum RefundStatus {
  // Ожидает подтверждения возврата
  Pending = 'PENDING',
  // Возврат подтвержден
  Approved = 'APPROVED',
  // Попытка возврата неуспешна (отказана эквайером, эмитентом, карта невалидна и тп.)
  Declined = 'DECLINED',
}

export const REFUND_STATUS_I18N: Record<RefundStatus, string> = {
  [RefundStatus.Pending]: 'В обработке',
  [RefundStatus.Approved]: 'Авторизован',
  [RefundStatus.Declined]: 'Отклонен',
};

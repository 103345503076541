import { Stack } from '@mobily/stacks';
import Grid from '@mui/material/Grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { cancelPaymentResolver, IFindPaymentByIdResult } from '@iokanx/shared/data-access/api';
import { useSnackbar } from '@iokanx/shared/feature';
import { Portal, Dialog, List, ListItem, SimpleDialog, Button } from '@iokanx/shared/ui/universal';

export interface ICancelPaymentProps {
  payment: IFindPaymentByIdResult;
}

export function CancelPayment(props: ICancelPaymentProps) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const [isVisibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  function handleShowConfirmDialog() {
    setVisibleConfirmDialog(true);
  }
  function handleDismissConfirmDialog() {
    setVisibleConfirmDialog(false);
  }

  const { mutate: cancelPayment } = useMutation(cancelPaymentResolver, {
    onSuccess: () => {
      snackbar.show('Принято', { type: 'success' });
      handleDismissConfirmDialog();
      return queryClient.invalidateQueries(['payment']);
    },
    onError: () => {
      snackbar.show('Ошибка при отмене платежа', { type: 'error' });
    },
  });
  function handleCancelPayment() {
    cancelPayment({ orderId: props.payment.order_id });
  }

  return (
    <>
      <Button mode={'outlined'} onPress={handleShowConfirmDialog}>
        Отменить платеж
      </Button>
      <Portal>
        <SimpleDialog visible={isVisibleConfirmDialog} onDismiss={handleDismissConfirmDialog}>
          <Dialog.Title>Вы действительно хотите отменить платеж?</Dialog.Title>
          <Dialog.Content>
            <List.Section>
              <ListItem title={'Номер заказа'} description={props.payment.order_id} />
              <ListItem title={'Сумма'} description={props.payment.approvedAmountWithSymbol} />
              <ListItem title={'Оплачен'} description={props.payment.createdAt} />
            </List.Section>
          </Dialog.Content>
          <Dialog.Actions>
            <Stack horizontal={[false, true]} padding={4} space={4}>
              <Button onPress={handleCancelPayment}>Отменить платеж</Button>
              <Button onPress={handleDismissConfirmDialog} mode={'outlined'}>
                Закрыть
              </Button>
            </Stack>
          </Dialog.Actions>
        </SimpleDialog>
      </Portal>
    </>
  );
}

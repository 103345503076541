import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { backendHttp } from '@iokanx/shared/data-access/api';
import { IHttpError } from '@iokanx/shared/data-access/types';

import { patchData, postData } from './OrganizationContactForm';

import { useFormEdit } from '../../../../../helpers/useFormEdit';
import { useProfileFormContext } from '../../../../../providers/ProfileFormProvider';
import { FormField } from '../../../../FormField/FormField';
import { ProfileFormSection } from '../../../../layouts/ProfileFormSection';
import { filterContact } from '../../helpers';

interface IOrganizationContactFormItem {
  type: string;
  label: string;
  updateChildrenCompletionStatus: any;
}

export const OrganizationContactFormItem = ({
  type,
  label,
  updateChildrenCompletionStatus,
}: IOrganizationContactFormItem) => {
  const { organizationId, isSubmitted } = useProfileFormContext();

  const { data: contacts } = useQuery(['organization', organizationId, 'contacts'], async () => {
    const { data } = await backendHttp.get(`/organizations/${organizationId}/contacts`);
    return data;
  });

  const { control, handleSubmit } = useForm({
    defaultValues: filterContact(contacts, type),
  });

  const onSubmit = (data: any) => saveContacts(data);
  const onError = (err: any) => console.error(err);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(
    Boolean(filterContact(contacts, type)),
    handleSubmit(onSubmit, onError),
  );

  const { mutate: saveContacts } = useMutation<any, AxiosError<IHttpError>, any>(
    filterContact(contacts, type)
      ? (formData: any) => patchData(formData, type, organizationId, formData.id)
      : (formData: any) => postData(formData, type, organizationId),
    {
      onSuccess: () => {
        handleIsEdit();
      },
    },
  );

  useEffect(() => {
    updateChildrenCompletionStatus(label, isDisable);
  }, [isDisable]);

  return (
    <ProfileFormSection isSubmitted={isSubmitted} handleSubmit={handlePress} isSaved={isDisable}>
      <FormField name={'value'} control={control} label={label} disabled={isDisable} />
    </ProfileFormSection>
  );
};

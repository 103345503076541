import { ComponentType } from 'react';
import { useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import { IFindDashboardShopsResolverResultItem } from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';

import { USER_LIST_LIMIT } from './constants';
import { getDefaultValues } from './helpers';
import { IUserListFormFieldValues } from './types';

import { Amplitude } from '../amplitude/amplitude';
import { useDashboardNavigation } from '../navigation';
import { UsersListDesktop } from '../users-list-desktop/user-list-desktop';

export interface IUserListProps {
  onShopPress: (shop: IFindDashboardShopsResolverResultItem) => void;
  form: UseFormReturn<IUserListFormFieldValues>;
  pageLimit: number;
}

const RESPONSIVE_USER_LIST: Record<Breakpoint, ComponentType<IUserListProps>> = {
  [Breakpoint.Desktop]: UsersListDesktop,
  [Breakpoint.Mobile]: UsersListDesktop,
};

export const UserList = () => {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const navigation = useDashboardNavigation();

  const form: UseFormReturn<IUserListFormFieldValues> = useForm<IUserListFormFieldValues>({
    defaultValues: getDefaultValues(),
  });

  async function handleNavigateToUser(user: IFindDashboardShopsResolverResultItem) {
    navigation.navigate('User', { userId: user.id });

    await Amplitude.logEventWithPropertiesAsync('shop_click', {});
  }

  const ResponsiveUserList = RESPONSIVE_USER_LIST[currentBreakpoint];

  return <ResponsiveUserList form={form} onShopPress={handleNavigateToUser} pageLimit={USER_LIST_LIMIT} />;
};

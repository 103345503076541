import { merge } from 'lodash';
import { VictoryThemeDefinition } from 'victory-core';
import { VictoryTheme } from 'victory-native';

export const VictoryMaterialTheme: VictoryThemeDefinition = merge<
  VictoryThemeDefinition,
  Partial<VictoryThemeDefinition>
>(VictoryTheme.material, {
  axis: {
    style: {
      axis: {
        stroke: 'none',
      },
      grid: VictoryTheme.grayscale.axis?.style?.grid,
      ticks: VictoryTheme.grayscale.axis?.style?.ticks,
    },
  },
  area: {
    style: {
      data: {
        strokeWidth: 2,
      },
    },
  },
  tooltip: {
    flyoutPadding: 16,
    flyoutStyle: {
      fill: '#475569',
      stroke: 'none',
      borderRadius: 4,
    },
    style: {
      fill: '#fff',
    },
  },
});

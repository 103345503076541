export enum TransferApiErrorCode {
  // Не авторизован.
  Unauthorized = 'Unauthorized',
  // Нет доступа.
  Forbidden = 'Forbidden',
  // Прочие неверные данные запроса.
  InvalidRequest = 'InvalidRequest',
  // Ошибка соединения со сторонним сервисом.
  BadGateway = 'BadGateway',
  /**
   * Заказ не найден.
   *
   * Может возникнуть при попытке создания платежа по несуществующему order_id.
   */
  OrderNotFound = 'OrderNotFound',
  /**
   * Заказ с указанным external_id уже существует.
   *
   * Может возникнуть при попытке повторного создания заказа.
   */
  OrderExists = 'OrderExists',
  /**
   * Заказ уже оплачен.
   *
   * Может возникнуть при попытке повторной оплаты заказа.
   */
  OrderPaid = 'OrderPaid',
  /**
   * Заказ просрочен.
   *
   * Может возникнуть при попытке оплаты заказа с истекшим сроком действия due_date.
   */
  OrderExpired = 'OrderExpired',
  /**
   * Платеж не найден.
   *
   * Может возникнуть при попытке отмены несуществующего платежа.
   */
  PayoutNotFound = 'PayoutNotFound',
  /**
   * Для указанного заказа уже запущен процесс платежа.
   *
   * Может возникнуть при попытке повторного создания платежа.
   */
  PayoutStarted = 'PayoutStarted',
  /**
   * Неверный статус платежа.
   *
   * Может возникнуть при попытке подтверждения неуспешного платежа.
   */
  InvalidPayoutStatus = 'InvalidPayoutStatus',
  /**
   * Неверный платежный токен.
   *
   * Может возникнуть при попытке создания новой связки для плательщика.
   */
  InvalidPaymentToken = 'InvalidPaymentToken',
  /**
   * Платежный токен не найден.
   *
   * Может возникнуть при попытке получения платежных данных по неверному токену.
   */
  PayoutTokenNotFound = 'PayoutTokenNotFound',
  /**
   * Плательщик не найден.
   *
   * Может возникнуть при попытке создания новой свзяки для несуществующего плательщика.
   */
  ReceiverNotFound = 'ReceiverNotFound',
  /**
   * Плательщик с таким external_id уже существует.
   *
   * Может возникнуть при попытке создания нового плательщика.
   */
  ReceiverExists = 'ReceiverExists',
  /**
   * Для указанной карты уже запущен процесс привязки.
   *
   * Может возникнуть при попытке создания новой связки для плательщика.
   */
  BindingStarted = 'BindingStarted',
  /**
   * Связка не найдена.
   *
   * Может возникнуть при попытке создания платежа по несуществующей связке.
   */
  BindingNotFound = 'BindingNotFound',
  /**
   * Webhook не найден.
   *
   * Может возникнуть при попытке получения webhook по ID.
   */
  WebhookNotFound = 'WebhookNotFound',
  /**
   * Webhook для вашего магазина уже установлен.
   *
   * Может возникнуть при попытке создания уже существующего webhook.
   */
  WebhookExists = 'WebhookExists',
  /**
   * Информация по БИНу не найдена.
   *
   * Может возникнуть при попытке поиска информацию по карте.
   */
  BinNotFound = 'BinNotFound',
}

export const DEFAULT_TRANSFER_API_ERROR_MESSAGE: Record<TransferApiErrorCode, string> = {
  [TransferApiErrorCode.Unauthorized]: 'Не авторизован',
  [TransferApiErrorCode.Forbidden]: 'Нет доступа',
  [TransferApiErrorCode.InvalidRequest]: 'Прочие неверные данные запроса',
  [TransferApiErrorCode.BadGateway]: 'Ошибка соединения со сторонним сервисом',
  [TransferApiErrorCode.OrderNotFound]: 'Заказ не найден',
  [TransferApiErrorCode.OrderExists]: 'Заказ с указанным external_id уже существует',
  [TransferApiErrorCode.OrderPaid]: 'Заказ уже оплачен',
  [TransferApiErrorCode.OrderExpired]: 'Заказ просрочен',
  [TransferApiErrorCode.PayoutNotFound]: 'Платеж не найден',
  [TransferApiErrorCode.PayoutStarted]: 'Для указанного заказа уже запущен процесс платежа',
  [TransferApiErrorCode.InvalidPayoutStatus]: 'Неверный статус платежа',
  [TransferApiErrorCode.InvalidPaymentToken]: 'Неверный платежный токен',
  [TransferApiErrorCode.PayoutTokenNotFound]: 'Платежный токен не найден',
  [TransferApiErrorCode.ReceiverNotFound]: 'Плательщик не найден',
  [TransferApiErrorCode.ReceiverExists]: 'Плательщик с таким external_id уже существует',
  [TransferApiErrorCode.BindingStarted]: 'Для указанной карты уже запущен процесс привязки',
  [TransferApiErrorCode.BindingNotFound]: 'Связка не найдена',
  [TransferApiErrorCode.WebhookNotFound]: 'Webhook не найден',
  [TransferApiErrorCode.WebhookExists]: 'Webhook для вашего магазина уже установлен',
  [TransferApiErrorCode.BinNotFound]: 'Информация по БИНу не найдена',
};

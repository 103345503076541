import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IGetUsersResponseDataItem, updateUsersResolver } from '@iokanx/shared/data-access/api';
import { AVAILABLE_USER_STATUSES, USER_STATUS_I18N, UserStatus } from '@iokanx/shared/data-access/constants';
import { Button, Chip, ISelectItem, Select } from '@iokanx/shared/ui/universal';

import { useMe } from '../auth';

export interface UserUpdateStatusProps {
  user: IGetUsersResponseDataItem;
}

const statusItems: ISelectItem<UserStatus>[] = AVAILABLE_USER_STATUSES.map((value) => ({
  title: USER_STATUS_I18N[value],
  value: value,
}));

export function UserUpdateStatus(props: UserUpdateStatusProps) {
  const { me } = useMe();
  const canAct = !me?.is_superuser;

  const queryClient = useQueryClient();
  const { mutate: updateUserStatus, isLoading: isUpdatingUserStatus } = useMutation(updateUsersResolver, {
    onSuccess: () => queryClient.invalidateQueries(['users']),
  });

  function handleUpdateUserStatus(newStatus: UserStatus) {
    updateUserStatus({
      userId: props.user.id,
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      status: newStatus,
      email: props.user.email,
    });
  }

  if (canAct) {
    return <Chip>{USER_STATUS_I18N[props.user.status]}</Chip>;
  }

  return (
    <Select
      anchor={(anchorProps) => (
        <Button
          mode={'outlined'}
          disabled={!canAct}
          icon={{ name: 'chevron-down' }}
          onPress={anchorProps.handleOpenMenu}
          loading={isUpdatingUserStatus}
        >
          {USER_STATUS_I18N[props.user.status]}
        </Button>
      )}
      value={props.user.status}
      items={statusItems}
      onChange={handleUpdateUserStatus}
      disabled={isUpdatingUserStatus}
    />
  );
}

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { List } from 'react-native-paper';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { License } from './License';
import { OKED } from './OKED';

import { FormButton } from '../../Button';
import { ConditionalComponent } from '../../ConditionalComponent';

interface IActivityType {
  isSubmitted: boolean;
  oked: any;
  organizationId: string;
}

export const ActivityType = ({ isSubmitted, organizationId, oked }: IActivityType) => {
  const [isMore, setIsMore] = useState(false);

  const { data: licenses } = useQuery(['licenses', oked?.id], async () => {
    const { data: response } = await backendHttp.get(`organizations/${organizationId}/okeds/${oked.id}/licenses`);

    return response;
  });

  const handleFormDisplay = () => setIsMore((isMore) => !isMore);

  useEffect(() => setIsMore(false), [licenses]);

  return (
    <Box border={'1px solid #e2e8f0'} padding={'8px'} borderRadius={'4px'} marginTop={'12px'} sx={{ width: '100%' }}>
      <List.Section title={oked?.code}>
        <OKED
          isMain={oked?.is_main}
          isRemovable={isEmpty(licenses)}
          isSubmitted={isSubmitted}
          organizationId={organizationId}
          oked={oked}
        />

        {isEmpty(licenses) ? (
          <FormButton onClick={handleFormDisplay}>{isMore ? 'Скрыть' : 'Добавить лицензию'}</FormButton>
        ) : (
          <List.Section>
            {licenses?.map((license: any, index: number) => {
              return (
                <License
                  isSubmitted={isSubmitted}
                  organizationId={organizationId}
                  key={index}
                  oked={oked}
                  license={license}
                />
              );
            })}

            <ConditionalComponent
              condition={!isSubmitted}
              component={<FormButton onClick={handleFormDisplay}>{isMore ? 'Скрыть' : 'Добавить лицензию'}</FormButton>}
            />
          </List.Section>
        )}

        <ConditionalComponent
          condition={isMore}
          component={<License isSubmitted={isSubmitted} organizationId={organizationId} oked={oked} />}
        />
      </List.Section>
    </Box>
  );
};

import moment from 'moment';
import { useForm } from 'react-hook-form';

import { ISearchPaymentLinksResolverParams } from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';

import { PaymentLinkCards } from '../payment-link-cards/payment-link-cards';
import { PaymentLinkTable } from '../payment-link-table/payment-link-table';

export type IPaymentLinkListFormFieldValues = Pick<
  ISearchPaymentLinksResolverParams,
  'slug' | 'link_type' | 'from_dt' | 'to_dt' | 'page'
>;

export const PAYMENT_LINK_LIST_LIMIT = 10;
export const PAYMENT_LINK_EMPTY_TEXT = 'По указанным параметрам, ссылок на оплату не найдено';

export function PaymentLinkList() {
  const paymentLinkListForm = useForm<IPaymentLinkListFormFieldValues>({
    defaultValues: {
      slug: '',
      link_type: undefined,
      from_dt: moment().subtract(1, 'month').startOf('day').toDate(),
      to_dt: moment().endOf('day').toDate(),
      page: 1,
    },
  });

  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  switch (currentBreakpoint) {
    case Breakpoint.Desktop:
      return (
        <PaymentLinkTable
          form={paymentLinkListForm}
          limit={PAYMENT_LINK_LIST_LIMIT}
          emptyText={PAYMENT_LINK_EMPTY_TEXT}
        />
      );
    default:
      return (
        <PaymentLinkCards
          form={paymentLinkListForm}
          limit={PAYMENT_LINK_LIST_LIMIT}
          emptyText={PAYMENT_LINK_EMPTY_TEXT}
        />
      );
  }
}

import { ComponentType } from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { PaperThemeProvider } from '../paper-theme-provider/paper-theme-provider';
import { SnackbarProvider } from '../snackbar/snackbar';

export interface IWrapPageOptions {
  paperThemeProvider?: boolean;
  keyboardAvoidingView?: boolean;
}

export function wrapPage<IProps>(Component: ComponentType<IProps>, options?: IWrapPageOptions): ComponentType<IProps> {
  return (props) => {
    let WrappedPage = (
      <SnackbarProvider>
        <SafeAreaView style={styles.safeAreaView} edges={['bottom']}>
          <KeyboardAvoidingView
            style={styles.keyboardAvoidingView}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            enabled={Boolean(options?.keyboardAvoidingView)}
          >
            <Component {...props} />
          </KeyboardAvoidingView>
        </SafeAreaView>
      </SnackbarProvider>
    );

    if (options?.paperThemeProvider) {
      WrappedPage = <PaperThemeProvider>{WrappedPage}</PaperThemeProvider>;
    }

    return WrappedPage;
  };
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
});

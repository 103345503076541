import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import {
  Amplitude,
  CreatePayoutForm,
  DashboardStackParamList,
  useDashboardNavigation,
  useMe,
} from '@iokanx/dashboard/feature';
import {
  createPayoutResolver,
  ICreatePayoutResolverParams,
  ICreatePayoutResolverResult,
} from '@iokanx/shared/data-access/api';
import { IHttpError } from '@iokanx/shared/data-access/types';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';

function CreateShop() {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { me } = useMe();
  const navigation = useDashboardNavigation();

  const {
    mutate: createPayout,
    error,
    isLoading,
    isError,
  } = useMutation<ICreatePayoutResolverResult, AxiosError<IHttpError>, ICreatePayoutResolverParams>(
    createPayoutResolver,
    {
      onSuccess: async () => {
        snackbar.show('Выплата успешно создана', { type: 'success' });
        navigation.navigate('Drawer', { screen: 'Reports', params: { screen: 'Payouts' } });
        return queryClient.invalidateQueries(['payouts']);
      },
    },
  );

  return (
    <CreatePayoutForm
      createPayout={createPayout}
      isLoading={isLoading}
      error={error?.response?.data.message}
      isError={isError}
    />
  );
}

export default wrapPage(
  wrapModalPage(CreateShop, {
    fillNavigationStack: (navigation: NativeStackNavigationProp<DashboardStackParamList>) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Reports', params: { screen: 'Payouts' } });
        navigation.push('CreatePayout');
      }
    },
    handleDismiss: (navigation) => {
      navigation.navigate('Drawer', { screen: 'Reports', params: { screen: 'Payouts' } });
    },
  }),
);

import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { Beneficiary } from './Beneficiary';
import { SHAREHOLDERS_TOOLTIP_TEXT } from './constants';
import { filterShares } from './helpers';

import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { FormButton } from '../../Button';
import { ConditionalComponent } from '../../ConditionalComponent';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';

export const ShareholdersSection = (props: any) => {
  const { organizationId, isSubmitted } = useProfileFormContext();

  const [isMore, setIsMore] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState<any[]>([]);

  const { data: shares } = useQuery(['shares'], async () => {
    try {
      const { data } = await backendHttp.get(`organizations/${organizationId}/shares`);

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  });

  const addBeneficiary = () => setIsMore(true);

  const filterParentsBeneficiaries = (beneficiaries: any[]) =>
    beneficiaries?.filter((beneficiary) => !beneficiary.parent_id);

  useEffect(() => {
    const filteredShares = filterShares(shares);

    setBeneficiaries(filteredShares);
    setIsMore(false);
  }, [shares]);

  return (
    <ProfileFormSection
      handleSubmit={null}
      tooltip={{ text: SHAREHOLDERS_TOOLTIP_TEXT }}
      title={'Данные об акционерах/учредителях организации/мерчанта'}
      {...props}
    >
      {isEmpty(beneficiaries) ? (
        <Beneficiary updateChildrenCompletionStatus={props.updateChildrenCompletionStatus} />
      ) : (
        <>
          {filterParentsBeneficiaries(beneficiaries).map((beneficiary, index) => {
            return (
              <Beneficiary
                updateChildrenCompletionStatus={props.updateChildrenCompletionStatus}
                beneficiary={beneficiary}
                beneficiaries={beneficiaries}
                key={index}
              />
            );
          })}

          <ConditionalComponent
            condition={!isSubmitted}
            component={<FormButton onClick={addBeneficiary}>Добавить</FormButton>}
          />

          <ConditionalComponent
            condition={isMore}
            component={<Beneficiary updateChildrenCompletionStatus={props.updateChildrenCompletionStatus} />}
          />
        </>
      )}
    </ProfileFormSection>
  );
};

import { backendHttp } from '../../../instances';

export interface IGetTransactionResponseData {
  id?: string;
  shop_id?: string;
  order_id?: string;
  reference?: string;
  date_time?: string;
  amount?: number;
  processing_fee?: number;
  acquirer_fee?: number;
  terminal?: string;
  acquirer?: string;
}

export interface IGetReestrResponseData {
  id?: string;
  acquirer?: string;
  subject?: string;
  msg_id?: string;
  parsed?: boolean;
  created_at?: string;
  url?: string;
}

export interface IGetBankTransactionResponseData {
  id?: string;
  reference?: string;
  amount?: number;
  acquirer_fee?: number;
  processing_fee?: number;
  transaction_date?: string;
  receipt_date?: string;
  reestr?: IGetReestrResponseData;
}

export interface IGetReviseByIdResponseData {
  shop_id?: string;
  order_id?: string;
  reference?: string;

  date_time?: string;
  amount?: number;
  processing_fee?: number;
  acquirer_fee?: number;

  acquirer?: string;
  bank_reference?: string;
  bank_date_time?: string;
  bank_receipt_date?: string;
  bank_amount?: number;
  bank_processing_fee?: number;
  bank_acquirer_fee?: number;

  diff_amount?: number;
  diff_acquirer_fee?: number;
  transactions?: IGetTransactionResponseData[];
  bank_transactions?: IGetBankTransactionResponseData[];
}

/** Получение сверки по референс */
export async function getReviseById(reference: string): Promise<IGetReviseByIdResponseData> {
  const { data } = await backendHttp.get<IGetReviseByIdResponseData>(`/reports/revises/${reference}`);
  return data;
}

import { IContactData } from './types';

export const parseContactData = ({ last_name, first_name, patronymic, position, email, phone }: IContactData) => {
  return {
    last_name,
    first_name,
    patronymic,
    position,
    email,
    phone,
  };
};

export const getDefaultValues = () => ({
  last_name: '',
  first_name: '',
  patronymic: '',
  position: '',
  email: '',
  phone: '',
});

import { AccountStatus, RegistrationType, ShopStatus } from '@iokanx/shared/data-access/constants';

import { IGetConfigByShopsIdResponseData } from './config';

import { backendHttp, http } from '../../../instances';

export interface IGetShopAccountRecourseResponseData {
  id: string;
  iban: string | null;
  bank_id: string | null;
  bank_name: string | null;
  is_default: boolean | false;
  external_id: string | null;
  status: AccountStatus;
}

export interface IGetShopAccountResponseData {
  id: string;
  external_id: string | null;
  status: AccountStatus;
  resources: [IGetShopAccountRecourseResponseData];
}

export interface IGetShopByIdResponseData {
  /** Идентификатор магазина */
  id: string;
  /** BIN ИЛИ IIN */
  bin: string;
  /** Дата создания */
  created_at: string;
  /** Юридическое название магазина */
  name: string;
  /** Название магазина для отображения в формах оплаты */
  display_name: string | null;
  /** MCC магазина */
  mcc: string | null;
  registration_type: RegistrationType | null;
  /** IBAN магазина */
  iban: string | null;
  bank_id: string | null;
  bank_name: string | null;
  /** ФИО руководителя магазина */
  owner: string | null;
  /** Юридический адрес магазина */
  address: string | null;
  /** Сайт магазина */
  site: string | null;
  /** Номер телефона магазина */
  phone: string | null;
  /** Почта магазина */
  email: string | null;
  /** Статус магазина */
  status: ShopStatus;
  /** Номер договора */
  contract_num: string | null;
  /** Ссылка на договор */
  contract: string | null;
  accounts: [IGetShopAccountResponseData];
}

export async function getShopById(id: string): Promise<IGetShopByIdResponseData> {
  const { data } = await http.get<IGetShopByIdResponseData>(`/shops/${id}`);
  return data;
}

export async function updateShopById(
  shopId: string,
  shop: IGetShopByIdResponseData,
): Promise<IGetShopByIdResponseData> {
  console.log(shop);
  const { data } = await backendHttp.patch(`/shops/${shopId}`, shop);
  return data;
}

import { ComponentProps } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Dialog } from 'react-native-paper';

import { DeviceSize } from '@iokanx/shared/data-access/constants';

export function SimpleDialog(props: ComponentProps<typeof Dialog>) {
  const { width, height } = useWindowDimensions();

  return <Dialog {...props} style={[styles.root, props.style, { maxWidth: width - 16, maxHeight: height - 16 }]} />;
}

const styles = StyleSheet.create({
  root: {
    alignSelf: 'center',
    width: DeviceSize.LargeMobile,
    overflow: 'scroll',
  },
});

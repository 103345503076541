import { Button } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { Item } from '@iokanx/dashboard/feature';

interface IFormButton {
  children: ReactNode;
  onClick: any;
}

export const FormButton: FC<IFormButton> = ({ children, onClick }) => {
  return (
    <Item>
      <Button sx={{ minWidth: '180px' }} fullWidth={false} onClick={onClick}>
        {children}
      </Button>
    </Item>
  );
};

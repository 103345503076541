import { Tiles } from '@mobily/stacks';
import { ComponentType, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import {
  IFindDashboardPaymentsResolverParams,
  IFindDashboardShopsResolverResultItem,
} from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { DateRangePicker, ISelectItem, Select, TextField } from '@iokanx/shared/ui/universal';

import { Amplitude } from '../amplitude/amplitude';
import { useDashboardNavigation } from '../navigation';
import { SearchOrdersParam } from '../order-list/order-list';
import { ShopListDesktop } from '../shop-list-desktop/shop-list-desktop';
import { ShopListMobile } from '../shop-list-mobile/shop-list-mobile';

export enum SearchShopsParam {
  shopId = 'shop_id',
  name = 'name',
  nib = 'nib',
  externalId = 'external_id',
}

export enum ShopStatuses {
  PENDING = 'PENDING',
  REVIEW = 'REVIEW',
  ACCEPTED = 'ACCEPTED',
  SUSPENDED = 'SUSPENDED',
  REJECTED = 'REJECTED',
  ALL = '',
}

export interface IShopListFormFieldValues extends Pick<IFindDashboardPaymentsResolverParams, SearchOrdersParam> {
  page: NonNullable<IFindDashboardPaymentsResolverParams['page']>;
  from_dt: IFindDashboardPaymentsResolverParams['from_dt'];
  to_dt: IFindDashboardPaymentsResolverParams['to_dt'];
  status: ShopStatuses;
}

const searchShopsParamI18n: Record<SearchShopsParam, string> = {
  [SearchShopsParam.shopId]: 'Идентификатор',
  [SearchShopsParam.name]: 'Наименование',
  [SearchShopsParam.nib]: 'БИН',
  [SearchShopsParam.externalId]: 'Вн. идентификатор',
};

const shopStatusesI18n: Record<ShopStatuses, string> = {
  [ShopStatuses.PENDING]: 'В Ожидании',
  [ShopStatuses.REVIEW]: 'На рассмотрении',
  [ShopStatuses.REJECTED]: 'Отклонено',
  [ShopStatuses.SUSPENDED]: 'В Ожидании',
  [ShopStatuses.ACCEPTED]: 'Принято',
  [ShopStatuses.ALL]: 'Все статусы',
};

const searchShopsParamItems: ISelectItem<SearchShopsParam>[] = Object.values(SearchShopsParam).map((value) => ({
  title: searchShopsParamI18n[value],
  value,
}));

const shopsStatuses: ISelectItem<ShopStatuses>[] = Object.values(ShopStatuses).map((value) => ({
  title: shopStatusesI18n[value],
  value,
}));

const SHOP_LIST_LIMIT = 10;

export interface IShopListProps {
  onShopPress: (shop: IFindDashboardShopsResolverResultItem) => void;
  form: UseFormReturn<IShopListFormFieldValues>;
  pageLimit: number;
  filter: JSX.Element;
}

const RESPONSIVE_SHOP_LIST: Record<Breakpoint, ComponentType<IShopListProps>> = {
  [Breakpoint.Desktop]: ShopListDesktop,
  [Breakpoint.Mobile]: ShopListMobile,
};

export function ShopList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  const form = useForm<IShopListFormFieldValues>({
    defaultValues: {
      page: 1,
      id: '',
      external_id: '',
      status: ShopStatuses.ALL,
    },
  });

  const [searchShopParam, setSearchShopParam] = useState(SearchShopsParam.name);

  function handleChangeSearchShopParam(nextSearchShopParam: SearchShopsParam) {
    form.reset();
    setSearchShopParam(nextSearchShopParam);
  }

  function handleChangeShopStatus(value: ShopStatuses) {
    form.setValue('status', value);
  }

  const navigation = useDashboardNavigation();

  function handleNavigateToShop(shop: IFindDashboardShopsResolverResultItem) {
    navigation.navigate('Shop', { shopId: shop.id });
  }

  const filter = (
    <Tiles columns={[1, 4]} space={2}>
      <Select onChange={handleChangeShopStatus} value={form.watch('status')} label={'Статус'} items={shopsStatuses} />
      <Select
        onChange={handleChangeSearchShopParam}
        value={searchShopParam}
        label={'Поиск по'}
        items={searchShopsParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label={searchShopsParamI18n[searchShopParam]}
          />
        )}
        name={searchShopParam}
      />
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          form.setValue('from_dt', values.startDate);
          form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
    </Tiles>
  );

  const ResponsiveShopList = RESPONSIVE_SHOP_LIST[currentBreakpoint];

  return (
    <ResponsiveShopList filter={filter} onShopPress={handleNavigateToShop} form={form} pageLimit={SHOP_LIST_LIMIT} />
  );
}

import { compactObject } from '@iokanx/shared/util';

import { transformVote } from './get-nps-by-id.resolver';

import { findNPS, IFindNpsRequestParams, IGetNpsResponseData } from '../../resources';

export type IFindNpsResolverResult = IGetNpsResponseData;

export interface IFindNpsRequestResolverParams extends IFindNpsRequestParams {
  email?: string;
  type_nps: string;
}

export async function searchNpsResolver(params: IFindNpsRequestParams): Promise<IFindNpsResolverResult> {
  const votes = await findNPS(compactObject(params));
  const data = votes.data.map(transformVote);
  return { ...votes, data };
}

import * as Clipboard from 'expo-clipboard';

import { useSnackbar } from '@iokanx/shared/feature';
import { Button, Paragraph } from '@iokanx/shared/ui/universal';

export interface ClipboardButtonProps {
  copiedText?: string;
  popupMessage?: string;
  children: string;
}

export function ClipboardButton(props: ClipboardButtonProps) {
  const snackbar = useSnackbar();

  async function copyToClipboard() {
    await Clipboard.setStringAsync(props.copiedText || props.children);
    snackbar.show(props.popupMessage || 'Скопировано');
  }

  return (
    <Button
      mode={'outlined'}
      icon={{ name: 'content-copy' }}
      uppercase={false}
      fullWidth
      {...props}
      onPress={copyToClipboard}
    >
      <Paragraph numberOfLines={1}>{props.children}</Paragraph>
    </Button>
  );
}

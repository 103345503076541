export enum ConfigSchema {
  MasterPass = 'master_pass',
  WebKassa = 'webkassa',
  UKassa = 'ukassa',
  Halyk = 'epay_v2',
  Bcc = 'bcc',
  Jusan = 'jusan',
  Bereke = 'sberbank',
  GooglePay = 'google_pay',
  ApplePay = 'apple_pay',
}

export const CONFIG_SCHEMA_TITLE: Record<ConfigSchema, string> = {
  [ConfigSchema.MasterPass]: 'MasterPass',
  [ConfigSchema.WebKassa]: 'WebKassa',
  [ConfigSchema.UKassa]: 'UKassa',
  [ConfigSchema.Halyk]: 'Halyk',
  [ConfigSchema.Bcc]: 'BCC',
  [ConfigSchema.Jusan]: 'Jusan',
  [ConfigSchema.Bereke]: 'Bereke',
  [ConfigSchema.GooglePay]: 'GooglePay',
  [ConfigSchema.ApplePay]: 'ApplePay',
};

export const google_pay_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    gateway_merchant_id: {
      type: 'string',
      title: 'Идентификатор магазина',
      default: 'googletest',
    },
  },
};

export const apple_pay_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    apple_id: {
      type: 'string',
      title: 'Идентификатор AppleID',
      default: '',
      readonly: true,
    },
    cert_file_path: {
      type: 'string',
      title: 'Путь к сертификату',
      default: '',
    },
    key_file_path: {
      type: 'string',
      title: 'Путь к ключу от сертификата',
      default: '',
    },
    display_name: {
      type: 'string',
      title: 'Название магазина',
      default: '',
    },
    domain_name: {
      type: 'string',
      title: 'Название домена',
      default: '',
    },
    merchant_certificate: {
      type: 'string',
      title: 'Путь с сертификату мерчанта',
      default: '',
    },
  },
};

export const masterpass_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    name: {
      type: 'string',
      title: 'Название',
      default: 'masterpass',
      hidden: true,
    },
    queue: {
      type: 'string',
      title: 'Очередь',
      default: 'masterpass.commands',
      hidden: true,
    },
    settings: {
      type: 'object',
      title: 'Настройки',
      hidden: true,
      properties: {
        max_operations_per_day: {
          type: 'number',
          title: 'мак.кол-во операции в день',
          default: 50,
        },
        max_operations_with_phone_per_day: {
          type: 'number',
          title: 'мак.кол-во операции по номеру в день',
          default: 50,
        },
        max_operations_with_all_user_per_day: {
          type: 'number',
          title: 'мак.кол-во операции по всем пользователям в день',
          default: 50,
        },
        max_sum_per_day: {
          type: 'number',
          title: 'макс. сумма в день',
          default: 500000,
        },
        max_sum_with_phone_per_day: {
          type: 'number',
          title: 'макс. сумма по номеру в день',
          default: 500000,
        },
        access: {
          type: 'object',
          title: 'Доступы',
          properties: {
            merchant_name: {
              type: 'string',
              title: 'Merchant name',
              // default: 'iokanheim',
            },
            password: {
              type: 'string',
              title: 'Пароль',
              // default: '07bb2e5d-4081-4c8a-ab66-97e0d70084c7',
            },
            consumer_key: {
              type: 'string',
              title: 'Ключ мерчанта',
              // default: '0d67172277f34a7ba083f1ac47674fea',
            },
            private_key_password: {
              type: 'string',
              title: 'Пароль от сертификата',
              // default: 'a627f2fcd1',
            },
            private_key_path: {
              type: 'string',
              title: 'Путь с сертификату',
              // default: 'masterpass.ioka.kz/stage/iokanheim/certificate.pfx',
            },
          },
        },
      },
    },
  },
};

export const webkassa_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    name: {
      type: 'string',
      title: 'Название',
      default: 'webkassa',
      readonly: true,
    },
    queue: {
      type: 'string',
      title: 'Очередь',
      default: 'webkassa.commands',
      readonly: true,
    },
    settings: {
      type: 'object',
      title: ' ',
      properties: {
        access: {
          type: 'object',
          title: 'Доступы',
          properties: {
            login: {
              type: 'string',
              title: 'Логин',
              // default: 'support@ioka.kz',
            },
            password: {
              type: 'string',
              title: 'Пароль',
              // default: 'Kassa123@',
            },
          },
        },
      },
    },
  },
};

export const ukassa_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    name: {
      type: 'string',
      title: 'Название',
      default: 'ukassa',
      readonly: true,
    },
    queue: {
      type: 'string',
      title: 'Очередь',
      default: 'webkassa.commands',
      readonly: true,
    },
    settings: {
      type: 'object',
      title: ' ',
      properties: {
        access: {
          type: 'object',
          title: 'Доступы',
          properties: {
            login: {
              type: 'string',
              title: 'Логин',
              // default: 'support@ioka.kz',
            },
            password: {
              type: 'string',
              title: 'Пароль',
              // default: 'Kassa123@',
            },
          },
        },
      },
    },
  },
};

const featuresEnum = [
  'card_pay',
  'card_binding',
  'card_rebind',
  'p2p',
  'card_pay_no_cvc',
  'apple_pay',
  'google_pay',
  'master_pass',
];

const paymentSystemEnum = ['VISA', 'MASTERCARD', 'UNIONPAY', 'MIR', 'AMEX'];

const emitterEnum = [
  'kazpost',
  'nurbank',
  'atfbank',
  'sberbank',
  'vtbbank',
  'capitalbank',
  'kaspibank',
  'jysanbank',
  'bankkassanova',
  'bankrbk',
  'tengribank',
  'altynbank',
  'alfabank',
  'centercredit',
  'fortebank',
  'eurasianbank',
  'homecredit',
  'simply',
  'halykbank',
];

const unitEnum = ['PERCENT', 'FIXED'];

const currencyEnum = ['KZT'];

const priorityEnum = [1, 2, 3, 4, 5];

const terminalOption = {
  currencies: {
    type: 'array',
    title: 'Валюты',
    format: 'checkbox',
    readonly: true,
    uniqueItems: true,
    items: {
      type: 'string',
      enum: currencyEnum,
    },
    default: ['KZT'],
  },
  features: {
    type: 'array',
    title: 'Фичи',
    uniqueItems: true,
    format: 'checkbox',
    items: {
      type: 'string',
      enum: featuresEnum,
    },
    default: ['card_pay', 'card_binding', 'card_rebind'],
  },
  mccs: {
    type: 'array',
    title: 'MCC',
    uniqueItems: true,
    format: 'string',
    items: {
      type: 'string',
      title: 'mcc',
    },
  },
  commission_rules: {
    type: 'array',
    title: 'Список комиссии',
    items: {
      type: 'object',
      title: 'Комиссия',
      properties: {
        id: {
          type: 'string',
          title: 'Идентификатор',
        },
        currency: {
          type: 'string',
          title: 'Валюта',
          default: 'KZT',
        },
        payment_systems: {
          type: 'array',
          title: 'Платежные системы',
          format: 'checkbox',
          uniqueItems: true,
          items: {
            type: 'string',
            enum: paymentSystemEnum,
          },
          default: ['VISA', 'MASTERCARD'],
        },
        emitters: {
          type: 'array',
          title: 'Банки эмитенты',
          format: 'checkbox',
          uniqueItems: true,
          items: {
            type: 'string',
            enum: emitterEnum,
          },
          default: [],
        },
        receiver_emitters: {
          type: 'array',
          title: 'Банки получатели',
          format: 'checkbox',
          uniqueItems: true,
          items: {
            type: 'string',
            enum: emitterEnum,
          },
          default: [],
        },
        mcc: {
          type: 'string',
          title: 'MCC - код категории',
        },
        acquirer_fee: {
          type: 'object',
          title: 'Комиссии эквайера',
          properties: {
            unit: {
              type: 'string',
              required: true,
              title: 'Единица измерения',
              enum: unitEnum,
            },
            value: {
              type: 'number',
              title: 'Значение',
            },
            min_value: {
              type: 'number',
              title: 'Минимальное значение',
            },
          },
        },
        processing_fee: {
          type: 'object',
          title: 'Комиссии процессинга',
          properties: {
            unit: {
              type: 'string',
              required: true,
              title: 'Единица измерения',
              enum: unitEnum,
            },
            value: {
              type: 'number',
              title: 'Значение',
            },
            min_value: {
              type: 'number',
              title: 'Минимальное значение',
            },
          },
        },
      },
    },
  },
  priority: {
    type: 'number',
    required: true,
    title: 'Приоритет',
    enum: priorityEnum,
  },
  activated: {
    type: 'boolean',
    format: 'checkbox',
    required: true,
    title: 'Активный',
    default: false,
  },
  transit: {
    type: 'boolean',
    format: 'checkbox',
    required: true,
    title: 'Транзитный',
    default: false,
  },
};

const acquirerOption = {
  priority: {
    type: 'number',
    required: true,
    title: 'Приоритет',
    enum: priorityEnum,
  },
  limit: {
    type: 'number',
    required: true,
    default: 1000000,
    title: 'Лимит на терминале в день',
  },
  activated: {
    type: 'boolean',
    format: 'checkbox',
    required: true,
    title: 'Активный',
    default: false,
  },
  blocked_until: {
    type: 'datetime',
    title: 'Заблокирован',
  },
};

export const epay_v2_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    name: {
      type: 'string',
      title: 'Название',
      default: 'epay_v2',
      readonly: true,
    },
    queue: {
      type: 'string',
      title: 'Очередь',
      default: 'halyk.v2.commands',
      readonly: true,
    },
    terminals: {
      type: 'array',
      title: 'Список терминалов',
      items: {
        type: 'object',
        title: 'Терминал',
        properties: {
          name: {
            type: 'string',
            title: 'Наименование терминала',
          },
          access: {
            type: 'object',
            title: 'Доступы',
            properties: {
              client_id: {
                type: 'string',
                title: 'ClientID',
                required: true,
              },
              client_secret: {
                type: 'string',
                title: 'ClientSecret',
                required: true,
              },
              terminal_id: {
                type: 'string',
                title: 'ID терминала',
                required: true,
              },
              scope: {
                type: 'string',
                title: 'Scope',
                readonly: true,
              },
              grant_type: {
                type: 'string',
                title: 'GrantType',
                readonly: true,
              },
            },
          },
          ...terminalOption,
        },
      },
    },
    ...acquirerOption,
  },
};

export const bcc_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    name: {
      type: 'string',
      title: 'Название',
      default: 'bcc',
      readonly: true,
    },
    queue: {
      type: 'string',
      title: 'Очередь',
      default: 'bcc.commands',
      readonly: true,
    },
    terminals: {
      type: 'array',
      title: 'Список терминалов',
      items: {
        type: 'object',
        title: 'Терминал',
        properties: {
          name: {
            type: 'string',
            title: 'Наименование терминала',
          },
          access: {
            type: 'object',
            title: 'Доступы',
            properties: {
              username: {
                type: 'string',
                title: 'Username',
                required: true,
              },
              password: {
                type: 'string',
                title: 'Пароль',
                required: true,
              },
              certificate_s3_path: {
                type: 'string',
                title: 'Путь к сертификату',
                required: true,
              },
              certificate_password: {
                type: 'string',
                title: 'Пароль от сертификата',
                required: true,
              },
            },
          },
          ...terminalOption,
        },
      },
    },
    ...acquirerOption,
  },
};

export const sberbank_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    name: {
      type: 'string',
      title: 'Название',
      default: 'sberbank',
      readonly: true,
    },
    queue: {
      type: 'string',
      title: 'Очередь',
      default: 'rbs.commands',
      readonly: true,
    },
    terminals: {
      type: 'array',
      title: 'Список терминалов',
      items: {
        type: 'object',
        title: 'Терминал',
        properties: {
          name: {
            type: 'string',
            title: 'Наименование терминала',
          },
          access: {
            type: 'object',
            title: 'Доступы',
            properties: {
              username: {
                type: 'string',
                title: 'Username',
                required: true,
              },
              password: {
                type: 'string',
                title: 'Пароль',
                required: true,
              },
              api_url: {
                type: 'string',
                title: 'API path',
                required: true,
              },
            },
          },
          ...terminalOption,
        },
      },
    },
    ...acquirerOption,
  },
};

export const jusan_schema = {
  type: 'object',
  title: 'Конфигурация',
  properties: {
    name: {
      type: 'string',
      title: 'Название',
      default: 'jusan',
      readonly: true,
    },
    queue: {
      type: 'string',
      title: 'Очередь',
      default: 'jusan.commands',
      readonly: true,
    },
    terminals: {
      type: 'array',
      title: 'Список терминалов',
      items: {
        type: 'object',
        title: 'Терминал',
        properties: {
          name: {
            type: 'string',
            title: 'Наименование терминала',
          },
          access: {
            type: 'object',
            title: 'Доступы',
            properties: {
              merchant_id: {
                type: 'string',
                title: 'merchant_id',
                required: true,
              },
              psm_secret_key: {
                type: 'string',
                title: 'psm_secret_key',
                required: true,
              },
              terminal_id: {
                type: 'string',
                title: 'ID терминала',
                required: true,
              },
            },
          },
          ...terminalOption,
        },
      },
    },
    ...acquirerOption,
  },
};

export const provider = {};

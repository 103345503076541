import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { answerQueryBuilder } from './helpers';
import { ProfileFormQuestion } from './ProfileFormQuestion';

import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { ConditionalComponent } from '../../ConditionalComponent';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { ProfileFormStep } from '../../layouts/ProfileFormStep';
import { IFormSection } from '../OrganizationInfoStep/types';

const combineQuestions = (questions: any, answers: any) => {
  const transformedData: any = Object.keys(questions).reduce((result: any, key) => {
    questions[key].forEach((item: any) => {
      const { id } = item;
      result[id] = { answer: '', comment: '' };
    });
    return result;
  }, {});

  answers?.forEach((item: any) => {
    const { question_id, answer, comment, id } = item;
    if (transformedData[question_id]) {
      transformedData[question_id] = { answer, comment, id };
    }
  });

  return transformedData;
};

export const AdditionalQuestionsStep: FC<IFormSection> = ({ ...props }) => {
  const { organizationId, isSubmitted } = useProfileFormContext();
  const [combinedData, setCombinedData] = useState<any>({});

  const { data: questions } = useQuery(['questions'], async () => {
    try {
      const { data } = await backendHttp.get(`/dictionaries/questions?is_group=true`);

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  });

  const { data: answers } = useQuery(['answers'], async () => {
    try {
      const { data } = await backendHttp.get(answerQueryBuilder(organizationId));

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  });

  useEffect(() => {
    const combinedQuestions = combineQuestions(questions, answers);

    setCombinedData(combinedQuestions);
  }, [questions, answers]);

  return (
    <ProfileFormStep {...props}>
      <ConditionalComponent
        condition={!isEmpty(questions)}
        component={
          <>
            {Object.keys(questions).map((objectKey, index) => {
              return (
                <ProfileFormSection isSaved={true} handleSubmit={null} title={objectKey} key={index}>
                  {questions[objectKey].map((data: any, index: number) => {
                    return (
                      <ProfileFormQuestion
                        isSubmitted={isSubmitted}
                        organizationId={organizationId}
                        key={index}
                        title={data.title}
                        description={data.description}
                        id={data.id}
                        answer={combinedData[data?.id]}
                      />
                    );
                  })}
                </ProfileFormSection>
              );
            })}
          </>
        }
      />
    </ProfileFormStep>
  );
};

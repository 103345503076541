import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp, getOrganizationByIdResolver } from '@iokanx/shared/data-access/api';
import { IHttpError } from '@iokanx/shared/data-access/types';
import { Select } from '@iokanx/shared/ui/universal';

import { useFormEdit } from '../../../../../helpers/useFormEdit';
import { useProfileFormContext } from '../../../../../providers/ProfileFormProvider';
import {
  getOrganizationFileTypesResolver,
  postOrganizationInfoResolver,
} from '../../../../../shared/data-access/organizations';
import { FormField } from '../../../../FormField/FormField';
import { ProfileFormSection } from '../../../../layouts/ProfileFormSection';
import { parseOrganizationsType } from '../../helpers';
import { IOrganizationInfo, organizationInfoValidationSchema } from '../../model/schemas';

const patchData = async (formData: any, organizationId: string) => {
  const { data } = await backendHttp.patch<any>(`/organizations/${organizationId}`, formData);

  return data;
};

const parseOrganizationInfo = (data: any): IOrganizationInfo | undefined => {
  if (isEmpty(data)) return;

  return {
    legal_name: data?.legal_name,
    trade_name: data?.trade_name,
    nib: data?.nib,
    organization_type_id: data?.organization_type?.id,
  };
};

export const OrganizationInfoSection = () => {
  const { organizationId, isSubmitted } = useProfileFormContext();

  const { data: organization } = useQuery(['organization', organizationId], () =>
    getOrganizationByIdResolver(organizationId),
  );
  const { data: types } = useQuery(['organization', 'types'], getOrganizationFileTypesResolver);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: parseOrganizationInfo(organization),
    resolver: yupResolver(organizationInfoValidationSchema),
  });

  const onSubmit = (data: any) => mutate(data);
  const onError = (error: any) => console.error(error);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(organization), handleSubmit(onSubmit, onError));

  const { mutate } = useMutation<any, AxiosError<IHttpError>, any>(
    organization ? (formData: any) => patchData(formData, organizationId) : postOrganizationInfoResolver,
    {
      onSuccess: (data: any) => {
        reset(parseOrganizationInfo(data));

        handleIsEdit();
      },
    },
  );

  const handleButtonPress = () => {
    return isDirty ? handlePress() : handleIsEdit();
  };

  useEffect(() => {
    organization ? reset(parseOrganizationInfo(organization)) : reset();
  }, [organization]);

  return (
    <ProfileFormSection
      handleSubmit={handleButtonPress}
      isSaved={isDisable}
      isSubmitted={isSubmitted}
      title={'Данные об организации'}
    >
      <FormField name={'legal_name'} control={control} label={'Юридическое наименование'} disabled={isDisable} />

      <FormField name={'trade_name'} control={control} label={'Торговое наименование'} disabled={isDisable} />

      <FormField name={'nib'} control={control} label={'БИН'} disabled={isDisable} />

      <Controller
        control={control}
        name={'organization_type_id'}
        render={({ field: { onChange, value } }) => (
          <Item>
            <Select
              onChange={onChange}
              label={'Тип организации'}
              value={value}
              items={parseOrganizationsType(types)}
              style={{ width: '100%' }}
              disabled={isDisable}
            />
          </Item>
        )}
      />
    </ProfileFormSection>
  );
};

import { useInfiniteQuery } from '@tanstack/react-query';
import { ListRenderItem } from 'react-native';

import { ReviseCard } from '@iokanx/dashboard/ui';
import { searchRevisesResolver, IFindReviseResolverResultItem } from '@iokanx/shared/data-access/api';
import { createGetNextPageParam } from '@iokanx/shared/util';

import { OperationFlatList } from '../operation-flat-list/operation-flat-list';
import { IReviseListProps } from '../revise-list/revise-list';

export function ReviseListMobile(props: IReviseListProps) {
  const query = useInfiniteQuery(
    ['revises', props.form.watch()],
    (context) => searchRevisesResolver({ ...props.form.watch(), page: context.pageParam, limit: props.pageLimit }),
    {
      getNextPageParam: createGetNextPageParam({ pageLimit: props.pageLimit }),
    },
  );

  const renderItem: ListRenderItem<IFindReviseResolverResultItem> = (info) => {
    return <ReviseCard revise={info.item} onCardPress={() => props.onRevisePress(info.item)} />;
  };

  return (
    <OperationFlatList
      renderItem={renderItem}
      query={query}
      hasNotNextPageText={'Платежей больше нет'}
      emptyText={'По указанным параметрам, платежей не найдено'}
      filter={props.filter}
    />
  );
}

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { DashboardUsersTabsParamList } from '@iokanx/dashboard/feature';

import { UsersPageWrapped } from './users-page';

const MaterialTopTabNavigator = createMaterialTopTabNavigator<DashboardUsersTabsParamList>();

export default function UsersNavigator() {
  return (
    <MaterialTopTabNavigator.Navigator
      screenOptions={{
        lazy: true,
        swipeEnabled: false,
      }}
    >
      <MaterialTopTabNavigator.Screen
        name="Owners"
        component={UsersPageWrapped}
        options={{
          title: 'Пользователи',
        }}
      />
    </MaterialTopTabNavigator.Navigator>
  );
}

import { backendHttp } from '../../instances';

export interface IPostAuthForgotPasswordRequestData {
  email: string;
}

export interface IPostAuthForgotPasswordResponseData {
  detail: string;
  status: number;
}

export async function postAuthForgotPassword(
  requestData: IPostAuthForgotPasswordRequestData,
): Promise<IPostAuthForgotPasswordResponseData> {
  const { data } = await backendHttp.post('/auth/forgot-password', requestData);
  return data;
}

import { AcquirerType, Currency, PayoutStatus } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../instances';

export interface IGetRecipientResponseData {
  id: string;
  iin: string;
  iban: string;
  name: string;
  knp: string;
  kbe: string;
  description: string | null;
  created_at: string;
}

export interface IGetErrorResponseData {
  code?: string;
  message?: string;
}

export interface IGetPayoutByIdResponseData {
  id: string;
  shop_id: string;
  external_id: string;
  amount: number;
  acquirer: AcquirerType;
  pre_auth: boolean;
  currency: Currency;
  fee: number;
  recipient: IGetRecipientResponseData;
  error?: IGetErrorResponseData;
  status: PayoutStatus;
  created_at: string;
}

/** Получение заказа по ID */
export async function getPayoutById(payoutId: string): Promise<IGetPayoutByIdResponseData> {
  const { data } = await backendHttp.get<IGetPayoutByIdResponseData>(`/reports/payouts/${payoutId}`);
  return data;
}

import { http } from '../../../instances';

export interface IGetAnalyticsPaymentAcceptanceParams {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
}

export interface IGetAnalyticsPaymentAcceptanceResponseData {
  /** Успешность приема платежа в процентах */
  rate: number;
}

/** Получить метрики по приему платежей за период */
export async function getAnalyticsPaymentAcceptance(
  params: IGetAnalyticsPaymentAcceptanceParams,
): Promise<IGetAnalyticsPaymentAcceptanceResponseData> {
  const { data } = await http.get<IGetAnalyticsPaymentAcceptanceResponseData>('/analytics/payment-acceptance', {
    params,
  });
  return data;
}

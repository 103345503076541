import { CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';
import { compactObject, prepareDateTime } from '@iokanx/shared/util';

import {
  getSearchPaymentLinks,
  IGetSearchPaymentLinksRequestParams,
  IGetSearchPaymentLinksResponseData,
  IGetSearchPaymentLinksResponseDataItem,
} from '../../resources';

export type ISearchPaymentLinksResolverParams = IGetSearchPaymentLinksRequestParams;

export interface ISearchPaymentLinksResolverResultItem extends IGetSearchPaymentLinksResponseDataItem {
  /** Дата создания в формате HH:mm, DD.MM.YYYY */
  createdAt: string;
  /** Минимальная сумма платежа с символом валюты */
  minAmountWithSymbol?: string;
  /** Максимальная сумма платежа с символом валюты */
  maxAmountWithSymbol?: string;
  /** Шаг платежа с символом валюты */
  stepWithSymbol?: string;
  /** Сумма платежа с символом валюты */
  amountWithSymbol?: string;
}

export async function searchPaymentLinksResolver(
  params: ISearchPaymentLinksResolverParams,
): Promise<IGetSearchPaymentLinksResponseData<ISearchPaymentLinksResolverResultItem>> {
  const paymentLinks = await getSearchPaymentLinks(compactObject(params));

  const data = paymentLinks.data.map(transformPaymentLink);

  return { ...paymentLinks, data };
}

export function transformPaymentLink(
  paymentLink: IGetSearchPaymentLinksResponseDataItem,
): ISearchPaymentLinksResolverResultItem {
  const currencySymbol = CURRENCY_SYMBOL[paymentLink.currency];

  return {
    ...paymentLink,
    createdAt: prepareDateTime(paymentLink.created_at),
    minAmountWithSymbol: paymentLink.min_amount
      ? `${(paymentLink.min_amount / 100).toLocaleString()} ${currencySymbol}`
      : undefined,
    maxAmountWithSymbol: paymentLink.max_amount
      ? `${(paymentLink.max_amount / 100).toLocaleString()} ${currencySymbol}`
      : undefined,
    stepWithSymbol: paymentLink.step ? `${(paymentLink.step / 100).toLocaleString()} ${currencySymbol}` : undefined,
    amountWithSymbol: paymentLink.amount
      ? `${(paymentLink.amount / 100).toLocaleString()} ${currencySymbol}`
      : undefined,
  };
}

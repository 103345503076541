import { Stack } from '@mobily/stacks';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { StyleSheet } from 'react-native';

import { TodayAnalyticsChart } from '@iokanx/dashboard/ui';
import { getAggregationAnalyticsResolver, getTimelineAnalyticsResolver } from '@iokanx/shared/data-access/api';
import { Title, Caption, SimpleCard, Card } from '@iokanx/shared/ui/universal';

export function TodaysAnalytics() {
  const from_dt = moment().startOf('day').toDate();
  const to_dt = moment().endOf('day').toDate();

  const { data: timelineAnalytics } = useQuery(['timeline-analytics', from_dt, to_dt], () =>
    getTimelineAnalyticsResolver({ from_dt, to_dt }),
  );
  const { data: aggregationAnalytics } = useQuery(['aggregation-analytics', from_dt, to_dt], () =>
    getAggregationAnalyticsResolver({ from_dt, to_dt }),
  );

  const capturedChartData = timelineAnalytics?.capturedAmounts.map((capturedAmount, index) => {
    const date = timelineAnalytics.timeline[index];

    return {
      x: date,
      y: capturedAmount,
      label: `Списано: ${capturedAmount?.toLocaleString()}`,
    };
  });

  const approvedChartData = timelineAnalytics?.approvedAmounts.map((approvedAmount, index) => {
    const date = timelineAnalytics.timeline[index];

    return {
      x: date,
      y: approvedAmount,
      label: `Авторизовано: ${approvedAmount?.toLocaleString()}`,
    };
  });

  return (
    <SimpleCard
      title={
        <Stack horizontal space={2}>
          <Title>Сегодня</Title>
          <Title style={styles.todayDate}>{moment().format('DD MMMM')}</Title>
        </Stack>
      }
    >
      <Card.Content>
        <Stack space={6}>
          <Stack horizontal space={6}>
            <Stack>
              <Caption>Сумма успешных платежей</Caption>
              <Title>{aggregationAnalytics?.approvedAmountWithSymbol}</Title>
            </Stack>
            <Stack>
              <Caption>Количество успешных платежей</Caption>
              <Title>{aggregationAnalytics?.approvedCount}</Title>
            </Stack>
          </Stack>
          <TodayAnalyticsChart approvedChartData={approvedChartData} capturedChartData={capturedChartData} />
        </Stack>
      </Card.Content>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  todayDate: {
    color: '#94A3B8',
  },
});

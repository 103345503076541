import { styled } from '@mui/material';

import { PaymentSystem } from '@iokanx/shared/data-access/constants';

import { CDNImage } from '../cdnimage/cdnimage';

export interface IPaymentSystemLogoProps {
  paymentSystem: PaymentSystem;
}

const PAYMENT_SYSTEM_LOGO: Record<PaymentSystem, string> = {
  [PaymentSystem.AmericanExpress]: 'american-express',
  [PaymentSystem.Mastercard]: 'mastercard',
  [PaymentSystem.Visa]: 'visa',
  [PaymentSystem.Mir]: 'mir',
  [PaymentSystem.Maestro]: 'maestro',
  [PaymentSystem.UnionPay]: 'union_pay',
  [PaymentSystem.NotDefined]: '',
};

const Img = styled(CDNImage)({
  borderWidth: 0.5,
  borderStyle: 'solid',
  borderColor: '#E2E8F0',
  borderRadius: 2,
});

export function PaymentSystemLogo(props: IPaymentSystemLogoProps) {
  const src = `payment-systems/${PAYMENT_SYSTEM_LOGO[props.paymentSystem]}-logo.svg`;
  const alt = `${props.paymentSystem} logo`;

  return <Img src={src} alt={alt} height={20} width={30} />;
}

import { http } from '../../../../instances';

export async function getOrderReceiptByOrderId(orderId: string): Promise<Blob> {
  const { data } = await http.get(`/orders/${orderId}/receipt`, { responseType: 'blob' });
  return data;
}

export async function getOrderFiscalReceiptByOrderId(orderId: string): Promise<Blob> {
  const { data } = await http.get(`/orders/${orderId}/check`, { responseType: 'blob' });
  return data;
}

export async function getRefundReceipt(refundId: string): Promise<Blob> {
  const { data } = await http.get(`/orders/refunds/${refundId}/receipt`, { responseType: 'blob' });
  return data;
}

import { RefundStatus } from '@iokanx/shared/data-access/constants';
import { IAcquirer, IHttpError } from '@iokanx/shared/data-access/types';

import { http } from '../../../../instances';

export interface IPostOrdersByIdRefundsRequestDataRule {
  /** Идентификатор счета */
  account_id: string;
  /** Сумма расщепления */
  amount: number;
}
export interface IPostOrdersByIdRefundsRequestData {
  /**
   * Сумма возврата в минорных денежных единицах.
   *
   * Например, для создания возврата на сумму 500 тенге, необходимо ввести 50000 (1 тенге = 100 тиын)
   *
   * @moreOrEqualThan 100
   */
  amount: number;
  /**
   * Причина совершения операции
   *
   * <= 255 characters
   */
  reason?: string;
  /** Правила расщепления суммы возврата */
  rules?: IPostOrdersByIdRefundsRequestDataRule[];
}

export interface IPostOrdersByIdRefundsResponseData {
  /** Идентификатор возврата */
  id: string;
  /** Идентификатор родительского платежа */
  payment_id: string;
  /** Идентификатор родительского заказа */
  order_id: string;
  /** Статус возврата */
  status: RefundStatus;
  /** Время создания возврата */
  created_at?: string;
  /**
   * Детальное описание ошибки платежа.
   *
   * Присутствует если status = DECLINED
   */
  error?: IHttpError;
  /** Данные банка эквайера */
  acquirer?: IAcquirer;
}

/** Создание нового возврата по списанному платежу */
export async function postOrdersByIdRefunds(
  orderId: string,
  requestData: IPostOrdersByIdRefundsRequestData,
): Promise<IPostOrdersByIdRefundsResponseData> {
  const { data } = await http.post<IPostOrdersByIdRefundsResponseData>(`/orders/${orderId}/refunds`, requestData);
  return data;
}

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { DashboardMerchantsTabsParamList } from '@iokanx/dashboard/feature';

import { ShopsPage } from '../shops-page/shops-page';

const MaterialTopTabNavigator = createMaterialTopTabNavigator<DashboardMerchantsTabsParamList>();

export default function MerchantsPage() {
  return (
    <MaterialTopTabNavigator.Navigator
      screenOptions={{
        lazy: true,
        swipeEnabled: false,
      }}
    >
      <MaterialTopTabNavigator.Screen
        name="Shops"
        component={ShopsPage}
        options={{
          title: 'Магазины',
        }}
      />
    </MaterialTopTabNavigator.Navigator>
  );
}

import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isString } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { IQueryParamsType, ICardFormType, IPreSavedCardType, IZoidProps } from '@iokanx/payment-form/types';
import { CustomHead } from '@iokanx/payment-form/ui';
import {
  createCardPaymentResolver,
  findCustomerCardByIdResolver,
  findShopFeaturesResolver,
  ICreateCardPaymentResolverParams,
  ICreateCardPaymentResolverResult,
  IFindShopFeaturesResolverResult,
  ISearchOrderItem,
} from '@iokanx/shared/data-access/api';
import { PaymentFormTemplate, AVIATA_TEMPLATES, ShopFeature } from '@iokanx/shared/data-access/constants';
import { HttpErrorCode, IHttpError } from '@iokanx/shared/data-access/types';
import { getAmountWithCurrency } from '@iokanx/shared/util';

import { ApplePay } from '../apple-pay/apple-pay';
import { AviataHeader } from '../aviata-header/aviata-header';
import { AviataPaymentForm } from '../aviata-payment-form/aviata-payment-form';
import { GooglePayBtn } from '../google-pay-btn/google-pay-btn';
import { useShopConfig } from '../use-shop-config/use-shop-config';

interface IPaymentFormContainerProps {
  order: ISearchOrderItem;
  orderId: string;
  widgetOptions?: IZoidProps | null;
  queryParams: IQueryParamsType;
  handleSetInterval: (value: number | false) => void;
  isApplePayAvailable?: boolean;
  shopConfig?: IFindShopFeaturesResolverResult;
  onError?: (error: IHttpError) => void;
}

const APPLE_PAY_TEMPLATES: string[] = [
  PaymentFormTemplate.CUSTOM_AVIATA_APPLE_PAY,
  PaymentFormTemplate.CUSTOM_CHOCOTRAVEL_APPLE_PAY,
];

const GOOGLE_PAY_TEMPLATES: string[] = [
  PaymentFormTemplate.CUSTOM_AVIATA_GOOGLE_PAY,
  PaymentFormTemplate.CUSTOM_CHOCOTRAVEL_GOOGLE_PAY,
];

export function AviataPaymentFormContainer({
  order,
  orderId,
  widgetOptions,
  queryParams,
  handleSetInterval,
  onError,
  shopConfig,
}: IPaymentFormContainerProps) {
  const { t } = useTranslation();

  const { data: shopFeatures } = useQuery(
    ['shop-features', order?.shop_id],
    () => order && findShopFeaturesResolver({ shopId: order?.shop_id }),
  );
  const isApplePayAvailable = shopFeatures?.features.includes(ShopFeature.ApplePay);

  const { template } = order;
  const isAviata = Boolean(template && AVIATA_TEMPLATES.includes(template));

  const isApplePay = Boolean(template && APPLE_PAY_TEMPLATES.includes(template));
  const googlePayOnly = Boolean(template && GOOGLE_PAY_TEMPLATES.includes(template));

  const [fetching, setFetching] = useState<boolean>(false);
  const [isToken, setIsToken] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IPreSavedCardType>({
    cardNumber: '',
    expireDate: '',
    saveCard: queryParams.defaultSaveCard,
  });

  useQuery(
    ['customer-card', order.customer_id],
    () => {
      if (order.customer_id && order.card_id) {
        return findCustomerCardByIdResolver({ customerId: order.customer_id, cardId: order.card_id });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        setIsToken(true);

        if (data) {
          setInitialValues({
            cardNumber: data.pan_masked,
            expireDate: data.expiry_date,
          });
        }
      },
      enabled: Boolean(order.customer_id && order.card_id),
    },
  );

  const payMutation = useMutation<
    ICreateCardPaymentResolverResult,
    AxiosError<IHttpError>,
    Omit<ICreateCardPaymentResolverParams, 'orderId'>
  >((variables) => createCardPaymentResolver({ orderId: order.id, ...variables }), {
    onError: (error) => {
      const isDeadlineReached =
        error.response?.data.code &&
        [HttpErrorCode.ProcessingDeadlineReachedError, HttpErrorCode.ProcessingDeadlineReached].includes(
          error.response.data.code,
        );

      if (isDeadlineReached) {
        return handleSetInterval(3000);
      }

      if (onError && error.response?.data) {
        onError(error.response?.data);
      }

      setFetching(false);
    },
    onSuccess: () => {
      handleSetInterval(1000);
    },
  });

  function handleSubmit(values: ICardFormType): void {
    setFetching(true);

    payMutation.mutate({
      cvc: values.cardCvC,
      exp: `${values.cardExpireMonth}/${values.cardExpireYear}`,
      pan: values.cardNumber,
      email: values.email,
      holder: values.cardHolder,
      save: values.saveCard,
      card_id: order.card_id || undefined,
      phone: values.phone,
    });
  }

  const orderNumber =
    (isString(order.extra_info?.['order_number']) && order.extra_info?.['order_number']) || order.external_id;

  const isSaveCard = !queryParams.hideSaveCard && !isToken;

  const { config, isConfigLoading } = useShopConfig();
  const additionalFieldNames = config?.card_payment?.additional_fields?.map((field) => field.name) || [];

  if (isConfigLoading) return null;

  return (
    <>
      <CustomHead titleText={`${t('Оплата заказа')} №${order.id}`} />
      {
        <AviataHeader
          isAviata={isAviata}
          isAviataRw={template === PaymentFormTemplate.CUSTOM_AVIATA_RW}
          orderId={orderNumber}
          expiryDate={order.due_date}
        />
      }

      {isApplePay ? (
        <div className="card-form card-form--apple-pay">
          <ApplePay
            orderId={orderId}
            handleSetInterval={handleSetInterval}
            amount={`${order.display_amount / 100}`}
            merchantId={shopConfig?.apple_pay?.merchant_id}
            merchantName={shopConfig?.apple_pay?.display_name}
          />
        </div>
      ) : googlePayOnly ? (
        <div className="card-form card-form--google-pay">
          <GooglePayBtn
            orderId={orderId}
            merchantName={queryParams.merchantName}
            handleSetInterval={handleSetInterval}
            merchantId="1"
            total={`${order.display_amount / 100}`}
          />
        </div>
      ) : (
        <AviataPaymentForm
          isSaveCard={isSaveCard}
          onSubmit={handleSubmit}
          amount={getAmountWithCurrency(order.display_amount / 100, order.currency)}
          widgetProps={widgetOptions?.widgetProps}
          handleSetInterval={handleSetInterval}
          initialValues={initialValues}
          isWaitPaymentInfo={fetching}
          merchantName={queryParams.merchantName}
          orderId={orderId}
          amountValue={order.display_amount}
          isApplePayAvailable={isApplePayAvailable}
          isTokenPayment={isToken}
          template={template}
          headerText={t('Оплата заказа')}
          additionalFields={additionalFieldNames}
          shopConfig={config}
        />
      )}
    </>
  );
}

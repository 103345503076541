import { patchAdminOrderById } from '../../../resources';

export interface IUpdateAdminOrderExtraInfoByIdParams {
  id: string;
  extraInfo: object;
}

export function updateAdminOrderExtraInfoById(params: IUpdateAdminOrderExtraInfoByIdParams) {
  return patchAdminOrderById(params.id, { extra_info: params.extraInfo });
}

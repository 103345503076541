export enum OrderEventName {
  ORDER_CREATED = 'ORDER_CREATED',
  PAYMENT_CREATED = 'PAYMENT_CREATED',
  REFUND_CREATED = 'REFUND_CREATED',
  INSTALLMENT_CREATED = 'INSTALLMENT_CREATED',
  SPLIT_CREATED = 'SPLIT_CREATED',
  ORDER_ON_HOLD = 'ORDER_ON_HOLD',
  ORDER_PAID = 'ORDER_PAID',
  /** При ошибках */
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  /** При проверке 3DS (pareq, acs_url, etc) */
  PAYMENT_ACTION_REQUIRED = 'PAYMENT_ACTION_REQUIRED',
  /** При авторизации двухфакторки */
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  /** При списании после однофакторки или двухфакторки */
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
  CAPTURE_DECLINED = 'CAPTURE_DECLINED',
  /** При отмене после двухфакторки */
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  CANCEL_DECLINED = 'CANCEL_DECLINED',
  /** При возврате после однофакторки или двухфакторки */
  REFUND_APPROVED = 'REFUND_APPROVED',
  REFUND_DECLINED = 'REFUND_DECLINED',
  /** При авторизации расщепления */
  SPLIT_APPROVED = 'SPLIT_APPROVED',
  SPLIT_DECLINED = 'SPLIT_DECLINED',
  OTP_SENT = 'OTP_SENT',
  SEND_OTP_DECLINED = 'SEND_OTP_DECLINED',
  OTP_CONFIRMED = 'OTP_CONFIRMED',
  CONFIRM_OTP_DECLINED = 'CONFIRM_OTP_DECLINED',
  INSTALLMENT_ACTION_REQUIRED = 'INSTALLMENT_ACTION_REQUIRED',
  INSTALLMENT_ISSUED = 'INSTALLMENT_ISSUED',
  INSTALLMENT_REJECTED = 'INSTALLMENT_REJECTED',
  INSTALLMENT_DECLINED = 'INSTALLMENT_DECLINED',
}

import { ISearchPaymentLinksResolverResultItem } from '@iokanx/shared/data-access/api';
import { PaymentLinkType } from '@iokanx/shared/data-access/constants';
import { Text } from '@iokanx/shared/ui/universal';

export interface IPaymentLinkAmountProps {
  paymentLink: ISearchPaymentLinksResolverResultItem;
}

export function PaymentLinkAmount(props: IPaymentLinkAmountProps) {
  switch (props.paymentLink.link_type) {
    case PaymentLinkType.Ranged:
      return (
        <Text>{`${props.paymentLink.minAmountWithSymbol} - ${props.paymentLink.maxAmountWithSymbol} (${props.paymentLink.stepWithSymbol})`}</Text>
      );
    case PaymentLinkType.Regular:
      return <Text>{props.paymentLink.amountWithSymbol}</Text>;
    default:
      return null;
  }
}

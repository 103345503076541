import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Column, Columns, Stack, Tiles } from '@mobily/stacks';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { VictoryStack, VictoryBar } from 'victory-native';

import { ConversionTooltip, VictoryMaterialTheme } from '@iokanx/dashboard/ui';
import {
  findPaymentAcceptanceAnalyticsResolver,
  getPaymentStatusAnalyticsResolver,
  IGetPaymentStatusAnalyticsResolverResultDataItem,
} from '@iokanx/shared/data-access/api';
import { Caption, Title, Text, SimpleCard, Card, ToggleButton } from '@iokanx/shared/ui/universal';

export interface IPaymentAnalyticsProps {
  from_dt?: Date;
  to_dt?: Date;
}

export enum PaymentStatusAnalyticsType {
  Numeric = 'numeric',
  Percent = 'percent',
}

const CHART_SIZE = 300;

export function PaymentAnalytics(props: IPaymentAnalyticsProps) {
  const [paymentAnalyticsType, setPaymentAnalyticsType] = useState<PaymentStatusAnalyticsType>(
    PaymentStatusAnalyticsType.Numeric,
  );

  const { data: paymentStatusAnalytics } = useQuery(['payment-status-analytics', props], () =>
    getPaymentStatusAnalyticsResolver(props),
  );

  function transformPaymentStatusCount(data: IGetPaymentStatusAnalyticsResolverResultDataItem) {
    switch (paymentAnalyticsType) {
      case PaymentStatusAnalyticsType.Numeric:
        return data.count;
      case PaymentStatusAnalyticsType.Percent:
        if (!paymentStatusAnalytics) return null;
        return (data.count / paymentStatusAnalytics.totalPaymentsCount) * 100;
    }
  }

  const paymentAcceptanceAnalyticsQuery = useQuery(['payment-acceptance-analytics', props], () =>
    findPaymentAcceptanceAnalyticsResolver(props),
  );

  return (
    <SimpleCard
      title={
        <Stack space={1} horizontal align={'center'}>
          <Title>Конверсия - {paymentAcceptanceAnalyticsQuery.data?.rate}</Title>
          <ConversionTooltip />
        </Stack>
      }
      right={(rightProps) => (
        <ToggleButton.Row
          onValueChange={(value) => {
            if (value) {
              setPaymentAnalyticsType(value as PaymentStatusAnalyticsType);
            }
          }}
          value={paymentAnalyticsType}
        >
          <ToggleButton icon="numeric" value={PaymentStatusAnalyticsType.Numeric} size={rightProps.size} />
          <ToggleButton icon="percent" value={PaymentStatusAnalyticsType.Percent} size={rightProps.size} />
        </ToggleButton.Row>
      )}
      rightStyle={styles.cardRightStyle}
      cardStyle={styles.card}
    >
      <Card.Content>
        <Stack space={6}>
          <Stack>
            <Caption>Количество платежей</Caption>
            <Title>{paymentStatusAnalytics?.totalPaymentsCount}</Title>
          </Stack>
          <Stack space={6}>
            <VictoryStack horizontal theme={VictoryMaterialTheme} height={CHART_SIZE} width={CHART_SIZE * 10}>
              {paymentStatusAnalytics?.data.map((item) => (
                <VictoryBar
                  key={item.paymentStatus}
                  data={[{ x: item.paymentStatus, y: transformPaymentStatusCount(item) }]}
                  style={{ data: { fill: item.color } }}
                  barWidth={CHART_SIZE}
                />
              ))}
            </VictoryStack>
            <Tiles space={2} columns={2}>
              {paymentStatusAnalytics?.data.map((item) => {
                const paymentStatusCount = transformPaymentStatusCount(item);

                return (
                  <Columns key={item.paymentStatus} space={2} alignY={'center'}>
                    <Column width={'content'}>
                      <Icon name={'circle'} color={item.color} />
                    </Column>
                    <Column width={'content'}>
                      <Caption>{item.title}</Caption>
                    </Column>
                    <Column>
                      <Text>
                        {paymentAnalyticsType === PaymentStatusAnalyticsType.Percent
                          ? `${paymentStatusCount?.toFixed(2)} %`
                          : paymentStatusCount}
                      </Text>
                    </Column>
                  </Columns>
                );
              })}
            </Tiles>
          </Stack>
        </Stack>
      </Card.Content>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  cardRightStyle: {
    paddingRight: 16,
  },
  card: {
    flex: 1,
  },
});

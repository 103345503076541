import { randomColor } from '@iokanx/shared/util';

import {
  getCdnDashboardBinapiPublicEmitter,
  IGetCdnDashboardBinapiPublicEmitterResponseDataItem,
} from '../../resources';

export interface IFindEmittersResolverResultItem extends IGetCdnDashboardBinapiPublicEmitterResponseDataItem {
  color: string;
}
export type IFindEmittersResolverResult = IFindEmittersResolverResultItem[];

export async function findEmittersResolver(): Promise<IFindEmittersResolverResult> {
  const emitters = await getCdnDashboardBinapiPublicEmitter();

  return emitters.map(transformEmitter);
}

export function transformEmitter(
  emitter: IGetCdnDashboardBinapiPublicEmitterResponseDataItem,
): IFindEmittersResolverResultItem {
  const color = emitter.color || randomColor();

  return { ...emitter, color };
}

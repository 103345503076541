import { DashboardPaymentStatus, DASHBOARD_PAYMENT_STATUS_I18N } from '@iokanx/shared/data-access/constants';

import { StatusChip, StatusChipPropsIconName, StatusChipVariant } from '../status-chip/status-chip';

interface IDashboardPaymentStatusChipProps {
  status: DashboardPaymentStatus;
}

const statusChipVariant: Record<DashboardPaymentStatus, StatusChipVariant> = {
  [DashboardPaymentStatus.EXPIRED]: StatusChipVariant.Failure,
  [DashboardPaymentStatus.DECLINED]: StatusChipVariant.Failure,
  [DashboardPaymentStatus.UNPAID]: StatusChipVariant.Loading,
  [DashboardPaymentStatus.PENDING]: StatusChipVariant.Loading,
  [DashboardPaymentStatus.REQUIRES_ACTION]: StatusChipVariant.Loading,
  [DashboardPaymentStatus.CANCELLED]: StatusChipVariant.Loading,
  [DashboardPaymentStatus.APPROVED]: StatusChipVariant.Success,
  [DashboardPaymentStatus.CAPTURED]: StatusChipVariant.Success,
  [DashboardPaymentStatus.PARTIALLY_REFUNDED]: StatusChipVariant.Success,
  [DashboardPaymentStatus.REFUNDED]: StatusChipVariant.Success,
};

const statusChipIconName: Record<DashboardPaymentStatus, StatusChipPropsIconName> = {
  [DashboardPaymentStatus.UNPAID]: 'loading',
  [DashboardPaymentStatus.EXPIRED]: 'clock',
  [DashboardPaymentStatus.DECLINED]: 'close',
  [DashboardPaymentStatus.PENDING]: 'loading',
  [DashboardPaymentStatus.REQUIRES_ACTION]: 'loading',
  [DashboardPaymentStatus.CANCELLED]: 'loading',
  [DashboardPaymentStatus.APPROVED]: 'check',
  [DashboardPaymentStatus.CAPTURED]: 'check',
  [DashboardPaymentStatus.PARTIALLY_REFUNDED]: 'check',
  [DashboardPaymentStatus.REFUNDED]: 'check',
};

export function DashboardPaymentStatusChip(props: IDashboardPaymentStatusChipProps) {
  return (
    <StatusChip variant={statusChipVariant[props.status]} iconName={statusChipIconName[props.status]}>
      {DASHBOARD_PAYMENT_STATUS_I18N[props.status]}
    </StatusChip>
  );
}

import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Column, Columns, Stack, Tiles } from '@mobily/stacks';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { VictoryBar, VictoryStack } from 'victory-native';

import { ConversionTooltip, VictoryMaterialTheme } from '@iokanx/dashboard/ui';
import { findAcquirersAnalytics, IFindAcquirersAnalyticsResolverResultItem } from '@iokanx/shared/data-access/api';
import { Caption, Card, SimpleCard, Title, ToggleButton } from '@iokanx/shared/ui/universal';

export interface AcquirersAnalyticsProps {
  from_dt?: Date;
  to_dt?: Date;
}

export enum AcquirerAnalyticsType {
  Numeric = 'numeric',
  Percent = 'percent',
}

const CHART_SIZE = 300;

export function AcquirersAnalytics(props: AcquirersAnalyticsProps) {
  const [acquirerAnalyticsType, setAcquirerAnalyticsType] = useState<AcquirerAnalyticsType>(
    AcquirerAnalyticsType.Numeric,
  );

  function transformAcquirerCount(data: IFindAcquirersAnalyticsResolverResultItem) {
    switch (acquirerAnalyticsType) {
      case AcquirerAnalyticsType.Numeric:
        return data.count;
      case AcquirerAnalyticsType.Percent:
        if (!acquirersAnalytics) return null;
        return (data.count / acquirersAnalytics.totalPaymentsCount) * 100;
    }
  }

  const { data: acquirersAnalytics } = useQuery(['acquirers-analytics', props], () => findAcquirersAnalytics(props));

  return (
    <SimpleCard
      title={
        <Stack space={1} horizontal align={'center'}>
          <Title>Эквайринг</Title>
        </Stack>
      }
      right={(rightProps) => (
        <ToggleButton.Row
          onValueChange={(value) => {
            if (value) {
              setAcquirerAnalyticsType(value as AcquirerAnalyticsType);
            }
          }}
          value={acquirerAnalyticsType}
        >
          <ToggleButton icon="numeric" value={AcquirerAnalyticsType.Numeric} size={rightProps.size} />
          <ToggleButton icon="percent" value={AcquirerAnalyticsType.Percent} size={rightProps.size} />
        </ToggleButton.Row>
      )}
      rightStyle={styles.cardRightStyle}
      cardStyle={styles.card}
    >
      <Card.Content>
        <Stack space={6}>
          <Stack>
            <Caption>Количество</Caption>
            <Title>{acquirersAnalytics?.totalPaymentsCount}</Title>
          </Stack>
          <Stack space={6}>
            <VictoryStack horizontal theme={VictoryMaterialTheme} height={CHART_SIZE} width={CHART_SIZE * 10}>
              {acquirersAnalytics?.data.map((item) => (
                <VictoryBar
                  key={item.acquirer}
                  data={[{ x: item.acquirer, y: transformAcquirerCount(item) }]}
                  style={{ data: { fill: item.color } }}
                  barWidth={CHART_SIZE}
                />
              ))}
            </VictoryStack>
            <Tiles space={2} columns={2}>
              {acquirersAnalytics?.data.map((item) => {
                const acquirerCount = transformAcquirerCount(item);

                return (
                  <Columns key={item.acquirer} space={2} alignY={'center'}>
                    <Column width={'content'}>
                      <Icon name={'circle'} color={item.color} />
                    </Column>
                    <Column width={'content'}>
                      <Caption>{item.title}</Caption>
                    </Column>
                    <Column>
                      <Text style={{ textAlign: 'right' }}>
                        {acquirerAnalyticsType === AcquirerAnalyticsType.Percent
                          ? acquirerCount?.toFixed(2)
                          : acquirerCount}
                      </Text>
                    </Column>
                  </Columns>
                );
              })}
            </Tiles>
          </Stack>
        </Stack>
      </Card.Content>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  cardRightStyle: {
    paddingRight: 16,
  },
  card: {
    flex: 1,
  },
});

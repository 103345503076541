import { uniq } from 'lodash';
import { useTranslation } from 'next-i18next';

import { IToggleButtonItem, ToggleButton } from '@iokanx/payment-form/ui';
import { IFindShopFeaturesResolverResult } from '@iokanx/shared/data-access/api';
import { ShopFeature } from '@iokanx/shared/data-access/constants';

import { useQueryParamShopFeature } from '../use-query-param-shop-feature/use-query-param-shop-feature';

const ITEM_TITLE: Partial<Record<ShopFeature, string>> = {
  [ShopFeature.CardPay]: 'Картой',
  [ShopFeature.Installment]: 'В рассрочку',
};

const availablePaymentMethods = Object.values([ShopFeature.CardPay, ShopFeature.Installment]);

export interface ITogglePaymentTypeButtonProps {
  shopConfig?: IFindShopFeaturesResolverResult;
}

export function TogglePaymentTypeButton(props: ITogglePaymentTypeButtonProps) {
  const { t } = useTranslation();

  const [paymentType, setPaymentType] = useQueryParamShopFeature();

  const items: IToggleButtonItem<ShopFeature>[] = uniq(props.shopConfig?.features)
    .filter((feature) => availablePaymentMethods.includes(feature))
    .map((value) => ({
      title: t(ITEM_TITLE[value] || ''),
      value,
    }));

  if (items.length < 2) return null;

  return <ToggleButton items={items} value={paymentType} onChange={setPaymentType} />;
}

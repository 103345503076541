import { ComponentType } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { useTheme } from 'react-native-paper';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { Scene } from 'react-native-tab-view/lib/typescript/types';

export interface ITab<Key> {
  key: Key;
  label: string;
  content: ComponentType<unknown>;
}

export interface ITabsProps<Key> {
  tabs: ITab<Key>[];
  tabContainerStyle?: StyleProp<ViewStyle>;
  onChange: (key: Key) => void;
  value: Key;
}

export function Tabs<Key extends string>(props: ITabsProps<Key>) {
  const theme = useTheme();

  const scenes = props.tabs.reduce((acc, tab) => ({ ...acc, [tab.key]: () => null }), {});
  const routes = props.tabs.map(({ key, label }) => ({ key, title: label }));

  const renderScene = SceneMap(scenes);

  const index = routes.findIndex(({ key }) => key === props.value);

  function handleChangeByIndex(index: number): void {
    props.onChange(props.tabs[index].key);
  }
  function handleChangeByScene(scene: Scene<ITab<Key>>) {
    props.onChange(scene.route.key);
  }

  return (
    <>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={handleChangeByIndex}
        renderTabBar={(tabBarProps) => (
          <TabBar
            {...tabBarProps}
            indicatorStyle={{ backgroundColor: theme.colors.primary }}
            style={{ backgroundColor: theme.colors.background }}
            activeColor={theme.colors.primary}
            inactiveColor={theme.colors.disabled}
            labelStyle={styles.labelStyle}
            onTabPress={handleChangeByScene}
          />
        )}
      />
      <View style={props.tabContainerStyle}>{props.tabs[index].content()}</View>
    </>
  );
}

const styles = StyleSheet.create({ labelStyle: { fontWeight: '500' } });

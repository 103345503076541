import { Button, styled, ButtonProps } from '@mui/material';

const StyledButton = styled(Button)(() => ({
  backgroundSize: '8px 8px',
  backgroundPosition: '0 0, 4px 0, 4px -4px, 0px 4px',
  transition: 'none',
  boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
  border: 0,
  borderRadius: 4,
  width: '24px',
  aspectRatio: '1 / 1',
  height: '24px',
  minWidth: 0,
}));

type ColorButtonProps = Omit<ButtonProps, 'children'> & {
  bgColor: string;
  isBgColorValid: boolean;
  disablePopover: boolean;
};

export const BG_IMAGE_FALLBACK =
  'linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(135deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(135deg, transparent 75%, #ccc 75%)';

export const ColorButton = (props: ColorButtonProps) => {
  const { bgColor, className, disablePopover, isBgColorValid, ...restButtonProps } = props;

  return (
    <StyledButton
      disableTouchRipple
      style={{
        backgroundColor: isBgColorValid ? bgColor : undefined,
        backgroundImage: isBgColorValid ? undefined : BG_IMAGE_FALLBACK,
        cursor: disablePopover ? 'default' : undefined,
      }}
      className={`MuiColorInput-Button ${className || ''}`}
      {...restButtonProps}
    />
  );
};

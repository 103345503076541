import { ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { Divider as PaperDivider } from 'react-native-paper';

export function Divider(props: ComponentProps<typeof PaperDivider>) {
  return <PaperDivider {...props} style={[props.style, styles.divider]} />;
}

const styles = StyleSheet.create({
  divider: {
    backgroundColor: '#E2E8F0',
  },
});

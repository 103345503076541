import { http } from '../../../../instances';
import { IGetOrderByIdPaymentByIdResponseData } from '../payments/by-id';

export interface IPostOrdersByIdCaptureRequestData {
  /**
   * Сумма списания в минорных денежных единицах
   *
   * Например, для списания 500 тенге, необходимо ввести 50000 (1 тенге = 100 тиын)
   *
   * >= 100
   */
  amount: number;
  /**
   * Причина совершения операции
   *
   * <= 255 characters
   */
  reason?: string;
}

export type IPostOrdersByIdCaptureResponseData = IGetOrderByIdPaymentByIdResponseData;

/** Полное или частичное списание авторизованного платежа заказа */
export async function postOrdersByIdCapture(
  orderId: string,
  requestData: IPostOrdersByIdCaptureRequestData,
): Promise<IPostOrdersByIdCaptureResponseData> {
  const { data } = await http.post<IPostOrdersByIdCaptureResponseData>(`/orders/${orderId}/capture`, requestData);
  return data;
}

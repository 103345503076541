import { prepareDateTime } from '@iokanx/shared/util';

import { getConfigByShopId, IGetConfigByShopsIdResponseData } from '../../resources';

export interface IFindConfigByShopIdResult extends IGetConfigByShopsIdResponseData {
  // Нормализованное имя магазина
  shopId?: string;
  displayName?: string;
  createdAt?: string;
}

export async function findConfigByShopIdResolver(shopId: string): Promise<IFindConfigByShopIdResult> {
  const config = await getConfigByShopId(shopId);
  return transformConfig(config);
}

export function transformConfig(config: IGetConfigByShopsIdResponseData): IFindConfigByShopIdResult {
  return {
    ...config,
    shopId: config.shop_id,
    displayName: config.shop_name,
    createdAt: prepareDateTime(config.created_at),
  };
}

import { ShopUserRole } from './shop-user-role';

export enum ShopStatus {
  /** Создан */
  Pending = 'PENDING',
  /**
   * В обработке
   *
   * @description Проверяются документы
   **/
  Review = 'REVIEW',
  /**
   * Принят
   *
   * @description Может принимать платежи и подучать выплаты по ним
   **/
  Accepted = 'ACCEPTED',
  /**
   * Приостановлен
   *
   * @description Пока не может принимать платежи
   **/
  Suspended = 'SUSPENDED',
  /**
   * Отказано
   *
   * @description Некорректные данные или доки. может переподать заявку
   **/
  Rejected = 'REJECTED',
}

export const SHOP_STATUS_TITLE: Record<ShopStatus, string> = {
  [ShopStatus.Pending]: 'Создан',
  [ShopStatus.Review]: 'В обработке',
  [ShopStatus.Accepted]: 'Принят',
  [ShopStatus.Suspended]: 'Приостановлен',
  [ShopStatus.Rejected]: 'Отказано',
};

export const SHOP_STATUS_COLOR: Record<ShopStatus, string> = {
  [ShopStatus.Review]: '#334155',
  [ShopStatus.Pending]: '#334155',
  [ShopStatus.Accepted]: '#10B981',
  [ShopStatus.Suspended]: '#EF4444',
  [ShopStatus.Rejected]: '#EF4444',
};

export const SHOP_STATUS_LIST = [
  ShopStatus.Review,
  ShopStatus.Pending,
  ShopStatus.Accepted,
  ShopStatus.Suspended,
  ShopStatus.Rejected,
] as const;

import { Button, Stack, Typography } from '@mui/material';
import { DataGrid, GridColumns, ruRU } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ScrollView } from 'react-native';

import { ShopNameChip } from '@iokanx/dashboard/ui';
import { IGetFulfilmentByIdResponseData, searchFulfilmentsResolver } from '@iokanx/shared/data-access/api';
import { Icon } from '@iokanx/shared/ui/universal';

import { useMe } from '../auth';
import { IFulfilmentListProps } from '../fulfilment-list/fulfilment-list';

const columns: GridColumns<IGetFulfilmentByIdResponseData> = [
  {
    headerName: 'Дата',
    field: 'date',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Магазин',
    field: 'shopId',
    flex: 1,
    renderCell: (props) => {
      return (
        <Suspense fallback={<Typography>loading...</Typography>}>
          <ShopNameChip shopId={props.value} />
        </Suspense>
      );
    },
    sortable: false,
  },
  {
    headerName: 'Аккаунт',
    field: 'account',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Сумма',
    field: 'amountWithSymbol',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Комиссия',
    field: 'processingFeeWithSymbol',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Выплата',
    field: 'payoutWithSymbol',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Выгрузка',
    field: 'report_url',
    flex: 1,
    renderCell: (props) => (
      <a href={props.value} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Button sx={{ borderRadius: '8px', minWidth: '28px', paddingX: '8px' }}>
          <Icon name={'download'} color={'#fff'} size={20} />
        </Button>
      </a>
    ),
    sortable: false,
  },
];

const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам, отчетов не найдено',
};

export function FulfilmentListDesktop(props: IFulfilmentListProps) {
  const { data: fulfilments = { count: 0, data: [] }, isFetching } = useQuery(['fulfilments', props.form.watch()], () =>
    searchFulfilmentsResolver({ ...props.form.watch(), limit: props.pageLimit }),
  );

  const { me } = useMe();

  return (
    <ScrollView>
      <Stack padding={2} spacing={2} marginBottom={8}>
        {props.filter}

        <DataGrid
          rows={fulfilments.data}
          columnVisibilityModel={{
            shopId: !!me?.is_superuser,
          }}
          columns={columns}
          loading={isFetching}
          page={props.form.watch('page') - 1}
          onRowClick={(params) => props.onFulfilmentPress(params.row)}
          onPageChange={(page) => props.form.setValue('page', page + 1)}
          rowCount={fulfilments.count}
          localeText={
            props.isToday
              ? {
                  ...localeText,
                  noRowsLabel:
                    'Отчет за текущий день еще не сформирован. Для получения предварительного отчета, выберите в календаре текущий день и нажмите на кнопку "Выгрузить"',
                }
              : localeText
          }
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          pageSize={props.pageLimit}
          rowsPerPageOptions={[props.pageLimit]}
          paginationMode="server"
        />
      </Stack>
    </ScrollView>
  );
}

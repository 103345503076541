import { Stack } from '@mobily/stacks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { Amplitude, CreatePaymentLinkForm, DashboardStackParamList, useMe } from '@iokanx/dashboard/feature';
import { ClipboardButton } from '@iokanx/dashboard/ui';
import {
  createPaymentLinkResolver,
  ICreatePaymentLinkResolverParams,
  ICreatePaymentLinkResolverResult,
} from '@iokanx/shared/data-access/api';
import { IHttpError } from '@iokanx/shared/data-access/types';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Button } from '@iokanx/shared/ui/universal';

function CreatePaymentLink() {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { me } = useMe();

  const {
    mutate: createPaymentLink,
    isSuccess,
    data: createdPaymentLink,
    reset,
    error,
    isLoading,
    isError,
  } = useMutation<ICreatePaymentLinkResolverResult, AxiosError<IHttpError>, ICreatePaymentLinkResolverParams>(
    createPaymentLinkResolver,
    {
      onSuccess: () => {
        snackbar.show('Платежная ссылка успешно создана', { type: 'success' });
        Amplitude.logEventWithPropertiesAsync('create_payment_link', { user_id: me?.id });
        return queryClient.invalidateQueries(['payment-links']);
      },
    },
  );

  if (isSuccess && createdPaymentLink) {
    return (
      <Stack padding={2} space={2}>
        <ClipboardButton>{createdPaymentLink.payment_link_url}</ClipboardButton>
        <Button onPress={reset}>Создать еще платежную ссылку</Button>
      </Stack>
    );
  }

  return (
    <CreatePaymentLinkForm
      onSubmit={({ lifecycle, ...data }) => createPaymentLink(data)}
      isLoading={isLoading}
      error={error?.response?.data.message}
      isError={isError}
    />
  );
}

export default wrapPage(
  wrapModalPage(CreatePaymentLink, {
    fillNavigationStack: (navigation: NativeStackNavigationProp<DashboardStackParamList>) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Operations', params: { screen: 'PaymentLinks' } });
        navigation.push('CreatePaymentLink');
      }
    },
    handleDismiss: (navigation) => {
      navigation.push('Drawer', { screen: 'Operations', params: { screen: 'PaymentLinks' } });
    },
  }),
  {
    keyboardAvoidingView: true,
  },
);

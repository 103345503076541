import { Stack, Skeleton } from '@mui/material';
import * as React from 'react';

export default function SkeletonComponent() {
  return (
    <Stack spacing={1}>
      <Skeleton animation="wave" height={60} sx={{ borderRadius: 2 }} />
      <Stack direction="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width="48%" height={120} sx={{ borderRadius: 2 }} />
        <Skeleton variant="rectangular" width="48%" height={120} sx={{ borderRadius: 2 }} />
      </Stack>

      <Skeleton animation="wave" height={60} sx={{ borderRadius: 2 }} />
      <Stack direction="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width="48%" height={120} sx={{ borderRadius: 2 }} />
        <Skeleton variant="rectangular" width="48%" height={120} sx={{ borderRadius: 2 }} />
      </Stack>
    </Stack>
  );
}

import { Tiles } from '@mobily/stacks';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { ComponentType, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Platform } from 'react-native';

import {
  exportDashboardPaymentsResolver,
  IFindDashboardPaymentsResolverParams,
  IFindDashboardPaymentsResolverResultItem,
} from '@iokanx/shared/data-access/api';
import { DATE_FORMAT } from '@iokanx/shared/data-access/constants';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { DateRangePicker, ISelectItem, Select, TextField, Button, MaskedPanInput } from '@iokanx/shared/ui/universal';
import { useSessionStorageDate } from '@iokanx/shared/util';

import { Amplitude } from '../amplitude/amplitude';
import { saveFile } from '../file-saver/file-saver';
import { useDashboardNavigation } from '../navigation';
import { OrderListDesktop } from '../order-list-desktop/order-list-desktop';
import { OrderListMobile } from '../order-list-mobile/order-list-mobile';

export enum SearchOrdersParam {
  /** Номер заказа */
  Status = 'status',
  /** Номер заказа */
  ExternalId = 'external_id',
  /** Идентификатор заказа */
  OrderId = 'id',
  /** Дополнительная информация */
  ExtraInfo = 'extra_info',
  /** Идентификатор платежа */
  PaymentId = 'payment_id',
  /** Референс банка */
  Reference = 'reference',
  /** E-mail плательщика */
  PayerEmail = 'payer_email',
  /** Маска карты */
  MaskedPan = 'maskedPan',
}

export enum DashboardStatusEnum {
  ALL = '',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  CAPTURED = 'CAPTURED',
  DECLINED = 'DECLINED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
}

export interface IOrderListFormFieldValues extends Pick<IFindDashboardPaymentsResolverParams, SearchOrdersParam> {
  page: NonNullable<IFindDashboardPaymentsResolverParams['page']>;
  from_dt: IFindDashboardPaymentsResolverParams['from_dt'];
  to_dt: IFindDashboardPaymentsResolverParams['to_dt'];
}

const searchOrdersParamI18n: Record<SearchOrdersParam, string> = {
  [SearchOrdersParam.Status]: 'Статус',
  [SearchOrdersParam.ExternalId]: 'Номер заказа',
  [SearchOrdersParam.OrderId]: 'Идентификатор заказа',
  [SearchOrdersParam.ExtraInfo]: 'Дополнительная информация',
  [SearchOrdersParam.PaymentId]: 'Идентификатор платежа',
  [SearchOrdersParam.Reference]: 'Референс банка',
  [SearchOrdersParam.PayerEmail]: 'E-mail плательщика',
  [SearchOrdersParam.MaskedPan]: 'Номер карты',
};
const searchOrdersParamItems: ISelectItem<SearchOrdersParam>[] = Object.values(SearchOrdersParam).map((value) => ({
  title: searchOrdersParamI18n[value],
  value,
}));

const dashboardStatusEnumI18n: Record<DashboardStatusEnum, string> = {
  [DashboardStatusEnum.ALL]: 'Все',
  [DashboardStatusEnum.DECLINED]: 'Отклонен',
  [DashboardStatusEnum.APPROVED]: 'Авторизован',
  [DashboardStatusEnum.CANCELLED]: 'Отменен',
  [DashboardStatusEnum.CAPTURED]: 'Списан',
  [DashboardStatusEnum.PARTIALLY_REFUNDED]: 'Частично возвращен',
  [DashboardStatusEnum.REFUNDED]: 'Возвращен',
};
const dashboardStatusFilterItems: ISelectItem<DashboardStatusEnum>[] = Object.values(DashboardStatusEnum).map(
  (value) => ({
    title: dashboardStatusEnumI18n[value],
    value,
  }),
);

const ORDER_LIST_LIMIT = 10;

export interface IOrderListProps {
  onOrderPress: (order: IFindDashboardPaymentsResolverResultItem) => void;
  form: UseFormReturn<IOrderListFormFieldValues>;
  pageLimit: number;
  filter: JSX.Element;
}
const RESPONSIVE_ORDER_LIST: Record<Breakpoint, ComponentType<IOrderListProps>> = {
  [Breakpoint.Mobile]: OrderListMobile,
  [Breakpoint.Desktop]: OrderListDesktop,
};

export function OrderList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const { startDate, endDate } = useSessionStorageDate();

  const form = useForm<IOrderListFormFieldValues>({
    defaultValues: {
      page: 1,
      from_dt: startDate || moment().subtract(7, 'day').startOf('day').toDate(),
      to_dt: endDate || moment().endOf('day').toDate(),
      id: '',
      external_id: '',
      extra_info: '',
      status: '',
    },
  });

  const [searchOrderParam, setSearchOrderParam] = useState(SearchOrdersParam.ExternalId);
  function handleChangeSearchOrderParam(nextSearchOrderParam: SearchOrdersParam) {
    form.reset();
    setSearchOrderParam(nextSearchOrderParam);
  }
  const [dashboardStatusEnum, setDashboardStatusEnum] = useState(DashboardStatusEnum.ALL);

  const navigation = useDashboardNavigation();
  function handleNavigateToOrder(order: IFindDashboardPaymentsResolverResultItem) {
    navigation.navigate('Order', { orderId: order.id });
    Amplitude.logEventWithPropertiesAsync('order_click', {});
  }

  const { mutate: exportDashboardPayments, isLoading: isExporting } = useMutation(exportDashboardPaymentsResolver, {
    onSuccess: (data, variables) => {
      const startDate = moment(variables.from_dt).format(DATE_FORMAT);
      const endDate = moment(variables.to_dt).format(DATE_FORMAT);

      return saveFile({ data, fileName: `payments_${startDate}-${endDate}.xlsx` });
    },
  });

  const filter = (
    <Tiles columns={[1, 4]} space={2}>
      <Select
        onChange={handleChangeSearchOrderParam}
        value={searchOrderParam}
        label={'Поиск по'}
        items={searchOrdersParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) =>
          searchOrderParam === SearchOrdersParam.MaskedPan ? (
            <MaskedPanInput
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              label={searchOrdersParamI18n[searchOrderParam]}
            />
          ) : searchOrderParam === SearchOrdersParam.Status ? (
            <Select
              onBlur={onBlur}
              onChange={(e) => {
                setDashboardStatusEnum(e);
                onChange(e);
              }}
              value={dashboardStatusEnum}
              label={'Статус'}
              items={dashboardStatusFilterItems}
            />
          ) : (
            <TextField
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              label={searchOrdersParamI18n[searchOrderParam]}
            />
          )
        }
        name={searchOrderParam}
      />
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          form.setValue('from_dt', values.startDate);
          form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
      {Platform.OS === 'web' && (
        <Button
          icon={{ name: 'file-excel' }}
          onPress={() => exportDashboardPayments(form.watch())}
          loading={isExporting}
        >
          Выгрузить
        </Button>
      )}
    </Tiles>
  );

  const ResponsiveOrderList = RESPONSIVE_ORDER_LIST[currentBreakpoint];

  return (
    <ResponsiveOrderList
      filter={filter}
      onOrderPress={handleNavigateToOrder}
      form={form}
      pageLimit={ORDER_LIST_LIMIT}
    />
  );
}

import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { Platform } from 'react-native';

import { Breakpoint, useTypedCurrentBreakpoint } from '../stack';

export type UseDrawerType = DrawerNavigationOptions['drawerType'];

export function useDrawerType(): UseDrawerType {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  return Platform.select({
    web: currentBreakpoint === Breakpoint.Mobile ? 'front' : 'permanent',
  });
}

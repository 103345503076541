import moment from 'moment';

import { DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';

import { getApiKeyByShopId, IGetApiKeyByShopsIdResponseItem } from '../../resources';

export interface IFindApiKeyByShopIdItem extends IGetApiKeyByShopsIdResponseItem {
  shopId?: string;
  createdAt: string;
}

export type IFindApiKeyByShopIdResult = IFindApiKeyByShopIdItem[];

export async function findApiKeyByShopIdResolver(shopId: string): Promise<IFindApiKeyByShopIdItem[]> {
  const apiKeys = await getApiKeyByShopId(shopId);
  const data = apiKeys.map(transformApiKey);
  return [...data];
}

export function transformApiKey(apiKey: IGetApiKeyByShopsIdResponseItem): IFindApiKeyByShopIdItem {
  return {
    ...apiKey,
    createdAt: moment(apiKey.created_at).format(DATETIME_FORMAT),
  };
}

import * as yup from 'yup';

import { REQUIRED_ERROR_MESSAGE } from '../shared/constants';

export interface IOrganizationLicense {
  type: string;
  number: number;
  expiry_date: string;
  issue_date: string;
}

export interface IOrganizationAnswer {
  answer: string;
  comment?: string;
}

export const organizationLicenseValidationSchema: yup.SchemaOf<IOrganizationLicense> = yup.object({
  type: yup.string().required(REQUIRED_ERROR_MESSAGE),
  number: yup.number().required(REQUIRED_ERROR_MESSAGE),
  expiry_date: yup.string().required(REQUIRED_ERROR_MESSAGE),
  issue_date: yup.string().required(REQUIRED_ERROR_MESSAGE),
});

export const organizationQuestionValidationSchema: yup.SchemaOf<IOrganizationAnswer> = yup.object({
  answer: yup.string().required(REQUIRED_ERROR_MESSAGE),
  comment: yup.string().when('answer', {
    is: 'yes',
    then: yup.string().required(REQUIRED_ERROR_MESSAGE),
  }),
});

export const organizationOfficialValidationSchema: yup.SchemaOf<IOrganizationLicense> = yup.object({
  type: yup.string().required(REQUIRED_ERROR_MESSAGE),
  number: yup.number().required(REQUIRED_ERROR_MESSAGE),
  expiry_date: yup.string().required(REQUIRED_ERROR_MESSAGE),
  issue_date: yup.string().required(REQUIRED_ERROR_MESSAGE),
});

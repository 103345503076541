import { IFindEmailByShopIdResult, transformEmail } from '@iokanx/shared/data-access/api';

import { createEmailByShopId, IEmailByShopsIdRequestData } from '../../resources';

export async function createEmailByShopIdResolver(
  shopId: string,
  email: IEmailByShopsIdRequestData,
): Promise<IFindEmailByShopIdResult> {
  const result = await createEmailByShopId(shopId, email);
  return transformEmail(result);
}

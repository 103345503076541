import { QueryClient, QueryClientProvider, QueryClientProviderProps } from '@tanstack/react-query';

export type IReactQueryClientProviderProps = Pick<QueryClientProviderProps, 'children'>;

const client = new QueryClient({
  defaultOptions: { queries: { suspense: true, keepPreviousData: true, refetchOnWindowFocus: false } },
});

export function ReactQueryClientProvider(props: IReactQueryClientProviderProps) {
  return <QueryClientProvider client={client} {...props} />;
}

import { ApiKeyStatus } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../../instances';

export interface IGetApiKeyByShopsIdResponseItem {
  id: string;
  name: string;
  hash_sum: string;
  src: string;
  status: ApiKeyStatus;
  created_at: string;
}

export type IGetApiKeyByShopsIdResponseData = IGetApiKeyByShopsIdResponseItem[];

/** Приглашение пользователя */
export async function getApiKeyByShopId(shopId: string): Promise<IGetApiKeyByShopsIdResponseData> {
  const { data } = await backendHttp.get(`/api-key`, { params: { shop_id: shopId } });
  return data;
}

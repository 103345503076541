import { useMutation, useQueryClient } from '@tanstack/react-query';

import { clearTokens } from '../token';

export interface UseSignOut {
  signOut: () => void;
  isSigningOut: boolean;
}

export function useSignOut(): UseSignOut {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(clearTokens, {
    onSuccess: () => queryClient.invalidateQueries(),
  });

  return { signOut: mutate, isSigningOut: isLoading };
}

import moment from 'moment';

import { DISPLAY_DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';

export function check(date?: any): boolean {
  return date < moment('2024-02-29 17:59:59');
}

export function prepareDateTime(dateTime?: string, dateFormat?: string): string {
  if (!dateTime) return '-';
  let dt = moment.utc(dateTime);
  dt = check(dt) ? dt.utcOffset(6) : dt.utcOffset(5);
  return dt.format(dateFormat || DISPLAY_DATETIME_FORMAT);
}

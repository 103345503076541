import {
  IPostPayoutRequestData,
  IGetPayoutByIdResponseData,
  createPayout,
  IPostPayoutRequestRecipient,
} from '../../resources';

export interface ICreatePayoutResolverParams extends IPostPayoutRequestData {
  shop_id: string;
  account_id: string;
  from_dt: string;
  to_dt: string;
  amount: number;
  acquirer: string;
  recipient: IPostPayoutRequestRecipient;
}

export type ICreatePayoutResolverResult = IGetPayoutByIdResponseData;

export function createPayoutResolver({
  ...params
}: ICreatePayoutResolverParams): Promise<Readonly<ICreatePayoutResolverResult>> {
  return createPayout({
    ...params,
  });
}

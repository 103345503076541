import { Add } from '@mui/icons-material';
import { Button as MaterialButton, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Item } from '@iokanx/dashboard/feature';

import { Beneficiary } from './Beneficiary';

import { ConditionalComponent } from '../../ConditionalComponent';

interface IShareholder {
  updateChildrenCompletionStatus: any;
  beneficiaries?: any[];
  childrenBeneficiaries: any[];
  parentId: string;
  isSubmitted: boolean;
}

export const ChildrenBeneficiary = ({
  updateChildrenCompletionStatus,
  beneficiaries,
  childrenBeneficiaries,
  parentId,
  isSubmitted,
}: IShareholder) => {
  const [isMore, setIsMore] = useState<boolean>(false);

  const addBeneficiary = () => setIsMore(true);

  useEffect(() => {
    setIsMore(false);
  }, [childrenBeneficiaries]);

  return (
    <Stack direction={'column'}>
      <ConditionalComponent
        condition={!isEmpty(childrenBeneficiaries)}
        component={
          <>
            {childrenBeneficiaries?.map((childrenBeneficiary, index) => (
              <Beneficiary
                key={index}
                beneficiary={childrenBeneficiary}
                beneficiaries={beneficiaries}
                parentId={parentId}
                updateChildrenCompletionStatus={updateChildrenCompletionStatus}
              />
            ))}
          </>
        }
      />

      <ConditionalComponent
        condition={!isEmpty(childrenBeneficiaries) && !isSubmitted}
        component={
          <Item sx={{ marginLeft: '24px' }}>
            <MaterialButton
              sx={{ padding: '8px 12px', minWidth: 'fit-content' }}
              onClick={addBeneficiary}
              fullWidth={false}
            >
              <Add sx={{ fontSize: '18px' }} />
            </MaterialButton>
          </Item>
        }
      />

      <ConditionalComponent condition={isMore} component={<Beneficiary parentId={parentId} />} />
    </Stack>
  );
};

import { IOked } from './types';

export const parseOrganizationsType = (data: any[]) => {
  return data.data?.map((dataItem: any) => ({
    value: dataItem.code,
    title: dataItem.name,
    id: dataItem.id,
  }));
};

export const getOptionLabel = (option: IOked | string) => {
  if (typeof option !== 'string') {
    return `${option.value} | ${option.title}`;
  }

  return option;
};

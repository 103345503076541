import { backendHttp } from '../../../../instances';

export interface IGetEmailByShopsIdResponseData {
  id: string;
  created_at?: string;
  email: string;
  active: boolean;
}

export async function getEmailsByShopId(shopId: string): Promise<IGetEmailByShopsIdResponseData[]> {
  const { data } = await backendHttp.get(`/shops/${shopId}/emails`);
  return data;
}

import AsyncStorage from '@react-native-async-storage/async-storage';
import { AxiosRequestConfig } from 'axios';

import { IConfigApiUrl, IConfigResponseData } from '../../../resources';

async function getApiUrl(apiUrl: IConfigApiUrl): Promise<string | null> {
  const stringifyConfig = await AsyncStorage.getItem('config');
  if (stringifyConfig) {
    const config: IConfigResponseData = JSON.parse(stringifyConfig);
    return config[apiUrl];
  }
  return null;
}

export const getHttpUrlRequestInterceptor =
  (apiUrl: IConfigApiUrl) =>
  async (requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const basedUrl = await getApiUrl(apiUrl);
    if (basedUrl) {
      requestConfig.baseURL = basedUrl;
    } else {
      throw new Error('config is not defined');
    }

    return requestConfig;
  };

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import { UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ICreatePayoutResolverParams, ICreatePayoutResolverResult } from '@iokanx/shared/data-access/api';
import { Button, HelperText, Select, TextField } from '@iokanx/shared/ui/universal';

export interface ICreatePayoutFormProps {
  createPayout: UseMutateFunction<ICreatePayoutResolverResult, unknown, ICreatePayoutResolverParams>;
  isLoading: boolean;
  error?: string;
  isError: boolean;
}

type ICreatePayoutFormFieldValues = {
  shop_id: string;
  account_id: string;
  from_dt: string;
  to_dt: string;
  amount: number;
  acquirer: string;
  recipient: {
    iin: string;
    iban: string;
    name: string;
    knp: number;
    kbe: number;
    description: string;
  };
};

const createPayoutFormValidationSchema: yup.SchemaOf<ICreatePayoutFormFieldValues> = yup.object({
  shop_id: yup.string().required(),
  account_id: yup.string().required(),
  from_dt: yup.string().required(),
  to_dt: yup.string().required(),
  acquirer: yup.string().required(),
  amount: yup.number().required(),
  recipient: yup.object({
    iin: yup.string().required(),
    iban: yup.string().required(),
    name: yup.string().required(),
    knp: yup.number().required(),
    kbe: yup.number().required(),
    description: yup.string().required(),
  }),
});

export function CreatePayoutForm(props: ICreatePayoutFormProps) {
  const queryClient = useQueryClient();

  const { control, handleSubmit } = useForm<ICreatePayoutFormFieldValues>({
    defaultValues: {
      shop_id: 'shp_3DINHDYHS0',
      account_id: 'act_1',
      from_dt: moment().format('YYYY-MM-DD HH:mm:ss'),
      to_dt: moment().format('YYYY-MM-DD HH:mm:ss'),
      amount: 0,
      acquirer: 'bcc',
      recipient: {
        iin: '191140003027',
        iban: 'KZ788562203127000865',
        name: 'ТОО "ioka fintech"',
        knp: 859,
        kbe: 17,
        description: 'Выплата комиссий IOKA',
      },
    },
    mode: 'all',
    resolver: yupResolver(createPayoutFormValidationSchema),
  });

  const onSubmit: SubmitHandler<ICreatePayoutFormFieldValues> = (data) => {
    props.createPayout({ ...data } as ICreatePayoutResolverParams);
  };

  const onError = (error: any) => console.log(error);

  return (
    <Stack padding={5} space={5}>
      <title>Title</title>

      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <Select
            onChange={field.onChange}
            value={field.value}
            label={'Выбрать банка эквайер'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
            items={[
              { title: 'Все банки', value: '' },
              { title: 'Halyk', value: 'epay_v2' },
              { title: 'BCC', value: 'bcc' },
              { title: 'Береке', value: 'bereke' },
            ]}
          />
        )}
        name={'acquirer'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => <TextField value={field.value} disabled label={'БИН'} />}
        name={'recipient.iin'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => <TextField value={field.value} disabled label={'Найменование'} />}
        name={'recipient.name'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'IBAN'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'recipient.iban'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'Сумма'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'amount'}
      />
      <Controller
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            onBlur={field.onBlur}
            onChangeText={field.onChange}
            value={field.value}
            label={'Примечание'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
        name={'recipient.description'}
      />
      <HelperText type={'error'} visible={props.isError}>
        {props.error}
      </HelperText>
      <Button onPress={handleSubmit(onSubmit, onError)} loading={props.isLoading}>
        Создать выплату
      </Button>
    </Stack>
  );
}

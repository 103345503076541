import { FillView, Stack } from '@mobily/stacks';
import { ActivityIndicator } from 'react-native-paper';

import { CDNImage } from '../cdnimage/cdnimage';

export function PageLoader() {
  return (
    <FillView alignX="center" alignY="center">
      <Stack horizontal space={2} align={'center'}>
        <ActivityIndicator color={'#0F172A'} />
        <CDNImage src="ioka-logo.svg" width={54} height={24} />
      </Stack>
    </FillView>
  );
}

import { AxiosResponse } from 'axios';

import { CaptureMethod, Currency, OrderStatus } from '@iokanx/shared/data-access/constants';

import { adminHttp, backendHttp } from '../../../instances';
import { IGetAdminPaymentByIdResponseData } from '../payments/by-id';

export interface IGetAdminOrderByIdResponseData {
  id: string;
  shop_id: string;
  status: OrderStatus;
  created_at: string;
  amount: number;
  currency: Currency;
  capture_method: CaptureMethod;
  external_id: string | null;
  description: string | null;
  extra_info: Record<string, unknown> | null;
  /**
   * Количество разрешенных попыток оплаты для заказа.
   *
   * Количество разрешенных попыток не ограничено, если параметр не указан
   */
  attempts: number | null;
  due_date: string | null;
  // Идентификатор плательщика
  customer_id: string | null;
  // Идентификатор сохраненной карты плательщика
  card_id: string | null;
  // Список платежей заказа
  payments: IGetAdminPaymentByIdResponseData | null;
}

export async function getAdminOrderById(id: string): Promise<IGetAdminOrderByIdResponseData> {
  const { data } = await backendHttp.get<IGetAdminOrderByIdResponseData>(`/orders/${id}`);
  return data;
}

export interface IPatchAdminOrderByIdRequestData {
  status?: OrderStatus;
  extra_info?: object;
}

export async function patchAdminOrderById(
  id: string,
  requestData: IPatchAdminOrderByIdRequestData,
): Promise<IGetAdminOrderByIdResponseData> {
  const { data } = await backendHttp.patch<
    IGetAdminOrderByIdResponseData,
    AxiosResponse<IGetAdminOrderByIdResponseData>,
    IPatchAdminOrderByIdRequestData
  >(`/admin/orders/${id}`, requestData);
  return data;
}

export interface IPatchPsmEventByIdRequestData {
  name: string;
  command_id: number;
}

export async function patchPsmOrderById(requestData: IPatchPsmEventByIdRequestData) {
  const { data } = await backendHttp.post<
    IGetAdminOrderByIdResponseData,
    AxiosResponse<IGetAdminOrderByIdResponseData>,
    IPatchPsmEventByIdRequestData
  >(`/psm/events`, requestData);
}

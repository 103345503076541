import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';

import { ActivityType } from './ActivityType';
import { OKED } from './OKED';

import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { ConditionalComponent } from '../../ConditionalComponent';
import { ProfileFormStep } from '../../layouts/ProfileFormStep';
import { IFormSection } from '../OrganizationInfoStep/types';

export const ActivityTypesStep: FC<IFormSection> = (props) => {
  const { organizationId, isSubmitted } = useProfileFormContext();
  const [isMore, setIsMore] = useState(false);

  const { data: registeredOkeds } = useQuery(['okeds'], async () => {
    const { data } = await backendHttp.get(`organizations/${organizationId}/okeds`);

    return data;
  });

  const handleFormDisplay = () => {
    setIsMore((isMore) => !isMore);
  };

  useEffect(() => {
    setIsMore(false);
  }, [registeredOkeds]);

  return (
    <ProfileFormStep {...props}>
      <ConditionalComponent
        condition={isEmpty(registeredOkeds)}
        component={
          <OKED isMain isSubmitted={isSubmitted} organizationId={organizationId} oked={null} isRemovable={false} />
        }
      />

      {registeredOkeds?.map((registeredOked: any, index: number) => {
        return (
          <ActivityType isSubmitted={isSubmitted} organizationId={organizationId} oked={registeredOked} key={index} />
        );
      })}

      <ConditionalComponent
        condition={!isSubmitted}
        component={
          <Item>
            <Button onClick={handleFormDisplay} fullWidth={false}>
              {isMore ? 'Скрыть' : '+ Добавить вид деятельности'}
            </Button>
          </Item>
        }
      />

      <ConditionalComponent
        condition={isMore}
        component={
          <OKED
            isMain={false}
            isSubmitted={isSubmitted}
            organizationId={organizationId}
            oked={null}
            isRemovable={false}
          />
        }
      />
    </ProfileFormStep>
  );
};

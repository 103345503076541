import moment from 'moment';

import { CURRENCY_SYMBOL, DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';

import { getIncomeById, IGetIncomeByIdResponseData } from '../../resources';

export interface IFindIncomeByIdResolverParams {
  incomeId: string;
}

export interface IFindIncomeByIdResolverResult extends IGetIncomeByIdResponseData {
  externalId: string;
  amountWithSymbol: string;
  createdAt: string;
  approvedAt: string;
}

export async function findIncomeByIdResolver(
  params: IFindIncomeByIdResolverParams,
): Promise<IFindIncomeByIdResolverResult> {
  const income = await getIncomeById(params.incomeId);
  return { ...transformIncome(income) };
}

export function transformIncome(income: IGetIncomeByIdResponseData): IFindIncomeByIdResolverResult {
  const amount = income.amount / 100;
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL[income.currency]}`;
  return {
    ...income,
    externalId: income.external_id,
    amountWithSymbol: amountWithSymbol,
    approvedAt: moment(income.approved_at).format(DATETIME_FORMAT),
    createdAt: moment(income.created_at).format(DATETIME_FORMAT),
  };
}

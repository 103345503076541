import { StyleSheet } from 'react-native';

import { PaymentLinkList, useDashboardNavigation } from '@iokanx/dashboard/feature';
import { Breakpoint, useTypedCurrentBreakpoint, wrapPage } from '@iokanx/shared/feature';
import { FAB } from '@iokanx/shared/ui/universal';

export function PaymentLinks() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const navigation = useDashboardNavigation();

  function handlePressCreatePaymentLink() {
    navigation.navigate('CreatePaymentLink');
  }

  return (
    <>
      <FAB
        style={styles.fab}
        icon="plus"
        onPress={handlePressCreatePaymentLink}
        label={currentBreakpoint === Breakpoint.Desktop ? 'Создать ссылку' : ''}
      />
      <PaymentLinkList />
    </>
  );
}

const styles = StyleSheet.create({
  fab: {
    position: 'absolute',
    margin: 8,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
});

export const PaymentLinksPage = wrapPage(PaymentLinks);

import { Currency, CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';
import { compactObject, prepareAmountWithSymbol, prepareDateTime } from '@iokanx/shared/util';

import {
  getSearchRefunds,
  IGetSearchRefundsRequestParams,
  IGetSearchRefundsResponseData,
  IGetSearchRefundsResponseDataItem,
} from '../../resources';

export type ISearchRefundsResolverParams = IGetSearchRefundsRequestParams;

export interface ISearchRefundsResolverResultItem extends IGetSearchRefundsResponseDataItem {
  // Нормализованная сумма возврата с символом валюты
  amountWithSymbol: string;
  // Дата создания в формате HH:mm, DD.MM.YYYY
  createdAt: string;
  // Нормализованная причина возврата
  reason: NonNullable<IGetSearchRefundsResponseDataItem['reason']>;
}
export type ISearchRefundsResolverResult = IGetSearchRefundsResponseData<ISearchRefundsResolverResultItem>;

export async function searchRefundsResolver(
  params: ISearchRefundsResolverParams,
): Promise<Readonly<ISearchRefundsResolverResult>> {
  const refunds = await getSearchRefunds(compactObject(params));

  const data = refunds.data.map(transformSearchRefund);

  return { ...refunds, data };
}

export function transformSearchRefund(
  searchRefund: IGetSearchRefundsResponseDataItem,
): ISearchRefundsResolverResultItem {
  const amount = searchRefund.amount / 100;

  return {
    ...searchRefund,
    amountWithSymbol: prepareAmountWithSymbol(amount),
    createdAt: prepareDateTime(searchRefund.created_at),
    reason: searchRefund.reason || '-',
  };
}

import { useInfiniteQuery } from '@tanstack/react-query';
import { UseFormReturn } from 'react-hook-form';
import { ListRenderItem } from 'react-native';

import { PaymentLinkCard } from '@iokanx/dashboard/ui';
import { ISearchPaymentLinksResolverResultItem, searchPaymentLinksResolver } from '@iokanx/shared/data-access/api';
import { createGetNextPageParam } from '@iokanx/shared/util';

import { OperationFlatList } from '../operation-flat-list/operation-flat-list';
import { PaymentLinkListFilter } from '../payment-link-list-filter/payment-link-list-filter';
import { IPaymentLinkListFormFieldValues } from '../payment-link-list/payment-link-list';

export interface IPaymentLinkCardsProps {
  form: UseFormReturn<IPaymentLinkListFormFieldValues>;
  limit: number;
  emptyText: string;
}

export function PaymentLinkCards(props: IPaymentLinkCardsProps) {
  const query = useInfiniteQuery(
    ['payment-links', props.form.watch()],
    (context) => searchPaymentLinksResolver({ ...props.form.watch(), page: context.pageParam, limit: props.limit }),
    {
      getNextPageParam: createGetNextPageParam({ pageLimit: props.limit }),
    },
  );

  const renderItem: ListRenderItem<ISearchPaymentLinksResolverResultItem> = (info) => {
    return <PaymentLinkCard paymentLink={info.item} />;
  };

  return (
    <OperationFlatList
      renderItem={renderItem}
      query={query}
      hasNotNextPageText={'Ссылок на оплату больше нет'}
      emptyText={props.emptyText}
      filter={<PaymentLinkListFilter form={props.form} />}
    />
  );
}

import { Box, Stack } from '@mobily/stacks';
import { StyleSheet } from 'react-native';

import { IGetIncomeByIdResponseData } from '@iokanx/shared/data-access/api';
import { Caption, Card, Paragraph, SimpleCard, SimpleCardProps } from '@iokanx/shared/ui/universal';

import { ClipboardButton } from '../clipboard-button/clipboard-button';

export interface IIncomeCardProps {
  income: IGetIncomeByIdResponseData;
  onCardPress: SimpleCardProps['onPress'];
}

interface IContentItem {
  title: string;
  key: keyof Pick<IGetIncomeByIdResponseData, 'external_id' | 'created_at'>;
}
const contentItems: IContentItem[] = [
  {
    title: 'Номер выплаты',
    key: 'external_id',
  },
  {
    title: 'Дата создания',
    key: 'created_at',
  },
];

export function IncomeCard(props: IIncomeCardProps) {
  return (
    <SimpleCard title={props.income.amount} right={() => <Box marginRight={4}>''</Box>} onPress={props.onCardPress}>
      <Card.Content>
        {contentItems.map((item) => (
          <Stack key={item.key} horizontal space={2} style={styles.contentItem}>
            <Caption>{item.title}</Caption>
            <Paragraph>{props.income[item.key]}</Paragraph>
          </Stack>
        ))}
      </Card.Content>
      <Card.Actions>
        <ClipboardButton copiedText={''}>{props.income.id}</ClipboardButton>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  contentItem: {
    justifyContent: 'space-between',
  },
});

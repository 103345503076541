import { useEffect, useState } from 'react';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { OrganizationContactFormItem } from './OrganizationContactFormItem';

import { checkChildrenSections } from '../../../../../helpers/helpers';
import { useProfileFormContext } from '../../../../../providers/ProfileFormProvider';
import { ProfileFormSection } from '../../../../layouts/ProfileFormSection';

export const postData = async (formData: any, type: string, organizationId: string) => {
  const { data } = await backendHttp.post<any>(`/organizations/${organizationId}/contacts`, {
    type,
    ...formData,
  });

  return data;
};

export const patchData = async (formData: any, type: string, organizationId: string, contactId: string) => {
  const { data } = await backendHttp.patch<any>(`/organizations/${organizationId}/contacts/${contactId}`, {
    type,
    ...formData,
  });

  return data;
};

const formItems = [
  {
    type: 'PHONE',
    label: 'Телефон',
  },
  {
    type: 'WEBSITE',
    label: 'Веб-сайт',
  },
];

export const OrganizationContactForm = ({ title }: { title: string }) => {
  const { isSubmitted } = useProfileFormContext();
  const [isSaved, setIsSaved] = useState(false);
  const [childrenSections, setChildrenSections] = useState<any[]>([]);

  const updateChildrenCompletionStatus = (title: string, isComplete: boolean) => {
    setChildrenSections((prevStatus) => ({
      ...prevStatus,
      [title]: isComplete,
    }));
  };

  useEffect(() => {
    setIsSaved(checkChildrenSections(childrenSections));
  }, [childrenSections]);

  return (
    <ProfileFormSection title={title} isSubmitted={isSubmitted} handleSubmit={null} isSaved={isSaved}>
      {formItems.map(({ type, label }, index: number) => {
        return (
          <OrganizationContactFormItem
            updateChildrenCompletionStatus={updateChildrenCompletionStatus}
            label={label}
            type={type}
            key={index}
          />
        );
      })}
    </ProfileFormSection>
  );
};

import { getConfig, IConfigResponseData } from '../../resources';

export interface IGetConfigResolverParams {
  ownConfig: IConfigResponseData;
}

export function getConfigResolver(params?: IGetConfigResolverParams) {
  if (params?.ownConfig) {
    return params.ownConfig;
  }

  return getConfig();
}

import { backendHttp } from '../../../../instances';

import { IGetEmailByShopsIdResponseData } from '.';

export interface IEmailByShopsIdRequestData {
  email: string;
  active: boolean;
}

export async function createEmailByShopId(
  shopId: string,
  email: IEmailByShopsIdRequestData,
): Promise<IGetEmailByShopsIdResponseData> {
  const { data } = await backendHttp.post(`/shops/${shopId}/emails`, email);
  return data;
}

import { Box, Typography } from '@mui/material';

import { Breakpoint } from '@iokanx/shared/feature';

import { NPSRating } from '../NPSRating';
import { NPSDesktopTitleStyles } from '../styles/NPSDesktopStyles';

const NPSFirstStepContent = {
  display: 'flex',
  flexDirection: 'column',
  gap: '60px',
};

export const NPSFirstStep = ({ isMobile, rating, handleChangeRating }: any) => {
  return (
    <Box sx={NPSFirstStepContent}>
      <Typography component="legend" sx={NPSDesktopTitleStyles}>
        Какова вероятность того, что Вы порекомендуете ioka?
      </Typography>

      <NPSRating
        type={isMobile ? Breakpoint.Mobile : Breakpoint.Desktop}
        rating={rating}
        handleChange={handleChangeRating}
      />
    </Box>
  );
};

import { Box, Tiles } from '@mobily/stacks';

import { ISearchRefundsResolverResultItem } from '@iokanx/shared/data-access/api';
import { Caption, Card, Paragraph, SimpleCard } from '@iokanx/shared/ui/universal';

import { RefundStatusChip } from '../refund-status-chip/refund-status-chip';

export interface IRefundCardProps {
  refund: ISearchRefundsResolverResultItem;
}

interface IContentItem {
  title: string;
  key: keyof ISearchRefundsResolverResultItem;
}
const contentItems: IContentItem[] = [
  {
    title: 'Номер возврата',
    key: 'id',
  },
  {
    title: 'Идентификатор заказа',
    key: 'order_id',
  },
  {
    title: 'Идентификатор платежа',
    key: 'payment_id',
  },
  {
    title: 'Причина',
    key: 'reason',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
  },
];

export function RefundCard(props: IRefundCardProps) {
  return (
    <SimpleCard
      title={props.refund.amountWithSymbol}
      right={() => (
        <Box marginRight={4}>
          <RefundStatusChip refundStatus={props.refund.status} />
        </Box>
      )}
    >
      <Card.Content>
        {contentItems.map((item) => (
          <Tiles key={item.key} columns={2} space={2}>
            <Caption>{item.title}</Caption>
            <Paragraph>{props.refund[item.key]}</Paragraph>
          </Tiles>
        ))}
      </Card.Content>
    </SimpleCard>
  );
}

import { useInfiniteQuery } from '@tanstack/react-query';
import { ListRenderItem } from 'react-native';

import { RefundCard } from '@iokanx/dashboard/ui';
import { ISearchRefundsResolverResultItem, searchRefundsResolver } from '@iokanx/shared/data-access/api';
import { createGetNextPageParam } from '@iokanx/shared/util';

import { OperationFlatList } from '../operation-flat-list/operation-flat-list';
import { IRefundListProps } from '../refund-list/refund-list';

export function RefundListMobile(props: IRefundListProps) {
  const query = useInfiniteQuery(
    ['refunds', props.form.watch()],
    (context) => searchRefundsResolver({ ...props.form.watch(), page: context.pageParam, limit: props.limit }),
    {
      getNextPageParam: createGetNextPageParam({ pageLimit: props.limit }),
    },
  );

  const renderItem: ListRenderItem<ISearchRefundsResolverResultItem> = (info) => {
    return <RefundCard refund={info.item} />;
  };

  return (
    <OperationFlatList
      renderItem={renderItem}
      query={query}
      hasNotNextPageText={'Возвратов больше нет'}
      emptyText={'По указанным параметрам, возвратов не найдено'}
      filter={props.filter}
    />
  );
}

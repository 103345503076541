import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { DashboardReportsTabsParamList, useMe } from '@iokanx/dashboard/feature';

import { FulfilmentsPage } from '../fulfilments-page/fulfilments-page';
// import { IncomesPage } from '../incomes-page/incomes-page';
import { PayoutsPage } from '../payouts-page/payouts-page';
import { RevisesPage } from '../revises-page/revises-page';

const MaterialTopTabNavigator = createMaterialTopTabNavigator<DashboardReportsTabsParamList>();

export default function ReportsPage() {
  const { me } = useMe();

  return (
    <MaterialTopTabNavigator.Navigator
      screenOptions={{
        lazy: true,
        swipeEnabled: false,
      }}
    >
      <MaterialTopTabNavigator.Screen name="Fulfilments" component={FulfilmentsPage} options={{ title: 'Отчеты' }} />
      {me?.is_superuser && (
        <MaterialTopTabNavigator.Screen name="Payouts" component={PayoutsPage} options={{ title: 'Выплаты' }} />
      )}
      {me?.is_superuser && (
        <MaterialTopTabNavigator.Screen name="Revises" component={RevisesPage} options={{ title: 'Сверки' }} />
      )}
      {/*{me?.is_superuser && (*/}
      {/*  <MaterialTopTabNavigator.Screen name="Incomes" component={IncomesPage} options={{ title: 'Поступления' }} />*/}
      {/*)}*/}
    </MaterialTopTabNavigator.Navigator>
  );
}

import { IPostOrdersByIdCaptureRequestData, postOrdersByIdCapture } from '../../resources';

export interface ICapturePaymentResolverParams extends IPostOrdersByIdCaptureRequestData {
  orderId: string;
}

export function capturePaymentResolver({ orderId, ...requestData }: ICapturePaymentResolverParams) {
  return postOrdersByIdCapture(orderId, {
    amount: Math.round(requestData.amount * 100),
    reason: requestData.reason,
  });
}

import * as yup from 'yup';

import { REQUIRED_ERROR_MESSAGE, EMAIL_ERROR_MESSAGE } from '../../../../shared/constants';
import { IOrganizationAddressFieldValues } from '../types';

export interface IOrganizationInfo {
  legal_name: string;
  trade_name: string;
  nib: number;
  organization_type_id: number;
}

export interface IOrganizationPhysicalData {
  first_name: string;
  last_name: string;
  patronymic?: string;
  nib: string;
  share: number;
  is_resident: string;
  number: number;
  issued_by: string;
  issue_date: string;
}

export interface IEmployer {
  first_name: string;
  last_name: string;
  patronymic?: string;
  phone: string;
  email: string;
  position: string;
}

export const contactFormValidationSchema: yup.SchemaOf<IEmployer> = yup.object({
  first_name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  last_name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  patronymic: yup.string(),
  phone: yup.string().required(REQUIRED_ERROR_MESSAGE),
  email: yup.string().required(REQUIRED_ERROR_MESSAGE).email(EMAIL_ERROR_MESSAGE),
  position: yup.string().required(REQUIRED_ERROR_MESSAGE),
});

export const organizationAddressValidationSchema: yup.SchemaOf<IOrganizationAddressFieldValues> = yup.object({
  country: yup.string().required(REQUIRED_ERROR_MESSAGE),
  city: yup.string().required(REQUIRED_ERROR_MESSAGE),
  street: yup.string().required(REQUIRED_ERROR_MESSAGE),
  region: yup.string().required(REQUIRED_ERROR_MESSAGE),
  home: yup.string().required(REQUIRED_ERROR_MESSAGE),
  flat: yup.string().required(REQUIRED_ERROR_MESSAGE),
});

export const organizationInfoValidationSchema: yup.SchemaOf<IOrganizationInfo> = yup.object({
  legal_name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  trade_name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  nib: yup.number().required(REQUIRED_ERROR_MESSAGE),
  organization_type_id: yup.number().required(REQUIRED_ERROR_MESSAGE),
});

export const organizationOKEDValidationSchema: yup.SchemaOf<{ okedTypes: string }> = yup.object({
  okedTypes: yup.string().required(REQUIRED_ERROR_MESSAGE),
});

const personalDataValidationSchema = yup.object({
  first_name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  last_name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  patronymic: yup.string(),
  nib: yup.string().required(REQUIRED_ERROR_MESSAGE),
  number: yup.string().required(REQUIRED_ERROR_MESSAGE),
  is_resident: yup.string().required(REQUIRED_ERROR_MESSAGE),
  issued_by: yup.string().required(REQUIRED_ERROR_MESSAGE),
  issue_date: yup.string().required(REQUIRED_ERROR_MESSAGE),
});

const personalDataLegalValidationSchema = yup.object({
  name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  nib: yup.string().required(REQUIRED_ERROR_MESSAGE),
  is_resident: yup.string().required(REQUIRED_ERROR_MESSAGE),
});

export const organizationHolderValidationSchema: yup.SchemaOf<Omit<IOrganizationPhysicalData, 'share'>> = yup.object({
  first_name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  last_name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  patronymic: yup.string(),
  nib: yup.string().required(REQUIRED_ERROR_MESSAGE),
  is_resident: yup.string().required(REQUIRED_ERROR_MESSAGE),
  number: yup.number().required(REQUIRED_ERROR_MESSAGE),
  issued_by: yup.string().required(REQUIRED_ERROR_MESSAGE),
  issue_date: yup.string().required(REQUIRED_ERROR_MESSAGE),
});

export const organizationIntermediaryValidationSchema: yup.SchemaOf<{ personal_data: { name: string } }> = yup.object({
  personal_data: yup.object({
    name: yup.string().required(REQUIRED_ERROR_MESSAGE),
  }),
});

export const organizationOfficialValidationSchema: yup.SchemaOf<any> = yup.object({
  personal_data: personalDataValidationSchema,
});

export const organizationShareholderValidationSchema: yup.SchemaOf<any> = yup.object().shape({
  type: yup.string().required(REQUIRED_ERROR_MESSAGE),
  share: yup.number().required(REQUIRED_ERROR_MESSAGE),
  personal_data: yup.mixed().when('type', {
    is: 'PHYSICAL',
    then: personalDataValidationSchema,
    otherwise: personalDataLegalValidationSchema,
  }),
});

export const organizationShareholderLegalValidationSchema: yup.SchemaOf<any> = yup.object({
  share: yup.number().required(REQUIRED_ERROR_MESSAGE),
  personal_data: yup.object({
    name: yup.string().required(REQUIRED_ERROR_MESSAGE),
    nib: yup.string().required(REQUIRED_ERROR_MESSAGE),
    is_resident: yup.string().required(REQUIRED_ERROR_MESSAGE),
  }),
});

export const organizationShareholderPhysicalValidationSchema: yup.SchemaOf<any> = yup.object({
  share: yup.number().required(REQUIRED_ERROR_MESSAGE),
  personal_data: personalDataValidationSchema,
});

import { DateCategory } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../instances';

export interface IGetAnalyticsAggregationsRequestParams {
  // Конец временного отрезка
  to_dt?: Date;
  // Начало временного отрезка
  from_dt?: Date;
  // Виды фильтров по дате
  date_category?: DateCategory;
}

export interface IGetAnalyticsAggregationsResponseData {
  acquirer_fee: number;
  approved_amount: number;
  approved_count: number;
  captured_amount: number;
  captured_count: number;
  processing_fee: number;
  refunded_amount: number;
  refunded_count: number;
}

export async function getAnalyticsAggregations(
  params: IGetAnalyticsAggregationsRequestParams,
): Promise<Readonly<IGetAnalyticsAggregationsResponseData>> {
  const { data } = await backendHttp.get<IGetAnalyticsAggregationsResponseData>(`/analytics/aggregations`, { params });
  return data;
}

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';
import { Icon, Select } from '@iokanx/shared/ui/universal';

import { ChildrenBeneficiary } from './ChildrenBeneficiary';
import { patchData, postData } from './data-access';
import { parseFormData } from './helpers';

import { isFormEmpty } from '../../../helpers/helpers';
import { useFormEdit } from '../../../helpers/useFormEdit';
import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { ConditionalComponent } from '../../ConditionalComponent';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { PersonalData } from '../../PersonalData';
import { organizationShareholderValidationSchema } from '../OrganizationInfoStep/model/schemas';

interface IBeneficiary {
  extra?: boolean;
  beneficiary?: any;
  beneficiaries?: any[];
  parentId?: string;
  updateChildrenCompletionStatus?: any;
}

const parentShareholderStyles = {
  border: '1px solid #e2e8f0',
  borderRadius: '6px',
  padding: '12px',
  backgroundColor: '#f4f4f4',
};

export const Beneficiary = ({
  extra,
  beneficiary,
  beneficiaries,
  parentId,
  updateChildrenCompletionStatus,
}: IBeneficiary) => {
  const queryClient = useQueryClient();
  const { organizationId, isSubmitted } = useProfileFormContext();

  const [isLegal, setIsLegal] = useState<boolean>();
  const [childBeneficiaries, setChildBeneficiaries] = useState<any[]>([]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    trigger,
    formState: { isDirty },
  } = useForm({
    defaultValues: parseFormData(beneficiary),
    resolver: yupResolver(organizationShareholderValidationSchema),
  });

  const { mutate: mutateBeneficiary } = useMutation(
    beneficiary
      ? (formData) => patchData(formData, organizationId, beneficiary.id, parentId)
      : (formData) => postData(formData, organizationId, parentId),
    {
      onSuccess: async (data: any) => {
        beneficiary ? reset(parseFormData(data)) : reset();
        handleIsEdit();

        await queryClient.invalidateQueries(['shares']);
      },
    },
  );

  const { mutate: removeBeneficiary } = useMutation(
    async () => {
      const { data: response } = await backendHttp.delete(`organizations/${organizationId}/shares/${beneficiary?.id}`);

      return response;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['shares']);
      },
    },
  );

  const onSubmit = (data: any) => mutateBeneficiary(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(beneficiary), handleSubmit(onSubmit, onError));

  const handleRemove = () => {
    removeBeneficiary();
  };

  const handleSubmitSection = () => {
    const formValues = getValues();

    if (isFormEmpty(formValues)) {
      trigger();

      return;
    }

    return isDirty ? handlePress() : handleIsEdit();
  };

  useEffect(() => {
    if (beneficiary) {
      reset(parseFormData(beneficiary));
      setIsLegal(beneficiary.personal_data.type === 'LEGAL');
    }
  }, [beneficiary]);

  useEffect(() => {
    const childrenBeneficiaries = beneficiaries?.filter(
      (childBeneficiary) => childBeneficiary.parent_id === beneficiary.id,
    );

    setChildBeneficiaries(childrenBeneficiaries as any[]);
  }, [beneficiaries]);

  useEffect(() => {
    if (beneficiary?.id || extra) {
      updateChildrenCompletionStatus(beneficiary?.id, isDisable);
    }
  }, [isDisable]);

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <ConditionalComponent
        condition={Boolean(parentId)}
        component={<Icon name={'arrow-right-bottom'} style={{ margin: '0 6px 0 12px' }} />}
      />

      <Box border={'1px solid #e2e8f0'} padding={'8px'} borderRadius={'4px'} marginTop={'12px'} sx={{ width: '100%' }}>
        <Box sx={!isEmpty(childBeneficiaries) ? parentShareholderStyles : null}>
          <ProfileFormSection
            title={beneficiary?.personal_data?.name || beneficiary?.personal_data?.first_name}
            isSaved={isDisable}
            isRemovable={true}
            isSubmitted={isSubmitted}
            handleSubmit={handleSubmitSection}
            handleRemove={handleRemove}
          >
            <Controller
              control={control}
              name={'type'}
              render={({ field: { onChange, value } }) => (
                <Item>
                  <Select
                    onChange={onChange}
                    style={{ width: '100%' }}
                    disabled={isDisable || !isEmpty(childBeneficiaries)}
                    value={value}
                    label={'Тип акционера/учредителя'}
                    items={[
                      { value: 'PHYSICAL', title: 'Физическое лицо' },
                      { value: 'LEGAL', title: 'Юридическое лицо' },
                    ]}
                  />
                </Item>
              )}
            />

            <PersonalData control={control} type={watch('type')} isDisable={isDisable} />
          </ProfileFormSection>
        </Box>

        <ChildrenBeneficiary
          updateChildrenCompletionStatus={updateChildrenCompletionStatus}
          isSubmitted={isSubmitted}
          beneficiaries={beneficiaries}
          childrenBeneficiaries={childBeneficiaries}
          parentId={beneficiary?.id}
        />

        <ConditionalComponent
          condition={isEmpty(childBeneficiaries) && Boolean(isLegal)}
          component={
            <Beneficiary
              updateChildrenCompletionStatus={updateChildrenCompletionStatus}
              parentId={beneficiary?.id}
              extra
            />
          }
        />
      </Box>
    </Stack>
  );
};

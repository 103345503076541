import { Stack } from '@mui/material';
import { DataGrid, GridColumns, ruRU } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { UseFormReturn } from 'react-hook-form';

import { ClipboardButton, PaymentLinkAmount, PaymentLinkStatus } from '@iokanx/dashboard/ui';
import { ISearchPaymentLinksResolverResultItem, searchPaymentLinksResolver } from '@iokanx/shared/data-access/api';

import { PaymentLinkListFilter } from '../payment-link-list-filter/payment-link-list-filter';
import { IPaymentLinkListFormFieldValues } from '../payment-link-list/payment-link-list';

export interface IPaymentLinkTableProps {
  form: UseFormReturn<IPaymentLinkListFormFieldValues>;
  limit: number;
  emptyText: string;
}

const columns: GridColumns<ISearchPaymentLinksResolverResultItem> = [
  {
    headerName: 'Ссылка на оплату',
    field: 'payment_link_url',
    renderCell: (props) => <ClipboardButton>{props.value}</ClipboardButton>,
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Slug',
    field: 'slug',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Оплаты',
    field: 'quantity',
    renderCell: (props) => <PaymentLinkStatus paymentLink={props.row} />,
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    renderCell: (props) => <PaymentLinkAmount paymentLink={props.row} />,
    flex: 1,
    sortable: false,
    type: 'number',
  },
  {
    headerName: 'Описание',
    field: 'description',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Дата создания',
    field: 'createdAt',
    flex: 1,
    sortable: false,
  },
];

export function PaymentLinkTable(props: IPaymentLinkTableProps) {
  const { data: paymentLinks, isFetching } = useQuery(['payment-links', props.form.watch()], () =>
    searchPaymentLinksResolver({ ...props.form.watch(), limit: props.limit }),
  );

  const page = (props.form.watch('page') ?? 1) - 1;

  const localeText = {
    ...ruRU.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: props.emptyText,
  };

  return (
    <Stack padding={2} spacing={2}>
      <PaymentLinkListFilter form={props.form} />
      <DataGrid
        rows={paymentLinks?.data || []}
        columns={columns}
        loading={isFetching}
        page={page}
        onPageChange={(page) => props.form.setValue('page', page + 1)}
        rowCount={paymentLinks?.count}
        localeText={localeText}
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
        pageSize={props.limit}
        rowsPerPageOptions={[props.limit]}
        paginationMode="server"
      />
    </Stack>
  );
}

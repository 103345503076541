import { ComponentProps } from 'react';
import { StyleSheet } from 'react-native';

import { Appbar } from '@iokanx/shared/ui/universal';

export type AppbarHeaderProps = ComponentProps<typeof Appbar.Header>;

export function AppbarHeader(props: AppbarHeaderProps) {
  return <Appbar.Header {...props} style={[styles.header, props.style]} />;
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
});

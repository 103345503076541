import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty, isUndefined } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { LEGAL_ADDRESS_TYPE, organizationAddressFields } from './constants';

import { isFormEmpty } from '../../../../../helpers/helpers';
import { useFormEdit } from '../../../../../helpers/useFormEdit';
import { useProfileFormContext } from '../../../../../providers/ProfileFormProvider';
import { FormField } from '../../../../FormField/FormField';
import { ProfileFormSection } from '../../../../layouts/ProfileFormSection';
import { getDefaultValues } from '../../helpers';
import { organizationAddressValidationSchema } from '../../model/schemas';

export interface IOrganizationAddress {
  isSameAddress?: boolean;
}

const ACTUAL_ADDRESS_TYPE = 'ACTUAL';

const filterAddress = (addresses: any[], type: string) => {
  return addresses?.find((addresses: any) => addresses.type === type);
};

const postData = async (data: any, organizationId: string) => {
  await backendHttp.post(`/organizations/${organizationId}/addresses`, {
    type: ACTUAL_ADDRESS_TYPE,
    ...data,
  });
};

const patchData = async (formData: any, organizationId: string, addresses: any[]) => {
  const address = filterAddress(addresses, ACTUAL_ADDRESS_TYPE);

  await backendHttp.patch(`/organizations/${organizationId}/addresses/${address.id}`, {
    type: ACTUAL_ADDRESS_TYPE,
    ...formData,
  });
};

export const OrganizationActualAddress: FC<IOrganizationAddress> = ({ isSameAddress }) => {
  const { organizationId, isSubmitted } = useProfileFormContext();

  const { data } = useQuery(['organization', organizationId, 'address'], async () => {
    const { data } = await backendHttp.get(`/organizations/${organizationId}/addresses`);

    return data;
  });

  const { mutate: mutateAddress } = useMutation(
    !isEmpty(filterAddress(data, ACTUAL_ADDRESS_TYPE))
      ? (formData: any) => patchData(formData, organizationId, data)
      : (formData: any) => postData(formData, organizationId),
    {
      onSuccess: () => {
        handleIsEdit();
      },
      onError: (error) => console.error(error),
    },
  );

  const { control, handleSubmit, reset, getValues, trigger } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(organizationAddressValidationSchema),
  });

  const onSubmit = (data: any) => mutateAddress(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(
    Boolean(!isEmpty(filterAddress(data, ACTUAL_ADDRESS_TYPE))),
    handleSubmit(onSubmit, onError),
  );

  useEffect(() => {
    const addressType = isSameAddress ? LEGAL_ADDRESS_TYPE : ACTUAL_ADDRESS_TYPE;

    const isCorrectAddress = (addresses: any) => addresses.type === addressType;
    const address = data?.find(isCorrectAddress);

    if (address) {
      reset({
        ...address,
        type: ACTUAL_ADDRESS_TYPE,
      });
    }
  }, [data, isSameAddress]);

  const handleSubmitSection = () => {
    const formValues = getValues();

    if (isFormEmpty(formValues)) {
      trigger();

      return;
    }

    handlePress();
  };

  useEffect(() => {
    if (!isUndefined(isSameAddress)) {
      handleIsEdit(false);
    }
  }, [isSameAddress]);

  return (
    <ProfileFormSection
      handleSubmit={handleSubmitSection}
      title={'Фактический адрес'}
      isSubmitted={isSubmitted}
      isSaved={isDisable}
    >
      {organizationAddressFields.map((field, index) => (
        <FormField key={index} name={field.name} control={control} label={field.label} disabled={isDisable} />
      ))}
    </ProfileFormSection>
  );
};

export enum SplitStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
}

export const SPLIT_STATUS_TITLE: Record<SplitStatus, string> = {
  [SplitStatus.Pending]: 'Создан',
  [SplitStatus.Approved]: 'Авторизован',
  [SplitStatus.Cancelled]: 'Отменен',
  [SplitStatus.Declined]: 'Отклонен',
};

export const SPLIT_STATUS_COLOR: Record<SplitStatus, string> = {
  [SplitStatus.Pending]: '#334155',
  [SplitStatus.Approved]: '#10B981',
  [SplitStatus.Cancelled]: '#B8CCE0',
  [SplitStatus.Declined]: '#EF4444',
};

import { yupResolver } from '@hookform/resolvers/yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/styles';
import { useMutation } from '@tanstack/react-query';
import { isBoolean, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';
import { RadioButtons } from '@iokanx/shared/ui/universal';

import { answerQueryBuilder, mapBooleanAnswer } from './helpers';

import { getBinaryItems } from '../../../helpers/helpers';
import { useFormEdit } from '../../../helpers/useFormEdit';
import { organizationQuestionValidationSchema } from '../../../model/schemas';
import { BinaryAnswer } from '../../../shared/types';
import { ConditionalComponent } from '../../ConditionalComponent';
import { FormField } from '../../FormField/FormField';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 140,
    fontSize: '14px',
  },
}));

interface IAnswerRequest {
  id: number;
  answer: boolean;
  organizationId: string;
  comment?: string;
  answerId?: number;
}

interface IProfileFormQuestion {
  isSubmitted: boolean;
  organizationId: string;
  title: string;
  description?: string;
  id: number;
  answer: IAnswerRequest;
}

const postData = async ({ id, answer, comment, organizationId }: IAnswerRequest) => {
  const { data } = await backendHttp.post(answerQueryBuilder(organizationId), {
    question_id: id,
    answer,
    comment,
  });

  return data;
};

const patchData = async ({ id, answer, comment, answerId, organizationId }: IAnswerRequest) => {
  const { data } = await backendHttp.patch(answerQueryBuilder(organizationId, answerId), {
    question_id: id,
    answer,
    comment,
  });

  return data;
};

const deleteData = async ({ answerId, organizationId }: { answerId: number; organizationId: string }) => {
  const { data } = await backendHttp.delete(answerQueryBuilder(organizationId, answerId));

  return data;
};

export const ProfileFormQuestion = ({
  isSubmitted,
  organizationId,
  answer,
  title,
  description,
  id,
}: IProfileFormQuestion) => {
  const { mutate: submitAnswer } = useMutation(
    answer?.answer
      ? (formData: any) =>
          patchData({
            id,
            organizationId,
            answer: formData.answer,
            comment: formData.comment,
            answerId: answer.id,
          })
      : (formData: any) =>
          postData({
            id,
            organizationId,
            answer: formData.answer,
            comment: formData.comment,
          }),
    {
      onSuccess: () => {
        handleIsEdit();
      },
      onError: (error) => console.error(error),
    },
  );

  const { mutate: deleteAnswer } = useMutation(async (_) => await deleteData({ organizationId, answerId: answer.id }), {
    onSuccess: () => {
      handleIsEdit();
    },
    onError: (error) => console.error(error),
  });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      answer: 'no',
      comment: '',
    },
    resolver: yupResolver(organizationQuestionValidationSchema),
  });

  const onSubmit = (data: any) => {
    data.answer = 'yes' === data.answer;

    if (!data.answer) {
      deleteAnswer();
      return;
    }

    submitAnswer(data);
  };

  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(
    isBoolean(!isEmpty(answer)),
    handleSubmit(onSubmit, onError),
  );

  useEffect(() => {
    const mappedAnswers = {
      answer: mapBooleanAnswer(answer?.answer) || 'no',
      comment: answer?.comment || '',
    };
    reset({ ...mappedAnswers });
  }, [answer]);

  const handleSubmitSection = () => {
    return isDirty ? handlePress() : handleIsEdit();
  };

  return (
    <ProfileFormSection isSubmitted={isSubmitted} isSaved={isDisable} handleSubmit={handleSubmitSection}>
      <Controller
        name={'answer'}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Item>
            <ConditionalComponent
              condition={Boolean(description)}
              component={
                <StyledTooltip
                  style={{ position: 'absolute', top: '24px', right: '18px', zIndex: 2 }}
                  title={description || ''}
                  arrow
                >
                  <IconButton size={'small'}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </StyledTooltip>
              }
            />
            <RadioButtons
              label={title}
              value={value}
              items={getBinaryItems(isDisable)}
              disabled={isDisable}
              onValueChange={onChange}
            />
          </Item>
        )}
      />

      <ConditionalComponent
        condition={watch('answer') === BinaryAnswer.YES}
        component={<FormField name={'comment'} control={control} label={'Ответ'} disabled={isDisable} />}
      />
    </ProfileFormSection>
  );
};

import { IRadioButtonItem } from '@iokanx/shared/ui/universal';

import { BINARY_ANSWER_I18N, BinaryAnswer } from './types';

export const commonInfoItems: IRadioButtonItem<BinaryAnswer>[] = Object.values(BinaryAnswer).map((captureMethod) => ({
  value: captureMethod,
  label: BINARY_ANSWER_I18N[captureMethod],
}));

export const QUESTION =
  'Является ли юридическое лицо самостоятельной организацией или частью какой-либо группы компании/холдинга?';

export const REQUIRED_ERROR_MESSAGE = 'Обязательное поле';
export const EMAIL_ERROR_MESSAGE = 'Некорректный email';

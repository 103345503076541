export enum PaymentFormTemplate {
  DEFAULT = 'DEFAULT',
  CUSTOM_AVIATA = 'CUSTOM_AVIATA',
  CUSTOM_AMWAY = 'CUSTOM_AMWAY',
  CUSTOM_AVIATA_APPLE_PAY = 'CUSTOM_AVIATA_APPLE_PAY',
  CUSTOM_AVIATA_GOOGLE_PAY = 'CUSTOM_AVIATA_GOOGLE_PAY',
  CUSTOM_AVIATA_RW = 'CUSTOM_AVIATA_RW',
  CUSTOM_CHOCOTRAVEL = 'CUSTOM_CHOCOTRAVEL',
  CUSTOM_CHOCOTRAVEL_APPLE_PAY = 'CUSTOM_CHOCOTRAVEL_APPLE_PAY',
  CUSTOM_CHOCOTRAVEL_GOOGLE_PAY = 'CUSTOM_CHOCOTRAVEL_GOOGLE_PAY',
}

export const AVIATA_TEMPLATES: string[] = [
  PaymentFormTemplate.CUSTOM_AVIATA,
  PaymentFormTemplate.CUSTOM_AVIATA_APPLE_PAY,
  PaymentFormTemplate.CUSTOM_AVIATA_GOOGLE_PAY,
  PaymentFormTemplate.CUSTOM_AVIATA_RW,
];

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Box } from '@mobily/stacks';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from '@react-navigation/drawer';
import { StyleSheet } from 'react-native';

import { DRAWER_ITEM_ICON_SIZE } from '@iokanx/shared/data-access/constants';
import { CDNImage, Colors, Divider } from '@iokanx/shared/ui/universal';

import { Amplitude } from '../amplitude/amplitude';
import { useMe } from '../auth';
import { useSignOut } from '../auth/use-sign-out/use-sign-out';
import { ShopsMenu } from '../shops-menu/shops-menu';

export function DashboardDrawerContent(props: DrawerContentComponentProps) {
  const { signOut } = useSignOut();

  const { drawerItemStyle, drawerLabelStyle } = props.descriptors[props.state.routes[props.state.index].key].options;

  const { me } = useMe();
  function handleSignOut() {
    signOut();
    return Amplitude.logEventWithPropertiesAsync('menu_exit', { user_id: me?.id });
  }

  return (
    <DrawerContentScrollView {...props} contentContainerStyle={styles.contentContainer}>
      <Box paddingX={8}>
        <CDNImage src="ioka-logo.svg" width={56} height={24} />
      </Box>
      <Box marginY={10} paddingX={4}>
        <ShopsMenu />
      </Box>
      <DrawerItemList {...props} />
      <Box marginY={6}>
        <Divider />
      </Box>
      <DrawerItem
        label={'Выход'}
        icon={(iconProps) => (
          <MaterialCommunityIcons name="exit-to-app" size={DRAWER_ITEM_ICON_SIZE} color={iconProps.color} />
        )}
        onPress={handleSignOut}
        inactiveTintColor={Colors.red500}
        style={drawerItemStyle}
        labelStyle={drawerLabelStyle}
      />
      <Divider style={{ marginTop: 'auto' }} />
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: '#F8FAFC',
    paddingTop: 40,
  },
});

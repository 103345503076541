import { backendHttp } from '../../../../instances';
import { IGetPayoutByIdResponseData } from '../index';

export interface IPostPayoutsByIdApproveRequestData {
  amount?: number;
}

export type IPostPayoutsByIdApproveResponseData = IGetPayoutByIdResponseData;

/** Отмена авторизованного платежа заказа */
export async function postPayoutsByIdApprove(
  payoutId: string,
  requestData?: IPostPayoutsByIdApproveRequestData,
): Promise<IPostPayoutsByIdApproveResponseData> {
  const { data } = await backendHttp.post<IPostPayoutsByIdApproveResponseData>(
    `/reports/payouts/${payoutId}/approve`,
    requestData,
  );
  return data;
}

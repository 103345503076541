import { useState, useEffect } from 'react';

const isInvalidDate = (date: Date | string | null): boolean => {
  return String(date) === 'Invalid Date';
};

const getSessionStorageDate = (stringDate?: string | null) => {
  if (!stringDate) return null;
  let date;

  try {
    date = new Date(stringDate);
  } catch (_) {
    date = null;
  }

  return isInvalidDate(date) ? null : date;
};

export const useSessionStorageDate = () => {
  const [date, setDate] = useState({
    startDate: getSessionStorageDate(sessionStorage.getItem('start_date') || null),
    endDate: getSessionStorageDate(sessionStorage.getItem('end_date') || null),
  });

  useEffect(() => {
    sessionStorage.setItem('start_date', String(date.startDate));
    sessionStorage.setItem('end_date', String(date.endDate));
  }, [date]);

  return { ...date, setDate };
};

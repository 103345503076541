import { prepareDateTime } from 'libs/shared/util/src/date-formatter';

import { getEmailsByShopId, IGetEmailByShopsIdResponseData } from '../../resources';

export interface IFindEmailsByShopIdParams {
  shopId: string;
}

export interface IFindEmailByShopIdResult extends IGetEmailByShopsIdResponseData {
  createdAt?: string;
}

export async function findEmailsByShopIdResolver(
  params: IFindEmailsByShopIdParams,
): Promise<IFindEmailByShopIdResult[]> {
  const emails = await getEmailsByShopId(params.shopId);
  return emails.map(transformEmail);
}

export function transformEmail(email: IGetEmailByShopsIdResponseData): IFindEmailByShopIdResult {
  return {
    ...email,
    createdAt: prepareDateTime(email.created_at),
  };
}

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';

import { isFormEmpty } from '../../../helpers/helpers';
import { useFormEdit } from '../../../helpers/useFormEdit';
import { organizationLicenseValidationSchema } from '../../../model/schemas';
import { DatePickerYear } from '../../Datepicker';
import { FormField } from '../../FormField/FormField';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { formatDateTime } from '../HolderStep/helpers';

const licenseQueryBuilder = (organizationId: string, okedId: string, licenseId: string) =>
  `organizations/${organizationId}/okeds/${okedId}/licenses/${licenseId}`;

const postData = async (data: any, organizationId: string, okedId: string) => {
  const { type, number, issue_date, expiry_date } = data;

  const { data: response } = await backendHttp.post(`organizations/${organizationId}/okeds/${okedId}/licenses`, {
    type,
    number,
    issue_date: formatDateTime(new Date(issue_date)),
    expiry_date: formatDateTime(new Date(expiry_date)),
  });

  return response;
};

const patchData = async (data: any, organizationId: string, okedId: string, licenseId: string) => {
  const { type, number, issue_date, expiry_date } = data;

  const { data: response } = await backendHttp.patch(licenseQueryBuilder(organizationId, okedId, licenseId), {
    type,
    number,
    issue_date: formatDateTime(new Date(issue_date)),
    expiry_date: formatDateTime(new Date(expiry_date)),
  });

  return response;
};

export const License = ({
  isSubmitted,
  organizationId,
  oked,
  license,
}: {
  isSubmitted: boolean;
  organizationId: string;
  oked: any;
  license?: any;
}) => {
  const queryClient = useQueryClient();

  const { mutate: removeLicense } = useMutation(
    async () => {
      const { data: response } = await backendHttp.delete(licenseQueryBuilder(organizationId, oked.id, license.id));

      return response;
    },
    {
      onSuccess: async () => {
        await Promise.all([queryClient.invalidateQueries(['okeds']), queryClient.invalidateQueries(['licenses'])]);
      },
    },
  );

  const { mutate: updateLicense } = useMutation(
    license
      ? (formData) => patchData(formData, organizationId, oked.id, license.id)
      : (formData) => postData(formData, organizationId, oked.id),
    {
      onSuccess: async (data: any) => {
        reset(data);
        handleIsEdit();
        await queryClient.invalidateQueries(['licenses', oked?.id]);
      },
    },
  );

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      type: '',
      number: '',
      issue_date: undefined,
      expiry_date: undefined,
    },
    resolver: yupResolver(organizationLicenseValidationSchema),
  });

  useEffect(() => {
    if (license) {
      const { type, number, issue_date, expiry_date } = license;

      reset({
        type,
        number,
        issue_date,
        expiry_date,
      });
    }
  }, [license]);

  const onSubmit = (data: any) => updateLicense(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(Boolean(license), handleSubmit(onSubmit, onError));

  const handleButtonPress = () => {
    const formValues = getValues();

    if (isFormEmpty(formValues)) {
      trigger();

      return;
    }

    return isDirty ? handlePress() : handleIsEdit();
  };

  const handleRemove = () => removeLicense();

  return (
    <ProfileFormSection
      title="Лицензии"
      isSaved={isDisable}
      isSubmitted={isSubmitted}
      isRemovable={true}
      handleRemove={handleRemove}
      handleSubmit={handleButtonPress}
    >
      <FormField name={'type'} control={control} label={'Вид'} disabled={isDisable} />
      <FormField name={'number'} control={control} label={'Номер'} disabled={isDisable} />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Item>
              <DatePickerYear
                disabled={isDisable}
                label="Дата выдачи"
                value={value && new Date(value)}
                onChange={onChange}
              />
            </Item>
          );
        }}
        name={'issue_date'}
      />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Item>
              <DatePickerYear
                disabled={isDisable}
                label="Срок действия"
                value={value && new Date(value)}
                onChange={onChange}
              />
            </Item>
          );
        }}
        name={'expiry_date'}
      />
    </ProfileFormSection>
  );
};

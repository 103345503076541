import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { turnoverQueryBuilder } from './TurnoversStep';

import { useFormEdit } from '../../helpers/useFormEdit';
import { FormField } from '../FormField/FormField';
import { ProfileFormSection } from '../layouts/ProfileFormSection';

interface ITurnoverRequest {
  period: number;
  amount: number;
  count: number;
}

const postData = async ({ amount, count, period, organizationId }: any) => {
  const { data } = await backendHttp.post<ITurnoverRequest>(turnoverQueryBuilder(organizationId), {
    period: Number(period?.period),
    amount: Number(amount),
    count: Number(count),
    countries: [],
  });

  return data;
};

const patchData = async ({ amount, count, period, organizationId, turnoverId }: any) => {
  const { data } = await backendHttp.patch<ITurnoverRequest>(turnoverQueryBuilder(organizationId, turnoverId), {
    period: Number(period.period),
    amount: Number(amount),
    count: Number(count),
    countries: [],
  });

  return data;
};

export const Turnover = ({
  handleIsEdit,
  disabled,
  handleSubmitSections,
  isSubmitted,
  organizationId,
  period,
  turnoverData,
}: {
  handleIsEdit: any;
  disabled: boolean;
  handleSubmitSections: any;
  isSubmitted: boolean;
  organizationId: string;
  period: any;
  turnoverData: any;
}) => {
  const { mutate: submitAnswer } = useMutation(
    turnoverData
      ? (formData: any) => patchData({ ...formData, organizationId, period, turnoverId: turnoverData.id })
      : (formData: any) => postData({ ...formData, period, organizationId }),
    {
      onSuccess: async (data) => {
        console.log('turnover created', data);
        handleIsEdit();
      },
      onError: (error) => console.error(error),
    },
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      amount: '',
      count: '',
    },
  });

  const onSubmit = (data: any) => {
    submitAnswer(data);
  };
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress } = useFormEdit(disabled, handleSubmit(onSubmit, onError));

  const handleSectionSubmit = () => {
    return handlePress();
  };

  useEffect(() => {
    const mappedTurnovers = {
      amount: turnoverData?.amount ?? '',
      count: turnoverData?.count ?? '',
    };

    reset({ ...mappedTurnovers });
  }, [turnoverData]);

  useEffect(() => {
    handleSubmitSections(handleSectionSubmit);
  }, []);

  return (
    <ProfileFormSection isSaved={isDisable} isSubmitted={isSubmitted} handleSubmit={null} style={{ margin: 0 }}>
      <FormField name={'amount'} control={control} label={period.amount} disabled={isDisable} />
      <FormField name={'count'} control={control} label={period.count} disabled={isDisable} />
    </ProfileFormSection>
  );
};

import { backendHttp } from '../../instances';

export interface ICheckNPS {
  required: boolean;
}

export interface IVoteNPS {
  rating: number;
  tags: string[];
  comment?: string;
}

export async function checkNPS(): Promise<ICheckNPS> {
  const { data } = await backendHttp.post<ICheckNPS>('support/nps/check');
  return data;
}

export async function voteNPS({ rating, comment, tags }: IVoteNPS) {
  const { data } = await backendHttp.post<IVoteNPS>('support/nps/vote', {
    rating,
    comment,
    tags,
  });
  return data;
}

export async function exitNPS() {
  const { data } = await backendHttp.get('support/nps/exit');
  return data;
}

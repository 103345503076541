import { createContext, ReactNode, useContext } from 'react';

interface IProfileFormProvider {
  children: ReactNode;
  organizationId: string;
  isSubmitted: boolean;
}

const ProfileFormContext = createContext<any>(null);

export const useProfileFormContext = () => useContext(ProfileFormContext);

export const ProfileFormProvider = ({ children, organizationId, isSubmitted }: IProfileFormProvider) => {
  return <ProfileFormContext.Provider value={{ organizationId, isSubmitted }}>{children}</ProfileFormContext.Provider>;
};

import { backendHttp } from '../../instances/backend-http';

interface OrganizationEmployer {
  last_name: string;
  first_name: string;
  patronymic: string;
  position: string;
  email: string;
  phone: string;
  id: string;
  created_at: string;
}

interface OrganizationType {
  code: string;
  name: string;
  parent_id: number;
  short: string;
  id: string;
}

export interface OrganizationsByIdResponse {
  id: string;
  legal_name: string;
  trade_name: string;
  nib: string;
  created_at: string;
  organization_type: OrganizationType;
  employer: OrganizationEmployer;
  status: string;
}

export interface OrganizationsResponse {
  count: number;
  data: any[];
}

export async function getOrganizationById(organizationId: string): Promise<OrganizationsByIdResponse> {
  const { data } = await backendHttp.get(`/organizations/${organizationId}`);
  return data;
}

export async function getOrganizations(): Promise<OrganizationsResponse> {
  const { data } = await backendHttp.get(`/organizations?limit=10`);

  return data;
}

import { ShopUserRole } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../../instances';

export interface IGetShopsByIdUsersResponseDataItem {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  role: ShopUserRole;
}
export type IGetShopsByIdUsersResponseData = IGetShopsByIdUsersResponseDataItem[];

/** Получение списка пользователей магазина */
export async function getShopsByIdUsers(shopId: string): Promise<IGetShopsByIdUsersResponseData> {
  const { data } = await backendHttp.get(`/shops/${shopId}/users`);
  return data;
}

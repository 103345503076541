import { useState, useEffect } from 'react';

import { IRemainsTimeObjectType } from '@iokanx/payment-form/types';

export interface IUseTimer {
  timeOut: boolean;
  isError: boolean;
  msg: string;
  time: IRemainsTimeObjectType | null;
}

const sInMs = 1;
const mInMs = sInMs * 60;
const hInMs = mInMs * 60;
const dInMs = hInMs * 24;

function remainsTimeObject(remainsInMs: number): IRemainsTimeObjectType {
  const d = Math.floor(remainsInMs / dInMs);
  remainsInMs %= dInMs;

  const h = Math.floor(remainsInMs / hInMs);
  remainsInMs %= hInMs;

  const m = Math.floor(remainsInMs / mInMs);
  remainsInMs %= mInMs;

  const s = Math.floor(remainsInMs / sInMs);

  return { d, h, m, s };
}

const nowInSeconds = (): number => Math.floor(Date.now() / 1000);

let current: number = nowInSeconds();

export function useTimer(date: string, label: string): IUseTimer {
  const dueTo: number = Math.floor(+new Date(`${date}+00:00`) / 1000);

  const [diff, setDiff] = useState<number>(0);
  const [timeOut, setTimeOut] = useState<boolean>(false);
  const [remainsTime, setRemainsTime] = useState<IRemainsTimeObjectType>({
    d: 0,
    h: 0,
    m: 0,
    s: 0,
  });

  useEffect(() => {
    current = nowInSeconds();
    setDiff(dueTo - current);
  }, [dueTo]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (diff % 60 === 0) {
        current = nowInSeconds();
        setDiff(dueTo - current - 1);
      } else if (diff > 0) {
        setDiff(diff - 1);
      } else {
        setTimeOut(true);
      }
    }, 1000);

    setRemainsTime(remainsTimeObject(diff));

    return () => clearTimeout(timer);
  }, [diff, dueTo]);

  if (Number.isNaN(dueTo)) {
    return {
      timeOut: false,
      isError: true,
      msg: 'Неправильный формат даты',
      time: null,
    };
  }

  if (dueTo - current <= 1) {
    return {
      timeOut: true,
      isError: false,
      msg: 'Истек время',
      time: null,
    };
  }

  if (timeOut) {
    return {
      timeOut: true,
      isError: false,
      msg: 'Истек время',
      time: remainsTime,
    };
  }

  return {
    isError: false,
    timeOut: false,
    msg: label,
    time: remainsTime,
  };
}

import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQuery } from '@tanstack/react-query';

import { IMeResolverResult, meResolver } from '@iokanx/shared/data-access/api';
import { SELECTED_SHOP_ID, SELECTED_SHOP_ROLE, ShopUserRole } from '@iokanx/shared/data-access/constants';

import { getAccessToken } from '../token';

export interface IUseMe {
  me?: IMeResolverResult | null;
}

export function useMe(): IUseMe {
  const { data: me } = useQuery(['me'], async () => {
    const accessToken = await getAccessToken();
    return accessToken ? meResolver() : null;
  });
  return { me };
}

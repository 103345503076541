import { Currency, CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';
import { compactObject } from '@iokanx/shared/util';

import { transformRevise } from './find-revise-by-id.resolver';

import {
  getRevises,
  IGetReviseByIdResponseData,
  IGetRevisesRequestParams,
  IGetRevisesResponseData,
  IGetStatsResponse,
} from '../../resources';

export interface IFindRevisesRequestResolverParams extends IGetRevisesRequestParams {
  /** Маска карты */
  reference?: string;
  order_id?: string;
  diff_type?: string;
  acquirer?: string;
}

export interface IFindReviseResolverStatsItem extends IGetStatsResponse {
  totalBankAmountWithSymbol?: string;
  totalBankAcquirerFeeWithSymbol?: string;
  totalBankProcessingFeeWithSymbol?: string;
  totalAmountWithSymbol?: string;
  totalAcquirerFeeWithSymbol?: string;
  totalProcessingFeeWithSymbol?: string;
  totalDiffAmountWithSymbol?: string;
  totalDiffAcquirerFeeWithSymbol?: string;
}

export interface IFindReviseResolverResultItem extends IGetReviseByIdResponseData {
  amountWithSymbol?: string;
  processingFeeWithSymbol?: string;
  acquirerFeeWithSymbol?: string;

  bankAmountWithSymbol?: string;
  bankProcessingFeeWithSymbol?: string;
  bankAcquirerFeeWithSymbol?: string;
  dateTime?: string;
  bankDateTime?: string;
  bankReceiptDate?: string;
  shopId?: string;
}

export interface IFindRevisesResolverResult extends IGetRevisesResponseData {
  stats: IFindReviseResolverStatsItem;
}

export async function searchRevisesResolver(params: IGetRevisesRequestParams): Promise<IFindRevisesResolverResult> {
  const revises = await getRevises(compactObject(params));
  const data = revises.data.map(transformRevise);
  const stats = transformReviseStats(revises.stats);
  return { ...revises, data, stats };
}

export function transformReviseStats(stats: IGetStatsResponse): IFindReviseResolverStatsItem {
  const totalBankAmount = stats.total_bank_amount ? stats.total_bank_amount / 100 : '-';
  const totalBankAmountWithSymbol = `${totalBankAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const totalBankProcessingFee = stats.total_bank_processing_fee ? stats.total_bank_processing_fee / 100 : '-';
  const totalBankProcessingFeeWithSymbol = `${totalBankProcessingFee.toLocaleString()} ${
    CURRENCY_SYMBOL[Currency.KZT]
  }`;

  const totalBankAcquirerFee = stats.total_bank_acquirer_fee ? stats.total_bank_acquirer_fee / 100 : '-';
  const totalBankAcquirerFeeWithSymbol = `${totalBankAcquirerFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const totalAmount = stats.total_amount ? stats.total_amount / 100 : '-';
  const totalAmountWithSymbol = `${totalAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const totalProcessingFee = stats.total_processing_fee ? stats.total_processing_fee / 100 : '-';
  const totalProcessingFeeWithSymbol = `${totalProcessingFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const totalAcquirerFee = stats.total_acquirer_fee ? stats.total_acquirer_fee / 100 : '-';
  const totalAcquirerFeeWithSymbol = `${totalAcquirerFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const totalDiffAmount = stats.total_diff_amount ? stats.total_diff_amount / 100 : '-';
  const totalDiffAmountWithSymbol = `${totalDiffAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const totalDiffAcquirerFee = stats.total_diff_acquirer_fee ? stats.total_diff_acquirer_fee / 100 : '-';
  const totalDiffAcquirerFeeWithSymbol = `${totalDiffAcquirerFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  return {
    ...stats,
    totalBankAmountWithSymbol: totalBankAmountWithSymbol,
    totalBankAcquirerFeeWithSymbol: totalBankAcquirerFeeWithSymbol,
    totalBankProcessingFeeWithSymbol: totalBankProcessingFeeWithSymbol,
    totalAmountWithSymbol: totalAmountWithSymbol,
    totalAcquirerFeeWithSymbol: totalAcquirerFeeWithSymbol,
    totalProcessingFeeWithSymbol: totalProcessingFeeWithSymbol,
    totalDiffAmountWithSymbol: totalDiffAmountWithSymbol,
    totalDiffAcquirerFeeWithSymbol: totalDiffAcquirerFeeWithSymbol,
  };
}

import { transformShop } from '@iokanx/shared/data-access/api';

import { IGetShopByIdResponseData, updateShopById } from '../../resources';

export interface IUpdateShopByIdResult extends IGetShopByIdResponseData {
  createdAt?: string;
  displayName?: string;
}

export async function updateShopByIdResolver(
  shopId: string,
  shop: IGetShopByIdResponseData,
): Promise<IUpdateShopByIdResult> {
  const result = await updateShopById(shopId, shop);
  return transformShop(result);
}

import { Currency, CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';

import {
  getAnalyticsAggregations,
  IGetAnalyticsAggregationsRequestParams,
  IGetAnalyticsAggregationsResponseData,
} from '../../resources';

export type IGetAggregationAnalyticsResolverParams = IGetAnalyticsAggregationsRequestParams;

export interface IGetAggregationAnalyticsResolverResult extends IGetAnalyticsAggregationsResponseData {
  // Нормализованная сумма списания с символом валюты
  capturedAmountWithSymbol: string;
  // Нормализованное кол-во списаний
  capturedCount: string;
  // Нормализованная комиссия с символом валюты
  processingFeeWithSymbol: string;
  // Нормализованная комиссия с символом валюты
  acquireFeeWithSymbol: string;
  // Нормализованная комиссия с символом валюты
  iokaFeeWithSymbol: string;
  // Чистая прибыль с символом валюты
  netProfitWithSymbol: string;
  /** Нормализованная авторизованная сумма с символом валюты */
  approvedAmountWithSymbol: string;
  /** Нормализованное кол-во авторизованных транзакций */
  approvedCount: string;
  /** Нормализованная возвращенная сумма с символом валюты */
  refundedAmountWithSymbol: string;
}

export async function getAggregationAnalyticsResolver(
  params: IGetAggregationAnalyticsResolverParams,
): Promise<Readonly<IGetAggregationAnalyticsResolverResult>> {
  const analytics = await getAnalyticsAggregations(params);

  return transformAnalyticsAggregations(analytics);
}

export function _getWholePart(val: number) {
  return Math.trunc(val / 100);
}

export function transformAnalyticsAggregations(
  analytics: IGetAnalyticsAggregationsResponseData,
): IGetAggregationAnalyticsResolverResult {
  const capturedAmount = _getWholePart(analytics.captured_amount);
  const capturedAmountWithSymbol = `${capturedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;
  const capturedCount = analytics.captured_count.toLocaleString();

  const processingFee = _getWholePart(analytics.processing_fee);
  const processingFeeWithSymbol = `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const acquireFee = _getWholePart(analytics.acquirer_fee);
  const acquireFeeWithSymbol = `${acquireFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const iokaFee = _getWholePart(analytics.processing_fee - analytics.acquirer_fee);
  const iokaFeeWithSymbol = `${iokaFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const netProfit = _getWholePart(analytics.captured_amount - analytics.processing_fee);
  const netProfitWithSymbol = `${netProfit.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const approvedAmount = _getWholePart(analytics.approved_amount);
  const approvedAmountWithSymbol = `${approvedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;
  const approvedCount = analytics.approved_count.toLocaleString();

  const refundedAmount = _getWholePart(analytics.refunded_amount);
  const refundedAmountWithSymbol = `${refundedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  return {
    ...analytics,
    capturedAmountWithSymbol,
    capturedCount,
    processingFeeWithSymbol,
    netProfitWithSymbol,
    approvedAmountWithSymbol,
    acquireFeeWithSymbol,
    iokaFeeWithSymbol,
    refundedAmountWithSymbol,
    approvedCount,
  };
}

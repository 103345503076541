import { Suspense } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import {
  ErrorResetBoundary,
  PaperThemeProvider,
  ReactQueryClientProvider,
  SnackbarProvider,
  StacksProvider,
} from '@iokanx/shared/feature';
import { PageLoader } from '@iokanx/shared/ui/universal';

import { ConfigProvider } from './config-provider/config-provider';
import { MetricsProvider } from './metrics-provider/metrics-provider';
import { NavigationProvider } from './navigation-provider/navigation-provider';

export function Providers() {
  return (
    <SafeAreaProvider>
      <PaperThemeProvider>
        <ErrorResetBoundary>
          <MetricsProvider>
            <Suspense fallback={<PageLoader />}>
              <StacksProvider>
                <ReactQueryClientProvider>
                  <ConfigProvider>
                    <SnackbarProvider>
                      <NavigationProvider />
                    </SnackbarProvider>
                  </ConfigProvider>
                </ReactQueryClientProvider>
              </StacksProvider>
            </Suspense>
          </MetricsProvider>
        </ErrorResetBoundary>
      </PaperThemeProvider>
    </SafeAreaProvider>
  );
}

export enum CommandEvent {
  GetStatus = 'GET_STATUS', // Получение статуса платежа
  InitPayment = 'INIT_PAYMENT', // Оплата по полным карточным данным - - PAN, EXP, HOLDER, CVC
  InitPaymentNotCvc = 'INIT_PAYMENT_NO_CVC', // Оплата по карточным данным - PAN, EXP, HOLDER
  InitPaymentWithBinding = 'INIT_PAYMENT_WITH_BINDING', // Оплата по полным карточным данным с последующим сохранением
  InitBindingPayment = 'INIT_BINDING_PAYMENT', // Оплата по карточным связкам без проверки CVC
  InitBindingPaymentCvc = 'INIT_BINDING_PAYMENT_CVC', // Оплата по карточным связкам c проверкой CVC (возможно 3DS)
  Finish3dsPayment = 'FINISH_3DS_PAYMENT', // Завершение 3DS процесса
  Capture = 'CAPTURE', // Списание авторизованной суммы
  Cancel = 'CANCEL', // Отмена авторизованной суммы
  Refund = 'REFUND', // Частичный или полный возврат списанной суммы
  Revoke = 'REVOKE', // Отмена или возврат платежа при недоступности банка
  InitBinding = 'INIT_BINDING', // Верификация карточных данных и получение связки (binding_id, client_id)
  Finish3dsBinding = 'FINISH_3DS_BINDING', // Завершение 3DS процесса при верификации карт - идентичен с FINISH_3DS_PAYMENT
  InitGooglePay = 'INIT_GOOGLE_PAY', // Оплата через Google Pay
  InitApplePay = 'INIT_APPLE_PAY', // Оплата через Apple Pay
  // Оплата по полным карточным данным, но без CVC
  // INIT_MASTER_PASS = 'INIT_MASTER_PASS'  nosec: B105,
  // Расщепление средств
  InitSplit = 'INIT_SPLIT',
  InitSplitRefund = 'INIT_SPLIT_REFUND',
  // Отправка чека в веб кассу
  InitCheck = 'INIT_CHECK',
  InitCheckRefund = 'INIT_CHECK_REFUND',
  SendOtp = 'SEND_OTP',
  ConfirmOtp = 'CONFIRM_OTP',
  ScoringRequest = 'SCORING_REQUEST',
}

export const COMMAND_EVENT_I18N: Record<CommandEvent, string> = {
  [CommandEvent.GetStatus]: 'Проверка статуса',
  [CommandEvent.InitPayment]: 'Авторизация платежа',
  [CommandEvent.InitPaymentNotCvc]: 'Авторизация платежа',
  [CommandEvent.InitPaymentWithBinding]: 'Авторизация платежа с сохр. карты',
  [CommandEvent.InitBindingPayment]: 'Авторизация платежа по сохр. карте',
  [CommandEvent.InitBindingPaymentCvc]: 'Авторизация платежа по сох. карте с CVC',
  [CommandEvent.Finish3dsPayment]: 'Завершение 3DS',
  [CommandEvent.Capture]: 'Списание',
  [CommandEvent.Cancel]: 'Отмена',
  [CommandEvent.Refund]: 'Возврат',
  [CommandEvent.Revoke]: 'Отмена при ошибке',
  [CommandEvent.InitBinding]: 'Авторизация сохр. карты',
  [CommandEvent.Finish3dsBinding]: 'Завершение 3DS при сохр. карты',
  [CommandEvent.InitGooglePay]: 'Авторизация по GooglePay',
  [CommandEvent.InitApplePay]: 'Авторизация по ApplePay',
  [CommandEvent.InitSplit]: 'Расщепление',
  [CommandEvent.InitSplitRefund]: 'Расщепление при возврате',
  [CommandEvent.InitCheck]: 'Фиск. чек',
  [CommandEvent.InitCheckRefund]: 'Фиск. чек при возврате',
  [CommandEvent.SendOtp]: 'SEND_OTP',
  [CommandEvent.ConfirmOtp]: 'CONFIRM_OTP',
  [CommandEvent.ScoringRequest]: 'SCORING_REQUEST',
};

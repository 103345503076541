import { IPostShopRequestData, IPostShopResponseData, createShop } from '../../resources';

export interface ICreateShopResolverParams extends IPostShopRequestData {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
}

export type ICreateShopResolverResult = IPostShopResponseData;

export function createShopResolver({
  ...params
}: ICreateShopResolverParams): Promise<Readonly<ICreateShopResolverResult>> {
  return createShop({
    ...params,
  });
}

import { Stack } from '@mobily/stacks';
import { StyleSheet } from 'react-native';

import { PaymentStatusChip } from '@iokanx/dashboard/ui';
import { IFindPaymentByIdResult } from '@iokanx/shared/data-access/api';
import { PaymentStatus } from '@iokanx/shared/data-access/constants';
import { Divider, List, ListItem, Text } from '@iokanx/shared/ui/universal';

import { CancelPayment } from '../cancel-payment/cancel-payment';
import { CapturePayment } from '../capture-payment/capture-payment';
import { RefundPayment } from '../refund-payment/refund-payment';

interface PaymentCardProps {
  payment: IFindPaymentByIdResult;
}

export function PaymentCard(props: PaymentCardProps) {
  const isApprovedPayment = props.payment.status === PaymentStatus.Approved;
  const isCapturedPayment = props.payment.status === PaymentStatus.Captured;

  const canRefund = isCapturedPayment && props.payment.capturedAmount > 0;

  return (
    <List.Accordion
      title={
        <Stack horizontal align={'center'} style={styles.titleContainer}>
          <Text>{props.payment.id}</Text>
          <PaymentStatusChip paymentStatus={props.payment.status} />
        </Stack>
      }
      id={props.payment.id}
    >
      <List.Section title={'Авторизованная сумма'}>
        <ListItem title={props.payment.approvedAmountWithSymbol} />
      </List.Section>
      {isApprovedPayment && (
        <Stack padding={2} space={2}>
          <CapturePayment payment={props.payment} />
          <CancelPayment payment={props.payment} />
        </Stack>
      )}
      {canRefund && (
        <Stack padding={2} space={2}>
          <RefundPayment payment={props.payment} />
        </Stack>
      )}
      <Divider />
      <List.Section title={'Детали'}>
        <ListItem title={'Сумма списания'} description={props.payment.capturedAmountWithSymbol} />
        <ListItem title={'Сумма возврата'} description={props.payment.refundedAmountWithSymbol} />
        {props.payment.error && <ListItem title={'Подробнее о статусе'} description={props.payment.error.message} />}
        <ListItem title={'Дата создания'} description={props.payment.createdAt} />
      </List.Section>
      <Divider />
      <List.Section title={'Карта'}>
        <ListItem title={'Номер карты'} description={props.payment.payer?.pan_masked || '-'} />
        <ListItem title={'Эмитент'} description={props.payment.payer?.emitter || '-'} />
        <ListItem title={'Платежная система'} description={props.payment.payer?.payment_system || '-'} />
        <ListItem title={'Способ оплаты'} description={props.payment.paymentMethod || '-'} />
      </List.Section>
      <Divider />
      <List.Section title={'Клиент'}>
        <ListItem title={'Номер телефона'} description={props.payment.payer?.phone || '-'} />
        <ListItem title={'Email'} description={props.payment.payer?.email || '-'} />
      </List.Section>
      <Divider />
      <List.Section title={'Эквайер'}>
        <ListItem title={'Имя'} description={props.payment.acquirer?.name || '-'} />
        <ListItem title={'RRN'} description={props.payment.acquirer?.reference || '-'} />
      </List.Section>
    </List.Accordion>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    justifyContent: 'space-between',
    display: 'flex',
  },
});

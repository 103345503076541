import { authHttp } from '../../instances/auth-http';

export interface IPostAuthRegisterRequestData {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}

export interface IPostAuthRegisterResponseData {
  access_token: string;
  refresh_token: string;
}

export async function postAuthRegister(
  requestData: IPostAuthRegisterRequestData,
): Promise<IPostAuthRegisterResponseData> {
  const { data } = await authHttp.post<IPostAuthRegisterResponseData>('/auth/register', requestData);
  return data;
}

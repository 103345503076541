import { getShopsByIdUsers, IGetShopsByIdUsersResponseDataItem } from '../../resources';

export interface IFindShopUsersResolverParams {
  shopId: string;
}

export interface IFindShopUsersResolverResultItem extends IGetShopsByIdUsersResponseDataItem {
  fullName: string;
}
export type IFindShopUsersResolverResult = IFindShopUsersResolverResultItem[];

export async function findShopUsersResolver(
  params: IFindShopUsersResolverParams,
): Promise<IFindShopUsersResolverResult> {
  const users = await getShopsByIdUsers(params.shopId);
  return users.map(transformShopUser);
}

export function transformShopUser(user: IGetShopsByIdUsersResponseDataItem): IFindShopUsersResolverResultItem {
  const fullName = [user.first_name, user.last_name].join(' ');
  return {
    ...user,
    fullName,
  };
}

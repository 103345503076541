import Constants from 'expo-constants';
import { PropsWithChildren } from 'react';
import * as Sentry from 'sentry-expo';

import { Amplitude } from '@iokanx/dashboard/feature';

Sentry.init({
  dsn: Constants.expoConfig?.extra?.DASHBOARD_SENTRY_DSN,
  enableInExpoDevelopment: true,
});

Amplitude.initializeAsync(Constants.expoConfig?.extra?.DASHBOARD_AMPLITUDE_API_KEY);

export function MetricsProvider(props: PropsWithChildren) {
  return <>{props.children}</>;
}

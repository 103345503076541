import type { RGB } from '@ctrl/tinycolor';
import type { SliderProps } from '@mui/material/Slider';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import React from 'react';

type AlphaSliderProps = SliderProps & {
  rgbColor: RGB;
};

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) => {
    return prop !== '$rgbaFrom' && prop !== '$rgbaTo';
  },
})(() => ({
  height: 8,

  '& .MuiSlider-rail': {
    opacity: 1,
    // TODO: find better way for perf
    background: `linear-gradient(to right, rgba(var(--rgb-r), var(--rgb-g), var(--rgb-b), 0) 0%, rgba(var(--rgb-r), var(--rgb-g), var(--rgb-b), 1) 100%)`,
  },

  '& .MuiSlider-track': {
    color: 'transparent',
    border: 0,
  },

  '& .MuiSlider-thumb': {
    backgroundColor: '#ffffff',
    border: '3px solid currentColor',
  },
}));

export const AlphaSlider = (props: AlphaSliderProps) => {
  const { rgbColor, style, className, ...restSliderProps } = props;

  const sliderStyle = {
    '--rgb-r': rgbColor.r,
    '--rgb-g': rgbColor.g,
    '--rgb-b': rgbColor.b,
    ...style,
  } as React.CSSProperties;

  return (
    <StyledSlider className={`MuiColorInput-AlphaSlider ${className || ''}`} style={sliderStyle} {...restSliderProps} />
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import { Checkbox, Collapse, FormControlLabel, MenuItem, Select, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { UseMutateFunction, useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { useState } from 'react';
import { useForm, Controller, SubmitHandler, Control } from 'react-hook-form';
import * as yup from 'yup';

import {
  findShopFeaturesResolver,
  ICreateOrderResolverParams,
  ICreateOrderResolverResult,
} from '@iokanx/shared/data-access/api';
import { CAPTURE_METHOD_I18N, CaptureMethod } from '@iokanx/shared/data-access/constants';
import { HelperText, TextField, DatePicker, Button, Icon } from '@iokanx/shared/ui/universal';

import { useSelectedShop } from '../use-selected-shop/use-selected-shop';

export interface ICreateOrderFormProps {
  createOrder: UseMutateFunction<ICreateOrderResolverResult, unknown, ICreateOrderResolverParams>;
  isLoading: boolean;
  error?: string;
  isError: boolean;
}

type ICreateOrderFormFieldValues = Pick<
  ICreateOrderResolverParams,
  'external_id' | 'amount' | 'description' | 'captureMethod' | 'dueDate'
>;

export const MIN_ORDER_AMOUNT = 1;

const MenuProps = {
  PaperProps: {
    style: {
      width: 'fit-content',
    },
  },
};

function generateValidationSchema(fields: any) {
  if (!fields) return null;
  const schema = {};
  fields.forEach((field: any) => {
    let fieldSchema;
    if (field.type !== 'number') {
      // @ts-ignore
      if (field.required) {
        fieldSchema = yup[field.type]().required('Обязательное поле');
      } else {
        fieldSchema = yup[field.type]();
      }
    }
    if (field.type === 'number') {
      fieldSchema = yup
        .mixed()
        .test({
          name: 'customNumber',
          message: '${path} must be a number matching the pattern',
          test: (value: any) => {
            return /^[0-9]*$/.test(value);
          },
        })
        .required('Обязательное поле');
    }
    if (field.email) fieldSchema.email();
    // @ts-ignore
    schema[field.name] = fieldSchema;
  });
  return yup.object().shape(schema);
}

function generateAmountValidationSchema(limit?: any) {
  let schema = yup.number().required();
  if (limit && limit?.min) schema = schema.min(limit.min / 100);
  if (limit && limit?.max) schema = schema.max(limit.max / 100);
  return schema;
}

const createOrderFormValidationSchema = (features?: any): yup.SchemaOf<any> =>
  yup.object({
    amount: generateAmountValidationSchema(features?.limit),
    external_id: yup.string(),
    description: yup.string(),
    captureMethod: yup.boolean(),
    dueDate: yup.date(),
    // @ts-ignore
    extra_info: generateValidationSchema(features?.card_payment?.additional_fields?.filter((field: any) => field.type)),
  });

const renderFormat = (config: any, watch: any, onChange: any, value: any, setValue: any, error: any) => {
  switch (config.format) {
    case 'select':
      return (
        <Select
          style={{ padding: 0 }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: '0',
            },
          }}
          name={config.name}
          MenuProps={MenuProps}
          onChange={(e) => handleSelect(e, config.display_name, setValue)}
          value={value}
          className={classnames('input', { 'card__prefix--error': error })}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <em style={{ fontSize: '16px', fontStyle: 'normal', color: 'rgb(113 113 113)' }}>
                  {config.display_name}
                </em>
              );
            }

            return selected;
          }}
          displayEmpty
        >
          <MenuItem disabled value="">
            <em>{config.display_name}</em>
          </MenuItem>

          {config.items.map((item: string, index: number) => {
            return (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      );
    case 'sub_select':
      return (
        config.items[watch(`extra_info.car_model`)] && (
          <Select
            style={{ padding: 0 }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: '0',
              },
            }}
            name={config.name}
            MenuProps={MenuProps}
            onChange={(e) => handleSelect(e, config.display_name, setValue)}
            value={value}
            className={classnames('input', { 'card__prefix--error': error })}
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <em style={{ fontSize: '16px', fontStyle: 'normal', color: 'rgb(113 113 113)' }}>
                    {config.display_name}
                  </em>
                );
              }
              return selected;
            }}
            displayEmpty
          >
            <MenuItem disabled value="">
              <em>{config.display_name}</em>
            </MenuItem>

            {config.items[watch(`extra_info.car_model`)]?.map((item: string, index: number) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        )
      );
    case 'prefix':
      return (
        <div
          className={classnames('card__prefix-container', {
            'card__prefix--error': error,
          })}
        >
          {
            <div
              className={classnames('card__prefix', {
                'card__prefix--disabled': !watch(`extra_info.car_model`),
              })}
            >
              {(watch && config.prefixes[watch(`extra_info.car_model`)]) || '000_'}
            </div>
          }

          <input
            type="text"
            name={config.name}
            className={classnames('input', 'card__prefix--input')}
            value={value}
            placeholder={'000000'}
            onChange={(e) => handleChangeContractNumber(e, config.display_name, setValue)}
            inputMode={'text'}
          />
        </div>
      );
    default:
      return (
        <TextField
          onChangeText={onChange}
          value={value}
          label={config.display_name}
          keyboardType={'numeric'}
          error={Boolean(error)}
          helperText={error?.message}
        />
      );
  }
};

const handleChangeContractNumber = (event: any, display_name: any, setValue: any) => {
  const { name, value } = event.target;
  const parsedValue = value?.replace(/[^0-9]/g, '');
  const limitedValue = parsedValue.slice(0, 6);
  setValue(`extra_info.${name}`, limitedValue);
};

const handleSelect = (event: any, display_name: any, setValue: any) => {
  const { name, value } = event.target;
  setValue(`extra_info.${name}`, value);
};

const renderFormFields = (config: any, watch: any, onChange: any, setValue: any, value: any, error: any) => {
  if (config.format) return renderFormat(config, watch, onChange, value, setValue, error);
  return (
    <TextField
      onChangeText={onChange}
      value={value}
      label={config.display_name}
      error={Boolean(error)}
      helperText={error?.message}
    />
  );
};

const onExtraInfoChange = (value: any, name: any, display_name: any, setValue: any) => {
  setValue(name, value);
};

const parseFormConfigs = (control: Control, config: any, watch: any, setValue: any, key: number) => {
  if (!config.type) return null;
  return (
    <Controller
      key={key}
      control={control}
      render={({ field: { value }, fieldState: { error } }) =>
        renderFormFields(
          config,
          watch,
          (value: any) => onExtraInfoChange(value, `extra_info.${config?.name}`, config?.display_name, setValue),
          setValue,
          value,
          error,
        )
      }
      name={`extra_info.${config?.name}`}
    />
  );
};

export function CreateOrderForm(props: ICreateOrderFormProps) {
  const { selectedShop } = useSelectedShop();

  const { data: features } = useQuery(
    ['shop-features', selectedShop?.id],
    () => findShopFeaturesResolver({ shopId: selectedShop?.id as string }),
    {
      enabled: Boolean(selectedShop),
    },
  );

  const { control, handleSubmit, watch, setValue } = useForm<any>({
    defaultValues: {
      amount: features?.limit?.min ? features?.limit?.min / 100 : MIN_ORDER_AMOUNT,
      external_id: undefined,
      description: undefined,
      dueDate: undefined,
      captureMethod: false,
      extra_info: {},
    },
    resolver: yupResolver(createOrderFormValidationSchema(features) as any),
    mode: 'all',
  });

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const onSubmit: SubmitHandler<ICreateOrderFormFieldValues> = (data) => {
    // @ts-ignore
    const config = features?.card_payment?.additional_fields.find((field) => field.format === 'prefix');
    // @ts-ignore
    const prefix = config?.prefixes[data?.extra_info.car_model];
    // @ts-ignore
    if (data.extra_info.contract_number) {
      // @ts-ignore
      data.extra_info.contract_number = prefix + data.extra_info.contract_number;
    }
    props.createOrder(data);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  const handleAmountChange = (onChange: any, text: string) => {
    const parsedValue = text?.replace(/[^0-9]/g, '');
    const limitedValue = parsedValue.slice(0, 12);
    onChange(limitedValue);
  };

  return (
    <Stack paddingY={4} paddingX={8} space={4}>
      <Typography sx={{ textAlign: 'center' }} marginY={'6px'} fontWeight={500} fontSize={18}>
        Создание платежной ссылки 1
      </Typography>

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={(text) => handleAmountChange(onChange, text)}
            value={String(value)}
            label={'Сумма'}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
            keyboardType={'numeric'}
          />
        )}
        name={'amount'}
      />

      {/* Parse Custom Configuration */}

      {features?.card_payment?.additional_fields?.map((config: any, index: number) => {
        return parseFormConfigs(control as any, config, watch, setValue, index);
      })}

      {/* -------------------------- */}

      <Grid container>
        <div
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%', cursor: 'pointer' }}
          onClick={() => setIsCollapsed((isCollapsed) => !isCollapsed)}
        >
          <Grid item>
            <Item className="infoTitle">Дополнительная информация</Item>
          </Grid>
          <Grid item justifyContent={'center'}>
            <Icon name={isCollapsed ? 'chevron-up' : 'chevron-down'} />
          </Grid>
        </div>
        <Collapse in={isCollapsed} style={{ width: '100%' }}>
          <Stack space={4}>
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  onBlur={field.onBlur}
                  onChangeText={field.onChange}
                  value={field.value}
                  label={'Номер'}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              )}
              name={'external_id'}
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField onBlur={onBlur} onChangeText={onChange} value={value} label={'Описание'} />
              )}
              name={'description'}
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker minDate={new Date()} label="Ссылка будет доступна до" value={value} onChange={onChange} />
              )}
              name={'dueDate'}
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  sx={{ justifyContent: 'space-between', marginLeft: 0 }}
                  control={<Checkbox />}
                  labelPlacement={'start'}
                  onChange={onChange}
                  checked={Boolean(value)}
                  label={
                    <Typography sx={{ fontSize: '16px' }} variant={'body2'}>
                      {CAPTURE_METHOD_I18N[CaptureMethod.Manual]}
                    </Typography>
                  }
                />
              )}
              name={'captureMethod'}
            />
          </Stack>
        </Collapse>
      </Grid>

      <HelperText type={'error'} visible={props.isError}>
        {props.error}
      </HelperText>

      <Button onPress={handleSubmit(onSubmit)} loading={props.isLoading}>
        Создать
      </Button>
    </Stack>
  );
}

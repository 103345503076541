import { DateCategory } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../instances';

export interface IGetAnalyticsTimelineRequestParams {
  // Конец временного отрезка
  to_dt?: Date;
  // Начало временного отрезка
  from_dt?: Date;
  date_category?: DateCategory;
}

export interface IGetAnalyticsTimelineResponseData {
  approved_amount: number[];
  approved_count: number[];
  captured_amount: number[];
  captured_count: number[];
  timeline: string[];
}

export async function getAnalyticsTimeline(
  params: IGetAnalyticsTimelineRequestParams,
): Promise<Readonly<IGetAnalyticsTimelineResponseData>> {
  const { data } = await backendHttp.get<IGetAnalyticsTimelineResponseData>('analytics/timeline', { params });
  return data;
}

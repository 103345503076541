import { RouteProp } from '@react-navigation/core';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ParamListBase } from '@react-navigation/routers';
import { ComponentType, useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { Dialog, SimpleDialog } from '@iokanx/shared/ui/universal';

import { useDrawerType } from '../use-drawer-type/use-drawer-type';

type Position = 'Center' | 'Right';
type Type = 'Sidebar' | 'Modal';

export interface IWrapModalPageProps<INavigationProp, IRouteProp> {
  title?: string;
  type?: Type;
  fillNavigationStack: (navigation: INavigationProp, route: IRouteProp) => void;
  handleDismiss: (navigation: INavigationProp, route: IRouteProp) => void;
  position?: Position;
}

export function wrapModalPage<IProps, INavigationProp, IRouteProp extends RouteProp<ParamListBase>>(
  Component: ComponentType<IProps>,
  props: IWrapModalPageProps<INavigationProp, IRouteProp>,
): ComponentType<IProps> {
  return (componentProps) => {
    const navigation = useNavigation<INavigationProp>();
    const route = useRoute<IRouteProp>();

    const drawerType = useDrawerType();
    const isPermanent = drawerType === 'permanent';

    const position = props.position ?? 'Center';
    const type = props.type ?? 'Sidebar';

    useEffect(() => {
      if (isPermanent) {
        props.fillNavigationStack(navigation, route);
      }
    }, [isPermanent, navigation, route]);

    if (!isPermanent) {
      return <Component {...componentProps} />;
    }

    return (
      <SimpleDialog
        visible
        onDismiss={() => props.handleDismiss(navigation, route)}
        style={[
          styles[`position${position}`],
          position === 'Right' && styles.fullHeight,
          type === 'Modal' && styles.modal,
        ]}
      >
        {props.title && <Dialog.Title style={styles.title}>{props.title}</Dialog.Title>}
        <Dialog.ScrollArea style={styles.scrollArea}>
          <Component {...componentProps} />
        </Dialog.ScrollArea>
      </SimpleDialog>
    );
  };
}

const styles = StyleSheet.create({
  scrollArea: { paddingHorizontal: 0, borderTopWidth: 0, borderBottomWidth: 0 },
  title: { marginHorizontal: 16, marginTop: 24, marginBottom: 24, color: 'rgba(0, 0, 0, 0.6)' },
  positionCenter: { alignSelf: 'center' },
  positionRight: { alignSelf: 'flex-end', marginRight: 0 },
  fullHeight: { minHeight: '100vh', width: '33%', minWidth: '380px' },
  modal: { minHeight: '40vh', width: '30%', minWidth: '360px' },
});

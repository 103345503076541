import { PsmStatus } from '@iokanx/shared/data-access/constants';

import { patchPsmOrderById } from '../../../resources';

interface IPaymentPsmParams {
  order_id?: string | null;
  reference?: string | null;
}
export interface IUpdatePsmEventByIdParams {
  command_id: number;
  name: PsmStatus;
  payment?: IPaymentPsmParams;
}

export function updatePsmEventById(params: IUpdatePsmEventByIdParams) {
  return patchPsmOrderById({ ...params });
}

import { transformConfig } from './find-config-by-shop-id.resolver';

import { IGetConfigByShopsIdResponseData, updateConfigByShopId } from '../../resources';

export interface IUpdateConfigByShopIdResult extends IGetConfigByShopsIdResponseData {
  // Нормализованное имя магазина
  displayName?: string;
  createdAt?: string;
}

export async function updateConfigByShopIdResolver(
  config: IGetConfigByShopsIdResponseData,
): Promise<IUpdateConfigByShopIdResult> {
  const result = await updateConfigByShopId(config.shop_id, config);
  return transformConfig(result);
}

export const DEFAULT_CARD_PLACEHOLDER = {
  pan: '1234 1234 1234 1234',
  expDate: '01/23',
  cvv: '123',
  email: 'example@domain.com',
};

export const AVIATA_CARD_PLACEHOLDER = {
  pan: '0000 0000 0000 0000',
  expDate: 'ММ/ГГ',
  cvv: '000',
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { Turnover } from './Turnover';

import { useFormEdit } from '../../helpers/useFormEdit';
import { useProfileFormContext } from '../../providers/ProfileFormProvider';
import { FormField } from '../FormField/FormField';
import { ProfileFormSection } from '../layouts/ProfileFormSection';
import { ProfileFormStep } from '../layouts/ProfileFormStep';
import { IFormSection } from '../steps/OrganizationInfoStep/types';

const periods = [
  {
    period: 3,
    amount: 'Предполагаемая сумма оборота в первый месяц',
    count: 'Предполагаемая сумма оборота в первый месяц',
  },
  {
    period: 6,
    amount: 'Предполагаемая сумма оборота в первые 6 месяцев',
    count: 'Предполагаемое количество операций в первые 6 месяцев',
  },
  {
    period: 12,
    amount: 'Предполагаемая сумма оборота в год',
    count: 'Предполагаемое количество операций в год',
  },
];

const filterTurnoverByPeriod = (turnovers: any[], period: number) => {
  return turnovers?.find((turnover) => turnover?.period === period);
};

const parseCountries = (citiesString: string) => {
  return citiesString.split(',');
};

export interface ITurnoverRequest {
  period: number;
  amount: number;
  count: number;
  avg_amount: number;
  countries: string;
  organizationId: string;
  turnoverId?: string;
}

export const turnoverQueryBuilder = (organizationId: string, id?: string) => {
  const baseURL = `/organizations/${organizationId}/turnovers`;
  return id ? baseURL + `/${id}` : baseURL;
};

const postData = async ({ amount, count, avg_amount, countries, organizationId }: ITurnoverRequest) => {
  const { data } = await backendHttp.post<ITurnoverRequest>(turnoverQueryBuilder(organizationId), {
    period: 1,
    avg_amount: Number(avg_amount),
    amount: Number(amount),
    count: Number(count),
    countries: parseCountries(countries),
  });

  return data;
};

const patchData = async ({ amount, count, avg_amount, countries, organizationId, turnoverId }: ITurnoverRequest) => {
  const { data } = await backendHttp.patch<ITurnoverRequest>(turnoverQueryBuilder(organizationId, turnoverId), {
    period: 1,
    avg_amount: Number(avg_amount),
    amount: Number(amount),
    count: Number(count),
    countries: parseCountries(countries),
  });

  return data;
};

export const TurnoversStep: FC<IFormSection> = (props: any) => {
  const queryClient = useQueryClient();
  const { organizationId, isSubmitted } = useProfileFormContext();
  const [turnoversArray, setTurnoversArray] = useState<any[]>([]);

  const { data: turnovers } = useQuery(['turnovers'], async () => {
    try {
      const { data } = await backendHttp.get(turnoverQueryBuilder(organizationId));

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  });

  const { mutate: submitAnswer } = useMutation(
    filterTurnoverByPeriod(turnovers, 1)?.id
      ? (formData: any) =>
          patchData({
            ...formData,
            organizationId,
            turnoverId: filterTurnoverByPeriod(turnovers, 1)?.id,
          })
      : (formData: any) => postData({ ...formData, organizationId }),
    {
      onSuccess: async () => {
        handleIsEdit();
        await queryClient.invalidateQueries(['turnovers']);
      },
      onError: (error) => console.error(error),
    },
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      avg_amount: '',
      countries: [],
      count: '',
      amount: '',
    },
  });

  const onSubmit = async (data: any) => {
    turnoversArray.forEach((fn) => fn());
    submitAnswer(data);
  };
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(
    Boolean(filterTurnoverByPeriod(turnovers, 1)?.id),
    handleSubmit(onSubmit, onError),
  );

  const handleButtonPress = () => {
    return handlePress();
  };

  const handleSubmitSections = (cb: any) => {
    setTurnoversArray((turnoversArray) => [...turnoversArray, cb]);
  };

  useEffect(() => {
    const turnoverData = filterTurnoverByPeriod(turnovers, 1);

    if (turnoverData) {
      const mappedTurnovers = {
        amount: turnoverData?.amount ?? '',
        count: turnoverData?.count ?? '',
        avg_amount: turnoverData?.avg_amount ?? '',
        countries: turnoverData?.countries.join(', ') || '',
      };
      reset({ ...mappedTurnovers });
    }
  }, [turnovers]);

  return (
    <ProfileFormStep {...props}>
      <ProfileFormSection
        title={'Обороты'}
        isSaved={isDisable}
        isSubmitted={isSubmitted}
        handleSubmit={handleButtonPress}
      >
        <FormField name={'avg_amount'} control={control} label={'Средний чек'} disabled={isDisable} />

        <FormField
          name={'countries'}
          control={control}
          label="Предполагаемые страны / география держателей карт (покупателей услуг/товаров Мерчанта)"
          disabled={isDisable}
        />

        <FormField
          name={'amount'}
          control={control}
          label={'Предполагаемая сумма оборота в месяц'}
          disabled={isDisable}
        />

        <FormField
          name={'count'}
          control={control}
          label={'Предполагаемое количество операций в месяц'}
          disabled={isDisable}
        />

        {periods.map((period, index) => (
          <Turnover
            handleIsEdit={handleIsEdit}
            disabled={isDisable}
            handleSubmitSections={handleSubmitSections}
            isSubmitted={isSubmitted}
            organizationId={organizationId}
            key={index}
            period={period}
            turnoverData={filterTurnoverByPeriod(turnovers, period.period)}
          />
        ))}
      </ProfileFormSection>
    </ProfileFormStep>
  );
};

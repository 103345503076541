import { Currency } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../../instances';

export interface IGetErrorIncomeResponseData {
  code?: string;
  message?: string;
}

export interface IGetIncomeByIdResponseData {
  id: string;
  external_id: string;
  amount: number;
  currency: Currency;
  type: string;
  account: string;
  bank: string;
  idn: string;
  name: string;
  knp: string;
  narrative: string;
  approved_at: string;
  created_at: string;
  error: IGetErrorIncomeResponseData;
}

/** Получение опреации по транзитному счету  */
export async function getIncomeById(incomeId: string): Promise<IGetIncomeByIdResponseData> {
  const { data } = await backendHttp.get<IGetIncomeByIdResponseData>(`/reports/statements/${incomeId}`);
  return data;
}

export enum OrderState {
  ERROR = 'ERROR',
  INIT = 'INIT',
  LOADING = 'LOADING',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_PAID = 'ORDER_PAID',
  ORDER_ON_HOLD = 'ORDER_ON_HOLD',
  ORDER_UNPAID = 'ORDER_UNPAID',
  PAYMENT_CREATED = 'PAYMENT_CREATED',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
  PAYMENT_ACTION_REQUIRED = 'PAYMENT_ACTION_REQUIRED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  REFUND_CREATED = 'REFUND_CREATED',
  REFUND_APPROVED = 'REFUND_APPROVED',
  REFUND_DECLINED = 'REFUND_DECLINED',
  TIMEOUT = 'TIMEOUT',
  NOT_FOUND = 'NOT_FOUND',
}

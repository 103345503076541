import { isEmpty } from 'lodash';

export const parseOrganizationsType = (data: any[]) => {
  if (isEmpty(data)) {
    return [];
  }

  return data?.map((dataItem) => ({
    value: dataItem.id,
    title: dataItem.name,
  }));
};

export const copySubFormValues = (getValues: any, setValue: any) => {
  const firstSubFormValues = getValues('legal');
  setValue('trade', firstSubFormValues);
};

export const getDefaultValues = () => ({
  city: '',
  country: '',
  region: '',
  street: '',
  home: '',
  flat: '',
});

export const filterContact = (contacts: any[], type: string) => {
  const filteredContact = contacts?.find((contact) => contact?.type === type);

  if (filteredContact) {
    const { value, id } = filteredContact;

    return { value, id };
  }

  return;
};

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { DashboardProfilesTabsParamList } from '@iokanx/dashboard/feature';

import { ProfilePage } from '../profile-page/profile-page';

const MaterialTopTabNavigator = createMaterialTopTabNavigator<DashboardProfilesTabsParamList>();

export default function ProfilesPage() {
  return (
    <MaterialTopTabNavigator.Navigator screenOptions={{ lazy: true, swipeEnabled: false }}>
      <MaterialTopTabNavigator.Screen name="Profile" component={ProfilePage} options={{ title: 'Профиль' }} />
    </MaterialTopTabNavigator.Navigator>
  );
}

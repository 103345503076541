import { StyleSheet } from 'react-native';

import { IFindShopUsersResolverResultItem } from '@iokanx/shared/data-access/api';
import { Card, SimpleCard } from '@iokanx/shared/ui/universal';

import { DeleteEmployee } from '../delete-employee/delete-employee';
import { EmployeeUpdateRole } from '../employee-update-role/employee-update-role';

export interface IEmployeeCardProps {
  employee: IFindShopUsersResolverResultItem;
}

export function EmployeeCard(props: IEmployeeCardProps) {
  return (
    <SimpleCard title={props.employee.fullName} subtitle={props.employee.email}>
      <Card.Actions style={styles.actions}>
        <EmployeeUpdateRole employee={props.employee} />
        <DeleteEmployee employee={props.employee} />
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  actions: {
    justifyContent: 'space-between',
  },
});

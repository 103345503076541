import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const AviataTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow classes={{ popper: className }} placement={'top-end'} {...props} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#202122',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#202122',
    borderRadius: 4,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '160%',
    padding: 12,
  },
}));

export enum ShopUserRole {
  God = 'GOD',
  Admin = 'admin',
  Owner = 'owner',
  Support = 'support',
  Manager = 'manager',
  Viewer = 'viewer',
}

export const SHOP_USER_ROLE_I18N: Record<ShopUserRole, string> = {
  [ShopUserRole.God]: 'God',
  [ShopUserRole.Admin]: 'Администратор',
  [ShopUserRole.Owner]: 'Владелец',
  [ShopUserRole.Support]: 'Поддержка',
  [ShopUserRole.Manager]: 'Менеджер',
  [ShopUserRole.Viewer]: 'Просмотр',
};

export const AVAILABLE_SHOP_USER_ROLES = [
  ShopUserRole.Admin,
  ShopUserRole.Support,
  ShopUserRole.Manager,
  ShopUserRole.Viewer,
] as const;

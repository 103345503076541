import { ITextFieldProps, TextField } from '../text-field/text-field';

export function EmailField(props: ITextFieldProps) {
  return (
    <TextField
      autoComplete={'email'}
      keyboardType={'email-address'}
      textContentType={'emailAddress'}
      autoCapitalize={'none'}
      {...props}
    />
  );
}

import { Stack, Typography } from '@mui/material';
import MuiSlider from '@mui/material/Slider';
import { isFunction } from 'lodash';

import { getSliderValue, ISliderProps } from './utils';

export function Slider(props: ISliderProps) {
  const { min, max, step } = getSliderValue(props);

  function isShowMarks(): boolean {
    if (min && max && step) {
      return (max - min) / step <= 10;
    }

    return false;
  }

  return (
    <>
      <MuiSlider min={min} max={max} step={step} marks={isShowMarks()} />
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'body2'}>
          {isFunction(props.rangeLabelFormat) ? props.rangeLabelFormat(min) : min}
        </Typography>
        <Typography variant={'body2'}>
          {isFunction(props.rangeLabelFormat) ? props.rangeLabelFormat(max) : max}
        </Typography>
      </Stack>
    </>
  );
}

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  IFindPaymentByIdResult,
  IRefundPaymentResolverParams,
  refundPaymentResolver,
} from '@iokanx/shared/data-access/api';
import { useSnackbar } from '@iokanx/shared/feature';
import { Card, IconButton, TextField, SimpleCard, Button } from '@iokanx/shared/ui/universal';

export interface IRefundPaymentProps {
  payment: IFindPaymentByIdResult;
}

type IRefundPaymentFormFieldValues = Omit<IRefundPaymentResolverParams, 'orderId'>;

export function RefundPayment(props: IRefundPaymentProps) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const [isVisibleRefundPaymentForm, setVisibleRefundPaymentForm] = useState(false);
  function handleShowRefundPaymentForm() {
    setVisibleRefundPaymentForm(true);
  }
  function handleHideRefundPaymentForm() {
    setVisibleRefundPaymentForm(false);
  }

  const validationSchema = yup.object({
    amount: yup.number().required().min(1).max(props.payment.capturedAmount),
    reason: yup.string(),
  });
  const { control, handleSubmit } = useForm<IRefundPaymentFormFieldValues>({
    defaultValues: {
      amount: 0,
      reason: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutate: refundPayment, isLoading: isRefunding } = useMutation(refundPaymentResolver, {
    onSuccess: () => {
      snackbar.show('Возврат оформлен', { type: 'success' });
      handleHideRefundPaymentForm();
      return queryClient.invalidateQueries(['payment']);
    },
  });

  const onSubmit: SubmitHandler<IRefundPaymentFormFieldValues> = (data) => {
    refundPayment({ orderId: props.payment.order_id, ...data });
  };

  if (isVisibleRefundPaymentForm) {
    return (
      <SimpleCard
        title={'Оформить возврат'}
        right={(props) => <IconButton {...props} icon="close" onPress={handleHideRefundPaymentForm} />}
      >
        <Card.Content>
          <Stack space={2}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState }) => (
                <TextField
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={String(value)}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  label={'Сумма'}
                />
              )}
              name={'amount'}
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  label={'Причина'}
                  helperText={'Необязательно'}
                />
              )}
              name={'reason'}
            />
          </Stack>
        </Card.Content>
        <Card.Actions>
          <Button onPress={handleSubmit(onSubmit)} loading={isRefunding} fullWidth>
            Вернуть
          </Button>
        </Card.Actions>
      </SimpleCard>
    );
  }

  return (
    <Button onPress={handleShowRefundPaymentForm} mode={'outlined'} icon={{ name: 'cached' }} color={'error'}>
      Оформить возврат
    </Button>
  );
}

import { Box, Stack } from '@mobily/stacks';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { last } from 'lodash';
import { StyleSheet, FlatList, ListRenderItem } from 'react-native';

import { ActivityIndicator, Caption, Button } from '@iokanx/shared/ui/universal';

export interface IShopFlatListProps<IItem> {
  renderItem: ListRenderItem<IItem>;
  query: UseInfiniteQueryResult<{ data: IItem[]; count: number }>;
  hasNotNextPageText: string;
  filter: JSX.Element;
  emptyText: string;
}

export function ShopFlatList<IItem>(props: IShopFlatListProps<IItem>) {
  const data = props.query.data?.pages?.flatMap((page) => page.data) || [];
  const count = last(props.query.data?.pages)?.count ?? 0;

  function ListFooterComponent() {
    if (data.length <= 0) return null;

    return (
      <Box marginY={2}>
        {props.query.hasNextPage ? (
          <Button
            mode={'outlined'}
            onPress={props.query.fetchNextPage}
            disabled={!props.query.hasNextPage}
            loading={props.query.isFetchingNextPage}
          >
            Загрузить еще
          </Button>
        ) : (
          <Box alignX={'center'}>
            <Caption>{props.hasNotNextPageText}</Caption>
          </Box>
        )}
      </Box>
    );
  }

  const ListHeaderComponent = (
    <Stack>
      {props.filter}
      <Stack horizontal padding={2} style={{ justifyContent: 'space-between' }}>
        <ActivityIndicator animating={props.query.isFetching && !props.query.isFetchingNextPage} />
        {data.length > 0 && <Caption>{`${data.length} из ${count}`}</Caption>}
      </Stack>
    </Stack>
  );

  function ListEmptyComponent() {
    return (
      <Box alignX={'center'}>
        <Caption>{props.emptyText}</Caption>
      </Box>
    );
  }

  return (
    <FlatList
      data={data}
      renderItem={props.renderItem}
      ItemSeparatorComponent={ItemSeparatorComponent}
      ListFooterComponent={ListFooterComponent}
      ListHeaderComponent={ListHeaderComponent}
      ListEmptyComponent={ListEmptyComponent}
      style={styles.root}
    />
  );
}

function ItemSeparatorComponent() {
  return <Box padding={1} />;
}

const styles = StyleSheet.create({
  root: {
    padding: 8,
  },
});

import { MenuItem, TextField } from '@mui/material';
import { ChangeEventHandler, CSSProperties, ReactNode, useState } from 'react';

import { IInputProps } from '../input/input';

export interface ISelectItem<IValue> {
  title: string;
  value: IValue;
}

export interface ISelectAnchorProps {
  handleOpenMenu: () => void;
}

export interface ISelectProps<IValue> extends Omit<IInputProps, 'onChange' | 'value'> {
  items: ISelectItem<IValue>[];
  onChange: (value: IValue) => void;
  value: IValue;
  anchor?: (anchorProps: ISelectAnchorProps) => ReactNode;
  style?: CSSProperties;
}

export function Select<IValue extends string | number>({
  items,
  onChange,
  value,
  anchor,
  ...inputProps
}: ISelectProps<IValue>) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value as unknown as IValue);
  };

  const [open, setOpen] = useState(false);

  function handleOpenMenu() {
    setOpen(true);
  }

  if (anchor) {
    anchor({ handleOpenMenu });
  }

  return (
    <TextField
      style={inputProps.style}
      select
      fullWidth={inputProps.fullWidth || false}
      label={inputProps.label}
      value={value}
      onChange={handleChange}
      helperText={inputProps.helperText}
      error={Boolean(inputProps.error)}
      SelectProps={{ open, onClose: () => setOpen(false), onOpen: handleOpenMenu }}
      disabled={inputProps.disabled}
    >
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value} disabled={item.value === value}>
          {item.title}
        </MenuItem>
      ))}
    </TextField>
  );
}

import { Box, Stack } from '@mobily/stacks';
import { StyleSheet } from 'react-native';

import { IFindPayoutResolverResultItem } from '@iokanx/shared/data-access/api';
import { Caption, Card, Paragraph, SimpleCard, SimpleCardProps } from '@iokanx/shared/ui/universal';

import { ClipboardButton } from '../clipboard-button/clipboard-button';
import { PayoutStatusChip } from '../payout-status-chip/payout-status-chip';

export interface IPayoutCardProps {
  payout: IFindPayoutResolverResultItem;
  onCardPress: SimpleCardProps['onPress'];
}

interface IContentItem {
  title: string;
  key: keyof Pick<IFindPayoutResolverResultItem, 'recipientName' | 'recipientIban' | 'external_id' | 'createdAt'>;
}

const contentItems: IContentItem[] = [
  {
    title: 'Номер выплаты',
    key: 'external_id',
  },
  {
    title: 'Кому',
    key: 'recipientName',
  },
  {
    title: 'Номер счета',
    key: 'recipientIban',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
  },
];

export function PayoutCard(props: IPayoutCardProps) {
  return (
    <SimpleCard
      title={props.payout.amountWithSymbol}
      right={() => (
        <Box marginRight={4}>
          <PayoutStatusChip status={props.payout.status} />
        </Box>
      )}
      onPress={props.onCardPress}
    >
      <Card.Content>
        {contentItems.map((item) => (
          <Stack key={item.key} horizontal space={2} style={styles.contentItem}>
            <Caption>{item.title}</Caption>
            <Paragraph>{props.payout[item.key]}</Paragraph>
          </Stack>
        ))}
      </Card.Content>
      <Card.Actions>
        <ClipboardButton copiedText={''}>{props.payout.id}</ClipboardButton>
      </Card.Actions>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  contentItem: {
    justifyContent: 'space-between',
  },
});

import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Item } from '@iokanx/dashboard/feature';
import { backendHttp } from '@iokanx/shared/data-access/api';

import { BENEFICIARY_TOOLTIP } from './constants';
import { MainBeneficiary } from './MainBeneficiary';

import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import { ConditionalComponent } from '../../ConditionalComponent';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { ProfileFormStep } from '../../layouts/ProfileFormStep';
import { filterShares } from '../HolderStep/helpers';

export const MainBeneficiariesStep = (props: any) => {
  const { organizationId, isSubmitted } = useProfileFormContext();
  const [beneficiaries, setBeneficiaries] = useState<any[]>([]);

  const { data: shares } = useQuery(['shares', 'PHYSICAL'], async () => {
    try {
      const { data } = await backendHttp.get(`organizations/${organizationId}/shares?type=PHYSICAL`);

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  });

  useEffect(() => {
    const filteredShares = filterShares(shares);

    setBeneficiaries(filteredShares);
  }, [shares]);

  return (
    <ProfileFormStep {...props}>
      <ProfileFormSection
        isSaved={!isEmpty(beneficiaries)}
        title={'Данные о бенефициарном собственнике'}
        tooltip={{ text: BENEFICIARY_TOOLTIP }}
        {...props}
      >
        <ConditionalComponent
          condition={isEmpty(beneficiaries)}
          component={
            <Item>
              <Typography style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px', fontWeight: 400 }}>
                Список бенефициаров пуст
              </Typography>
            </Item>
          }
        />

        {beneficiaries.map((beneficiary, index: number) => {
          return (
            <MainBeneficiary
              isSubmitted={isSubmitted}
              organizationId={organizationId}
              beneficiary={beneficiary}
              key={index}
            />
          );
        })}
      </ProfileFormSection>
    </ProfileFormStep>
  );
};

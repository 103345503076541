import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { useDashboardNavigation } from '@iokanx/dashboard/feature';
import { findConfigByShopIdResolver, IFindShopIdResult } from '@iokanx/shared/data-access/api';
import { CONFIG_SCHEMA_TITLE, ConfigSchema } from '@iokanx/shared/data-access/constants';
import { useSnackbar } from '@iokanx/shared/feature';
import { Button, List } from '@iokanx/shared/ui/universal';

export interface IShopConfigProps {
  shop: IFindShopIdResult | undefined;
  is_superuser?: boolean;
}

export default function ShopConfig(props: IShopConfigProps) {
  const snackbar = useSnackbar();
  const navigation = useDashboardNavigation();
  const { shop, is_superuser } = props;
  const { data } = useQuery(['config', shop.id], () => findConfigByShopIdResolver(shop.id));

  function handleNavigateToConfig(shopId: string, schema: ConfigSchema) {
    navigation.navigate('Config', { shopId: shopId, schema: schema });
  }

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  return (
    <Grid container>
      <Grid item xs={12}>
        <List.Section title={'Основные'}>
          <Grid container>
            <Grid item xs={6}>
              <Item>Идентификатор</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{data?.shop_id}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>Название</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{data?.shop_name}</Item>
            </Grid>
          </Grid>
        </List.Section>

        <List.Section title={'Адаптеры'}>
          <Grid container>
            {[ConfigSchema.UKassa, ConfigSchema.WebKassa, ConfigSchema.MasterPass].map((item) => {
              return (
                <Grid item xs={6} key={item}>
                  <Item>
                    <Button
                      icon={{ name: 'receipt' }}
                      onPress={() => handleNavigateToConfig(shop.id, item)}
                      fullWidth={true}
                    >
                      {CONFIG_SCHEMA_TITLE[item]}
                    </Button>
                  </Item>
                </Grid>
              );
            })}
          </Grid>
        </List.Section>

        <List.Section title={'Эквайеры'}>
          <Grid container>
            {[ConfigSchema.Halyk, ConfigSchema.Bcc, ConfigSchema.Jusan, ConfigSchema.Bereke].map((item) => {
              return (
                <Grid item xs={6} key={item}>
                  <Item>
                    <Button
                      icon={{ name: 'receipt' }}
                      onPress={() => handleNavigateToConfig(shop.id, item)}
                      fullWidth={true}
                    >
                      {CONFIG_SCHEMA_TITLE[item]}
                    </Button>
                  </Item>
                </Grid>
              );
            })}
          </Grid>
        </List.Section>

        <List.Section title={'Методы оплат'}>
          <Grid container>
            {[ConfigSchema.GooglePay, ConfigSchema.ApplePay].map((item) => {
              return (
                <Grid item xs={6} key={item}>
                  <Item>
                    <Button
                      icon={{ name: 'receipt' }}
                      onPress={() => handleNavigateToConfig(shop.id, item)}
                      fullWidth={true}
                    >
                      {CONFIG_SCHEMA_TITLE[item]}
                    </Button>
                  </Item>
                </Grid>
              );
            })}
          </Grid>
        </List.Section>
      </Grid>
    </Grid>
  );
}

import { useInfiniteQuery } from '@tanstack/react-query';
import { ListRenderItem } from 'react-native';

import { FulfilmentCard } from '@iokanx/dashboard/ui';
import { searchFulfilmentsResolver, IGetFulfilmentByIdResponseData } from '@iokanx/shared/data-access/api';
import { createGetNextPageParam } from '@iokanx/shared/util';

import { IFulfilmentListProps } from '../fulfilment-list/fulfilment-list';
import { OperationFlatList } from '../operation-flat-list/operation-flat-list';

export function FulfilmentListMobile(props: IFulfilmentListProps) {
  const query = useInfiniteQuery(
    ['fulfilment', props.form.watch()],
    (context) => searchFulfilmentsResolver({ ...props.form.watch(), page: context.pageParam, limit: props.pageLimit }),
    {
      getNextPageParam: createGetNextPageParam({ pageLimit: props.pageLimit }),
    },
  );

  const renderItem: ListRenderItem<IGetFulfilmentByIdResponseData> = (info) => {
    return <FulfilmentCard fulfilment={info.item} onCardPress={() => props.onFulfilmentPress(info.item)} />;
  };

  return (
    <OperationFlatList
      renderItem={renderItem}
      query={query}
      hasNotNextPageText={'Отчетов больше нет'}
      emptyText={
        props.isToday
          ? 'Отчет за текущий день еще не сформирован. Для получения предварительного отчета, выберите в календаре текущий день и нажмите на кнопку "Выгрузить"'
          : 'По указанным параметрам, отчетов не найдено'
      }
      filter={props.filter}
    />
  );
}

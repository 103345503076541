import { backendHttp } from '../../instances';

export type IGetExportPayoutsResponseData = Blob;

/** Выгрузка списка оплат ввошедших в выпоату */
export async function getExportPayouts(payoutId: string): Promise<IGetExportPayoutsResponseData> {
  const { data } = await backendHttp.get<IGetExportPayoutsResponseData>(`/reports/payouts/${payoutId}/export`, {
    responseType: 'blob',
  });
  return data;
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FileUploadField } from './FileUploadField';
import { parseFiles, parseFileTypes } from './helpers';

import { useProfileFormContext } from '../../../providers/ProfileFormProvider';
import {
  deleteOrganizationFileResolver,
  // downloadOrganizationFileResolver,
  getOrganizationFilesResolver,
  getOrganizationFileTypesResolver,
  uploadOrganizationFileResolver,
} from '../../../shared/data-access/files';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { ProfileFormStep } from '../../layouts/ProfileFormStep';

const ORGANIZATION_TYPE_ID = 7;

const getFileId = (files: any[], fileType: any) => {
  const file = files?.find((file) => file.file_type_id === fileType.id);

  return file?.id;
};

export const FileUploadStep = (props: any) => {
  const queryClient = useQueryClient();
  const { organizationId } = useProfileFormContext();

  // const [fileId, setFileId] = useState<string>();

  const { data: fileTypes } = useQuery(
    ['files', 'types'],
    async () => await getOrganizationFileTypesResolver(ORGANIZATION_TYPE_ID),
  );

  const { data: files } = useQuery(['files'], async () => await getOrganizationFilesResolver(organizationId));

  // const { data: downloadedFile } = useQuery(['files', 'download', fileId], async () => {
  //   if (fileId) {
  //     return await downloadOrganizationFileResolver(organizationId, fileId);
  //   }
  //
  //   return '';
  // });

  const { mutate: uploadFile } = useMutation(
    async (data: any) => uploadOrganizationFileResolver(organizationId, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['files']);
      },
    },
  );

  const { mutate: deleteFile } = useMutation(
    async (fileId: string) => deleteOrganizationFileResolver(organizationId, fileId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['files']);
      },
    },
  );

  const { control, reset } = useForm({
    defaultValues: parseFileTypes(fileTypes, 'id'),
  });

  useEffect(() => {
    reset(parseFiles(files, parseFileTypes(fileTypes, 'id'), 'file_type_id'));
  }, [files]);

  return (
    <ProfileFormStep {...props}>
      <ProfileFormSection title={props?.title} handleSubmit={null} isSaved={!isEmpty(files)}>
        {fileTypes?.map((fileUploadField: any, index: number) => {
          return (
            <FileUploadField
              key={index}
              id={getFileId(files, fileUploadField)}
              // downloadFile={setFileId}
              deleteFile={deleteFile}
              fileTypeId={fileUploadField.id}
              uploadFile={uploadFile}
              control={control}
              title={fileUploadField.title}
            />
          );
        })}
      </ProfileFormSection>
    </ProfileFormStep>
  );
};

import { backendHttp } from '../../instances';

export type IGetExportRevisesRequestParams = {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
};

export type IGetExportRevisesResponseData = Blob;

export async function getExportRevises(params: IGetExportRevisesRequestParams): Promise<IGetExportRevisesResponseData> {
  const { data } = await backendHttp.get<IGetExportRevisesResponseData>('/reports/revises/export', {
    params,
    responseType: 'blob',
  });
  return data;
}

import { ComponentProps } from 'react';
import { HelperText as PaperHelperText } from 'react-native-paper';

export type IHelperTextProps = ComponentProps<typeof PaperHelperText>;

export function HelperText(props: IHelperTextProps) {
  const isVisible = props.visible === undefined ? true : props.visible;

  return isVisible ? <PaperHelperText {...props} /> : null;
}

import { IFindDashboardPaymentsResolverParams } from '@iokanx/shared/data-access/api';

import { SearchOrdersParam } from '../order-list/order-list';

export enum SearchUsersParam {
  name = 'name',
  email = 'email',
}

export interface IUserListFormFieldValues extends Pick<IFindDashboardPaymentsResolverParams, SearchOrdersParam> {
  page: NonNullable<IFindDashboardPaymentsResolverParams['page']>;
  from_dt: IFindDashboardPaymentsResolverParams['from_dt'];
  to_dt: IFindDashboardPaymentsResolverParams['to_dt'];
}

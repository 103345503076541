import { transformApiKey } from './find-api-keys-by-shop-id.resolver';

import { createApiKeyByShopId, IGetApiKeyByShopsIdResponseItem } from '../../resources';

export interface ICreateApiKeyByShopIdResult extends IGetApiKeyByShopsIdResponseItem {
  createdAt?: string;
}

export async function createApiKeyByShopIdResolver(shopId: string): Promise<ICreateApiKeyByShopIdResult> {
  const result = await createApiKeyByShopId({ shop_id: shopId, expire: 10 });
  return transformApiKey(result);
}

interface IConditionalComponent {
  condition: boolean;
  component: JSX.Element;
}

export const ConditionalComponent = ({ condition, component }: IConditionalComponent) => {
  if (condition) {
    return component;
  }

  return null;
};

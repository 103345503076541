import { Tiles } from '@mobily/stacks';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { ComponentType, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import {
  getBalanceResolver,
  IFindIncomesRequestResolverParams,
  IGetIncomeByIdResponseData,
} from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { DateRangePicker, ISelectItem, Select, TextField } from '@iokanx/shared/ui/universal';
import { useSessionStorageDate } from '@iokanx/shared/util';

import { Amplitude } from '../amplitude/amplitude';
import { IncomeListDesktop } from '../income-list-desktop/income-list-desktop';
import { IncomeListMobile } from '../income-list-mobile/income-list-mobile';
import { useDashboardNavigation } from '../navigation';

export enum SearchIncomesParam {
  incomeId = 'operation_id',
  amount = 'amount',
  externalId = 'external_id',
  operationType = 'operation_type',
}

export interface IIncomeListFormFieldValues extends Pick<IFindIncomesRequestResolverParams, SearchIncomesParam> {
  page: NonNullable<IFindIncomesRequestResolverParams['page']>;
  from_dt: IFindIncomesRequestResolverParams['from_dt'];
  to_dt: IFindIncomesRequestResolverParams['to_dt'];
}

const searchIncomesParamI18n: Record<SearchIncomesParam, string> = {
  [SearchIncomesParam.incomeId]: 'Идентификатор операции',
  [SearchIncomesParam.amount]: 'Сумма',
  [SearchIncomesParam.externalId]: 'Вн. идентификатор операции',
  [SearchIncomesParam.operationType]: 'Тип операции',
};
const searchIncomesParamItems: ISelectItem<SearchIncomesParam>[] = Object.values(SearchIncomesParam).map((value) => ({
  title: searchIncomesParamI18n[value],
  value,
}));

const INCOME_LIST_LIMIT = 10;

export interface IIncomeListProps {
  onIncomePress: (income: IGetIncomeByIdResponseData) => void;
  form: UseFormReturn<IIncomeListFormFieldValues>;
  pageLimit: number;
  filter: JSX.Element;
}

const RESPONSIVE_INCOME_LIST: Record<Breakpoint, ComponentType<IIncomeListProps>> = {
  [Breakpoint.Desktop]: IncomeListDesktop,
  [Breakpoint.Mobile]: IncomeListMobile,
};

export function IncomeList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const { startDate, endDate } = useSessionStorageDate();

  const form = useForm<IIncomeListFormFieldValues>({
    defaultValues: {
      page: 1,
      from_dt: startDate || moment().subtract(1, 'month').startOf('day').toDate(),
      to_dt: endDate || moment().endOf('day').toDate(),
    },
  });

  const { data: balance } = useQuery(['balance', ''], () => getBalanceResolver());

  const [searchIncomeParam, setSearchIncomeParam] = useState(SearchIncomesParam.incomeId);

  function handleChangeSearchIncomeParam(nextSearchIncomeParam: SearchIncomesParam) {
    form.reset();
    setSearchIncomeParam(nextSearchIncomeParam);
  }

  const navigation = useDashboardNavigation();

  function handleNavigateToIncome(income: IGetIncomeByIdResponseData) {
    navigation.navigate('Income', { incomeId: income.id });
    Amplitude.logEventWithPropertiesAsync('income_click', {});
  }

  const filter = (
    <Tiles columns={[1, 4]} space={2}>
      <Select
        onChange={handleChangeSearchIncomeParam}
        value={searchIncomeParam}
        label={'Поиск по'}
        items={searchIncomesParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label={searchIncomesParamI18n[searchIncomeParam]}
          />
        )}
        name={searchIncomeParam}
      />
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          form.setValue('from_dt', values.startDate);
          form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
      <Tiles columns={[1, 2]} space={2}>
        <TextField value={balance && balance.balanceWithSymbol} label={'Баланс'} />
        <TextField value={balance && balance.processedWithSymbol} label={'В процессе'} />
      </Tiles>
    </Tiles>
  );

  const ResponsiveIncomeList = RESPONSIVE_INCOME_LIST[currentBreakpoint];

  return (
    <ResponsiveIncomeList
      filter={filter}
      onIncomePress={handleNavigateToIncome}
      form={form}
      pageLimit={INCOME_LIST_LIMIT}
    />
  );
}

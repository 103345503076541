import { authHttp } from '../../instances/auth-http';

export interface IPostAuthRefreshTokenRequestData {
  // Токен для регенерации нового токена доступа
  refresh_token: string;
}

export interface IPostAuthRefreshTokenResponseData {
  readonly type: string;
  // Токен доступа
  readonly access_token: string;
  // Токен для генерации нового токена доступа
  readonly refresh_token: string;
  // API ключ
  readonly api_key?: string;
  // Публичный ключ
  readonly public_key?: string;
  // Роль пользователя в этом магазине
  readonly role?: string;
}

export async function postAuthRefreshToken(
  requestData: IPostAuthRefreshTokenRequestData,
): Promise<IPostAuthRefreshTokenResponseData> {
  const { data } = await authHttp.post<IPostAuthRefreshTokenResponseData>('/auth/refresh-token', requestData);
  return data;
}

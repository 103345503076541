import { ORDER_STATUS_I18N, OrderStatus } from '@iokanx/shared/data-access/constants';

import { StatusChip, StatusChipPropsIconName, StatusChipVariant } from '../status-chip/status-chip';

export interface IOrderStatusChipProps {
  orderStatus: OrderStatus;
}

const statusChipVariant: Record<OrderStatus, StatusChipVariant> = {
  [OrderStatus.Expired]: StatusChipVariant.Failure,
  [OrderStatus.Unpaid]: StatusChipVariant.Loading,
  [OrderStatus.OnHold]: StatusChipVariant.Success,
  [OrderStatus.Paid]: StatusChipVariant.Success,
};

const statusChipIconName: Record<OrderStatus, StatusChipPropsIconName> = {
  [OrderStatus.Expired]: 'clock',
  [OrderStatus.Unpaid]: 'loading',
  [OrderStatus.OnHold]: 'check',
  [OrderStatus.Paid]: 'check',
};

export function OrderStatusChip(props: IOrderStatusChipProps) {
  return (
    <StatusChip variant={statusChipVariant[props.orderStatus]} iconName={statusChipIconName[props.orderStatus]}>
      {ORDER_STATUS_I18N[props.orderStatus]}
    </StatusChip>
  );
}

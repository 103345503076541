export enum OrderStatus {
  Expired = 'EXPIRED',
  Unpaid = 'UNPAID',
  OnHold = 'ON_HOLD',
  Paid = 'PAID',
}

export const ORDER_STATUS_I18N: Record<OrderStatus, string> = {
  [OrderStatus.Expired]: 'Истек',
  [OrderStatus.Unpaid]: 'Создан',
  [OrderStatus.OnHold]: 'Авторизован',
  [OrderStatus.Paid]: 'Оплачен',
};

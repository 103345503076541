import { AmountCategory, DateCategory, RefundStatus } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../instances';

export type IGetSearchRefundsRequestParams = {
  /**
   * Номер запрашиваемой страницы
   *
   * @default 1
   * @moreOrEqual 1
   */
  page?: number;
  /**
   * Количество запрашиваемых строк
   *
   * @default 10
   * @moreOrEqual 1
   */
  limit?: number;
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
  /** Виды фильтров по дате */
  date_category?: DateCategory;
  /**
   * Идентификатор заказа
   *
   * @nonEmpty
   */
  order_id?: string;
  /**
   * Идентификатор платежа
   *
   * @nonEmpty
   */
  payment_id?: string;
  /** Статус возврата */
  refund_status?: RefundStatus;
  /** Виды фильтров по сумме */
  amount_category?: AmountCategory;
  /**
   * Фискированная сумма возврата
   *
   * @more 1
   */
  fixed_amount?: number;
  /**
   * Минимальная сумма возврата
   *
   * @more 1
   */
  min_amount?: number;
  /**
   * Максимальная сумма возврата
   *
   * @more 1
   */
  max_amount?: number;
  /** Дополнительная информация по заказу */
  extra_info?: string;
};

export interface IGetSearchRefundsResponseDataItem {
  /** Идентификатор возврата */
  id: string;
  /** Идентификатор магазина */
  shop_id: string | null;
  /** Идентификатор заказа */
  order_id: string | null;
  /** Идентификатор платежа */
  payment_id: string | null;
  /** Статус возврата */
  status: RefundStatus;
  /** Время создания возврата */
  created_at: string;
  /** Сумма возврата */
  amount: number;
  /** Причина возврата */
  reason: string | null;
}
export interface IGetSearchRefundsResponseData<IDataItem = IGetSearchRefundsResponseDataItem> {
  /**
   * Количество возвратов
   *
   * @moreOrEqual 0
   */
  count: number;
  /** Информация по возвратам */
  data: IDataItem[];
}

/** Поиск возвратов по фильтрам */
export async function getSearchRefunds(
  params: IGetSearchRefundsRequestParams,
): Promise<Readonly<IGetSearchRefundsResponseData>> {
  const { data } = await backendHttp.get<IGetSearchRefundsResponseData>('/search/refunds', { params });
  return data;
}

import { FillView } from '@mobily/stacks';

import { PasswordRecoveryForm } from '@iokanx/dashboard/feature';
import { wrapPage } from '@iokanx/shared/feature';

function ForgotPassword() {
  return (
    <FillView alignX={'center'} alignY={'center'} padding={2}>
      <PasswordRecoveryForm />
    </FillView>
  );
}

export default wrapPage(ForgotPassword);

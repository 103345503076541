import { MaterialCommunityIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQuery } from '@tanstack/react-query';
import { Platform, StyleSheet } from 'react-native';

import {
  DashboardDrawer,
  DashboardDrawerContent,
  DashboardDrawerParamList,
  NPS,
  useMe,
} from '@iokanx/dashboard/feature';
import AnalyticsPage from '@iokanx/dashboard/pages/analytics-page/analytics-page';
import EmployeesPage from '@iokanx/dashboard/pages/employees-page/employees-page';
import MerchantsPage from '@iokanx/dashboard/pages/merchants-page/merchants-page';
import NpsPage from '@iokanx/dashboard/pages/nps-page/nps-page';
import OperationsPage from '@iokanx/dashboard/pages/operations-page/operations-page';
import { OrganizationsPage } from '@iokanx/dashboard/pages/organizations-page/organizations-page';
import ProfilesPage from '@iokanx/dashboard/pages/profiles-page/profiles-page';
import ReportsPage from '@iokanx/dashboard/pages/reports-page/reports-page';
import UsersNavigator from '@iokanx/dashboard/pages/users-page/users-page-navigator';
import {
  DRAWER_ITEM_ICON_SIZE,
  DRAWER_WIDTH,
  SELECTED_SHOP_ROLE,
  ShopUserRole,
} from '@iokanx/shared/data-access/constants';
import { useDrawerType } from '@iokanx/shared/feature';
import { Colors, IconButton } from '@iokanx/shared/ui/universal';

export default function DashboardDrawerNavigator() {
  const drawerType = useDrawerType();
  const { me } = useMe();

  const { data: selectedShopRole } = useQuery([SELECTED_SHOP_ROLE], () => AsyncStorage.getItem(SELECTED_SHOP_ROLE));

  const menuList = [
    {
      title: 'Операции',
      name: 'Operations',
      component: OperationsPage,
      icon: 'credit-card',
      hidden: [],
    },
    {
      title: 'Профиль',
      name: 'Profiles',
      component: ProfilesPage,
      icon: 'badge-account',
      hidden: [ShopUserRole.Admin, ShopUserRole.Manager, ShopUserRole.Support, ShopUserRole.Viewer],
    },
    {
      title: 'Аналитика',
      name: 'Analytics',
      component: AnalyticsPage,
      icon: 'poll',
      hidden: [ShopUserRole.Manager],
    },

    {
      title: 'Отчеты',
      name: 'Reports',
      component: ReportsPage,
      icon: 'cash-lock',
      hidden: [],
    },
    {
      title: 'Организации',
      name: 'Organizations',
      component: OrganizationsPage,
      icon: 'card',
      hidden: [ShopUserRole.Admin, ShopUserRole.Owner, ShopUserRole.Manager, ShopUserRole.Support, ShopUserRole.Viewer],
    },
    {
      title: 'Магазины',
      name: 'Merchants',
      component: MerchantsPage,
      icon: 'shopping',
      hidden: [ShopUserRole.Admin, ShopUserRole.Owner, ShopUserRole.Manager, ShopUserRole.Support, ShopUserRole.Viewer],
    },
    {
      title: 'Пользователи',
      name: 'Users',
      component: UsersNavigator,
      icon: 'account-group',
      hidden: [ShopUserRole.Admin, ShopUserRole.Owner, ShopUserRole.Manager, ShopUserRole.Support, ShopUserRole.Viewer],
    },
    {
      title: 'Сотрудники',
      name: 'Employees',
      component: EmployeesPage,
      icon: 'account-group',
      hidden: [ShopUserRole.Support, ShopUserRole.Manager],
    },
    {
      title: 'Nps опросы',
      name: 'Nps',
      component: NpsPage,
      icon: 'align-vertical-bottom',
      hidden: [ShopUserRole.Admin, ShopUserRole.Owner, ShopUserRole.Manager, ShopUserRole.Support, ShopUserRole.Viewer],
    },
    // {
    //   title: 'Контакты',
    //   name: 'Contacts',
    //   component: ContactsPage,
    //   icon: 'contacts',
    //   hidden: [ShopUserRole.Admin],
    // }
  ];

  return (
    <>
      <DashboardDrawer.Navigator
        useLegacyImplementation={Platform.OS !== 'android'}
        screenOptions={(screenOptionsProps) => ({
          drawerType,
          headerShown: drawerType !== 'permanent',
          headerTitleAlign: 'left',
          headerTintColor: Colors.black,
          unmountOnBlur: true,
          headerShadowVisible: false,
          headerLeft: Platform.select({
            android: (headerLeftProps) => (
              <IconButton
                icon={'menu'}
                onPress={screenOptionsProps.navigation.toggleDrawer}
                color={headerLeftProps.tintColor}
              />
            ),
          }),
          drawerItemStyle: styles.drawerItemStyle,
          drawerActiveBackgroundColor: '#F1F5F9',
          drawerInactiveTintColor: '#64748B',
          drawerLabelStyle: styles.drawerLabelStyle,
          drawerStyle: styles.drawerStyle,
        })}
        drawerContent={(props) => <DashboardDrawerContent {...props} />}
      >
        {menuList
          .filter((menu) => !menu.hidden.includes(selectedShopRole as ShopUserRole))
          .map((item, index) => {
            return (
              <DashboardDrawer.Screen
                key={index}
                name={item.name as keyof DashboardDrawerParamList}
                component={item.component}
                options={{
                  title: `${item.title}`,
                  drawerIcon: (props) => (
                    <MaterialCommunityIcons
                      name={`${item.icon}` as any}
                      size={DRAWER_ITEM_ICON_SIZE}
                      color={props.color}
                    />
                  ),
                }}
              />
            );
          })}
      </DashboardDrawer.Navigator>
      {!me?.is_superuser && <NPS />}
    </>
  );
}

const styles = StyleSheet.create({
  drawerStyle: {
    width: DRAWER_WIDTH,
    borderRightWidth: 1,
    borderRightColor: 'rgb(226, 232, 240)',
  },
  drawerItemStyle: {
    marginHorizontal: 16,
    marginVertical: 0,
    borderRadius: 12,
    paddingHorizontal: 8,
  },
  drawerLabelStyle: {
    marginLeft: -20,
    fontWeight: '400',
  },
});

import { Platform } from 'react-native';

export interface ISaveFileParams {
  data: Blob | string;
  fileName?: string;
}

export async function saveFile(params: ISaveFileParams): Promise<void> {
  if (Platform.OS === 'web') {
    const { saveAs } = await import('file-saver');
    saveAs(params.data, params.fileName);
  }
}

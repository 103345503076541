import { getOrdersByIdSplit, IFindSplitByIdResult } from '../../resources';

export interface IGetOrderSplitResolverParams {
  orderId: string;
}

export async function getOrderSplitResolver(params: IGetOrderSplitResolverParams) {
  const split = await getOrdersByIdSplit(params.orderId);
  return transformSplit(split);
}

export function transformSplit(split: IFindSplitByIdResult): IFindSplitByIdResult {
  split.stats = {
    WITHDRAWAL: !split.stats.WITHDRAWAL ? 0 : split.stats.WITHDRAWAL / 100,
    CASHBACK: !split.stats.CASHBACK ? 0 : split.stats.CASHBACK / 100,
    DEPOSIT: !split.stats.DEPOSIT ? 0 : split.stats.DEPOSIT / 100,
    COMMISSION: !split.stats.COMMISSION ? 0 : split.stats.COMMISSION / 100,
  };
  return split;
}

import { Tiles } from '@mobily/stacks';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import { ISelectItem, Select, TextField } from '@iokanx/shared/ui/universal';

import { searchUsersParamI18n } from './constants';
import { IUserListFormFieldValues, SearchUsersParam } from './types';

export const Filter = ({ form }: { form: UseFormReturn<IUserListFormFieldValues> }) => {
  const [searchUsersParam, setSearchUsersParam] = useState(SearchUsersParam.name);

  const searchUsersParamItems: ISelectItem<SearchUsersParam>[] = Object.values(SearchUsersParam).map((value) => ({
    title: searchUsersParamI18n[value],
    value,
  }));

  function handleChangeSearchUserParam(nextSearchUserParam: SearchUsersParam) {
    form.reset();
    setSearchUsersParam(nextSearchUserParam);
  }

  return (
    <Tiles columns={[1, 4]} space={2}>
      <Select
        onChange={handleChangeSearchUserParam}
        value={searchUsersParam}
        label={'Поиск по'}
        items={searchUsersParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label={searchUsersParamI18n[searchUsersParam]}
          />
        )}
        name={searchUsersParam}
      />
    </Tiles>
  );
};

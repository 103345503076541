import { ComponentProps, ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';

export type SimpleCardProps = ComponentProps<typeof Card.Title> &
  Pick<ComponentProps<typeof Card>, 'onPress'> & {
    children: ReactNode;
    cardStyle?: ComponentProps<typeof Card>['style'];
    fullHeight?: boolean;
  };

export function SimpleCard({ onPress, cardStyle, fullHeight, ...props }: SimpleCardProps) {
  return (
    <Card mode={'outlined'} onPress={onPress} style={[fullHeight && styles.fullHeight, cardStyle]}>
      <Card.Title {...props} />
      {props.children}
    </Card>
  );
}

const styles = StyleSheet.create({
  fullHeight: {
    height: '100%',
  },
});

import { MaterialIcons } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mobily/stacks';
import { isDate } from 'lodash';
import moment from 'moment';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ScrollView, StyleSheet } from 'react-native';
import * as yup from 'yup';

import { ICreatePaymentLinkResolverParams } from '@iokanx/shared/data-access/api';
import { CAPTURE_METHOD_I18N, CaptureMethod, PaymentLinkType } from '@iokanx/shared/data-access/constants';
import { Breakpoint, useCombinedTheme, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import {
  Caption,
  Divider,
  HelperText,
  TextField,
  IRadioButtonItem,
  OutlineView,
  RadioButtons,
  Tabs,
  Title,
  Button,
  DatePicker,
} from '@iokanx/shared/ui/universal';

import { MIN_ORDER_AMOUNT } from '../create-order-form/create-order-form';
import { CreatePaymentLinkFormRangedTab } from '../create-payment-link-form-ranged-tab/create-payment-link-form-ranged-tab';

export interface CreatePaymentLinkFormProps {
  onSubmit: (data: ICreatePaymentLinkFormFieldValues) => void;
  isLoading: boolean;
  error?: string;
  isError: boolean;
}

export interface ICreatePaymentLinkFormFieldValues
  extends Pick<
      ICreatePaymentLinkResolverParams,
      'description' | 'dueDate' | 'amount' | 'slug' | 'quantity' | 'min_amount' | 'max_amount' | 'step'
    >,
    Required<Pick<ICreatePaymentLinkResolverParams, 'link_type' | 'capture_method'>> {
  lifecycle: Lifecycle;
}

const captureMethodItems: IRadioButtonItem<CaptureMethod>[] = Object.values(CaptureMethod).map((captureMethod) => ({
  value: captureMethod,
  label: CAPTURE_METHOD_I18N[captureMethod],
}));

enum Lifecycle {
  Date = 'DATE',
  Count = 'COUNT',
}

const MIN_QUANTITY = 1;

const createPaymentLinkFormValidationSchema: yup.SchemaOf<ICreatePaymentLinkFormFieldValues> = yup.object({
  description: yup.string().required(),
  link_type: yup.mixed().oneOf(Object.values(PaymentLinkType)),
  capture_method: yup.mixed().oneOf(Object.values(CaptureMethod)),
  dueDate: yup.date().when('lifecycle', {
    is: Lifecycle.Date,
    then: yup.date().required(),
  }),
  lifecycle: yup.mixed().oneOf(Object.values(Lifecycle)),
  amount: yup.number().when('link_type', {
    is: PaymentLinkType.Regular,
    then: yup.number().required().min(MIN_ORDER_AMOUNT),
  }),
  slug: yup
    .string()
    .required()
    .min(1)
    .max(128)
    .matches(/^[a-zA-Z0-9_-]+$/),
  quantity: yup.number().when('lifecycle', {
    is: Lifecycle.Count,
    then: yup.number().required().min(MIN_QUANTITY),
  }),
  min_amount: yup.number().when('link_type', {
    is: PaymentLinkType.Ranged,
    then: yup.number().required().min(MIN_ORDER_AMOUNT),
  }),
  max_amount: yup.number().when('link_type', {
    is: PaymentLinkType.Ranged,
    then: yup.number().required().min(MIN_ORDER_AMOUNT),
  }),
  step: yup.number().when('link_type', {
    is: PaymentLinkType.Ranged,
    then: yup.number().required().min(MIN_ORDER_AMOUNT),
  }),
});

const DEFAULT_DUE_DATE = moment().add(10, 'days').toDate();

export function CreatePaymentLinkForm(props: CreatePaymentLinkFormProps) {
  const theme = useCombinedTheme();
  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  const { control, handleSubmit, setValue, resetField } = useForm<ICreatePaymentLinkFormFieldValues>({
    defaultValues: {
      description: '',
      link_type: PaymentLinkType.Regular,
      capture_method: CaptureMethod.Auto,
      dueDate: DEFAULT_DUE_DATE,
      lifecycle: Lifecycle.Date,
      slug: '',
    },
    resolver: yupResolver(createPaymentLinkFormValidationSchema),
  });
  const onSubmit: SubmitHandler<ICreatePaymentLinkFormFieldValues> = (data) => {
    props.onSubmit(data);
  };

  function handleChangeLinkType(linkType: PaymentLinkType) {
    switch (linkType) {
      case PaymentLinkType.Regular:
        resetField('amount');
        setValue('min_amount', undefined);
        setValue('max_amount', undefined);
        setValue('step', undefined);
        break;
      case PaymentLinkType.Ranged:
        setValue('amount', undefined);
        resetField('min_amount');
        resetField('max_amount');
        resetField('step');
    }

    setValue('link_type', linkType);
  }

  function handleChangeLifecycle(lifecycle: Lifecycle) {
    switch (lifecycle) {
      case Lifecycle.Date:
        resetField('dueDate');
        setValue('quantity', undefined);
        break;
      case Lifecycle.Count:
        setValue('dueDate', undefined);
        resetField('quantity');
    }

    setValue('lifecycle', lifecycle);
  }

  return (
    <ScrollView>
      <Stack paddingX={4} paddingY={6} space={6}>
        {currentBreakpoint === Breakpoint.Desktop && <Title>Новая ссылка на оплату</Title>}
        <Controller
          control={control}
          name={'slug'}
          render={({ field, fieldState }) => (
            <TextField
              label={'Slug'}
              value={field.value}
              onChangeText={field.onChange}
              onBlur={field.onBlur}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              autoCapitalize={'none'}
            />
          )}
        />
        <Controller
          control={control}
          name={'description'}
          render={({ field, fieldState }) => (
            <TextField
              label={'Описание'}
              multiline
              numberOfLines={3}
              value={field.value}
              onChangeText={field.onChange}
              onBlur={field.onBlur}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Divider />
        <Controller
          control={control}
          name={'link_type'}
          render={({ field }) => (
            <Tabs
              tabs={[
                {
                  key: PaymentLinkType.Regular,
                  label: 'Фикс. цена',
                  content: () => (
                    <Controller
                      control={control}
                      name={'amount'}
                      render={({ field, fieldState }) => (
                        <TextField
                          label={'Цена'}
                          value={field.value?.toString() ?? ''}
                          onChangeText={field.onChange}
                          onBlur={field.onBlur}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          keyboardType={'numeric'}
                        />
                      )}
                    />
                  ),
                },
                {
                  key: PaymentLinkType.Ranged,
                  label: 'Диапазон цен',
                  content: () => <CreatePaymentLinkFormRangedTab control={control} />,
                },
              ]}
              tabContainerStyle={styles.tabContainerStyle}
              value={field.value}
              onChange={handleChangeLinkType}
            />
          )}
        />
        <Controller
          control={control}
          name={'capture_method'}
          render={({ field }) => (
            <RadioButtons
              label={'Тип транзакции'}
              items={captureMethodItems}
              value={field.value}
              onValueChange={field.onChange}
            />
          )}
        />
        <OutlineView>
          <Caption>Лимиты на оплаты</Caption>
          <Controller
            control={control}
            name={'lifecycle'}
            render={({ field }) => (
              <Tabs
                tabs={[
                  {
                    key: Lifecycle.Date,
                    label: 'До даты',
                    content: () => (
                      <Stack space={2}>
                        <Controller
                          control={control}
                          name={'dueDate'}
                          render={({ field, fieldState }) => (
                            <DatePicker
                              label="Выберите дату окончания"
                              value={field.value}
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                        <Stack horizontal align={'center'} space={1}>
                          <MaterialIcons name="error" color={theme.colors.disabled} size={20} />
                          <HelperText padding={'none'} type={'error'}>
                            После выбранной даты, ссылка на оплату будет недоступной
                          </HelperText>
                        </Stack>
                      </Stack>
                    ),
                  },
                  {
                    key: Lifecycle.Count,
                    label: 'С кол-вом оплат',
                    content: () => (
                      <Stack space={2}>
                        <Controller
                          control={control}
                          name={'quantity'}
                          render={({ field, fieldState }) => (
                            <TextField
                              label={'Количество оплат'}
                              value={isDate(field.value) ? '' : field.value?.toString() ?? ''}
                              onChangeText={field.onChange}
                              onBlur={field.onBlur}
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error?.message}
                              keyboardType={'numeric'}
                            />
                          )}
                        />
                        <Stack horizontal align={'center'} space={1}>
                          <MaterialIcons name="error" color={theme.colors.disabled} size={20} />
                          <HelperText padding={'none'} type={'error'}>
                            После введеного количества, ссылка на оплату будет недоступной
                          </HelperText>
                        </Stack>
                      </Stack>
                    ),
                  },
                ]}
                tabContainerStyle={styles.tabContainerStyle}
                value={field.value}
                onChange={handleChangeLifecycle}
              />
            )}
          />
        </OutlineView>
        <HelperText type={'error'} visible={props.isError}>
          {props.error}
        </HelperText>
        <Button onPress={handleSubmit(onSubmit)} loading={props.isLoading}>
          Создать
        </Button>
      </Stack>
    </ScrollView>
  );
}

const styles = StyleSheet.create({ tabContainerStyle: { marginTop: 24 } });

import { Tiles } from '@mobily/stacks';
import moment from 'moment';
import { ComponentType, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';

import {
  IFindPayoutsRequestResolverParams,
  IFindRevisesRequestResolverParams,
  IGetPayoutByIdResponseData,
} from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { Button, DateRangePicker, ISelectItem, Select, TextField } from '@iokanx/shared/ui/universal';
import { useSessionStorageDate } from '@iokanx/shared/util';

import { Amplitude } from '../amplitude/amplitude';
import { useDashboardNavigation } from '../navigation';
import { PayoutListDesktop } from '../payout-list-desktop/payout-list-desktop';
import { PayoutListMobile } from '../payout-list-mobile/payout-list-mobile';

export enum SearchPayoutsParam {
  PayoutId = 'payout_id',
  ExternalId = 'external_id',
}

export interface IPayoutListFormFieldValues extends Pick<IFindPayoutsRequestResolverParams, SearchPayoutsParam> {
  page: NonNullable<IFindPayoutsRequestResolverParams['page']>;
  from_dt: IFindPayoutsRequestResolverParams['from_dt'];
  to_dt: IFindPayoutsRequestResolverParams['to_dt'];
  acquirer?: IFindPayoutsRequestResolverParams['acquirer'];
}

const searchPayoutsParamI18n: Record<SearchPayoutsParam, string> = {
  [SearchPayoutsParam.PayoutId]: 'ID выплаты',
  [SearchPayoutsParam.ExternalId]: 'Номер выплаты',
};
const searchPayoutsParamItems: ISelectItem<SearchPayoutsParam>[] = Object.values(SearchPayoutsParam).map((value) => ({
  title: searchPayoutsParamI18n[value],
  value,
}));

const PAYOUT_LIST_LIMIT = 10;

export interface IPayoutListProps {
  onPayoutPress: (payout: IGetPayoutByIdResponseData) => void;
  form: UseFormReturn<IPayoutListFormFieldValues>;
  pageLimit: number;
  filter: JSX.Element;
}

const RESPONSIVE_PAYOUT_LIST: Record<Breakpoint, ComponentType<IPayoutListProps>> = {
  [Breakpoint.Desktop]: PayoutListDesktop,
  [Breakpoint.Mobile]: PayoutListMobile,
};

export function PayoutList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const { startDate, endDate } = useSessionStorageDate();

  const form = useForm<IPayoutListFormFieldValues>({
    defaultValues: {
      page: 1,
      from_dt: startDate || moment().subtract(1, 'month').startOf('day').toDate(),
      to_dt: endDate || moment().endOf('day').toDate(),
      external_id: '',
      acquirer: '',
    },
  });

  const [searchPayoutParam, setSearchPayoutParam] = useState(SearchPayoutsParam.ExternalId);

  function handleChangeSearchPayoutParam(nextSearchPayoutParam: SearchPayoutsParam) {
    form.reset();
    setSearchPayoutParam(nextSearchPayoutParam);
  }

  const navigation = useDashboardNavigation();

  function handleNavigateToPayout(payout: IGetPayoutByIdResponseData) {
    navigation.navigate('Payout', { payoutId: payout.id });
    Amplitude.logEventWithPropertiesAsync('payout_click', {});
  }

  const filter = (
    <Tiles columns={[1, 5]} space={2}>
      <Select
        onChange={handleChangeSearchPayoutParam}
        value={searchPayoutParam}
        label={'Поиск по'}
        items={searchPayoutsParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label={searchPayoutsParamI18n[searchPayoutParam]}
          />
        )}
        name={searchPayoutParam}
      />
      <Select
        onChange={(value: string) => {
          form.setValue('acquirer', value);
        }}
        value={form.watch('acquirer') as string}
        label={'Выбрать банка эквайер'}
        items={[
          {
            title: 'Все банки',
            value: '',
          },
          {
            title: 'Halyk',
            value: 'epay_v2',
          },
          {
            title: 'Jusan',
            value: 'jusan',
          },
          {
            title: 'BCC',
            value: 'bcc',
          },
          {
            title: 'Eubank',
            value: 'eubank',
          },
        ]}
      />
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          form.setValue('from_dt', values.startDate);
          form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Временной отрезок' }}
      />
      <Button icon={{ name: 'beaker-plus' }} onPress={() => navigation.navigate('CreatePayout')}>
        Создать выплату
      </Button>
    </Tiles>
  );

  const ResponsivePayoutList = RESPONSIVE_PAYOUT_LIST[currentBreakpoint];

  return (
    <ResponsivePayoutList
      filter={filter}
      onPayoutPress={handleNavigateToPayout}
      form={form}
      pageLimit={PAYOUT_LIST_LIMIT}
    />
  );
}

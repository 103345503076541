import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useQuery } from '@tanstack/react-query';
import { ScrollView } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { DashboardStackParamList } from '@iokanx/dashboard/feature';
import { findFulfilmentByIdResolver } from '@iokanx/shared/data-access/api';
import { wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Divider, List } from '@iokanx/shared/ui/universal';

export type IFulfilmentPageProps = NativeStackScreenProps<DashboardStackParamList, 'Fulfilment'>;

function Fulfilment(props: IFulfilmentPageProps) {
  const { data: fulfilment } = useQuery(['fulfilment', props.route.params], () =>
    findFulfilmentByIdResolver(props.route.params),
  );
  if (!fulfilment) return null;

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  // @ts-ignore
  return (
    <ScrollView>
      <List.Section title={'Выплата'}>
        <Grid container>
          <Grid item xs={6}>
            <Item className="infoTitle">Идентификатор</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>asdsd</Item>
          </Grid>
        </Grid>
      </List.Section>
      <Divider />
    </ScrollView>
  );
}

export default wrapPage(
  wrapModalPage(Fulfilment, {
    position: 'Right',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'Fulfilment'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Reports', params: { screen: 'Fulfilments' } });
        navigation.push('Fulfilment', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

import { useInfiniteQuery } from '@tanstack/react-query';
import { ListRenderItem } from 'react-native';

import { IncomeCard } from '@iokanx/dashboard/ui';
import { searchIncomesResolver, IGetIncomeByIdResponseData } from '@iokanx/shared/data-access/api';
import { createGetNextPageParam } from '@iokanx/shared/util';

import { IIncomeListProps } from '../income-list/income-list';
import { OperationFlatList } from '../operation-flat-list/operation-flat-list';

export function IncomeListMobile(props: IIncomeListProps) {
  const query = useInfiniteQuery(
    ['incomes', props.form.watch()],
    (context) => searchIncomesResolver({ ...props.form.watch(), page: context.pageParam, limit: props.pageLimit }),
    {
      getNextPageParam: createGetNextPageParam({ pageLimit: props.pageLimit }),
    },
  );

  const renderItem: ListRenderItem<IGetIncomeByIdResponseData> = (info) => {
    return <IncomeCard income={info.item} onCardPress={() => props.onIncomePress(info.item)} />;
  };

  return (
    <OperationFlatList
      renderItem={renderItem}
      query={query}
      hasNotNextPageText={'Платежей больше нет'}
      emptyText={'По указанным параметрам, платежей не найдено'}
      filter={props.filter}
    />
  );
}

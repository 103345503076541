import moment from 'moment';

import { Currency, CURRENCY_SYMBOL, DATE_FORMAT, DATETIME_FORMAT } from '@iokanx/shared/data-access/constants';

import {
  getReviseById,
  IGetBankTransactionResponseData,
  IGetReviseByIdResponseData,
  IGetTransactionResponseData,
} from '../../resources';

export interface IFindReviseByReferenceResolverParams {
  reference: string;
}

export interface IFindTransactionResolverResult extends IGetTransactionResponseData {
  shopId?: string;
  dateTime?: string;
  amountWithSymbol?: string;
  processingFeeWithSymbol?: string;
  acquirerFeeWithSymbol?: string;
}

export interface IFindBankTransactionResolverResult extends IGetBankTransactionResponseData {
  dateTime?: string;
  receiptDate?: string;
  amountWithSymbol?: string;
  acquirerFeeWithSymbol?: string;
  processingFeeWithSymbol?: string;
}

export interface IFindReviseByIdResolverResult extends IGetReviseByIdResponseData {
  shopId?: string;

  dateTime?: string;
  amountWithSymbol?: string;
  processingFeeWithSymbol?: string;
  acquirerFeeWithSymbol?: string;

  bankAmountWithSymbol?: string;
  bankProcessingFeeWithSymbol?: string;
  bankAcquirerFeeWithSymbol?: string;
  bankDateTime?: string;
  bankReceiptDate?: string;

  diffAmountWithSymbol?: string;
  diffAcquirerFeeWithSymbol?: string;

  transactions?: IFindTransactionResolverResult[];
  bankTransactions?: IFindBankTransactionResolverResult[];
}

export async function findReviseByIdResolver(
  params: IFindReviseByReferenceResolverParams,
): Promise<IFindReviseByIdResolverResult> {
  const revise = await getReviseById(params.reference);
  return transformRevise(revise);
}

export function transformRevise(revise: IGetReviseByIdResponseData): IFindReviseByIdResolverResult {
  const amountWith = revise.amount ? revise.amount / 100 : '-';
  const amountWithSymbol = `${amountWith.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const processingFee = revise.processing_fee ? revise.processing_fee / 100 : '-';
  const processingFeeWithSymbol = `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const acquirerFee = revise.acquirer_fee ? revise.acquirer_fee / 100 : '-';
  const acquirerFeeWithSymbol = `${acquirerFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const bankAmountWith = revise.bank_amount ? revise.bank_amount / 100 : '-';
  const bankAmountWithSymbol = `${bankAmountWith.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const bankProcessingFee = revise.bank_processing_fee ? revise.bank_processing_fee / 100 : '-';
  const bankProcessingFeeWithSymbol = `${bankProcessingFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const bankAcquirerFee = revise.bank_acquirer_fee ? revise.bank_acquirer_fee / 100 : '-';
  const bankAcquirerFeeWithSymbol = `${bankAcquirerFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const diffAmount = revise.diff_amount ? revise.diff_amount / 100 : '-';
  const diffAmountWithSymbol = `${diffAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const diffAcquirerFee = revise.diff_acquirer_fee ? revise.diff_acquirer_fee / 100 : '-';
  const diffAcquirerFeeWithSymbol = `${diffAcquirerFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const reference = revise.reference || revise.bank_reference;
  const dateTime = revise.date_time || revise.bank_date_time;

  const bankReceiptDate = revise.bank_receipt_date;

  const transactions = revise.transactions?.map(transformTransaction);
  const bankTransactions = revise.bank_transactions?.map(transformBankTransaction);
  return {
    ...revise,
    shopId: revise.shop_id,
    reference: reference,
    dateTime: moment(dateTime).format(DATETIME_FORMAT),
    bankReceiptDate: bankReceiptDate ? moment(bankReceiptDate).format(DATE_FORMAT) : '',
    diffAmountWithSymbol: diffAmountWithSymbol,
    diffAcquirerFeeWithSymbol: diffAcquirerFeeWithSymbol,
    transactions: transactions,
    bankTransactions: bankTransactions,
    amountWithSymbol: amountWithSymbol,
    processingFeeWithSymbol: processingFeeWithSymbol,
    acquirerFeeWithSymbol: acquirerFeeWithSymbol,
    bankAmountWithSymbol: bankAmountWithSymbol,
    bankProcessingFeeWithSymbol: bankProcessingFeeWithSymbol,
    bankAcquirerFeeWithSymbol: bankAcquirerFeeWithSymbol,
  };
}

export function transformTransaction(transaction: IGetTransactionResponseData): IFindTransactionResolverResult {
  const amount = transaction.amount ? transaction.amount / 100 : '-';
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const processingFee = transaction.processing_fee ? transaction.processing_fee / 100 : '-';
  const processingFeeWithSymbol = `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const acquirerFee = transaction.acquirer_fee ? transaction.acquirer_fee / 100 : '-';
  const acquirerFeeWithSymbol = `${acquirerFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  return {
    ...transaction,
    shopId: transaction.shop_id,
    amountWithSymbol: amountWithSymbol,
    processingFeeWithSymbol: processingFeeWithSymbol,
    acquirerFeeWithSymbol: acquirerFeeWithSymbol,
    dateTime: moment(transaction.date_time).format(DATETIME_FORMAT),
  };
}

export function transformBankTransaction(
  bank_transaction: IGetBankTransactionResponseData,
): IFindBankTransactionResolverResult {
  const amount = bank_transaction.amount ? bank_transaction.amount / 100 : '-';
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const processingFee = bank_transaction.processing_fee ? bank_transaction.processing_fee / 100 : '-';
  const processingFeeWithSymbol = `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  const acquirerFee = bank_transaction.acquirer_fee ? bank_transaction.acquirer_fee / 100 : '-';
  const acquirerFeeWithSymbol = `${acquirerFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`;

  return {
    ...bank_transaction,
    amountWithSymbol: amountWithSymbol,
    processingFeeWithSymbol: processingFeeWithSymbol,
    acquirerFeeWithSymbol: acquirerFeeWithSymbol,
    dateTime: moment(bank_transaction.transaction_date).format(DATETIME_FORMAT),
    receiptDate: moment(bank_transaction.receipt_date).format(DATETIME_FORMAT),
  };
}

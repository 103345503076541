import { Stack } from '@mobily/stacks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { compact } from 'lodash';
import moment from 'moment/moment';
import { useReducer } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from 'victory-native';

import { VictoryMaterialTheme } from '@iokanx/dashboard/ui';
import { exportErrorAnalyticsResolver, findErrorAnalyticsResolver } from '@iokanx/shared/data-access/api';
import { DATE_FORMAT } from '@iokanx/shared/data-access/constants';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { Button, SimpleCard, Card, IconButton } from '@iokanx/shared/ui/universal';

import { saveFile } from '../file-saver/file-saver';

export interface IErrorCountsAnalyticsProps {
  from_dt?: Date;
  to_dt?: Date;
}

const PADDING = 16;
const TOP_CHART_COUNT = 10;

export function ErrorCountsAnalytics(props: IErrorCountsAnalyticsProps) {
  const errorAnalyticsQuery = useQuery(['error-counts-analytics', props], () => findErrorAnalyticsResolver(props));

  const [fullErrorAnalytics, toggleFullErrorAnalytics] = useReducer((state) => !state, false);

  const chartData = errorAnalyticsQuery.data?.slice(0, fullErrorAnalytics ? undefined : TOP_CHART_COUNT);
  const chartDataLength = chartData?.length ?? 0;

  const windowDimensions = useWindowDimensions();
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const victoryChartWidth = (windowDimensions.width - 64) * (currentBreakpoint === Breakpoint.Desktop ? 0.375 : 1);
  const victoryChartHeight = (victoryChartWidth / (victoryChartWidth / 175)) * (chartDataLength * 0.2);

  const exportErrorAnalyticsMutation = useMutation(() => exportErrorAnalyticsResolver(props), {
    onSuccess: (data) => {
      const startDate = moment(props.from_dt).format(DATE_FORMAT);
      const endDate = moment(props.to_dt).format(DATE_FORMAT);

      return saveFile({ data, fileName: `error_analytics_${startDate}-${endDate}.xlsx` });
    },
  });

  return (
    <SimpleCard
      title={'Причины отклонения платежей'}
      right={(rightProps) => (
        <IconButton
          {...rightProps}
          icon={'download'}
          onPress={() => exportErrorAnalyticsMutation.mutate()}
          disabled={exportErrorAnalyticsMutation.isLoading}
        />
      )}
      cardStyle={styles.card}
      style={{ minHeight: 50 }}
    >
      <Card.Content>
        <Stack space={2}>
          <VictoryChart
            horizontal
            theme={VictoryMaterialTheme}
            width={victoryChartWidth}
            height={victoryChartHeight}
            padding={{
              left: 50 + PADDING,
              right: 50 + PADDING,
              top: PADDING,
              bottom: PADDING * 2,
            }}
          >
            <VictoryBar
              style={{
                data: { fill: (args) => args.datum.color },
              }}
              data={chartData}
              x={'error'}
              y={'count'}
              labels={({ datum }) => datum.error}
              sortKey="y"
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  text={(args) => compact(String(args.datum.error).split('.')).join('.\n')}
                />
              }
              barWidth={10}
            />
            <VictoryAxis
              orientation={'right'}
              tickFormat={(tick) => chartData?.find((item) => item.error === tick)?.count}
            />
            <VictoryAxis
              orientation={'left'}
              tickFormat={(tick) => chartData?.find((item) => item.error === tick)?.percentage}
            />
          </VictoryChart>
          {errorAnalyticsQuery.data && errorAnalyticsQuery.data.length > TOP_CHART_COUNT && (
            <Button
              mode={'outlined'}
              onPress={toggleFullErrorAnalytics}
              icon={{ name: fullErrorAnalytics ? 'chevron-up' : 'chevron-down' }}
            >
              {fullErrorAnalytics ? 'Свернуть' : 'Развернуть'} диаграмму
            </Button>
          )}
        </Stack>
      </Card.Content>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
  },
});

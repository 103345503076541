import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { TextField } from '@mui/material';
import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import { useId } from 'react';
import DatePicker from 'react-datepicker';

export const DatePickerYear = (props: any) => {
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const portalId = useId();

  function handleChange(value: Date | null) {
    props.onChange(value ?? undefined);
  }

  return (
    <DatePicker
      disabled={props.disabled}
      selected={props.value}
      onChange={handleChange}
      dateFormat={'dd.MM.yyyy'}
      customInput={
        <TextField
          label={props.label}
          InputProps={{ readOnly: true, endAdornment: <CalendarMonthIcon /> }}
          fullWidth
          id={portalId}
        />
      }
      portalId={portalId}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {'<'}
          </button>
          <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {'>'}
          </button>
        </div>
      )}
    />
  );
};

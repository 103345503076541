import { isNumber } from 'lodash';
import moment from 'moment';
import { Text } from 'react-native';

import { ISearchPaymentLinksResolverResultItem } from '@iokanx/shared/data-access/api';
import { Icon } from '@iokanx/shared/ui/universal';

export interface IPaymentLinkStatusProps {
  paymentLink: ISearchPaymentLinksResolverResultItem;
}

export function PaymentLinkStatus(props: IPaymentLinkStatusProps) {
  if (isNumber(props.paymentLink.quantity) && isNumber(props.paymentLink.paid_orders_count)) {
    return <Text>{`Осталось оплат: ${props.paymentLink.quantity - props.paymentLink.paid_orders_count}`}</Text>;
  }

  if (props.paymentLink.due_date) {
    return <Text>{`до ${moment(props.paymentLink.due_date).format('D MMMM')}`}</Text>;
  }

  return <Icon name="infinity" />;
}

import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Stack } from '@mobily/stacks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { StyleSheet } from 'react-native';

import { FinanceAnalyticsChart } from '@iokanx/dashboard/ui';
import { getAggregationAnalyticsResolver, getTimelineAnalyticsResolver } from '@iokanx/shared/data-access/api';
import { useTypedCurrentBreakpoint, Breakpoint } from '@iokanx/shared/feature';
import { Caption, Card, SimpleCard, Title } from '@iokanx/shared/ui/universal';

import { useMe } from '../auth';

export function FinanceTooltip() {
  return (
    <Tooltip title={'Финансовые показатели за выбранный промежуток времени.'} arrow>
      <IconButton size={'small'}>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}

export interface IFinanceAnalyticsProps {
  from_dt?: Date;
  to_dt?: Date;
}

export function FinanceAnalytics(props: IFinanceAnalyticsProps) {
  const { me } = useMe();

  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  const { data: aggregationAnalytics } = useQuery(['aggregation-analytics', props], () =>
    getAggregationAnalyticsResolver(props),
  );

  const { data: timelineAnalytics } = useQuery(['timeline-analytics', props], () =>
    getTimelineAnalyticsResolver(props),
  );

  const capturedChartData = timelineAnalytics?.capturedAmounts.map((capturedAmount, index) => {
    const date = timelineAnalytics.timeline[index];

    return {
      x: date,
      y: capturedAmount,
      label: `Списано: ${capturedAmount?.toLocaleString()}`,
    };
  });

  return (
    <SimpleCard
      title={
        <Stack space={1} horizontal align={'center'}>
          <Title>Финансы</Title>
          <FinanceTooltip />
        </Stack>
      }
      fullHeight={currentBreakpoint === Breakpoint.Desktop}
    >
      <Card.Content style={currentBreakpoint === Breakpoint.Desktop && styles.cardContent}>
        <Stack space={6} style={currentBreakpoint === Breakpoint.Desktop && styles.contentStack}>
          <Stack space={4} horizontal={[false, true]}>
            <Stack>
              <Caption>
                <Icon name="circle" color={'#3ABD8E'} style={styles.totalIncomeIcon} />
                Списано
              </Caption>
              <Title>{aggregationAnalytics?.capturedAmountWithSymbol}</Title>
            </Stack>
            <Stack>
              <Caption>Возвращено</Caption>
              <Title>{aggregationAnalytics?.refundedAmountWithSymbol}</Title>
            </Stack>
            {!me?.is_superuser && (
              <Stack>
                <Caption>Прибыль</Caption>
                <Title>{aggregationAnalytics?.netProfitWithSymbol}</Title>
              </Stack>
            )}
            {me?.is_superuser ? (
              <Stack>
                <Caption>Комиссия ioka</Caption>
                <Title>{aggregationAnalytics?.iokaFeeWithSymbol}</Title>
              </Stack>
            ) : (
              <Stack>
                <Caption>Комиссия</Caption>
                <Title>{aggregationAnalytics?.processingFeeWithSymbol}</Title>
              </Stack>
            )}
            {me?.is_superuser && (
              <Stack>
                <Caption>Комиссия банка</Caption>
                <Title>{aggregationAnalytics?.acquireFeeWithSymbol}</Title>
              </Stack>
            )}
          </Stack>
          <FinanceAnalyticsChart capturedChartData={capturedChartData} />
        </Stack>
      </Card.Content>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  totalIncomeIcon: { marginRight: 4 },
  cardContent: { flex: 1 },
  contentStack: { justifyContent: 'space-between', flex: 1 },
});

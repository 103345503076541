import { Box, Stack } from '@mobily/stacks';
import { StyleSheet } from 'react-native';

import { IFindDashboardShopsResolverResultItem } from '@iokanx/shared/data-access/api';
import { Caption, Card, Paragraph, SimpleCard, SimpleCardProps } from '@iokanx/shared/ui/universal';

import { ShopStatusChip } from '../shop-status-chip/shop-status-chip';

export interface IShopCardProps {
  shop: IFindDashboardShopsResolverResultItem;
  onCardPress: SimpleCardProps['onPress'];
}

interface IContentItem {
  title: string;
  key: keyof Pick<IFindDashboardShopsResolverResultItem, 'externalId' | 'createdAt'>;
}

const contentItems: IContentItem[] = [
  {
    title: 'Вн. идентификатор',
    key: 'externalId',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
  },
];

export function ShopCard(props: IShopCardProps) {
  return (
    <SimpleCard
      title={props.shop.name}
      right={() => <Box marginRight={4}>{<ShopStatusChip shopStatus={props.shop.status} />}</Box>}
      onPress={props.onCardPress}
    >
      <Card.Content>
        {contentItems.map((item) => (
          <Stack key={item.key} horizontal space={2} style={styles.contentItem}>
            <Caption>{item.title}</Caption>
            <Paragraph>{props.shop[item.key]}</Paragraph>
          </Stack>
        ))}
      </Card.Content>
    </SimpleCard>
  );
}

const styles = StyleSheet.create({
  contentItem: {
    justifyContent: 'space-between',
  },
});

import moment from 'moment';

import { PAYMENT_STATUS_COLOR, PAYMENT_STATUS_TITLE, PaymentStatus } from '@iokanx/shared/data-access/constants';

import {
  getAnalyticsPaymentStatuses,
  IGetAnalyticsPaymentStatusesRequestParams,
  IGetAnalyticsPaymentStatusesResponseData,
} from '../../resources';

export type IGetPaymentStatusAnalyticsResolverParams = IGetAnalyticsPaymentStatusesRequestParams;

export interface IGetPaymentStatusAnalyticsResolverResultDataItem {
  paymentStatus: PaymentStatus;
  count: number;
  color: string;
  title: string;
}
export interface IGetPaymentStatusAnalyticsResolverResult {
  /** Общее кол-во платежей */
  totalPaymentsCount: number;
  /** Данные статусов платежей */
  data: IGetPaymentStatusAnalyticsResolverResultDataItem[];
}

export async function getPaymentStatusAnalyticsResolver(
  _params: IGetPaymentStatusAnalyticsResolverParams,
): Promise<IGetPaymentStatusAnalyticsResolverResult> {
  const params = { ..._params };
  if (params.from_dt && !params.to_dt) {
    params.to_dt = moment(params.from_dt).endOf('day').toDate();
  }

  const analytics = await getAnalyticsPaymentStatuses(params);

  return prepareGetPaymentStatusAnalyticsResolver(analytics);
}

export function prepareGetPaymentStatusAnalyticsResolver(
  analytics: IGetAnalyticsPaymentStatusesResponseData,
): IGetPaymentStatusAnalyticsResolverResult {
  const totalPaymentsCount = analytics.reduce((acc, payment) => acc + payment.count, 0);

  const data = analytics.map((payment) => ({
    paymentStatus: payment.status,
    count: payment.count,
    color: PAYMENT_STATUS_COLOR[payment.status],
    title: PAYMENT_STATUS_TITLE[payment.status],
  }));

  data.sort((a, b) => b.count - a.count);

  return { totalPaymentsCount, data };
}

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { alert } from '@iokanx/dashboard/ui';
import { deleteShopUserResolver, IFindShopUsersResolverResultItem } from '@iokanx/shared/data-access/api';
import { ShopUserRole } from '@iokanx/shared/data-access/constants';
import { Colors, IconButton } from '@iokanx/shared/ui/universal';

import { Amplitude } from '../amplitude/amplitude';
import { useMe } from '../auth';
import { useSelectedShop } from '../use-selected-shop/use-selected-shop';

export interface IDeleteEmployeeProps {
  employee: IFindShopUsersResolverResultItem;
}

export function DeleteEmployee(props: IDeleteEmployeeProps) {
  const { selectedShop } = useSelectedShop();
  const { me } = useMe();

  const isOwner = props.employee.role === ShopUserRole.Owner;
  const meOwnerOrAdmin = selectedShop && [ShopUserRole.Owner, ShopUserRole.Admin].includes(selectedShop.role);

  const canAct = !isOwner && meOwnerOrAdmin && props.employee.id !== me?.id;

  const queryClient = useQueryClient();
  const { mutate: deleteShopUser, isLoading: isDeletingShopUser } = useMutation(deleteShopUserResolver, {
    onSettled: () => queryClient.invalidateQueries(['users']),
    onSuccess: () => Amplitude.logEventWithPropertiesAsync('staff_delete', { user_id: me?.id }),
  });
  function handleDeleteShopUser() {
    alert({
      title: 'Вы действительно хотите удалить сотрудника?',
      buttons: [
        {
          text: 'Нет',
          style: 'cancel',
        },
        {
          text: 'Да',
          onPress: () => {
            if (selectedShop) {
              deleteShopUser({ shopId: selectedShop.id, userId: props.employee.id });
            }
          },
        },
      ],
    });
  }

  return (
    <IconButton
      icon="delete"
      color={Colors.red500}
      size={20}
      disabled={!canAct || isDeletingShopUser}
      onPress={handleDeleteShopUser}
    />
  );
}

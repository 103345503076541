export interface IHttpError {
  code: HttpErrorCode;
  message: string;
}

export enum HttpErrorCode {
  ProcessingDeadlineReachedError = 'ProcessingDeadlineReachedError',
  OrderHasNoAttemptsLeft = 'OrderHasNoAttemptsLeft',
  ProcessingDeadlineReached = 'ProcessingDeadlineReached',
}

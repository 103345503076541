import { CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';

import { getFulfilmentById, IGetFulfilmentByIdResponseData } from '../../resources';
import { IFindShopIdResult } from '../shops/find-shop-by-id.resolver';

export interface IFindFulfilmentByIdResolverParams {
  shopId: string;
  date: string;
}

export interface IFindFulfilmentByIdResolverResult extends IGetFulfilmentByIdResponseData {
  id: string;
  shopId: string;
  amountWithSymbol: string;
  processingFeeWithSymbol: string;
  processingToShopWithSymbol: string;
}

export interface IFindDetailFulfilmentByIdResolverResult extends IFindFulfilmentByIdResolverResult {
  shop?: IFindShopIdResult;
}

export async function findFulfilmentByIdResolver(
  params: IFindFulfilmentByIdResolverParams,
): Promise<IFindDetailFulfilmentByIdResolverResult> {
  const fulfilment = await getFulfilmentById({
    shop_id: params.shopId,
    date: params.date,
  });
  return { ...transformFulfilment(fulfilment) };
}

export function transformFulfilment(fulfilment: IGetFulfilmentByIdResponseData): IFindFulfilmentByIdResolverResult {
  const amount = fulfilment.amount / 100;
  const processingFee = fulfilment.processing_fee / 100;
  const payout = fulfilment.payout / 100;
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL['KZT']}`;
  const processingFeeWithSymbol = `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL['KZT']}`;
  const payoutWithSymbol = `${payout.toLocaleString()} ${CURRENCY_SYMBOL['KZT']}`;
  return {
    ...fulfilment,
    id: fulfilment.date,
    shopId: fulfilment.shop_id,
    amountWithSymbol: amountWithSymbol,
    processingFeeWithSymbol: processingFeeWithSymbol,
    processingToShopWithSymbol: payoutWithSymbol,
  };
}

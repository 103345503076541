export const parseFileTypes = (fileTypes: any, idKey: string) => {
  if (fileTypes) {
    return fileTypes.reduce((form: any, fileType: any) => {
      const id = fileType[idKey];

      form[id] = '';

      return form;
    }, {});
  }

  return null;
};

export const parseFiles = (files: any, form: any, idKey: string) => {
  if (files) {
    return files.reduce((result: any, file: any) => {
      const id = file[idKey];
      result[id] = file.name;

      return result;
    }, form);
  }

  return null;
};

import { Stack } from '@mui/material';
import { DataGrid, GridColumns, ruRU } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';

import { RefundStatusChip } from '@iokanx/dashboard/ui';
import { ISearchRefundsResolverResultItem, searchRefundsResolver } from '@iokanx/shared/data-access/api';

import { IRefundListProps } from '../refund-list/refund-list';

const columns: GridColumns<ISearchRefundsResolverResultItem> = [
  {
    headerName: 'Номер возврата',
    field: 'id',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Идентификатор заказа',
    field: 'order_id',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Идентификатор платежа',
    field: 'payment_id',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Сумма возврата',
    field: 'amountWithSymbol',
    type: 'number',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Причина',
    field: 'reason',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: (props) => <RefundStatusChip refundStatus={props.value} />,
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Дата создания',
    field: 'createdAt',
    flex: 1,
    sortable: false,
  },
];

const localeText = {
  ...ruRU.components.MuiDataGrid.defaultProps.localeText,
  noRowsLabel: 'По указанным параметрам, возвратов не найдено',
};

export function RefundListDesktop(props: IRefundListProps) {
  const { data: refunds, isFetching } = useQuery(['refunds', props.form.watch()], () =>
    searchRefundsResolver({ ...props.form.watch(), limit: props.limit }),
  );

  return (
    <Stack padding={2} spacing={2}>
      {props.filter}
      <DataGrid
        rows={refunds?.data || []}
        columns={columns}
        loading={isFetching}
        page={props.form.watch('page') - 1}
        onPageChange={(page) => props.form.setValue('page', page + 1)}
        rowCount={refunds?.count}
        localeText={localeText}
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
        pageSize={props.limit}
        rowsPerPageOptions={[props.limit]}
        paginationMode="server"
      />
    </Stack>
  );
}

import { CSSProperties, FC, ReactNode } from 'react';

interface IStatusChipWrapper {
  children: ReactNode;
}

const wrapperStyles: CSSProperties = {
  width: 'fit-content',
  position: 'relative',
};

const layoverStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
};

export const StatusChipWrapper: FC<IStatusChipWrapper> = ({ children }) => {
  return (
    <div style={wrapperStyles}>
      <div style={layoverStyles} />
      {children}
    </div>
  );
};

import { ShopUserRole } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../instances';

export interface IGetShopsRequestParams {
  /**
   * Количество запрашиваемых строк
   * @default 10
   */
  limit?: number;
}

export interface IShop {
  id: string;
  name: string;
  display_name: string | null;
  role: ShopUserRole;
  organization_id: null | string;
}
export type IGetShopsResponseData = IShop[];

export async function getShops(params?: IGetShopsRequestParams): Promise<IGetShopsResponseData> {
  const { data } = await backendHttp.get<IGetShopsResponseData>('/auth/shops', { params });
  return data;
}

export interface IPostShopRequestData {
  bin: string;
  name: string;
  iban: string;
  owner_id: string;
}

export interface IPostShopResponseData {
  id: string;
  created_at: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  patronymic: string;
  status: string;
  is_superuser: boolean;
}

export async function createShop(requestData: IPostShopRequestData): Promise<Readonly<IPostShopResponseData>> {
  const { data } = await backendHttp.post<IPostShopResponseData>('/shops', requestData);
  return data;
}

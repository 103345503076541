import { ArrowBack, Close } from '@mui/icons-material';
import { Box, Button, Fade, IconButton, Modal, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, isNumber, isUndefined, omitBy } from 'lodash';
import { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react';

import { exitNPSResolver, voteNPSResolver } from '@iokanx/shared/data-access/api';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { ConditionalComponent } from '@iokanx/shared/ui/universal';

import { NPSFirstStep } from './components/NPSFirstStep';
import { NPSFourthStep } from './components/NPSFourthStep';
import { NPSSecondStep } from './components/NPSSecondStep';
import { NPSThirdStep } from './components/NPSThirdStep';
import { messages } from './constants';
import { getDefaultState } from './helpers';
import {
  NPSButtonsContainer,
  NPSDesktopContainerStyles,
  NPSDesktopModalStyles,
  NPSDesktopSnackbarStyles,
  NPSDesktopTitleStyles,
  NPSSubtitleStyles,
} from './styles/NPSDesktopStyles';

import { IVoteNPS } from '../../../../../shared/data-access/api/src/resources';
import { useNPSCheck } from '../auth/use-nps-check/use-nps-check';

const detectIsMobile = {
  [Breakpoint.Mobile]: true,
  [Breakpoint.Desktop]: false,
};

enum NPSContentSteps {
  START = 'start',
  TAGS = 'tags',
  COMMENT = 'comment',
  LAST = 'last',
}

const NPS_CONTENT_STEPS = {
  start: NPSFirstStep,
  tags: NPSSecondStep,
  comment: NPSThirdStep,
  last: NPSFourthStep,
};

const tagsStateStyles = (isMobile: boolean) => ({
  display: 'flex',
  gap: isMobile ? '16px' : '32px',
  width: isMobile ? '80%' : '100%',
  margin: '40px 0',
});

const tagStateStyle = (isActive: boolean) => ({
  width: '100%',
  borderBottom: isActive ? '2px solid #63F' : '2px solid #E2D8FF',
  borderRadius: '10px',
});

const NPSButton = styled(Button)({
  padding: '10px 32px !important',
  color: '#FFF  !important',
  borderRadius: '16px  !important',
  backgroundColor: '#6633FF !important',
  '&:hover': {
    backgroundColor: '#6633FF !important',
  },
});

const NPSButtonSecondary = styled(Button)({
  padding: '10px 32px  !important',
  color: '#000 !important',
  borderRadius: '16px !important',
  backgroundColor: '#FFF !important',
  boxShadow: 'none !important',
  '&:hover': {
    boxShadow: 'none !important',
    backgroundColor: '#FFF !important',
  },
});

export const NPS = () => {
  const { required } = useNPSCheck();

  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const isMobile = useMemo(() => detectIsMobile[currentBreakpoint], [currentBreakpoint]);

  const [isOpen, setIsOpen] = useState<boolean>(isMobile);
  const [rating, setRating] = useState<number | undefined>(undefined);
  const [step, setStep] = useState<NPSContentSteps>(NPSContentSteps.START);
  const [tags, setTags] = useState<string[]>([]);
  const [text, setText] = useState(getDefaultState());
  const [comment, setComment] = useState<string | undefined>();
  const [customTag, setCustomTag] = useState<string | undefined>();
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

  const onSuccess = () => setIsOpen(false);

  const { mutate: exit } = useMutation(exitNPSResolver, { onSuccess });

  const { mutate: vote } = useMutation(voteNPSResolver);

  const changeText = (rating: number) => {
    let title;

    if (rating > 8) {
      title = messages.good.title;
    } else if (rating >= 7) {
      title = messages.medium.title;
    } else {
      title = messages.bad.title;
    }

    setText({ title });
  };

  useEffect(() => {
    setTags([]);
    setComment(undefined);
    setCustomTag(undefined);
  }, [rating]);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => setIsOpen(true), 1000);
    }
  }, []);

  if (!required) return null;

  const handleChangeRating = (e: MouseEvent<HTMLDivElement>) => {
    const value = parseInt((e.currentTarget as HTMLDivElement).innerText);
    setRating(value);
  };

  const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setComment(value);
  };

  const handleSubmit = () => {
    if (!isUndefined(rating)) {
      const submitData = {
        rating,
        comment,
        tags: customTag ? [...tags, customTag] : tags,
      };

      const formattedData = omitBy(submitData, isUndefined);
      vote({ ...(formattedData as unknown as IVoteNPS) });

      setStep(NPSContentSteps.LAST);

      return;
    }

    exit();
  };

  const handleSelect = (e: any) => {
    const selectedTag = e.target.id;

    if (selectedTag) {
      setTags((prevTags) => {
        return prevTags.includes(selectedTag)
          ? [...prevTags.filter((tag) => tag !== selectedTag)]
          : [...prevTags, selectedTag];
      });
    }
  };

  const NPSContentStep = NPS_CONTENT_STEPS[step];

  const handleNext = () => {
    if (NPSContentSteps.START === step && isNumber(rating)) {
      changeText(rating);
      setStep(NPSContentSteps.TAGS);
      return;
    }

    if (NPSContentSteps.TAGS === step) {
      setStep(NPSContentSteps.COMMENT);
      return;
    }

    if (NPSContentSteps.COMMENT === step) {
      setStep(NPSContentSteps.LAST);
    }
  };

  const handleBack = () => {
    if (NPSContentSteps.COMMENT === step) {
      setStep(NPSContentSteps.TAGS);
      return;
    }

    if (NPSContentSteps.TAGS === step) {
      setStep(NPSContentSteps.START);
    }
  };

  const handleNextValidation = (step: NPSContentSteps) => {
    if (step === NPSContentSteps.LAST) return false;

    if (step === NPSContentSteps.START) {
      return !isNumber(rating);
    }

    if (step === NPSContentSteps.TAGS) {
      return !(!isEmpty(tags) || Boolean(customTag));
    }

    return !comment;
  };

  const handleOpenPopup = () => {
    setIsOpenPopup(true);
  };

  const handleButtonText = (step: NPSContentSteps) => {
    if (step === NPSContentSteps.COMMENT) return 'Отправить';
    if (step === NPSContentSteps.LAST) return 'Завершить';

    return 'Далее';
  };

  const handleButtonCB = (step: NPSContentSteps) => {
    if (step === NPSContentSteps.COMMENT) {
      return handleSubmit;
    }

    if (step === NPSContentSteps.LAST) {
      return () => setIsOpen(false);
    }

    return handleNext;
  };

  const handleClose = () => {
    handleSubmit();
    setIsOpenPopup(false);
    setIsOpen(false);
  };

  const handleClosePopup = () => {
    if (NPSContentSteps.LAST === step) {
      setIsOpen(false);
      return;
    }

    handleOpenPopup();
  };

  return (
    <Modal open={isOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Fade in={isOpen} easing={'linear'}>
        <Box sx={NPSDesktopSnackbarStyles(isMobile)}>
          <Box sx={{ width: '100%' }}>
            <Stack justifyContent={'space-between'} sx={{ width: '100%', flexDirection: 'row-reverse' }}>
              <IconButton size={'large'} onClick={handleClosePopup}>
                <Close />
              </IconButton>

              {(step === NPSContentSteps.TAGS || step === NPSContentSteps.COMMENT) && (
                <IconButton size={'large'} onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              )}
            </Stack>

            <Box sx={NPSDesktopContainerStyles(isMobile)}>
              {step !== NPSContentSteps.LAST && (
                <>
                  <Typography component="legend" sx={NPSDesktopTitleStyles}>
                    Форма обратной связи
                  </Typography>

                  <ConditionalComponent
                    condition={step === NPSContentSteps.START}
                    component={
                      <Typography component="legend" sx={NPSSubtitleStyles}>
                        Будем благодарны, если Вы ответите на 3 вопроса. Время прохождения ~1 минута
                      </Typography>
                    }
                  />

                  <Box sx={tagsStateStyles(isMobile)}>
                    <div style={tagStateStyle(step === NPSContentSteps.START)} />
                    <div style={tagStateStyle(step === NPSContentSteps.TAGS)} />
                    <div style={tagStateStyle(step === NPSContentSteps.COMMENT)} />
                  </Box>
                </>
              )}

              <NPSContentStep
                comment={comment}
                text={text}
                tags={tags}
                rating={rating}
                isMobile={isMobile}
                customTag={customTag}
                setCustomTag={setCustomTag}
                handleSelect={handleSelect}
                handleChangeComment={handleChangeComment}
                handleChangeRating={handleChangeRating}
                handleSubmit={handleSubmit}
              />

              <Stack direction="row" sx={NPSButtonsContainer(step === NPSContentSteps.LAST)}>
                <NPSButton disabled={handleNextValidation(step)} onClick={handleButtonCB(step)}>
                  {handleButtonText(step)}
                </NPSButton>
              </Stack>
            </Box>
          </Box>

          <Modal open={isOpenPopup} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Fade in={isOpenPopup} easing={'linear'}>
              <Box sx={NPSDesktopModalStyles(isMobile)}>
                <Stack direction={'row'} justifyContent={'flex-end'} sx={{ width: '100%' }}>
                  <IconButton size={'large'} onClick={() => setIsOpenPopup(false)}>
                    <Close />
                  </IconButton>
                </Stack>

                <Typography component="legend" sx={NPSDesktopTitleStyles}>
                  Вы уверены, что хотите завершить опрос?
                </Typography>

                <Stack direction="column" justifyContent="flex-end" sx={{ width: '100%', gap: '12px' }}>
                  <NPSButton onClick={() => setIsOpenPopup(false)}>{'Продолжить опрос'}</NPSButton>

                  <NPSButtonSecondary onClick={handleClose}>{'Завершить опрос'}</NPSButtonSecondary>
                </Stack>
              </Box>
            </Fade>
          </Modal>
        </Box>
      </Fade>
    </Modal>
  );
};

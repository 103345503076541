import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';

import { CDNImage } from '@iokanx/shared/ui/web';

const StyledShopButton = styled(LoadingButton)<LoadingButtonProps>({
  fontWeight: 500,
  fontSize: 14,
  textTransform: 'none',
  backgroundColor: 'transparent',
  border: 'none',
  justifyContent: 'flex-start',
  padding: '4px 8px',
  borderRadius: 12,
  lineHeight: 1.5,
  letterSpacing: '0.00938em',
  '&:hover': {
    border: 'none',
  },
  '&.MuiLoadingButton-root': {
    border: 'none',
  },
  '& .MuiButton-endIcon': {
    marginLeft: 'auto',
    marginRight: 0,
  },
  '& .MuiButton-startIcon': {
    marginLeft: 0,
  },
});

export function ShopButton(props: LoadingButtonProps) {
  return (
    <StyledShopButton
      {...props}
      variant={'outlined'}
      startIcon={<CDNImage src={'shop-icon.svg'} width={40} height={40} />}
    />
  );
}

import { CURRENCY_SYMBOL } from '@iokanx/shared/data-access/constants';
import { compactObject } from '@iokanx/shared/util';

import {
  getFulfilments,
  IGetFulfilmentByIdResponseData,
  IGetFulfilmentsRequestParams,
  IGetFulfilmentsResponseData,
} from '../../resources';

export type IFindFulfilmentsResolverResult = IGetFulfilmentsResponseData;

export type IFindFulfilmentsRequestResolverParams = IGetFulfilmentsRequestParams;

export interface IFindFulfilmentResolverResultItem extends IGetFulfilmentByIdResponseData {
  id: string;
  shopId: string;
  amountWithSymbol: string;
  processingFeeWithSymbol: string;
  payoutWithSymbol: string;
}

export async function searchFulfilmentsResolver(
  params: IGetFulfilmentsRequestParams,
): Promise<IFindFulfilmentsResolverResult> {
  const fulfilments = await getFulfilments(compactObject(params));
  const data = fulfilments.data.map(transformFulfilment);
  return { ...fulfilments, data };
}

function transformFulfilment(fulfilment: IGetFulfilmentByIdResponseData): IFindFulfilmentResolverResultItem {
  const amount = fulfilment.amount / 100;
  const processingFee = fulfilment.processing_fee / 100;
  const payout = fulfilment.payout / 100;
  const amountWithSymbol = `${amount.toLocaleString()} ${CURRENCY_SYMBOL['KZT']}`;
  const processingFeeWithSymbol = `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL['KZT']}`;
  const payoutWithSymbol = `${payout.toLocaleString()} ${CURRENCY_SYMBOL['KZT']}`;

  return {
    ...fulfilment,
    id: `${fulfilment.date}_${fulfilment.account}_${fulfilment.payout}`,
    shopId: fulfilment.shop_id,
    amountWithSymbol: amountWithSymbol,
    processingFeeWithSymbol: processingFeeWithSymbol,
    payoutWithSymbol: payoutWithSymbol,
  };
}

import { useReducer } from 'react';

import { ITextFieldProps, TextField } from '../text-field/text-field';

export function PasswordField(props: ITextFieldProps) {
  const [secureTextEntry, toggleSecureTextEntry] = useReducer((prevState) => !prevState, true);

  return (
    <TextField
      secureTextEntry={secureTextEntry}
      autoComplete={'password'}
      textContentType={'password'}
      rightIcon={{ name: secureTextEntry ? 'eye' : 'eye-off', onPress: toggleSecureTextEntry }}
      {...props}
    />
  );
}

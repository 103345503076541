import { Tiles } from '@mobily/stacks';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { ComponentType, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Platform } from 'react-native';

import {
  exportRevisesResolver,
  IFindReviseByIdResolverResult,
  IFindRevisesRequestResolverParams,
  IGetReviseByIdResponseData,
} from '@iokanx/shared/data-access/api';
import { SELECTED_SHOP_ID } from '@iokanx/shared/data-access/constants';
import { Breakpoint, useTypedCurrentBreakpoint } from '@iokanx/shared/feature';
import { Button, DateRangePicker, ISelectItem, Select, TextField } from '@iokanx/shared/ui/universal';
import { useSessionStorageDate } from '@iokanx/shared/util';

import { Amplitude } from '../amplitude/amplitude';
import { saveFile } from '../file-saver/file-saver';
import { useDashboardNavigation } from '../navigation';
import { ReviseListDesktop } from '../revise-list-desktop/revise-list-desktop';
import { ReviseListMobile } from '../revise-list-mobile/revise-list-mobile';

export enum SearchRevisesParam {
  OrderId = 'order_id',
  Reference = 'reference',
}

export interface IReviseListFormFieldValues extends Pick<IFindRevisesRequestResolverParams, SearchRevisesParam> {
  page: NonNullable<IFindRevisesRequestResolverParams['page']>;
  from_dt: IFindRevisesRequestResolverParams['from_dt'];
  to_dt: IFindRevisesRequestResolverParams['to_dt'];
  diff_type?: IFindRevisesRequestResolverParams['diff_type'];
  acquirer?: IFindRevisesRequestResolverParams['acquirer'];
  shop_id?: IFindRevisesRequestResolverParams['shop_id'];
}

const searchRevisesParamI18n: Record<SearchRevisesParam, string> = {
  [SearchRevisesParam.OrderId]: 'Идентификатор заказа',
  [SearchRevisesParam.Reference]: 'Референс',
};

const searchRevisesParamItems: ISelectItem<SearchRevisesParam>[] = Object.values(SearchRevisesParam).map((value) => ({
  title: searchRevisesParamI18n[value],
  value,
}));

const REVISE_LIST_LIMIT = 10;

export interface IReviseListProps {
  onRevisePress: (revise: IGetReviseByIdResponseData) => void;
  form: UseFormReturn<IReviseListFormFieldValues>;
  pageLimit: number;
  filter: JSX.Element;
  selectedShopId?: string;
}

const RESPONSIVE_PAYOUT_LIST: Record<Breakpoint, ComponentType<IReviseListProps>> = {
  [Breakpoint.Desktop]: ReviseListDesktop,
  [Breakpoint.Mobile]: ReviseListMobile,
};

export function ReviseList() {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();
  const { startDate, endDate } = useSessionStorageDate();
  const { data: selectedShopId } = useQuery([SELECTED_SHOP_ID], () => AsyncStorage.getItem(SELECTED_SHOP_ID));

  const form = useForm<IReviseListFormFieldValues>({
    defaultValues: {
      page: 1,
      from_dt: startDate || moment().subtract(1, 'month').startOf('day').toDate(),
      to_dt: endDate || moment().endOf('day').toDate(),
      reference: '',
      diff_type: '',
      acquirer: '',
    },
  });

  const [searchReviseParam, setSearchReviseParam] = useState(SearchRevisesParam.Reference);

  function handleChangeSearchReviseParam(nextSearchReviseParam: SearchRevisesParam) {
    form.reset();
    setSearchReviseParam(nextSearchReviseParam);
  }

  const navigation = useDashboardNavigation();

  function handleNavigateToRevise(revise: IFindReviseByIdResolverResult) {
    navigation.navigate('Revise', { reference: revise.reference });
    Amplitude.logEventWithPropertiesAsync('revise_click', {});
  }

  const { mutate: exportRevises, isLoading: isExporting } = useMutation(exportRevisesResolver, {
    onSuccess: (data, variables) => {
      return saveFile({ data, fileName: `revises_${startDate}-${endDate}.xlsx` });
    },
  });

  const filter = (
    <Tiles columns={[1, 5]} space={2}>
      <Select
        onChange={handleChangeSearchReviseParam}
        value={searchReviseParam}
        label={'Поиск по'}
        items={searchRevisesParamItems}
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label={searchRevisesParamI18n[searchReviseParam]}
          />
        )}
        name={searchReviseParam}
      />
      <Select
        onChange={(value: string) => {
          form.setValue('acquirer', value);
        }}
        value={form.watch('acquirer') as string}
        label={'Выбрать банка эквайер'}
        items={[
          {
            title: 'Все банки',
            value: '',
          },
          {
            title: 'Halyk',
            value: 'epay_v2',
          },
          {
            title: 'Jusan',
            value: 'jusan',
          },
          {
            title: 'BCC',
            value: 'bcc',
          },
        ]}
      />
      <DateRangePicker
        values={{ startDate: form.watch('from_dt'), endDate: form.watch('to_dt') }}
        onChangeValues={(values) => {
          form.setValue('from_dt', values.startDate);
          form.setValue('to_dt', values.endDate);
        }}
        inputProps={{ label: 'Дата возмещения' }}
      />
      {Platform.OS === 'web' && (
        <Button icon={{ name: 'file-excel' }} onPress={() => exportRevises(form.watch())} loading={isExporting}>
          Выгрузить
        </Button>
      )}
    </Tiles>
  );

  const ResponsiveReviseList = RESPONSIVE_PAYOUT_LIST[currentBreakpoint];

  return (
    <ResponsiveReviseList
      filter={filter}
      onRevisePress={handleNavigateToRevise}
      form={form}
      selectedShopId={selectedShopId || ''}
      pageLimit={REVISE_LIST_LIMIT}
    />
  );
}

export enum AcquirerType {
  SBERBANK = 'sberbank',
  EPAY = 'epay',
  EPAY_V2 = 'epay_v2',
  JUSAN = 'jusan',
  PAYBOX = 'paybox',
  RBS = 'rbs',
  BCC = 'bcc',
  EUBANK = 'eubank',
}

import { findOrderEventsResolver, IFindOrderEventsResolverResultItem } from './find-order-events.resolver';
import { transformSplit } from './get-order-split.resolver';

import { getOrderById, IFindSplitByIdResult } from '../../resources';
import { IFindPaymentByIdResult, transformPayment } from '../payments/find-payment-by-id.resolver';
import { ISearchOrderItem, transformSearchOrder } from '../search/orders';

export interface IFindOrderByIdResolverParams {
  orderId: string;
}

export interface IFindOrderByIdResolverResult extends ISearchOrderItem {
  split?: IFindSplitByIdResult;
  payments: IFindPaymentByIdResult[];
  events: IFindOrderEventsResolverResultItem[];
}

export async function findOrderByIdResolver(
  params: IFindOrderByIdResolverParams,
): Promise<IFindOrderByIdResolverResult> {
  const orderPromise = getOrderById(params.orderId);
  const eventsPromise = findOrderEventsResolver({ orderId: params.orderId });
  const [order, events] = await Promise.all([orderPromise, eventsPromise]);
  const payments = (order.payments ?? []).map(transformPayment);
  const split = order.split ? transformSplit(order.split) : undefined;

  return { ...transformSearchOrder(order), payments, split, events };
}

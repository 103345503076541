export enum TransferOrderEventName {
  // Статус при создании заказа
  OrderNotTransferred = 'ORDER_NOT_TRANSFERRED',
  // Статус при успешном переводе заказа
  OrderTransferred = 'ORDER_TRANSFERRED',
  // Перевод успешен
  TransferApproved = 'TRANSFER_APPROVED',
  // Перевод отклонен
  TransferDeclined = 'TRANSFER_DECLINED',
  // 3дс нужно пройти
  TransferActionRequired = 'TRANSFER_ACTION_REQUIRED',
}

import { backendHttp } from '@iokanx/shared/data-access/api';

import { generateFileFormData } from '../../helpers/helpers';

export const getOrganizationFileTypes = async (organizationTypeId: number) => {
  const { data } = await backendHttp.get(`/dictionaries/files/types?organization_type_id=${organizationTypeId}`);

  return data;
};

export const getOrganizationFileTypesResolver = async (organizationTypeId: number) => {
  try {
    return await getOrganizationFileTypes(organizationTypeId);
  } catch (e) {
    console.log(e);
  }

  return null;
};

export const getOrganizationFiles = async (organizationId: string) => {
  const { data } = await backendHttp.get(`/organizations/${organizationId}/files`);

  return data;
};

export const getOrganizationFilesResolver = async (organizationId: string) => {
  try {
    return await getOrganizationFiles(organizationId);
  } catch (e) {
    console.log(e);
  }

  return null;
};

export const postOrganizationFile = async (organizationId: string, formData: any) => {
  const { data } = await backendHttp.post(`/organizations/${organizationId}/files`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export const uploadOrganizationFileResolver = async (organizationId: string, data: any) => {
  try {
    const formData = generateFileFormData(data);

    return await postOrganizationFile(organizationId, formData);
  } catch (e) {
    console.log(e);
  }

  return null;
};

export const downloadOrganizationFile = async (organizationId: string, fileId: string) => {
  const { data } = await backendHttp.get(`/organizations/${organizationId}/files/${fileId}/download`, {
    headers: {
      redirect: 'follow',
    },
    responseType: 'blob',
  });

  return data;
};

export const downloadOrganizationFileResolver = async (organizationId: string, fileId: string) => {
  try {
    return await downloadOrganizationFile(organizationId, fileId);
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const deleteOrganizationFile = async (organizationId: string, fileId: string) => {
  const { data } = await backendHttp.delete(`/organizations/${organizationId}/files/${fileId}`);

  return data;
};

export const deleteOrganizationFileResolver = async (organizationId: string, fileId: string) => {
  try {
    return await deleteOrganizationFile(organizationId, fileId);
  } catch (e) {
    console.error(e);
  }

  return null;
};

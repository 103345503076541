import { render } from 'react-dom';

import { IAlertProps } from './utils';
import { WebAlert } from './web-alert/web-alert';

export function alert(props: IAlertProps): void {
  const id = 'alert-' + Math.random().toString();

  const alert = document.createElement('div');
  alert.setAttribute('id', id);
  document.body.appendChild(alert);

  function handleCancel() {
    alert.remove();
  }

  render(<WebAlert {...props} onCancel={handleCancel} />, document.getElementById(id));
}

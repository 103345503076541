import { DashboardPaymentStatus, PaymentStatus } from '@iokanx/shared/data-access/constants';
import { I3DSAction, IAcquirer, IHttpError } from '@iokanx/shared/data-access/types';

import { CommandEvent } from '../../../../../../constants/src/command-event';
import { http } from '../../../../instances';
import { IGetSearchPaymentsResponseDataItemPayer } from '../../../search/payments';

export interface IGetCommandResponseDataItem {
  amount: number;
  payment_id: number;
  code: string;
  message: string;
  id: number;
  created_at: string;
  updated_at: string;
  payment_status: string;
  refund_status: string;
  name: CommandEvent;
  route: string;
}

export interface IGetOrderHistoriesResponseDataItem {
  id: string;
  order_id: string;
  status: DashboardPaymentStatus;
  created_at: string;
  approved_amount: number;
  captured_amount: number;
  refunded_amount: number;
  processing_fee: number;
  payer?: IGetSearchPaymentsResponseDataItemPayer;
  error?: IHttpError;
  acquirer?: IAcquirer;
  action?: I3DSAction;
  commands: IGetCommandResponseDataItem[];
}

export type IGetOrderHistoriesResponseData = IGetOrderHistoriesResponseDataItem[];

export async function getOrderHistories(orderId: string): Promise<IGetOrderHistoriesResponseData> {
  const { data } = await http.get<IGetOrderHistoriesResponseData>(`/admin/orders/${orderId}/payments`);
  return data;
}

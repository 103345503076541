import { PAYOUT_STATUS_I18N, PayoutStatus } from '@iokanx/shared/data-access/constants';

import { StatusChip, StatusChipPropsIconName, StatusChipVariant } from '../status-chip/status-chip';

export interface IPayoutStatusChipProps {
  status: PayoutStatus;
}

const payoutStatusVariant: Record<PayoutStatus, StatusChipVariant> = {
  [PayoutStatus.Pending]: StatusChipVariant.Loading,
  [PayoutStatus.Accepted]: StatusChipVariant.Loading,
  [PayoutStatus.Approved]: StatusChipVariant.Success,
  [PayoutStatus.Declined]: StatusChipVariant.Failure,
  [PayoutStatus.InProcess]: StatusChipVariant.Loading,
};

const statusChipIconName: Record<PayoutStatus, StatusChipPropsIconName> = {
  [PayoutStatus.Pending]: 'loading',
  [PayoutStatus.Accepted]: 'loading',
  [PayoutStatus.Approved]: 'check',
  [PayoutStatus.Declined]: 'close',
  [PayoutStatus.InProcess]: 'loading',
};

export function PayoutStatusChip(props: IPayoutStatusChipProps) {
  return (
    <StatusChip variant={payoutStatusVariant[props.status]} iconName={statusChipIconName[props.status]}>
      {PAYOUT_STATUS_I18N[props.status]}
    </StatusChip>
  );
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import {
  Amplitude,
  CreateShopForm,
  DashboardStackParamList,
  useDashboardNavigation,
  useMe,
} from '@iokanx/dashboard/feature';
import { createShopResolver, ICreateShopResolverResult } from '@iokanx/shared/data-access/api';
import { IHttpError } from '@iokanx/shared/data-access/types';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';

interface ICreateShopFormValues {
  bin: string;
  iban: string;
  name: string;
}

function CreateShop() {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { me } = useMe();
  const navigation = useDashboardNavigation();

  const {
    mutate: createShop,
    error,
    isLoading,
    isError,
  } = useMutation<ICreateShopResolverResult, AxiosError<IHttpError>, ICreateShopFormValues>(createShopResolver, {
    onSuccess: async () => {
      snackbar.show('Мерчант успешно создан', { type: 'success' });
      await Amplitude.logEventWithPropertiesAsync('create_shop', { shop_id: me?.id });

      navigation.navigate('Drawer', { screen: 'Users', params: { screen: 'Owners' } });

      return queryClient.invalidateQueries(['shops']);
    },
  });

  if (!queryClient.getQueryData(['selectedUser'])) {
    navigation.navigate('Drawer', { screen: 'Users', params: { screen: 'Owners' } });
  }

  return (
    <CreateShopForm
      createShop={createShop}
      isLoading={isLoading}
      error={error?.response?.data.message}
      isError={isError}
    />
  );
}

export default wrapPage(
  wrapModalPage(CreateShop, {
    fillNavigationStack: (navigation: NativeStackNavigationProp<DashboardStackParamList>) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Users', params: { screen: 'Owners' } });
        navigation.push('CreateShop');
      }
    },
    handleDismiss: (navigation) => {
      navigation.navigate('Drawer', { screen: 'Users', params: { screen: 'Owners' } });
    },
  }),
);

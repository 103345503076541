import { Skeleton, Stack } from '@mui/material';
import * as React from 'react';

export const TableSkeletonDesktop = () => {
  return (
    <Stack spacing={4} marginX={2} marginY={2}>
      <Stack spacing={1} direction={'row'} sx={{ width: '50%' }}>
        <Skeleton animation="wave" variant={'rectangular'} width={'50%'} height={40} sx={{ borderRadius: 2 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'50%'} height={40} sx={{ borderRadius: 2 }} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={50} sx={{ borderRadius: 1 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={50} sx={{ borderRadius: 1 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={50} sx={{ borderRadius: 1 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={50} sx={{ borderRadius: 1 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={50} sx={{ borderRadius: 1 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={50} sx={{ borderRadius: 1 }} />
      </Stack>
    </Stack>
  );
};

export const TableSkeletonMobile = () => {
  return (
    <Stack spacing={4} marginX={2} marginY={2}>
      <Stack spacing={1}>
        <Skeleton animation="wave" variant={'rectangular'} height={40} sx={{ borderRadius: 4 }} />
        <Skeleton animation="wave" variant={'rectangular'} height={40} sx={{ borderRadius: 4 }} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={120} sx={{ borderRadius: 2 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={120} sx={{ borderRadius: 2 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={120} sx={{ borderRadius: 2 }} />
        <Skeleton animation="wave" variant={'rectangular'} width={'100%'} height={120} sx={{ borderRadius: 2 }} />
      </Stack>
    </Stack>
  );
};

import { isArray } from 'lodash';

export const parseOfficialData = (data: any) => {
  if (data) {
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      patronymic: data.patronymic,
      nib: data.nib,
      number: data.number,
      is_resident: data.is_resident,
      issued_by: data.nib,
      issue_date: data.nib,
      share: data.share,
      reason: data.reason,
    };
  }

  return;
};

export const filterOfficialsShares = (shares: any[]) => {
  if (isArray(shares)) {
    return shares?.filter((share: any) => share.is_official);
  }

  return shares;
};

import { CaptureMethod, Currency, DashboardPaymentStatus } from '@iokanx/shared/data-access/constants';

import { http } from '../../../instances';

export interface IGetDashboardPaymentsRequestParams {
  /**
   * Номер запрашиваемой страницы
   *
   * @default 1
   * @moreOrEqual 1
   */
  page?: number;

  /**
   * Количество запрашиваемых строк
   *
   * @default 10
   * @moreOrEqual 1
   */
  limit?: number;

  /** Конец временного отрезка */
  to_dt?: Date;

  /** Начало временного отрезка */
  from_dt?: Date;

  /**
   * Статус заказа
   *
   * @nonEmpty
   */
  status?: string;

  /**
   * Идентификатор заказа
   *
   * @nonEmpty
   */
  id?: string;

  /**
   * Внешний идентификатор заказа
   *
   * @nonEmpty
   */
  external_id?: string;

  /** Дополнительная информация по заказу */
  extra_info?: string;

  /**
   * Идентификатор платежа
   *
   * @nonEmpty
   */
  payment_id?: string;

  /**
   * Идентификатор платежа на стороне банка
   *
   * @nonEmpty
   */
  reference?: string;

  /**
   * БИН карты
   *
   * ^\d{6}$
   */
  pan_first6?: string;

  /**
   * Последние 4 цифры карты
   *
   * ^\d{4}$
   */
  pan_last4?: string;

  /** Адрес электронной почты плательщика */
  payer_email?: string;

  /** Номер мобильного телефона с международным префиксом */
  payer_phone?: string;

  /** Идентификатор плательщика */
  customer_id?: string;

  /** Идентификатор сохраненной карты */
  card_id?: string;
}

export interface IGetDashboardPaymentsResponseDataItem {
  /** Идентификатор заказа */
  id: string;

  /** Ссылка на форму оплаты */
  checkout_url: string;

  /** Идентификатор магазина */
  shop_id: string;

  /** Статус платежа */
  status: DashboardPaymentStatus;

  /** Время создания заказа */
  created_at: string;

  /**
   * Стоимость предлагаемых товаров или услуг в минорных денежных единицах.
   *
   * Например, для создания заказа на сумму 500 тенге, необходимо ввести 50000 (1 тенге = 100 тиын)
   */
  amount: number;

  /** Доступные валюты для проведения платежа */
  currency: Currency;

  /** Способ списания оплаты */
  capture_method: CaptureMethod;

  /** Внешний идентификатор заказа */
  external_id?: string;

  /** Описание заказа */
  description?: string;

  /** Дополнительные данные, связанные с заказом */
  extra_info?: Record<string, unknown>;

  /** Идентификатор платежа */
  payment_id?: string;

  /** Авторизованная сумма */
  approved_amount: number;

  /** Сумма списания */
  captured_amount: number;

  /** Сумма возврата */
  refunded_amount: number;

  /** Комиссия процессинга */
  processing_fee: number;
}
export interface IGetDashboardPaymentsResponseData {
  /**
   * Количество платежей
   *
   * @moreOrEqual 0
   */
  count: number;

  /** Список платежей */
  data: IGetDashboardPaymentsResponseDataItem[];
}

/** Поиск платежей по фильтрам */
export async function getDashboardPayments(
  params: IGetDashboardPaymentsRequestParams,
): Promise<IGetDashboardPaymentsResponseData> {
  const { data } = await http.get<IGetDashboardPaymentsResponseData>(`/dashboard/payments`, {
    params,
  });
  return data;
}

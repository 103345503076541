import { CaptureMethod, Currency, PaymentLinkType } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../instances';
import { IGetSearchPaymentLinksResponseDataItem } from '../search/payment-links';

export interface IPostPaymentLinksRequestData {
  /**
   * Идентификатор платежной ссылки в рамках всей системы ioka
   *
   * [ 1 .. 128 ] characters
   * ^[a-zA-Z0-9_\-]+$
   **/
  slug: string;
  /**
   * Тип платежной ссылки
   *
   * RANGED - Платежная ссылка с шагом(step), минимальной(min_amount) и максимальной(max_amount) суммой оплаты;
   * REGULAR - Платежная ссылка с фиксированной суммой(amount) оплаты
   **/
  link_type?: PaymentLinkType;
  /** Способ списания оплаты */
  capture_method?: CaptureMethod;
  /** Доступные валюты для проведения платежа */
  currency?: Currency;
  /**
   * Описание платежной ссылки
   *
   * Краткое описание платежной ссылки для отображения на форме
   **/
  description: string;
  /**
   * Стоимость предлагаемых товаров или услуг, в минорных денежных единицах(тиынах)
   *
   * Обязателен для платежных ссылок типа REGULAR.
   *
   * [ 100 .. 2147483647 ]
   **/
  amount?: number;
  /**
   * Минимальная сумма платежа, в минорных денежных единицах(тиынах)
   *
   * Обязателен для платежных ссылок типа RANGED.
   *
   * [ 100 .. 2147483547 ]
   **/
  min_amount?: number;
  /**
   * Максимальная сумма платежа, в минорных денежных единицах(тиынах)
   *
   * Обязателен для платежных ссылок типа RANGED.
   *
   * [ 100 .. 2147483647 ]
   **/
  max_amount?: number;
  /**
   * Шаг платежа, в минорных денежных единицах(тиынах)
   *
   * Обязателен для платежных ссылок типа RANGED.
   *
   * [ 100 .. 2147483647 ]
   **/
  step?: number;
  /**
   * Количество возможных успешных платежей по данной ссылке
   *
   * Когда количество успешно оплаченных заказов по данной платежной ссылке достигнет данного значения,
   * платежная ссылка больше не будет принимать платежи.
   *
   * [ 1 .. 2147483647 ]
   **/
  quantity?: number;
  /**
   * Дата(UTC) до которой платежная ссылка будет принимать платежи
   *
   * По истечении срока платежная ссылка больше не будет принимать платежи.
   *
   * @dateTime
   **/
  due_date?: string;
}

export async function postPaymentLinks(
  requestData: IPostPaymentLinksRequestData,
): Promise<IGetSearchPaymentLinksResponseDataItem> {
  const { data } = await backendHttp.post<IGetSearchPaymentLinksResponseDataItem>('/payment-links', requestData);
  return data;
}

import { CaptureMethod, Currency, DateCategory, PaymentLinkType } from '@iokanx/shared/data-access/constants';

import { backendHttp } from '../../instances';

export type IGetSearchPaymentLinksRequestParams = {
  /**
   * Номер запрашиваемой страницы
   *
   * >= 1
   * @default 1
   * */
  page?: number;
  /**
   * Количество запрашиваемых строк
   *
   * [ 1 .. 50 ]
   * @default 10
   * */
  limit?: number;
  /**
   * Конец временного отрезка
   *
   * @dateTime
   * */
  to_dt?: Date;
  /**
   * Начало временного отрезка
   *
   * @dateTime
   * */
  from_dt?: Date;
  /** Виды фильтров по дате */
  date_category?: DateCategory;
  /**
   * Идентификатор платежной ссылки
   *
   * @nonEmpty
   * */
  payment_link_id?: string;
  /**
   * Шорткат ссылки на оплату
   *
   * Идентификатор платежной ссылки в рамках всей системы ioka
   *
   * @nonEmpty
   * ^[a-zA-Z0-9_\-]+$
   * */
  slug?: string;
  /**
   * Электронный адрес плательщика
   *
   * @email
   * */
  email?: string;
  /**
   * Тип платежной ссылки
   *
   * RANGED - Платежная ссылка с шагом(step), минимальной(min_amount) и максимальной(max_amount) суммой оплаты;
   * REGULAR - Платежная ссылка с фиксированной суммой(amount) оплаты
   * */
  link_type?: PaymentLinkType;
};

export interface IGetSearchPaymentLinksResponseDataItem {
  /**
   * ID ссылки
   *
   * Идентификатор платежной ссылки.
   * Формат pal_xxxxxxxxxx
   * */
  id: string;
  /**
   * Уникальный шорткат платежной ссылки
   *
   * Уникальный, в рамках системы ioka, шорткат платежной ссылки по которому будет доступна платежная ссылка.
   * */
  slug: string;
  /**
   * Тип платежной ссылки
   *
   * RANGED - Платежная ссылка с шагом(step), минимальной(min_amount) и максимальной(max_amount) суммой оплаты;
   * REGULAR - Платежная ссылка с фиксированной суммой(amount) оплаты*/
  link_type: PaymentLinkType;
  /**
   * Идентификатор магазина
   *
   * Уникальный идентификатор магазина в рамках системы ioka.
   * shp_xxxxxxxxx.
   * */
  shop_id: string;
  /**
   * Флаг индвидуальности платежной ссылки
   *
   * Флаг того что платежная ссылка предназначена для определенного плательщика.
   *
   * @default false
   * */
  individual: boolean | null;
  /**
   * Дата создания
   *
   * Дата создания платежной ссылки.
   *
   * @dateTime
   * */
  created_at: string;
  /**
   * Сумма одного платежа
   *
   * Сумма одного платежа для платежной ссылки в тиынах.
   * */
  amount: number | null;
  /**
   * Минимальная сумма одного платежа
   *
   * Минимальная сумма одного платежа для платежной ссылки в тиынах.
   * */
  min_amount: number | null;
  /**
   * Максимальная сумма одного платежа
   *
   * Максимальная сумма одного платежа для платежной ссылки в тиынах.
   * */
  max_amount: number | null;
  /**
   * Шаг платежа
   *
   * Шаг платежа между минимальной и максимальной суммами для платежной ссылки в тиынах
   * */
  step: number | null;
  /**
   * Количество оплаченных заказов
   *
   * Количество оплаченных заказов для платежной ссылки.
   * */
  quantity: number | null;
  /**
   * Количество оплаченных заказов
   *
   * Количество оплаченных заказов для платежной ссылки.
   **/
  paid_orders_count: number | null;
  /** Валюта платежной ссылки */
  currency: Currency;
  /** Способ списания оплаты */
  capture_method: CaptureMethod;
  /**
   * Описание платежной ссылки
   *
   * Краткое описание платежной ссылки для отображения на форме
   * */
  description: string;
  /**
   * Дата(UTC) до которой платежная ссылка будет принимать платежи
   *
   * По истечении срока платежная ссылка больше не будет принимать платежи.
   *
   * @dateTime
   * */
  due_date: string | null;
  /** Токен доступа к заказу */
  access_token: string;
  /**
   * URL платежной ссылки
   *
   * URL на форму для обработки платежной ссылки.
   *
   * @uri
   * [ 1 .. 2083 ] characters
   * */
  payment_link_url: string;
}
export interface IGetSearchPaymentLinksResponseData<Item> {
  data: Item[];
  count: number;
}

export async function getSearchPaymentLinks(
  params: IGetSearchPaymentLinksRequestParams,
): Promise<IGetSearchPaymentLinksResponseData<IGetSearchPaymentLinksResponseDataItem>> {
  const { data } = await backendHttp.get<IGetSearchPaymentLinksResponseData<IGetSearchPaymentLinksResponseDataItem>>(
    'search/payment-links',
    { params },
  );
  return data;
}

import { http } from '../../../../instances';

export interface IGetAnalyticsErrorsExportParams {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
}

/** Скачать статистику по причинам отклонений платежей за период */
export async function getAnalyticsErrorsExport(params: IGetAnalyticsErrorsExportParams): Promise<Blob> {
  const { data } = await http.get('/analytics/errors/export', { params, responseType: 'blob' });
  return data;
}

import { backendHttp } from '@iokanx/shared/data-access/api';

export const getOrganizationTypes = async () => {
  const { data } = await backendHttp.get('/dictionaries/organizations/types');
  return data;
};

export const getOrganizationFileTypesResolver = async () => {
  try {
    return await getOrganizationTypes();
  } catch (e) {
    console.error(e);
  }

  return null;
};

const postOrganizationInfo = async (formData: any) => {
  const { data } = await backendHttp.post<any>(`/organizations`, formData);

  return data;
};

export const postOrganizationInfoResolver = async (formData: any) => {
  return await postOrganizationInfo(formData);
};

const completeOrganizationForm = async (organizationId: string) => {
  const { data } = await backendHttp.post<any>(`/organizations/${organizationId}/completed`);

  return data;
};

export const completeOrganizationFormResolver = async (organizationId: string) => {
  return await completeOrganizationForm(organizationId);
};

import { Stack } from '@mui/material';
import { CategoryScale, Chart, LinearScale, PointElement, LineElement, ChartDataset, Tooltip, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useTypedCurrentBreakpoint } from '@iokanx/shared/feature';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);

interface IChartDataItem {
  x: string;
  y: number;
  label: string;
}
export interface ITodayAnalyticsChartProps {
  capturedChartData?: IChartDataItem[];
  approvedChartData?: IChartDataItem[];
}

export function TodayAnalyticsChart(props: ITodayAnalyticsChartProps) {
  const { currentBreakpoint } = useTypedCurrentBreakpoint();

  const labels = props.approvedChartData?.map((item) => item.x) || [];

  const datasets: ChartDataset<'line', number[] | undefined>[] = [
    {
      label: 'Авторизовано',
      data: props.approvedChartData?.map((item) => item.y),
      borderColor: '#6A30FF',
      backgroundColor: 'rgba(106, 48, 255, 0.1)',
      fill: true,
      borderWidth: 2,
      pointRadius: 0,
    },
    {
      label: 'Списано',
      data: props.capturedChartData?.map((item) => item.y),
      backgroundColor: 'rgba(58, 189, 142, 0.2)',
      borderColor: '#3ABD8E',
      fill: true,
      borderWidth: 2,
      pointRadius: 0,
    },
  ];

  return (
    <Stack>
      <Line
        data={{
          labels,
          datasets,
        }}
        height={currentBreakpoint === 'desktop' ? 40 : 160}
        options={{
          scales: {
            x: {
              ticks: {
                display: false,
              },
            },
            y: {
              ticks: {
                display: false,
              },
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
          },
          elements: {
            line: {
              tension: 0.08,
            },
          },
          layout: {
            padding: 4,
          },
          plugins: {
            tooltip: {
              intersect: false,
              mode: 'nearest',
              backgroundColor: '#475569',
              bodyFont: { size: 12 },
              padding: 16,
            },
          },
        }}
      />
    </Stack>
  );
}

import { ScrollView } from 'react-native';

import { SignInForm } from '@iokanx/dashboard/feature';
import { wrapPage } from '@iokanx/shared/feature';

function SignIn() {
  return (
    <ScrollView contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center', padding: 16 }}>
      <SignInForm />
    </ScrollView>
  );
}

export default wrapPage(SignIn);

import { CSSProperties } from 'react';

export const NPSRatingItemStyles = (item: number, rating?: number) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  border: '1px solid #B4B4B4FF',
  fontFamily: 'Roboto_400Regular',
  borderRadius: 50,

  fontSize: 16,
  height: 38,
  width: 38,

  color: Number(rating) === item ? '#FFF' : '#000',
  backgroundColor: Number(rating) === item ? '#63F' : 'inherit',

  cursor: 'pointer',

  '&:hover': {
    scale: '1.05',
  },
});

export const NPSRatingContainerStyles = (mobile: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  gap: mobile ? '12px' : '20px',
  flexWrap: mobile ? 'wrap' : 'noWrap',
  justifyContent: mobile ? 'center' : 'space-between',
  width: 'max-content',
  minWidth: 'auto',
});

export const NPSRatingContainerStylesNoWrap = {
  display: 'flex',
  flexWrap: 'noWrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',
};

export const NPSMobileContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',

  backgroundColor: '#ffffff',
  width: '80%',

  padding: '40px 20px 20px',

  gap: '24px',
  borderRadius: '8px',
};

export const NPSMobileTextareaStyles: CSSProperties = {
  boxSizing: 'border-box',
  resize: 'none',
  width: '100%',
  height: '170px',
  padding: '12px',
  fontSize: '18px',
  fontFamily: 'Roboto_400Regular, sans-serif',
  border: '1px solid #B197FF',
  borderRadius: '6px',
  outline: 'none',
};

export const NPSRatingWrapperStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

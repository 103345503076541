import { useTheme as useNavigationTheme } from '@react-navigation/native';
import { merge } from 'lodash';
import { useMemo } from 'react';
import { DeepPartial } from 'react-hook-form';
import { useTheme as usePaperTheme } from 'react-native-paper';
import { Theme } from 'react-native-paper/lib/typescript/types';

export const paperThemeOverrides: DeepPartial<Theme> = {
  colors: {
    primary: '#0F172A',
    background: '#fff',
    accent: '#0F172A',
    text: '#0F172A',
  },
  fonts: {
    thin: {
      fontFamily: 'Roboto_100Thin',
      fontWeight: '100',
    },
    light: {
      fontFamily: 'Roboto_300Light',
      fontWeight: '300',
    },
    regular: {
      fontFamily: 'Roboto_400Regular',
      fontWeight: '400',
    },
    medium: {
      fontFamily: 'Roboto_500Medium',
      fontWeight: '500',
    },
  },
};

export function useCombinedTheme() {
  const navigationTheme = useNavigationTheme();
  const paperTheme = usePaperTheme(paperThemeOverrides);

  return useMemo(() => {
    return merge(navigationTheme, paperTheme);
  }, [paperTheme, navigationTheme]);
}

import moment from 'moment';

import { randomColor } from '@iokanx/shared/util';

import {
  getAnalyticsEmitters,
  IGetAnalyticsEmittersRequestParams,
  IGetAnalyticsEmittersResponseData,
} from '../../resources/analytics/emitters';
import {
  findEmittersResolver,
  IFindEmittersResolverResult,
  IFindEmittersResolverResultItem,
} from '../emitter/find-emitters.resolver';

export type IGetEmitterAnalyticsResolverParams = IGetAnalyticsEmittersRequestParams;

export interface IGetEmitterAnalyticsResolverResultItem extends IFindEmittersResolverResultItem {
  count: number;
}
export type IGetEmitterAnalyticsResolverResult = IGetEmitterAnalyticsResolverResultItem[];

export async function getEmitterAnalyticsResolver(
  _params: IGetEmitterAnalyticsResolverParams,
): Promise<IGetEmitterAnalyticsResolverResult> {
  const params = { ..._params };
  if (params.from_dt && !params.to_dt) {
    params.to_dt = moment(params.from_dt).endOf('day').toDate();
  }

  const analytics = await getAnalyticsEmitters(params);
  const emitters = await findEmittersResolver();

  return prepareGetEmitterAnalyticsResolver(analytics, emitters);
}

export function prepareGetEmitterAnalyticsResolver(
  analytics: IGetAnalyticsEmittersResponseData,
  emitters: IFindEmittersResolverResult,
): IGetEmitterAnalyticsResolverResult {
  return analytics
    .map(([code, count]) => {
      const emitter = emitters.find((emitter) => emitter.code === code);
      if (emitter) {
        return { ...emitter, count };
      }

      const color = randomColor();
      const name = code;

      return { code, count, color, name };
    })
    .sort((a, b) => b.count - a.count);
}

import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  createApiKeyByShopIdResolver,
  createEmailByShopIdResolver,
  deleteEmailByIdAndShopIdResolver,
  findApiKeyByShopIdResolver,
  findEmailsByShopIdResolver,
  IFindShopIdResult,
} from '@iokanx/shared/data-access/api';
import { useSnackbar } from '@iokanx/shared/feature';
import { Button, Divider, Icon, List, TextField } from '@iokanx/shared/ui/universal';

export interface IShopApiKeyProps {
  shop: IFindShopIdResult | undefined;
  is_superuser?: boolean;
}

const validationSchema = yup.object({
  email: yup.string().email().required(),
  active: yup.boolean().required(),
});

export default function ShopApiKey(props: IShopApiKeyProps) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { shop, is_superuser } = props;

  const { data } = useQuery(['accesses', shop?.id], () => findApiKeyByShopIdResolver(shop.id));
  const { data: emails } = useQuery(['emails', shop.id], () => findEmailsByShopIdResolver({ shopId: shop.id }));

  const generateHandler = () => {
    createApiKeyByShopIdResolver(shop.id)
      .then((result) => snackbar.show('Создан новый API ключ', { type: 'success' }))
      .catch((err) => snackbar.show(`Ошибка при создании: ${err.message}`, { type: 'warning' }));
  };

  const { mutate: createEmailByShop, isLoading: isCreating } = useMutation(
    ({ shopId, data }: any) => createEmailByShopIdResolver(shopId, data),
    {
      onSuccess: () => {
        snackbar.show('Еmail добавлен в список рассылок', { type: 'success' });
        queryClient.invalidateQueries(['emails', shop?.id]);
      },
    },
  );

  const { mutate: deleteEmailById, isLoading: isDeleting } = useMutation(
    ({ shopId, emailId }: any) => deleteEmailByIdAndShopIdResolver(shopId, emailId),
    {
      onSuccess: () => {
        snackbar.show('Еmail удален из списка рассылок', { type: 'success' });
        queryClient.invalidateQueries(['emails', shop?.id]);
      },
    },
  );

  const { control, handleSubmit, watch, setValue } = useForm<any>({
    defaultValues: {
      email: '',
      active: true,
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const onSubmitEmail: SubmitHandler<IFindShopIdResult> = (data) => createEmailByShop({ shopId: shop.id, data });
  const onDeleteSubmitEmail = (emailId: any) => deleteEmailById({ shopId: shop.id, emailId: emailId });

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  return (
    <Grid container>
      <Grid item xs={12}>
        <List.Section title={'API ключи'}>
          {data &&
            data.map((item) => {
              return (
                <Grid container style={{ marginTop: '15px' }} key={item.id}>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Item className="infoTitle">Файл</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>{item.name}</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item className="infoTitle"> Дата создания</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>{item.createdAt}</Item>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Item>
                      <a href={item.src} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Icon name={'download'} color={'#000'} size={20} />
                      </a>
                    </Item>
                  </Grid>
                </Grid>
              );
            })}
        </List.Section>
        <Divider />
        {is_superuser && (
          <List.Section title={' '}>
            <Grid container>
              <Grid item xs={12}>
                <Item>
                  <Button icon={{ name: 'receipt' }} onPress={generateHandler} fullWidth={true}>
                    Создать
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </List.Section>
        )}
      </Grid>

      <Grid item xs={12}>
        <List.Section title={'Список рассылок'}>
          {emails &&
            emails.map((email) => {
              return (
                <Grid container style={{ marginTop: '15px' }} key={email.id}>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Item className="infoTitle">Почта</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>{email.email}</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item className="infoTitle"> Дата создания</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>{email.createdAt}</Item>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Item>
                      <Icon name={'delete'} color={'#000'} size={20} onPress={() => onDeleteSubmitEmail(email.id)} />
                    </Item>
                  </Grid>
                </Grid>
              );
            })}
        </List.Section>

        <Divider />

        {is_superuser && (
          <List.Section title={''}>
            <Grid container>
              <Grid item xs={6}>
                <Item className="infoTitle">Почта</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <Controller
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        onBlur={field.onBlur}
                        onChangeText={field.onChange}
                        value={field.value}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                        disabled={!is_superuser}
                      />
                    )}
                    name={'email'}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <Button icon={{ name: 'plus' }} onPress={handleSubmit(onSubmitEmail)} loading={isCreating} fullWidth>
                    Добавить
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </List.Section>
        )}
      </Grid>
    </Grid>
  );
}

import { Box, Stack } from '@mobily/stacks';
import { ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { RadioButton, Caption, Subheading, RadioButtonProps } from 'react-native-paper';

import { OutlineView } from '../outline-view/outline-view';

export type IRadioButtonItem<Value> = Omit<RadioButtonProps, 'theme'> & {
  value: Value;
  label: string;
  description?: string;
};

export interface IRadioButtonsProps<Value> extends Omit<ComponentProps<typeof RadioButton.Group>, 'children'> {
  items: IRadioButtonItem<Value>[];
  label: string;
  disabled?: boolean;
}

export function RadioButtons<Value extends string>(props: IRadioButtonsProps<Value>) {
  return (
    <OutlineView>
      <RadioButton.Group {...props}>
        <Caption>{props.label}</Caption>
        <Stack space={4} marginTop={4}>
          {props.items.map(({ label, ...item }) => (
            <Stack key={item.value} horizontal align={'center'} style={styles.item}>
              <Stack>
                <Subheading>{label}</Subheading>
                {item.description && <Caption>{item.description}</Caption>}
              </Stack>
              <Box alignX={'center'}>
                <RadioButton {...item} />
              </Box>
            </Stack>
          ))}
        </Stack>
      </RadioButton.Group>
    </OutlineView>
  );
}

const styles = StyleSheet.create({
  item: { justifyContent: 'space-between' },
});

// @ts-ignore
import { JSONEditor } from '@json-editor/json-editor';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { RouteProp } from '@react-navigation/core/src/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';

import { DashboardStackParamList } from '@iokanx/dashboard/feature';
import {
  findConfigByShopIdResolver,
  IFindConfigByShopIdResult,
  updateConfigByShopIdResolver,
} from '@iokanx/shared/data-access/api';
import {
  apple_pay_schema,
  bcc_schema,
  CONFIG_SCHEMA_TITLE,
  ConfigSchema,
  epay_v2_schema,
  google_pay_schema,
  jusan_schema,
  masterpass_schema,
  sberbank_schema,
  ukassa_schema,
  webkassa_schema,
} from '@iokanx/shared/data-access/constants';
import { useSnackbar, wrapModalPage, wrapPage } from '@iokanx/shared/feature';
import { Button, List } from '@iokanx/shared/ui/universal';

export type IConfigProps = NativeStackScreenProps<DashboardStackParamList, 'Config'>;

const getSchema = (schema: ConfigSchema): any => {
  const schemaList = {
    ukassa: ukassa_schema,
    master_pass: masterpass_schema,
    webkassa: webkassa_schema,
    jusan: jusan_schema,
    sberbank: sberbank_schema,
    epay_v2: epay_v2_schema,
    bcc: bcc_schema,
    google_pay: google_pay_schema,
    apple_pay: apple_pay_schema,
  };
  // @ts-ignore
  return schemaList[schema as string];
};

const getSchemaData = (schema: ConfigSchema, config: IFindConfigByShopIdResult | undefined): object => {
  let items: any = [];
  if ([ConfigSchema.MasterPass, ConfigSchema.UKassa, ConfigSchema.WebKassa].includes(schema)) items = config?.adapters;
  if ([ConfigSchema.Halyk, ConfigSchema.Jusan, ConfigSchema.Bcc, ConfigSchema.Bereke].includes(schema))
    items = config?.acquirers;
  for (let i = 0; i < items.length; i++) if (items[i]['name'] === schema) return items[i];

  if ([ConfigSchema.ApplePay].includes(schema)) return config?.apple_pay || {};
  if ([ConfigSchema.GooglePay].includes(schema)) return config?.google_pay || {};
  return {};
};

const setSchemaData = (
  schema: ConfigSchema,
  config: IFindConfigByShopIdResult | undefined,
  data: any,
): IFindConfigByShopIdResult | undefined => {
  if ([ConfigSchema.MasterPass, ConfigSchema.UKassa, ConfigSchema.WebKassa].includes(schema) && config) {
    const adapters: any = config?.adapters || [];
    for (let i = 0; i < adapters.length; i++) if (adapters[i]['name'] === schema) adapters[i] = data;
    if (adapters) config['adapters'] = adapters;
  }
  if ([ConfigSchema.Halyk, ConfigSchema.Jusan, ConfigSchema.Bcc, ConfigSchema.Bereke].includes(schema) && config) {
    const acquirers: any = config?.acquirers || [];
    for (let i = 0; i < acquirers.length; i++) if (acquirers[i]['name'] === schema) acquirers[i] = data;
    if (acquirers) config.acquirers = acquirers;
  }
  if ([ConfigSchema.ApplePay].includes(schema) && config) config.apple_pay = data;
  if ([ConfigSchema.GooglePay].includes(schema) && config) config.google_pay = data;

  return config;
};

function Config(props: IConfigProps) {
  const snackbar = useSnackbar();
  const { shopId, schema } = props.route.params;
  const { data: config } = useQuery(['config', shopId], () => findConfigByShopIdResolver(shopId));

  const ref = useRef(null);
  const [editor, setEditor] = useState<JSONEditor | null>(null);
  const editorSchema = getSchema(schema as ConfigSchema);
  const editorData = getSchemaData(schema as ConfigSchema, config);

  const editorOptions = {
    schema: editorSchema,
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
    compact: true,
    disable_array_delete_all_rows: true,
    disable_array_delete_last_row: true,
    disable_array_reorder: true,
    startval: editorData,
  };
  // @ts-ignore
  if (Object.keys(editorData).length === 0) delete editorOptions.startval;

  useEffect(() => {
    if (editor) editor.destroy();
    if (ref.current) setEditor(new JSONEditor(ref.current, editorOptions));
  }, [props]);

  const updateHandler = (data: IFindConfigByShopIdResult) => {
    updateConfigByShopIdResolver(data)
      .then((result) => snackbar.show('Обновлено', { type: 'success' }))
      .catch((err) => snackbar.show(`Ошибка: ${err.message}`, { type: 'warning' }));
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  }));

  return (
    <ScrollView>
      <List.Section title={CONFIG_SCHEMA_TITLE[schema as ConfigSchema]}>
        <Grid container>
          <Grid item xs={12}>
            <Item>
              <div ref={ref} className={'editor'} />
              <Button
                icon={{ name: 'receipt' }}
                onPress={() => {
                  if (editor.validate().length === 0) {
                    const data = setSchemaData(schema as ConfigSchema, config, editor.getValue());
                    if (data) updateHandler(data);
                  }
                }}
                fullWidth={true}
              >
                Сохранить
              </Button>
            </Item>
          </Grid>
        </Grid>
      </List.Section>
    </ScrollView>
  );
}

// @ts-ignore
export default wrapPage(
  wrapModalPage(Config, {
    position: 'Center',
    type: 'Modal',
    fillNavigationStack: (
      navigation: NativeStackNavigationProp<DashboardStackParamList>,
      route: RouteProp<DashboardStackParamList, 'Config'>,
    ) => {
      if (!navigation.canGoBack()) {
        navigation.push('Drawer', { screen: 'Shops' });
        navigation.push('Config', route.params);
      }
    },
    handleDismiss: (navigation) => {
      navigation.goBack();
    },
  }),
);

import AsyncStorage from '@react-native-async-storage/async-storage';
import { AxiosRequestConfig } from 'axios';
import { set } from 'lodash';

import { ACCESS_TOKEN_KEY } from '@iokanx/shared/data-access/constants';

export async function setAccessTokenInterceptor(requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const accessToken = await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
  if (accessToken) {
    set(requestConfig, ['headers', 'Authorization'], `Bearer ${accessToken}`);
  }

  return requestConfig;
}

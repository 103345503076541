import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { backendHttp } from '@iokanx/shared/data-access/api';

import { parseIntermediaryData } from './IntermediariesForm';

import { isFormEmpty } from '../../../helpers/helpers';
import { useFormEdit } from '../../../helpers/useFormEdit';
import { FormField } from '../../FormField/FormField';
import { ProfileFormSection } from '../../layouts/ProfileFormSection';
import { organizationIntermediaryValidationSchema } from '../OrganizationInfoStep/model/schemas';

interface IIntermediary {
  id: number;
  is_intermediary: boolean;
  personal_data: {
    type: string;
    nib: string;
    name: string;
  };
}

export const Intermediary = ({
  organizationId,
  intermediary,
  isSubmitted,
}: {
  isSubmitted: boolean;
  organizationId: string;
  intermediary: IIntermediary;
}) => {
  const queryClient = useQueryClient();

  const { mutate: removeIntermediary } = useMutation(
    async () => {
      const { data: response } = await backendHttp.delete(`organizations/${organizationId}/shares/${intermediary?.id}`);

      return response;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['shares']);
      },
    },
  );

  const { mutate: updateIntermediary } = useMutation(
    async () => {
      const data = getValues();

      const { data: response } = await backendHttp.patch(`organizations/${organizationId}/shares/${intermediary?.id}`, {
        is_intermediary: true,
        personal_data: {
          type: 'LEGAL',
          nib: data.personal_data.nib,
          name: data.personal_data.name,
        },
      });

      return response;
    },
    {
      onSuccess: (data) => {
        reset({
          personal_data: parseIntermediaryData(data),
        });
        handleIsEdit();
      },
    },
  );

  const {
    trigger,
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      personal_data: {
        name: intermediary.personal_data.name || '',
        nib: intermediary.personal_data.nib || '',
      },
    },
    resolver: yupResolver(organizationIntermediaryValidationSchema),
  });

  useEffect(() => {
    reset({
      personal_data: {
        name: intermediary.personal_data.name || '',
        nib: intermediary.personal_data.nib || '',
      },
    });
  }, [intermediary]);

  const onSubmit = (data: any) => updateIntermediary(data);
  const onError = (e: any) => console.error(e);

  const { isDisable, handlePress, handleIsEdit } = useFormEdit(
    Boolean(updateIntermediary),
    handleSubmit(onSubmit, onError),
  );

  const handleButtonPress = () => {
    const formValues = getValues();

    if (isFormEmpty(formValues)) {
      trigger();

      return;
    }

    return isDirty ? handlePress() : handleIsEdit();
  };

  const handleRemove = () => removeIntermediary();

  return (
    <ProfileFormSection
      isSaved={isDisable}
      isSubmitted={isSubmitted}
      isRemovable={true}
      handleRemove={handleRemove}
      handleSubmit={handleButtonPress}
    >
      <FormField name={'personal_data.name'} control={control} label={'Название'} disabled={isDisable} />

      <FormField name={'personal_data.nib'} control={control} label={'БИН/ИИН'} disabled={isDisable} />
    </ProfileFormSection>
  );
};

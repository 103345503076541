export enum Breakpoint {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export const BreakpointSizeMap: Record<Breakpoint, number> = {
  [Breakpoint.Mobile]: 0,
  [Breakpoint.Desktop]: 1023,
};

export const BREAKPOINTS: [Breakpoint, number][] = (
  Object.keys(BreakpointSizeMap) as (keyof typeof BreakpointSizeMap)[]
).map((key) => [key, BreakpointSizeMap[key]]);

import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { ITextFieldProps, Select, TextField } from '@iokanx/shared/ui/universal';

import { Item } from '../ProfileForm';

interface FormSelectFieldProps extends ITextFieldProps {
  name: string;
  control: Control<any>;
  label: string;
  disabled: boolean;
  items: any[];
}

export const FormSelectField: FC<FormSelectFieldProps> = ({ disabled, name, control, label, items, ...props }) => {
  return (
    <Controller
      control={control}
      name={'is_resident'}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Item>
          <Select
            onChange={onChange}
            style={{ width: '100%' }}
            disabled={disabled}
            value={String(value)}
            label={label}
            items={items}
            error={Boolean(error)}
            helperText={error?.message}
            {...props}
          />
        </Item>
      )}
    />
  );
};

import { Box, Typography } from '@mui/material';

import { NPSDesktopTitleStyles } from '../styles/NPSDesktopStyles';

export const NPSFourthStep = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'center', alignItems: 'center', marginTop: '100px' }}>
      <Typography component="legend" sx={NPSDesktopTitleStyles}>
        Спасибо за обратную связь!
      </Typography>
    </Box>
  );
};

import { Currency, CURRENCY_SYMBOL, PayerType } from '@iokanx/shared/data-access/constants';
import { IRefund } from '@iokanx/shared/data-access/types';
import { prepareDateTime } from '@iokanx/shared/util';

import {
  getOrderByIdPaymentById,
  IGetOrderByIdPaymentByIdRequestParams,
  IGetOrderByIdPaymentByIdResponseData,
} from '../../resources';

export interface IFindPaymentByIdResult extends IGetOrderByIdPaymentByIdResponseData {
  // Нормализованная авторизованная сумма с символом валюты
  approvedAmountWithSymbol: string;
  // Нормализованная сумма списания с символом валюты
  capturedAmountWithSymbol: string;
  // Нормализованная сумма возврата с символом валюты
  refundedAmountWithSymbol: string;
  // Нормализованная сумма комиссии процессинга
  processingFeeWithSymbol: string;
  // Дата создания в формате HH:mm, DD.MM.YYYY
  createdAt: string;
  // Авторизованная сумма в тенге
  approvedAmount: IGetOrderByIdPaymentByIdResponseData['approved_amount'];
  // Сумма списания в тенге
  capturedAmount: IGetOrderByIdPaymentByIdResponseData['captured_amount'];
  /** Способ оплаты */
  paymentMethod?: string;
}

export interface IFindRefundByIdResult extends IRefund {
  amountWithSymbol: string;
  createdAt: string;
}

export async function findPaymentByIdResolver(
  params: IGetOrderByIdPaymentByIdRequestParams,
): Promise<IFindPaymentByIdResult> {
  const payment = await getOrderByIdPaymentById(params);
  return transformPayment(payment);
}

export const PAYMENT_METHODS: Record<PayerType, string> = {
  [PayerType.CARD]: 'Карта',
  [PayerType.CARD_NO_CVC]: 'Карта',
  [PayerType.CARD_WITH_BINDING]: 'Карта',
  [PayerType.BINDING]: 'Карта',
  [PayerType.APPLE_PAY]: 'Apple Pay',
  [PayerType.GOOGLE_PAY]: 'Google Pay',
  [PayerType.MASTERPASS]: 'Masterpass',
};

export function transformPayment(payment: IGetOrderByIdPaymentByIdResponseData): IFindPaymentByIdResult {
  const approvedAmount = payment.approved_amount / 100;
  const capturedAmount = payment.captured_amount / 100;
  const refundedAmount = payment.refunded_amount / 100;
  const processingFee = payment.processing_fee / 100;

  const paymentMethod = payment.payer?.type && PAYMENT_METHODS[payment.payer.type];
  const approved_refunds = payment.approved_refunds.map(transformRefund);
  return {
    ...payment,
    approvedAmountWithSymbol: `${approvedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    capturedAmountWithSymbol: `${capturedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    refundedAmountWithSymbol: `${refundedAmount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    processingFeeWithSymbol: `${processingFee.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    createdAt: prepareDateTime(payment.created_at),
    approvedAmount,
    capturedAmount,
    paymentMethod,
    approved_refunds,
  };
}

export function transformRefund(refund: IRefund): IFindRefundByIdResult {
  const amount = refund.amount / 100;

  return {
    ...refund,
    amountWithSymbol: `${amount.toLocaleString()} ${CURRENCY_SYMBOL[Currency.KZT]}`,
    createdAt: prepareDateTime(refund.created_at),
    reason: refund.reason || '-',
    author: refund.author || '-',
  };
}

import { backendHttp } from '../../instances';

export interface IPostActionLinkResetForgotPasswordRequestData {
  password1: string;
  password2: string;
}

export interface IPostActionLinkResetForgotPasswordRequestParams {
  token: string;
}

export async function postActionLinkResetForgotPassword(
  requestData: IPostActionLinkResetForgotPasswordRequestData,
  params: IPostActionLinkResetForgotPasswordRequestParams,
) {
  await backendHttp.post('/action-link/reset-forgot-password', requestData, { params });
}

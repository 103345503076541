import {
  postAuthForgotPassword,
  IPostAuthForgotPasswordRequestData,
  IPostAuthForgotPasswordResponseData,
} from '../../resources';

export type IPasswordRecoveryResolverParams = IPostAuthForgotPasswordRequestData;
export type IPasswordRecoveryResolverResult = IPostAuthForgotPasswordResponseData;

export function passwordRecoveryResolver(
  params: IPasswordRecoveryResolverParams,
): Promise<IPasswordRecoveryResolverResult> {
  return postAuthForgotPassword(params);
}

import { CardPayAdditionalField } from '@iokanx/shared/data-access/constants';

import {
  getConfigsByShopIdFeatures,
  IGetConfigsByShopIdFeaturesResponseData,
  IGetConfigsByShopIdFeaturesResponseDataCardPayment,
  IGetConfigsByShopIdFeaturesResponseDataCardPaymentAdditionalField,
} from '../../resources';

export interface IFindShopFeaturesResolverParams {
  shopId: string;
}

export interface IFindShopFeaturesResolverResultCardPayment extends IGetConfigsByShopIdFeaturesResponseDataCardPayment {
  additional_fields?: IGetConfigsByShopIdFeaturesResponseDataCardPaymentAdditionalField[];
  save_card?: boolean;
}
export interface IFindShopFeaturesResolverResult extends IGetConfigsByShopIdFeaturesResponseData {
  card_payment: IFindShopFeaturesResolverResultCardPayment;
}

export async function findShopFeaturesResolver(
  params: IFindShopFeaturesResolverParams,
): Promise<IFindShopFeaturesResolverResult> {
  const shopFeatures = await getConfigsByShopIdFeatures(params.shopId);

  return {
    ...shopFeatures,
    card_payment: {
      ...shopFeatures.card_payment,
      additional_fields: shopFeatures.card_payment?.additional_fields?.map(transformAdditionalField),
    },
  };
}

function transformAdditionalField(
  additionalField: IGetConfigsByShopIdFeaturesResponseDataCardPaymentAdditionalField | CardPayAdditionalField,
): IGetConfigsByShopIdFeaturesResponseDataCardPaymentAdditionalField {
  if (typeof additionalField === 'string') {
    return {
      name: additionalField,
      required: true,
    };
  }

  return additionalField;
}

import classnames from 'classnames';

import { PaymentSystem } from '@iokanx/shared/data-access/constants';

interface ICardBrandPropsType {
  items: PaymentSystem[];
  active?: PaymentSystem;
  cName?: string;
}

const PAYMENT_SYSTEM_ICONS: Record<PaymentSystem, string> = {
  [PaymentSystem.AmericanExpress]: 'american-express',
  [PaymentSystem.Mastercard]: 'mastercard',
  [PaymentSystem.Visa]: 'visa',
  [PaymentSystem.Mir]: 'mir',
  [PaymentSystem.Maestro]: 'maestro',
  [PaymentSystem.UnionPay]: 'union_pay',
  [PaymentSystem.NotDefined]: '',
};

export function CardBrands({ items = [], active, cName = '' }: ICardBrandPropsType) {
  return (
    <ul className={classnames('card-brands', cName)}>
      {items
        .filter((item) => item && (!active || active === item))
        .map((item) => (
          <li key={item} className={classnames('card-brands__item')}>
            <img src={`/images/${PAYMENT_SYSTEM_ICONS[item]}-logo.svg`} alt="card type" />
          </li>
        ))}
    </ul>
  );
}

import { DateCategory, PayerType } from '@iokanx/shared/data-access/constants';

import { http } from '../../../instances';

export interface IGetAnalyticsPaymentMethodsRequestParams {
  /** Конец временного отрезка */
  to_dt?: Date;
  /** Начало временного отрезка */
  from_dt?: Date;
  /** Категория временного отрезка */
  date_category?: DateCategory;
}

export type IGetAnalyticsPaymentMethodsResponseDataItem = [PayerType, number];
export type IGetAnalyticsPaymentMethodsResponseData = IGetAnalyticsPaymentMethodsResponseDataItem[];

export async function getAnalyticsPaymentMethods(
  params: IGetAnalyticsPaymentMethodsRequestParams,
): Promise<IGetAnalyticsPaymentMethodsResponseData> {
  const { data } = await http.get<IGetAnalyticsPaymentMethodsResponseData>('/analytics/payment-methods', {
    params,
  });
  return data;
}

import moment from 'moment';

import {
  getAnalyticsTimeline,
  IGetAnalyticsTimelineRequestParams,
  IGetAnalyticsTimelineResponseData,
} from '../../resources';

export type IGetTimelineAnalyticsResolverParams = IGetAnalyticsTimelineRequestParams;

export interface IGetTimelineAnalyticsResolverResult extends Omit<IGetAnalyticsTimelineResponseData, 'timeline'> {
  // Нормаллизованные суммы списаний
  capturedAmounts: IGetAnalyticsTimelineResponseData['captured_amount'];
  // Нормаллизованный таймлайн
  timeline: string[];
  // Нормаллизованный авторизованные суммы
  approvedAmounts: IGetAnalyticsTimelineResponseData['approved_amount'];
}

export async function getTimelineAnalyticsResolver(
  params: IGetTimelineAnalyticsResolverParams,
): Promise<Readonly<IGetTimelineAnalyticsResolverResult>> {
  const analytics = await getAnalyticsTimeline(params);

  return transformAnalyticsTimeline(analytics, params);
}

export function transformAnalyticsTimeline(
  analytics: IGetAnalyticsTimelineResponseData,
  params: IGetTimelineAnalyticsResolverParams,
): IGetTimelineAnalyticsResolverResult {
  const daysInterval = moment(params.to_dt).diff(params.from_dt, 'days');

  const capturedAmounts = analytics.captured_amount.map((capturedAmount) => Math.round(capturedAmount / 100));

  const timeline = analytics.timeline.map((stringifyDate) => {
    const date = moment.utc(stringifyDate).utcOffset(6);

    if (daysInterval > 365) {
      return date.format('YYYY');
    }
    if (daysInterval > 90) {
      return date.format('MMMM YYYY');
    }
    if (daysInterval > 1) {
      return date.format('DD MMMM, YYYY');
    }

    const hourStart = date.format('DD MMMM, HH:mm');
    const hourEnd = date.add(moment.duration(1, 'hours')).format('kk:mm');

    return hourStart + ' - ' + hourEnd;
  });

  const approvedAmounts = analytics.approved_amount.map((approvedAmount) => Math.round(approvedAmount / 100));

  return { ...analytics, capturedAmounts, timeline, approvedAmounts };
}
